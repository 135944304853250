import actions from './actions';

const {
  PLAN_TRIAL_PROGRESS,
  PLAN_TRIAL_SUCCESS,
  PLAN_TRIAL_FAILED,
  FETCH_PLAN_TRIAL_PROGRESS,
  FETCH_PLAN_TRIAL_FAILED,
  FETCH_PLAN_TRIAL_SUCCESS,
  PLAN_STARTER_FAILED,
  PLAN_STARTER_PROGRESS,
  PLAN_STARTER_SUCCESS,
  FETCH_PLAN_STARTER_FAILED,
  FETCH_PLAN_STARTER_PROGRESS,
  FETCH_PLAN_STARTER_SUCCESS,
  USERS_WORDS_CRAFTED,
  ADMIN_BILLING_SETTINGS,
  USER_CRAFT_ALLOW,
  USER_BOT_DATA,
} = actions;

const initialState = {
  loading: false,
  error: null,
  todaysTotalHits: 0,
  planTrialLoading: false,
  fetchPlanTrialLoading: false,
  fetchPlanTrial: null,
  planStarterLoading: false,
  fetchPlanStarterLoading: false,
  fetchPlanStarter: null,
  totalWordsCraft: 0,
  adminBillingSettings: [],
  userAllowToCraft: { craft: true },
  userBots: [],
  lastCraftDate: null,
};

const billing = (state = initialState, action) => {
  const {
    type,
    todaysTotalHits,
    fetchPlanTrial,
    fetchPlanStarter,
    totalWordsCraft,
    adminBillingSettings,
    userAllowToCraft,
    userBots,
    lastCraftDate,
  } = action;
  switch (type) {
    case PLAN_TRIAL_PROGRESS:
      return {
        ...state,
        planTrialLoading: true,
      };

    case PLAN_TRIAL_SUCCESS:
      return {
        ...state,
        planTrialLoading: false,
      };

    case PLAN_TRIAL_FAILED:
      return {
        ...state,
        planTrialLoading: false,
      };

    case FETCH_PLAN_TRIAL_PROGRESS:
      return {
        ...state,
        fetchPlanTrialLoading: true,
      };

    case FETCH_PLAN_TRIAL_SUCCESS:
      return {
        ...state,
        fetchPlanTrialLoading: false,
        fetchPlanTrial,
      };

    case FETCH_PLAN_TRIAL_FAILED:
      return {
        ...state,
        fetchPlanTrialLoading: false,
      };

    case PLAN_STARTER_PROGRESS:
      return {
        ...state,
        planStarterLoading: true,
      };

    case PLAN_STARTER_SUCCESS:
      return {
        ...state,
        planStarterLoading: false,
      };

    case PLAN_STARTER_FAILED:
      return {
        ...state,
        planStarterLoading: false,
      };

    case FETCH_PLAN_STARTER_PROGRESS:
      return {
        ...state,
        fetchPlanStarterLoading: true,
      };

    case FETCH_PLAN_STARTER_SUCCESS:
      return {
        ...state,
        fetchPlanStarterLoading: false,
        fetchPlanStarter,
      };

    case FETCH_PLAN_STARTER_FAILED:
      return {
        ...state,
        fetchPlanStarterLoading: false,
      };
    case USERS_WORDS_CRAFTED:
      return {
        ...state,
        totalWordsCraft,
        todaysTotalHits,
        lastCraftDate,
      };
    case ADMIN_BILLING_SETTINGS:
      return {
        ...state,
        adminBillingSettings,
      };
    case USER_CRAFT_ALLOW:
      return {
        ...state,
        userAllowToCraft,
      };
    case USER_BOT_DATA:
      return {
        ...state,
        userBots,
      };

    default:
      return state;
  }
};

export { billing };

/* ------------------------------------------------------ */
/*                         Invite                         */
/* ------------------------------------------------------ */

/**
 * This method is obselete.
 * Methodology
 * When a craftly admin invite the user, admin invite the user with email address
 * This page appears when the user click on the email link and then user have to set the password
 * firebase signInWithEmailLink is going to set the password
 */

import React, { useState, useCallback, useEffect, Suspense } from 'react';
import { InviteWrapper } from './styled';
import { Main } from '../styled';
import Heading from '../../components/Headings';
import { Button } from '../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Input, notification, Skeleton, Typography } from 'antd';
import { getFirebase } from 'react-redux-firebase';
import { emailLinkSignUp } from '../../redux/firebase/auth/actionCreator';
import { login } from '../../redux/authentication/actionCreator';
import { setNewPasswordEmailLink } from '../../redux/firebase/auth/actionCreator';
import { useNavigate } from 'react-router-dom';
import { Cards } from '../../components/Card';

const { Text } = Typography;

const Invite = ({ urlParms, showSetPassword }) => {
  const [verifyEmail, setVerifyEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const firebase = getFirebase();

  const { setNewPasswordLoading, isFbAuthenticate, requiredSetPassword, email } = useSelector((state) => {
    return {
      isLoading: state.firebaseAuth.loading,
      setNewPasswordLoading: state.firebaseAuth.setNewPasswordLoading,
      isFbAuthenticate: state.fb.auth.uid,
      requiredSetPassword: state.fb.profile.requiredSetPassword,
      email: state.fb.profile.email,
    };
  });

  const onNewPassClick = () => {
    dispatch(setNewPasswordEmailLink(newPassword));
  };

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    navigate('/');
  }, [navigate]);

  useEffect(() => {
    if (isFbAuthenticate && !requiredSetPassword) {
      handleFbLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFbAuthenticate]);

  const onChangeEmail = (e) => {
    const email = e.target.value && e.target.value.trim();
    setVerifyEmail(email);
  };
  const onChangeNewPass = (e) => {
    setNewPassword(e.target.value);
  };

  const onClickEnter = () => {
    // Confirm the link is a sign-in with email link.
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.

      // var email = window.localStorage.getItem('emailForSignIn');
      // if (!email) {
      // email = window.prompt('Please provide your email for confirmation');
      // }

      // The client SDK will parse the code from the link for you.

      firebase
        .auth()
        .signInWithEmailLink(verifyEmail, window.location.href)
        .then((result) => {
          // Clear email from storage.
          console.log(`result`, result);
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser

          const { providerId } = result.additionalUserInfo;
          const { email, uid } = result.user;

          const userDetails = {
            provider: providerId,
            email,
            uid,
          };
          dispatch(emailLinkSignUp(userDetails));
        })
        .catch((error) => {
          console.log(`error`, error);
          notification.error({
            message: error.message,
          });
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    } else {
      notification.info({
        message: 'No invitation found on this email',
      });
    }
  };

  return (
    <Main>
      <Suspense
        fallback={
          <Cards headless>
            <Skeleton active />
          </Cards>
        }
      >
        <InviteWrapper>
          <img src={require(`../../static/general/invite.jpg`)} alt="invite" />
          {showSetPassword ? (
            <>
              <Text>You have successfully signed up using this </Text> <Text strong> {email}</Text>
              <br />
              <Text>Please set your new password </Text>
              <Input.Password className="email-verify" value={newPassword} onChange={onChangeNewPass} />
              <Text>Min Length: 6 chars </Text>
              <br />
              <Button
                loading={setNewPasswordLoading}
                disabled={newPassword.length < 6}
                type="primary"
                onClick={onNewPassClick}
              >
                Enter
              </Button>
            </>
          ) : (
            <>
              <Heading as="h3">Hey, You have been invited to join Craftly. </Heading>
              <p>Please enter your email for verification </p>
              {/*  User opened the link on a different device. To prevent session fixation
                 attacks, ask the user to provide the associated email again. For example:*/}
              {/* reason of getting the email verification */}
              <Input className="email-verify" value={verifyEmail} onChange={onChangeEmail} />
              <br />
              <Button disabled={verifyEmail.length < 1} type="primary" onClick={onClickEnter}>
                Enter
              </Button>
            </>
          )}
        </InviteWrapper>
      </Suspense>
    </Main>
  );
};

export default Invite;

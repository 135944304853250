/* ------------------------------------------------------ */
/*                        Price Pop                       */
/* ------------------------------------------------------ */

/**
 *
 * Display of 3 plans
 * 1. Starter
 * 2. Crafter
 * 3. Enterprise
 *
 * it redirect to landing page pricing
 * Landing page also have the Pricing page layout
 */

import React from 'react';
import { Typography } from 'antd';
import { PricePopStyled, PopContent, ComparePriceStyled } from './styled';
import { Button } from '../../../Button';
import PopCard from './PricePopCard';

const PricePop = () => {
  const { Title, Text } = Typography;

  const redirect = (url) => {
    url && window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <PricePopStyled>
      <PopCard />
      <ComparePriceStyled>
        <PopContent>
          <Title level={2}>Pricing</Title>
          <Text className="pop-text">Detailed pricing models.</Text>
        </PopContent>
        <Button size="default" type="light-purple" onClick={() => redirect('https://www.craftly.ai/pricing')}>
          Compare Pricing
        </Button>
      </ComparePriceStyled>
    </PricePopStyled>
  );
};

export default PricePop;

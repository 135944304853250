/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import React from 'react';
import ContentCategory from './ContentCategory';
import CraftedContent from './CraftedContent';
import { ResultPageWrap } from './styled';

const ResultPage = () => {
  return (
    <ResultPageWrap>
      <CraftedContent />
      <ContentCategory />
    </ResultPageWrap>
  );
};

export default ResultPage;

import React from 'react';
import { Collapse, Typography } from 'antd';
import iman from '../../../../static/new-teams/iman.svg';
import nicole from '../../../../static/new-teams/nicole.svg';
import mehga from '../../../../static/new-teams/mehga.svg';
import chart from '../../../../static/new-teams/Chart.svg';
import chat from '../../../../static/new-teams/Chat.svg';
import folder from '../../../../static/new-teams/Folder.svg';
import { AdminTableStyle, TypographyStyle } from './styled';
const { Panel } = Collapse;

export const TabItemData = [
  { icon: chart, title: 'keep your projects and clients organized in one place' },
  { icon: chat, title: 'keep your branding consistent with shared outputs and favorites' },
  {
    icon: folder,
    title: (
      <>
        Move faster by working together - editing and comment in real time <i>[coming soon]</i>
      </>
    ),
  },
];

export const RowTitle = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Team Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Words',
    dataIndex: 'words',
    key: 'words',
  },
];

export const RowDataItems = [
  {
    key: '1',
    name: (
      <TypographyStyle>
        {window.innerWidth > 767 && <img src={iman} alt="iman" className="person-icon" />}
        Iman Bashir
      </TypographyStyle>
    ),
    email: 'iman@craftly.ai',
    role: <Typography style={{ color: '#737277' }}>Owner</Typography>,
    words: <Typography style={{ color: '#737277' }}>456 word</Typography>,
  },
  {
    key: '2',
    name: (
      <TypographyStyle>
        {window.innerWidth > 767 && <img src={nicole} alt="nicole" className="person-icon" />}
        Nicole Lytle
      </TypographyStyle>
    ),
    email: 'nicole@craftly.ai',
    role: (
      <AdminTableStyle>
        <Collapse>
          <Panel header="Administrator" key="1"></Panel>
        </Collapse>
      </AdminTableStyle>
    ),
    words: <Typography style={{ color: '#737277' }}>675 word</Typography>,
  },
  {
    key: '3',
    name: (
      <TypographyStyle>
        {window.innerWidth > 767 && <img src={mehga} alt="nicole" className="person-icon" />}
        Megan Apa
      </TypographyStyle>
    ),
    email: 'megan@craftly.ai',
    role: (
      <AdminTableStyle>
        <Collapse>
          <Panel header="Administrator" key="1"></Panel>
        </Collapse>
      </AdminTableStyle>
    ),
    words: <Typography style={{ color: '#737277' }}>344 word</Typography>,
  },
];

/* ------------------------------------------------------ */
/*                     USER Management                    */
/* ------------------------------------------------------ */

/* ----------------- CRAFTLY ADMIN PAGE ----------------- */

/**
 * This page is for Craftly admin and staff
 * All users details are displayed.
 * Users data is fetched from bigQuery.
 *
 * Inorder to display the user data faster, BigQuery help us to manage the data.
 * As soon as the user data inserted or updated, the data is synced with BigQuery using Firebase Extension.
 *
 * Firestore
 * Collection: users
 * Document: <UID>
 *
 * BigQuery Table: users_view
 * SELECT * FROM `craftly-staging-44134.craftly_users.users_bot`
 *
 * Admin can filter the data on the bases of
 * 1. Roles -  Staff | Admin | Free User | User
 * 2. Plan  -  NA | Trial | Starter | Crafter | Team | No Credit Card Trial
 * 3. Status - Active | Pause | Deactive | Deleted | Approved | Expired | Expiring soon | Error
 *
 * Craftly admin can perform these filters in order to get the users list
 *
 * Users list contains the following information
 * Name |  Email | Roles | Plan | Status | Words | Created Date | Actions
 *
 *
 * isCanceled: a user has cancelled the plan and now the plan is in notice period. a plan will be fully cancelled at the period or duration ends
 *
 * When the user selects multiple rows the Update Status options appear below the filters
 *
 * When search is enable, all the filters get disabled. Inorder to reset, press reset button
 *
 *
 */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Input, Checkbox, Select, Tooltip, Radio, Typography, Spin, Divider } from 'antd';
import AllUsersList from './overview/UserList';
import { Main } from '../styled';
import { TableFilters, UserManagementHeader } from './styled';
import { Button } from '../../components/Button';
import { planOptions, roleOptions, BulkStatusOptions, statusWithPlanOptions } from '../../utility/userProperties';
import AddUser from './overview/AddUser';
import {
  getFilteredUsers,
  inviteUser,
  saveUserManagementDataFilters,
  searchInputUsers,
} from '../../redux/AdminFeatures/actionCreator';
import { InfoCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { WITH_PLAN_STATUS } from '../../constants';
import { capitalizeFirstLetter } from '../../utility/utility';

const { Text } = Typography;
const { Search } = Input;
const { Option } = Select;

const UserManagement = () => {
  const [bulkStatus, setBulkStatus] = useState();
  const [selectedRowsCount, setSelectedRowsCount] = useState(0); // When the user selects multiple rows the Update Status options appear below the filters
  const [createUserOpen, setCreateUserOpen] = useState(false); // open users modal for more info
  const [searchValue, setSearchValue] = useState('');
  const [searchParameter, setSearchParameter] = useState('email');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { createUserLoading } = useSelector((state) => {
    return {
      createUserLoading: state.adminFeatures.createUserLoading,
    };
  });
  const { filteredUsersLoading, totalUsersRows, userManagmentFilters } = useSelector((state) => state.adminFeatures);

  const pathName = window.location.pathname;
  const mainPathSplit = pathName.split('/');
  const urlUIDorEmail = mainPathSplit[2];

  useEffect(() => {
    !createUserLoading && setCreateUserOpen(false);
  }, [createUserLoading]);

  useEffect(() => {
    selectedRowsCount === 0 && setBulkStatus('');
  }, [selectedRowsCount]);

  const onChangeFilterStatus = (checkedValues) => {
    // BigQuery always need first empty index of array. thats why if the array is empty then we are sending First index of empty string
    // setFilterStatus(checkedValues.length > 0 ? checkedValues : ['']);
    const newValue = { ...userManagmentFilters, status: checkedValues.length > 0 ? checkedValues : [''] };
    dispatch(
      saveUserManagementDataFilters({
        userManagmentFilters: newValue,
      }),
    );
    removeURL();
  };
  const onChangeFilterRole = (checkedValues) => {
    // BigQuery always need first empty index of array. thats y if the array is empty then we are sending First index of empty string
    const newValue = { ...userManagmentFilters, roles: checkedValues.length > 0 ? checkedValues : [''] };
    dispatch(
      saveUserManagementDataFilters({
        userManagmentFilters: newValue,
      }),
    );

    removeURL();
  };
  const onChangeFilterPlan = (checkedValues) => {
    // BigQuery always need first empty index of array. thats y if the array is empty then we are sending First index of empty string
    const newValue = { ...userManagmentFilters, plan: checkedValues.length > 0 ? checkedValues : [''] };
    dispatch(
      saveUserManagementDataFilters({
        userManagmentFilters: newValue,
      }),
    );
    removeURL();
  };

  const onBulkActionStatus = (e) => {
    setBulkStatus(e.target.value);
    removeURL();
  };

  const applyFilter = () => {
    // when the user click on ApplyFilters then this action executes.
    // this fetch the results from BigQuery
    dispatch(getFilteredUsers({ userManagmentFilters }));
    setSearchValue('');
  };

  // it reset the filters
  const onRefresh = () => {
    dispatch(
      saveUserManagementDataFilters({
        userManagmentFilters: {
          roles: undefined,
          status: [WITH_PLAN_STATUS.ACTIVE, WITH_PLAN_STATUS.APPROVED],
          plan: undefined,
        },
      }),
    );
    removeURL();
  };

  // On creating or invite user by craftly admin
  const onCreateNew = (values) => {
    // this is for the firebase signInWithEmailLink which sends the authentication code also
    // dispatch(addNewUser({ userData: values }));

    // this is simple. No authentication code. user must have to Sign-Up
    // only user email details are save in users collection
    dispatch(inviteUser({ userData: values }));
  };

  // search request will be sent by BigQuery
  const handleSearch = (searchText, event) => {
    if (searchText && searchText.length > 0) {
      const inputSearchText = searchParameter !== 'uid' ? searchText.toLowerCase() : searchText;
      setSearchValue(inputSearchText);
      dispatch(searchInputUsers({ inputSearchText, inputSearchParameter: searchParameter }));
      removeURL('searchText');
    }
  };

  const removeURL = (searchText) => {
    navigate('/user-management');
    !searchText && setSearchValue('');
  };

  const selectBefore = (
    <Select defaultValue="email" value={searchParameter} onChange={setSearchParameter}>
      <Option value="email">Email</Option>
      <Option value="name">Name</Option>
      <Option value="uid">UID</Option>
    </Select>
  );

  // Filter to search the data
  const filters = () => {
    return (
      // These are User Management Filters.
      <Spin spinning={filteredUsersLoading}>
        <TableFilters>
          <div className="filters-wrapper">
            <div className="filters-status">
              <Text>Status</Text>
              <Checkbox.Group
                disabled={searchValue && searchValue.length > 0}
                defaultValue={[WITH_PLAN_STATUS.ACTIVE, WITH_PLAN_STATUS.APPROVED]}
                value={userManagmentFilters.status}
                onChange={onChangeFilterStatus}
              >
                {statusWithPlanOptions.map(({ label, value, toolTip }) => {
                  return (
                    <Checkbox value={value}>
                      {label}{' '}
                      <Tooltip title={toolTip}>
                        <InfoCircleFilled style={{ fontSize: '10px' }} />
                      </Tooltip>
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text>Roles</Text>
              <Checkbox.Group
                disabled={searchValue && searchValue.length > 0}
                defaultValue={[]}
                value={userManagmentFilters.roles}
                onChange={onChangeFilterRole}
              >
                {roleOptions.map(({ label, value }) => {
                  return <Checkbox value={value}>{label} </Checkbox>;
                })}
              </Checkbox.Group>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text>Plan</Text>
              <Checkbox.Group
                disabled={searchValue && searchValue.length > 0}
                defaultValue={[]}
                value={userManagmentFilters.plan}
                onChange={onChangeFilterPlan}
              >
                {planOptions.map(({ label, value }) => {
                  return <Checkbox value={value}>{label} </Checkbox>;
                })}
              </Checkbox.Group>
            </div>
            <div>
              <Button style={{ margin: '5px' }} onClick={applyFilter} size="small" type="warning" key="1">
                Apply Filters
              </Button>
              <Button type={'danger'} style={{ margin: '5px' }} onClick={onRefresh} size="small" key="2">
                Reset Filter
              </Button>
            </div>
          </div>
          {selectedRowsCount > 0 && (
            <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '25px' }}>
              <Text>Update Status</Text>
              <Radio.Group
                options={BulkStatusOptions}
                optionType="button"
                buttonStyle="solid"
                onChange={onBulkActionStatus}
                value={bulkStatus}
                size="middle"
              />
            </div>
          )}
        </TableFilters>
      </Spin>
    );
  };

  return (
    <>
      <Main>
        <UserManagementHeader>
          <Row gutter={[16, 16]} justify={'space-between'}>
            <Col sm={24} lg={21}>
              <Row gutter={[16, 16]}>
                <Col>
                  <span className="title-counter">{totalUsersRows} Users </span>
                  <Divider type="vertical" />
                </Col>
                <Col lg={15} xl={10} xxl={8}>
                  <Search
                    className="user-search"
                    placeholder={`Search ${capitalizeFirstLetter(searchParameter)}`}
                    allowClear
                    enterButton="Search"
                    size="middle"
                    onSearch={handleSearch}
                    addonBefore={selectBefore}
                    loading={filteredUsersLoading}
                  />
                </Col>
              </Row>
            </Col>
            <Col sm={24} lg={3}>
              <Button
                className="btn-add_new"
                onClick={() => setCreateUserOpen(true)}
                size="default"
                type="primary"
                key="1"
              >
                + Add New User
              </Button>
            </Col>
          </Row>
        </UserManagementHeader>

        <Row gutter={15}>
          <Col md={24}>
            {filters()}
            {/* List of users */}
            <AllUsersList
              bulkStatus={bulkStatus}
              selectedRowsCount={setSelectedRowsCount}
              searchValue={searchValue}
              searchParameter={searchParameter}
            />
          </Col>
        </Row>

        {/* This feature is used sometime. */}
        <AddUser
          createUserOpen={createUserOpen}
          onCreateNew={onCreateNew}
          onCancel={() => {
            setCreateUserOpen(false);
          }}
          confirmLoading={createUserLoading}
        />
      </Main>
    </>
  );
};

export default UserManagement;

/* ------------------------------------------------------ */
/*                      Export Excel                      */
/* ------------------------------------------------------ */

/* 
Methadology
  Exports Excel Sheets 
  Data exports in the given columns and rows
*/

/* Used in
 src/container/UserManagement/UsersList/allUsersList.js
 src/container/UserManagement
*/

import React from 'react';
import Excel from 'exceljs';
import { Input, Typography } from 'antd';
import { saveAs } from 'file-saver';
import { Button } from '../Button';
import { ExcelExportStyled } from './styled';

const { Text } = Typography;

const workSheetName = 'userManagement-1';
const workBookName = 'userManagement';
const myInputId = 'myInput';

/**
@array  data: object array of users data 
@array columns: it contains the 8 columns, UID, Name, Email, Roles, Plan, Status, Words, Hits, Created Date
@boolean exportBtnLoading: loading status 
 */

const ExportExcel = ({ data, columns, exportBtnLoading }) => {
  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    try {
      const myInput = document.getElementById(myInputId);
      const fileName = myInput.value || workBookName;

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName, { views: [{ showRowStripes: true }] });

      // add worksheet columns
      // each columns contains header and its mapping key from data
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      worksheet.columns.forEach((column) => {
        column.width = column.header.length + 5;
        column.alignment = { horizontal: 'center' };
      });

      // loop through data and add each one to worksheet
      data.forEach((singleData) => {
        worksheet.addRow(singleData);
      });

      // loop through all of the rows and set the outline style.
      worksheet.eachRow({ includeEmpty: false }, (row) => {
        // store each cell to currentCell
        const currentCell = row._cells;

        // loop through currentCell to apply border only for the non-empty cell of excel
        currentCell.forEach((singleCell) => {
          // store the cell address i.e. A1, A2, A3, B1, B2, B3, ...
          const cellAddress = singleCell._address;

          // apply border
          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getCell(cellAddress).font = {
            // name: 'Comic Sans MS',
            // family: 4,
            size: 14,
            underline: false,
            bold: false,
          };
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  return (
    <ExcelExportStyled>
      <Text>Export to</Text>
      <div className="file-name">
        <Input width={400} id={myInputId} defaultValue={workBookName} /> .xlsx{' '}
      </div>
      <Button loading={exportBtnLoading} size="small" onClick={saveExcel} type="primary" key="1">
        Export
      </Button>
    </ExcelExportStyled>
  );
};

export default ExportExcel;

/* ------------------------------------------------------ */
/*                      StripeElement                     */
/* ------------------------------------------------------ */

/**
 * StripeElement
 * This cover all the credit card information, subscription subscribed, check credit card, promocode and etc
 *
 *
 * ---------------------  CHECKCARD --------------------
 * When a user enters credit card information, the first credit card details are checked to see if the user's credit card is already in use.
 * If a credit card is not used, Craftly will give the user a 5-day trial period; otherwise, there will be no trial period.
 *
 * 1. Stripe Card token is being generated using CardNumberElement
 * ---------------------  CHECKCARD --------------------
 *
 */

import React, { useState, useEffect } from 'react';
import { getFirestore } from 'redux-firestore';
import { useDispatch } from 'react-redux';
import { getData } from 'country-list';
import FeatherIcon from 'feather-icons-react';
import { Typography, Tooltip, Tag, Input, Row, Col, Select, Form, Card, Divider, Modal } from 'antd';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { StripeCustomization } from './styled';
import {
  checkCardUsage,
  checkPromoCode,
  enableSubscriptionApi,
  retryEnableSubscriptionApi,
} from '../../../../services/apiServices';

import { Button } from '../../../../components/Button';
import { capitalizeFirstLetter } from '../../../../utility/utility';
import { PREPAID_CARD_MESSAGE_DESCRIPTION, PREPAID_CARD_MESSAGE_TITLE } from '../../../../constants/content';
import { alertModal } from '../../../../components/Modal';
import { ExclamationCircleOutlined, TagOutlined } from '@ant-design/icons';
import { BUTTON_PRIMARY, MONTHLY_DURATION, PLAN_STATUS, PREPAID_CARD, YEARLY_DURATION } from '../../../../constants';

import { savePrepaidCardUserInfo } from '../../../../redux/AdminFeatures/actionCreator';

const { Text } = Typography;
const { Option } = Select;

const StripeElement = ({
  uid,
  selectedPriceId,
  selectedPlanWords,
  stripeId,
  changePlan,
  email,
  firstName,
  lastName,
  setonLive,
  activatedPlanDetails,
  currentInvoiceId,
  planSelect,
  selectedPlanPrice,
  planDuration,
  showPlanDuration,
  selectedProductId,
  selectedPlanDisplayWords,
}) => {
  const [modal, contextHolder] = Modal.useModal();

  const [error, setError] = useState(null);
  const [code, setCode] = useState('');
  const [codeLoading, setCodeLoading] = useState(false);
  const [codeMessage, setCodeMessage] = useState(false);
  const [displayPrice, setDisplayPrice] = useState(0);
  const [codeParameters, setCodeParameters] = useState(false);
  const [promoId, setCodePromoId] = useState(false);
  const [codeDiscountPrice, setCodeDiscountPrice] = useState(0);
  const [buttonText, setButtonText] = useState('Start Free Trial');
  const [checkCardDetails, setCheckCardDetails] = useState({
    cardNumberEmpty: true,
    cardNumberError: null,
    cardNumberComplete: false,
    cardExpiryEmpty: true,
    cardExpiryError: null,
    cardExpiryComplete: false,
    cardCvcEmpty: true,
    cardCvcError: null,
    cardCvcComplete: false,
  });

  const dispatch = useDispatch();
  const [checkCreditCardExit, setCheckCreditCardExist] = useState(false);
  const [processing, setProcessing] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const countries = getData();
  const [form] = Form.useForm();

  const { currcentPricingPlanId, status: currentStripePlanStatus } = activatedPlanDetails || {
    currcentPricingPlanId: null,
    status: null,
  };

  // check user credit card is already use in another account or not
  const checkCard = async () => {
    stripe.createToken(elements.getElement(CardNumberElement)).then(async (result) => {
      const { country, address } = form.getFieldsValue();

      // check the credit card already user or not
      await checkCardUsage({
        tokenID: result.token.id,
        accountExist: stripeId,
        name: `${firstName} ${lastName}`,
        country,
        address,
      })
        .then((response) => {
          const { alreadyExists } = response.data;
          setCheckCreditCardExist(alreadyExists);
          setProcessing(false);

          // If the credit card is already in use then there would not be any trial
          if (alreadyExists) {
            modal.confirm({
              title: 'Your free trial has already been redeemed!',
              icon: <ExclamationCircleOutlined />,
              content:
                'By selecting continue, you agree that your selected plan will begin immediately, and your card will be charged.',
              okText: 'Continue',
              onOk() {
                createCardPayment({ cardExist: !alreadyExists });
                setButtonText('Continue');
                setCheckCreditCardExist(false);
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          } else {
            createCardPayment({ cardExist: !alreadyExists });
          }
        })
        .catch((err) => {
          setProcessing(false);
          console.log('err', err);
        });
    });
  };

  // to inititate the payment
  const createCardPayment = ({ cardExist }) => {
    const { country, name, address } = form.getFieldsValue();
    setProcessing(true);

    // stripe create payment method to get card info
    stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          email,
          name,
          address: {
            country: country,
            line1: address,
          },
        },
      })
      .then(async (result) => {
        const {
          paymentMethod: {
            card: { funding },
          },
        } = result;

        if (result.error) {
          setError(`Payment failed ${result.error.message}`);
          setProcessing(false);
          // displayError(result);
        } else {
          // PREPAID Cards are not allowed
          if (funding !== PREPAID_CARD) {
            if (currcentPricingPlanId && currentStripePlanStatus) {
              if (currentStripePlanStatus === PLAN_STATUS.INCOMPLETE && currcentPricingPlanId === selectedPriceId) {
                retrySubscription({ paymentMethodId: result.paymentMethod.id });
              } else {
                createSubscription({ paymentMethodId: result.paymentMethod.id });
              }
            } else {
              createSubscription({ paymentMethodId: result.paymentMethod.id });
            }
          } else if (funding === PREPAID_CARD) {
            alertModal.error({
              title: PREPAID_CARD_MESSAGE_TITLE,
              content: PREPAID_CARD_MESSAGE_DESCRIPTION,
            });
            setProcessing(false);

            // save prepaid cards
            dispatch(savePrepaidCardUserInfo({ cardInfo: result.paymentMethod, email, uid }));
          } else {
            setProcessing(false);
          }
        }
      })
      .catch((error) => {
        console.log(`error`, error);
        setError(`${error.message}`);
        setProcessing(false);
      });
  };

  // create Subscription
  const createSubscription = async ({ paymentMethodId }) => {
    await enableSubscriptionApi({
      priceId: selectedPriceId,
      paymentMethodId,
      promotionCode: promoId,
      quantity: selectedPlanWords,
      userSelectedPlanName: planSelect,
      userSelectedPlanInterval: showPlanDuration,
    })
      .then((response) => {
        if (response.data.error) {
          // console.log('result.error-->', response.data.error);
          // The card had an error when trying to attach it to a customer.
          throw response.data.error;
        }
        const subscriptionResult = response.data;
        return {
          paymentMethodId: paymentMethodId,
          priceId: selectedPriceId,
          subscription: subscriptionResult,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(handleSubscriptionRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(onSubscriptionComplete)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        if (error.response) {
          // console.log('error', error.response.data);
          const { data } = error.response;
          setProcessing(false);
          setError(data.error.message);
        }
      });
  };

  /**
   * some creadit cards are required customer action such as sms or email verification code.
   */
  const handlePaymentThatRequiresCustomerAction = ({ subscription, invoice, priceId, paymentMethodId, isRetry }) => {
    if (subscription && (subscription.status === PLAN_STATUS.ACTIVE || subscription.status === PLAN_STATUS.TRIAL)) {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;
    if (
      paymentIntent &&
      (paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method'))
    ) {
      // stripe confirm Card payment
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            console.log('The card was declined (that is, insufficient funds, card has expired, etc).', result.error);
            alertModal.error({
              title: 'The card was declined',
              content: result.error.message,
            });
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (that is, insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              return { subscription, invoice, priceId, paymentMethodId };
            }
          }
        })
        .catch((error) => {
          const { message } = error.error;
          setError(message);
          setProcessing(false);
          return { subscription, invoice, priceId, paymentMethodId };
        });
    } else {
      // No customer action needed.
      return { subscription, invoice, priceId, paymentMethodId };
    }
  };

  function handleSubscriptionRequiresPaymentMethod({ subscription, paymentMethodId, priceId }) {
    if (subscription && (subscription.status === PLAN_STATUS.ACTIVE || subscription.status === PLAN_STATUS.TRIAL)) {
      return { subscription, priceId, paymentMethodId };
    } else {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      alertModal.error({
        title: 'The card was declined',
      });
      setProcessing(false);
      throw { error: { message: 'Your card was declined....' } };
    }
  }

  function onSubscriptionComplete({ subscription }) {
    // Payment was successful.
    if (subscription && (subscription.status === PLAN_STATUS.ACTIVE || subscription.status === PLAN_STATUS.TRIAL)) {
      const db = getFirestore();
      db.collection('users').doc(uid).set(
        {
          trialBillingPage: true,
        },
        { merge: true },
      );
    } else {
      // console.log('result', subscription);
    }
  }

  const retrySubscription = async ({ paymentMethodId }) => {
    const invoiceId = currentInvoiceId || localStorage.getItem('latestInvoiceId');
    await retryEnableSubscriptionApi({ paymentMethodId, invoiceId })
      .then((response) => {
        if (response.data.error) {
          // console.log('result.erro', response.data.error);
          // The card had an error when trying to attach it to a customer.
          throw response.data.error;
        }
        const invoiceResult = response.data;
        return {
          invoice: invoiceResult,
          paymentMethodId: paymentMethodId,
          priceId: selectedPriceId,
          isRetry: true,
        };
      })
      // Some payment methods require a customer to be on session
      // to complete the payment process. Check the status of the
      // payment intent to handle these actions.
      .then(handlePaymentThatRequiresCustomerAction)
      // If attaching this card to a Customer object succeeds,
      // but attempts to charge the customer fail, you
      // get a requires_payment_method error.
      .then(handleInvoiceRequiresPaymentMethod)
      // No more actions required. Provision your service for the user.
      .then(onInvoiceComplete)
      .catch((error) => {
        // An error has happened. Display the failure to the user here.
        // We utilize the HTML element we created.
        if (error.response) {
          console.log('error', error.response.data);
          const { data } = error.response;
          setProcessing(false);
          setError(data.error.message);
        }
      });
  };

  function handleInvoiceRequiresPaymentMethod({ invoice }) {
    if (invoice && invoice.status === 'open') {
      return { invoice };
    } else {
      // console.log('invoice ', invoice);
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      // console.log('subscription.latest_invoice.id', invoice.id);
      localStorage.setItem('latestInvoiceId', invoice.id);
      throw { error: { message: 'Your card was declined....' } };
    }
  }

  function onInvoiceComplete(result) {
    // Payment was successful.
    if (result.invoice.status === 'open') {
    } else {
      console.log('result', result);
    }
  }

  const cardStyle = {
    showIcon: true,
    iconStyle: 'solid',
    style: {
      base: {
        color: '#32325d',
        fontSmoothing: 'antialiased',
        borderRadius: '6px',
        fontSize: '16px',
        '::placeholder': {
          color: '#BFBFBF',
        },
      },
      invalid: {
        color: '#fa755a',
        borderColor: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  // Listen for changes in the CardElement
  const onCardDetailsChange = async (event) => {
    // and display any errors as the customer types their card details
    const { error, empty, elementType, complete } = event; // complete -> correct input of card details  . elementType -> card Field Type
    setCode('');
    setCodeMessage(false);
    setCodeParameters(false);
    setCodeDiscountPrice(0);
    if (error) {
      const validateResult = {
        ...checkCardDetails,
        [`${elementType}Error`]: error.message,
        [`${elementType}Empty`]: empty,
        [`${elementType}Complete`]: complete,
      };
      setCheckCardDetails(validateResult);
    } else {
      const validateResult = {
        ...checkCardDetails,
        [`${elementType}Error`]: null,
        [`${elementType}Empty`]: false,
        [`${elementType}Complete`]: complete,
      };
      setCheckCardDetails(validateResult);
    }
    setCheckCreditCardExist(false);
  };

  // Manually check all stripe card fields
  const validateCardFields = () => {
    const updateCardFields = {};
    const { cardNumberEmpty, cardExpiryEmpty, cardCvcEmpty } = checkCardDetails;

    if (cardNumberEmpty) {
      updateCardFields.cardNumberError = 'Please enter your card number.';
    }
    if (cardExpiryEmpty) {
      updateCardFields.cardExpiryError = 'Please enter your expiration date.';
    }
    if (cardCvcEmpty) {
      updateCardFields.cardCvcError = 'Please enter your security code.';
    }
    const validateResult = { ...checkCardDetails, ...updateCardFields };
    setCheckCardDetails(validateResult);
    const expectedResult = {
      cardNumberEmpty: false,
      cardNumberError: null,
      cardNumberComplete: true,
      cardExpiryEmpty: false,
      cardExpiryError: null,
      cardExpiryComplete: true,
      cardCvcEmpty: false,
      cardCvcError: null,
      cardCvcComplete: true,
    };
    return JSON.stringify(expectedResult) === JSON.stringify(validateResult);
  };

  const onPressTrialButton = async () => {
    setError('');
    setCheckCreditCardExist(false);
    const cardValidateStatus = validateCardFields();
    try {
      await form.validateFields();
      if (cardValidateStatus) {
        setProcessing(true);
        checkCard();
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };
  const { cardNumberError, cardCvcError, cardExpiryError } = checkCardDetails;

  useEffect(() => {
    if (planDuration === YEARLY_DURATION) {
      setDisplayPrice(selectedPlanPrice);
    } else if (planDuration === MONTHLY_DURATION) {
      setDisplayPrice(selectedPlanPrice);
      onCodeRemove();
    } else {
      setDisplayPrice(0);
    }
    setCodeParameters(false);
    setCodeDiscountPrice(0);
    setCodeMessage(false);
    setCodePromoId(false);
  }, [planDuration, selectedPlanPrice, selectedPriceId]);

  const showWords = () => {
    return selectedPlanDisplayWords === 'unlimited' ? 'unlimited' : selectedPlanDisplayWords?.toLocaleString('en-US');
  };

  // on Promocode activate
  const onCodeClick = (userDiscountCode) => {
    let approve = true;
    let discountCode = userDiscountCode || code;

    if (approve) {
      setCodeLoading(true);
      setCodePromoId(false);
      discountCode &&
        checkPromoCode({ checkCode: discountCode })
          .then((response) => {
            if (response.data.error) {
              setCodeLoading(false);
              console.log('response.data.error', response.data.error);
            }
            setCodeLoading(false);
            if (response.data) {
              // promo-code details which is stored in stripe promoCode section
              const {
                coupon: { amount_off, duration, duration_in_months, percent_off, valid, applies_to },
                restrictions: { minimum_amount },
                expires_at,
                customer,
                active,
                id,
              } = response.data;

              let allCheckClear = true;
              // Check all validations.

              // check if the promocode is valid or not.
              if (valid === false && allCheckClear) {
                setCodeMessage({ message: 'This code is invalid', approveType: false });
                allCheckClear = false;
              }
              // check if the promocode is active or not. if promoCode is expire it turns active into false.
              if (active === false && allCheckClear) {
                setCodeMessage({ message: 'This code has expired.', approveType: false });
                allCheckClear = false;
              }

              // check if promoCode has applied on some specific products.
              if (applies_to && allCheckClear) {
                const { products } = applies_to;
                if (!products.includes(selectedProductId)) {
                  setCodeMessage({
                    message: 'This code is valid, but does not apply to items in your order.',
                    approveType: false,
                  });
                  allCheckClear = false;
                }
              }
              const planPriceInCents = parseFloat(displayPrice) * 100;
              if (minimum_amount && planPriceInCents < minimum_amount) {
                setCodeMessage({
                  message: `This code is valid, but your order doesn't meet the minimum amount.`,
                  approveType: false,
                });
                allCheckClear = false;
              }

              // If all promoCode validates
              if (allCheckClear) {
                setCodeParameters({
                  duration,
                  duration_in_months,
                  percent_off,
                  valid,
                  minimum_amount,
                  customer,
                  expires_at,
                  amount_off,
                  active,
                  id,
                });
                setCodeMessage(false);
                setCodePromoId(id);

                if (amount_off) {
                  const fixedAmount = parseFloat(amount_off / 100);
                  setCodeDiscountPrice(`${parseFloat(fixedAmount.toFixed(2))}`); // this is discount price on the selected plan price
                  setCodeMessage({ message: `$${amount_off / 100} off once`, approveType: true });
                  setCodePromoId(id);
                }
                if (percent_off) {
                  const percentAmount = parseFloat(displayPrice) * parseFloat(percent_off / 100);
                  if (duration && duration === 'once') {
                    setCodeMessage({ message: `${percent_off} off once,`, approveType: true });
                    setCodeDiscountPrice(`${parseFloat(percentAmount.toFixed(2))}`);
                  }
                  if (duration && duration === 'repeating') {
                    setCodeMessage({
                      message: `${percent_off}% off next ${duration_in_months} billing cycles.`,
                      approveType: true,
                    });
                    setCodeDiscountPrice(`${parseFloat(percentAmount.toFixed(2))}`);
                  }
                  if (duration && duration === 'forever') {
                    setCodeMessage({
                      message: `${percent_off}% off`,
                      approveType: true,
                    });
                    setCodeDiscountPrice(`${parseFloat(percentAmount.toFixed(2))}`);
                  }
                  setCodePromoId(id);
                }
              }
            } else {
              setCodeMessage({
                message: 'This code is invalid',
                approveType: false,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              const { data } = error.response;
              setCodeMessage(false);
              setCodeLoading(false);
              console.log('error', data);
            }
          });
    } else {
      setCodeMessage({ message: 'This code is invalid', approveType: false });
    }
  };

  const onCodeRemove = () => {
    setCodeParameters(false);
    setCode('');
    setCodeDiscountPrice(0);
    setCodeMessage(false);
  };

  const totalAfterTrial = parseFloat(displayPrice) - parseFloat(codeDiscountPrice);
  return (
    <StripeCustomization>
      <Card bordered={false} className="stripe-card">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={16}>
            <div className="stripe-email-textbox">
              <Text>Email</Text>
              <Text className="stripe-email">{email}</Text>
            </div>
            <Form
              form={form}
              layout="vertical"
              id="payment-form"
              initialValues={{ email, country: 'US' }}
              scrollToFirstError
            >
              <Row gutter={16}>
                <Col xs={24}>
                  <Form.Item
                    name="name"
                    label="Name on card"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your name.',
                      },
                    ]}
                  >
                    <Input className="payment-card-name" placeholder="Enter your name" />
                  </Form.Item>
                </Col>
              </Row>

              <div className="billing-title">
                <Text className="text">Add your card</Text>
              </div>
              <div className="billing-wrapper">
                <Row gutter={16}>
                  <Col xs={24}>
                    <Form.Item required help={cardNumberError} validateStatus={cardNumberError && 'error'}>
                      <CardNumberElement
                        options={cardStyle}
                        className="card-element"
                        id="card-element"
                        onChange={onCardDetailsChange}
                        name="cardNumber"
                        placeholder="Card number"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider className="billing-divider" />
                <Row gutter={16}>
                  <Col xs={12} sm={12} md={14}>
                    <Form.Item required help={cardExpiryError} validateStatus={cardExpiryError && 'error'}>
                      <CardExpiryElement
                        onChange={onCardDetailsChange}
                        options={cardStyle}
                        className="card-element"
                        id="card-element"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={10}>
                    <Form.Item required help={cardCvcError} validateStatus={cardCvcError && 'error'}>
                      <CardCvcElement
                        onChange={onCardDetailsChange}
                        options={cardStyle}
                        className="card-element cvc-element"
                        id="card-element"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="stripe-lock">
                <img src={require(`../../../../static/onboarding/stripe-lock.png`)} alt="lock" />
                <Text className="stripe-payment-text">This payment is being securely processed by Stripe</Text>
              </div>

              <div className="billing-title">
                <Text className="text"> Billing address</Text>
              </div>
              <div className="billing-wrapper">
                <Row gutter={16}>
                  <Col xs={24}>
                    <Form.Item
                      className="select-country"
                      name="country"
                      rules={[
                        {
                          required: true,
                          message: 'Please select country.',
                        },
                      ]}
                      label=""
                      required
                    >
                      <Select
                        bordered={false}
                        showSearch
                        defaultValue={'US'}
                        placeholder="Country"
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {countries && countries.map(({ name, code }) => <Option key={code}>{name}</Option>)}
                      </Select>
                    </Form.Item>
                    <Divider className="billing-divider" />
                    <Form.Item
                      className="address"
                      name={'address'}
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your address.',
                        },
                      ]}
                      required
                    >
                      <Input bordered={false} placeholder="Address" />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className="selected-plan">
              <div className="text">
                Plan Type{' '}
                <Tooltip
                  title="Your billing cycle will begin after your free trial. The displayed amount will become a recurring
                  payment unless changed in settings. Need assistance? Send us a message!"
                >
                  <img src={require('../../../../static/trial/notice.svg')} alt="" />
                </Tooltip>
              </div>
              <div className="plan-type">
                <div>
                  <Text className="details"> {capitalizeFirstLetter(planSelect)}</Text>
                  {showPlanDuration === 'year' && <Text className="two-months-free"> 2 Months Free</Text>}
                </div>
                <Text className="details">
                  <Text> 5 days free</Text>
                  {/* <br />
                  <Text strong> ${displayPrice?.toFixed(2)}</Text>/{showPlanDuration} after */}
                </Text>
              </div>

              {/*  <br />
              <div className="plan-type">
                <Text className="details"> Subtotal</Text>
                <Text className="details">
                  <Text strong> ${displayPrice?.toFixed(2)}</Text>
                </Text>
              </div> */}
              <Divider className="divider-details" />
              <div className="text">Discount Code</div>
              <div className="plan-type">
                {codeParameters ? (
                  <div className="code-correct">
                    <Tag className="code-tag" closable onClose={onCodeRemove}>
                      <TagOutlined />
                      <Text className="tag-text"> {code}</Text>
                    </Tag>
                    <div>
                      <Text> -${codeDiscountPrice}</Text>
                    </div>
                  </div>
                ) : (
                  <>
                    <Input
                      placeholder="Input code"
                      className="code-input"
                      value={code}
                      onChange={(e) => {
                        setCodeMessage(false);
                        setCode(e.target.value);
                      }}
                    />
                    <Button
                      disabled={code.length === 0 ? true : false}
                      onClick={() => onCodeClick()}
                      transparentwithoutline
                      type={BUTTON_PRIMARY}
                      loading={codeLoading}
                    >
                      Use Code
                    </Button>{' '}
                  </>
                )}
              </div>
              {codeMessage && (
                <Text type={`${codeMessage.approveType === true ? 'success' : 'danger'}`}>{codeMessage.message}</Text>
              )}
              <Divider className="divider-details" />
              <div className="text">
                Credits <img src={require('../../../../static/trial/notice.svg')} alt="" />
              </div>
              <div className="plan-type">
                <Text className="details">
                  {showWords()} words/{showPlanDuration}
                </Text>
              </div>

              <Divider className="divider-details" />
              <div className="plan-type">
                <Text className="text">Total after trial</Text>
                <Text className="details">
                  ${totalAfterTrial.toFixed(2)}/{showPlanDuration}
                </Text>
              </div>
              <div className="plan-type">
                <Text className="text">Total due today</Text>
                <Text className="details">$0/{showPlanDuration}</Text>
              </div>
              <Divider className="colorful" />
              <Text className="trial-payment-text"> Payment will be automatically withdrawn after trial ends</Text>
              {error && <div className="card-error">{error}</div>}

              <div className="trial-continue">
                <Button
                  block
                  size="large"
                  type="primary"
                  loading={processing}
                  disabled={codeLoading}
                  className="pay-button"
                  onClick={onPressTrialButton}
                >
                  {buttonText}
                  <FeatherIcon style={{ marginLeft: '1rem' }} icon="arrow-right" size={18} />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Card>

      {contextHolder}
    </StripeCustomization>
  );
};

export default StripeElement;

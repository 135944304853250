import styled from 'styled-components';

const HeaderNewDesignStyle = styled.div`
  display: block;

  .header-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px !important;
    padding: 0px 14px 0px;
    @media (max-width: 768px) {
      // gap: 30px !important;
      padding-bottom: 10px;
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
    }
    .page-title {
      font-size: 28px;
      font-family: 'Poppins-SemiBold';
    }
    figure {
      margin-bottom: 0px;
      display: flex;
      gap: 3.35px;
      align-items: center;
      .flag-img {
        border-radius: 50%;
        @media (min-width: 768px) {
          display: none;
        }
      }

      @media (max-width: 768px) {
        font-size: 2px;
      }
    }
  }
`;

const NewHeaderMain = styled.div`
  border-radius: 10px;

  .banner-cta .banner-cta__content .action-buttons .language-button-wrapper .language-button .lang-text {
    color: ${({ theme }) => theme['color-black']} !important;
  }

  .ant-row {
    margin-right: 0px !important;
    margin-left: 0px !important;
    .ant-col-md-24 {
      padding-right: 0px !important;
      padding-left: 0px !important;
    }
  }
  .standard-breadcum {
    font-weight: 500;
    font-size: 14px;
    font-family: 'Poppins-Medium';
    color: #282b3f;
    cursor: pointer;
  }

  .banner-cta {
    min-height: 102px;
    justify-content: flex-start;
    background-color: ${({ theme }) => theme['primary-color']};
    border-radius: 10px;
    background-color: #fff;
    min-height: 0px !important;

    .banner-cta__content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 18px 0px 18px 0px;
      border-bottom: 1.5px solid #eeeeee;

      @media only screen and (max-width: 575px) {
        flex-direction: column;
        padding: 0px;
      }
      @media (max-width: 768px) {
        border: none;
        display: flex;
        flex-direction: column;
        padding: 0px;
      }
      color: ${({ theme }) => theme['color-white']};
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;
export { HeaderNewDesignStyle, NewHeaderMain };

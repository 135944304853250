/* ------------------------------------------------------ */
/*                      Message Alert                     */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * This component is the antd messages component.  https://ant.design/components/message
 * it is used to display notifications
 *
 * The way of calling this component is changed in antd v5 version. but we are still using the way of v4 version
 */

import { message } from 'antd';

export const messageAlert = ({
  type,
  textMessage,
}: {
  type: 'info' | 'success' | 'error' | 'loading' | 'warn' | 'warning',
  textMessage: string,
}) => {
  message[type](textMessage);
};

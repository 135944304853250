/* ------------------------------------------------------ */
/*                        DOCUMENT                        */
/* ------------------------------------------------------ */

/**
 *
 * This document is linked to the long-form / Blog Builder feature.
 * Any initiate in BlogBuilder/longForm will be displayed here.
 *
 * The process in the long form
 *
 * There are 2 type of docs
 * 1. Editor - complete
 * 2. Blog post - incomplete
 *
 * Documents UI is only avaible to the CRAFTER or enterprise or team members or team plan users
 * Those users who are Free user or admin role can also access documents
 *
 * Firestore:
 * Collection: long-form-documents, Document: uid
 * SubCollection: documents
 * SubDocument Document ID
 */

import React, { useEffect, useState } from 'react';
import { Space, Typography, Table, Tag, Empty, Result } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { deleteDocument, getDocumentList } from '../../redux/features/actionCreator';
import moment from 'moment/moment';
import { dateFormatWithTime } from '../../utility/utility';
import deleteIcon from '../../static/longForm/Delete.svg';
import { ReactSVG } from 'react-svg';
import { DocumentStyled } from './styled';
import { CRAFTER_ACCESS, ENTERPRISE_ACCESS, isAdminRole, isFreeUserRole, STARTER_ACCESS } from '../../constants';
import PageHeader from '../../components/PageHeader';
import { BLOG_BUILDER, EDITOR, GO_TO_BLOG_POST, GO_TO_TEXT_EDITOR } from '../../constants/content';

const { Text } = Typography;

const Documents = () => {
  const { uid } = useSelector((state) => state.fb.auth);
  const { plan, roles } = useSelector((state) => state.fb.profile);
  const { documentList, documentListLoading } = useSelector((state) => state.features);
  const [documentListData, setDocumentListData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { memberTeamsWithOutHold } = useSelector((state) => state.accountSettings);

  useEffect(() => {
    if (uid) {
      // to fetch the latest documents of the user
      dispatch(getDocumentList(uid));
    }
  }, [uid]);

  // on table click it redirect to specific route
  const onTitleClick = (id, type) => {
    if (type === 'blogPost') {
      navigate(`/long-form/?documentId=${id}`);
    } else if (type === 'editor') {
      navigate(`/editor/?blogPostId=${id}`);
    }
  };

  // there are 2 type of documents
  const displayDocumentType = (documentType) => {
    if (documentType === EDITOR) {
      return GO_TO_TEXT_EDITOR;
    }
    if (documentType === BLOG_BUILDER) {
      return GO_TO_BLOG_POST;
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <Text>{text}</Text>,
    },
    {
      title: 'Document Type',
      key: 'documentType',
      dataIndex: 'documentType',
      render: (_, { documentType, type, id }) => {
        let color = '#0000ff8f';
        if (type === 'blogPost') {
          color = 'rgba(108, 90, 251, 0.74)';
        }
        return (
          <Tag color={color} key={id}>
            {documentType.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { id, type, documentType }) => (
        <Space size="middle">
          <Text className={`document-${documentType}`} onClick={() => onTitleClick(id, type)}>
            {displayDocumentType(documentType)}
          </Text>
        </Space>
      ),
    },
    {
      title: 'Last Modified',
      dataIndex: 'date',
      key: 'date',
      render: (date) => <Text>{moment(date.toDate()).format(dateFormatWithTime)}</Text>,
    },
    {
      title: 'Delete',
      dataIndex: 'date',
      key: 'date',
      render: (_, { id }) => (
        <Space size="middle">
          <ReactSVG
            onClick={() => dispatch(deleteDocument({ uid, documentId: id }))}
            className="delete-icon"
            src={deleteIcon}
          />
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const list = documentList.map(({ date, title, id, editor }) => {
      const documentType = editor ? EDITOR : BLOG_BUILDER;
      const type = editor ? 'editor' : 'blogPost';
      return {
        key: id,
        id,
        title,
        documentType: documentType,
        type,
        date,
      };
    });
    setDocumentListData(list);
  }, [documentList]);

  /* Documents UI is only avaible to the CRAFTER or eterprices or team members or team plan users  */
  // those users who are Free user or admin role can also access documents
  const allowAccess =
    CRAFTER_ACCESS.includes(plan) ||
    ENTERPRISE_ACCESS.includes(plan) ||
    isAdminRole(roles) ||
    isFreeUserRole(roles) ||
    (memberTeamsWithOutHold && memberTeamsWithOutHold.length > 0)
      ? true
      : STARTER_ACCESS.includes(plan)
      ? false
      : false;

  return (
    <>
      <PageHeader hideTranslation={true} title="Documents" />

      <DocumentStyled>
        {allowAccess && (
          <>
            {documentList.length > 0 && (
              <Table columns={columns} dataSource={documentListData} loading={documentListLoading} />
            )}
            {documentList.length === 0 && (
              <Empty description={<span>Getting started by creating a new document</span>}>
                <Button onClick={() => navigate(`/long-form`)} type="primary">
                  New Document
                </Button>
              </Empty>
            )}
          </>
        )}

        {!allowAccess && (
          <Result
            status="403"
            title="Limited Access"
            subTitle="Sorry, you are not authorized to access this page."
            extra={
              <Button type="primary" onClick={() => navigate('/settings/billing')}>
                Upgrade Now
              </Button>
            }
          />
        )}
      </DocumentStyled>
    </>
  );
};

export default Documents;

/* ------------------------------------------------------ */
/*                       User Avatar                      */
/* ------------------------------------------------------ */

/**
 * Methadology
 * This component is used in the App Header.
 * On the right side of the website, there is an avatar. The settings and billing link appear when you click the avatar, which also opens the menu.
 * Additionally to a signout button.
 *
 * On a desktop view there is Notification icon, Earn button, setting icon and information icon.
 * On a mobile view, only avatar is visible.
 *
 * The picture in the avatar is usually the user google account image.
 *
 * Used In
 * src/layout/new-designs/header/AppHeader.jsx
 */

import React from 'react';
import { Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { InfoStyled, AvatarPopoverStyled } from './styled';
import Help from '../Help';
import { Popover } from '../Popover';
import { Button } from '../Button';
import { logOut } from '../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../redux/firebase/auth/actionCreator';
import NotificationList from '../NotificationList';
import GeneralUserIcon from '../../static/general/general-user.png';
import { DollarOutlined, SettingOutlined } from '@ant-design/icons';

/**
 * @boolean isMobile: Only the avatar is viewable on mobile. The remaining icons as well as the Earn button will be hidden.
 */
const UserAvatar = ({ isMobile }) => {
  const dispatch = useDispatch();

  // check whether the person is already logout or now
  const { isLogout } = useSelector((state) => {
    return {
      isLogout: state.fb.auth.uid,
    };
  });

  // picture is taking from the firebase google account.
  const { picture } = useSelector((state) => state.fb.profile);

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(fbAuthLogout());
  };

  if (!isLogout) dispatch(logOut());

  // this UI is the Popover content
  const userContent = (
    <AvatarPopoverStyled>
      <div className="user-dropdwon">
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/settings/edit-profile">
              <SettingOutlined style={{ fontSize: '1rem' }} />
              Settings
            </Link>
          </li>
          <li>
            <Link to="/settings/billing">
              <DollarOutlined style={{ fontSize: '1rem' }} />
              Billing
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </AvatarPopoverStyled>
  );

  // Top Header right side page navigations with links
  return (
    <InfoStyled>
      {/* This component is viewable for desktop only. */}
      {!isMobile && (
        <>
          <NotificationList />
          <div className="support">
            <Link to="/earn" className="link-style">
              <Button type="primary">Earn </Button>
            </Link>
          </div>
          <div className="support">
            <Link to="/settings" className="link-style">
              <FeatherIcon icon="settings" size={20} />
            </Link>
          </div>
          <Help />
        </>
      )}
      {/* Avatar  */}
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} trigger="click">
          <Link to="#" className="link-style">
            <Avatar src={picture || GeneralUserIcon} />
          </Link>
        </Popover>
      </div>
    </InfoStyled>
  );
};

export default UserAvatar;

/* ------------------------------------------------------ */
/*                  Edit Profile Settings                 */
/* ------------------------------------------------------ */

/**
 * Add First Name, Last Name , website
 *
 * Firestore
 * Collection: users
 *
 */

import React from 'react';
import { Col, Form, Input, Row, Typography, Space } from 'antd';
import { SettingsWrapStyled } from './styled';
import { Helmet } from 'react-helmet';
import { Button } from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { changeProfileDetails } from '../../../redux/AccountSettings/actionCreator';
import { PAGETILE } from '../../../constants/content';

const EditProfileSettings = () => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { lastName, firstName, website: saveWebsite } = useSelector((state) => state.fb.profile);

  const onFinish = (values) => {
    const trimFirstName = values.firstName.replace(/\s+/g, ' ');
    const trimLastName = values.lastName.replace(/\s+/g, ' ');
    const newValues = {
      ...values,
      firstName: trimFirstName,
      lastName: trimLastName,
      name: `${trimFirstName} ${trimLastName}`,
    };
    // this is a generic user profile update reducer
    dispatch(changeProfileDetails(newValues, true));
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    form.resetFields();
  };
  return (
    <>
      <Helmet>
        <title>{PAGETILE('Profile')}</title>
      </Helmet>
      <SettingsWrapStyled>
        <Title>Edit Profile</Title>
        <Text className="profile">Manage your profile here</Text>
        <Form
          form={form}
          name="editProfile"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            firstName,
            lastName,
            website: saveWebsite || '',
          }}
        >
          <Row gutter={24}>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[{ required: true, message: 'Please input your first name!', whitespace: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} md={12} xs={24}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[{ required: true, message: 'Please input your last name!', whitespace: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {/* <Text>We’re big on real names around here, so people know who’s who.</Text>
          <Form.Item label="Number">
            <Input placeholder="123-456-7890" />
          </Form.Item>
          {window.innerWidth > 768 && <Text>This is to authenticate that you’re a real person.</Text>} */}

          <Form.Item name="website" label="Website">
            <Input />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button size="large" onClick={handleCancel} type="light">
                Cancel
              </Button>
              <Button type="primary" size={'large'} htmlType="submit">
                Save Changes
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </SettingsWrapStyled>
    </>
  );
};

export default EditProfileSettings;

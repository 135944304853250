/* eslint-disable react-hooks/exhaustive-deps */
/* ------------------------------------------------------ */
/*                    LIST OF ALL USERS                   */
/* ------------------------------------------------------ */

/**
 * Display all the users in a table form and on clicking on the actions it redirect to the user detail page.
 *
 * list of users data is fetch from bigQuery and it is fetched in mechanism of pagination.
 *
 * Current plan details is visible when user subscripbe to a plan
 *
 */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Row, Col } from 'antd';
import { UserTableStyleWrapper } from '../styled';
import { TableWrapper } from '../../styled';
import { Button } from '../../../components/Button';
import { Cards } from '../../../components/Card';
import { dateFormat } from '../../../utility/utility';
import { ArrowUpOutlined } from '@ant-design/icons';
import {
  changeUserProfileBulk,
  deleteUserByAdmin,
  searchInputUsers,
  getFilteredUsers,
  saveUIDForFetchingUserDetail,
} from '../../../redux/AdminFeatures/actionCreator';
import { usersColumns } from './UserColumn';
import { ADMIN_DELETE, PAID_PLAN, PERMANENT_DELETE, STATUS } from '../../../constants';
import ActivePlan from './ActivePlan';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import ExportExcel from '../../../components/Exports';
import { setMirrorTeamUID } from '../../../redux/team/actionCreator';

/**
 * @bulkStatus contains the row details
 * @selectedRowsCount When the user selects multiple rows the Update Status options appear below the filters
 * @searchValue search value and when search is enable filters disable
 * @searchParameter uid | email | name
 *
 */
const UserList = ({ bulkStatus, selectedRowsCount, searchValue, searchParameter }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [displayTableData, setDisplayTableData] = useState([]);
  const [allowDelete, setAllowDelete] = useState(false);
  const [paginationCurrent, setPaginationCurrent] = useState(1);
  const { name } = useSelector((state) => {
    return {
      name: state.fb.profile.name,
    };
  });

  const {
    filteredUsers,
    filteredUsersLoading,
    changeProfileLoading,
    searchUsers,
    userDeleteByAdminLoading,
    userManagmentFilters,
    totalUsersRows,
    userManagementRowsLimit,
  } = useSelector((state) => state.adminFeatures);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Bullk actions by admin
  useEffect(() => {
    if (bulkStatus === STATUS.DELETED) {
      selectedRows.map(async (userUID) => {
        await dispatch(deleteUserByAdmin({ userUID, permanentDelete: false, deletedBy: `${ADMIN_DELETE} (${name})` }));
      });
    } else if (bulkStatus === PERMANENT_DELETE) {
      selectedRows.map(async (userUID) => {
        await dispatch(deleteUserByAdmin({ userUID, permanentDelete: true, deletedBy: `${ADMIN_DELETE} (${name})` }));
      });
    } else {
      selectedRows.length > 0 &&
        dispatch(changeUserProfileBulk({ userData: { status: bulkStatus }, uids: selectedRows }));
    }
  }, [bulkStatus]);

  useEffect(() => {
    selectedRowsCount(selectedRows.length);
  }, [selectedRows]);

  // search user feature
  useEffect(() => {
    if (!userDeleteByAdminLoading && allowDelete) {
      setSelectedRows([]);
      selectedRowsCount(selectedRows.length);
      searchValue &&
        searchValue.length > 0 &&
        dispatch(searchInputUsers({ inputSearchText: searchValue, inputSearchParameter: searchParameter }));
      dispatch(getFilteredUsers({ userManagmentFilters }));
      setAllowDelete(false);
    }
  }, [userDeleteByAdminLoading]);

  const onEditClick = (selectedUser) => {
    // when user click on the row column ACTIONS, the specific profile is called.
    dispatch(saveUIDForFetchingUserDetail(selectedUser.uid));
    navigate('/user-details');
  };

  // populate the table with user data .
  // either the data is for search-users or filtered-users
  useEffect(() => {
    const usersTableData = [];
    const currentData = searchValue.length > 0 ? searchUsers : filteredUsers;

    currentData &&
      currentData.map((user) => {
        const {
          uid,
          name,
          email,
          firstName,
          lastName,
          roles,
          plan,
          displayPlanStatus,
          status,
          acceptInvite,
          planStatus,
          activatedPlanDetails,
          companyName,
          referralLink,
          createdDate,
          teamEnable,
          noTrialccPlanAcitivated,
          currentPlanCraftedWords,
          totalPricePerRun,
          lifeTimeTotalPricePerRun,
          lifeTimeHits,
          lifeTimeCrafted,
          lastCraftDate,
          todaysHits,
          currentPlanTotalHits,
        } = user;

        return usersTableData.push({
          key: uid,
          name,
          email,
          firstName,
          lastName,
          roles,
          plan,
          displayPlanStatus,
          status,
          acceptInvite,
          planStatus,
          activatedPlanDetails,
          companyName,
          referralLink,
          createdDate: createdDate ? moment(createdDate).format(dateFormat) : '',
          teamEnable,
          noTrialccPlanAcitivated,
          wordsStatictics: {
            currentPlanCraftedWords,
            totalPricePerRun,
            lifeTimeTotalPricePerRun,
            lifeTimeHits,
            lifeTimeCrafted,
            lastCraftDate,
            todaysHits,
            currentPlanTotalHits,
          },

          action: (
            // onEdit button, these values are passed to Edit Modal.
            <Button
              onClick={() =>
                onEditClick({
                  uid,
                  name,
                })
              }
              className="btn-icon"
            >
              <ArrowUpOutlined style={{ transform: 'rotate(30deg)', fontSize: '20px' }} />
            </Button>
          ),
        });
      });
    setDisplayTableData(usersTableData);
  }, [searchUsers, filteredUsers, searchValue]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };

  const onPaginationChange = (page) => {
    setPaginationCurrent(page);
    if (searchValue && searchValue.length > 0) {
      dispatch(searchInputUsers({ inputSearchText: searchValue, inputSearchParameter: searchParameter, page }));
    } else {
      dispatch(getFilteredUsers({ userManagmentFilters, page }));
    }
  };

  const excelColumns = [
    { header: 'UID', key: 'key' },
    { header: 'Name', key: 'name' },
    { header: 'Email', key: 'email' },
    { header: 'Roles', key: 'roles' },
    { header: 'Plan', key: 'plan' },
    { header: 'Status', key: 'status' },
    { header: 'Words', key: 'words' },
    { header: 'Hits', key: 'hits' },
    { header: 'Created Date', key: 'createdDate' },
  ];

  // excel data
  const exportExcelData = displayTableData.map(
    ({ key, email, name, plan, displayPlanStatus, createdDate, wordsStatictics, roles }) => {
      const words =
        typeof wordsStatictics.currentPlanCraftedWords === 'string'
          ? parseInt(wordsStatictics.currentPlanCraftedWords)
          : wordsStatictics.currentPlanCraftedWords;
      const hits =
        typeof wordsStatictics.currentPlanCraftedWords === 'string'
          ? parseInt(wordsStatictics.currentPlanTotalHits)
          : wordsStatictics.currentPlanCraftedWords;
      return {
        key,
        name,
        email,
        roles,
        plan,
        status: displayPlanStatus,
        words,
        hits,
        createdDate,
      };
    },
  );

  const onteamClick = (teamID) => {
    dispatch(setMirrorTeamUID(teamID));
    navigate('/team-portal');
  };

  return (
    <>
      <ExportExcel
        exportBtnLoading={changeProfileLoading || filteredUsersLoading}
        data={exportExcelData}
        columns={excelColumns}
      />
      <Cards headless>
        <UserTableStyleWrapper>
          <TableWrapper className="table-responsive">
            <Table
              size="small"
              loading={changeProfileLoading || filteredUsersLoading}
              rowSelection={rowSelection}
              dataSource={displayTableData}
              columns={usersColumns(onteamClick)}
              pagination={{
                defaultPageSize: userManagementRowsLimit,
                pageSizeOptions: [userManagementRowsLimit],
                total: totalUsersRows,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                onChange: onPaginationChange,
                current: paginationCurrent,
              }}
              expandable={{
                // this shows the plan details
                expandedRowRender: (record) => {
                  return (
                    <div style={{ marginLeft: '2.5rem' }}>
                      <Row gutter={16}>
                        {record.activatedPlanDetails && (
                          <Col xs={24} sm={24} md={24}>
                            <ActivePlan
                              activatedPlanDetails={record.activatedPlanDetails}
                              stripeLink={record.stripeLink}
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  );
                },
                rowExpandable: (record) => PAID_PLAN.includes(record.plan) || record.words > 0, // visible if user have a plan
              }}
            />
          </TableWrapper>
        </UserTableStyleWrapper>
      </Cards>
    </>
  );
};

export default UserList;

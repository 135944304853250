/* ------------------------------------------------------ */
/*                   WorkspaceSelection                   */
/* ------------------------------------------------------ */

/**
 *
 * When a user switch to team plan, a new Team workspace created. A team admin can use tool craft on both workspaces.
 * A team member also have multiple workspaces.
 * if a team member use personal workspace then all the tool outputs will be store in personal database.
 *
 * selection of workspace UI  is only visible when the plan is team or team member is a part of any team
 *
 * Firestore
 * Collection: workspaces
 * Document: <UID>
 * it store 2 things
 * 1. Default Workspace  - either personal or team
 * 2. team workspace - along with projectId and projectName
 *
 * Selection of any workspace, it stores as the Recent or Default workspace for the user.
 * if a user refresh the screen the default workspace will be selected automatically
 *
 * In team workspace the objects are
 *  project
 *  projectId
 *  teamId
 *  teamName
 *
 * Default worskspace objects are
 *  selectedName
 *  selectedUID
 *  workspaceType
 *
 * Personal Workspace name is the user name itself
 *
 */

import React from 'react';
import { Typography, Row, Col, Avatar, Spin } from 'antd';
import { TeamSelectionWrapper } from './styled';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedWorkSpace } from '../../redux/AccountSettings/actionCreator';
import { capitalizeFirstLetter, firstTwoLetters } from '../../utility/utility';
import { PERSONAL_WORKSPACE, TEAM, TEAM_STATUS, TEAM_WORKSPACE } from '../../constants';
import { CheckOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import Swap from '../../static/sidebar/Swap.svg';
import { Popover } from '../../components/Popover';

const { Text, Paragraph } = Typography;

const WorkspaceSelection = ({ settingsView }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { usersTeams, usersTeamsLoading, selectedWorkspace } = useSelector((state) => state.accountSettings);
  const { email, plan, name: userName_ByHimSelf } = useSelector((state) => state.fb.profile);
  const { uid } = useSelector((state) => state.fb.auth);

  const onSelectWorkspace = ({ selectedUID, selectedName, workspaceType }) => {
    dispatch(setSelectedWorkSpace({ selectedUID, selectedName, workspaceType }));
    navigate('/');
  };

  const title = <Text className="popover-title"> {email}</Text>;

  const showWorkspace = plan === TEAM || (usersTeams && usersTeams.length > 0);
  const content = (
    <>
      {showWorkspace &&
        usersTeams &&
        usersTeams
          .filter(
            ({ teamInviteStatus }) =>
              teamInviteStatus === TEAM_STATUS.ACCEPTED ||
              teamInviteStatus === TEAM_STATUS.HOLD ||
              teamInviteStatus === TEAM_STATUS.ACTIVE,
          )
          .map(({ adminName, teamName, adminUID }, index) => {
            return (
              <Row
                justify="space-between"
                align="middle"
                className="team-list"
                onClick={() =>
                  onSelectWorkspace({
                    selectedUID: adminUID,
                    selectedName: teamName,
                    workspaceType: TEAM_WORKSPACE,
                  })
                }
                key={index}
              >
                <Col className="selected-team">
                  <div className="team-image">
                    <Avatar gap={10} size={34} style={{ color: '#ffff', backgroundColor: '#9D79FD' }}>
                      {firstTwoLetters(teamName)}
                    </Avatar>
                  </div>
                  <div className="team-name">
                    <Text className="name" ellipsis={2}>
                      {capitalizeFirstLetter(teamName)}
                    </Text>
                    <div>
                      <Text className="selectionType">{TEAM_WORKSPACE}</Text>
                    </div>
                  </div>
                  {selectedWorkspace &&
                    selectedWorkspace.selectedUID === adminUID &&
                    selectedWorkspace.workspaceType === TEAM_WORKSPACE && (
                      <div>
                        <CheckOutlined style={{ fontSize: '16px', color: '#9D79FD' }} />
                      </div>
                    )}
                </Col>
              </Row>
            );
          })}

      {/* Display personal workpsace.  */}
      <Row
        justify="space-between"
        align="middle"
        className="team-list"
        onClick={() =>
          onSelectWorkspace({
            selectedUID: uid,
            selectedName: userName_ByHimSelf,
            workspaceType: PERSONAL_WORKSPACE,
          })
        }
        key={'personal'}
      >
        <Col className="selected-team">
          <div className="team-image">
            <Avatar gap={10} size={34} style={{ color: '#ffff', backgroundColor: '#9D79FD' }}>
              {firstTwoLetters(selectedWorkspace && selectedWorkspace.selectedName)}
            </Avatar>
          </div>
          <div className="team-name">
            <Text className="name">{capitalizeFirstLetter(userName_ByHimSelf)}</Text>
            <div>
              <Text className="selectionType">{PERSONAL_WORKSPACE}</Text>
            </div>
          </div>
          {selectedWorkspace &&
            selectedWorkspace.selectedUID === uid &&
            selectedWorkspace.workspaceType === PERSONAL_WORKSPACE && (
              <div>
                <CheckOutlined style={{ fontSize: '16px', color: '#9D79FD' }} />
              </div>
            )}
        </Col>
      </Row>
      {/* )} */}
    </>
  );

  const onWorkspaceClick = () => {
    navigate('/settings/workspace');
  };
  return (
    <>
      {showWorkspace && (
        <TeamSelectionWrapper settingsView={settingsView}>
          {!settingsView && (
            <Popover
              overlayClassName="show-teams-sidebar"
              placement="bottom"
              title={title}
              content={content}
              trigger="hover"
            >
              <Spin spinning={usersTeamsLoading} onClick={onWorkspaceClick}>
                <Row justify="space-between" align="middle">
                  <Col className="selected-team">
                    <div>
                      <Avatar size={30} gap={8} style={{ color: '#ffff', backgroundColor: '#9D79FD' }}>
                        {firstTwoLetters(selectedWorkspace && selectedWorkspace.selectedName)}
                      </Avatar>
                    </div>

                    <div className="team-name">
                      <div className="name">
                        {selectedWorkspace && capitalizeFirstLetter(selectedWorkspace.selectedName)}
                      </div>
                      <Paragraph className="selectionType">
                        {selectedWorkspace && selectedWorkspace.workspaceType}
                      </Paragraph>
                    </div>
                  </Col>
                  <Col>
                    <img src={Swap} className="dropdown" alt="" />
                  </Col>
                </Row>
              </Spin>
            </Popover>
          )}
          {settingsView && content}
        </TeamSelectionWrapper>
      )}
    </>
  );
};

export default WorkspaceSelection;

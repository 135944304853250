import Styled from 'styled-components';

const InviteWrapper = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 90vh;
  text-align: center;
  img{
    max-width: 400px;
    width: 100%;
    @media only screen and (max-width: 575px){
      margin-bottom: 30px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  p{
    color: ${({ theme }) => theme['gray-color']};
  }
    .verify-logout {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 1rem 2rem;
    }
  }
  .email-verify{
    width: 50%
  }
`;
export { InviteWrapper };

import { Card, Col, Input, Row, Typography } from 'antd';
import React from 'react';
import { ShareLinkWrap } from './styled';

import { Button } from '../../../components/Button';
import { IconImagesData, ShareLinkData } from './mock';

const ShareLink = () => {
  const { Text, Title } = Typography;

  return (
    <ShareLinkWrap>
      <div className="partA">
        <div>
          <Title level={4}>Share your link</Title>
          <Text>For every singup that sticks, you recive two weeks free of Craftly creations (plus bonuses)!</Text>
        </div>
        <Input placeholder="https://app.craftly.ai/sigup?via=iman" />
        <div className="icon-images">
          {IconImagesData.map((item) => (
            <img src={item.src} className={item.classname} alt={item.alt} />
          ))}
        </div>
      </div>
      <div className="partB">
        <Text>
          Mention our product in any way you see fit, and share your referral link and our promotional materials.{' '}
        </Text>
        <Row>
          {ShareLinkData.map((item) => (
            <Col span={8}>
              <Card>
                <Text>{item.text}</Text>
                <Text>{item.number}</Text>
              </Card>
            </Col>
          ))}
        </Row>
        <Button type="primary" size="small">
          Generate a review
        </Button>
      </div>
    </ShareLinkWrap>
  );
};

export default ShareLink;

/* ------------------------------------------------------ */
/*                       Deactivate                       */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * The user is no longer able to redirect to any page when a Craftly admin deactivates the user from the user management user profile.
 * The user will only be able to see this page.
 * To leave the account, click the Signout option.
 */

import React from 'react';
import { DeactivateWrapper } from './styled';
import { Row, Col, Typography } from 'antd';
import Signout from '../../components/Signout';

const { Text, Title, Link } = Typography;

const Deactivate = () => {
  return (
    <DeactivateWrapper>
      <Row justify="space-between">
        <Col xs={6} sm={3} md={2}>
          <img
            className="logo"
            style={{ width: '100%', margin: '2rem' }}
            src={require('../../static/general/craftly_final.png')}
            alt="logo"
          />
        </Col>
        <Col>
          <div style={{ margin: '2rem' }}>
            <Signout />
          </div>
        </Col>
      </Row>

      <div className="box-wrapper">
        <div className="verify-box">
          <Title level={2} className="early-text">
            Oops, Your account is deactivated by Craftly Administration.
          </Title>
          <br />
          <Text className="text-notice">
            Please contact our administration at <Link>support@craftly.ai</Link>
          </Text>
        </div>
      </div>
    </DeactivateWrapper>
  );
};

export default Deactivate;

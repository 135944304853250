/* ------------------------------------------------------ */
/*                USER CURRENT ACTIVE PLAN                */
/* ------------------------------------------------------ */

/* ----------------- CRAFTLY ADMIN PAGE ----------------- */

/**
 * This is visible in the users table as a expandable button.
 *
 * It shows the user current plan detials. such as
 * 1. product name
 * 2. status
 * 3. interval
 * 4. Period start
 * 5. Next cycle
 * 6. subscriptlink link
 * 7. Stripe profile link
 *
 *
 * isCanceled: a user has cancelled the plan and now the plan is in notice period. a plan will be fully cancelled at the period or duration ends
 */
import React from 'react';
import { Descriptions, Typography } from 'antd';
import { Cards } from '../../../components/Card';
import { PLAN_STATUS } from '../../../constants';
import { changeTrailingStatus } from '../../../utility/userProperties';

const { Title, Text, Link } = Typography;

/**
 * @activatedPlanDetails - it contains all the plan related details
 * @stripeLink - stripe customer link
 */
const ActivePlan = ({ activatedPlanDetails, stripeLink }) => {
  const {
    expiredDate,
    expiringDate,
    isCanceled,
    periodEnd,
    periodStart,
    planInterval,
    productName,
    showStartEndDate,
    status,
    subscriptionLink,
  } = activatedPlanDetails;

  return (
    <>
      <Title level={5}> Active Plan</Title>
      <Cards headless bodypadding={'0px'}>
        <Descriptions
          size="small"
          bordered
          // layout="vertical"
          // column={{ xxl: 5, xl: 4, lg: 3, md: 3, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            style={{ background: 'whitesmoke' }}
            label={
              <Text type="primary" strong underline>
                Product Name{' '}
              </Text>
            }
          >
            {productName}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Text type="danger" strong underline>
                Status{' '}
              </Text>
            }
          >
            {changeTrailingStatus({ status, isCanceled })}
          </Descriptions.Item>
          {status !== PLAN_STATUS.TRIAL && (
            <Descriptions.Item
              label={
                <Text type="primary" strong underline>
                  Interval{' '}
                </Text>
              }
            >
              {planInterval}
            </Descriptions.Item>
          )}

          {status !== PLAN_STATUS.TRIAL && showStartEndDate && (
            <Descriptions.Item
              label={
                <Text type="primary" strong underline>
                  Period Start{' '}
                </Text>
              }
            >
              {periodStart}
            </Descriptions.Item>
          )}

          {status === PLAN_STATUS.TRIAL && showStartEndDate && (
            <Descriptions.Item
              label={
                <Text type="primary" strong underline>
                  Trial Start{' '}
                </Text>
              }
            >
              {periodStart}
            </Descriptions.Item>
          )}

          {status !== PLAN_STATUS.TRIAL && showStartEndDate && isCanceled ? (
            <Descriptions.Item
              label={
                <Text type="danger" strong underline>
                  Expiring{' '}
                </Text>
              }
            >
              {expiringDate}
            </Descriptions.Item>
          ) : (
            status !== PLAN_STATUS.TRIAL &&
            showStartEndDate && (
              <Descriptions.Item
                label={
                  <Text type="success" strong underline>
                    {status === PLAN_STATUS.CANCELED ? 'Ended' : 'Next Cycle'}
                  </Text>
                }
              >
                {status === PLAN_STATUS.CANCELED ? expiredDate : periodEnd}
              </Descriptions.Item>
            )
          )}

          {/* only for trial plan */}
          {status === PLAN_STATUS.TRIAL && showStartEndDate && (
            <Descriptions.Item
              label={
                <Text type="primary" strong underline>
                  Trial Ends{' '}
                </Text>
              }
            >
              {periodEnd}
            </Descriptions.Item>
          )}

          <Descriptions.Item
            label={
              <Text type="primary" strong underline>
                Subscription{' '}
              </Text>
            }
          >
            <Link href={subscriptionLink} target="_blank">
              Subscription{' '}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <Text type="primary" strong>
                Profile{' '}
              </Text>
            }
          >
            <Link href={stripeLink} target="_blank">
              Stripe Profile{' '}
            </Link>
          </Descriptions.Item>
        </Descriptions>
      </Cards>
    </>
  );
};

export default ActivePlan;

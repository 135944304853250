// These Options are used in the User Management Filters

import {
  CRAFTER,
  NA,
  NO_TRIAL_CC,
  PLAN_STATUS,
  ROLES,
  STARTER,
  STATUS,
  TEAM,
  TEAM_STATUS,
  TRIAL,
  WITH_PLAN_STATUS,
} from '../constants';

export const BulkStatusOptions = [
  { label: 'Approve', value: WITH_PLAN_STATUS.ACTIVE },
  { label: 'Pause', value: WITH_PLAN_STATUS.PAUSE },
  { label: 'Deactive', value: WITH_PLAN_STATUS.DEACTIVE },
  { label: 'Delete', value: WITH_PLAN_STATUS.DELETED },
  { label: 'Permanent Delete', value: 'permanentDelete' },
];
export const statusWithPlanOptions = [
  { label: 'Active', value: PLAN_STATUS.ACTIVE, toolTip: 'On plan' },
  { label: 'Pause', value: WITH_PLAN_STATUS.PAUSE, toolTip: 'Admin Pause the user account' },
  { label: 'Deactive', value: WITH_PLAN_STATUS.DEACTIVE, toolTip: 'Cannot Login - data remains' },
  { label: 'Deleted', value: WITH_PLAN_STATUS.DELETED, toolTip: 'Cannot Login - data erased' },
  { label: 'Approved', value: WITH_PLAN_STATUS.APPROVED, toolTip: 'Access to onboarding, but no plan' },
  { label: 'Expired', value: WITH_PLAN_STATUS.EXPIRED, toolTip: "Login access to renew but can't craft" },
  { label: 'Expiring Soon', value: PLAN_STATUS.EXPIRING_SOON, toolTip: 'Cancelled but duration remaining' },
  { label: 'Error', value: PLAN_STATUS.ERROR, toolTip: "Credit card not working, can't craft" },
];

export const roleOptions = [
  { label: 'User', value: ROLES.USER },
  { label: 'Staff', value: ROLES.STAFF },
  { label: 'Admin', value: ROLES.ADMIN },
  { label: 'Free User', value: ROLES.FREE_USER },
];
export const planOptions = [
  { label: 'NA', value: NA },
  { label: 'Trial', value: TRIAL },
  { label: 'Starter', value: STARTER },
  { label: 'Crafter', value: CRAFTER },
  { label: 'Team', value: TEAM },
  { label: 'No Credit Card Trial', value: NO_TRIAL_CC },
];

// These status are just for the DISPLAY, thats y they are called mapping. in the code PLAN_STATUS & STATUS objects is working.

// When user is approved. Status -> APPROVED.  They can login to onboarding

// PLAN STATUS
// Status -> Active (On plan)
// Status -> Expiring Soon (On plan)
// Status -> Expired (if they cancel or don’t continue)
// Status -> Error (Credit card not working) (On plan)

// They cannot login to the application
// Deactive - Blue
// Delete (They or we delete their account, have to manually restore) - Blue

export const userMappingStatus = ({ status, planStatus }) => {
  let mappingStatus = `${status}-${planStatus}`;
  if (status === STATUS.PAUSE) {
    mappingStatus = 'pause';
  }
  if (status === STATUS.ACTIVE) {
    mappingStatus = 'approved';
  }
  if (status === STATUS.DEACTIVE) {
    mappingStatus = 'deactive';
  }
  if (status === STATUS.DELETED) {
    mappingStatus = 'deleted';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === PLAN_STATUS.ACTIVE) {
    mappingStatus = 'active';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === PLAN_STATUS.CANCELED) {
    mappingStatus = 'expired';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === PLAN_STATUS.INCOMPLETE) {
    mappingStatus = 'error';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === PLAN_STATUS.TRIAL) {
    mappingStatus = 'active';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === PLAN_STATUS.INCOMPLETE_EXPIRED) {
    mappingStatus = 'error';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === PLAN_STATUS.UNPAID) {
    mappingStatus = 'error';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === PLAN_STATUS.ERROR) {
    mappingStatus = 'error';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === PLAN_STATUS.PAST_DUE) {
    mappingStatus = 'error';
  }
  if (status === STATUS.ACTIVE && planStatus && planStatus === WITH_PLAN_STATUS.EXPIRING_SOON) {
    mappingStatus = WITH_PLAN_STATUS.EXPIRING_SOON;
  }
  return mappingStatus;
};

// this helps in mapping the stripe status in to our custom status
export const getPlanStatus = ({ currentPlanStatus: stripePlanStatus, currentPeriodWillEnds, displayStatusUI }) => {
  let planStatus = '';
  if (stripePlanStatus === PLAN_STATUS.CANCELED) {
    planStatus = 'expired';
  } else if (currentPeriodWillEnds) {
    planStatus = displayStatusUI ? 'Expiring Soon' : WITH_PLAN_STATUS.EXPIRING_SOON;
  } else {
    switch (stripePlanStatus) {
      case PLAN_STATUS.ACTIVE:
        planStatus = 'active';
        break;
      case PLAN_STATUS.CANCELED:
        planStatus = 'expired';
        break;
      case PLAN_STATUS.INCOMPLETE:
        planStatus = 'error';
        break;
      case PLAN_STATUS.INCOMPLETE_EXPIRED:
        planStatus = 'error';
        break;
      case PLAN_STATUS.UNPAID:
        planStatus = 'error';
        break;
      case PLAN_STATUS.PAST_DUE:
        planStatus = 'error';
        break;
      case PLAN_STATUS.TRIAL:
        planStatus = 'active';
        break;
      default:
        planStatus = stripePlanStatus;
    }
  }
  return planStatus;
};

export const changeTrailingStatus = ({ status, isCanceled }) => {
  return isCanceled && status === PLAN_STATUS.TRIAL
    ? 'Expiring Soon'
    : !isCanceled && status === PLAN_STATUS.TRIAL
    ? 'Trial'
    : status;
};

export const remainingTrialHits = ({ adminBilling, currentDate, lastCraftDate, todaysTotalHits, plan }) => {
  if (plan === NO_TRIAL_CC) {
    if (currentDate !== lastCraftDate) {
      return 100;
    } else {
      const adminPlan = adminBilling.find(({ plan }) => plan === NO_TRIAL_CC);
      const { totalTrialHits } = (adminPlan && adminPlan.details) || {};
      return totalTrialHits - todaysTotalHits || 0;
    }
  } else {
    if (currentDate !== lastCraftDate) {
      return 100;
    } else {
      const adminPlan = adminBilling.find(({ plan }) => plan === TRIAL);
      const { totalTrialHits } = (adminPlan && adminPlan.details) || {};
      return totalTrialHits - todaysTotalHits || 0;
    }
  }
};

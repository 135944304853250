/* ------------------------------------------------------ */
/*                       API REQUEST                      */
/* ------------------------------------------------------ */

// This is the function which calls the Cloud-function api hit

import { CLOUD_FUNCTION } from '../constants';
import createAxiosInstance from './axiosConfriguration';

/* -- Delete user by admin -- */
/**
 * Craftly admin can delete user account
 * @param {{
 * UserUID: string
 * deletedBy: string
 * }}
 * @param userUID delete person UID userUID
 * @param permanentDelete if true then user is also be removed from "user" collection
 * @param deletedBy - who delete the user - admin name
 */
export const adminDeleteUserByAdmin = async ({ userUID, permanentDelete, deletedBy = '' }) => {
  await createAxiosInstance({ apiType: CLOUD_FUNCTION }).get(
    `/delete-user?userUID=${userUID}&permanentDelete=${permanentDelete}&deletedBy=${deletedBy}`,
  );
};

/* -- Team Plan Update Subscription-- */
/**
 * When team admin wants to create a team.
 * @param {{
 * subID: string
 * quantity: number
 * activateNow: date
 * allowTeamStarterPlan: boolean
 * }}
 * @param subID subscription Id
 * @param quantity number of seats
 * @param activateNow   a timezone local time of the user
 * @param allowTeamStarterPlan there are 2 pricing for team plan.
 * @return subscription stripe object array
 */
export const updateSubscription = async ({ subID, quantity, activateNow, allowTeamStarterPlan }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/update-subscription`, {
    subID,
    quantity,
    activateNow,
    allowTeamStarterPlan,
  });
};

/* -- Team Plan Update Quantity-- */
/**
 * When team admin wants to update team seats. it happens in Proration
 * @param {{
 * subID: string
 * quantity: number
 * activateNow: date
 * }}
 * @param subID subscription id
 * @param quantity  seats quantity
 * @param activateNow  user local time
 * @returns stripe subscription object
 */
export const updateSubscriptionQuantity = async ({ subID, quantity, activateNow }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/update-team-plan-quantity`, {
    subID,
    quantity,
    activateNow,
  });
};

/**
 *
 * @param {subID} subscriptionId
 * @param {quantity} SeatsQuantity
 * @param {customer} uid
 * @param {optionalFields} apiOptionalFields
 * @returns stripe proration details
 */

/* -- Team Plan Proration Price - */
/**
 * This api is to get proration charges.
 * You can retrieve an upcoming invoice to preview changes to a subscription. This API call doesn’t modify the subscription,
 * It returns the upcoming invoice based only on the parameters that you pass.
 *
 * @param {{
 * subID: string
 * quantity: number
 * customer: string
 * optionalFields: object
 * allowTeamStarterPlan: boolean
 * }}
 * @param subID subscription id
 * @param quantity  seats quantity
 * @param customer  stripe customer id
 * @param optionalFields optional fields
 * @param allowTeamStarterPlan diff in team plan pricing
 * @returns  stripe proration object
 */
export const getProration = async ({ subID, quantity, customer, optionalFields, allowTeamStarterPlan }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/get-proration`, {
    subID,
    quantity,
    customer,
    optionalFields,
    allowTeamStarterPlan,
  });
};

/* -- Get User STRIPE Credit cards - */
/**
 *
 * @param {{
 * customerId: string
 * userUID: string
 * }}
 * @param customerId customer id
 * @param userUID  userId
 * @returns stripe customer object
 */
export const getUserCards = async ({ customerId, userUID }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/get-cards`, { customerId, userUID });
};

/* -- Cancel Team Plan Subscription - */
/**
 * Cancel team plan when the period ends
 *
 * @param {{
 * subID: string
 * }}
 * @param subID subscription id
 * @returns stripe subscription object
 */
export const cancelSubscriptionAuto = async ({ subID }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/cancel-subscription`, { subID });
};

/**
 * Renew Subscription
 * The team plan was created but later on team plan expired or cancelled.
 * Now team admin wants to renew team subscription
 * @param {{
 * subID: string
 * }}
 * @param {subID} subID
 * @returns
 */
export const renewSubscription = async ({ subID }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/renew-subscription`, { subID });
};

/**
 * fetchAnalyzeBotFromBigQuery
 * the analyze bots have all the tool output data.
 * this data is fetch from BigQuery.
 * it is directly sync with bigQuery.
 * When ever there is an craft, a the output of any tool also save in Analyze-Bots for admin puupose.
 *
 * Firebase Collection: analyze-bots.
 * BIG_QUERY_BOT_MODERATOR=craftly-d50ba.craftly.bot_moderator_view
 *
 * @param {{
 * limit: number
 * date: string
 * }}
 * @param {limit} limit to fetch the number of rows
 * @param {date} date fetch specific data by date
 * @returns rows data
 */
export const fetchAnalyzeBotFromBigQuery = async ({ limit, date }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/analyze-bots`, { limit, date });
};

/* ---- FETCH USER-MANAGEMENT DATA FROM BIGQUERY VIEW --- */

/**
 * fetchUserManagementFromBigQuery
 *
 * it fetch the data from BigQuery
 * Firebase Collection: users collection is synced with BigQuery
 * Firebase Collection: users-bot summary is also sync with BigQuery
 *
 * BIG_QUERY_BOT_MODERATOR=craftly-d50ba.craftly.user_management
 *
 * @param {{
 * roleWhere: NOT IN | IN
 * statusWhere: NOT IN | IN
 * planWhere: NOT IN | IN
 * roles: [user | admin | staff | freeUser]
 * status: [ active, approved, deactive, deleted, pause, expired, expiring_soon,  error ]
 * plan: [ noCreditCard, team, crafter, starter, trial, na ]
 * limit: quantity
 * offset: quantity
 * }}
 * @param {roleWhere} roleWhere where clause
 * @param {statusWhere} statusWhere where clause
 * @param {planWhere} planWhere where clause
 * @param {roles} roles roles type
 * @param {status} status status type
 * @param {plan} plan plan type
 * @param {limit} limit number of rows
 * @param {offset} offset next pagination rows
 * @returns rows data
 */
export const fetchUserManagementFromBigQuery = async ({
  roleWhere,
  statusWhere,
  planWhere,
  roles,
  status,
  plan,
  location = 'us',
  limit,
  offset,
}) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/fetch-user-management`, {
    roleWhere,
    statusWhere,
    planWhere,
    roles,
    status,
    plan,
    location,
    limit,
    offset,
  });
};

/* ---- FETCH SEARCH-USER-MANAGEMENT DATA FROM BIGQUERY VIEW --- */
/**
 * fetchSearchUserManagementFromBigQuery
 *
 * Craftly admin can search the data either by email, uid or name
 * @param {{
 * email: string
 * uid:   string
 * emailWhere: string,
 * nameWhere: string
 * limit: number
 * offset: number
 * }}
 * @param email user email address
 * @param uid user firebase uid
 * @param emailWhere LIKE or NOT LIKE
 * @param nameWhere  LIKE or NOT LIKE
 * @param limit used for pagination
 * @param offset used for pagination
 * @returns user-data
 */
export const fetchSearchUserManagementFromBigQuery = async ({
  email,
  uid,
  name,
  emailWhere,
  nameWhere,
  limit,
  offset,
}) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/search-user-management`, {
    email,
    uid,
    name,
    emailWhere,
    nameWhere,
    limit,
    offset,
  });
};

/**
 * checkCardUsage
 *
 * Check credit card is it is already use or not
 * @param {{
 * tokenID: string
 * accountExist: boolean | string
 * name: string,
 * country: string
 * address: string
 * }}
 * @param tokenID stripe generated id using CardNumberElement
 * @param accountExist user already have stripeId
 * @param name card name
 * @param country card country
 * @param address card  address
 * @returns alreadyExists boolean
 */
export const checkCardUsage = async ({ tokenID, accountExist, name, country, address }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/card-usage`, {
    tokenID,
    accountExist,
    name,
    country,
    address,
  });
};

/**
 * checkPromoCode
 *
 * Craftly admin have to create promocode form the stripe dashboard.
 * Stripe generate the code and the code given to the users to get discounts
 * @param {{
 * checkCode: string
 * }}
 * @params checkCode stripe provided promocode
 * @returns stripe promo details
 */
export const checkPromoCode = async ({ checkCode }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/check-promotion-code`, {
    checkCode,
  });
};

/**
 * enableSubscriptionApi
 *
 * Enable Subscription from Trial Billing page.
 * @param {{
 * priceId: string
 * paymentMethodId: string
 * promotionCode: string
 * quantity: number
 * userSelectedPlanName: string
 * userSelectedPlanInterval: string
 * }}
 * @params priceId stripe price id
 * @params paymentMethodId created by stripe payment method
 * @params promotionCode stripe promo code
 * @params quantity plan words quantity
 * @params userSelectedPlanName plan description for stripe metadata in subscription
 * @params userSelectedPlanInterval plan duration for stripe metadata in subscription
 * @returns
 */
export const enableSubscriptionApi = async ({
  priceId,
  paymentMethodId,
  promotionCode,
  quantity,
  userSelectedPlanName,
  userSelectedPlanInterval,
}) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/start-subscription`, {
    priceId,
    paymentMethodId,
    promotionCode,
    quantity,
    userSelectedPlanName,
    userSelectedPlanInterval,
  });
};

/**
 * retryEnableSubscriptionApi
 *
 * Retry First Subscription from Trial Billing page.
 * Due to the verification process or any wrong input in verification code, this process happens
 * @param {{
 * paymentMethodId: string
 * invoiceId: string
 * }}
 * @params paymentMethodId: stripe already generated payment method for the subscription
 * @params invoiceId: already generated invoice-id by stripe
 * @returns
 */
export const retryEnableSubscriptionApi = async ({ paymentMethodId, invoiceId }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/retry-subscription`, {
    invoiceId,
    paymentMethodId,
  });
};

/**
 * Get UserBots via date filter by bigQuery
 * 
 * BIG_QUERY_BOT_MODERATOR=craftly-staging-44134.craftly_users.bot_moderator

 * @param {{
 * uid: string
 * date: dateString
 * }}
 * @returns
 * @params uid: uid
 * @params date: date
 */
export const fetchUserBotsFromBigQuery = async ({ uid, date, limit = 3000 }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/user-bots`, { uid, date, limit });
};

/**
 * Get UserBots on available dates only via UID bigQuery
 *
 * Fetch only those dates in which there are tools are used.
 * BIG_QUERY_BOTS_AVAILABLE_DATES=craftly-d50ba.craftly.bot_moderator_available_dates_view
 * @param {{
 * uid: string
 * }}
 * @params uid: firebase uid
 * @returns rows
 */
export const fetchUserBotsAvailableDates = async ({ uid, limit = 3000 }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/user-bots-available-dates`, {
    uid,
    limit,
  });
};

/* ------------------------------------------------------ */
/*                       BILLING Page UI                  */
/* ------------------------------------------------------ */

/**
 * previewPriceChange
 *
 * This api is to get proration charges.
 * You can retrieve an upcoming invoice to preview changes to a subscription.
 * This API call doesn’t modify the subscription,
 * It returns the upcoming invoice based only on the parameters that you pass.
 * Changing in price  result in a proration.
 * @param {{
 * quantity: number
 * priceId: string
 * optionalFields: object
 * code: string
 * }}
 * @params quantity: number
 * @params priceId: stripe price Id
 * @params optionalFields: additional keyObjects
 * @params code: promotional code
 * @returns stripe proration details
 */
export const previewPriceChange = async ({ quantity, priceId, optionalFields, code }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/preview-price-change`, {
    quantity,
    optionalFields,
    priceId,
    code,
  });
};

/**
 * changeSubscription
 *
 * It is used in Billing. It change the current subscription.
 * If there is trail ongoing then it would end the trial
 *
 * @param {{
 * subID: string
 * quantity: number
 * priceId: string
 * activateNow: dateTime
 * code: string
 * }}
 * @params subID subscription id for a existing plan
 * @params quantity quantity
 * @params priceId: stripe price Id
 * @params activateNow: dateTime
 * @params code: promotional code
 * @returns stripe subscription data
 */
export const changeSubscription = async ({ subID, quantity, priceId, activateNow, code }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/change-subscription`, {
    subID,
    quantity,
    activateNow,
    priceId,
    code,
  });
};

/**
 *
 * @param {subscriptionId - user subscription get ended and plan activated.} subscriptionId
 * @returns
 */
/**
 * changeSubscriptionStatus
 *
 * User can renew or cancel the subscription.
 * If a user cancel the subscription, it can be renewed before the subscription time ended.
 *
 * @param {{
 * subID: string
 * status: cancel | renew
 * }}
 * @params subID subscription id
 * @params status  either to renew or cancel the subscription
 * @return subscription stripe data
 */
export const changeSubscriptionStatus = async ({ subId, status }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/subscription-status`, {
    subId,
    status,
  });
};

/**
 * deleteSubscription
 *
 * It ends the subscription.
 * Invoice related to subscription will be void.
 * Normally this happens for Incomplete  or PAST_DUE subscription
 *
 * @param {{
 * subID: string
 * currentInvoiceId: string
 * }}
 * @params subID subscription id
 * @params currentInvoiceId  invoice id
 * @return { cancelled: true}
 */
export const deleteSubscription = async ({ subId, currentInvoiceId }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/cancel-now`, {
    subId,
    currentInvoiceId,
  });
};

/**
 * getCustomerBalance
 *
 * Get stripe  Customer details for balance
 *
 * @returns stripe customer details
 */
export const getCustomerBalance = async () => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/balance`);
};

/**
 * getInvoiceSynced
 *
 * @returns sync user invoice
 */
export const getInvoiceSynced = async () => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/sync`);
};

/* ------------------------------------------------------ */
/*                        OLD APIs                        */
/* ------------------------------------------------------ */

/* -------------- OLD API NOT USING ANYMORE ------------- */

export const updateTrialSuccess = async ({ userUID, paymentMethodId, selectedPlan }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/trail-success`, {
    userUID,
    paymentMethodId,
    selectedPlan,
  });
};

/* -------------- OLD API NOT USING ANYMORE ------------- */
export const setTrialSubscription = async ({ userUID, paymentMethodId }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/trial-subscription`, {
    userUID,
    paymentMethodId,
  });
};

/* -------------- OLD API NOT USING ANYMORE ------------- */

/**
 *
 * @param {subscriptionId - user subscription get ended and plan activated.} subscriptionId
 * @returns
 */
export const endTrailNow = async ({ subscriptionId }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/end-trial-period`, { subscriptionId });
};

/* -------------- OLD API NOT USING ANYMORE ------------- */

/**
 *
 * @param {config - customize team portal.} config
 * @returns
 */
export const setCustomConfig = async ({ config }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/portal-config`, { config });
};

/* -------------- OLD API NOT USING ANYMORE ------------- */

export const uploadZendesk = async ({ userUID }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/upload-zendesk`, { userUID });
};

/* -------------- OLD API NOT USING ANYMORE ------------- */

export const createAffiliate = async ({ userUID }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/create-affiliates`, { userUID });
};

/* -------------- OLD API NOT USING ANYMORE ------------- */

export const getAffiliateData = async ({ email }) => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).get(`/get-affiliates?email=${email}`);
};

/* -------------- OLD API NOT USING ANYMORE testing for secure api ------------- */

export const secureApi = async () => {
  return await createAxiosInstance({ apiType: CLOUD_FUNCTION }).post(`/secure`);
};

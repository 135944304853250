import React, { lazy, Suspense } from 'react';
import { Row, Col, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import Heading from '../../../components/Headings';
import { Cards } from '../../../components/Card';

const ToolCard = lazy(() => import('./ToolCard'));

const ToolCards = ({ selectedCategory }) => {
  const { allTools } = useSelector((store) => store.adminFeatures);

  const popularTools = allTools.filter(({ popular }) => popular === true);

  let showTools = [];
  if (selectedCategory === 'all') {
    showTools = allTools;
  } else {
    showTools = popularTools;
  }

  return (
    <Row gutter={25}>
      {allTools && allTools.length ? (
        showTools &&
        showTools.map(({ commonIcon, id, subItem, item, title, description, img, uid, isVisible }, index) => {
          const toolCard = { title, description, img, uid, isVisible };
          return (
            <Col key={`manage-${index}-${item}`} xxl={6} xl={6} lg={8} md={8} xs={24}>
              <Suspense
                fallback={
                  <Cards headless>
                    <Skeleton active />
                  </Cards>
                }
              >
                <ToolCard commonIcon={commonIcon} itemId={id} value={toolCard} key={index} />
              </Suspense>
            </Col>
          );
        })
      ) : (
        <Col md={24}>
          <Cards headless>
            <Heading>Data Not Found!</Heading>
          </Cards>
        </Col>
      )}
    </Row>
  );
};

export default ToolCards;

/* ------------------------------------------------------ */
/*                       GroupFields                      */
/* ------------------------------------------------------ */

/* ----------------- CRAFTLY ADMIN PAGE ----------------- */

/**
 * Methodology
 *
 * There are 2 kind of option fields
 *  1. tool-group-fields
 *  2. tool-categories
 *
 * tool-group-fields is used to render the dropdowns in a tool field
 *   for example in the tool field there is a dropdown which have the options to select engines like divinci , curie
 *
 * Firestore
 *  Colleciton: tool-group-fields
 *  Document: <ID>
 *  object :
 *        type: tool-group-fields
 *        groupName
 *        groupTitle
 *        optionalFields : [{ fieldKey: '' , fieldName: ''}]
 *
 */

import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Button, Space, Card, Typography, Divider, Spin } from 'antd';
import { MinusCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Main } from '../styled';
import { getFirestore } from 'redux-firestore';
import {
  addNotificationError,
  addNotificationInfo,
  addNotificationSuccess,
} from '../../components/utilities/notifications';
import { TOOL_GROUP_FIELDS } from '../../constants';
import PageTitle from '../../components/PageTitle';

const { Text, Paragraph } = Typography;

const GroupFields = () => {
  const [addLoading, setAddLoading] = useState(false);
  const [allToolGroups, setAllToolGroups] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [groupEditId, setGroupEditId] = useState(null);
  const [form] = Form.useForm();
  const db = getFirestore();

  useEffect(() => {
    setFetchLoading(true);
    db.collection('tools-option-fields')
      .orderBy('date', 'desc')
      .onSnapshot((querySnapshot) => {
        var allGroups = [];
        querySnapshot.forEach((doc) => {
          allGroups.push({ ...doc.data(), groupFieldId: doc.id });
        });
        const allGroupsFields = allGroups.filter(({ type }) => type === TOOL_GROUP_FIELDS);
        setAllToolGroups(allGroupsFields);
        setFetchLoading(false);
      });
  }, [db]);

  const onFinish = (values) => {
    setAddLoading(true);
    try {
      groupEditId
        ? db
            .collection('tools-option-fields')
            .doc(groupEditId)
            .set(
              {
                ...values,
                type: TOOL_GROUP_FIELDS,
                date: new Date(),
              },
              { merge: true },
            )
            .then(() => {
              addNotificationInfo('Options Added');
              setAddLoading(false);
              setGroupEditId(null);
              form.resetFields();
            })
        : db
            .collection('tools-option-fields')
            .add(
              {
                ...values,
                type: TOOL_GROUP_FIELDS,
                date: new Date(),
              },
              { merge: true },
            )
            .then(() => {
              addNotificationInfo('Options Added');
              setAddLoading(false);
              form.resetFields();
            });
    } catch (err) {
      addNotificationError(err.message);
    }
  };

  const deleteGroupField = (groupFieldId) => {
    db.collection('tools-option-fields')
      .doc(groupFieldId)
      .delete()
      .then(() => {
        addNotificationSuccess('Group Fields Successfully deleted.');
      })
      .catch((error) => {
        addNotificationError(`Error in deleting group fields. ${error.message}`);
      });
  };

  const editGroupField = (groupId) => {
    const getSpecificTool =
      allToolGroups && allToolGroups.length > 0 && allToolGroups.find(({ groupFieldId }) => groupFieldId === groupId);

    form.setFieldsValue({
      groupTitle: null,
    });
    Object.keys(getSpecificTool).forEach((data) => {
      form.setFieldsValue({
        [data]: getSpecificTool[data],
      });
    });
    setGroupEditId(groupId);
    const element = document.getElementById('groupFieldsForm');
    if (element) {
      // 👇 Will scroll smoothly to the top of the billing section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id="groupFieldsForm">
      <PageTitle title="Tools Group Fields" />

      <Main>
        <Row gutter={[10, 10]}>
          <Col sm={24} lg={15}>
            <Form
              form={form}
              name="groupFields"
              onFinish={onFinish}
              onReset={() => {
                form.resetFields();
                setGroupEditId(null);
              }}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                name="groupName"
                label="Group Name"
                rules={[{ required: true, message: 'Group Name is required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="groupTitle" label="Group Title" rules={[{ required: false }]}>
                <Input />
              </Form.Item>

              <Form.List name="optionFields">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div style={{ display: 'flex' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'fieldName']}
                          rules={[{ required: true, message: 'Missing Field name' }]}
                          style={{ width: '95%' }}
                        >
                          <Input placeholder="Field Name" style={{ width: '95%' }} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'fieldKey']}
                          rules={[{ required: true, message: 'Missing Field key' }]}
                          style={{ width: '95%' }}
                        >
                          <Input placeholder="Field Key" style={{ width: '95%' }} />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item>
                <Space>
                  <Button loading={addLoading} type="primary" htmlType="submit">
                    {groupEditId ? 'Update' : 'Submit'}
                  </Button>
                  <Button loading={addLoading} type="dashed" htmlType="reset">
                    Reset
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
          <Col sm={24} lg={24}>
            <Spin spinning={fetchLoading}>
              <Row gutter={[10, 10]}>
                {allToolGroups &&
                  allToolGroups.length > 0 &&
                  allToolGroups.map(({ groupName, optionFields, groupTitle, groupFieldId }) => {
                    return (
                      <Col sm={24} lg={8}>
                        <Card
                          title={groupName}
                          extra={
                            <>
                              <span
                                onClick={() => deleteGroupField(groupFieldId)}
                                style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}
                              >
                                <DeleteOutlined />
                              </span>
                              <span
                                onClick={() => editGroupField(groupFieldId)}
                                style={{ color: 'blue', cursor: 'pointer' }}
                              >
                                <EditOutlined />
                              </span>
                            </>
                          }
                        >
                          <Text strong> Group Title: </Text>
                          <Text italic> {groupTitle} </Text>
                          <Divider />
                          {optionFields.map(({ fieldKey, fieldName }) => {
                            return (
                              <Row>
                                <Col>
                                  <Paragraph copyable>{fieldName}</Paragraph>
                                </Col>
                                <Col>
                                  <Paragraph copyable>{fieldKey}</Paragraph>
                                </Col>
                              </Row>
                            );
                          })}
                        </Card>
                      </Col>
                    );
                  })}
              </Row>
            </Spin>
          </Col>
        </Row>
      </Main>
    </div>
  );
};

export default GroupFields;

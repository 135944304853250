/* ------------------------------------------------------ */
/*               Tool Design Right Container              */
/* ------------------------------------------------------ */

/**
 *
 * Methodology
 *
 * TOOL COMPONENT (OUTPUT) Component.
 * Handled the tools ouptuts
 *
 * This is the Right section of the tool page.
 * There is All & Favourite tab options.
 *
 * The past fav tool output in Fav tab displays according to the project  and workspace selection.
 *
 * There will be no Craft button in REPHRASE_TOOL
 * Rephrase Tool has a very different tool design UI than the rest of the tool
 *
 * "Upgrade-Now" UI will be shown if a user is not permitted to use the tool because of a plan or billing restriction.
 *
 */

import React, { useState, useEffect } from 'react';
import { Card, Empty, Result } from 'antd';
import moment from 'moment';
import copy from 'copy-to-clipboard';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import loadingGIF from '../../../static/general/loading.gif';
import { Button } from '../../../components/Button';
import { convertTimeStampToDateTime, htmlToText, breakLineForCopy } from '../../../utility/utility';
import { PERSONAL_WORKSPACE, REPHRASE_TOOL_KEY, TEAM_WORKSPACE } from '../../../constants';
import CraftDataDisplay from './CraftDataDisplay';
import DesignDefaultUI from './DesignDefaultUI';
import { RightSideStyle } from '../styled';
import { deleteOutputCraft } from '../../../redux/UserProject/actionCreator';
import { useNavigate } from 'react-router-dom';

/**
 * @output Tool output data
 * @categoryId  tool category id
 * @loading tool output loading time
 * @onSubmit submit the tool request to generate data from openai
 * @highlightRowsId  it always highlight the new tool output data.
 * @allowTemplateOnPlan whether the user is able to use or not
 * @imageAITool tool image generation id
 * @toolId tool id
 */
const ToolDesignRightContainer = ({
  output,
  categoryId,
  loading,
  onSubmit,
  highlightRowsId,
  allowTemplateOnPlan,
  imageAITool,
  toolId,
}) => {
  const [selectedTab, setSelectedTab] = useState('all');
  const [copySuccess, setCopySuccess] = useState(null);
  const [copied, setCopied] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { content } = output || { content: null };
  const { favorites, userProjectsList } = useSelector((state) => {
    return {
      favorites: state.userProject.favorites,
      userProjectsList: state.userProject.data,
    };
  });
  const { selectedWorkspace } = useSelector((state) => state.accountSettings);
  const { uid } = useSelector((state) => state.fb.auth);

  useEffect(() => {
    !copySuccess &&
      setInterval(function () {
        setCopySuccess(null);
      }, 4000);
  }, [copySuccess]);

  // it calculate the fav content. it depends on the team and personal workspace.
  // the fav flag is saved within a tool output
  const displayFavData = () => {
    if (favorites && Array.isArray(favorites[categoryId])) {
      let userFav = [];
      if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
        userFav = favorites[categoryId];
        userFav = favorites[categoryId].filter((e) => e.uid === uid);
      } else if (selectedWorkspace && selectedWorkspace.workspaceType === PERSONAL_WORKSPACE) {
        userFav = favorites[categoryId].filter((e) => e.uid === uid || e.uid === undefined);
      }
      const filterlen = userFav.filter(({ len }) => len !== undefined);
      const filterWithOutLen = userFav.filter(({ len }) => len === undefined);
      filterlen.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        const Adat = convertTimeStampToDateTime(a.date);
        const Bdat = convertTimeStampToDateTime(b.date);

        return moment(Bdat).toDate() - moment(Adat).toDate();
      });
      const ser = [...filterlen, ...filterWithOutLen].reduce((state, data, index) => {
        state.push({
          ...data,
        });
        return state;
      }, []);
      return ser;
    } else return [];
  };

  const onOutputDelete = ({ contentId }) => {
    dispatch(deleteOutputCraft({ contentId }));
  };

  // Content List of tabs
  const contentListNoTitle = {
    all: (
      <>
        {content ? (
          <>
            <CraftDataDisplay
              content={content}
              userProjectsList={userProjectsList}
              selectedWorkspace={selectedWorkspace}
              categoryId={categoryId}
              uid={uid}
              setCopySuccess={setCopySuccess}
              copySuccess={copySuccess}
              onOutputDelete={onOutputDelete}
              highlightRowsId={highlightRowsId}
              toolId={toolId}
            />

            {/* There will be no Craft button in REPHRASE_TOOL */}
            {toolId !== REPHRASE_TOOL_KEY && (
              <div className="craft-btn">
                <Button loading={loading} onClick={onSubmit} block size="large" type="primary">
                  {loading ? ' Crafting  ' : 'Keep Crafting'}
                </Button>
              </div>
            )}
          </>
        ) : loading ? (
          <div className="animationWrapper">
            <img alt="" src={loadingGIF} className="loading-image" />
          </div>
        ) : (
          <DesignDefaultUI />
        )}
      </>
    ),
    favorite: (
      <>
        {displayFavData() && displayFavData().length > 0 ? (
          <CraftDataDisplay
            content={displayFavData()}
            userProjectsList={userProjectsList}
            selectedWorkspace={selectedWorkspace}
            categoryId={categoryId}
            uid={uid}
            setCopySuccess={setCopySuccess}
            copySuccess={copySuccess}
            toolId={toolId}
          />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="There are no favorited results. Please click all to see results."
          />
        )}
      </>
    ),
  };
  const tabListNoTitle = [
    {
      key: 'all',
      tab: 'All',
    },
    {
      key: 'favorite',
      tab: 'Favourite',
    },
  ];

  const displayTabs = imageAITool ? tabListNoTitle.filter(({ key }) => key !== 'favorite') : tabListNoTitle;

  const onTabChange = (key) => {
    setSelectedTab(key);
  };

  const copyAll = () => {
    setCopied(true);
    try {
      const text = content.map(({ contentData }) => htmlToText(breakLineForCopy(contentData)));
      copy(text.join('\n\n'));
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    } catch (error) {}
  };

  return (
    <RightSideStyle>
      {/* " Upgrade-Now " UI will be shown if a user is not permitted to use the tool because of a plan or billing restriction. */}
      {!allowTemplateOnPlan && (
        <Result
          status="403"
          title="Limited Access"
          subTitle="Sorry, you are not authorized to access AI Image Generation."
          extra={
            <Button type="primary" onClick={() => navigate('/settings/billing')}>
              Upgrade Now
            </Button>
          }
        />
      )}

      {/* Rephrase Tool has a very different tool design UI than the rest of the tool */}
      {allowTemplateOnPlan && toolId !== REPHRASE_TOOL_KEY && (
        <Card
          className="product-tabs product-tabs-web"
          tabList={displayTabs}
          activeTabKey={selectedTab}
          onTabChange={(key) => {
            onTabChange(key);
          }}
          tabBarExtraContent={
            content &&
            !imageAITool && (
              <Button type={'light'} onClick={copyAll} icon={copied ? <CheckOutlined /> : <CopyOutlined />}>
                {copied ? 'Copied' : 'Copy all'}
              </Button>
            )
          }
        >
          {contentListNoTitle[selectedTab]}
        </Card>
      )}
    </RightSideStyle>
  );
};

export default ToolDesignRightContainer;

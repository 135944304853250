import styled from 'styled-components';

const ExcelExportStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  .file-name {
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
  }
`;
export { ExcelExportStyled };

import React from 'react';
import { getFirebase } from 'react-redux-firebase';

const ErrorHTML = ({ botId, toolID, input, errorMessage }) => {
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;
  return (
    <div>
      <div
        style={{
          fontFamily: 'Helvetica,Arial,sans-serif',
          fontSize: '18px',
          lineHeight: '24px',
          wordBreak: 'break-word',
          paddingBottom: '8px',
        }}
      >
        Error occur in Tools. Take Action Now.
      </div>

      {errorMessage && (
        <div
          style={{
            fontFamily: 'Helvetica,Arial,sans-serif',
            fontSize: '16px',
            wordBreak: 'break-word',
            paddingBottom: '8px',
            color: 'red',
          }}
        >
          Error Message : {errorMessage}
        </div>
      )}
      <div
        style={{
          fontFamily: 'Helvetica,Arial,sans-serif',
          fontSize: '16px',
          paddingBottom: '14px',
        }}
      >
        Details
      </div>
      <table border="0" cellPadding="0" cellSpacing="0" width="100%">
        <tbody>
          <tr>
            <td align="left" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              Tool ID
            </td>
            <td align="right" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              {toolID}
            </td>
          </tr>
          <tr>
            <td align="left" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              Bot ID
            </td>
            <td align="right" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              {botId}
            </td>
          </tr>
          <tr>
            <td align="left" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              Input
            </td>
            <td align="right" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              {input}
            </td>
          </tr>
          <tr>
            <td align="left" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              User Email
            </td>
            <td align="right" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              {user.email}
            </td>
          </tr>
          <tr>
            <td align="left" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              URL
            </td>
            <td align="right" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              {window.location.href}
            </td>
          </tr>
          <tr>
            <td align="left" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              Time
            </td>
            <td align="right" style={{ borderTop: '1px solid black', padding: '10px 0', fontSize: '14px' }}>
              {new Date().toString()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ErrorHTML;

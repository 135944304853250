import styled from 'styled-components';

const NotificationComponentWrap = styled.div`
  padding: 29.71px 13.73px;
  @media (max-width: 991px) {
    padding: 30px 16px;
  }
  .ant-form-vertical {
    max-width: 677px;
    margin-top: 34px;
  }
  .ant-row {
    padding-top: 10px;
  }
  .ant-typography {
    font-weight: 500;
    font-size: 12px;
    color: #737277;
    line-height: 18px;
    padding-bottom: 15px;
    border-bottom: 1.5px solid #eeeeee;
    width: 100%;
    font-family: 'Poppins-Regular';
    display: inline-block;
  }
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    border: 1.5px solid #eeeeee;
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .ant-checkbox + span {
    // font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 0;
    color: #282b3f;
    padding-right: 12px;
    padding-left: 12px;
    font-family: 'Poppins-SemiBold';
  }

  .ant-form-vertical .ant-form-item-label > label {
    margin: 0;
    color: red;
    // font-weight: 600;
    font-size: 16px;
    color: #282b3f;
    line-height: 24px;
    font-family: 'Poppins-SemiBold';
  }

  .notification-btn {
    margin-top: 40px;
  }

  h1.ant-typography {
    // font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #282b3f;
    margin-bottom: 8px;
    padding-bottom: 0px;
    border-bottom: none;
    font-family: 'Poppins-SemiBold';
  }

  .alert {
    margin-bottom: 1px !important;
  }

  .opt {
    border-bottom: none;
    padding-bottom: 0px;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-checkbox-wrapper {
    @media (max-width: 768px) {
      margin-bottom: 6px !important ;
    }
  }
`;

const SettingsWrapStyled = styled.div`
  padding: 29.71px 13.73px;
  @media (max-width: 991px) {
    padding: 30px 16px;
  }

  .profile {
    margin-top: 4px !important;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }

  .ant-form-item-explain-error {
    font-size: 12px;
  }

  .ant-form-vertical {
    max-width: 677px;
    margin-top: 18px;
  }

  .ant-form-item-label > label {
    // font-weight: 600;
    line-height: 21px;
    color: #282b3f;
    font-family: 'Poppins-SemiBold';
  }

  .ant-form-vertical .ant-form-item-label {
    margin: 0 0 4px !important;
    padding: 0px !important;
  }

  .ant-form-item-control-input-content {
    // font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }

  h1.ant-typography {
    font-size: 16px;
    line-height: 22px;
    color: #282b3f;
    margin-bottom: 0px;
    margin-top: 0;
    font-family: 'Poppins-SemiBold';
  }
  h2.ant-typography {
    font-size: 13px;
    line-height: 22px;
    color: #282b3f;
    margin-bottom: 0px;
    margin-top: 0;
    font-family: 'Poppins-SemiBold';
  }

  .ant-typography {
    font-weight: 500;
    font-size: 12px;
    color: #737277;
    line-height: 18px;
    margin-top: 8px;
    display: inline-block;
    width: 100%;
    font-family: 'Poppins-Regular';
    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .ant-form-item {
    margin-bottom: 0px;
    margin-top: 20px;
  }

  .google-message {
    padding: 1rem 0rem 1rem;

    .google-wrapper {
      margin-top: 1rem;
      margin-bottom: 1rem;
      .google-icon {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
    .notice-wrapper {
      background-color: rgb(255, 250, 230);
      padding: 1rem;
      .notice {
        display: flex;
        flex-direction: row;
      }
      .notice-text {
        padding: 0rem 14px;
      }
    }
  }

  .social-accounts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 15px;
    // margin-top: 4px;
    margin-bottom: 0px;
    height: 72px;

    .google {
      display: flex;
      gap: 14px;
      img {
        width: 42px;
        height: 42px;
        border: 1px solid #eeeeee;
        border-radius: 4px;
        padding: 6px;
      }
    }

    p {
      margin-bottom: 4px;
      // font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: #282b3f;
      font-family: 'Poppins-SemiBold';
    }
  }
  .account-form-bottom {
    margin-top: 5rem;
  }
`;

const Mystyle = styled.div`
  .ant-form-item-control-input-content {
    margin-top: -35px;
  }
  .ant-typography {
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    color: #737277;
    line-height: 18px;
    display: inline-block;
    width: 100%;
    font-family: 'Poppins-Regular';
  }
  .ant-form-item {
    margin-top: 22px;
  }
`;

const SettingsPasswordStyled = styled.div`
  margin-top: 44px !important;
`;

const GoogleMessage = styled.div`
  padding: 1rem 0rem;
  .spacing {
    margin: 10px 0px;
  }
  .google-wrapper {
    margin-bottom: 1rem;
    .google-icon {
      margin-right: 1rem;
    }
  }
  .notice-wrapper {
    background-color: rgb(255, 250, 230);
    padding: 1rem;
    .notice {
      display: flex;
      flexdirection: row;
    }
    .notice-text {
      padding: 0rem 14px;
    }
  }
`;
export { NotificationComponentWrap, SettingsWrapStyled, Mystyle, SettingsPasswordStyled, GoogleMessage };

import styled from 'styled-components';

const ErrorPageWrapper = styled.div`
  min-height: 100vh;

  .loading-time-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .verify-box {
      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
      padding: 2rem;
      font-family: 'Poppins-Regular';
      margin-left: auto;
      margin-right: auto;
      width: 53%;
      margin-top: 2rem;
      margin-bottom: 2rem;

      @media only screen and (max-width: 576px) {
        padding: 1rem;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      .early-text {
        font-size: 24px;
        color: rgb(36, 38, 38);
        padding: 0 3rem;
        @media only screen and (max-width: 1200px) {
          padding: 0;
        }
      }
      .text-notice {
        font-size: 14px;
        color: ${({ theme }) => theme['gray-color']};
      }
      .link {
        font-size: 12px;
      }
    }
  }
`;

export { ErrorPageWrapper };

/* ------------------------------------------------------ */
/*                     Suggested Tools                    */
/* ------------------------------------------------------ */

/**
 * There are 5 blocks of different Tools and features
 */

import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SuggestedToolsStyled } from '../styled';

const { Text } = Typography;
const SuggestedTools = () => {
  const navigate = useNavigate();

  const redirect = (pageLink) => {
    navigate(`/${pageLink}`);
  };
  return (
    <SuggestedToolsStyled>
      <Text className="suggested-text">You might want to try...</Text>
      <div className="box-wrapper">
        <Row
          gutter={[
            { xs: 16, sm: 16, md: 24, lg: 24 },
            { xs: 16, sm: 16, md: 24, lg: 24 },
          ]}
        >
          <Col>
            <div className="suggested-box  suggested-color-blog" onClick={() => redirect('long-form')}>
              <img
                alt="example"
                src={require(`../../../static/category/main/longForm.png`)}
                className="suggested-icon"
              />
              <Text className="suggested-tool-title"> Longform Blog</Text>
            </div>
          </Col>
          <Col>
            <div className="suggested-box suggested-color-bullet" onClick={() => redirect('tool/command-tool')}>
              <img
                alt="Command Tool"
                src={require(`../../../static/category/main/commandTool.png`)}
                className="suggested-icon"
              />
              <Text className="suggested-tool-title">Command Tool</Text>
            </div>
          </Col>
          <Col>
            <div className="suggested-box suggested-color-blog" onClick={() => redirect('tool/one-shot-blog')}>
              <img
                alt="example"
                src={require(`../../../static/category/main/longForm.png`)}
                className="suggested-icon"
              />
              <Text className="suggested-tool-title">One-shot Blog</Text>
            </div>{' '}
          </Col>
          <Col>
            <div className="suggested-box suggested-color-improver" onClick={() => redirect('tool/cai-chat')}>
              <img
                alt="example"
                src={require(`../../../static/category/main/content-improver.png`)}
                className="suggested-icon"
              />
              <Text className="suggested-tool-title">Cai Chat</Text>
            </div>
          </Col>
          <Col>
            <div className="suggested-box suggested-color-sentence" onClick={() => redirect('tool/cai-rephrase')}>
              <img
                alt="example"
                src={require(`../../../static/category/main/sentence-rewriter.png`)}
                className="suggested-icon"
              />
              <Text className="suggested-tool-title">Cai Rephrase</Text>
            </div>
          </Col>
        </Row>
      </div>
    </SuggestedToolsStyled>
  );
};

export default SuggestedTools;

import styled from 'styled-components';
import { Modal } from 'antd';
import calendar from '../../../static/favourite/calendar-icon.svg';
import Swap from '../../../static/favourite/Swap.svg';

const FavoriteCardStyle = styled.div`
  padding: 24px 0px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0px;
    margin-top: 40px;
  }
  .ant-card-bordered {
    border: none;
  }
  .ant-card-body {
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 10px;
    padding: 15px 15px 8px !important;
  }
  .highlight {
    .ant-card-body {
      border: 1px solid #6c5afb;
    }
  }
`;

const CardFavHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
  }
  .favourite-top {
    display: flex;
    gap: 5px;
    align-items: center;
    img {
      width: 16px;
      height: 16px;
    }
  }
`;

const CardFavContent = styled.div`
  display: flex;
  flex-direction: column;

  .subhead-date {
    font-family: 'Poppins-Regular';
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #848484;
    margin-bottom: 15px;
  }
  .heading-title {
    font-size: 14px;
    font-family: 'Poppins-Semibold';
    font-weight: 500;
    margin-bottom: 4px;
  }
  .favourite-bottom {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    font-family: 'Poppins-Regular';
    .ant-typography {
      color: #848484 !important;
    }
    &::before {
      content: '';
      width: 90%;
      height: 38px;
      position: absolute;
      background: linear-gradient(360deg, #ffffff 5%, rgba(255, 255, 255, 0) 200%);
      bottom: 28px;
    }
  }
  .favchar-words {
    float: right;
    display: flex;
    align-items: center;
    gap: 5px;

    .dot-fav {
      color: #848484;
      font-size: 15px;
    }
  }
`;

const CardFavContentText = styled.div`
  height: 135px;
  overflow: hidden;
  .output-content {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    color: #282b3f;
    font-weight: 500;
  }
`;

const FavouriteHearderWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const FavouriteHeadingWrap = styled.div`
  padding: 19px 0px;
  background: #ffffff;
  border-bottom: 1px solid #e2e4e8 !important;
  @media (max-width: 768px) {
    padding: 0px 0px 5px;
    border-bottom: 0px !important;
  }

  h5.ant-typography {
    font-family: 'Poppins-SemiBold';
    font-size: 28px;
    color: #282b3f;
    margin-bottom: 0;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

const FavouriteDateWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 18px 0px;
  gap: 24px;
  background: #ffffff;
  border-bottom: 1px solid #e2e4e8 !important;
  @media screen and (max-width: 1200px) and (min-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 768px) {
    border-bottom: 0px !important;
    padding: 0px;
    align-items: flex-start !important;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 10px 16px;
    @media (max-width: 768px) {
      padding: 0px;
      display: flex;
      align-items: center;
    }
  }
  .fav-header-wrap {
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: 767px) {
      flex-direction: column;
      width: 100%;
      gap: 20px;
    }
  }
`;

const DateWrap = styled.div`
  .ant-picker-panels {
    flex-direction: column;
  }

  .ant-picker-suffix {
    content: url(${calendar});
    height: 18px !important;
  }

  .ant-picker-range-separator {
    content: url(${Swap});
    height: 18px !important;
  }

  .ant-picker-range-separator {
    padding: 0px;
  }
  .ant-picker {
    padding: 13px 15px;
    gap: 10px;
    width: 270px;
    height: 44px;
    background: #ffffff;
    border-radius: 8px;
    border: none;
    font-family: 'Poppins-Regular';
    font-size: 18px;
    @media (max-width: 767px) {
      width: 100% !important;
      padding: 13px 15px 13px 0px !important;
      height: auto;
      font-size: 18px;
      gap: 27px;
      margin: 0 auto;
    }
  }
  .ant-picker-input > input::placeholder {
    color: #8f9095 !important;
    font-family: 'Poppins-Regular';
    text-align: center !important;
  }
`;

const FilterWrap = styled.div`
  display: flex;
  flex-direction: row;
  // padding-left: 15px;
  gap: 24px;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    // padding-left: 24px;
  }
  .copied-fav {
    padding: 0px 34px !important;
    height: 44px;
    @media (max-width: 768px) {
      padding: 0px 40px !important;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }

  button {
    padding: 10px 16px;
    gap: 17px;
    color: #8f9095;
    height: 44px;
    font-family: 'Poppins-Regular';
    @media (max-width: 768px) {
      font-family: 'Poppins-Medium';
    }
    img {
      width: 18px;
      height: 18px;
    }
  }

  .ant-btn-light {
    color: #978cfb !important;
    font-weight: 500;
    background: #f5f4ff !important;
    font-size: 14px;
    font-family: 'Poppins-Medium';
  }

  img + span {
    margin-left: 0px;
  }
`;

const SearchWrap = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
  .ant-input-affix-wrapper {
    padding: 13px 15px;
    width: 266px;
    height: 44px;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    background: #ffffff;
    @media (max-width: 768px) {
      width: 100%;
      height: auto;
    }
    .ant-input-suffix {
      margin-left: 0px !important;
    }
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding-left: 0px !important;
    font-weight: 500;
    font-size: 14px;
    /* color: #8f9095; */
    font-family: 'Poppins-Regular';
  }
`;

// Modal Styling ---

const FavouriteModalStyle = styled.div`
  .favourite-input-output {
    margin: 6px 0px 30px;
    display: flex;
    flex-direction: column;
    .display-output {
      margin-top: 10px;
      .heading-text {
        font-family: 'Poppins-SemiBold';
        font-size: 12px;
      }
      .display-data {
        font-size: 12px;
        line-height: 18px;
        font-family: 'Poppins-Regular';
      }
    }
  }
`;

const ModalFavFooter = styled.div`
  display: flex;
  justify-content: space-between;

  .favIcon {
    display: flex;
    gap: 17px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
  }
  .favourite-bottom {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    font-family: 'Poppins-Medium';
    .ant-typography {
      color: #848484 !important;
    }
    .favchar-words {
      float: right;
      display: flex;
      align-items: center;
      gap: 5px;

      .dot-fav {
        color: #848484;
        font-size: 15px;
      }
    }
  }
`;
const ModalFavHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .Modal-fav-date {
    margin-bottom: 0px;
    font-size: 10px;
    font-family: 'Poppins-Regular';
    color: #737277;
    @media (max-width: 768px) {
      margin-left: 43px;
    }
  }

  img {
    width: 32px;
    height: 32px;
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }
  .fav-left-header {
    display: flex;
    gap: 19px;
    -webkit-box-align: center;
    .ant-typograpgy {
      font-size: 14px;
      font-family: 'Poppins-Semibold';
    }
  }
  .Modal-fav-title {
    display: flex;
    margin-bottom: 0px;
    align-items: center;
  }
`;

const Modalfav = styled(Modal)`
  @media (max-width: 768px) {
    width: auto;
  }

  .ant-menu-vertical {
    width: 200px;
    border: none;
    display: flex;
    flex-direction: column;
    height: 40px;
    padding-left: 0px !important;
  }

  .ant-modal-footer {
    display: none;
  }

  .ant-modal-close-x {
    display: none;
  }
`;

export {
  FavoriteCardStyle,
  CardFavHeader,
  CardFavContent,
  FavouriteHearderWrap,
  FavouriteHeadingWrap,
  FavouriteDateWrap,
  DateWrap,
  FilterWrap,
  SearchWrap,
  CardFavContentText,
  FavouriteModalStyle,
  ModalFavHeader,
  Modalfav,
  ModalFavFooter,
};

import styled from 'styled-components';

const WordCraftStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .card-chunk {
    width: 50%;
    flex: 0 0 50%;
  }
  @media only screen and (max-width: 379px) {
    .card-chunk {
      width: 100%;
      flex: 0 0 100%;
      h1 {
        margin-bottom: 0;
      }
      p {
        margin: 5px 0 20px 0;
      }
    }
  }
`;
const WordCardStyled = styled.div`
  @media only screen and (max-width: 379px) {
    text-align: center;
  }
  h1 {
    margin-bottom: 5px;
  }
  @media only screen and (max-width: 1500px) {
    h1 {
      font-size: 22px;
    }
  }

  .cycle {
    display: flex;
    flex-direction: column;
    margin: 21px 0 0 0;
    @media only screen and (max-width: 379px) {
      justify-content: center;
    }
    .next-cycle {
      color: ${({ theme }) => theme['primary-color']};
      font-size: 15px;
    }
    span {
      color: ${({ theme }) => theme['light-gray-color']};
      font-size: 13px;
      display: inline-block;
    }
  }
  .words-count {
    font-size: 23px;
    margin-bottom: 0px;
    transform: scale(1, 1.2);
  }
  .words-title {
    color: ${({ theme }) => theme['light-gray-color']};
    font-size: 14px;
  }
`;

export { WordCraftStyled, WordCardStyled };

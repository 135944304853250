/* ------------------------------------------------------ */
/*                       Words Craft                      */
/* ------------------------------------------------------ */

/**
 * Methadology
 * This component is used in Team Member Summary.
 * This is to display the words craft by a team member.
 *
 * This object contain all the team members data include team admin. This data is only available for team workspace.
 *
 * This is the payload of a current team
 * wordCraftMemberList = [
 *  {
 *    botId:
 *    currentTeamCraftedWords:
 *    currentTotalPricePerRun:
 *    lastCraftDate:
 *    lastCraftDateTime: Object { seconds: 1684923416, nanoseconds: 339000000 }
 *    lifeTimeCrafted:
 *    lifeTimeHits:
 *    lifeTimeTotalPricePerRun:
 *    todaysHits:
 *    totalHits:
 *    uid:
 *    workspaceType:
 *    workspaceUID:
 *  }
 * ]
 *
 * Used In:
 * src/container/TeamPortal/overview/MemberSummary.js
 */

import React from 'react';
import { Typography } from 'antd';
import { numberFormating } from '../../utility/utility';
import { Cards } from '../Card';
import { WordCardStyled, WordCraftStyled } from './styled';

const { Text, Title } = Typography;

const WordCarft = ({
  memberPortalWords,
  teamMemberUID,
  wordCraftMemberList,
  displayTitle,
  subHeading,
}: {
  teamMemberUID: string,
  wordCraftMemberList: [],
  displayTitle: 'words' | 'totalHits',
  subHeading: string,
}) => {
  console.log('wordCraftMemberList', wordCraftMemberList);
  const getWordsByUID = () => {
    const words =
      wordCraftMemberList &&
      wordCraftMemberList.find(({ uid }) => {
        return teamMemberUID === uid;
      });
    return { words: words?.currentTeamCraftedWords || 0, totalHits: words?.totalHits || 0 };
  };
  const { words, totalHits } = getWordsByUID(teamMemberUID);

  const displayNumbers = displayTitle === 'words' ? words : displayTitle === 'totalHits' ? totalHits : '-';
  return (
    <Cards border bodypadding={'29px'} borderRadius={'16px'} headless borderColor={'rgb(226, 236, 241)'}>
      <WordCraftStyled>
        <div className="card-chunk">
          <WordCardStyled>
            <Title level={2} className="words-count">
              {numberFormating(displayNumbers)}
            </Title>
            <Text className="words-title">{subHeading}</Text>
          </WordCardStyled>
        </div>
      </WordCraftStyled>
    </Cards>
  );
};

export default WordCarft;

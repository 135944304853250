const actions = {
  USERS_RECENT_BOTS_START: 'USERS_RECENT_BOTS_START',
  USERS_RECENT_BOTS_SUCCESS: 'USERS_RECENT_BOTS_SUCCESS',
  USERS_RECENT_BOTS_FAILED: 'USERS_RECENT_BOTS_FAILED',

  DOCUMENT_READ_START: 'DOCUMENT_READ_START',
  DOCUMENT_READ_SUCCESS: 'DOCUMENT_READ_SUCCESS',
  DOCUMENT_READ_FAILED: 'DOCUMENT_READ_FAILED',

  DOCUMENT_READ_BY_ID_START: 'DOCUMENT_READ_BY_ID_START',
  DOCUMENT_READ_BY_ID_SUCCESS: 'DOCUMENT_READ_BY_ID_SUCCESS',
  DOCUMENT_READ_BY_ID_FAILED: 'DOCUMENT_READ_BY_ID_FAILED',

  userRecentBotsStart: ({ toolName }) => {
    return {
      type: actions.USERS_RECENT_BOTS_START,
    };
  },
  userRecentBotsSuccess: userBots => {
    return {
      type: actions.USERS_RECENT_BOTS_SUCCESS,
      userBots,
    };
  },
  userRecentBotsFailed: () => {
    return {
      type: actions.USERS_RECENT_BOTS_FAILED,
    };
  },

  documentReadStart: () => {
    return {
      type: actions.DOCUMENT_READ_START,
    };
  },
  documentReadSuccess: ({ documentList }) => {
    return {
      type: actions.DOCUMENT_READ_SUCCESS,
      documentList,
    };
  },
  documentReadFailed: () => {
    return {
      type: actions.DOCUMENT_READ_FAILED,
    };
  },

  documentReadByIdStart: () => {
    return {
      type: actions.DOCUMENT_READ_BY_ID_START,
    };
  },
  documentReadByIdSuccess: ({ readDocumentById }) => {
    return {
      type: actions.DOCUMENT_READ_BY_ID_SUCCESS,
      readDocumentById,
    };
  },
  documentReadByIdFailed: () => {
    return {
      type: actions.DOCUMENT_READ_BY_ID_FAILED,
    };
  },
};

export default actions;

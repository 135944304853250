import styled from 'styled-components';

const TrialBillingWrapper = styled.div`
  background: #ffff;
  min-height: 100vh;

  .stripe-form {
    width: 96%;
    @media (max-width: 576px) {
      padding: 0px;
    }
    .billing-summary {
      margin-top: 80px;
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 30px;
      font-family: 'Poppins-SemiBold';
      @media (max-width: 576px) {
        margin-top: 50px;
      }
    }
  }
`;

const TrialBilling = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  max-width: 1200px;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  margin-left: auto; 
  margin-right: auto; 
  overflow:hidden;
  @media (max-width: 768px){
    padding: 0;
  }

  .ant-card-body{
  //  height: 855px;
    @media (max-width: 576px){
     // display: none;
    }
    @media (min-width: 731px) {
     // height: 855px
    }
  }

  .top-section {
    text-align: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 650px){
      padding: 2rem;
    }
    @media (max-width: 576px){
      padding: 1rem;
    }

    .heading-wrapper{
      display: inline-flex;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: center;
      
      .heading {
        margin-top: 0rem;
        font-family: 'Poppins-SemiBold';
        font-style: normal;
        font-size:  2.69rem;
        align-items: center;
        padding: 0px;
        gap: 6px;

        .thumbs-icon{
          width: 45px;
        }
        @media (max-width: 574px) {
          margin-top: 0rem;
          font-size: 26px;
          color: rgb(36, 38, 38); 
          gap: 0px;
          margin: 0px;
          font-weight: 600 !important;
          .thumbs-icon{
            width: 30px;
          }
        }
      }
      @media (max-width: 574px) { 
        gap: 0px;
        margin-bottom: 2rem;
      }
    } 
    .plan-type-switch {
      justify-content: center;
      align-items: center;
      width: auto;
      display: flex;
    }
  }

  
  .plan-comparison {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 0 1rem;
    gap: 40px;
    margin-top: 49px;
    /* .row-price { 
      gap: 24px; 
       @media(max-width: 1040px){
        display:flex ;
        justify-content: center;
      }
      @media(min-width: 768px){
        gap: 24px;
        justify-content: center;
      }
    } */
    @media (max-width: 650px) {
      flex-direction: column;
    }
    @media (max-width: 576px) {
      display: none;
    } 
  
  }  
  .plan-Recomend {
    width: 110px;
    height: 40px;
    border-radius: 10px;
  }

  .free-trial{
    width: 100%;
    height:48px
    font-size: 12px;
    background-color: #6c5afb
  }

  .statement {
      color: rgb(106, 114, 127);  
      text-align: center;
      font-size: 14px; 
      font-family: 'Poppins-Regular';
  }
  
  .block {
      padding: 2rem ;
      margin-top: 1.5rem; 
      width: 100%;
      border-width: 1px;
      border-color: rgb(219, 232, 238);
      border-style: solid;
      background-color: rgb(255, 255, 255);
      border-radius: 10px;

      @media (max-width: 574px){
          padding: 2rem;
          border: none;
          width: 100%;
      }
      @media (max-width: 574px){
          .selected-color{
            background-color: rgb(240, 238, 255);
          }
      }
 
      .selected-color{
        background-color: rgb(240, 238, 255);
      }
      .content {
          display: flex; 
          flex-direction: column;
          padding: 1rem 0rem;

          .ant-radio-wrapper{
              margin-left: 1.5rem;
              .ant-radio{
                  padding-bottom: 4px;
              }
          }
          .radio-text{
            font-size: 25px;
            font-family: 'Poppins-Regular';
          }
          @media (max-width: 574px){ 
              padding: 1rem 0rem;
          }
           
          .details {
              padding:  5px 1rem 1rem 0rem;
              .details-content {
                margin-left: 1.5rem;
                  display: flex;
                  flex-direction: column;
                  .price-description{
                    font-size: 13px;
                    color: rgb(106,114,127);
                    font-family: "Poppins-Regular";     
                  }
                  .point {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    font-size: 13px;
                    color: rgb(36, 38, 38);  
                    font-family: "Poppins-Regular"; 
                  }
                  .bullet {
                      color: rgb(36, 38, 38);  
                      margin: 6px 0px;    
                      font-size: 12px;  
                      font-family: "Poppins-Regular";
                    }       
                      .icon {
                          color: black;
                          margin-right: 10px;
                      }
                  } 
              }
            
      } 
      .terms-wrapper{
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: center;
          color: ${({ theme }) => theme['light-grey-color']};
          margin: 1rem 0rem;
          @media (max-width: 574px){
              padding: 1.5rem;
          } 
          .terms{ 
              color: #f81f9f; 
              padding: 3px; 
              margin-bottom: -2px;  
          }
      }
      .continue-button{
          margin-top: 2rem;
          display: flex;
          justify-content: center;     
          button {
                background-image: -moz-linear-gradient( 0deg, rgb(139,119,252) 0%, rgb(165,122,254) 100%);
                background-image: -webkit-linear-gradient( 0deg, rgb(139,119,252) 0%, rgb(165,122,254) 100%);
                background-image: -ms-linear-gradient( 0deg, rgb(139,119,252) 0%, rgb(165,122,254) 100%);
          }  
      }
      .notesWrapper{
          display: flex;
          flex-direction: column;
          margin-top: 2rem;
          
          .team-invite {
            text-align: center;
            font-size: 15px;
            color: #9D79FD;
          }
          .team-skip {
            text-align: center;
            font-size: 15px;
            color: #9D79FD;
            font-style: italic;
            cursor: pointer;
            text-decoration: underline;
          }
          .notes {
            margin-top: 1rem;
            text-align: center;
            font-size: 12px;
            color: #6a727f;
          }
          .issues {
            text-align: center;
            font-size: 12px;
          }
      }  

      .noTrialcc{
          display: flex;
          flex-direction: column;
          margin-top: 1rem;
          .error {
            text-align: center;
            font-size: 16px;
          }
          .success {
            color: #9D79FD;
            text-align: center;
            font-size: 16px;
          }
      }  
    }
    .row-price { 
      
      @media(max-width: 1040px){
        display:flex;
        justify-content: center;
      }
      @media(min-width: 768px){
        gap: 24px
        justify-content: center;
      }
    }
    .plan-list-title{
      font-family: 'Poppins-SemiBold';
      font-size: 16px;
      line-height: 24px;
      color: #282B3F;
      display: inline-block;
      margin-bottom: 10px;
    } 
`;

const SliderDiv = styled.div`
  .slider-custom {
    width: 50% !important;
    @media (max-width: 574px) {
      width: 90% !important;
    }
  }
`;

const TrialSliderRange = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  width: 100%;
  height: 30px;
  margin-top: 15px;
  margin-bottom: 24.5px;
  display: flex;
  width: 100%;

  .ant-typography {
    font-size: 14px;

    line-height: 21px;
    color: #8189a2;
    height: 21px;
  }
  @media (max-width: 574px) {
    width: 82% !important;
    margin-bottom: 0px;
    .ant-typography {
      color: #8189a2;
    }
  }
`;

const TrailMobilCard = styled.div`
  display: contents;
  @media (min-width: 577px) {
    display: none;
  }

  display: flex;
  gap: 16px;
  margin: auto !important;

  .ant-card {
    width: 164px;
    height: auto;
  }
  .ant-card-bordered {
    border: 0px;
  }

  .ant-card-body {
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 11px !important;
    gap: 10px;
    width: 164px;
    height: 90px;
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    box-shadow: 6px 8px 35px -23.4967px rgba(95, 103, 117, 0.1);
    border-radius: 12px;
    @media (max-width: 576px) {
      display: block;
    }
  }

  .ant-card-bordered {
    width: 0% !important;
  }

  .price-content-m {
    display: flex;
    flex-direction: column;
  }

  .plan-tilte-m {
    height: 30px;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #2b2a35;
    font-family: 'Poppins-Regular';
  }
  .plan-des-m {
    width: 133px;
    height: 36px;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #737277;
    text-align: center;
    font-family: 'Poppins-Regular';
  }

  .ant-carousel .slick-dots li button {
    display: none;
  }
`;

const BottomtextWrapper = styled.div`
  background: #ffffff;
  margin-top: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MobileTrail = styled.div`
  display: contents;
  @media (min-width: 577px) {
    display: none;
  }
  .ant-card-body {
    display: block;
    height: auto;
    width: 343px;
  }

  .custom {
    font-style: normal;

    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2b2a35;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .plan-slider {
    padding-top: 15px;
    width: 80%;
  }
  @media (max-width: 577px) {
    .plan-slider {
      padding-top: 5px;
    }
  }

  .started-head {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .started-text {
    font-size: 17px;
    color: #2b2a35;
    font-family: 'Poppins-Regular';
    line-height: 26px;

    font-style: normal;
    margin-bottom: 4px;
  }

  .reachText {
    color: #848484;
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 15px;
  }

  .custom-head {
    font-size: 17px;
    color: #2b2a35;
    font-family: 'Poppins-SemiBold';
    line-height: 26px;

    font-style: normal;
  }
  .text-head {
    font-style: normal;

    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #2b2a35;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const TrialText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;

  .Contactus-heading {
    text-decoration: underline;
  }
  @media (max-width: 576px) {
    max-width: 80%;
  }

  .ant-typography {
    text-align: center;
  }
  .heading {
    color: #282b3f;
    font-size: 16px;
    text-align: center;
    margin-bottom: 35px;
  }

  .sub-heading {
    color: #282b3f;
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
    text-align: center;
    margin-bottom: 0px !important;
  }
  .heading {
    color: #282b3f;
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
    text-align: center;
  }

  .head {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }

  .subhead {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 18px;
  }
`;

const TrialMobileBilling = styled.div`
  @media (max-width: 576px) {
    .plan-mobile-box {
      .ant-card-body {
        background: ${({ theme }) => theme['primary-color']};
        .plan-des-m,
        .plan-tilte-m {
          font-family: 'Poppins-Regular';
          color: ${({ theme }) => theme['white-color']} !important ;
        }
      }
    }
    .active-plan-mobile-box {
      .ant-card-body {
        background: green;
        .plan-des-m .plan-tilte-m {
          color: white !important;
        }
      }
    }
  }
`;

const PriceListStyled = styled.div`
  .price-content {
    height: 57px;
    display: flex;
    flex-direction: column;

    .plan-tilte {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #2b2a35;
    }

    .plan-des {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #737277;
    }

    .plan-heading {
      font-size: 40px;
      margin-bottom: 0px;
    }

    .plan-price {
      font-weight: 600;
      font-size: 40px;
      line-height: 47px;
      text-align: center;
      color: #2b2a35;
    }
    .plan-month {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #848484;
    }
  }
  .price-buttons {
    display: flex;
    justify-content: center;

    .select-price {
      margin-top: 20px;
      width: 100%;
      border-radius: 10px;
      height: 37px;
    }
    .highlight-words-button {
      background: rgba(249, 249, 255, 0.25) !important;
      color: white !important;
    }
    .price-start-button {
      width: 100%;
      height: 48px;
    } 
    .price-start-button {
      width: 100%;
      height: 48px;
    }
    .price-start-button-modified { 
      width: 100%;
      height: 48px;
      margin-top: 42px; // v1 pricing
      /* margin-top: 78px; v2 pricing */ 
    }

    .ant-anchor {
      color: inherit;
      .ant-anchor-ink {
        display: none;
      } 
      .ant-anchor-link {
        padding: 0px;
        .ant-anchor-link-title {
          color: inherit;
        }
        a:hover {
          color: inherit;
        }
      }
    }
    
  }
  
  .monthly {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    margin-top: 10px;
  }
  .plan-slider {
    padding-top: 15px;
    padding-bottom: 27px;
  }
  .ant-btn > span {
    display: inline-block;
    text-shadow: none;
  }
  .plan-recommended {
    color: ${({ theme }) => theme['white-color']}  !important;
    background:  rgba(249, 249, 255, 0.25) !important
    border-radius: 10px;
    color: #fff !important;
    padding: 0px 10px;
    align-items: center;
    font-size: 12px;
    line-height: 2rem;
    font-weight: 600;
    height: 2rem;
  }
  
  .plan-tiers{
    height: 790px;
  }

  .active-plan-box { 
    background:  ${({ theme }) => theme['primary-color']}  ;
    .plan-name,  .plan-subtext   {
    color:${({ theme }) => theme['white-color']} !important 
    }
    .plan-tilte,  .plan-des, .plan-price, .plan-month, .plan-list-title, .plan-heading, .list-single {
    color:${({ theme }) => theme['white-color']} !important 
    }
    
    .price-start-button, .price-start-button-modified  {
      color: ${({ theme }) => theme['primary-color']} ;
    }

    .price-button-button {
      color: ${({ theme }) => theme['white-color']}  !important;
      background:  rgba(249, 249, 255, 0.25) !important
      border-radius: 10px;
      color: #ffffff !important;
      background: rgba(249,249,255,0.25) !important;
      padding: 8px 10px;
      align-items: center;
    }
    .divider{
    background: #EEEEEE !important;
    }
    .icon{
      color: ${({ theme }) => theme['green-slider-color']} !important;
    }
    .ant-typography {
      color: rgba(255, 255, 255, 0.9) !important;  
    }  
  }
    

 `;

export {
  SliderDiv,
  TrialBilling,
  TrialBillingWrapper,
  TrialSliderRange,
  TrailMobilCard,
  TrialText,
  BottomtextWrapper,
  MobileTrail,
  TrialMobileBilling,
  PriceListStyled,
};

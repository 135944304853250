/* ------------------------------------------------------ */
/*                    Tool Library Card                   */
/* ------------------------------------------------------ */

/**
 * Methadology
 * This component is used to display information about the tool, including the tool's icon, title, and description.
 * When you click on the card, you are sent to the tool page.
 *
 * It renders on clicking on the categories. The display of categories are in 2 places.
 * 1. Category page /category
 * 2. Home page /
 * 3. Sidebar, Categories are also displayed in sidebar.
 *
 * When a category in the sidebar is clicked, a modal window will appear, and ToolLibraryCard will render inside of it.
 * The ToolLibraryCard renders below when a category on the Home page or Category page is clicked.
 *
 * Used In
 * src/container/ToolLibraryPopup/index.jsx
 * src/container/Category/ToolsLibrary.js
 */

import React from 'react';
import { Card, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getToolIcon } from '../../utility/utility';
import { BLOG_BUILDER_TOOL } from '../../constants';
import { ToolLibraryCardStyled, ToolPopCardWrapper } from './styled';
import PopularIcon from '../../static/tool-library/popular.svg';
import BetaIcon from '../../static/tool-library/beta.svg';
import NewIcon from '../../static/tool-library/new-tool-icon.svg';

const { Text } = Typography;

/**
 * @imagelink commonIcon: many tools have same icons, so many tools icons are group as same icons
 * @imagelink newIcon: when a new tool is launched, new icon icon displayed
 * @boolean popUp: it flags whether to open the popup or not.
 * @imagelink beta: beta icon - show beta icon on the tool card
 * @imagelink popular: popular icon
 * @string title: tool title
 * @string description: tool description
 * @string description: tool description
 * @string id: tool-id, it is very important, most of the tool images are same as tool id
 * @func setCardClick: when a tool card is clicked it returns the tool-id
 */

const ToolLibraryCard = ({ commonIcon, newIcon, popUp, beta, popular, title, description, id, setCardClick }) => {
  const navigate = useNavigate();

  // on ToolCard Click
  const handleClick = (id) => {
    setCardClick(id);

    // if the tool-id is blog-builder-tool then it redirects to long-form
    if (id === BLOG_BUILDER_TOOL) {
      navigate(`/long-form`);
    } else {
      // if popUp === false it redirect to tool page
      return !popUp ? navigate(`/tool/${id}`) : '';
    }
  };

  return (
    <ToolPopCardWrapper>
      <ToolLibraryCardStyled>
        {/* tool card */}
        <Card key={id} onClick={() => handleClick(id)}>
          <div className="top">
            <div className="icon-box box-secondary">
              <img src={getToolIcon(commonIcon || id)} alt="tool-icon" />
            </div>
            {newIcon ? <img className="second-img" src={NewIcon} alt="new-icon" /> : null}
            {popular ? <img className="second-img" src={PopularIcon} alt="objimage" /> : null}
            {beta ? <img className="second-img" src={BetaIcon} alt="objimage" /> : null}
            <div className="title">
              <Text className="tool-title-text">{title}</Text>
            </div>
            <p className="desc">{description}</p>
          </div>
        </Card>
      </ToolLibraryCardStyled>
    </ToolPopCardWrapper>
  );
};

export default ToolLibraryCard;

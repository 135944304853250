/* ------------------------------------------------------ */
/*                         Sidebar                        */
/* ------------------------------------------------------ */

/**
 *
 * There are 4 type of page link navigation
 * 1. Craftly admin page links
 * 2. Dashboard and Features link
 * 3. Popular links
 * 4. Tool Category
 */

import React, { useEffect, useState } from 'react';
import { Collapse, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import { useViewport } from '../../hooks/viewPort';
import SidebarMenu from './overview/SidebarMenu';
import { SidebarContainer, SidebarMenuStyle } from './styled';
import { SidebarPopularData, DashboardData, AdminPageData } from './overview/SideBarMenuList';
import { ALL_TOOLS, CRAFTER, isAdminRole, isStaffRole, STARTER, TOOL_CATEGORIES, TRIAL } from '../../constants';
import UpgradePro from '../../layout/overview/UpgradePro';
import ProjectSelection from '../ProjectSelection';
import WorkspaceSelection from '../../container/WorkspaceSelection';

const { Panel } = Collapse;

const Sidebar = ({ onMenuClicked }) => {
  const { width } = useViewport();
  const [displayCategories, setDisplayCategories] = useState([]);
  const [toolCollapse, setToolCollapse] = useState(true);
  const { allGroupFields } = useSelector((store) => store.adminFeatures);
  const { roles, plan } = useSelector((state) => state.fb.profile);

  // the display of tools are according to tools
  useEffect(() => {
    const categories =
      allGroupFields &&
      allGroupFields.filter(({ type }) => type === TOOL_CATEGORIES).sort((a, b) => a.categoryOrder - b.categoryOrder);
    categories &&
      categories.length > 0 &&
      setDisplayCategories([{ categoryName: 'All Tools', categoryId: ALL_TOOLS, iconSmall: ALL_TOOLS }, ...categories]);
  }, [allGroupFields]);

  const onChange = (key) => {
    setToolCollapse(!toolCollapse);
  };

  const url = window.location.pathname;
  const splitURL = url.split('/');

  let activeSideNav = null;
  if (url === '/' || url === '/category') {
    activeSideNav = 'dashboard';
  } else if (url === '/tool/sentence-rewriter') {
    activeSideNav = 'tool/sentence-rewriter';
  } else if (url === '/tool/content-improver') {
    activeSideNav = 'tool/content-improver';
  } else if (splitURL.length === 2) {
    activeSideNav = splitURL[1];
  } else if (splitURL.length === 3) {
    if (splitURL[1] === 'tool') {
      activeSideNav = null;
    }
  }

  return (
    <SidebarContainer width={width} toolCollapse={toolCollapse}>
      <div>
        {/* Selection of workspaces */}
        <WorkspaceSelection />

        {/* Only visible in mobile view  */}
        {width > 992 && <ProjectSelection />}

        {/* Craftly and Staff  Page links   */}
        {(isAdminRole(roles) || isStaffRole(roles)) && (
          <SidebarMenuStyle>
            <Collapse>
              <Panel header={'Admin'} key="1">
                {AdminPageData.map(({ menuItems, title }, index) => {
                  return (
                    <Collapse>
                      <Panel header={title} key={index}>
                        <SidebarMenu activeSideNav={activeSideNav} menudata={menuItems} onMenuClicked={onMenuClicked} />
                      </Panel>
                    </Collapse>
                  );
                })}
              </Panel>
            </Collapse>
          </SidebarMenuStyle>
        )}

        {/* Dashboard page links  */}
        <SidebarMenu activeSideNav={activeSideNav} menudata={DashboardData} onMenuClicked={onMenuClicked} />

        {/* Popular page links */}
        <SidebarMenuStyle>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="POPULAR" key="1">
              <SidebarMenu activeSideNav={activeSideNav} menudata={SidebarPopularData} onMenuClicked={onMenuClicked} />
            </Panel>
          </Collapse>
        </SidebarMenuStyle>
      </div>

      {/* Categories. it opens the popup to further selection of tool */}
      <SidebarMenuStyle>
        <Collapse defaultActiveKey={['1']} onChange={onChange}>
          <Panel header="SELECT A TOOL" key="1">
            <div className="scroller-menu">
              <Skeleton
                size={'small'}
                paragraph={{ rows: 8 }}
                active
                block
                loading={displayCategories && displayCategories.length === 0 ? true : false}
              >
                <SidebarMenu
                  activeSideNav={activeSideNav}
                  tools
                  menudata={displayCategories}
                  onMenuClicked={onMenuClicked}
                />
              </Skeleton>
            </div>
          </Panel>
        </Collapse>
      </SidebarMenuStyle>

      <>
        <div className="stickers">
          {(plan === CRAFTER || plan === STARTER || plan === TRIAL) && <UpgradePro plan={plan} />}
        </div>
      </>
    </SidebarContainer>
  );
};
export default Sidebar;

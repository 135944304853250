const actions = {
  DELETE_TEAM_START: 'DELETE_TEAM_START',
  DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_ERR: 'DELETE_TEAM_ERR',
  DELETE_TEAM_RESET: 'DELETE_TEAM_RESET',
  MIRROR_TEAM_UID: 'MIRROR_TEAM_UID',
  SAVE_TEAM_LIST: 'SAVE_TEAM_LIST',

  deleteTeamStart: () => {
    return {
      type: actions.DELETE_TEAM_START,
    };
  },

  deleteTeamSuccess: data => {
    return {
      type: actions.DELETE_TEAM_SUCCESS,
    };
  },

  deleteTeamError: err => {
    return {
      type: actions.DELETE_TEAM_ERR,
      err,
    };
  },
  resetTeam: () => {
    return {
      type: actions.DELETE_TEAM_RESET,
    };
  },
  showMirrorTeamUID: mirrorTeamUID => {
    return {
      type: actions.MIRROR_TEAM_UID,
      mirrorTeamUID,
    };
  },
  saveTeamList: teamList => {
    return {
      type: actions.SAVE_TEAM_LIST,
      teamList,
    };
  },
};

export default actions;

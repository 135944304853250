/* ------------------------------------------------------ */
/*                      Category Card                     */
/* ------------------------------------------------------ */

/**
 * Category Card is to display the Category name with Icon
 */

import React from 'react';
import { Card, Col, Row, Skeleton } from 'antd';
import { CardWrap } from '../styled.js';
import { ArrowUpOutlined } from '@ant-design/icons';

const { Meta } = Card;

const CategoryCard = ({ categories, selectedCategory }) => {
  const onCategorySelect = (categoryId) => {
    selectedCategory(categoryId);
  };
  return (
    <CardWrap>
      <div>
        <Row
          gutter={[
            { xs: 16, sm: 16, md: 24, lg: 24 },
            { xs: 16, sm: 16, md: 24, lg: 24 },
          ]}
          className="custom-grid"
          style={{ margin: '0 -12px' }}
        >
          {categories &&
            Array.isArray(categories) &&
            categories.map(({ categoryName, categoryId, icon }) => {
              return (
                <Col key={`${categoryId}-categoryid`}>
                  <Card
                    hoverable
                    onClick={() => onCategorySelect(categoryId)}
                    loading={categories.length === 0}
                    cover={<img alt="example" src={require(`../../../static/category/main/${icon}.png`)} />}
                    bordered={false}
                    className="category-image"
                  >
                    <Meta
                      title={categoryName}
                      avatar={
                        <ArrowUpOutlined
                          style={{ transform: 'rotate(45deg)', fontSize: '20px', color: 'rgb(157, 121, 253)' }}
                        />
                      }
                    />
                  </Card>
                </Col>
              );
            })}
          {categories && categories.length === 0 && <Skeleton active />}
        </Row>
      </div>
    </CardWrap>
  );
};

export default CategoryCard;

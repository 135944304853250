/* ------------------------------------------------------ */
/*                        Constant                        */
/* ------------------------------------------------------ */

/**
 * Thesse are keywords which are used during the development. this is used in code to compare or to check conditions
 */

export const APP_MODE_PRODUCTION = 'production';
export const APP_MODE_DEVELOPMENT = 'development';

// There are 4 type of users
export const ROLES = {
  ADMIN: 'admin',
  STAFF: 'staff',
  USER: 'user',
  FREE_USER: 'freeUser',
};

// There are 3 types of plan and total 7 type of conditions of plan
export const STARTER = 'starter';
export const CRAFTER = 'crafter';
export const TEAM = 'team';
export const ENTERPRISE = 'enterprise';
export const TRIAL = 'trial'; // when a user subscribe to any plan we give 5 days of trial.
export const NA = 'na'; // when a user dont have any plan.
export const NO_TRIAL_CC = 'noCreditCard'; // this is special offer from craftly, to sign up the users with out using their credit cards

export const CRAFTER_ACCESS = [CRAFTER, TEAM, TRIAL];
export const ENTERPRISE_ACCESS = [ENTERPRISE];
export const STARTER_ACCESS = [STARTER, NA, NO_TRIAL_CC];

export const totalPlanList = [STARTER, CRAFTER, TEAM, TRIAL, NA, NO_TRIAL_CC];

export const ROLES_PUBLIC = [ROLES.FREE_USER, ROLES.USER];
export const ROLES_PRIVATE = [ROLES.ADMIN, ROLES.STAFF];
export const ROLES_LIST = [ROLES.ADMIN, ROLES.STAFF, ROLES.FREE_USER];
export const SUPER_ROLES_LIST = [ROLES.ADMIN, ROLES.STAFF];

// status is for account.
export const STATUS = {
  PAUSE: 'pause',
  ACTIVE: 'active',
  DEACTIVE: 'deactive',
  DELETED: 'deleted',
};

// team status
export const TEAM_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  HOLD: 'hold',
  ACTIVE: 'active',
  DECLINE: 'decline',
  REMOVE: 'remove',
  LEAVE: 'leave',
  REACTIVATE: 'reactivate',
};

export const TEAM_ROLE = {
  ADMIN: 'admin',
  MEMBER: 'member',
};

export const isAdminRole = (r) => r && r.includes(ROLES.ADMIN);
export const isStaffRole = (r) => r && r.includes(ROLES.STAFF);
export const isUserRole = (r) => r && r.includes(ROLES.USER);
export const isFreeUserRole = (r) => r && r.includes(ROLES.FREE_USER);

// this is stripe Plan Status, subscription billing status
export const PLAN_STATUS = {
  NA: 'na',
  ERROR: 'error',
  CANCELED: 'canceled',
  ACTIVE: 'active',
  UNPAID: 'unpaid',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  PAST_DUE: 'past_due',
  EXPIRING_SOON: 'expiring_soon',
  TRIAL: 'trialing',
  NO_TRIAL_CC: 'noCreditCard',
};

// This is customized User status which is visible in user management
export const WITH_PLAN_STATUS = {
  APPROVED: 'approved',
  ACTIVE: 'active',
  PAUSE: 'pause',
  DELETED: 'deleted',
  EXPIRED: 'expired',
  DEACTIVE: 'deactive',
  ERROR: 'error',
  EXPIRING_SOON: 'expiring_soon',
};

export const ALL_PLAN_LIST = [
  { planTitle: 'Starter', planValue: STARTER },
  { planTitle: 'Crafter', planValue: CRAFTER },
  { planTitle: 'Trial using Credit Card', planValue: TRIAL },
  { planTitle: 'Team', planValue: TEAM },
  { planTitle: 'Trial without using Credit Card', planValue: NO_TRIAL_CC },
  { planTitle: 'No Plan Available', planValue: NA },
];
export const SHOW_BILLING = [PLAN_STATUS.ACTIVE, PLAN_STATUS.CANCELED, PLAN_STATUS.UNPAID, PLAN_STATUS.PAST_DUE];
export const PAID_PLAN = [STARTER, CRAFTER, TRIAL, TEAM];
export const INDIVIDUAL_PLAN = [STARTER, CRAFTER, TRIAL];
export const INCOMPELTE_PLAN = [PLAN_STATUS.INCOMPLETE, PLAN_STATUS.PAST_DUE];
export const SELECT_NEW_PLAN = [
  PLAN_STATUS.INCOMPLETE_EXPIRED,
  PLAN_STATUS.INCOMPLETE,
  PLAN_STATUS.CANCELED,
  PLAN_STATUS.NA,
  PLAN_STATUS.EXPIRING_SOON,
  WITH_PLAN_STATUS.EXPIRED,
  PLAN_STATUS.ERROR,
];
export const SHOW_TEAM_INFO = [STARTER, CRAFTER, TRIAL, NO_TRIAL_CC, NA];
export const SHOW_BILLING_PLANS = [STARTER, CRAFTER, TRIAL, NO_TRIAL_CC, NA];
export const CANCEL_PLAN_AUTOMATICALLY = [STARTER, CRAFTER, TRIAL];
export const FREE_PLAN = [TRIAL, NO_TRIAL_CC, NA];
export const SHOW_UPGRADE_BUTTONS = [PLAN_STATUS.ACTIVE, PLAN_STATUS.TRIAL]; // Active Plans

export const DONT_SHOW_CANCEL_BUTTONS = [
  PLAN_STATUS.INCOMPLETE,
  PLAN_STATUS.INCOMPLETE_EXPIRED,
  PLAN_STATUS.CANCELED,
  PLAN_STATUS.PAST_DUE,
];
export const SHOW_CANCEL_NOW_BUTTONS = [PLAN_STATUS.INCOMPLETE, PLAN_STATUS.PAST_DUE];

export const SHOW_ACTIVATE_NEW_PLAN_BUTTON = [
  PLAN_STATUS.CANCELED,
  PLAN_STATUS.NA,
  WITH_PLAN_STATUS.EXPIRED,
  PLAN_STATUS.INCOMPLETE_EXPIRED,
];

export const SHOW_NO_ACTIVE_PLAN_TOPBAR = [
  PLAN_STATUS.INCOMPLETE,
  PLAN_STATUS.INCOMPLETE_EXPIRED,
  PLAN_STATUS.CANCELED,
  PLAN_STATUS.PAST_DUE,
  WITH_PLAN_STATUS.EXPIRED,
  PLAN_STATUS.ERROR,
];

export const CHECK_APPROVE_SUBSCRIPTION = [PLAN_STATUS.ACTIVE, PLAN_STATUS.TRIAL];
export const ALLOW_TOOLS_PLAN_STATUS = [PLAN_STATUS.ACTIVE, PLAN_STATUS.EXPIRING_SOON];
export const BILLING_PLAN_STATUS = [PLAN_STATUS.ACTIVE];

export const CONTENT_CREATOR = 'Content Creator';
export const COMPANY = 'Company';
export const MARKETING = 'Marketing';
export const STUDENT = 'Student';
export const BLOG_WRITING = 'Blogs + Writing';
export const SOCIAL_MEDIA = 'Social Media';
export const EMAIL_OUTREACH = 'Email Outreach';
export const ADS_ECOMMERCE = 'Ads + eCommerce';
export const WEB_COPY = 'Web Copy';
export const SEO = 'SEO';
export const OTHER = 'Other';

export const TEAMS_DO = {
  STUDENT_COLLABORATION: 'Student Collaboration',
  CONTENT_CREATION: 'Content Creation',
  MARKETING: 'Marketing',
  OTHER: 'Other',
};

export const GOOGLE_PROVIDER = 'google.com';
export const PASSWORD_PROVIDER = 'password';

export const TECHNICAL_CRAFT_NOT_ALLOWED_MESSAGE = 'Technical_craft_not_allowed_message';
export const TRIAL_CRAFT_EXPIRE_MESSAGE = 'trail_craft_expire_message';
export const TRIAL_CRAFT_NO_HITS_LEFT_MESSAGE = 'trail_craft_no_hits_left_message';
export const CRAFT_WORDS_EXCEEDED_MESSAGE = 'craft_words_exceeded_message';
export const CRAFTER_CRAFT_WORDS_EXCEEDED_MESSAGE = 'crafter_craft_words_exceeded_message';
export const NO_PLAN_SUBSCRIPED_MESSAGE = 'no_plan_subscriped_message';
export const TRIAL_PLAN_CHANGE_DOWNGRADE = 'trial_plan_change_downgrade';

export const TEAM_MEMBER_CANT_CRAFT = 'team_member_cant_craft';
export const TEAM_MEMBER_ON_HOLD = 'team_member_on_hold';
export const TEAM_MEMBER_CHECK_IN_TEAM = 'team_member_check_in_team';

export const NO_PLAN_SUBSCRIPED = 'no_plan_subscriped';
export const PLAN_CANCELED = 'plan_canceled';
export const PLAN_PAST_DUE = 'plan_past_due'; // when credit card declined or bank didnt approve the transaction.
export const TRIAL_EXPIRED = 'trail-expired';

export const REDIRECT_INVITE_BASE_URL = `https://app.craftly.ai/`;

export const TOOL_ERROR = 'tool-error';
export const TOOL_USER_CANCEL_RESULTS = 'user-clicks-on-the-other-tool-while-the-current-tool-is-fetching-results';

export const NEW_ACCOUNT_CREATED = 'new-account-created';
export const DELETED_EMAIL_USED_IN_SIGN_UP = 'deleted-email-address-used-in-signup';
export const INVITED_USER_SIGN_UP = 'invited-user-sign-up';

export const PERMANENT_DELETE = 'permanentDelete';
export const ADMIN_DELETE = 'Admin deleted the account';
export const USER_DELETE = 'User deleted the account';

export const OLD_CRAFTLY = 'https://app.craftly.ai';
export const FIXED_CRAFTLY = 'https://craftly.web.app';
export const STAGING = 'https://craftly-staging.web.app';
export const DEVELOPMENT = 'https://craftly-development.web.app';
export const DEVELOPMENT_LOCAL = 'http://localhost:3001';
export const PRODUCTION_LOCAL = 'http://localhost:3000';
export const PRODUCTION = 'https://app.craftly.ai';

export const BILLING_SCHEME_TIERED = 'tiered';
export const BILLING_SCHEME_PER_UNIT = 'per_unit';

export const PERSONAL_WORKSPACE = 'Personal Workspace';
export const TEAM_WORKSPACE = 'Team Workspace';

export const FIREBASE_RECENT_PROJECT_OBJECT = {
  [TEAM_WORKSPACE]: 'teamRecentProject',
  [PERSONAL_WORKSPACE]: 'recentProject',
};
export const INPUT_LANGUAGE = 'input_language';
export const OUTPUT_LANGUAGE = 'output_language';

export const TOOL_CATEGORIES = 'tool-categories';
export const ALL_TOOLS = 'all-tools';
export const TOOL_GROUP_FIELDS = 'tool-group-fields';

/* ---------------------- INTERNET ---------------------- */
export const SESSION_EXPIRED = 'sessionExpired';
export const TOOLS_LOADING = 'We’ll be back in a jiffy! Tools are undergoing a quick update.';

/* ------------------------------------------------------ */
/*                        Stripe Id                       */
/* ------------------------------------------------------ */

export const TEAM_STRIPE_ID = [
  'price_1JvwmjJfRsQcG5z9uziXAiwm',
  'price_1K6iHXJfRsQcG5z953AD13ed',
  'price_1KX4clJfRsQcG5z9Qm3A1Ffo',
  'price_1KZIwuJfRsQcG5z9klNTXEUs',
]; // dev and live priceId

export const TEAM_STRIPE_CONFIG = 'bpc_1Jvy41JfRsQcG5z9YfXUnMXs';
export const promoCode =
  process.env.REACT_APP_MODE === APP_MODE_PRODUCTION
    ? 'promo_1JmvqKJfRsQcG5z9mY9kmykj'
    : 'promo_1JmwAIJfRsQcG5z94GhvJk4C'; // Promotion Coupons

/* ------------------------------------------------------ */
/*                          TEAM                          */
/* ------------------------------------------------------ */
export const TEAM_DASHBOARD_PAGE = 'team-dashboard';
export const TEAM_MEMBERS_PAGE = 'team-members';
export const TEAM_RESULTS = 'team-results';
export const CRAFTLY_ADMIN_TEAM_PAGE = 'team-management';
export const SHOW_REMAINING_HITS_BAR = 5;

/* -------------------- API SERVICES -------------------- */
export const TOOL_CRAFT = 'toolcraft';
export const CLOUD_FUNCTION = 'cloudfunctions';
export const LOCAL_BACKEND = 'localBackend';
export const BIQ_QUERY_IN = 'IN';
export const BIQ_QUERY_NOT_IN = 'NOT IN';
export const BIQ_QUERY_NOT_LIKE = 'NOT LIKE';
export const BIQ_QUERY_LIKE = 'LIKE';

/* ------------------------------------------------------ */
/*                      V2 TRIAL PAGE                       */
/* ------------------------------------------------------ */

export const MONTHLY_DURATION = 'monthly';
export const YEARLY_DURATION = 'yearly';
export const STRIPE_STARTER_MONTHLY_PRICEID = 'price_1LFMTbJfRsQcG5z9gO40kgvI';
export const STRIPE_STARTER_YEARLY_PRICEID = 'price_1LPtHOJfRsQcG5z9jJh3HNHR';
export const STRIPE_CRAFTER_MONTHLY_PRICEID = 'price_1LPv4WJfRsQcG5z9Dl0HhgDh';
export const STRIPE_CRAFTER_YEARLY_PRICEID = 'price_1LPvAFJfRsQcG5z972UapgdU';
export const STRIPE_YEARLY_DISCOUNT_COUPON = 'promo_1M7BNMJfRsQcG5z96vAXNfaE';
export const YEARLY_PROMO_CODE = 'YEARLYDISCOUNT';
export const STRIPE_PRODUCT_ID =
  process.env.REACT_APP_MODE === APP_MODE_PRODUCTION
    ? ''
    : {
        name: 'DevPromoCode30',
        ID: 'mukMCzPq',
      };

/* ------------------------------------------------------ */
/*                     V1 TRIAL PAGE                      */
/* ------------------------------------------------------ */

export const STRIPE_STARTER_MONTHLY_PRICEID_V1 =
  process.env.REACT_APP_MODE === APP_MODE_PRODUCTION
    ? 'price_1JmrMeJfRsQcG5z97XsvYcRr'
    : 'price_1Jmde1JfRsQcG5z9ejBgHPag';

export const STRIPE_STARTER_YEARLY_PRICEID_V1 =
  process.env.REACT_APP_MODE === APP_MODE_PRODUCTION
    ? 'price_1MWg0NJfRsQcG5z9sAvS8EtK'
    : 'price_1MTLxFJfRsQcG5z98Z3JERql';

export const STRIPE_CRAFTER_MONTHLY_PRICEID_V1 =
  process.env.REACT_APP_MODE === APP_MODE_PRODUCTION
    ? 'price_1JS6FzJfRsQcG5z9EPEotHFj'
    : 'price_1IsZBLJfRsQcG5z9pf2N3n0z';

export const STRIPE_CRAFTER_YEARLY_PRICEID_V1 =
  process.env.REACT_APP_MODE === APP_MODE_PRODUCTION
    ? 'price_1LIn5aJfRsQcG5z9ArmPgc6a'
    : 'price_1MTLyTJfRsQcG5z9Esgv7sIe';

export const CALENDLY = 'https://calendly.com/nicole-lytle/enterprise-set-up';

/* ------------------------------------------------------ */
/*                       Tool                             */
/* ------------------------------------------------------ */

export const FIELD_TYPE_TEXTAREA = 'textarea';
export const FIELD_TYPE_MULTIPLE_OPTIONS = 'multiple-options';
export const FIELD_TYPE_SECTION_POINTS = 'section-points';
export const FIELD_TYPE_SECTION_BUILDER = 'section-builder';
export const FIELD_TYPE_OUTLINE_POINTS = 'outline-points';
export const FIELD_TYPE_CONCLUSION_POINTS = 'conclusion-points';
export const STEP_TITLE_DESCRIPTION = 'title-description';
export const STEP_CHOOSE_TITLE = 'choose-title';
export const STEP_PARAGRAGH_POINT = 'paragraph-point';
export const STEP_CHOOSE_INTRO = 'choose-intro';
export const STEP_KEY_TOPICS = 'key-topics';
export const STEP_PARAGRAPH_BUILDER = 'paragraph-builder';
export const STEP_CONCLUSION = 'conclusion';
export const SUMMARY_UI = 'summary-ui';
export const TOOL_ID_BLOG_TITLES = 'blog-titles';
export const TOOL_ID_BLOG_INTRODUCTION = 'blog-introduction';
export const TOOL_ID_BLOG_OUTLINE = 'blog-outline-longform';
export const TOOL_ID_OUTLINE_BULLET_POINT = 'generate-ideas';
export const TOOL_ID_PRODUCT_BULLET = 'product-bullet-longform';
export const TOOL_ID_CONCLUSION = 'blog-conclusion-longform';

export const NEXT_BUTTON_CLICK = 'next-button-click';
export const GENERATE_BUTTON_CLICK = 'generate-button-click';

export const BLOG_BUILDER_TOOL = 'blog-builder';

/* ------------------------------------------------------ */
/*                       UI                               */
/* ------------------------------------------------------ */

export const BUTTON_PRIMARY = 'primary';
export const BUTTON_SECONDARY = 'secondary';
export const BUTTON_SUCCESS = 'success';
export const BUTTON_INFO = 'info';
export const BUTTON_WARNING = 'warning';
export const BUTTON_DANGER = 'danger';
export const BUTTON_LINK = 'link';
export const BUTTON_DARK = 'dark';
export const BUTTON_LIGHT = 'light';
export const BUTTON_WHITE = 'white';
export const BUTTON_DASHED = 'dashed';
export const BUTTON_ERROR = 'error';
export const BUTTON_DEFAULT = 'default';
export const BUTTON_LIGHT_PURPLE = 'light-purple';
export const BUTTON_GRADIENT = 'gradient';
export const BUTTON_PURPLE_GRADIENT = 'purple-gradient';
export const BUTTON_BLUE_GRADIENT = 'blue-gradient';
export const BUTTON_YELLOW_GRADIENT = 'yellow-gradient';

export const OPENAI_DEDUCTION = 'Unexpected error communicating with OpenAI.';

export const MODAL_TYPE = {
  CLOSE_ACCOUNT: 'closeAccount',
  CHANGE_USERNAME_EMAIL: 'changeUserNameEmail',
};
export const ALREADY_EXIST = 'already-exits';
export const IMAGE_TOOL_KEY = 'image-gen';
export const REPHRASE_TOOL_KEY = 'cai-rephrase';
export const CHAT_TOOL_KEY = 'cai-chat';
export const SPECIAL_TOOLS = ['command-tool', 'image-gen', 'one-shot-blog'];
export const SPECIAL_TOOLS_UI = [REPHRASE_TOOL_KEY, CHAT_TOOL_KEY];
export const CAI_CHAT_INPUT = 'cai-chat-input';
export const CAI_CHAT_OUTPUT = 'cai-chat-output';
export const RECENT_TOOL_NOT_DISPLAY = [CHAT_TOOL_KEY, IMAGE_TOOL_KEY];

export const PREPAID_CARD = 'prepaid';
export const TOOL_ID_ENTER_SUBMIT = [CHAT_TOOL_KEY];

/* ------------------------------------------------------ */
/*              Mobile Project Tool Selection             */
/* ------------------------------------------------------ */

/**
 * Specific design mobile header for main page
 *
 * Functionalities
 * 1. Project selections
 * 2. HeaderSearchTools - search tools
 */

import { Typography, Button } from 'antd';
import React from 'react';
import SearchInput from '../../static/category/SearchInputIcon.svg';
import { useSelector } from 'react-redux';
import HeaderSearchTools from '../HeaderSearchTools';
import ProjectSelection from '../ProjectSelection';
import { MobileProjectToolStyled } from './styled';

const { Text } = Typography;

const MobileProjectToolSelection = () => {
  const { allTools } = useSelector((state) => state.adminFeatures);
  const customButton = (
    <Button className="search-btn" type="primary" icon={<img alt="search" src={SearchInput}></img>} />
  );

  return (
    <MobileProjectToolStyled style={{ backgroundColor: window.scrollY > 1 ? '#fbfbfb' : 'transparent' }}>
      <ProjectSelection />
      <div className="search-tools">
        <Text className="banner-text">What are you writing today?</Text>
        <HeaderSearchTools
          categoryToolList={allTools}
          showSearchOptionsInDropdown={false}
          placeholder="Try article, press release, anything!"
          customButton={customButton}
        />
      </div>
    </MobileProjectToolStyled>
  );
};

export default MobileProjectToolSelection;

/* ------------------------------------------------------ */
/*                Account Settings Actions                */
/* ------------------------------------------------------ */

/**
 *
 * User Account related Reducers
 */

import { notification } from 'antd';
import { addNotificationInfo } from '../../components/utilities/notifications';
import {
  TEAM,
  GOOGLE_PROVIDER,
  PASSWORD_PROVIDER,
  PLAN_STATUS,
  TEAM_ROLE,
  TEAM_STATUS,
  USER_DELETE,
  TEAM_WORKSPACE,
  PERSONAL_WORKSPACE,
} from '../../constants';
import {
  ACCEPT_INVITATION,
  ACCOUNT_DELETED,
  ACCOUNT_SETTINGS_UPDATE,
  AUTH_EMAIL_SEND_PASSWORD_CHANGE,
  CHANGE_EMAIL_PASSWORD,
  CHANGE_GOOGLE_EMAIL,
  DECLINE_INVITATION,
  PASSWORD_SUCCESS_CHANGE,
  PENDING_INVITATION,
  PLAN_CANCEL_TEAM_JOIN,
  TEAM_MEMBER_DELETED,
  TEAM_NAME_CHANGED,
  TEAM_NO_LONGER_PRESENT,
  TEAM_PORTAL_ALREADY_TEAM_MEMBER,
  TEAM_PORTAL_INVITE_CREATED_SUCCESS,
  TEAM_PORTAL_INVITE_SEND,
} from '../../constants/content';
import { updateSubscription, cancelSubscriptionAuto } from '../../services/apiServices';
import { allSubscribers, capitalizeFirstLetter } from '../../utility/utility';
import { userDefaultWorkspace, userProjectDataSubmit, userProjectRecentSubmit } from '../UserProject/actionCreator';
import actions from './actions';

const addNotificationSuccess = (message) => {
  notification.success({
    message: message,
  });
};

const addNotificationError = (err) => {
  if (err !== 'Missing or insufficient permissions.') {
    notification.error({
      message: err,
    });
  }
};

const {
  changeEmailProgress,
  changeEmailSuccess,
  changeEmailFailed,
  closeAccountProgress,
  closeAccountSuccess,
  closeAccountError,
  onBoardingProgress,
  onBoardingSuccess,
  changeUsernameProgress,
  changeUsernameSuccess,
  changeUsernameFailed,
  changeProfileProgress,
  changeProfileSuccess,
  changeProfileFailed,

  setAddTeamUIDisplay,
  addTeamMemberStart,
  addTeamMemberSuccess,
  addTeamMemberFailed,
  checkTeamMemberAlreadyExists,

  fetchTeamMembersStart,
  fetchTeamMembersSuccess,
  fetchTeamMembersFailed,

  usersTeams,
  usersTeamsLoading,

  currentSelectedWorkspace,
  membersCraftList,

  memberBotsStart,
  memberBotsSuccess,
  memberBotsFailed,

  workspaceBotsStart,
  workspaceBotsSuccess,
  workspaceBotsFailed,

  currentMonthCraftTeam,

  teamPlanUpdateLoading,

  teamInviteActionStart,
  teamInviteActionSuccess,
  teamInviteActionFailed,

  updateTeamNameStart,
  updateTeamNameSuccess,
  memberCopiedSuccess,
} = actions;

// in order to change email, we need to reauthenticate the user. for this with email/password and googile sign-up have different approach to reauthenticate
const changeEmail = ({ newEmail, credentails, isGoogleAccount = false }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const { email } = firebase.auth().currentUser;
    const user = firebase.auth().currentUser;

    await dispatch(changeEmailProgress());
    const { provider, currentPassword } = credentails;

    // PASSWORD
    if (provider === PASSWORD_PROVIDER) {
      const credential = firebase.auth.EmailAuthProvider.credential(email, currentPassword);

      user
        .reauthenticateWithCredential(credential)
        .then(async (res) => {
          await user
            .updateEmail(newEmail)
            .then(async (res) => {
              // Update successful.
              dispatch(
                changeProfileFields({
                  fieldsToChange: { email: newEmail },
                  isEmailChange: { isGoogleAccount: false, emailChange: false },
                }),
              );
            })
            .catch(function (error) {
              console.log(`error`, error);
              addNotificationError(error.message);
              dispatch(changeEmailFailed());
            });
        })
        .catch(function (error) {
          console.log(`error`, error);
          addNotificationError(error.message);
          dispatch(changeEmailFailed());
        });
    }

    //GOOGLE
    if (provider === GOOGLE_PROVIDER) {
      const googleProvider = new firebase.auth.GoogleAuthProvider();

      // Reauthenticate with popup: google reauthentication
      user
        .reauthenticateWithPopup(googleProvider)
        .then(function (res) {
          user
            .updateEmail(newEmail)
            .then(async (res) => {
              // Update successful.

              // this shows whether to send email or not. if goole account no need to send email
              if (isGoogleAccount === true) {
                await firebase
                  .auth()
                  .sendPasswordResetEmail(newEmail)
                  .then(() => {
                    // Password reset email sent!
                    dispatch(
                      changeProfileFields({
                        fieldsToChange: { email: newEmail },
                        isEmailChange: { isGoogleAccount, emailChange: true },
                        isGoogleAccount,
                      }),
                    );
                    addNotificationSuccess(AUTH_EMAIL_SEND_PASSWORD_CHANGE);
                  })
                  .catch((error) => {
                    addNotificationError(error.message);
                  });
              } else {
                dispatch(
                  changeProfileFields({
                    fieldsToChange: { email: newEmail },
                    isEmailChange: { isGoogleAccount, emailChange: true },
                  }),
                );
              }
            })
            .catch(function (error) {
              console.log(`error`, error);
              addNotificationError(error.message);
              dispatch(changeEmailFailed());
            });
        })
        .catch(function (error) {
          console.log(`error`, error);
          addNotificationError(JSON.stringify(error.message));
          dispatch(changeEmailFailed());
        });
    }
  };
};

/**
 * This is generic Action function to update user profile in
 * COLLECTION: User
 */
const changeProfileFields = ({ fieldsToChange, isEmailChange = { isGoogleAccount: false, emailChange: false } }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    const firebase = getFirebase();
    const fields = Object.keys(fieldsToChange);
    fields.includes('username') && dispatch(changeUsernameProgress());
    try {
      await db
        .collection('users')
        .doc(uid)
        .set({ ...fieldsToChange }, { merge: true })
        .then(async () => {
          if (isEmailChange.emailChange) {
            dispatch(changeEmailSuccess());
            isEmailChange.isGoogleAccount
              ? addNotificationSuccess(CHANGE_GOOGLE_EMAIL)
              : addNotificationSuccess(CHANGE_EMAIL_PASSWORD);
            await firebase.auth().signOut();
          } else {
            dispatch(changeUsernameSuccess());
            dispatch(changeEmailSuccess());
            addNotificationSuccess(` ${capitalizeFirstLetter(Object.keys(fieldsToChange).toString())} updated.`);
          }
        });
    } catch (err) {
      await addNotificationError(err);
      await dispatch(changeEmailFailed());
      await dispatch(changeUsernameFailed());
    }
  };
};

// Firebase Email/Password change password
// it reautenticate the user with old password and then change the new password
// Set new password for google account
const changePassword = ({ newPassword, oldPassword, credentails }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const { email } = getState().fb.auth;
    const user = firebase.auth().currentUser;
    const { provider } = credentails;
    // EMAIL / PASSWORD
    if (provider === PASSWORD_PROVIDER) {
      const credential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
      user
        .reauthenticateWithCredential(credential)
        .then(function (res) {
          user
            .updatePassword(newPassword)
            .then(() => {
              addNotificationSuccess('Password successfully changed.');
            })
            .catch((error) => {
              addNotificationError(error.message);
            });
        })
        .catch(function (error) {
          // addNotificationError(error);
          console.log(`error`, error);
          addNotificationError(JSON.stringify(error.message));
        });
    }
    // GOOGLE_PROVIDER - set a new password
    if (provider === GOOGLE_PROVIDER) {
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      // Reauthenticate with popup: google reauthentication
      user
        .reauthenticateWithPopup(googleProvider)
        .then(function (res) {
          user
            .updatePassword(newPassword)
            .then(() => {
              addNotificationSuccess(PASSWORD_SUCCESS_CHANGE);
            })
            .catch((error) => {
              addNotificationError(error.message);
            });
        })
        .catch(function (error) {
          console.log(`error`, error);
          addNotificationError(JSON.stringify(error.message));
        });
    }
  };
};

// Set the project and user detail.
// More important set visitOnboarding to TRUE.
const boardingSteps = (userDetails) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const { jam, companyName, projectName } = userDetails;
    dispatch(onBoardingProgress());
    await dispatch(changeProfileDetails({ jam, companyName, visitOnboarding: true }, false));
    await dispatch(userProjectDataSubmit({ project: projectName }, false));
    dispatch(onBoardingSuccess());
  };
};

// Generic User Profile update action
// have the ability to control the notifications
const changeProfileDetails = (details, showNotification) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;

    dispatch(changeProfileProgress());
    try {
      await db
        .collection('users')
        .doc(uid)
        .set({ ...details }, { merge: true })
        .then(() => {
          showNotification && addNotificationSuccess(ACCOUNT_SETTINGS_UPDATE);
          dispatch(changeProfileSuccess());
        });
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(changeEmailFailed());
      await dispatch(changeProfileFailed());
    }
  };
};

const setAddTeamDisplay = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(setAddTeamUIDisplay());
  };
};

const resetTeamPlanUpdateLoading = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(teamPlanUpdateLoading({ teamPlanUpdateLoading: null }));
  };
};

/**
 * It creates the team on team onboarding process steps
 * User created a team plan in the past and has chosen to do so again.
 * @subID subscription id
 * @seatCounts seats quantity
 * @teamName team name
 * @teamPlanActivateOnly Team already have a team member, no need to add again
 */
const createTeamWithTeamPlan = ({ subID, seatCounts, teamName, teamPlanActivateOnly }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(addTeamMemberStart());
    const { allowTeamStarterPlan } = getState().fb.auth;

    const activateNow = Math.floor(Date.now() / 1000); // local date

    // Cloud-Function API request. Team Plan Update stripe
    updateSubscription({
      subID,
      quantity: seatCounts,
      activateNow,
      allowTeamStarterPlan,
    })
      .then((response) => {
        dispatch(teamPlanUpdateLoading({ teamPlanUpdateLoading: true }));
        teamPlanActivateOnly
          ? dispatch(addTeamMemberSuccess())
          : dispatch(addTeamMember({ details: { teamName }, createNewTeam: true, seatCounts }));
      })
      .catch((error) => {
        console.log(error.response);
      });
  };
};

/** This function does 2 functionality.
 * 1st to create a team and
 * 2nd to add a new member.
 *
 * Collection: team-members
 * Document: <TeamId> - Team admin UID
 *
 * createNewTeam: Creating a new group.
 * In that case, a new member must be added.
 * If not, just the team is activated.
 * The first member is the team admin by default.
 */
const addTeamMember = ({ details, createNewTeam, seatCounts }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    const { name, email, companyName } = getState().fb.profile;
    const projectData = getState().userProject.data;
    const firebase = getFirebase();

    if (createNewTeam) {
      dispatch(addTeamMemberStart());
      // Add team admin details.
      const adminMemberDetails = {
        teamMemberName: name,
        teamMemberEmail: email,
        role: TEAM_ROLE.ADMIN, // role is important in team
        lastActive: 'NA',
        teamInviteStatus: TEAM_STATUS.ACTIVE,
        teamMemberUID: uid,
        teamMemberRef: db.doc('users/' + uid),
      };
      try {
        await db
          .collection('team-members') // firebase team collection name.
          .doc(uid) // team admin UID is same as TeamId
          .set(
            {
              ...details,
              teamProjects: projectData, // teamAdmin list of Projects
              seatsCountAtTeamCreation: seatCounts,
              adminEmail: email, // AdminEmail.
              adminName: name, // Name
              adminUID: uid,
              members: firebase.firestore.FieldValue.arrayUnion(adminMemberDetails), // Team members, Admin is also a team member
              membersUID: [uid],
              teamStatus: TEAM_STATUS.ACTIVE, // Team status, ACTIVE or HOLD
            },
            { merge: true },
          )
          .then(() => {
            dispatch(addTeamMemberSuccess());
            addNotificationSuccess(TEAM_PORTAL_INVITE_CREATED_SUCCESS);
          });
      } catch (err) {
        await addNotificationError(err.message);
        await dispatch(addTeamMemberFailed());
      }
    } else {
      // Adding a new team member.
      try {
        const { teamMemberEmail } = details;
        dispatch(addTeamMemberStart());
        const memberDetails = { ...details };
        console.log('memberDetails', memberDetails);
        await db
          .collection('team-members')
          .doc(uid)
          .update({
            members: firebase.firestore.FieldValue.arrayUnion(memberDetails),
            membersUID: firebase.firestore.FieldValue.arrayUnion(memberDetails.teamMemberEmail),
            sendTeamInviteEmail: {
              teamMemberEmail: teamMemberEmail,
              teamName: capitalizeFirstLetter(companyName),
            },
          })
          .then(() => {
            dispatch(addTeamMemberSuccess());
            addNotificationSuccess(TEAM_PORTAL_INVITE_SEND);
          });
      } catch (err) {
        await addNotificationError(err.message);
        await dispatch(addTeamMemberFailed());
      }
    }
  };
};

// remove a team member
const deleteTeamMember = (updatedData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    try {
      await db
        .collection('team-members')
        .doc(uid)
        .set(
          {
            members: updatedData,
          },
          { merge: true },
        )
        .then(() => {
          addNotificationSuccess(TEAM_MEMBER_DELETED);
        });
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

const updateTeamName = (updatedData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    dispatch(updateTeamNameStart());
    try {
      await db
        .collection('team-members')
        .doc(uid)
        .set(
          {
            teamName: updatedData,
          },
          { merge: true },
        )
        .then(() => {
          dispatch(updateTeamNameSuccess());
          addNotificationSuccess(TEAM_NAME_CHANGED);
        });
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

// this triggers the cloud-function
const updateTeamProjects = ({ teamProjects, action }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const { uid } = getState().fb.auth;
    try {
      await db
        .collection('team-members')
        .doc(uid)
        .set(
          {
            teamProjects:
              action === 'ADD'
                ? firebase.firestore.FieldValue.arrayUnion(teamProjects)
                : action === 'DELETE'
                ? teamProjects
                : [],
          },
          { merge: true },
        );
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

/**
 *
 * @param {action type: accept, decline, remove} action
 * @param {adminUID, check whether the user is admin or not} teamAdminUID
 * @param {memberEmail} memberEmail
 * @param {selectedUID, specific user} selectedUID
 * @param {whem team member accept the request of team, their team plan will be cancelled} cancelSubscription
 * @param {whem team admin is performing actions.} teamAdminControl
 * @returns
 */

const updateTeamJoinAction = ({
  action,
  teamAdminUID,
  memberEmail,
  selectedUID,
  selectedName,
  cancelSubscription,
  teamAdminControl,
  teamMemberUID: memberUID,
}) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { name, activatedPlanDetails } = getState().fb.profile;
    const { uid } = getState().fb.auth;
    const { subscriptionList } = getState().stripe;
    const firebase = getFirebase();

    dispatch(teamInviteActionStart());
    try {
      // Create a reference to the SF doc.
      var sfDocRef = db.collection('team-members').doc(teamAdminUID);

      return await db
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then(async (sfDoc) => {
            if (!sfDoc.exists) {
              throw 'Document does not exist!';
            }

            const existingMembers = sfDoc.data().members;
            //? Removing a team member
            if (action === TEAM_STATUS.REMOVE) {
              // this remove the member from the existing member-list
              const updatedMemberList = existingMembers.filter(({ teamMemberEmail, teamMemberUID }) =>
                teamMemberUID ? memberUID !== teamMemberUID : teamMemberEmail !== memberEmail,
              );

              const { quantity } = activatedPlanDetails;
              const checkQuantity = quantity - existingMembers.length;
              //? This check the quantity of seats and team member quantity
              if (checkQuantity < 0) {
                if (updatedMemberList && updatedMemberList.length === quantity) {
                  updatedMemberList.forEach((updatedData) => {
                    const objIndex = updatedMemberList.findIndex(
                      ({ teamMemberEmail, role }) =>
                        teamMemberEmail === updatedData.teamMemberEmail && role !== TEAM_ROLE.ADMIN,
                    );
                    if (objIndex > 0) {
                      updatedMemberList[objIndex].teamInviteStatus = updatedMemberList[objIndex].previousInviteStatus;
                    }
                  });
                  // ones the team quantity equalize the HOLD will be removed.
                  transaction.update(sfDocRef, {
                    members: updatedMemberList,
                    teamStatus: 'active',
                    membersUID: firebase.firestore.FieldValue.arrayRemove(memberEmail),
                  });
                } else {
                  // this will keep going on untill the team member and team plan quantity equalize.
                  transaction.update(sfDocRef, {
                    members: updatedMemberList,
                    membersUID: firebase.firestore.FieldValue.arrayRemove(memberEmail),
                  });
                }
              } else {
                transaction.update(sfDocRef, {
                  members: updatedMemberList,
                  membersUID: firebase.firestore.FieldValue.arrayRemove(memberEmail),
                });
              }
            } else if (action === TEAM_STATUS.REACTIVATE) {
              const objIndex = existingMembers.findIndex(({ teamMemberEmail }) => teamMemberEmail === memberEmail);
              existingMembers[objIndex].teamInviteStatus = existingMembers[objIndex].previousInviteStatus;
              transaction.update(sfDocRef, { members: existingMembers });
            } else {
              const objIndex = existingMembers.findIndex(({ teamMemberEmail }) => teamMemberEmail === memberEmail);

              existingMembers[objIndex].teamInviteStatus = action;
              existingMembers[objIndex].teamMemberName = name;
              existingMembers[objIndex].teamMemberUID = uid;
              existingMembers[objIndex].teamMemberRef = db.doc('users/' + uid);
              transaction.update(sfDocRef, {
                members: existingMembers,
                membersUID: firebase.firestore.FieldValue.arrayUnion(uid),
              });
            }
          });
        })
        .then(() => {
          if (teamAdminControl) {
            addNotificationSuccess(`Action: ${action} successful.`);
          } else {
            action === TEAM_STATUS.DECLINE && addNotificationSuccess(DECLINE_INVITATION);
            action === TEAM_STATUS.ACCEPTED && addNotificationSuccess(ACCEPT_INVITATION);
            action === TEAM_STATUS.PENDING && addNotificationSuccess(PENDING_INVITATION);
          }

          dispatch(teamInviteActionSuccess());
          if (selectedUID && !teamAdminControl) {
            dispatch(
              setSelectedWorkSpace({
                selectedUID,
                selectedName,
                workspaceType: TEAM_WORKSPACE,
              }),
            );
          }

          //> If the user has active Starter or Crafter plan then plan will cancel automatically.
          const subID = subscriptionList && subscriptionList.length > 0 && subscriptionList[0].subscriptionId;
          if (cancelSubscription && subID) {
            cancelSubscriptionAuto({
              subID,
            })
              .then((response) => {
                addNotificationInfo(PLAN_CANCEL_TEAM_JOIN);
                // console.log(`response`, response);
              })
              .catch((error) => {
                console.log(error.response);
              });
          }
        })
        .catch((error) => {
          console.log('Transaction failed: ', error);
          dispatch(teamInviteActionFailed());
        });
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

/**
 *
 * @param {email} email
 * @returns
 * Adding new team members.
 *  Firestore
 * Collection: team-members
 * Document: <UID>
 * data: admin email
 *       admin name
 *       admin UID
 *       members - array
 *       membersUID
 *       seatsCountAtTeamCreation
 *       teamName
 *       teamProjects - array
 *       teamStatus
 */
const checkTeamMemberAlreadyInTeam = ({ email }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;

    dispatch(addTeamMemberStart());
    try {
      uid &&
        db
          .collection('team-members') // Firestore collection.
          .doc(uid)
          .get()
          .then(async (doc) => {
            const allMemberList = doc.data();
            // First Check is the team member is already present or not.
            const memberExits =
              allMemberList &&
              allMemberList.members &&
              Object.keys(allMemberList.members).length > 0 &&
              allMemberList.members.find(({ teamMemberEmail }) => teamMemberEmail === email);
            if (memberExits) {
              // If team member present then notification will be issued, of an exiting team member.
              dispatch(
                checkTeamMemberAlreadyExists({
                  checkTeamMemberExists: true,
                  checkTeamMemberDetails: null,
                }),
              );
              dispatch(addTeamMemberSuccess());
              addNotificationError(TEAM_PORTAL_ALREADY_TEAM_MEMBER(email));
            } else {
              dispatch(
                checkTeamMemberAlreadyExists({
                  checkTeamMemberExists: false,
                  checkTeamMemberDetails: null,
                }),
              );
              // object of new  team meber
              const addNewMemberData = {
                teamMemberName: '',
                teamMemberEmail: email,
                role: TEAM_ROLE.MEMBER,
                lastActive: 'NA',
                teamInviteStatus: TEAM_STATUS.PENDING,
              };
              // Sending the request of adding new team member
              // this action also create time but when adding a new team member it wont create a new team
              dispatch(addTeamMember({ details: addNewMemberData, createNewTeam: false }));
            }
          })
          .catch((error) => {
            addNotificationError(error.message);
            dispatch(addTeamMemberFailed());
          });
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(addTeamMemberFailed());
    }
  };
};

/**
 * Checking whether a user is a part of any team.
 * Placing a snapshot on entire collection so that it reads when a team admin sends the invite to a user.
 * When a team member accepts an email invitation from the team admin, we also add the team member's UID to the team profile.
 *
 * A team member may be a part of more than one team, but there must be only one active team for the tool to operate on the personal workspace.
 */
const checkUserPresentInTeam = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { email } = getState().fb.profile;
    const { uid } = getState().fb.auth;
    dispatch(usersTeamsLoading());
    try {
      const checkUserPresentInTeamSubscriber = await db.collection('team-members').onSnapshot(async (querySnapshot) => {
        const allMemberList = [];
        querySnapshot.forEach((doc) => {
          allMemberList.push(doc.data());
        });
        const newUserTeams = allMemberList
          .map(({ members, adminName, teamProjects, teamStatus, adminUID, teamName, seatsCountAtTeamCreation }) => {
            const teamDetails =
              members &&
              Array.isArray(members) &&
              members.find(({ teamMemberUID, teamMemberEmail }) => teamMemberUID === uid || teamMemberEmail === email);
            return (
              teamDetails && {
                seatsCountAtTeamCreation,
                teamProjects,
                teamName,
                adminName,
                adminUID,
                ...teamDetails,
                otherMembers: members,
                teamStatus,
              }
            );
          })
          .filter(Boolean);

        // it checks the team and team invite status. a team member can use any tool on these 2 status
        const memberTeamsWithOutHold = newUserTeams.filter(
          ({ teamInviteStatus, teamStatus }) =>
            teamInviteStatus !== TEAM_STATUS.HOLD &&
            teamInviteStatus !== TEAM_STATUS.ACTIVE &&
            teamInviteStatus !== TEAM_STATUS.PENDING &&
            teamStatus !== TEAM_STATUS.HOLD,
        );

        // a team member can be a part of multiple teams
        dispatch(usersTeams({ usersTeams: newUserTeams || [], memberTeamsWithOutHold }));
      });
      allSubscribers.findIndex((x) => x.name === 'checkUserPresentInTeamSubscriber') === -1 &&
        allSubscribers.push({ func: checkUserPresentInTeamSubscriber, name: 'checkUserPresentInTeamSubscriber' });
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

// this fetch the team members from the firestore collection
// Firestore
// Collection: team-members
// Document : <UID>
//
const fetchTeamMembers = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    dispatch(fetchTeamMembersStart());
    try {
      const teamMemberSubscriber =
        uid &&
        db
          .collection('team-members')
          .doc(uid)
          .onSnapshot((doc) => {
            const { teamName, members, adminUID, adminName, teamProjects, teamStatus } = doc.data() || {
              adminName: '',
              teamName: null,
              members: [],
              adminUID: null,
              teamProjects: [],
              teamStatus: null,
            };

            dispatch(
              fetchTeamMembersSuccess({
                teamMembersList: members || [],
                teamName: teamName,
                teamAdminUID: adminUID,
                teamAdminName: adminName,
                teamProjectsList: teamProjects,
                teamStatus,
              }),
            );
          });
      allSubscribers.findIndex((x) => x.name === 'teamMemberSubscriber') === -1 &&
        allSubscribers.push({ func: teamMemberSubscriber, name: 'teamMemberSubscriber' });
    } catch (err) {
      dispatch(fetchTeamMembersFailed());
      await addNotificationError(err.message);
    }
  };
};

const fetchCopiedMembersContent = (workspaceUID) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      const CopyCollectionRef = await db.collection('team-copy-content').where('workspaceUID', '==', workspaceUID);
      CopyCollectionRef.onSnapshot(async (snapshot) => {
        const memberCopiedContent = [];
        snapshot.forEach((doc) => {
          memberCopiedContent.push({ ...doc.data(), botId: doc.id });
        });
        await dispatch(memberCopiedSuccess({ memberCopiedContent }));
      });
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

//
/**
 * When default workspace is set.
 *
 * This can be either Team or Personal workspace
 *
 */
const setSelectedWorkSpace = ({ selectedUID, selectedName, workspaceType }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const { usersTeams } = getState().accountSettings;

    try {
      if (selectedUID) {
        dispatch(usersTeamsLoading());
        // if the changed workspace in team workspace or not.
        if (usersTeams && workspaceType === TEAM_WORKSPACE) {
          // use to check whether the user is team member or team admin
          await dispatch(
            currentSelectedWorkspace({
              selectedUID,
              selectedName,
              workspaceType: TEAM_WORKSPACE,
              canTeamMemberCraft: true,
              checkHoldAccount: false,
              checkUserIsStillInTeam: true,
              teamPlanActive: true,
              teamWorkspaceTrigger: true,
            }),
          );
          // Save selected workspace in firebase collection.
          await dispatch(
            userDefaultWorkspace({
              selectedUID,
              selectedName,
              workspaceType: TEAM_WORKSPACE,
            }),
          );
        } else {
          dispatch(
            currentSelectedWorkspace({
              selectedUID,
              selectedName,
              workspaceType: PERSONAL_WORKSPACE,
              canTeamMemberCraft: true,
            }),
          );
          // Save selected workspace in firebase collection.
          dispatch(
            userDefaultWorkspace({
              selectedUID,
              selectedName,
              workspaceType: PERSONAL_WORKSPACE,
            }),
          );
        }
      }
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

// When workspace is changed  // this needs to handle the teamWorkspace
const onWorkspaceChanged = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    const { teamRecentProject } = getState().userProject;
    const { usersTeams, selectedWorkspace } = getState().accountSettings;

    const { selectedUID, workspaceType: checkWorkspace } = selectedWorkspace || {
      selectedUID: null,
      workspaceType: null,
    };

    try {
      if (selectedUID) {
        dispatch(usersTeamsLoading());
        // if the changed workspace in team workspace or not.
        if (usersTeams && checkWorkspace === TEAM_WORKSPACE) {
          // use to check whether the user is team member or team admin
          const teamAdminPlanCheckSubscriber = db
            .collection('users')
            .doc(selectedUID)
            .onSnapshot(async (doc) => {
              const data = doc.data();
              // console.log('workspaceType', workspaceType);
              // this check is to verify becuase onSnapshot automatically triggers when there is a change in user profile
              const { selectedWorkspace } = getState().accountSettings;

              const { selectedUID, selectedName, workspaceType } = selectedWorkspace || {
                selectedUID: null,
                selectedName: null,
                workspaceType: null,
              };

              if (workspaceType === TEAM_WORKSPACE) {
                if (data) {
                  const { plan, planStatus } = data;
                  const { teamProjects } = (usersTeams &&
                    usersTeams.find(({ adminUID }) => adminUID === selectedUID)) || {
                    teamProjects: [],
                  };
                  const checkUserIsStillInTeam =
                    usersTeams.filter(({ teamInviteStatus, adminUID }) => adminUID === selectedUID).length === 0
                      ? false
                      : true;
                  const checkHoldAccount =
                    usersTeams.filter(
                      ({ teamInviteStatus, adminUID }) =>
                        teamInviteStatus === TEAM_STATUS.HOLD && adminUID === selectedUID,
                    ).length === 0
                      ? false
                      : true;
                  const canTeamMemberCraft =
                    checkUserIsStillInTeam &&
                    !checkHoldAccount &&
                    plan === TEAM &&
                    (planStatus === PLAN_STATUS.ACTIVE || planStatus === PLAN_STATUS.EXPIRING_SOON);
                  const teamPlanActive =
                    plan === TEAM && (planStatus === PLAN_STATUS.ACTIVE || planStatus === PLAN_STATUS.EXPIRING_SOON);
                  await dispatch(
                    currentSelectedWorkspace({
                      selectedUID,
                      selectedName,
                      workspaceType: TEAM_WORKSPACE,
                      canTeamMemberCraft,
                      checkUserIsStillInTeam,
                      checkHoldAccount,
                      teamProjects,
                      teamPlanActive,
                      teamWorkspaceTrigger: false,
                    }),
                  );
                  // Save selected workspace in firebase collection.
                  await dispatch(
                    userDefaultWorkspace({
                      selectedUID,
                      selectedName,
                      workspaceType: TEAM_WORKSPACE,
                    }),
                  );
                  if (!teamRecentProject) {
                    const pickFirstProject =
                      teamProjects && Array.isArray(teamProjects) && teamProjects.length > 0 && teamProjects[0];
                    pickFirstProject && dispatch(userProjectRecentSubmit(pickFirstProject));
                  }
                } else {
                  // ToDo if admin delete the account.
                  addNotificationInfo(TEAM_NO_LONGER_PRESENT);
                }
              }
            });

          allSubscribers.findIndex((x) => x.name === 'teamAdminPlanCheckSubscriber') === -1 &&
            allSubscribers.push({
              func: teamAdminPlanCheckSubscriber,
              name: 'teamAdminPlanCheckSubscriber',
            });
        }
      }
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

const closeUserAccount = ({ credentails }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const { email } = getState().fb.auth;
    const user = firebase.auth().currentUser;

    await dispatch(closeAccountProgress());
    // console.log(`credentails`, credentails);

    // await dispatch(deleteingData());
    // this is for the authentication // THIS IS SENSITIVE INFORMATION
    const { provider, currentPassword } = credentails;
    if (provider === PASSWORD_PROVIDER) {
      const credential = firebase.auth.EmailAuthProvider.credential(email, currentPassword);
      user
        .reauthenticateWithCredential(credential)
        .then(async (res) => {
          dispatch(deleteingData(credential));
        })
        .catch(function (error) {
          addNotificationError(error.message);
          dispatch(closeAccountError());
        });
    }

    if (provider === GOOGLE_PROVIDER) {
      const googleProvider = new firebase.auth.GoogleAuthProvider();

      // Reauthenticate with popup: google reauthentication
      user
        .reauthenticateWithPopup(googleProvider)
        .then(
          (result) => {
            const credential = result.credential;
            dispatch(deleteingData(credential));
          },
          function (error) {
            console.log(`error`, error);
            addNotificationError(error.message);
            dispatch(closeAccountError());
          },
        )
        .catch(function (error) {
          console.log(`error`, error);
          addNotificationError(error.message);
          dispatch(closeAccountError());
        });
    }
  };
};

const deleteingData = (credential) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = getFirestore();
    const user = firebase.auth().currentUser;
    const { uid } = getState().fb.auth;
    user
      .reauthenticateWithCredential(credential)
      .then(async (res) => {
        const dataBatch = db.batch();
        const usersRef = db.collection('users').doc(uid);
        const projectRef = db.collection('projects').doc(uid);
        const userBotsRef = db.collection('users-bot').doc(uid);
        dataBatch.update(usersRef, {
          status: 'deleted',
          plan: 'na',
          displayPlanStatus: 'deleted',
          activatedPlanDetails: null,
          deletedBy: USER_DELETE,
        });
        dataBatch.delete(projectRef);
        dataBatch.delete(userBotsRef);
        await dataBatch
          .commit()
          .then(() => {
            user
              .delete()
              .then(async () => {
                addNotificationSuccess(ACCOUNT_DELETED);
                await dispatch(closeAccountSuccess());
                setInterval(() => {
                  window.location.reload();
                }, 2000);
              })
              .catch(function (error) {
                addNotificationError(error.message);
                dispatch(closeAccountError());
              });
          })
          .catch((error) => {
            console.error('Error removing document: ', error);
          });
      })
      .catch(function (error) {
        console.log(`error`, error);
        addNotificationError(error.message);
        dispatch(closeAccountError());
      });
  };
};

/**
 * @param uid This either will be user UID or workspace UID. Remember User UID is the workspace UID.
 * this function is fetching the words from the users-bot collection.
 * This fetch all the results in the user-bots and from team-memberlist data is filtered.
 */
const fetchTeamMembersBots = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    /// it is used to get each team member individual content bot data.
    const { selectedWorkspace } = getState().accountSettings;
    const { selectedUID } = selectedWorkspace;
    let query = db.collection('teams-bot').doc(selectedUID).collection('team-member-bot');
    try {
      const fetchTeamMemberSubscriber = await query.onSnapshot((snapshot) => {
        const wordCraftMemberList = [];
        snapshot.forEach((doc) => {
          const data = doc.data();

          wordCraftMemberList.push({ ...data });
        });
        // console.log('wordCraftMemberList', wordCraftMemberList);
        dispatch(membersCraftList({ wordCraftMemberList: wordCraftMemberList }));
      });

      allSubscribers.findIndex((x) => x.name === 'fetchTeamMemberSubscriber') === -1 &&
        allSubscribers.push({ func: fetchTeamMemberSubscriber, name: 'fetchTeamMemberSubscriber' });
    } catch (err) {
      console.log(`error`, err);
    }
  };
};

// For Team portal use
const getWorkSpaceBots = ({ workspaceAdminUID }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(workspaceBotsStart());
      const teamBotsCollectionRef = await db
        .collection('team-members') // get all team-members bots
        .doc(workspaceAdminUID)
        .collection('craft');

      teamBotsCollectionRef.onSnapshot(async (snapshot) => {
        const workspaceBots = [];
        snapshot.forEach((doc) => {
          workspaceBots.push({ ...doc.data(), botId: doc.id });
        });
        await dispatch(workspaceBotsSuccess({ workspaceBots }));
      });
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(workspaceBotsFailed());
    }
  };
};

// For Team portal use //...
const getMemberBots = ({ workspaceAdminUID, teamMemberUID }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(memberBotsStart());
      const query = await db
        .collection('team-members') // members-bots to get top 3 tools
        .doc(workspaceAdminUID)
        .collection('craft')
        .where('uid', '==', teamMemberUID);

      const botCollectionRef = query.onSnapshot(async (snapshot) => {
        const membersBots = [];
        snapshot.forEach((doc) => {
          const { toolName } = doc.data();
          membersBots.push({ toolName, botId: doc.id });
        });
        await dispatch(memberBotsSuccess({ memberBots: membersBots }));
      });

      allSubscribers.findIndex((x) => x.name === 'botCollectionRef') === -1 &&
        allSubscribers.push({ func: botCollectionRef, name: 'botCollectionRef' });
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(memberBotsFailed());
    }
  };
};

// Total Words Crafted in current month from team members
// For Team portal use
const getTopToolsUsedByTeamMembers = ({ workspaceUID }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      if (workspaceUID) {
        await dispatch(memberBotsStart());
        const getCurrentWordsCraftSubscriber = await db
          .collection('team-members')
          .doc(workspaceUID)
          .collection('craft')
          .orderBy('dateInFormat', 'desc')
          .onSnapshot(async (snapshot) => {
            const teamAllBots = [];
            snapshot.forEach((doc) => {
              teamAllBots.push({ ...doc.data(), botId: doc.id });
            });

            //Calculating Top tools for a team
            const teamUsedTools = teamAllBots
              .filter(({ toolName }) => toolName !== undefined)
              .map((item) => item.toolName)
              .map((tool, index, data) => {
                const count = data.filter((toolName) => toolName === tool).length;
                return { tool, count };
              });

            const topToolsUsedByTeamMembers = [...new Set(teamUsedTools.map(JSON.stringify))]
              .map(JSON.parse)
              .sort(function (a, b) {
                return parseFloat(b.count) - parseFloat(a.count);
              })
              .slice(0, 3);
            await dispatch(currentMonthCraftTeam({ topToolsUsedByTeamMembers }));
          });
        allSubscribers.findIndex((x) => x.name === 'getCurrentWordsCraftSubscriber') === -1 &&
          allSubscribers.push({ func: getCurrentWordsCraftSubscriber, name: 'getCurrentWordsCraftSubscriber' });
      } else {
        await dispatch(currentMonthCraftTeam({ topToolsUsedByTeamMembers: [] }));
      }
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(memberBotsFailed());
    }
  };
};

const updateTeamMemberVisit = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    try {
      uid &&
        (await db.collection('users').doc(uid).set(
          {
            visitTeamPage: true,
          },
          { merge: true },
        ));
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

export {
  changeEmail,
  changeProfileFields,
  changePassword,
  boardingSteps,
  closeUserAccount,
  changeProfileDetails,
  addTeamMember,
  checkTeamMemberAlreadyInTeam,
  fetchTeamMembers,
  deleteTeamMember,
  checkUserPresentInTeam,
  updateTeamJoinAction,
  setSelectedWorkSpace,
  fetchTeamMembersBots,
  setAddTeamDisplay,
  getMemberBots,
  getWorkSpaceBots,
  getTopToolsUsedByTeamMembers,
  createTeamWithTeamPlan,
  resetTeamPlanUpdateLoading,
  updateTeamName,
  updateTeamProjects,
  fetchCopiedMembersContent,
  updateTeamMemberVisit,
  onWorkspaceChanged,
};

import React from 'react';
import { Typography } from 'antd';
import { TrialText, BottomtextWrapper } from '../styled';

const TrailFaq = () => {
  const { Text } = Typography;

  return (
    <BottomtextWrapper>
      <TrialText>
        <Text className="heading">
          Having trouble choosing a plan? Read below or <span className="Contactus-heading">contact us</span> for help!
        </Text>

        <Text className="sub-heading">How are word credits calculated?</Text>
        <Text className="subhead">
          You are allotted a monthly word budget nased on the plan you selected, which is reduced each time the app
          produces an output.
        </Text>

        <Text className="sub-heading">Can I get more credits?</Text>
        <Text className="head">
          You have the flexibility to increase your budget at any time during the month. If you find
        </Text>
        <Text className="subhead">
          yourself in a situation where you need more words, just upgrade to the next pricing tier to keep crafting.
        </Text>

        <Text className="sub-heading">Can I change my plan after I choose one?</Text>
        <Text className="head">
          You can upgrade or downgrade your plan at any time to better suit your needs. Whether you need more
        </Text>

        <Text className="subhead">content or want to save money, we have a plan that will work for you.</Text>
      </TrialText>
    </BottomtextWrapper>
  );
};

export default TrailFaq;

import styled from 'styled-components';

const TeamSelectionWrapper = styled.div`
  display: flex;
  padding-right: 1rem;
  margin-bottom: 10px;
  cursor: pointer;
  flex-direction: column;

  .ant-row {
    width: 100%;
    .workspace-text {
      font-size: 10px;
      color: #9979fd;
      font-family: 'Poppins-Regular';
      @media (max-width: 991px) {
        color: ${({ settingsView }) => (settingsView ? `#282b3f` : '#fff')};
      }
    }
    .selected-team {
      display: flex;
      align-items: flex-start;
      width: ${({ settingsView }) => (settingsView ? `100%` : '')};
    }
    .team-image {
      margin-right: 1rem;
    }
    .team-name {
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      width: ${({ settingsView }) => (settingsView ? `100%` : '8rem')};
      .name {
        font-size: ${({ settingsView }) => (settingsView ? `14px` : '16px')};
        word-break: break-all;
        color: #282b3f;
        font-family: 'Poppins-Medium';
        line-height: 14px;
        margin-top: 0px;
        @media (max-width: 991px) {
          color: ${({ settingsView }) => (settingsView ? `#282b3f` : '#fff')};
        }
      }
      .selectionType {
        font-size: 12px;
        margin-bottom: 0px;
        color: #282b3f;
        margin-top: 0px;

        @media (max-width: 991px) {
          color: ${({ settingsView }) => (settingsView ? `#282b3f` : '#fff')};
        }
      }
    }
  }

  .team-list {
    padding: 9px 10px;
    margin-top: 12px;
    border-bottom: 1px solid #e2e4e8;
  }
`;
export { TeamSelectionWrapper };

/* ------------------------------------------------------ */
/*                      Create New User                   */
/* ------------------------------------------------------ */

/* ----------------- CRAFTLY ADMIN PAGE ----------------- */

/**
 * Craftly admin can also send add the user.
 * This creates a magic link and the code which is being sent to their email.
 * The account will automatically created.
 * In this method the email-link has an expiration date and time after that code will be expired.
 * this is the Modal UI
 *
 * There are 2 ways to add a member
 * 1. Authenticate with Firebase Using Email Link in JavaScript in which a user profile is being created and a link is being sent to email.
 * link: https://firebase.google.com/docs/auth/web/email-link-auth#web-version-9
 *
 * 2. Simple add a user with unique id in user collection
 *
 */

import React from 'react';
import { Modal, Input, Form, Radio } from 'antd';
import { ROLES, STATUS } from '../../../constants';

const AddUser = ({ createUserOpen, onCreateNew, onCancel, confirmLoading, allUsers }) => {
  const [form] = Form.useForm();

  return (
    <Modal
      open={createUserOpen}
      title="Create a new user"
      okText="Create"
      confirmLoading={confirmLoading}
      cancelText="Cancel"
      destroyOnClose
      maskClosable={false}
      onCancel={() => {
        form.resetFields();
        Modal.destroyAll();
        onCancel();
      }}
      onOk={() => {
        form.validateFields().then((values) => {
          const newValues = { ...values, createdDate: new Date(), name: `${values.firstName} ${values.lastName}` };
          onCreateNew(newValues);
          form.resetFields();
        });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ roles: ROLES.USER, status: STATUS.ACTIVE, notes: '', lastName: '' }}
      >
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: 'Please input the first name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            {
              required: true,
              message: 'Please input the last name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          validateTrigger={['onBlur']}
          label="Email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
            // {
            //   validator: (_, value) => {
            //     const isEmailExist = allUsers.find(({ email }) => email && email.toLowerCase() === value.toLowerCase());
            //     return isEmailExist ? Promise.reject(new Error('Email Already Exist')) : Promise.resolve();
            //   },
            // },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="roles" label="Role">
          <Radio.Group>
            <Radio value={ROLES.USER}>User (Paid)</Radio>
            <Radio value={ROLES.FREE_USER}>Free User</Radio>
            <Radio value={ROLES.ADMIN}>Admin</Radio>
            <Radio value={ROLES.STAFF}>Staff</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Radio.Group>
            <Radio value={STATUS.ACTIVE}>Active</Radio>
            {/* <Radio value={STATUS.PAUSE}>Pause</Radio> */}
            <Radio value={STATUS.DEACTIVE}>Deactive</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="notes" label="Note">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUser;

/* ------------------------------------------------------ */
/*                         SignOut                        */
/* ------------------------------------------------------ */

/*
Methadology 
Signout, use firebaseAuthLogout to signout from the app.
In reducer, the subscribe list of each snapshot, it happens in reducer action.

Used In 
src/components/CustomSteps/index.js
src/container/Deactivate
src/container/Deleted
src/container/ErrorPage
src/container/JoinTeam
src/container/Pause
src/container/Trial/Trial.jsx
src/container/Trial/Trialv2.jsx
*/

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import { logOut } from '../../redux/authentication/actionCreator';
import { fbAuthLogout } from '../../redux/firebase/auth/actionCreator';
import { BUTTON_PRIMARY } from '../../constants';
import LogoutIcon from '../../static/general/LogoutIcon';

/**
 @boolean logowhite: to display white logo or black logo
 */
const Signout = ({ logoWhite }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLogout } = useSelector((state) => {
    return {
      isLogout: state.fb.auth.uid,
    };
  });
  const SignOut = (e) => {
    e.preventDefault();
    dispatch(fbAuthLogout());
    navigate('/'); // redirect to main page.
  };

  if (!isLogout) {
    dispatch(logOut());
  }
  return (
    <Button transparented type={BUTTON_PRIMARY} onClick={SignOut}>
      {logoWhite ? <LogoutIcon color={'white'} /> : <LogoutIcon color={'#848484'} />} Logout
    </Button>
  );
};

export default Signout;

const PromptData = [
  {
    label: 'Social Media',
    value: 'social-media',
    subCategory: [
      {
        value: 'captions',
        label: 'Captions',
        prompts: [
          'Can you suggest a funny caption for a moment of [insert event/moment]?',
          'I want a caption that expresses my [emotion] for [event/experience]. Can you help?',
          'Can you come up with a caption that includes the words [insert keyword/phrase]?',
          'I want a caption that summarizes my [insert experience/adventure]. Can you create one?',
          'I need a caption that conveys [insert emotion/feeling]. Can you help?',
        ],
      },
      {
        value: 'content-idea',
        label: 'Content Idea',
        prompts: [
          'I need to create content around [specific topic], can you give me some suggestions?',
          'I want to create [visual content] that will stand out, can you help me with that?',
          'How can I create [type of content] that will remain relevant and interesting for a long time?',
          'I want to make my [content format] more interesting, can you give me some ideas?',
          'I want to create a [video/blog post/infographic] that showcases the benefits of [product/service]. Can you help me come up with a list of ideas for how to present the information in an interesting and engaging way?',
        ],
      },
      {
        value: 'webinar-topics',
        label: 'Webinar Topics',
        prompts: [
          'Suggest 10 topics for a webinar to attract [ideal customer] to [offer]',
          'Write 20 headlines for a webinar on [topic] for [audience]',
          'Tell me 10 ideas that would be interesting for [ideal client] about [topic]',
          'Brainstorm 20 topics for a workshop that will attract [ideal client] and present them a solution to [problem], so they can [achieve desired state]',
        ],
      },
      {
        value: 'headlines',
        label: 'Headlines',
        prompts: [
          'Write 3 attention-grabbing headlines for this social media post: [copy and paste text of the post]',
          'Write 5 attention-grabbing headlines for a [platform] post on [topic] for [audience]',
          'Provide me 3 examples for headlines that grab attention for [product type]',
          'What are some examples of headlines that effectively convey the main idea of [topic]',
          'Give me examples of catchy headlines for a [topic or product] that will make people want to [desired action]. Make them BuzzFeed style',
        ],
      },
    ],
  },
  {
    label: 'Twitter',
    value: 'twitter',
    subCategory: [
      {
        value: 'optimizing-twitter-profile ',
        label: 'Optimizing Twitter Profile ',
        prompts: [
          'How can I optimize my Twitter profile to attract potential [customers/clients/partners] and grow my [business/brand/persona] in the [commerce/marketing/sales] industry?',
          'What are some effective strategies for increasing [engagement/followers/visibility] and building a strong online presence for my [business/brand/persona] on Twitter in the [commerce/marketing/sales] industry?',
          'How can I use Twitter to position my [business/brand/persona] as a [thought leader/influencer/expert] and establish a strong reputation in the [commerce/marketing/sales] industry?',
          'What are some creative ways to showcase my [products/services/skills] on my Twitter profile to attract and retain [customers/clients/followers] in the [commerce/marketing/sales] industry?',
          'How can I use Twitter to build relationships with key players and [influencers/partners/stakeholders] in the [commerce/marketing/sales] industry to drive growth and success for my [business/brand/personal]?',
        ],
      },
      {
        value: 'viral-tweets-and-threads',
        label: 'Viral Tweets and Threads',
        prompts: [
          'I want to create a tweet that will go viral and increase awareness about [topic]. Can you help me come up with a catchy headline and engaging content for the tweet?',
          'I want to create a tweet that will be shared widely by influencers in [industry]. Can you help me write a tweet that will appeal to their audience and encourage them to share it?',
          `I want to create a tweet that will increase my brand's visibility and reach on Twitter. Can you help me write a tweet that includes hashtags and keywords related to [brand/industry]?`,
          'I want to create a tweet that will increase my Twitter followers. Can you help me write a tweet that includes a giveaway or a contest related to [topic/brand]?',
          'I want to create a tweet that will drive traffic to my website or blog. Can you help me write a tweet that includes a link to my website or blog and a compelling call-to-action related to [topic]?',
        ],
      },
      {
        value: 'leveraging-twitter-trends',
        label: 'Leveraging Twitter Trends',
        prompts: [
          'Write a tweet that will go viral and increase awareness about [topic]. Provide 10 suggestions.',
          'Help me come up with a catchy headline and engaging content for 10 tweets on [topic].',
          'What are popular topics on Twitter about [topic]?',
          'What type of content receives the most engagement on Twitter?',
          'What type of content for [industry] goes viral on Twitter?',
        ],
      },
      {
        value: 'Twitter Ads',
        label: 'twitter-ads',
        prompts: [
          'Write 10 Twitter ads to promote [product] for [niche].',
          'Write 10 Twitter ads to promote [product] for [niche]. Use the AIDA formula.',
          'Write 10 Twitter ads to promote [product] for [niche]. Use the PAS formula.',
          'Write 10 Twitter ads to promote [product] for [niche]. Start with a playful tone but finish using scarcity.',
          'Suggest 5 angles for Twitter ads to sell [product] for [angle]. Make sure the angles connect on an emotional level with potential customers.',
        ],
      },
      {
        value: 'going-viral',
        label: 'Going Viral',
        prompts: [
          'Write 10 tweets with a high chance of going viral on [niche].',
          'Optimize this tweet to go viral: [copy and paste tweet].',
          'Tell me 5 characteristics of tweets that go viral about [topic].',
          'Create an attention-grabbing tweet around [topic].',
          'Create 3 tweets that will be shared widely by influencers in [industry]. Make sure they appeal to [audience] and encourage them to share the tweet.',
        ],
      },

      {
        value: 'lead-generation',
        label: 'Lead Generation',
        prompts: [
          'How can I step by step effectively use Twitter for inbounding lead generation for my [Business type]?',
          'Can you walk me through the process of generating leads through Twitter for [Business type]? What steps should I follow to maximize Twitter`s potential for generating leads in [Business type]?',
          'Can you provide a roadmap for using Twitter to generate leads effectively in [Business type]?',
          'Can you explain the most effective tactics for generating inbound leads through Twitter for [Product/Service]?',
        ],
      },
      {
        value: 'growth-automation',
        label: 'Growth Automation',
        prompts: [
          'I want to automate my Twitter analytics. Can you help me set up tracking for [key metrics] and create a schedule for analyzing and adjusting my strategy?',
          'I am looking to automate my Twitter advertising. Can you help me set up [target audience] targeting for [your content], budgeting and scheduling for Twitter ads?',
          'I am looking to automate my Twitter [your content] growth strategy. Can you help me come up with a list of [target audience] hashtags and a schedule for tweeting and re-tweeting?',
          'Can you help me to automate my Twitter growth by creating a schedule of tweets about [topic]. Can you help me come up with a list of tweet ideas and a schedule for posting them?',
          'I want to automate my Twitter growth by addressing frequently asked questions and providing helpful information about [topic]. Can you help me write tweets that will educate my audience and establish me as a thought leader in the field?',
        ],
      },
    ],
  },
  {
    label: 'Facebook',
    value: 'facebook',
    subCategory: [
      {
        value: 'ideas-for-creatives',
        label: 'Ideas for Creatives',
        prompts: [
          'Can you suggest some unique and eye-catching images that could grab the attention of my target audience?',
          'What are some creative ways to visually represent the benefits of my product/service in an advertisement?',
          'How can I use images to convey a specific emotion or feeling that resonates with my target audience?',
          'Can you help me come up with a visual concept that aligns with my brand`s values and messaging?',
          'What kind of visual storytelling techniques can I use to make my advertisement more engaging and memorable?',
        ],
      },
      {
        value: 'Headlines-for-FB-ads',
        label: 'Headlines for FB Ads',
        prompts: [
          'Can you generate attention-grabbing headlines for my Facebook ads promoting my new [fill in the product/service] that [fill in the benefit]?',
          'I need catchy headlines for my Facebook ads promoting my [fill in the service] that helps [fill in the target audience]. Can you help?',
          'Can you come up with compelling headlines for my Facebook ads promoting my [fill in the product/service] that is [fill in the unique selling point]?',
          `I'm launching a new line of [fill in the product] and I need eye-catching headlines for my Facebook ads targeting [fill in the target audience]. Can you write them for me?`,
          'Can you help me write engaging headlines for my Facebook ads promoting my [fill in the product] that uses [fill in the natural/organic ingredient]?',
        ],
      },
      {
        value: 'A/B-testing-fB-ads',
        label: 'A/B Testing FB Ads',
        prompts: [
          'Can you rewrite a version of the [winning creative/ad copy] that we can use for A/B testing on our campaign targeting [specific demographics/audiences]?',
          'Can you create a variation of the [winning creative/ad copy] that will appeal to a [different target audience/market segment] compared to the original version?',
          'Can you rephrase the [winning creative/ad copy] to make it more [concise/impactful] for a [specific audience/campaign]',
          'I need help rewriting a winning creative for an [ad campaign/email marketing/social media] campaign. Can you help me come up with a new version that is similar to the original but with a fresh perspective? Original copy: [Original ad copy]',
          'I want to create an A/B version of a winning creative for [campaign]. Can you help me write a new version that will resonate with a different demographic? Original copy: [Original ad copy]',
        ],
      },
      {
        value: 'video-briefs',
        label: 'Video Briefs',
        prompts: [
          'Can you write a brief for a [video style/type] that will be used to promote our [product/service/brand] to a [target audience]?',
          `Can you draft a brief for a [video length/duration] that will be used to explain our [company's mission/values] to potential clients?`,
          'Can you create a brief for a [explainer/how-to] video that will be used to demonstrate the steps to use our [product/service] effectively to a [new/existing customers]',
          'I am planning to create a video that will educate my audience about [topic]. Can you help me write a brief for the video editor that includes information on tone, pacing, and desired outcome of the video?',
          'I need to create a video that will showcase the features of [product/service]. Can you help me write a brief for the video editor that highlights the key selling points and differentiates our offering from competitors?',
        ],
      },
      {
        value: 'FB-ad-copy',
        label: 'FB Ad Copy',
        prompts: [
          'Can you provide examples of effective ad copy for [specific product/service]?',
          'What are the key elements that should be included in ad copy for [specific industry/field]?',
          'How do I write a compelling headline for [specific product/service] ad targeting [specific audience/demographic]?',
          'I am creating an ad campaign for [product/service] and need help writing copy that will grab the attention of potential customers. Can you help me come up with headlines and body copy that will persuade them to make a purchase?',
          'I am launching a new [product/service] and need help writing ad copy that will generate excitement and interest of [audience]. Can you help me come up with headlines and body copy that will make people want to learn more?',
        ],
      },
    ],
  },
  {
    label: 'Youtube',
    value: 'youtube',
    subCategory: [
      {
        value: 'video-scripts',
        label: 'Video Scripts',
        prompts: [
          'I want to create a YouTube video about [topic]. Can you help me write a script that will engage my audience and provide valuable information?',
          'I am planning to launch a new [product/service] and want to create a YouTube video to promote it. Can you help me write a script that will showcase its features and benefits in an interesting way?',
          'I want to create a YouTube video that addresses common questions and concerns about [topic]. Can you help me write a script that will provide clear and concise answers?',
          'I am creating a tutorial video on [topic]. Can you help me write a script that will break down the steps in an easy-to-follow format for my audience?',
          'I am creating an animated explainer video about [topic]. Can you help me write a script that will be informative, clear and easy to understand?',
        ],
      },
      {
        value: 'Video-descriptions',
        label: 'Video Descriptions',
        prompts: [
          'Please provide a video description for a [specific type of video] that includes [key elements/stands out/encourages] viewers to watch the video.',
          'Write a video description for [specific type of video] that uses emotional appeal and storytelling to improve its effectiveness.',
          'Generate a video description for a video that is about [specific industry/field] that includes words or phrases to increase engagement and views.',
          'I am creating a promotional video for [business/event]. Can you help me write a video description that captures the essence of the event and encourages people to attend?',
        ],
      },
      {
        value: 'Growth Automation',
        label: 'Growth Automation',
        prompts: [
          'I am looking to grow my YouTube channel and increase my views and subscribers. Can you help me come up with a strategy for [target audience] that includes a schedule for creating and promoting content?',
          'What are some strategies for increasing [channel/video type] views on YouTube?',
          'How can I optimize my YouTube [channel/video] for search?',
          'I am looking to use YouTube ads to promote my channel and increase my reach. Can you help me research and identify the best targeting options for [target audience] and provide examples of ad copy and creative that will appeal to them?',
          'What are some ways to monetize my YouTube channel that has the topic of [topic]?',
          'How can I increase [channel niche] views and subscribers on YouTube?',
          'What strategies can I use to optimize [theme] YouTube videos for SEO and discoverability?',
          'What are some creative ways to promote [theme] YouTube channel with content to a larger audience?',
          'What are some common mistakes that are made when trying to grow a YouTube channel with a theme of [theme] and how can I avoid them?',
        ],
      },
      {
        value: 'creative-thumbnails',
        label: 'Creative Thumbnails',
        prompts: [
          'Can you suggest [fill in the topic] themed thumbnail ideas for my YouTube channel?',
          'I need creative thumbnail ideas for my new YouTube video on [fill in the topic]. Can you help me out?',
          'Can you generate eye-catching thumbnail ideas for my YouTube channel that targets [fill in the audience]?',
          `I'm struggling to come up with thumbnail ideas for my YouTube videos on [fill in the topic]. Can you assist me with that?`,
          `Can you suggest thumbnail designs for my YouTube channel that aligns with my brand's aesthetic?`,
          'I need thumbnail ideas for my YouTube videos that will make viewers want to click and watch. Can you generate some ideas?',
        ],
      },
    ],
  },
  {
    label: 'Email Marketing',
    value: 'email-marketing',
    subCategory: [
      {
        value: 'subject-lines',
        label: 'Subject Lines',
        prompts: [
          `I am looking for a subject line for an [event, launch, reminder, promotion, etc] about [product/service]. The email will be sent to [target audience] and the goal is to [action desired from recipient]. Can you please generate a subject line that is [specific tone/style] and includes [keywords/phrases]?`,
          `I am looking for a subject line for an email promotion about [product/service]. The email will be sent to [target audience] and the goal is to [action desired from recipient]. Can you please generate a subject line that is [specific tone/style] and includes [keywords/phrases]?`,
          `I am looking for a subject line for a newsletter about [topic]. The email will be sent to [target audience] and the goal is to [action desired from recipient]. Can you please generate a subject line that is [specific tone/style] and includes [keywords/phrases]?`,
          `I am looking for a subject line for a reminder email about [event/deadline]. The email will be sent to [target audience] and the goal is to [action desired from recipient]. Can you please generate a subject line that is [specific tone/style] and includes [keywords/phrases]?`,
          `I am crafting an email to promote our [product/service], and I need a compelling subject line that will entice recipients to open the email. Can you help me generate several options?`,
          `I am sending an email to announce a new [feature/offer] and I need a catchy subject line that will grab the attention of my audience. Can you help me come up with a few options?`,
          `Our [product or service] is the perfect solution for [pain point] that many of our customers are facing. It offers [benefit 1], [benefit 2], and [benefit 3] that make it a must-have for [target audience X]. Write a sales email that highlights the product/service and encourages customers to take action.`,
          `We are launching [product]. It is designed for [audience]. These are three main features/benefits [Item], [Item], [Item]. Please write a sales email inviting the reader to buy a product at [percentage]% off.`,
        ],
      },
      {
        value: 'Email Newsletters',
        label: 'email-newsletters',
        prompts: [
          `Write a newsletter for [audience] about [topic]. The tone should be [formal/casual/etc.] and include information about [specific information or key points to include]. For our target customers [target customers], the newsletter should emphasize on [emphasize on], and the newsletter should end with a call-to-action to [call-to-action]`,
          `Greeting: Hi [recipient name],<br/>
  
            Update: We have exciting news for you!<br/>
            
            Content: Our [insert your something you want to showcase] is live and you can take a look here [include link]<br/>
            
            Benefits: This will help you [insert your first benefit], [insert your second benefit], and [insert your third benefit].<br/>
            
            Call-to-action: If you want to [main benefit] without [main objection], this is definitely for you.<br/>
            
            Sign-off: Talk to you next week, [insert your name]`,
          `Write an email newsletter about the upcoming launch of our new product [product name].`,
          `Can you help me write an email newsletter for my business, targeting [target audience] and promoting our new service [service name]?`,
          `Generate a newsletter email for my company's upcoming event [event name].`,
          `Write a newsletter email that promotes our new product [product name] and includes a call-to-action to visit our website for more information.`,
        ],
      },
      {
        value: 'cold-emails',
        label: 'Cold Emails',
        prompts: [
          `Develop a persuasive email that showcases the success stories of similar [target persona] who have used my [product/service] to overcome [pain point], and emphasize the urgency and scarcity of the offer.`,
          `Write a cold email for [product/service] addressing [specific persona pain points] and present  [product/solution] as the only viable solution in the market, leaving them with a sense of urgency and a compelling call to action.Write an email for a [describe prospect] who is struggling with [pain points] and wants to [desire]. Mention that my offer is [describe offer], the guarantee is [insert guarantee], and my credentials are [insert credentials]. Invite them to book a call with me. Use a friendly tone and make the email short`,
          `Write a cold email for potential customers that includes a sentence about our company, We're [Company Name], and we specialize in [Company Expertise].`,
          `Can you craft a cold email for potential customers that emphasizes our commitment to customer satisfaction?`,
        ],
      },
      {
        value: 'Product-launch-email',
        label: 'Product Launch Email',
        prompts: [
          `What is the best way to create excitement and generate interest for [product/service] in a product launch email?`,
          `Write me a launch email that grabs the attention of potential customers for [product/service].`,
          `Write me a launch email that highlights the key features and benefits of [product/benefits].`,
          `Write me a launch email that creates excitement and generates interest for [product/service].`,
          `Write me a launch email with all the key elements included to ensure success in generating sales for [product/service].`,
        ],
      },
      {
        value: 'sales-email',
        label: 'Sales Email',
        prompts: [
          `Write an email introducing our new product [product name] to a potential customer, highlighting its key features and benefits`,
          `Write a follow-up email to a potential customer who has shown interest in our product [product name], addressing any objections they may have and closing the sale.`,
          `Write a thank-you email to a customer who has just made a purchase, encouraging them to leave a review and promoting related products.`,
          `Write a re-engagement email to a customer who has not made a purchase in the last [X] months, offering them a special deal to come back.`,
          `Write an email with a subject line that would entice the customer to open it and a body that will make them want to buy our product [product name].`,
          `Greeting: Hi [recipient name], <br/>Introduction: We are [insert your business name], and we specialize in [insert your product/service name].<br/>Benefits: Our [insert your product/service name] offers several benefits, including [insert your first benefit], [insert your second benefit], and [insert your third benefit].<br/>Call-to-action: [Insert your clear and compelling call-to-action, such as 'Schedule a demo today' or 'Sign up for a free trial'].<br/>Sense of Urgency or Scarcity: Act now to [insert your sense of urgency or scarcity, such as 'take advantage of our limited-time offer' or 'join our exclusive program while spots are still available'].<br/>Contact Information: If you have any questions or need more information, please contact us by [insert your preferred method of contact, such as phone, email, or chat] at [insert your team's email address or phone number].<br/>Sign-off: Thanks, [insert your name].`,
        ],
      },
      {
        value: 'onboarding-email',
        label: 'Onboarding Email',
        prompts: [
          'Write a welcome email to my customers after they purchased [name of the product]',
          `Generate an onboarding email for customers after they have made a purchase. The email should start with a greeting that includes the customer's name, express gratitude for their purchase of [insert your product/service name], and provide a list of recommended onboarding steps, including [insert your first step], [insert your second step], and [insert your third step]. The email should also offer assistance from your team and provide contact information for them to get in touch, including [insert your preferred method of contact] and [insert your team's email address or phone number]. Use [insert your name] as the sign-off for the email.`,
          `Congratulate them for their purchase, and invite them to keep in contact so you can keep helping them solve [problem].`,
          `Can you draft an email that welcomes new customers to our company and provides important information about their account setup?`,
          `Can you create an email that concludes by expressing excitement for the new customer's arrival and reiterating our commitment to their success?`,
          `Write an onboarding email that outlines the process for accessing and using our products and services.`,
        ],
      },
      {
        value: 'cart-abandonment-email',
        label: 'Cart Abandonment Email ',
        prompts: [
          'Generate a cart abandonment email for potential customers that includes:',
          'Greeting: Hi [recipient name],',
          'Reminder: You left [insert your product/service name] in your cart.',
          'Benefits: Our [insert your product/service name] offers [insert your first benefit], [insert your second benefit], and [insert your third benefit].',
          'Call-to-action: Complete your purchase and enjoy [insert your offer or incentive].',
          `Contact Information: Reach out to us by [insert your preferred method of contact, such as phone, email, or chat] at [insert your team's email address or phone number] if you need help.`,
          `Sign-off: Thanks, [insert your name].`,
          `Write a 3-email sequence for potential customers who started purchasing [product] but did not complete their purchase. Use urgency by saying their [percentage]% discount will expire in 48 hours, and use a playful tone across the emails.`,
          `Can you compose an email that addresses common reasons for cart abandonment and provides solutions?`,
          `Can you draft an email that provides a time-limited offer to encourage the customer to complete their purchase?`,
        ],
      },
      {
        value: 'welcome-email',
        label: 'Welcome Email',
        prompts: [
          `Write an email to welcome new customers to [describe company].`,
          `Write an email to welcome new customers, mentions their login information, and tells them they can contact you with any questions at [insert customer service email]`,
          `Write an email that welcomes new subscribers to my list, thanks them for opting-in and mentions I will be sending valuable information over the next few days.`,
          `Can you draft an email that greets new customers and offers support for their onboarding experience?`,
          `Write a welcome email that emphasizes the importance of customer satisfaction and our commitment to their success.`,
        ],
      },
    ],
  },
  {
    label: 'Instagram',
    value: 'instagram',
    subCategory: [
      {
        value: 'live-stream-ideas',
        label: 'Live Stream Ideas',
        prompts: [
          'Can you suggest some unique and creative ideas for live streaming content based on the theme [Enter Theme]?',
          'What are some effective strategies to increase audience engagement during a live stream on [Enter Platform]?',
          'What are some interesting and engaging topics I can talk about during my live stream to keep my [audience] interested?',
          'Can you recommend any interactive elements I can add to my live stream to make it more fun for my [audience]?',
          'What are some tips to improve the production value of [theme/type] live stream and make it more professional?',
        ],
      },
      {
        value: 'automating-instagram-growth',
        label: 'Automating Instagram Growth',
        prompts: [
          'Create a caption for my latest Instagram post about [product/service] that will entice users to check it out and consider making a purchase.',
          'Write a caption for an Instagram post featuring a picture of [product/service] that highlights its [unique feature].',
          'Generate a list of hashtags I can use for my Instagram post about [topic].',
          'Write a creative Instagram caption for a post featuring [location].',
          'Compose an Instagram post caption that showcases the benefits of [product/service] in a compelling way.',
        ],
      },
      {
        value: 'Instagram Captions',
        label: 'Instagram Captions',
        prompts: [
          'Can you help me come up with a funny caption for this [insert event/moment]?',
          `I'm looking for a caption that expresses my [emotion] about this [event/experience]. Can you suggest one?`,
          'Can you create a caption that includes the words [insert keyword/phrase] for this post?',
          'I want a caption that summarizes my [insert experience/adventure]. Can you help me create one?',
          'I need a caption that conveys [insert emotion/feeling] for this post. Can you suggest one?',
        ],
      },
      {
        value: 'instagram-content-ideas',
        label: 'Instagram Content Ideas',
        prompts: [
          'Can you suggest some unique and eye-catching Instagram post ideas that will help my brand stand out in [specific industry]?',
          'I want to promote my new [product] on Instagram. Can you suggest some creative ways to showcase it in my posts?',
          'What are some effective strategies to increase engagement on my Instagram page? Can you suggest some post ideas that will encourage comments and shares?',
          `I'm looking to highlight the values of my brand on Instagram. Can you suggest some post ideas that align with our mission and vision?`,
          'Can you suggest some fun and creative Instagram post ideas for the upcoming holiday/season that will resonate with my target audience in [specific industry]?',
        ],
      },
    ],
  },
  {
    label: 'TikTok',
    value: 'tikTok',
    subCategory: [
      {
        value: 'tikTok-ad-scripts',
        label: 'TikTok Ad Scripts',
        prompts: [
          'I want to create a viral TikTok video about [topic]. Can you help me come up with a script that will capture the attention of my target audience and make them want to share it with their friends?',
          'I want to create a TikTok video that teaches my audience something new about [topic]. Can you help me write a script that will be informative and entertaining at the same time?',
          'I am trying to promote my [product/service] on TikTok. Can you help me write a script for a creative and catchy video that will show off its features and benefits?',
          'I want to create a viral TikTok video that teaches viewers a new skill or provides valuable information about [topic]. Can you help me write a script that will make learning interesting and easy to understand?',
          'I want to create a viral TikTok video that uses humor to promote [brand/product/service]. Can you help me write a script that is funny and relatable?',
        ],
      },
      {
        value: 'viral-content-ideas',
        label: 'Viral Content Ideas',
        prompts: [
          'How can I spot and capitalize on upcoming trends on TikTok to [goal/mission]?',
          'I want to take my [idea] on TikTok to the next level, can you give me some tips?',
          'I am looking to create a viral TikTok video about [topic]. Can you help me come up with a unique and [adjective] concept for the video?',
          'I want to create a TikTok video that tells a [adjective] and engaging story about [topic]. Can you help me come up with a script and visual concept for the video?',
          'I am trying to create a TikTok video about [topic]. Can you help me come up with a catchy title, a script and a list of hashtags that will make the video go viral?',
          'I want to create a TikTok video that highlights the benefits of [product/service]. Can you help me come up with a creative concept, a script and a list of hashtags that will encourage people to try it out?',
          'I want to create a [topic] TikTok challenge that will go viral. Can you help me come up with a catchy name, rules and a list of hashtags for the challenge?',
          'I want to create a TikTok video that is entertaining and funny, based on [topic]. Can you help me come up with a concept for a comedic video that will go viral on the platform?',
          'I want to create a TikTok video that shares success stories and testimonials from [business/industry]. Can you help me create a concept for a video that will showcase the benefits and impact of [product/service]?',
        ],
      },
      {
        value: 'growth-automation',
        label: 'Growth Automation',
        prompts: [
          'Can you come up with a caption for a TikTok video showcasing a new product [product name]?',
          'Can you write a caption for a TikTok video featuring a popular dance trend [dance trend name]?',
          'Can you write a catchy caption for a TikTok video of [name] doing a comedic skit?',
          'Can you come up with a caption for a TikTok video that gives a tutorial on [topic]?',
          'Can you write a caption for a TikTok video that promotes a giveaway for [prize]?',
        ],
      },
      {
        value: 'A/B-testing-TikTok-ads',
        label: 'A/B Testing TikTok Ads',
        prompts: [
          'Can you rewrite a version of the [winning creative/ad copy] that we can use for A/B testing on our campaign targeting [specific demographics/audiences]?',
          'Can you create a variation of the [winning creative/ad copy] that will appeal to a [different target audience/market segment] compared to the original version?',
          'Can you rephrase the [winning creative/ad copy] to make it more [concise/impactful] for a [specific audience/campaign]',
          'I need help rewriting a winning creative for an [ad campaign/email marketing/social media] campaign. Can you help me come up with a new version that is similar to the original but with a fresh perspective? Original copy: [Original ad copy]',
          'I want to create an A/B version of a winning creative for [campaign]. Can you help me write a new version that will resonate with a different demographic? Original copy: [Original ad copy]',
        ],
      },
    ],
  },
  {
    label: 'LinkedIn',
    value: 'linkedIn',
    subCategory: [
      {
        value: 'optimize-linkedIn-profile',
        label: 'Optimize LinkedIn Profile',
        prompts: [
          `Can you write a LinkedIn 'about' section for a [role] in [industry] and that has the following credentials: [Credential 1] [Credential 2] [Credential 3]. Write in first person, use a [fun/professional/relaxed/etc] tone, and finish by saying [If you'd like me to help you, just send me a personal message]`,
          `Can you write a LinkedIn 'about' section for a [role] in [industry] and that has the following credentials: [Credential 1] [Credential 2] [Credential 3]. Write it in the voice of [author]`,
          `What are some ways I can showcase my expertise in [topic] on LinkedIn?`,
          `What key things should I consider on my LinkedIn page in order to stand out?`,
        ],
      },
      {
        value: 'LinkedIn Post Ideas',
        label: 'LinkedIn Post Ideas',
        prompts: [
          `How can [industry/field] professionals stand out on LinkedIn and increase their visibility among their peers and potential employers?`,
          `What are some creative ways that a job seeker in [industry/field] can use LinkedIn to boost their chances of getting hired?`,
          `What are some best practices for writing a LinkedIn summary as a [job title] and how can it be used to stand out among competitors?`,
          `How can you use LinkedIn to build a strong professional network and what are some strategies for networking effectively on the platform in [industry]?`,
          `What are some top strategies for using LinkedIn to generate leads and grow your [business/brand]?`,
        ],
      },
      {
        value: 'content-ideas',
        label: 'Content Ideas',
        prompts: [
          `How can a recent college graduate leverage LinkedIn to stand out in the job market in [specific field]?`,

          `What are the ways to effectively utilize LinkedIn to promote [industry] business and increase leads?`,

          `What are some creative ways to engage with your LinkedIn network and build relationships in [specific industry]?`,

          `What are some tips for creating a strong LinkedIn profile that will attract recruiters in [specific field]?`,

          `How can small business owners use LinkedIn to increase brand awareness and generate more sales in [specific industry]?`,
        ],
      },
      {
        value: 'leveraging-groups',
        label: 'Leveraging Groups',
        prompts: [
          `Can you write posts for LinkedIn groups on [fill in the topic] that will engage members and spark conversation?`,

          `I need help creating content for LinkedIn groups that speaks to professionals in [fill in the industry]. Can you assist me with that?`,

          `Can you generate posts for LinkedIn groups that will position me as an authority in [fill in the field] and attract potential clients?`,

          `I'm looking for help with writing posts for LinkedIn groups that will drive traffic to my website and increase my brand awareness. Can you write them for me?`,

          `Can you create thought-provoking posts for LinkedIn groups that will challenge members to think differently about [fill in the topic]?`,
        ],
      },
      {
        value: 'linkedIn-ads',
        label: 'LinkedIn Ads',
        prompts: [
          `Can you provide examples of effective ad copy to promote [product] to [audience]? Make sure they are [persuasive/playful/emotional] and mention these benefits: [Benefit 1] [Benefit 2 [Benefit 3]`,
          `Write me 3 LinkedIn ad copies based on this landing page: [Copy and paste the landing page text]`,
          `Finish with a call to action saying [CTA]. Add 3 emojis to it`,
          `I am creating an ad campaign for LinkedIn to promote [product] for [niche]. Please write 3 copies that will grab the attention of potential customers and persuade them to purchase.`,
        ],
      },
      {
        value: 'growth-automation',
        label: 'Growth Automation',
        prompts: [
          'Provide a detailed guide on how to automate B2B LinkedIn growth for a company in the [specific industry] using tools like [specific tools] and [specific strategy].',
          'Create a step-by-step plan for automating B2B LinkedIn growth for a company targeting [specific job titles/industries] using [specific automation methods].',
          'Write a script for a LinkedIn automation bot that focuses on [specific goals, such as lead generation or networking] and can be customized for a company in the [specific industry].',
          'Develop a strategy for automating B2B LinkedIn growth that includes tactics for [specific actions, such as personalized messaging or content sharing] and metrics for measuring success in [specific industries].',
          `What are the best ways to use LinkedIn to grow our B2B [specific product/service] company's brand awareness among [target audience] in [specific industry] industry?`,
        ],
      },
      {
        value: 'linkedIn-hashtags',
        label: 'LinkedIn Hashtags',
        prompts: [
          `I am looking to improve my LinkedIn presence and reach more people in my [industry/niche]. Can you help me come up with a list of relevant hashtags and a strategy for using them effectively on my profile and posts?`,
          `I want to increase my LinkedIn engagement and reach more people in my [industry/niche]. Can you help me come up with a list of relevant hashtags and a strategy for using them to boost engagement on my posts and profile?`,
          `I want to increase the visibility of my LinkedIn posts and reach more potential clients in my [industry/niche]. Can you help me come up with a list of popular hashtags and a plan for incorporating them into my content?`,
          `I am looking to build a community on LinkedIn by starting conversations around [topic]. Can you help me come up with a list of hashtags that will attract people interested in this topic?`,
          `I am looking to build a network of professionals in [industry/field] on LinkedIn. Can you help me come up with a list of hashtags that will help me connect with like-minded individuals?`,
        ],
      },
    ],
  },
  {
    label: 'Influencer Outreach',
    value: 'influencer-outreach',
    subCategory: [
      {
        value: 'automation',
        label: 'Automation',
        prompts: [
          'How can I automate my influencer outreach process for [my brand/product]?',
          'What are some tools or strategies I can use to streamline my influencer outreach for [my industry/niche]?',
          'Can you recommend any best practices for automating influencer outreach for [my target audience]?',
          'Can you provide general information on successful influencer outreach automation campaigns for [my industry/niche]?',
          'How can I use automation to personalize my influencer outreach for [my target audience]?',
        ],
      },
      {
        value: 'personalized-outreach',
        label: 'Personalized Outreach',
        prompts: [
          'I am looking to reach out to influencers in the [industry/niche] field to collaborate with my brand that [tell more about the brand]. Can you help me come up with personalized messages that will grab their attention and make them want to work with me?',
          `I am looking to collaborate with influencers who specialize in [specific content type, e.g. beauty, fashion, food]. Can you help me write personalized messages that showcase my brand's expertise with [expertise of the brand] in that area and how it aligns with the influencer's content?`,
          `I am reaching out to influencers who have a large following in [specific audience]. Can you help me write personalized messages that will highlight [brand's relevance] to their audience and make it clear why it's worth their time to work with me?`,
        ],
      },
      {
        value: 'influencer-agreements',
        label: 'Influencer Agreements',
        prompts: [
          `Can you provide a template for an influencer agreement for [specific campaign/product]?`,
          `Can you provide a checklist for ensuring the completeness and legality of an influencer agreement for [specific campaign/product]`,
          `What are the key terms and clauses that should be included in an influencer agreement for [specific industry/field]?`,
          `I am looking to establish a long-term influencer partnership with [who specifically]. Can you help me create an agreement that includes milestones and performance metrics for both parties?`,
          `I need help drafting an influencer agreement for a [beauty/fashion/tech/etc.] brand. Can you provide language for terms such as compensation, exclusivity, content guidelines, and rights to use the content?`,
        ],
      },
      {
        value: 'pitching-an-influencer',
        label: 'Pitching an Influencer',
        prompts: [
          `What are some key points we should consider when approaching influencers from [influencer's niche] for a long-term partnership with [brand/product]?`,

          `How can we convince influencers with a niche [influencer's niche] to promote our [product/service] on their social media platforms?`,

          `Create a one-page proposal for [influencer's name] to partner with [brand name] for a series of content promoting our [product/service].`,

          `How can we make our pitch for influencers from [industry] industry to stand out among the many other pitches they receive?`,
        ],
      },
    ],
  },
  {
    label: 'Customer Support',
    value: 'customer-support',
    subCategory: [
      {
        value: 'email-responses',
        label: 'Email Responses',
        prompts: [
          `What are some effective ways to respond to a customer's complaint in an email? The customer's complaint says: [customers complaint/message]`,

          `I am looking for a way to streamline my customer service email responses. Can you help me create a template for addressing common questions and concerns about [product/service]?`,

          `I am looking for a way to handle customer complaints and turn the situation around. Can you help me write an email that acknowledges the issue, apologizes and offers a solution to make it right? The issue is [issue]`,

          `I want to make sure I am providing a professional and personalized response to customer emails. Can you help me create a script for introducing myself, thanking the customer for their email, and addressing their inquiry related to [product/service]?`,

          `I need help crafting an email that addresses a common issue that customers have with [product/service]. Can you help me write a response that provides a solution and reassures the customer?`,
        ],
      },
      {
        value: 'responding-to-comments',
        label: 'Responding to Comments',
        prompts: [
          `What are the benefits of [product/service]?`,

          `How does [process] work?`,

          `What are some alternative solutions for [problem]?`,

          `Can you provide a step-by-step guide for [process]?`,

          `Can you provide more information about [product/service]?`,
        ],
      },
      {
        value: 'creating-faqs',
        label: 'Creating FAQs',
        prompts: [
          `I want to create an FAQ section for my [business/website/product/service]. Can you help me come up with a list of frequently asked questions and answers that will provide helpful information for my customers?`,

          `I want to improve the user experience on my website by creating an FAQ section for [business/website/product/service]. Can you help me come up with a list of frequently asked questions and answers that will be easily accessible and provide quick solutions for my customers?`,

          `I want to create an FAQ brochure for [industry], Can you help me write answers that will help our customers better understand our product and services`,

          `I want to create a FAQ section that addresses common misconceptions about [topic]. Can you help me write answers that provide accurate information and clear up any confusion based on this info [info]?`,

          `I want to improve the user experience on my website by creating an FAQ section for [business/website/product/service]. Can you help me come up with a list of frequently asked questions and answers that will be easily accessible and provide quick solutions for my customers?`,
        ],
      },
      {
        value: 'summarizing Customer Feedback',
        label: 'Summarizing-customer-feedback',
        prompts: [
          `Can you provide a summary of the customer feedback for [product/service] based on the data I have provided: [Feedback Data]?`,

          `What are the key highlights from the customer feedback for [product/service] based on the data I have given you: [Feedback Data]?`,

          `Can you condense the customer feedback for [product/service] based on the data into a brief summary for me? Data: [Feedback Data]`,

          `What are the major takeaways from the customer feedback for [product/service] according to the data I have provided? Data: [Feedback Data]`,

          `Can you summarize the customer feedback for [product/service] as described in the data I have shared with you? Data: [Feedback Data]`,
        ],
      },
      {
        value: 'increasing-customer-retention',
        label: 'Increasing Customer Retention',
        prompts: [
          `Could I offer additional resources or support to help customers get the most out of my product or service? What types of resources or support would be most valuable to my customers, and how could I make them easily accessible?`,

          `Could I implement a referral program to incentivize existing customers to refer new customers to my business? What rewards or benefits would be most effective, and how could I promote the program to my existing customers?`,

          `Are there any social or community-driven features that I could add to my product or service to increase customer engagement and retention? What types of features would be most effective, and how could I encourage customers to use them?`,

          `Could I offer exclusive content or access to customers who have been with my business for a certain period of time? What types of content or access would be most appealing to my customers, and how could I deliver it in a way that is both valuable and engaging?`,

          `Are there any communication channels or touchpoints where I could improve my interactions with customers? What steps could I take to improve these interactions and make them more personalized and effective?`,

          `Could I partner with other businesses or organizations to offer additional benefits or rewards to my customers? What types of partnerships would be most valuable to my customers, and how could I leverage these partnerships to increase customer retention?`,
        ],
      },
    ],
  },
  {
    label: 'Affiliate Marketing',
    value: 'affiliate-marketing',
    subCategory: [
      {
        value: 'affiliate-product-reviews',
        label: 'Affiliate Product Reviews',
        prompts: [
          `Write a review on how my [product/service] compares to other products in its category in terms of quality, features, and price`,
          `Can you provide a comprehensive review of the [product/service], including its features, performance, and value for money?`,

          `Write what are possible likes and dislikes about the [product/service] and why?  Write a review based on that info.`,

          `Write a review on how the [product/service] compares to other products in its category in terms of quality, features, and price?`,

          `Can you provide a brief overview of the [product/service]?`,
        ],
      },
      {
        value: 'affiliate-product-comparison',
        label: 'Affiliate Product Comparison',
        prompts: [
          `Write a review on how [product] compares to other products in its category in terms of quality, features, and price`,

          `What are 10 advantages of [product 1] over [product 2]`,

          `Provide a comprehensive review of the [product], including its features, performance, and value for money`,

          `Give me 10 reasons why [ideal client] would buy [product 1] over [product 2]`,
        ],
      },
      {
        value: 'affiliate-product-recommendations',
        label: 'Affiliate Product Recommendations',
        prompts: [
          `Write a script for a YouTube video that talks about how [product] helped me [benefit]`,

          `Write a blog post that talks about how [product] helped me [benefit]. Mention statistics`,

          `Write a [platform] post that talks about how [product] helped me [benefit]. Mention statistics and this testimonial: [include testimonial]`,
        ],
      },
      {
        value: 'affiliate-product-emails',
        label: 'Affiliate Product Emails',
        prompts: [
          `Create a 3-step email sequence to promote [product] to [audience]. Mention that the product was created by someone you trust and that you earn a small affiliate commission if the reader buys`,
          `Write an email promoting a [product] to [audience]. Describe the product in detail and mention these key benefits:[Benefit 1][Benefit 2][Benefit 3]Mention that this is an affiliate product, and thank them for supporting you.`,
          `I am an affiliate of [product], which helps [audience] achieve [dream result]. Write a sales email inviting the reader to buy this product with a discount if they buy from my affiliate link`,
          `Can you suggest some techniques for creating a sense of urgency or scarcity in a sales email?`,
          `What are some ways to personalize a sales email and make it more relevant to the recipient's needs and interests?`,
          `Can you suggest ways to follow up and stay in touch with the reader after sending a sales email, without being too pushy or aggressive?`,
        ],
      },
    ],
  },
  {
    label: 'SEO',
    value: 'seo',
    subCategory: [
      {
        value: 'blog-writing',
        label: 'Blog writing',
        prompts: [
          `Write a blog post about the impact of [specific event/news] on [specific industry or community].`,

          `Write a blog post of what are the key takeaways from [insert book/article] and how can they be applied to [insert industry/field]?`,

          `Write a blog post about some common misconceptions about [insert topic] and how can they be corrected?`,

          `Write an explanatory blog about the process of [specific task or project] and include tips on how to do it efficiently.`,

          `Write a blog about an overview of the advantages of [specific activity or hobby] and see how to start with helpful tips and the equipment you need.`,
        ],
      },
      {
        value: 'SEO Optimization',
        label: 'SEO Optimization',
        prompts: [
          `Write a detailed blog outline on [topic] with H2, H3, subheading, and bullet points`,

          `Provide 10 long tail keywords related to [topic]. Match each keyword with any of the 4 types of search intent`,

          `I am trying to rank for a specific local search term, such as [City] [industry]. Can you help me optimize my website and create content that will appeal to local customers?`,

          `I am trying to improve my website's visibility in video search results. Can you help me optimize my videos and create video transcripts that include [keyword]?`,

          `Help me generate ‘how-to schema markup’ for the following steps on [topic]. Pin down your target audience, Choose a Topic, Research Keywords`,

          `I want to include a list of keywords in my blog post about [topic]. Can you help me generate a list of relevant and high-performing keywords to include in the post?`,

          `I want to improve the readability of my blog post about [topic]. Can you provide a list of tips and best practices for making the post more engaging and easy to read?`,

          `Write user-friendly URLs for this keyword on the domain <domain> for the following keywords - [keywords]`,
        ],
      },
      {
        value: 'keywording',
        label: 'Keywording',
        prompts: [
          `I want to include a list of keywords in my blog post about [topic]. Can you help me generate a list of relevant and high-performing keywords to include in the post?`,

          `Generate a list of 10 keyword ideas on [topic]`,

          `Optional: Cluster this list of keywords according to funnel stages whether they are top of the funnel, middle of the funnel or bottom of the funnel keywords (based on previous search)`,
        ],
      },
      {
        value: 'content-ideas',
        label: 'Content Ideas',
        prompts: [
          `I am looking to create content on [topic]. Can you help me generate a list of potential article ideas and titles?`,

          `I am trying to expand my content portfolio by writing about [industry/niche]. Can you help me come up with a list of topics that would be of interest to my [target audience]?`,

          `I want to create a series of blog posts that cover the basics of [topic]. Can you help me generate a list of subtopics and titles for these posts?`,

          `I am looking to expand the content on my website, but I am struggling to come up with new ideas. Can you help me generate a list of possible topics for my [industry/niche] business?`,

          `I am a content creator and looking to come up with new ideas for my [YouTube channel/ Instagram account]. Can you help me generate a list of potential [video/post topics] that would be popular among my audience [audience type]?`,
        ],
      },
      {
        value: 'content-calendar',
        label: 'Content Calendar',
        prompts: [
          `Can you create a content calendar for the next [month/quarter/year] that includes [specific number] of blog posts, [specific number] of social media posts, and [specific number] of email newsletters?`,

          `Can you help me come up with a list of [topics/keywords] that are relevant to my [industry/niche/target audience] and can be used to create a variety of content?`,

          `Can you provide suggestions for specific types of content to create, such as infographics, videos, webinars, or e-books, and indicate which channels they should be shared on?`,

          `Can you help me identify key dates and events that are relevant to my business and suggest content ideas that can be tied to those dates?`,

          `Can you create a weekly or monthly theme for my content and suggest topics that align with that theme?`,
        ],
      },
      {
        value: 'advertorial',
        label: 'Advertorial',
        prompts: [
          `Write an advertorial that describes [topic]. Mention that the product helps [benefit 1], [benefit 2], [benefit 3]. Finish by saying [call to action]. Include statistics, it should sound like a report on a newspaper.`,

          `Brainstorm 10 possible headlines for this advertorial. Make them [insert magazine or author] style: [copy and paste text of the advertorial].`,

          `Can you write an advertorial for my new [product], which is designed to help [audience achieve] achieve [result]?`,

          `I need an advertorial for my [type of business] that provides [product] to [audience]. Can you write copy that appeals to [pain points] and highlights the advantages of using my service?`,

          `I'm launching a new [fill in the blank] service that provides [fill in the blank] to [fill in the blank]. Can you write an advertorial that speaks to [fill in the blank] and emphasizes the unique features of my product?`,
        ],
      },
      {
        value: 'meta-descriptions',
        label: 'Meta Descriptions',
        prompts: [
          `Write a meta description for a page about [page topic]`,

          `Write a meta description for a product page for [product]`,

          `Write a meta description for a page about travel booking that includes the keyword 'last-minute deals'.`,

          `For a page about [product/service], write a meta description that includes [keyword] and entices users to click`,

          `Craft a persuasive meta description for a page about [product/service] that includes [keyword], and prompts users to click and learn more about your offerings.`,
        ],
      },
    ],
  },
  {
    label: 'Copywriting',
    value: 'copywriting',
    subCategory: [
      {
        value: 'Improving Existing Copy',
        label: 'Improving-existing-copy',
        prompts: [
          `I am trying to make my ad copy for [product/service] more interesting. Can you help me come up with a catchy headline and a unique selling point that will grab people's attention?`,

          `I am looking to create an ad campaign for [product/service] that stands out. Can you help me write ad copy that is engaging, memorable and persuasive?`,

          `I want to create an ad for [product/service] that evokes emotions and resonates with [target audience]. Can you help me write copy that will connect with them on a deeper level?`,

          `Introduce our new [product/service] in a way that highlights its unique features and benefits.`,

          `Write an ad copy that showcases how our [product/service] can help [target audience] achieve [specific goal].`,

          `Paraphrase the following text: [text]. Make it [tone/style] and make sure it includes [keywords/main points].`,
        ],
      },
      {
        value: 'proofreading',
        label: 'Proofreading',
        prompts: [
          `Find and correct the typos in this text: [copy and paste text].`,

          `Tell me if there are typos or grammatical errors in this text: [copy and paste text].`,

          `Proofread this copy: [copy and paste text].`,

          `Fact-check the data in this text: [copy and paste text].`,

          `Suggest reliable sources to back up the claims in this text: [copy and paste text].`,
        ],
      },
      {
        value: 'creating-USPs',
        label: 'Creating USPs',
        prompts: [
          `Why is [type of company/business]'s [product/service] the best choice for [target audience] looking for [desired outcome]?`,

          `What unique solution does [type of company/business] offer to solve the [pain point] faced by [target audience]?`,

          `What makes [specific topic company/business] approach to [industry challenge] different and more effective?`,

          `How does [specific type of company/business] [product/service feature] provide a superior [customer benefit] compared to others in the market?`,

          `How does [specific type of company/business] [product/service feature] provide a superior [customer benefit] compared to others in the market?`,
        ],
      },
      {
        value: 'headlines',
        label: 'Headlines',
        prompts: [
          `What is the best way to write a headline for an article on [topic]?`,

          `How can I make a headline that grabs attention for a [type of content]?`,

          `What are some examples of headlines that effectively convey the main idea of [topic]?`,

          `What are some tips for writing headlines that will make people want to read [type of content]?`,

          `How can I write a catchy headline for a [topic] that will make people want to click and read the article?`,
        ],
      },
      {
        value: 'ad-copy',
        label: 'Ad Copy',
        prompts: [
          `Can you provide examples of effective ad copy for [specific product/service]?`,

          `What are the key elements that should be included in ad copy for [specific industry/field]?`,

          `How do I write a compelling headline for [specific product/service] ad targeting [specific audience/demographic]?`,

          `I am creating an ad campaign for [product/service] and need help writing copy that will grab the attention of potential customers. Can you help me come up with headlines and body copy that will persuade them to make a purchase?`,

          `I am launching a new [product/service] and need help writing ad copy that will generate excitement and interest of [audience]. Can you help me come up with headlines and body copy that will make people want to learn more?`,
        ],
      },
      {
        value: 'A/B-testing-copy',
        label: 'A/B Testing Copy',
        prompts: [
          `Can you rewrite a version of the [winning creative/ad copy] that we can use for A/B testing on our campaign targeting [specific demographics/audiences]?`,

          `Can you create a variation of the [winning creative/ad copy] that will appeal to a [different target audience/market segment] compared to the original version?`,

          `Can you rephrase the [winning creative/ad copy] to make it more [concise/impactful] for a [specific audience/campaign]`,

          `I need help rewriting a winning creative for an [ad campaign/email marketing/social media] campaign. Can you help me come up with a new version that is similar to the original but with a fresh perspective? Original copy: [Original ad copy]`,

          `I want to create an A/B version of a winning creative for [campaign]. Can you help me write a new version that will resonate with a different demographic? Original copy: [Original ad copy]`,
        ],
      },
      {
        value: 'marketing-angles',
        label: 'Marketing Angles',
        prompts: [
          `Brainstorm unique marketing angles for a new line of [product].`,

          `Brainstorm unique marketing angles for our [products] target [audience] who are concerned about [something they should be concerned about].`,

          `How can we position our [products] as a premium brand?`,

          `What are some key benefits of our [products] that we can highlight in our marketing campaigns?`,

          `How can we differentiate our [product] from other similar products on the market?`,
        ],
      },
      {
        value: 'Long-form-sales-copy',
        label: 'Long-form Sales Copy',
        prompts: [
          `Can you write an extended sales copy describing the unique features and benefits of [product/service] that sets it apart from the competition and makes it a must-have for [target audience]?`,

          `Can you write an extended sales copy on what are the top features of [product/service] that [target audience] will love?`,

          `Can you write an extended sales copy explaining in detail the process of using [product/service] and how it can help [target audience] achieve their goals?`,

          `Can you write an extended sales copy on what makes [product/service] stand out from similar offerings in the market, and why should [target audience] choose it over the competition?`,

          `Can you write an extended sales copy convincing me why [product/service] is the missing piece [target audience] needs to take their [aspect of life/business] to the next level?`,
        ],
      },
      {
        value: 'benefit-driven-sales-copy',
        label: 'Benefit-driven Sales Copy',
        prompts: [
          `Can you write a benefit-driven sales copy that highlights the unique features and benefits of [product/service]?`,

          `How would you describe the benefits of [product/service] to a potential customer in a way that would convince them to purchase?`,

          `Can you craft a benefit-driven sales copy that explains why [product/service] is the best solution for [customer's problem or need]?`,

          `How would you sell the benefits of [product/service] to a customer who is on the fence about making a purchase?`,

          `How would you sell the benefits of [product/service] to a customer who is sceptical about its effectiveness for [customer's problem or need]?`,
        ],
      },
      {
        value: 'translating-ads ',
        label: 'Translating Ads ',
        prompts: [
          `Translate the following ad copy into [language]: [ad copy]`,

          `Rewrite the following ad copy to make it more persuasive: [ad copy]`,

          `What are some alternative ways to phrase the following ad copy in [language]: [ad copy]`,

          `Write a compelling call-to-action for our [product/service] in [language]: [product/service description]`,

          `Create a catchy tagline for our new [product/service] in [language]: [product/service description]`,
        ],
      },
      {
        value: 'translating-web-copy',
        label: 'Translating Web Copy',
        prompts: [
          `I need help with translating this document, can you assist me?`,

          `Can Chat GPT provide me with a translation of this text?`,

          `I don't understand this text, can you translate it for me?`,

          `Can you help me to convert this text into another language?`,

          `Is it possible for Chat GPT to translate this passage into a different language?`,

          `Can you assist me with a translation of this paragraph?`,

          `Can Chat GPT help me to understand this text by translating it for me?`,

          `I need a translation of this document, can you help me out?`,

          `Can you translate this text into [target language]?`,
        ],
      },
    ],
  },
  {
    label: 'Website',
    value: 'website',
    subCategory: [
      {
        value: 'idea-generation-and-aesthetics',
        label: 'Idea Generation and Aesthetics',
        prompts: [
          `Can you provide me with some website design inspiration based on the theme of [insert topic here]?`,

          `Can you suggest some color schemes for a website in the [insert industry here] industry?`,

          `What are some website layouts that would work well for a [insert type of business or organization here]?`,

          `Can you give me some ideas on header and footer designs for a [insert type of website here] website?`,

          `Can you suggest some website font combinations for a [insert style or theme here] design?`,
        ],
      },
      {
        value: 'Product-focused Landing-pages',
        label: 'Product-focused Landing Pages',
        prompts: [
          `What are the benefits of using [product name] for [specific use case]?`,

          `How do I write persuasive copy for a landing page that focuses on [benefit]?`,

          `How does [product name] help with [problem it solves]?`,

          `What makes [product name] stand out from other [similar products/competitors]?`,

          `How has [product name] helped other customers improve their [specific aspect of business/life]?`,
        ],
      },
      {
        value: 'Product-comparison',
        label: 'Product Comparison',
        prompts: [
          `Which product, [product A] or [product B], offers better performance in regards to [feature 1], [feature 2], and [feature 3] and why?`,

          `What are the key differences between [product A] and [product B] in terms of [feature 1], [feature 2], and [feature 3]?`,

          `Which one is better, [product A] or [product B], and why in terms of [feature 1], [feature 2], and [feature 3]?`,

          `What makes [product A] a better option compared to [product B] when it comes to [feature 1], [feature 2], and [feature 3]?`,

          `Can you provide a side-by-side comparison of [product A] and [product B] in regards to [feature 1], [feature 2], and [feature 3]?`,
        ],
      },
      {
        value: 'Web-copy-translation',
        label: 'Web Copy Translation',
        prompts: [
          `Can you provide a translation of the following text from [source language] to [target language]: [specific text to be translated]`,

          `Translate the following sentence from [language] to [target language]: [Sentence]`,

          `What is the meaning of the following words from [language] to [target language]: [sentence]`,

          `Can you help me translate our website's [product/service] page into [language]? I want to make sure the language is natural and easy to understand for our international audience.`,

          `We're launching a new [product/service] in [country] and need to translate our website's [product/service] page into [language]. Can you help me create a translation that accurately conveys the features and benefits of the [product/service]?`,
        ],
      },
      {
        value: 'Testimonials ',
        label: 'Testimonials ',
        prompts: [
          `Can you write me a testimonial about what makes [product/service] a unique solution in the commerce industry?`,

          `Write 5 testimonials for [product] in a conversational way`,

          `Can you write me a testimonial about why you would recommend [product/service] to others?`,

          `Can you write me a testimonial about a specific scenario where [product/service] came in handy?`,

          `Can you write me a testimonial about whether you believe [product/service] is worth the investment?`,

          `Can you write me a testimonial about how [product/service] has simplified your day-to-day tasks?`,

          `Write 10 testimonials for [product] that address these objections: [Objection 1][Objection 2][Objection 3]. Make sure they sound real and use the words [ideal client] would use`,
        ],
      },
      {
        value: 'CTA-optimization ',
        label: 'CTA Optimization ',
        prompts: [
          `What are some effective strategies for creating a CTA that stands out on [a website/a landing page/an email campaign] and encourages users to take action for [specific product/ service]?`,

          `Can you give some advice on how to write a persuasive CTA that specifically targets [customers in a particular age group/location/industry]?`,

          `What are some common pitfalls to avoid in [industry/business] when designing a CTA, and how can I ensure my CTA is both attractive and easy to understand for [target audience]?`,

          `Can you help me come up with a strong call-to-action for my landing page that encourages visitors to sign up for my newsletter?`,

          `How can I measure the success of my CTA, and what metrics should I track to determine its effectiveness in driving conversions for [specific product/service]?`,

          `Suggest the best call to action for a [home/product/about] page for a [type of business]`,

          `How can I use language and tone in my CTA to build trust and credibility with potential customers, and encourage them to take action for [specific product/service]?`,
        ],
      },
    ],
  },
  {
    label: 'Business Toolkit ',
    value: 'business-toolkit',
    subCategory: [
      {
        value: 'competitor-research',
        label: 'competitor-research',
        prompts: [
          `Here are the names of my top competitors in [industry]: [competitor 1], [competitor 2], and [competitor 3]. Generate an analysis of their strengths, weaknesses, and market position.`,

          ` Here is a list of the key products or services offered by my top competitors. [Product 1] by [Company 1], [Product 2] by [Company 2]. Analyze their pricing, features, and marketing strategies to gain insights into their competitive position.`,

          `Please provide a list of the marketing channels used by my top competitors. Analyze their messaging, targeting, and customer engagement strategies to identify areas for improvement and gain a competitive advantage.`,

          `Who are my main competitors in the [industry/niche] and how do they compare to [my company/product]?`,

          `What are my competitors' [strengths/weaknesses] and how can I use this information to gain a competitive advantage?`,

          `What kind of [products/services] do my competitors offer and how do they compare to [my company/product]?`,
        ],
      },
      {
        value: 'Business-idea-generator',
        label: 'Business Idea Generator',
        prompts: [
          `Can you suggest some business ideas that are currently in demand?`,

          `Can you suggest some business ideas that are currently in demand in [insert industry or country]?`,

          `I'm looking for business ideas that require minimal investment. What do you suggest?`,

          `What are some business ideas that are innovative and unique?`,

          `I run this type of business [describe business]. What new products and services can I offer my customers?`,

          `Can you recommend some business ideas for a startup with limited resources?`,

          `I'm interested in starting a business in [insert industry]. What ideas do you have for me?`,
        ],
      },
      {
        value: 'business-plan-generator',
        label: 'Business Plan Generator',
        prompts: [
          `Write a business plan for my business called [name of the company] that sells [product] to [niche] and wants to [goals]`,

          `Write a business plan for my business called [name of the company] that sells [product] to [niche] and wants to [goals]. Include an executive summary, financial calculations of expected costs, sales and profit, and company mission and vision. Use a formal tone and statistics`,

          `I want to reach [goal] with my company and I require performance targets for my team. Please write an outline of quarterly milestones and roles accountable for each milestone`,

          `Write a list of specific, measurable, and attainable goals for [company or project] using the OKR framework`,

          `Turn this vision into a SMART goal: [insert vision]. Include the most important outcomes and deliverables`,
        ],
      },
      {
        value: 'proposal-writing',
        label: 'Proposal Writing',
        prompts: [
          `Write a proposal for my [potential client] who is struggling with [pain points]. These are the services that I offer [list services]. Make the proposal [describe the tone of voice]`,

          `Write a proposal for my potential [client] who is struggling with [pain points]. These are the services that I offer [list services]. Here is my proposed [pricing] and [timeline] for delivery. Make the proposal [describe the tone of voice]`,

          `I am pitching a project to [insert company/organization name] and need to write a proposal. The project is focused on [insert project scope]. What are the key deliverables and timeline for the project? How will I ensure that the project meets their needs? Write a proposal that is concise and persuasive`,

          `What solutions can a [type of business] provide to address any current pain points or challenges in the market?`,

          `How can [type of industry] demonstrate the ROI and potential impact of its services to clients?`,
        ],
      },
      {
        value: 'create-a-company-vision',
        label: 'Create a Company Vision',
        prompts: [
          `Generate a company vision statement that includes:<br/>Introduction: Our vision is to [insert your vision statement, such as 'change the world', 'disrupt the industry', or 'transform lives'].   <br/> Values: We believe in [insert your first value, such as 'innovation', 'excellence', or 'integrity'], [insert your second value], and [insert your third value]. These values guide our actions and decisions as we work toward our vision.  <br/> Call-to-Action: Join us as we work to [insert your vision statement in action, such as 'create a better future', 'inspire change', or 'make a difference']. <br/>  Contact Information: Contact us by [insert your preferred method of contact, such as phone, email, or chat] at [insert your team's email address or phone number] if you want to learn more. <br/>    Sign-off: Best regards, [insert your name].`,

          `I am pitching a project to [insert company/organization name] and need to write a proposal. The project is focused on [insert project scope]. What are the key deliverables and timeline for the project? How will I ensure that the project meets their needs? Write a proposal that is concise and persuasive.   `,

          `Generate a vision statement that encapsulates the purpose and direction of my company.`,

          `Refine my vision statement by inputting more specific prompts related to my company's goals, values, and audience.`,
        ],
      },
      {
        value: 'summarizing-meetings',
        label: 'Summarizing Meetings',
        prompts: [
          `Summarize the 5 main takeaways from these meeting notes: [copy and paste notes].`,

          `Summarize the decisions made and the next steps outlined in these meeting notes: [copy and paste notes].`,

          `Summarize the key points raised, the solutions proposed and the responsible departments in these meeting notes: [copy and paste notes].`,

          `Could you put together a meeting summary that I can share with other stakeholders? Here is the transcript of the recordings: [copy and paste transcript of the meeting].`,

          `I need to send a follow-up email to everyone who attended this meeting [copy and paste the transcript of the recordings]. Can you write a summary that I can include in the email?`,
        ],
      },
      {
        value: 'hiring',
        label: 'Hiring',
        prompts: [
          `How can I create a personalized message for a new hire of [role] that highlights their unique strengths and contributions to the [Company type] team, while also making them feel valued and supported in their new role?`,

          `What would be a good message to welcome a new hire to [company details] team and provide them with a brief overview of our company culture, values, and goals?`,

          `How can I create a fun and engaging onboarding experience for a new hire in [company type], that includes opportunities for them to get to know their colleagues, familiarize themselves with our company culture, and understand their role and responsibilities in [role]?`,

          `How can I create a message for a new hire for [role], that showcases our [company type] commitment to diversity, equity, and inclusion, and makes them feel welcome and valued as a member of our team?`,

          `Imagine you're hiring a new [position]. What experience and qualifications are essential for this role? What questions would you ask to assess their understanding of your target market and their ability to develop effective marketing strategies?`,
        ],
      },

      {
        value: 'resume',
        label: 'Resume',
        prompts: [
          `Define your professional summary: <br/>'Please generate a professional summary that accurately describes my experience as a ___________, with expertise in ___________ and a proven track record of ___________.'`,

          `List your work experience:<br/>'Please help me generate bullet points that describe my key responsibilities and achievements as a ___________ at ___________. Some of my major accomplishments include ___________ and ___________.'`,

          `Include your education: <br/>'Please create a section that lists my education, including my degree, major, graduation year, and any relevant academic achievements. I graduated from ___________ with a degree in ___________.'`,

          `Highlight your skills:<br/>'Please generate a section that highlights my key skills and areas of expertise, including ___________, ___________, and ___________. These skills have allowed me to excel in my role as a ___________ and would be valuable in any position requiring these abilities.`,

          `Customize your resume:<br/>'Please help me customize my resume for the ___________ position by emphasizing my experience with ___________ and ___________. I would like my resume to include relevant keywords and highlight my most relevant experiences and achievements in this field.`,
        ],
      },
    ],
  },
  {
    label: 'eCommerce',
    value: 'eCommerce',
    subCategory: [
      {
        value: 'Product Reviews',
        label: 'Product Reviews',
        prompts: [
          `Can you provide a comprehensive review of the [product/service], including its features, performance, and value for money?`,

          `Write what are possible likes and dislikes about the [product/service] and why? Write a review based on that info.`,

          `Write a review on how the [product/service] compares to other products in its category in terms of quality, features, and price?`,

          `Can you provide a brief overview of the [product/service]?`,
        ],
      },

      {
        value: 'Product-bundle-strategies',
        label: 'Product Bundle Strategies',
        prompts: [
          `Create a bundled product offering that effectively communicates the value proposition to customers for [product/brand].`,

          `Develop a bundled product pricing strategy that maximizes profitability for [product/brand].`,

          `Create a bundled product offering that considers the compatibility and complementarity of products for [product/brand].`,

          `Create me a product bundling strategy for [product/brand] that maximizes customer value.`,

          `Create me a product bundling strategy for [product/brand] that maximizes customer value.`,
        ],
      },
      {
        value: 'Cross-selling-strategies',
        label: 'Cross-selling Strategies',
        prompts: [
          `Can you provide a step-by-step plan for integrating cross-selling into our customer service interactions for [product line]?`,

          `What are some examples of effective cross-selling strategies for [specific industry]?`,

          `How can [specific industry] company implement successful cross-selling techniques for [product/service]?`,

          `Based on [the information about your customers, products] can you create a customized cross-selling strategy for [type of business]?`,

          `Can you suggest ways to overcome common challenges, such as customer pushback or low conversion rates, when implementing a cross-selling strategy for [product line]?`,
        ],
      },
      {
        value: 'strategizing-upsells',
        label: 'Strategizing Upsells',
        prompts: [
          `What are some examples of effective upselling strategies for [specific industry]?`,

          `How can [specific industry] company implement successful upselling techniques for [product/service]?`,

          `How can a business increase its revenue through upselling [product/service] to existing customers?`,

          `What are some effective methods for [specific company] to approach customers about upselling opportunities for [specific products or services]?`,

          `What are some additional accessories or services that can enhance the experience of using [product]?`,
        ],
      },
      {
        value: 'product-descriptions',
        label: 'Product Descriptions',
        prompts: [
          `I need to write a product description for my [product]. Can you help me highlight the key features and benefits in a way that will persuade potential customers to buy it?`,
          `I am struggling to find the right words to describe my [product]. Can you help me write a compelling product description that will make it stand out from the competition?`,
          `I am looking to write compelling product descriptions for my [e-commerce/online store] that will entice customers to make a purchase. Can you help me write descriptions for our top-selling [product category] that highlight the key features and benefits?`,
          `I am looking to write compelling product descriptions for my [e-commerce/online store] that will entice customers to make a purchase. Can you help me write descriptions for our top-selling [product category] that highlight the key features and benefits?`,
          `I am in need of product descriptions for a new line of [product category] that we are launching. Can you help me write descriptions that effectively communicate the unique selling points and differentiates our products from competitors?`,
          `I am planning to create a product description on my website for [product], the perfect solution for [audience]. Can you come up with an effective description that highlights its benefits and key features?`,
          `What is the most important information that I should include when creating product descriptions?`,
        ],
      },
    ],
  },
];

export { PromptData };

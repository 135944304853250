/* ------------------------------------------------------ */
/*                      PricePopCard                      */
/* ------------------------------------------------------ */

/**
 * Display of the 3 plans designs
 */

import { Card, Col, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { Popcards, PopBottom, PopBottomTop, PopCardsTop, PopWrap, Cardstyled } from './styled';
import Vector from '../../../../static/header/Vector.svg';
import { PriceData, CardEditData } from './pricePopData';
import ArrowRight from '../../../../static/header/ArrowRight.svg';
import ArrowRightHover from '../../../../static/header/ArrowRightHover.svg';

const PricePopCard = () => {
  const { Text, Title } = Typography;
  const [hover, setHover] = useState({ state: false, cardKey: null });
  const handleHoverIn = (cardKey) => {
    setHover({ state: true, cardKey });
  };

  const handleHoverOut = (cardKey) => {
    setHover({ state: false, cardKey });
  };

  const redirect = (url) => {
    url && window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <PopWrap>
      <Popcards>
        <Row gutter={[24, 24]}>
          {PriceData.map(({ key, icon, desc, title, url }) => (
            <Col key={`pricing-${key}`} onClick={() => redirect(url)}>
              <Card className="popcard">
                <PopCardsTop>
                  <img src={icon} alt="icon" style={{ maxWidth: '100%' }} />
                </PopCardsTop>
                <PopBottom>
                  <PopBottomTop>
                    <Title level={4}>{title}</Title>
                    <img src={Vector} alt="vector" />
                  </PopBottomTop>
                  <Text className="bottom-text">{desc}</Text>
                </PopBottom>
              </Card>
            </Col>
          ))}
        </Row>
      </Popcards>
      <div className="pop-bottom">
        {CardEditData.map(({ title, desc, icon, key }) => (
          <Card key={`price-${key}`} onMouseOver={() => handleHoverIn(key)} onMouseOut={() => handleHoverOut(key)}>
            <img src={icon} alt="Blog" />
            <Cardstyled>
              <div>
                <Text className="price-title">{title}</Text>
                <br />
                <Text className="price-description">{desc}</Text>
              </div>
              <img src={hover.state && key === hover.cardKey ? ArrowRightHover : ArrowRight} alt="ArrowRight" />
            </Cardstyled>
          </Card>
        ))}
      </div>
    </PopWrap>
  );
};

export default PricePopCard;

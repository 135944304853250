import { Checkbox, Col, Form, Row, Typography } from 'antd';
import React from 'react';
import { Button } from '../../../components/Button';
import { NotificationComponentWrap } from './styled';
import { NotificationData } from './mock';

const NotificationComponent = () => {
  const { Text, Title } = Typography;
  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <>
      <NotificationComponentWrap>
        <Title className="alert">Alerts & Notifications</Title>
        <Text className="opt">Opt in or out of email notifications</Text>

        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item name="send-me" label="Send me:">
            <Checkbox.Group>
              {NotificationData.map((item, index) => (
                <Row>
                  <Col span={24}>
                    <Checkbox value={item.value}>{item.title}</Checkbox>
                    <Text style={index === 3 ? { borderBottom: 'none' } : {}}>{item.description}</Text>
                  </Col>
                </Row>
              ))}
            </Checkbox.Group>
          </Form.Item>

          <Form.Item className="notification-btn">
            <Button size="large" type="primary">
              Update Email Notifications
            </Button>
          </Form.Item>
        </Form>
      </NotificationComponentWrap>
    </>
  );
};

export default NotificationComponent;

/* ------------------------------------------------------ */
/*                   TEAM PORTAL PAGE                     */
/* ------------------------------------------------------ */

/**
 * The main container of team portal.
 *
 * Team ID is the same ID of Team admin UID.
 *
 * Team Portal is to manage team members, view each team member results, team statistics
 * Team admin has the ability to add new members and remove the team members.
 *
 * Dashboard page is active for team members in team workspace. If the team admin wants to visit dashboard then he must be on personal workspace.
 *
 * Team member can also create his own team by switching from Personal to Team workspace.
 * A team member can also use Personal workspace on the bases of that he is a part of active team workspace.
 * A team member can use all the tools and special features on the bases of an active team.
 *
 * A team member on team workspace is not able to create new projects.
 *
 * The results of any tools of TEAM and PERSONAL WORKSPACE are stored in separate database collection in firebase
 *
 * The collection of team details. when a team is created these are the most important information
 * Firestore
 * Collection: team-members
 * Document: <UID>
 * data: admin email
 *       admin name
 *       admin UID
 *       members - email
 *       membersUID
 *       seatsCountAtTeamCreation
 *       teamName
 *       teamProjects - array
 *       teamStatus
 *
 * Condition: If a team admin created a team and after some time team admin switch to different plan or eixsitng team plan expired or cancenlled
 * so in this situation the team status is HOLD and team members cant use any tools. their personal workspace also wont work.
 * This condition is denoted by showTeamCreatedNoTeamPlan:
 *
 * if the team administrator renews the team plan with fewer seats than the number of team members that are currently available.
 * For instance, if there were 4 members when the team admin changed plans or the team plan expired, it is possible that when the team plan is renewed again,
 * the team admin will assign fewer seats than are actually necessary.
 * the entire team will be placed on HOLD status. To resolve this
 * 1. eliminate the excess team members.
 * 2. Increase the number of seats
 *
 */

import React, { useEffect, useState } from 'react';
import { Typography, Spin, Input, Divider, Result } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { TeamManagementWrapper } from './style';
import { Main } from '../styled';
import { Button } from '../../components/Button';
import {
  createTeamWithTeamPlan,
  fetchTeamMembers,
  fetchTeamMembersBots,
  getTopToolsUsedByTeamMembers,
  resetTeamPlanUpdateLoading,
  setAddTeamDisplay,
  updateTeamJoinAction,
  updateTeamMemberVisit,
  updateTeamName,
} from '../../redux/AccountSettings/actionCreator';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  TEAM,
  SHOW_TEAM_INFO,
  ROLES,
  PERSONAL_WORKSPACE,
  TEAM_WORKSPACE,
  PLAN_STATUS,
  TEAM_DASHBOARD_PAGE,
  TEAM_MEMBERS_PAGE,
  WITH_PLAN_STATUS,
  TEAM_STATUS,
} from '../../constants';
import TeamOnboarding from '../TeamOnboarding';
import Proration from './overview/Proration';
import loadingGIF from '../../static/general/loading.gif';
import {
  TEAM_ADMIN_VIEW_PORTAL,
  TEAM_DELETE_CONFIRMATION_CONTENT,
  TEAM_DELETE_CONFIRMATION_TITLE,
} from '../../constants/content';
import { cancelSubscriptionAuto, renewSubscription } from '../../services/apiServices';
import TeamHeader from '../../components/TeamPageHeader/TeamHeader';
import TeamDashboard from './overview/TeamDashboard';
import TeamMembersPage from './overview/TeamMembersPage';
import { deleteTeam, setMirrorTeamUID } from '../../redux/team/actionCreator';
import { Modal, alertModal } from '../../components/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import TeamCreatedNoTeamPlan from './overview/TeamCreatedNoTeamPlan';

const { Text, Paragraph, Link } = Typography;

const TeamPortal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showTeamMembers, setShowTeamMembers] = useState(false);
  const [showCreateTeamView, setShowCreateTeamView] = useState(false);
  // const [showOtherPlanViews, setShowOtherPlanViews] = useState(false);
  const [showTeamOnboardingView, setShowTeamOnboardingView] = useState(false);

  const [showTeamCreatedNoTeamPlan, setShowTeamCreatedNoTeamPlan] = useState(false);
  const [teamWorkspaceView, setTeamWorkspaceView] = useState(false);
  const [workspaceAdminUID, setWorkspaceAdminUID] = useState(null);
  const [updateTeamLoading, setUpdateTeamLoading] = useState(false);
  const [activateTeamLoading, setActivateTeamLoading] = useState(false);
  const [isAddMoreSeatVisible, setIsAddMoreSeatVisible] = useState(false);
  const [seatCounts, setSeatCounts] = useState([]);
  const [renewTeamLoading, setRenewTeamLoading] = useState(false);
  const [cancelTeamLoading, setCancelTeamLoading] = useState(false);
  const [createTeamDisable, setCreateTeamDisable] = useState(false);

  // current Page to display
  const [currentPageToDisplay, setCurrentPageToDisplay] = useState(TEAM_DASHBOARD_PAGE);

  // When team admin has selected the team workspace and goes to team portal page.
  const [showTeamAdminView, setTeamAdminView] = useState(false);

  const { activatedPlanDetails, plan, planStatus, roles, visitTeamPage, allowTeamStarterPlan } = useSelector(
    (state) => state.fb.profile,
  );
  const { uid } = useSelector((state) => state.fb.auth);
  const { adminBillingSettings } = useSelector((state) => state.billing);
  const { teamDeletedSuccessfully } = useSelector((state) => state.team);
  const [selectedMember, setSelectedMember] = useState({ teamMemberName: '', teamMemberUID: '' });
  const { subscriptionList } = useSelector((state) => state.stripe);
  const {
    teamMembersList,
    displayAddTeam,
    teamAdminUID,
    teamName,
    teamMembersLoading,
    wordCraftMemberList,
    selectedWorkspace,
    addTeamMemberSuccess,
    teamPlanUpdateLoading,
    teamInviteActionLoading,
    updateTeamNameLoading,
    teamProjectsList,
    topToolsUsedByTeamMembers,
    teamStatus,
  } = useSelector((state) => state.accountSettings);
  const { memberTeamsWithOutHold } = useSelector((state) => state.accountSettings);
  const { mirrorTeamUID } = useSelector((state) => state.team);

  const urlUID = mirrorTeamUID !== null && mirrorTeamUID;
  console.log('urlUID', urlUID);
  const { search } = location;

  useEffect(() => {
    checkDisplay();
    //  This check is for 1st time team member. When team member accept the request of team and then the user lands on team portal.
    visitTeamPage === false && dispatch(updateTeamMemberVisit());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (teamDeletedSuccessfully) {
  //     navigate('/settings/billing');
  //   }
  // }, [teamDeletedSuccessfully]);

  useEffect(() => {
    search.includes('create-new-team') && teamAdminUID === null && dispatch(setAddTeamDisplay());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, teamAdminUID]);

  // if in URL team-members is present then TeamMembersPage component will be displayed
  useEffect(() => {
    if (location && location.pathname && location.pathname.includes('/team-members')) {
      setCurrentPageToDisplay(TEAM_MEMBERS_PAGE);
    }
    if (location && location.pathname && location.pathname === '/team-portal') {
      setCurrentPageToDisplay(TEAM_DASHBOARD_PAGE);
    }
  }, [location]);

  const isCraftlyAdminView = roles === ROLES.ADMIN && urlUID;
  useEffect(() => {
    if (isCraftlyAdminView) {
      dispatch(fetchTeamMembers(urlUID));
      teamMembersList && teamMembersList.length > 0 && dispatch(fetchTeamMembersBots(urlUID));
      teamAdminUID && dispatch(getTopToolsUsedByTeamMembers({ workspaceUID: urlUID }));
      setWorkspaceAdminUID(urlUID);
    } else {
      const { workspaceType, selectedUID } = selectedWorkspace || {};
      if (workspaceType === PERSONAL_WORKSPACE) {
        dispatch(fetchTeamMembers(uid));
        teamMembersList && teamMembersList.length > 0 && dispatch(fetchTeamMembersBots(uid));
        teamAdminUID && dispatch(getTopToolsUsedByTeamMembers({ workspaceUID: uid }));
        setWorkspaceAdminUID(uid);
        setTeamWorkspaceView(false);
      } else if (workspaceType === TEAM_WORKSPACE) {
        dispatch(fetchTeamMembers(selectedUID));
        teamMembersList && teamMembersList.length > 0 && dispatch(fetchTeamMembersBots(selectedUID));
        teamAdminUID && dispatch(getTopToolsUsedByTeamMembers({ workspaceUID: selectedUID }));
        setTeamWorkspaceView(true);
        setWorkspaceAdminUID(selectedUID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTeamMemberSuccess, teamAdminUID, urlUID, uid, isCraftlyAdminView, selectedWorkspace]);

  //? The main logic which data should be displayed on Team Portal.
  const checkDisplay = () => {
    // Admin View Design
    setShowTeamMembers(
      plan === TEAM &&
        (planStatus === PLAN_STATUS.ACTIVE || planStatus === PLAN_STATUS.EXPIRING_SOON) &&
        selectedWorkspace &&
        selectedWorkspace.workspaceType === PERSONAL_WORKSPACE &&
        displayAddTeam === false &&
        teamAdminUID &&
        teamMembersList,
    );

    //> Those users who created team before but they switched their plan.
    if (planStatus === PLAN_STATUS.ACTIVE) {
      setShowTeamCreatedNoTeamPlan(
        SHOW_TEAM_INFO.includes(plan) && displayAddTeam === false && teamAdminUID && teamMembersList,
      );
    } else if (plan !== TEAM) {
      setShowTeamCreatedNoTeamPlan(teamAdminUID && teamMembersList);
    } else if (plan === TEAM && planStatus === WITH_PLAN_STATUS.EXPIRED) {
      setShowTeamCreatedNoTeamPlan(teamAdminUID && teamMembersList);
    }

    //| This is Default view when Team is not created.
    setShowCreateTeamView(
      displayAddTeam === false && (teamAdminUID === undefined || teamAdminUID === null) && teamMembersList,
    );
    setShowTeamOnboardingView(displayAddTeam === true);

    if (selectedWorkspace && selectedWorkspace.selectedUID === uid) {
      setTeamAdminView(true);
    }
  };

  useEffect(() => {
    checkDisplay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    plan,
    teamPlanUpdateLoading,
    displayAddTeam,
    addTeamMemberSuccess,
    teamAdminUID,
    teamMembersList,
    selectedWorkspace,
    planStatus,
  ]);

  // reset Team Protal
  useEffect(() => {
    if (teamPlanUpdateLoading && plan === TEAM) {
      dispatch(resetTeamPlanUpdateLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, teamPlanUpdateLoading]);

  // To open the details of selected memeber in member-summary-bots
  const onMemberSelect = (selectedMemberDetails) => {
    setSelectedMember(selectedMemberDetails);
    navigate('/team-member-details', {
      state: {
        selectedMember: selectedMemberDetails,
        wordCraftMemberList,
        workspaceAdminUID,
      },
    });
  };

  const onSeatChange = (count) => {
    setSeatCounts(count);
  };

  const showModal = () => {
    setIsAddMoreSeatVisible(true);
  };

  const handleOk = () => {
    setIsAddMoreSeatVisible(false);
  };

  const handleCancel = () => {
    setIsAddMoreSeatVisible(false);
  };

  // on Activate Team,
  // User created a team plan in the past and has chosen to do so again.
  const onActivateTeam = () => {
    const subID = subscriptionList && subscriptionList.length > 0 && subscriptionList[0].subscriptionId;
    setActivateTeamLoading(true);
    dispatch(createTeamWithTeamPlan({ subID, seatCounts, teamPlanActivateOnly: true }));
  };

  const { quantity } = activatedPlanDetails || { quantity: 0 };
  const teamQuantity = quantity;

  const makeTeamMemberAction = ({ memberEmail, action, teamMemberUID }) => {
    // Generic team action which deals all kind of information.
    dispatch(updateTeamJoinAction({ action, teamMemberUID, teamAdminUID: uid, memberEmail, teamAdminControl: true }));
  };
  const onEditTeamName = (e) => {
    const newName = e.target.value;
    dispatch(updateTeamName(newName));
  };

  const content = (
    <Input
      style={{ marginTop: '1rem', width: '170px' }}
      onPressEnter={onEditTeamName}
      defaultValue={teamName}
      suffix={updateTeamNameLoading && <LoadingOutlined />}
    />
  );

  const getTeamPlanId = () => {
    const planDetails = adminBillingSettings.find(({ plan }) => plan === 'team');
    if (planDetails) {
      // this is to handle users who want special team with starter plan.
      if (allowTeamStarterPlan) {
        const { teamStarterMonthly } = planDetails.details;
        return teamStarterMonthly;
      } else {
        // this is for normal users.
        const { monthly } = planDetails.details;
        return monthly;
      }
    } else {
      return null;
    }
  };

  // renew team plan on the subscription Id
  // team admin cancel the team plan but now wants to resume it
  const onRenewPlan = () => {
    const subID = subscriptionList && subscriptionList.length > 0 && subscriptionList[0].subscriptionId;
    if (subID) {
      setRenewTeamLoading(true);

      // renew team plan subscription
      renewSubscription({ subID })
        .then((res) => {
          // console.log(`res`, res);
        })
        .catch((error) => {
          setRenewTeamLoading(false);
          console.log(`error`, error);
        });
    }
  };

  const onCancelPlan = () => {
    const subID = subscriptionList && subscriptionList.length > 0 && subscriptionList[0].subscriptionId;
    if (subID) {
      setCancelTeamLoading(true);

      // Cloud Function API
      cancelSubscriptionAuto({ subID })
        .then((res) => {
          // setCancelTeamLoading(false);
          // console.log(`res`, res);
        })
        .catch((error) => {
          setCancelTeamLoading(false);
          console.log(`error`, error);
        });
    }
  };

  useEffect(() => {
    if (planStatus === PLAN_STATUS.EXPIRING_SOON) {
      setCancelTeamLoading(false);
    } else if (planStatus === PLAN_STATUS.ACTIVE) {
      setRenewTeamLoading(false);
    }
  }, [planStatus]);

  const page = (page) => {
    setCurrentPageToDisplay(page);
  };

  function warning() {
    alertModal.confirm({
      closable: true,
      title: TEAM_DELETE_CONFIRMATION_TITLE,
      okType: 'danger',
      okText: 'Reset Team',
      cancelText: 'Cancel',
      content: TEAM_DELETE_CONFIRMATION_CONTENT,
      onOk() {
        dispatch(deleteTeam());
      },
    });
  }

  // craftly admin watch user profile as a user.
  const cancelCraftlyAdminView = () => {
    console.log('mirrorTeamUID');
    // dispatch(setMirrorTeamUID('null'));
  };

  return (
    <Main>
      <TeamManagementWrapper>
        {teamPlanUpdateLoading && (
          <div className="loading-gif">
            <Text>Finalizing Team Plan...</Text>
            <br />
            <img alt="" src={loadingGIF} style={{ width: '50%' }} />
          </div>
        )}

        {!isCraftlyAdminView && !teamWorkspaceView && !teamPlanUpdateLoading && (
          <>
            {showTeamMembers && (
              // Edit team name functionality
              // <div className="page-title">
              //   <span style={{ marginRight: '10px' }}> Hello </span>
              //   <Popover trigger="click" content={content} title="Change Team Name">
              //     {capitalizeFirstLetter(teamName)}
              //     <sup>
              //       <EditOutlined style={{ fontSize: '15px', color: '#08c' }} />{' '}
              //     </sup>
              //   </Popover>
              //   , Welcome Back
              // </div>
              <TeamHeader
                isCraftlyAdminView={isCraftlyAdminView}
                currentPageToDisplay={currentPageToDisplay}
                name={teamName}
                currentPageLink={page}
                viewAsUser={() => cancelCraftlyAdminView()}
              />
            )}
            <div className="page-wrapper">
              <div className="page-title">
                <Text>{!showTeamMembers && `Team Portal`}</Text>
              </div>
              {!showCreateTeamView && (showTeamMembers || teamName) && (
                <Button size="small" type="secondary" onClick={warning}>
                  Reset Team
                </Button>
              )}
            </div>

            {(teamMembersList && teamMembersList.length === 0) ||
              (showCreateTeamView === null &&
                showTeamCreatedNoTeamPlan === null &&
                !showTeamOnboardingView &&
                !showTeamMembers && (
                  <div className="team-spinner">
                    <Spin />
                    <Text>Fetching Details </Text>
                  </div>
                ))}

            {/* the default UI for a team page when there is no team created */}
            {showCreateTeamView && (
              <>
                <div className="title-wrapper">
                  <div>
                    <Text className="welcome-title">
                      Craftly Team Portal is a great place to manage colleagues and collaborative projects{' '}
                    </Text>
                    <div className="plan-points">
                      <Paragraph className="subtitle">
                        <CheckOutlined className="icon" />
                        Each team member will be billed to your account as the administrator
                      </Paragraph>
                      <Paragraph className="subtitle">
                        <CheckOutlined className="icon" />
                        Each team seat will be 30% off
                      </Paragraph>
                      <Paragraph className="subtitle">
                        <CheckOutlined className="icon" />
                        All team members will have access to each other's crafted content
                      </Paragraph>
                      <Paragraph className="subtitle">
                        <CheckOutlined className="icon" />
                        All team members will have unlimited crafting access
                      </Paragraph>
                    </div>
                  </div>
                </div>

                <div className="create-team-wrapper">
                  <Button onClick={() => dispatch(setAddTeamDisplay())} type="primary" size="large">
                    Create a Team
                  </Button>
                </div>
                <div className="getting-started">
                  <Paragraph>
                    Need help getting started?{' '}
                    <Link href="https://calendly.com/nicole-lytle/teams" target={'_blank'}>
                      Book an onboarding call!{' '}
                    </Link>
                  </Paragraph>
                </div>
                <div className="team-note">
                  <Paragraph>
                    If you are already part of team, please make sure you are on your ‘Team Workspace’ to access it
                  </Paragraph>
                </div>
              </>
            )}

            {/* if the team plan was created but later on admin switch to different plan or team plan expired or cancelled. */}
            {showTeamCreatedNoTeamPlan && (
              <TeamCreatedNoTeamPlan
                renewTeamLoading={renewTeamLoading}
                activatedPlanDetails={activatedPlanDetails}
                plan={plan}
                planStatus={planStatus}
                teamName={teamName}
                onRenewPlan={onRenewPlan}
                onSeatChange={onSeatChange}
                memberTeamsWithOutHold={memberTeamsWithOutHold}
                seatCounts={seatCounts}
                activateTeamLoading={activateTeamLoading}
                onActivateTeam={onActivateTeam}
                cancelTeamLoading={cancelTeamLoading}
                onCancelPlan={onCancelPlan}
                setUpdateTeamLoading={setUpdateTeamLoading}
                updateTeamLoading={updateTeamLoading}
                setCreateTeamDisable={setCreateTeamDisable}
                getTeamPlanId={getTeamPlanId()}
              />
            )}

            {showTeamOnboardingView && <TeamOnboarding />}

            {showTeamMembers && (
              <>
                {currentPageToDisplay === TEAM_DASHBOARD_PAGE && (
                  <TeamDashboard
                    wordCraftMemberList={wordCraftMemberList}
                    teamMembersList={teamMembersList}
                    teamProjectsList={teamProjectsList}
                    addNewMember={() => setCurrentPageToDisplay(TEAM_MEMBERS_PAGE)}
                    teamAdminControlOnly={teamAdminUID === uid}
                    topToolsUsedByTeamMembers={topToolsUsedByTeamMembers}
                  />
                )}

                {currentPageToDisplay === TEAM_MEMBERS_PAGE && (
                  <TeamMembersPage
                    teamMembersList={teamMembersList}
                    teamQuantity={teamQuantity}
                    teamMembersLoading={teamMembersLoading}
                    teamAdminControlOnly={teamAdminUID === uid}
                    onMemberSelect={onMemberSelect}
                    selectedMember={selectedMember}
                    wordCraftMemberList={wordCraftMemberList}
                    makeTeamMemberAction={makeTeamMemberAction}
                    teamInviteActionLoading={teamInviteActionLoading}
                    openSeatModal={showModal}
                  />
                )}
              </>
            )}
          </>
        )}

        {/* Craftly admin View  and TeamWorkspace for team workspace and team admin  */}
        {(isCraftlyAdminView || teamWorkspaceView) && (
          <>
            <TeamHeader
              isCraftlyAdminView={isCraftlyAdminView}
              currentPageToDisplay={currentPageToDisplay}
              name={teamName}
              currentPageLink={page}
            />

            {/* Crafly Admin can view team portal   */}
            {showTeamAdminView && !isCraftlyAdminView && <Text>{TEAM_ADMIN_VIEW_PORTAL()} </Text>}

            {(!showTeamAdminView || isCraftlyAdminView) && (
              <>
                {currentPageToDisplay === TEAM_DASHBOARD_PAGE && teamStatus !== TEAM_STATUS.HOLD && (
                  <TeamDashboard
                    wordCraftMemberList={wordCraftMemberList}
                    teamMembersList={teamMembersList}
                    teamProjectsList={teamProjectsList}
                    addNewMember={() => setCurrentPageToDisplay(TEAM_MEMBERS_PAGE)}
                    teamAdminControlOnly={teamAdminUID === uid}
                    topToolsUsedByTeamMembers={topToolsUsedByTeamMembers}
                  />
                )}

                {/*currentPageToDisplay is to display the page route
                 teamStatus is not equal to hold  */}
                {currentPageToDisplay === TEAM_MEMBERS_PAGE && teamStatus !== TEAM_STATUS.HOLD && (
                  <TeamMembersPage
                    teamMembersList={teamMembersList}
                    teamQuantity={teamQuantity}
                    teamMembersLoading={teamMembersLoading}
                    teamAdminControlOnly={teamAdminUID === uid}
                    onMemberSelect={onMemberSelect}
                    selectedMember={selectedMember}
                    wordCraftMemberList={wordCraftMemberList}
                    makeTeamMemberAction={makeTeamMemberAction}
                    teamInviteActionLoading={teamInviteActionLoading}
                    openSeatModal={showModal}
                  />
                )}
                {teamStatus === TEAM_STATUS.HOLD && (
                  <Result
                    status="403"
                    subTitle="Sorry, There is no Team Plan in place. Please contact your administrator for help"
                  />
                )}
              </>
            )}
            <br />

            {/* <Row>
              <Col xs={12}>
                <Title level={4}>Group Chat</Title>
                <TeamChatApp teamID={workspaceAdminUID} teamName={teamName} />
              </Col>
            </Row> */}
          </>
        )}

        <Modal
          width={600}
          footer={null}
          destroyOnClose
          open={isAddMoreSeatVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Proration
            reset={isAddMoreSeatVisible}
            onCancel={handleCancel}
            activatedPlanDetails={activatedPlanDetails}
            showNextMonth
            disableCreateTeamButton={setCreateTeamDisable}
          />
        </Modal>
      </TeamManagementWrapper>
    </Main>
  );
};

export default TeamPortal;

import styled from 'styled-components';

const BillingStyled = styled.div`
  margin-top: 2rem;
  padding: 1rem 1.2rem;
  margin-bottom: 1rem;

  @media only screen and (min-width: 1200px) {
    padding: 1rem 2rem;
  }

  .top-heading {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 576px) {
      flex-direction: column;
    }
    .heading {
      display: flex;
      flex-direction: column;
      margin-bottom: 14px;

      .plan-text {
        font-size: 16px;
        font-family: 'Poppins-SemiBold';
      }
      .plan-description {
        font-size: 14px;
        font-family: 'Poppins-Regular';
        color: #737277;
      }
    }
    .action-billing-btn {
      justify-content: flex-end;
      display: flex;
    }
  }
  .current-plan {
    margin-top: 1.7rem;
    .pending-invoice {
      margin-top: 2.5rem;
    }

    .team-section {
      padding: 20px;
      background: #6c5afb;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      justify-content: space-between;
      .top {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .crafter-access-btn {
          color: white;
        }
      }
      .team-action {
        margin-top: 1.5rem;
        display: flex;
        width: 100%;
        flex-direction: column;
        .team-text {
          font-size: 16px;
          font-family: 'Poppins-SemiBold';
          color: #ffffff;
        }
        .configure-btn {
          border-radius: 8px;
          color: #705bfa;
        }
      }
    }
  }

  .plan-type {
    margin: 3rem 0px;
    width: 100%;

    .plan-duration {
      width: auto;
      padding: 1rem;
      display: flex;
      justify-content: center;
    }

    .team-text {
      color: #282b3f;
      font-size: 16px;
      font-family: 'Poppins-SemiBold';
    }
    .plan-feature {
      cursor: pointer;
      border: 1px solid #e2e4e8;
      border-radius: 10px;
      padding: 25px;
      width: 100%;
      min-height: 324px;
      max-height: 100%;

      .title {
        color: #737277;
        font-size: 14px;
        font-family: 'Poppins-Regular';
      }
      .radio-text {
        font-size: 16px;
        font-family: 'Poppins-SemiBold';
      }
      .feature-section {
        margin-top: 1rem;
        .title {
          color: #737277;
          font-size: 14px;
          font-family: 'Poppins-Regular';
          margin-bottom: 10px;
        }
        .feature-text {
          margin: 8px 0px;
          color: #737277;
          font-size: 13px;
          .tick {
            margin-right: 10px;
          }
        }
      }
      .starts-at {
        display: flex;
        flex-direction: column;
        align-items: end;
        .starts {
          font-size: 13px;
          font-family: 'Poppins-Regular';
          color: #737277;
        }
        .start-at-price {
          flex-wrap: nowrap;
          display: flex;
          align-items: baseline;
          .plan-price {
            margin-top: 5px;
            font-size: 24px;
            font-family: 'Poppins-Medium';
            color: ${({ theme }) => theme['color-black']};
          }
        }
      }
    }
    .selected-plan {
      background: #f9f8ff;
    }
    .price-section {
      margin: 3rem 0px;
      .price-text {
        color: ${({ theme }) => theme['primary-color']};
        font-size: 14px;
      }
      .ant-radio-wrapper {
        color: #bfbfbf;
        font-family: 'Poppins-Medium';
        font-size: 15px;
      }
      .selected-price {
        .ant-radio-wrapper {
          color: ${({ theme }) => theme['color-black']};
        }
      }
      .ant-radio-group {
        margin-top: 10px;
      }
    }
  }
  .detail-plan {
    margin-top: 1rem;
    margin-bottom: 4rem;
    .detail {
      min-height: 65px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      .parameter-name {
        font-size: 15px;
        color: ${({ theme }) => theme['color-black']};
        font-family: 'Poppins-Medium';
      }
      .parameter {
        display: flex;
        justify-content: space-between;
        align-items: end;
        .parameter-type {
          color: #737277;
          font-size: 12px;
          font-family: 'Poppins-Regular';
        }
        .amount {
          font-size: 14px;
          color: ${({ theme }) => theme['color-black']};
          font-family: 'Poppins-Medium';
        }
      }
      .plan-divider {
        margin: 7px 0;
      }
      .parameter-single {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0px;
        .parameter-name {
          font-size: 15px;
          color: ${({ theme }) => theme['color-black']};
          font-family: 'Poppins-Medium';
        }
        .amount {
          font-size: 14px;
          color: ${({ theme }) => theme['color-black']};
          font-family: 'Poppins-Medium';
        }
        .discount {
          font-size: 12px;
          color: ${({ theme }) => theme['color-black']};
          font-family: 'Poppins-Medium';
          margin-right: 1rem;
        }
      }

      .code-correct {
        display: flex;
        float: right;
        align-items: flex-end;
        flex-direction: column;
      }
      .discount-input-wrapper {
        display: flex;
        justify-content: space-between;
        .discount-name {
          font-size: 15px;
          color: ${({ theme }) => theme['color-black']};
          font-family: 'Poppins-Medium';
          word-break: keep-all;
        }
        .discount-code-wrapper {
          margin-left: 10px;
          width: 100%;
          .discount-field {
            .discount-code-input-field {
              float: right;
            }
          }
        }
      }
    }
    .action-button {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      .text {
        color: #737277;
        font-size: 11px;
      }
      .btn-upgrade {
        height: 44px;
        width: 200px;
        border-radius: 8px;
      }
      .btn-renew {
        height: 44px;
        width: 200px;
        border-radius: 8px;
        color: #705bfa;
      }
    }
  }
`;

const BillingHeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 0px;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
  margin-top: 2rem;

  .page-title {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.2;
    @media only screen and (max-width: 575px) {
      font-weight: 300;
    }
  }
  .billing-action-buttons {
    float: right;
    display: flex;
    align-items: center;
    .cancel-subscription {
      margin-right: 1rem;
      @media only screen and (max-width: 575px) {
        margin-right: 0rem;
      }
    }
    @media only screen and (max-width: 575px) {
      flex-direction: column;
      align-items: initial;
      row-gap: 15px;
      padding-top: 1.5rem;
    }
  }
`;

const PopUpContentWrapper = styled.div`
  .ant-modal-body {
    border: 2px solid white;
    border-radius: 3px;
  }

  .content {
    text-align: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .button-action {
    display: flex;
    justify-content: center;
  }
`;

export { BillingStyled, BillingHeaderWrap, PopUpContentWrapper };

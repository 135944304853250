/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import { SearchOutlined } from '@ant-design/icons';
import { Col, Collapse, Input, Row, Typography } from 'antd';
import React from 'react';
import { PeopleSearchBarWrap, SidebarMenuInput, PeapleSearchHead } from './styled';
import { Button } from '../../../../components/Button';
import User from '../../../../static/new-teams/User.svg';
import AddUser from '../../../../static/new-teams/AddUser.svg';
import Category from '../../../../static/new-teams/Category.svg';
import MemberMobileSearch from './MemberMobileSearch';

const MemberSearchBar = () => {
  const { Panel } = Collapse;
  const { Text, Title } = Typography;

  return (
    <PeopleSearchBarWrap>
      <div className="people-title">
        <Title>People (4)</Title>
      </div>
      <PeapleSearchHead>
        <div className="people-search">
          <Row gutter={[20, 20]} className="people-search">
            {window.innerWidth > 767 ? (
              <Col>
                <Input
                  placeholder="Seacrh members by name or email"
                  prefix={<SearchOutlined style={{ fontSize: '20px' }} />}
                />
              </Col>
            ) : (
              ''
            )}
            {window.innerWidth > 767 ? (
              <>
                <Col>
                  <SidebarMenuInput>
                    <Collapse defaultActiveKey={['1']}>
                      <Panel
                        header={
                          <>
                            <img src={User} alt="User" className="imageuser" />
                            <Text>All roles</Text>
                          </>
                        }
                        key="1"
                      ></Panel>
                    </Collapse>
                  </SidebarMenuInput>
                </Col>
                <Col>
                  <Button size="default" type="primary">
                    <div>
                      <img src={AddUser} alt="Add new member" className="image-button" />
                      Add new member
                    </div>
                  </Button>
                </Col>
              </>
            ) : (
              <MemberMobileSearch />
            )}
          </Row>
          {window.innerWidth < 768 ? (
            <div className="search-input">
              <Col>
                <Input
                  placeholder="Seacrh members by name or email"
                  prefix={<SearchOutlined style={{ fontSize: '20px' }} />}
                />
              </Col>
              <div style={{ marginRight: '16px' }}>
                <img src={Category} alt="Category" />
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        {window.innerWidth > 768 && (
          <div>
            <img src={Category} alt="Category" />
          </div>
        )}
      </PeapleSearchHead>
    </PeopleSearchBarWrap>
  );
};

export default MemberSearchBar;

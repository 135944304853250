/* ------------------------------------------------------ */
/*                        ErrorPage                       */
/* ------------------------------------------------------ */

/**
 * This error page occur if the user profile in firestore have some issues in it.
 * it also check the token of the logged in person.
 *
 * This screen also appear if the user doesnt have a good internet connection and
 * it failed to fetch the user data from firebase.
 */

import React, { useEffect, useState } from 'react';
import { ErrorPageWrapper } from './style';
import { useNavigate } from 'react-router-dom';
import Signout from '../../components/Signout';
import { useSelector } from 'react-redux';
import { Typography, Row, Col, Spin } from 'antd';
import { getFirebase } from 'react-redux-firebase';

const { Text, Title, Link } = Typography;

const ErrorPage = () => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(true);
  const [showFirebaseError, setShowFirebaseError] = useState(null);
  const { profile, uid } = useSelector((state) => {
    return {
      uid: state.fb.auth.uid,
      profile: state.fb.profile,
    };
  });

  useEffect(
    () => {
      // profile && profile.isApproved && navigate('/');
      let timer1 = setTimeout(() => setShowSpinner(false), 2000);
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    [navigate, profile],
  );

  useEffect(() => {
    const firebase = getFirebase();
    setShowFirebaseError(null);

    // it is to verify the user
    if (uid) {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {})
        .catch(function (error) {
          setShowSpinner(false);
          setShowFirebaseError(error.message);
        });
    }
  }, [profile, navigate, uid]);

  return (
    <ErrorPageWrapper>
      {showSpinner ? (
        <div className="loading-time-spinner">
          <Spin />
        </div>
      ) : (
        <>
          <Row justify="space-between">
            <Col xs={6} sm={3} md={2}>
              <img
                className="logo"
                style={{ width: '100%', margin: '2rem' }}
                src={require('../../static/general/craftly_final.png')}
                alt="logo"
              />
            </Col>
            <Col>
              <div style={{ margin: '2rem' }}>
                <Signout />
              </div>
            </Col>
          </Row>
          <div className="box-wrapper">
            <div className="verify-box">
              {window.navigator.onLine ? (
                <>
                  {showFirebaseError ? (
                    <Title level={2} className="early-text">
                      {showFirebaseError} <br />
                    </Title>
                  ) : (
                    <Title level={2} className="early-text">
                      Oops! Something has gone wrong <br />
                    </Title>
                  )}
                  <br />
                  <Text className="text-notice">
                    Please refresh your page or contact administration at <Link>support@craftly.ai</Link> if the error
                    persists
                  </Text>
                </>
              ) : (
                <>
                  <Title level={1} className="early-text">
                    No Internet Connection Found
                  </Title>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </ErrorPageWrapper>
  );
};

export default ErrorPage;

/* ------------------------------------------------------ */
/*                       Price List                       */
/* ------------------------------------------------------ */

/**
 * Price List
 * There are 3 type of plan list
 * 1. Starter
 * 2. Crafter
 * 3. Enterpise
 *
 * The list contains the feature detail points, words calculation, yearly and monthly prices.
 * In the V2 pricing model, there would be a slider from which the customer could choose the terms that best fit their needs.
 * A tier structure would be put in place to allow for a variable number of words in each plan period.
 * For example, in the V1 pricing model, the Starter plan contains 20,000 words for $35 per month,
 * however in the V2 pricing model, the Starter plan has numerous possibilities, such as $29 for 20,000 words and $49 for 50k words.
 * The slider UI makes it simple to choose a plan.
 *
 */

import React from 'react';
import { Typography, Divider, Card } from 'antd';
import { TRAIL_STARTER_DATA, TRAIL_CUSTOM_DATA, TRAIL_CRAFTER_DATA } from '../../../constants/trailprice';
import { Button } from '../../../components/Button';
import { CustomSlider } from '../../../components/CustomSlider';
import PlanPoints from '../../../components/PlanPoints';
import { BUTTON_YELLOW_GRADIENT, CRAFTER, ENTERPRISE, STARTER } from '../../../constants';
import { PriceListStyled } from '../styled';

/**
 *
 * @title plan title Starter | Crafter | Enterprise
 * @description plan description
 * @marks  v2 pricing object. it set the slider according to tier structure
 * @titleId plan id
 * @words display of words of each plan
 * @startBtn the button of the selection of plan. normally starter and crafter have same text. enterprise has different text
 * @price plan price
 * @selectedSliderTier v2 pricing of selected slider tier either crafter or starter
 * @starterSliderValue v2 starter slider value of starter plan
 * @crafterSliderValue v2 starter slider value of crafter plan
 * @onStarterSliderChange  v2 pricing of starter slider
 * @highlight  crafter plan is highlighted as it is recommended plan
 * @selectedPrice selected price of each plan
 * @v1Pricing v1Pricing is active or not
 */
const PriceList = ({
  title,
  marks,
  titleId,
  description,
  words,
  startBtn,
  price,
  selectedSliderTier,
  starterSliderValue,
  crafterSliderValue,
  onStarterSliderChange,
  highlight,
  selectedPrice,
  v1Pricing,
}) => {
  const { Text, Title } = Typography;

  const formatter = (value) => {
    return <Text className="tooltip-slider">{`${value}%`}</Text>;
  };
  return (
    <PriceListStyled highlight={highlight} key={titleId}>
      <Card className={`${highlight && 'active-plan-box'} plan-tiers `}>
        <div>
          {titleId === STARTER && (
            <div style={{ display: 'flex', justifyContent: 'space-between', height: '50px' }}>
              <figure>
                <img src={require('../../../static/trial/Work.svg')} alt="" />
              </figure>
            </div>
          )}

          {titleId === CRAFTER && (
            <div style={{ display: 'flex', justifyContent: 'space-between', height: '50px' }}>
              <figure>
                <img src={require('../../../static/trial/two-users.png')} alt="2nd pic" />
              </figure>
              <div className="plan-recommended">Recommended</div>
            </div>
          )}

          {titleId === ENTERPRISE && (
            <div style={{ display: 'flex', justifyContent: 'space-between', height: '50px' }}>
              <figure>
                <img src={require('../../../static/trial/users-group.png')} alt="" />
              </figure>
            </div>
          )}

          <div className="price-content">
            <Text className="plan-tilte">{title}</Text>
            <span className="plan-des">{description}</span>
          </div>

          {titleId === ENTERPRISE ? (
            <div className="price-content" style={{ display: 'inline-block', marginTop: '10px' }}>
              <Title className="plan-heading">Custom</Title>
            </div>
          ) : (
            <div className="price-content monthly" style={{ display: 'inline-block' }}>
              <Text className="plan-price">${price}</Text>
              <Text className="plan-month">/Month</Text>
            </div>
          )}

          <div className="price-buttons">
            <Button type="light" className={`select-price  ${highlight && 'highlight-words-button'}`}>
              {words}
            </Button>
          </div>
        </div>
        {titleId === STARTER && (
          <div className="plan-slider">
            {!v1Pricing && (
              <CustomSlider
                onAfterChange={onStarterSliderChange}
                tooltipVisible={false}
                tipFormatter={formatter}
                fixValue={starterSliderValue}
                marks={marks}
                trackColor={highlight && 'green-slider-color'}
                railColor={highlight && 'lightblue-slider-rail-color'}
              />
            )}
          </div>
        )}
        {titleId === CRAFTER && (
          <div className="plan-slider">
            {!v1Pricing && (
              <CustomSlider
                onAfterChange={selectedSliderTier}
                step={null}
                tooltipVisible={false}
                defaultValue={crafterSliderValue}
                tipFormatter={formatter}
                marks={marks}
                trackColor={highlight && 'green-slider-color'}
                railColor={highlight && 'lightblue-slider-rail-color'}
              />
            )}
          </div>
        )}
        <div className="price-buttons">
          {titleId === CRAFTER && (
            <Button
              block
              type={highlight ? BUTTON_YELLOW_GRADIENT : 'primary'}
              className={'price-start-button'}
              onClick={() => selectedPrice(titleId)}
            >
              {startBtn}
            </Button>
          )}
          {titleId === STARTER && (
            <Button
              block
              type={highlight ? BUTTON_YELLOW_GRADIENT : 'primary'}
              className={`price-start-button`}
              onClick={() => selectedPrice(titleId)}
            >
              {startBtn}
            </Button>
          )}
          {titleId === ENTERPRISE && (
            <Button
              block
              type="primary"
              className={'price-start-button-modified'}
              onClick={() => selectedPrice(titleId)}
            >
              {startBtn}
            </Button>
          )}
        </div>

        <Divider className="divider" />
        <Text className="plan-list-title">Plan Inclusions List </Text>
        {title === 'Starter'
          ? TRAIL_STARTER_DATA.map(({ name, flag, hover }) => <PlanPoints text={name} condition={flag} hover={hover} />)
          : title === 'Crafter'
          ? TRAIL_CRAFTER_DATA.map(({ recommended, name, flag, hover }) => (
              <PlanPoints recommended={recommended} text={name} condition={flag} hover={hover} />
            ))
          : TRAIL_CUSTOM_DATA.map(({ recommended, name, flag, hover }) => (
              <PlanPoints text={name} condition={flag} hover={hover} />
            ))}
      </Card>
    </PriceListStyled>
  );
};

export default PriceList;

import { Card, Typography } from 'antd';
import React, { useState } from 'react';
import { ChatDataWrap, ChatEarnHeading, ChatEarnWrap } from './styled';
import ArrowRight from '../../../static/earn/arrowRight.svg';
import ArrowRightHover from '../../../static/earn/ArrowRightHover.svg';
import { ChatData } from './mock';

const ChatEarn = () => {
  const { Text, Title } = Typography;
  const [hover, setHover] = useState({ state: false, cardKey: null });

  const handleHoverIn = (cardKey) => {
    setHover({ state: true, cardKey });
  };

  const handleHoverOut = (cardKey) => {
    setHover({ state: false, cardKey });
  };

  return (
    <ChatEarnWrap>
      <ChatEarnHeading>
        <Title level={4}>Chat, learn and continue to earn!</Title>
        <Text>
          Do you love Craftly? Want to share your experience with the world? Write an honest review on one of these
          platforms, send us a screenshot and start crafting!
        </Text>
      </ChatEarnHeading>
      <ChatDataWrap>
        {ChatData.map((item) => (
          <Card onMouseOver={() => handleHoverIn(item.key)} onMouseOut={() => handleHoverOut(item.key)}>
            <div className="ChatEarnCard">
              <div className="data">
                <img src={item.img} alt={item.key} />
                <div className="site">
                  <Title level={5}>{item.title}</Title>
                  <Text className="freeDays">{item.days}</Text>
                </div>
              </div>

              <div className="link">
                <Text>{item.link}</Text>
                <img src={hover.state && item.key === hover.cardKey ? ArrowRightHover : ArrowRight} alt="ArrowRight" />
              </div>
            </div>
          </Card>
        ))}
      </ChatDataWrap>
    </ChatEarnWrap>
  );
};

export default ChatEarn;

const actions = {
  PLAN_TRIAL_PROGRESS: 'PLAN_TRIAL_PROGRESS',
  PLAN_TRIAL_SUCCESS: 'PLAN_TRIAL_SUCCESS',
  PLAN_TRIAL_FAILED: 'PLAN_TRIAL_FAILED',

  PLAN_STARTER_PROGRESS: 'PLAN_STARTER_PROGRESS',
  PLAN_STARTER_SUCCESS: 'PLAN_STARTER_SUCCESS',
  PLAN_STARTER_FAILED: 'PLAN_STARTER_FAILED',

  FETCH_PLAN_TRIAL_PROGRESS: 'FETCH_PLAN_TRIAL_PROGRESS',
  FETCH_PLAN_TRIAL_SUCCESS: 'FETCH_PLAN_TRIAL_SUCCESS',
  FETCH_PLAN_TRIAL_FAILED: 'FETCH_PLAN_TRIAL_FAILED',

  FETCH_PLAN_STARTER_PROGRESS: 'FETCH_PLAN_STARTER_PROGRESS',
  FETCH_PLAN_STARTER_SUCCESS: 'FETCH_PLAN_STARTER_SUCCESS',
  FETCH_PLAN_STARTER_FAILED: 'FETCH_PLAN_STARTER_FAILED',

  USERS_WORDS_CRAFTED: 'USERS_WORDS_CRAFTED',
  ADMIN_BILLING_SETTINGS: 'ADMIN_BILLING_SETTINGS',
  USER_CRAFT_ALLOW: 'USER_CRAFT_ALLOW',
  USER_BOT_DATA: 'USER_BOT_DATA',

  planTrialProgress: () => {
    return {
      type: actions.PLAN_TRIAL_PROGRESS,
    };
  },

  planTrialSuccess: () => {
    return {
      type: actions.PLAN_TRIAL_SUCCESS,
    };
  },
  planTrialFailed: () => {
    return {
      type: actions.PLAN_TRIAL_FAILED,
    };
  },
  fetchPlanTrialProgress: () => {
    return {
      type: actions.FETCH_PLAN_TRIAL_PROGRESS,
    };
  },

  fetchPlanTrialSuccess: fetchPlanTrial => {
    return {
      type: actions.FETCH_PLAN_TRIAL_SUCCESS,
      fetchPlanTrial,
    };
  },
  fetchPlanTrialFailed: () => {
    return {
      type: actions.FETCH_PLAN_TRIAL_FAILED,
    };
  },

  planStarterProgress: () => {
    return {
      type: actions.PLAN_STARTER_PROGRESS,
    };
  },

  planStarterSuccess: () => {
    return {
      type: actions.PLAN_STARTER_SUCCESS,
    };
  },
  planStarterFailed: () => {
    return {
      type: actions.PLAN_STARTER_FAILED,
    };
  },

  fetchPlanStarterProgress: () => {
    return {
      type: actions.FETCH_PLAN_STARTER_PROGRESS,
    };
  },

  fetchPlanStarterSuccess: fetchPlanStarter => {
    return {
      type: actions.FETCH_PLAN_STARTER_SUCCESS,
      fetchPlanStarter,
    };
  },
  fetchPlanStarterFailed: () => {
    return {
      type: actions.FETCH_PLAN_STARTER_FAILED,
    };
  },
  userTodayCurrentPlanActivity: ({ totalWordsCraft, todaysTotalHits, lastCraftDate }) => {
    return {
      type: actions.USERS_WORDS_CRAFTED,
      totalWordsCraft,
      todaysTotalHits,
      lastCraftDate,
    };
  },
  userBotData: userBots => {
    return {
      type: actions.USER_BOT_DATA,
      userBots,
    };
  },
  adminBillingConfig: adminBillingSettings => {
    return {
      type: actions.ADMIN_BILLING_SETTINGS,
      adminBillingSettings,
    };
  },
  userAllowToCraft: craftAllow => {
    return {
      type: actions.USER_CRAFT_ALLOW,
      userAllowToCraft: craftAllow,
    };
  },
};

export default actions;

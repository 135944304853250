import React from 'react';
import { Card, Typography } from 'antd';
import { TrialMobileBilling } from '../styled';

const MobileCarousel = ({ text, planId, subText, handleCarousel, tabClick }) => {
  const { Text } = Typography;

  return (
    <TrialMobileBilling key={planId}>
      <Card onClick={() => handleCarousel(planId)} className={`${planId === tabClick ? 'plan-mobile-box' : ''}`}>
        <div className="price-content-m">
          <Text className="plan-tilte-m">{text}</Text>
          <span className="plan-des-m">{subText}</span>
        </div>
      </Card>
    </TrialMobileBilling>
  );
};

export default MobileCarousel;

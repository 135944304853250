import React, { useState, useEffect } from 'react';
import { Row, Col, Card, InputNumber, Form, Input, Button, Modal, Space, Switch, Select, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Main } from '../styled';
import { MinusCircleOutlined, PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { AddNewToolWrapper, CategoryBoxStyled } from './styled';
import { getFirestore } from 'redux-firestore';
import { addNotificationError, addNotificationInfo } from '../../components/utilities/notifications';
import { Cards } from '../../components/Card';
import FeatherIcon from 'feather-icons-react';
import PageTitle from '../../components/PageTitle';

const { Search, TextArea } = Input;
const { Text } = Typography;
const { confirm } = Modal;

const UpdateTool = () => {
  const [form] = Form.useForm();
  const { allTools } = useSelector((store) => store.adminFeatures);
  const [selectedTool, setSelectedTool] = useState();
  const [displayToolList, setDisplayToolList] = useState([]);
  const [displayToolData, setDisplayToolData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [allToolGroups, setAllToolGroups] = useState([]);

  const [loading, setLoading] = useState(false);

  const db = getFirestore();

  useEffect(() => {
    db.collection('tools-option-fields').onSnapshot((querySnapshot) => {
      var allGroups = [];
      querySnapshot.forEach((doc) => {
        allGroups.push({ ...doc.data() });
      });
      setAllToolGroups(allGroups);
    });
  }, []);

  const onFinish = (values) => {
    const updatedValues = { ...displayToolData, ...values };
    setLoading(true);
    updatedValues.fields.forEach(({ optional, type, toolTipText, subtitle, maxRows }, index) => {
      if (optional === undefined) {
        updatedValues.fields[index].optional = false;
      }
      if (toolTipText === undefined) {
        updatedValues.fields[index].toolTipText = null;
      }
      if (subtitle === undefined) {
        updatedValues.fields[index].subtitle = null;
      }
      if (maxRows === undefined) {
        updatedValues.fields[index].maxRows = null;
      }

      if (type.includes('generic-options')) {
        const selectedGroupName = type.split('@')[1];
        const groupFields = allToolGroups.find(({ groupName }) => groupName === selectedGroupName);
        if (groupFields && groupFields.optionFields.length > 0) {
          updatedValues.fields[index].keyOptionValues = groupFields.optionFields;
        }
        // updatedValues.fields[index].type = 'generic-options';
      }
    });
    try {
      db.collection('tools')
        .doc(updatedValues.uid)
        .set({
          ...updatedValues,
        })
        .then(() => {
          addNotificationInfo('Tool Updated');
          setLoading(false);
        });
    } catch (err) {
      addNotificationError(err.message);
      console.log(`err`, err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchValue !== '') {
      const searchedList = allTools.filter(
        ({ title }) => title && title.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setDisplayToolList(searchedList);
    } else {
      setDisplayToolList([...allTools]);
    }
  }, [allTools, searchValue]);

  const handleChange = (e, data) => {
    setSelectedTool(data.uid);
    setDisplayToolData(data);
    e.preventDefault();
    try {
      selectedTool !== undefined && e.target.closest('ul').querySelector('a.active').classList.remove('active');
    } catch {}

    e.target.classList.add('active');
  };
  const tagsColor = ['success', 'primary', 'info', 'warning', 'danger'];

  const onSearch = (value) => {
    setSearchValue(value);
  };
  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      groupId: null,
      popularOrder: null,
      popular: false,
      showInNav: false,
      displayToolName: null,
      commonIcon: null,
      beta: false,
    });
    Object.keys(displayToolData).forEach((data) => {
      form.setFieldsValue({
        [data]: displayToolData[data],
      });
    });
  }, [displayToolData, form]);

  const showDeleteConfirm = () => {
    confirm({
      title: `Are you sure delete ${displayToolData.title} tool ?`,
      icon: <ExclamationCircleOutlined />,
      content: 'All tool settings will be permanently deleted',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        db.collection('tools')
          .doc(displayToolData.uid)
          .delete()
          .then(() => {
            addNotificationInfo('Tool Deleted');
            setDisplayToolData([]);
            setSelectedTool();
          })
          .catch((error) => {
            addNotificationError(error.message);
          });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return (
    <AddNewToolWrapper>
      <PageTitle
        ghost
        title="Update Tool"
        extra={
          <Button className="btn-add_new" size="default" type="primary" key="1">
            <Link to="/add-new-tool">
              <FeatherIcon icon="plus" size={14} /> Add New Tool
            </Link>
          </Button>
        }
      />

      <Main>
        <Row gutter={15}>
          <Col md={7}>
            <Search
              allowClear
              onChange={onSearchChange}
              placeholder="Search Tool"
              onSearch={onSearch}
              style={{ width: '100%' }}
            />
            <CategoryBoxStyled>
              <Cards headless>
                <ul>
                  {displayToolList && displayToolList.length ? (
                    displayToolList.map((data, index) => {
                      const { id, title, uid } = data;
                      return (
                        <li key={index}>
                          <Link
                            className={`${selectedTool === uid && 'active'}  ${tagsColor[index]}`}
                            onClick={(e) => {
                              handleChange(e, data);
                            }}
                            to="#"
                          >
                            {title}
                          </Link>
                        </li>
                      );
                    })
                  ) : (
                    <Col md={24}>
                      <Text>Data Not Found!</Text>
                    </Col>
                  )}
                </ul>
              </Cards>
            </CategoryBoxStyled>
          </Col>

          <Col md={17}>
            <div style={{ float: 'right' }}>
              <Space>
                <Button disabled={!displayToolData.uid} loading={loading} type="primary" onClick={() => form.submit()}>
                  Submit
                </Button>
                <Button type="danger" disabled={!displayToolData.uid} onClick={showDeleteConfirm}>
                  Delete Tool{' '}
                </Button>
              </Space>
            </div>
            <br />
            <br />
            <Form
              className={'add-new-form'}
              layout="vertical"
              form={form}
              name="add-new-tool"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item name="title" label="Tool Name" rules={[{ required: true, message: 'Tool Name is required' }]}>
                <Input disabled={!displayToolData.uid} />
              </Form.Item>
              <Form.Item name="displayToolName" label="Display Tool Name (Optional)">
                <Input disabled={!displayToolData.uid} />
              </Form.Item>
              <Form.Item name="id" label="Tool ID" rules={[{ required: true, message: 'Tool ID is required' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Tool Description is required' }]}
              >
                <Input disabled={!displayToolData.uid} />
              </Form.Item>

              <Form.Item name="maxToken" label="Max Token" rules={[{ required: false }]}>
                <InputNumber disabled={!displayToolData.uid} style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item name="groupId" label="Group ID (Optional)" rules={[{ required: false }]}>
                <Input disabled={!displayToolData.uid} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="commonIcon" label="Common Icon">
                <Input style={{ width: '100%' }} />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col xs={4}>
                  <Form.Item
                    name="isVisible"
                    label="Visible to Public"
                    valuePropName={form.getFieldValue('isVisible') ? 'checked' : 'unchecked' || 'unchecked'}
                  >
                    <Switch disabled={!displayToolData.uid} />
                  </Form.Item>
                </Col>

                <Col xs={7}>
                  <Form.Item
                    name="showInNav"
                    label="Show In Navigation"
                    valuePropName={form.getFieldValue('showInNav') ? 'checked' : 'unchecked' || 'unchecked'}
                  >
                    <Switch disabled={!displayToolData.uid} />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item
                    name="beta"
                    label="Beta"
                    valuePropName={form.getFieldValue('beta') ? 'checked' : 'unchecked' || 'unchecked'}
                  >
                    <Switch disabled={!displayToolData.uid} />
                  </Form.Item>
                </Col>

                <Col xs={16}>
                  <Space>
                    <Form.Item
                      name="popular"
                      label="Show In Popular Group"
                      valuePropName={form.getFieldValue('popular') ? 'checked' : 'unchecked' || 'unchecked'}
                    >
                      <Switch disabled={!displayToolData.uid} />
                    </Form.Item>
                    <Form.Item name="popularOrder" label="Popular Order" rules={[{ required: false }]}>
                      <Input disabled={!displayToolData.uid} style={{ width: '100%' }} />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>

              <Form.List name="fields">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => {
                      return (
                        <Card
                          key={field.key}
                          size="small"
                          extra={
                            fields.length > 1 && (
                              <>
                                <MinusCircleOutlined
                                  style={{ fontSize: '16px', color: 'red', marginRight: '0.5rem' }}
                                  onClick={() => remove(field.name)}
                                />
                                Remove Field
                              </>
                            )
                          }
                        >
                          <Space key={field.key} align="baseline">
                            <Form.Item
                              {...field}
                              label="Name"
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Name is required' }]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              label="Key"
                              name={[field.name, 'key']}
                              fieldKey={[field.fieldKey, 'key']}
                              rules={[{ required: true, message: 'Key is required' }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label="Character Limit"
                              name={[field.name, 'characterLimit']}
                              fieldKey={[field.fieldKey, 'characterLimit']}
                              rules={[{ required: true, message: 'Character Limit is required' }]}
                            >
                              <InputNumber style={{ width: '200px' }} />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              label="Placeholder"
                              name={[field.name, 'placeholder']}
                              fieldKey={[field.fieldKey, 'placeholder']}
                              rules={[{ required: true, message: 'Placeholder is required' }]}
                            >
                              <TextArea rows={4} />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              label="Information Text"
                              name={[field.name, 'toolTipText']}
                              fieldKey={[field.fieldKey, 'toolTipText']}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              label="Subtitle"
                              name={[field.name, 'subtitle']}
                              fieldKey={[field.fieldKey, 'subtitle']}
                            >
                              <TextArea rows={3} />
                            </Form.Item>

                            <Form.Item
                              label="Type"
                              name={[field.name, 'type']}
                              fieldKey={[field.fieldKey, 'type']}
                              rules={[{ required: true, message: 'Type is required' }]}
                            >
                              <Select style={{ width: '400px' }}>
                                <Select.Option value="textarea">Textarea</Select.Option>
                                <Select.Option value="input">Input</Select.Option>
                                <Select.Option value="slider">Slider</Select.Option>
                                <Select.Option value="radio">Toggle</Select.Option>
                                <Select.Option value="engine-select">Engine's Dropdown</Select.Option>
                                <Select.Option value="number-of-output">Number of Output's Dropdown</Select.Option>
                                <Select.Option value="model-radio-button">Radio Buttons</Select.Option>
                                {allToolGroups &&
                                  allToolGroups.length > 0 &&
                                  allToolGroups
                                    .filter(({ type }) => type === 'tool-group-fields')
                                    .map(({ groupName }) => {
                                      return (
                                        <Select.Option value={`generic-options@${groupName}`}>
                                          {groupName}
                                        </Select.Option>
                                      );
                                    })}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, curValues) => {
                                const changeDeduct =
                                  fields &&
                                  prevValues.fields &&
                                  curValues.fields &&
                                  Array.isArray(prevValues.fields) &&
                                  Array.isArray(curValues.fields) &&
                                  prevValues.fields[index] &&
                                  curValues.fields[index] &&
                                  prevValues.fields[index].type !== curValues.fields[index].type;

                                return changeDeduct;
                              }}
                            >
                              {({ getFieldValue }) => {
                                return (
                                  <>
                                    {getFieldValue(['fields', field.name, 'type']) === 'textarea' ? (
                                      <Form.Item
                                        {...field}
                                        label="Max Rows"
                                        name={[field.name, 'maxRows']}
                                        fieldKey={[field.fieldKey, 'maxRows']}
                                      >
                                        <InputNumber
                                          defaultValue={
                                            getFieldValue(['fields', field.name, 'maxRows'])
                                              ? getFieldValue(['fields', field.name, 'maxRows'])
                                              : 7 || 7
                                          }
                                          style={{ width: '200px' }}
                                          placeholder="Max Rows"
                                        />
                                      </Form.Item>
                                    ) : null}
                                  </>
                                );
                              }}
                            </Form.Item>

                            <Form.Item
                              label="Field Optional"
                              name={[field.name, 'optional']}
                              fieldKey={[field.fieldKey, 'optional']}
                              valuePropName={
                                form.getFieldValue(fields[index].optional) ? 'checked' : 'unchecked' || 'unchecked'
                              }
                            >
                              <Switch />
                            </Form.Item>
                          </Space>
                        </Card>
                      );
                    })}
                    <Form.Item>
                      <Button
                        disabled={!displayToolData.uid}
                        type="dashed"
                        onClick={() =>
                          add({
                            optional: false,
                            toolTipText: null,
                            subtitle: null,
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Fields
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item>
                <Button disabled={!displayToolData.uid} loading={loading} type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Main>
    </AddNewToolWrapper>
  );
};

export default UpdateTool;

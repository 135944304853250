/* ------------------------------------------------------ */
/*                    HOME -  MAIN PAGE                   */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * This is the main page of the app.
 * All Categories and Tool cards are displayed here.
 *
 * Projects and workspace are load here.
 *
 * when a category is selected the page url change. /category/category-name
 */

import React, { useState, useEffect } from 'react';
import CategoryBanner from './overview/CategoryBanner';
import CategoryCard from './overview/CategoryCard';
import CategoryBreadcrumb from './overview/CategoryBreadcrumb';
import { CategoryConatiner } from './styled';
import ToolsLibrary from './overview/ToolsLibrary';
import { useSelector, useDispatch } from 'react-redux';
import { ALL_TOOLS, TOOL_CATEGORIES } from '../../constants';
import { getCategoryTools } from '../../utility/utility';
import { displayCategoryList } from '../../utility/toolsList';
import { Helmet } from 'react-helmet';
import { PAGETILE } from '../../constants/content';
import { userProjectDataRead } from '../../redux/UserProject/actionCreator';
import { saveCategoryId } from '../../redux/AdminFeatures/actionCreator';
import { Typography } from 'antd';
import SuggestedTools from './overview/SuggestedTools';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const { Text } = Typography;
const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { categoryId } = useParams();
  const location = useLocation();

  const [categoryTools, setCategoryTools] = useState([]);
  const [displayCategories, setDisplayCategories] = useState(displayCategoryList);
  const [initialpageLoad, setInitialpageLoad] = useState(false);
  const { allTools, allGroupFields, searchToolIds, selectedCategoryId } = useSelector((store) => store.adminFeatures);
  const { visitTeamPage, joinAsTeamMember } = useSelector((state) => state.fb.profile);

  useEffect(() => {
    // fetch  the users project details
    dispatch(userProjectDataRead());

    // if the team member is joined via team invite then it redirec to team port page as of the inital page but this happens only first time
    visitTeamPage === false && joinAsTeamMember === true && navigate('/team-portal');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (categoryId) {
      // it is used to save the category as it is used in multitple places
      dispatch(saveCategoryId({ selectedCategoryId: categoryId }));
    }
    setInitialpageLoad(true);
  }, []);

  // all this logic is to display the initial display of category list
  useEffect(() => {
    const { pathname } = location;
    if ((pathname === '/category' || pathname === '/') && selectedCategoryId !== null && initialpageLoad) {
      pageReset();
    }
  }, [location, selectedCategoryId, initialpageLoad]);

  // Page reset means to reset every user choose the option such as category selection or search
  const pageReset = () => {
    dispatch(saveCategoryId({ selectedCategoryId: null }));
    setCategoryTools([]);
    setInitialpageLoad(false);
    navigate('/category');
  };

  // this display the list of categories
  useEffect(() => {
    const categories =
      allGroupFields &&
      allGroupFields.filter(({ type }) => type === TOOL_CATEGORIES).sort((a, b) => a.categoryOrder - b.categoryOrder);
    categories &&
      categories.length > 1 &&
      setDisplayCategories([
        { categoryName: 'All Tools', categoryId: ALL_TOOLS, icon: ALL_TOOLS, categoryOrder: 1 },
        ...categories,
      ]);
  }, [allGroupFields]);

  useEffect(() => {
    if (selectedCategoryId) {
      if (selectedCategoryId === ALL_TOOLS) {
        setCategoryTools(allTools);
        setInitialpageLoad(true);
      } else {
        const displayCategoryTools = getCategoryTools({
          displayCategories,
          selectedCategory: selectedCategoryId,
          allToolsList: allTools,
        });
        setInitialpageLoad(true);
        setCategoryTools(displayCategoryTools);
      }
      navigate(`/category/${selectedCategoryId}`);
    }
  }, [allTools, displayCategories, selectedCategoryId, displayCategories.optionFields]);

  useEffect(() => {
    const displayCategoryTools =
      searchToolIds &&
      Array.isArray(searchToolIds) &&
      searchToolIds.map((toolId) => {
        return allTools.find(({ id }) => id === toolId.id);
      });
    setInitialpageLoad(true);
    setCategoryTools(displayCategoryTools);
  }, [allTools, searchToolIds]);

  const showTools = selectedCategoryId && categoryTools && Array.isArray(categoryTools) && categoryTools.length > 0;

  const selectedCategoryName =
    selectedCategoryId && displayCategories.find(({ categoryId }) => categoryId === selectedCategoryId)?.categoryName;

  const onCategoryClick = (categoryId) => {
    setInitialpageLoad(false);
    dispatch(saveCategoryId({ selectedCategoryId: categoryId }));
  };
  return (
    <>
      <Helmet>
        <title>{PAGETILE('Dashboard')} </title>
      </Helmet>

      <CategoryConatiner>
        <CategoryBanner categoryTools={allTools} />

        {/* list of categories in a slider */}
        <SuggestedTools />

        {/* it only displays when the user select any category */}
        {selectedCategoryId && (
          <CategoryBreadcrumb
            displayCategories={displayCategories}
            onCategoryClick={onCategoryClick}
            selectedCategory={selectedCategoryId}
            selectedCategoryName={selectedCategoryName}
            goToCategoryPage={() => {
              pageReset();
            }}
          />
        )}
        {!selectedCategoryId && (
          <div className="explore-section">
            <Text level={5} className="explore-text">
              Explore
            </Text>
          </div>
        )}

        {/* once the user selects the category all the categories tools will be displayed */}
        {showTools ? (
          <ToolsLibrary
            categoryTools={categoryTools}
            goBack={() => {
              pageReset();
            }}
          />
        ) : (
          <CategoryCard categories={displayCategories} selectedCategory={onCategoryClick} />
        )}
      </CategoryConatiner>
    </>
  );
};

export default Home;

/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import React from 'react';
import Invite from './Invite';
import MemberSearchBar from './MemberSearchBar';
import MemberTable from './MemberTable';
import { TeamsWrapped } from './styled';

const TeamComponent = () => {
  return (
    <TeamsWrapped>
      <Invite />
      <MemberSearchBar />
      <MemberTable />
    </TeamsWrapped>
  );
};

export default TeamComponent;

import styled from "styled-components";

const TeamsContainer = styled.div`

`;
const TeamsHeader = styled.div`
 border-bottom: 1px solid #EEEEEE;
 padding: 19px 24px;

 .ant-typography {
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 28px !important;
    font-family: 'Poppins-Semibold';
    color: ##282B3F !important;
    padding-bottom: 0px;
    @media (max-width: 768px){
      font-size: 24px !important;
    }
  }
  }

  @media (max-width: 767px){
    padding-top: 0px;
  .welcome{
    font-size: 18px;
    font-family: 'Poppins-Regular';
  }
.ant-typography{
  display: flex;
  flex-direction: column;
}

  }

`;

export {TeamsContainer, TeamsHeader}
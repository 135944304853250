import React from 'react';
import { AuthRightSide } from '../auth/Components/AuthRightSide/AuthRightSide';
import { Home } from '../auth/Home/Home';
import { Outlet } from 'react-router-dom';
import AppFooter from '../../../components/AppFooter';

const AuthLayout = () => {
  const withOutAuthLinks = ['/craftly', '/craftly/', 'craftly'];
  const check = withOutAuthLinks.includes(window.location.pathname);

  return (
    <div>
      {!check && (
        <div>
          <div>
            <Home />
            <AppFooter />
          </div>
          <AuthRightSide>
            <Outlet />
          </AuthRightSide>
        </div>
      )}

      {check && <Outlet />}
    </div>
  );
};

export default AuthLayout;

/* eslint-disable jsx-a11y/control-has-associated-label */

/* ------------------------------------------------------ */
/*                         EDITOR                         */
/* ------------------------------------------------------ */

/**
 * Tinymce is used as code editor tinymce/tinymce-react
 *
 * From the Document UI page it redirect to this editor
 *
 * blogPostId is get from the url
 * blogPostId is used to fetch the document from firestore
 *
 * it is under development
 *
 *
 */

import React, { useRef, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { Main } from '../styled';
import { Editor } from '@tinymce/tinymce-react';
import { useSelector } from 'react-redux';
import { getFirestore } from 'redux-firestore';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const { Title } = Typography;

const TextEditor = ({ initialValue }) => {
  const editorRef = useRef(null);
  const useDarkMode = false;
  const location = useLocation();
  const { blogPostId } = queryString.parse(location.search);

  const { uid } = useSelector((state) => state.fb.auth);
  const [populateData, setPopulateData] = useState('');

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  useEffect(() => {
    const db = getFirestore();
    if ((uid, blogPostId)) {
      db.collection('long-form-documents')
        .doc(uid)
        .collection('documents')
        .doc(blogPostId)
        .onSnapshot((doc) => {
          const { editor } = doc.data() || { editor: null };
          setPopulateData(editor);
        });
    }
  }, [uid, blogPostId]);

  const onSave = () => {};

  return (
    <Main>
      <Title level={4} style={{ marginTop: '3rem' }}>
        Editor{' '}
      </Title>
      <Editor
        apiKey="ljxb9kxlr5fs3ztxj70owdr0yjvts9lo4h899g26pr42cidp"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={populateData}
        init={{
          selector: 'textarea',
          // plugins: 'tinymcespellchecker  print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
            'codesample',
            'autoresize',
          ],
          // menubar: 'file edit view insert format tools table help',
          // toolbar:
          //   'undo spellchecker language spellcheckdialog redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
          menubar: false,
          toolbar:
            'fullscreen  preview save print | undo redo cut copy  selectall | searchreplace  | blocks | codesample |' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',

          toolbar_sticky: true,
          // image_caption: true,
          // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
          // noneditable_noneditable_class: 'mceNonEditable',
          // toolbar_mode: 'sliding',
          // contextmenu: 'link image imagetools table',
          skin: useDarkMode ? 'oxide-dark' : 'oxide',
          content_css: useDarkMode ? 'dark' : 'default',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',

          // spellchecker_language: 'en',
          // content_langs: [
          //   { title: 'English (US)', code: 'en_US' },
          //   { title: 'English (US Medical)', code: 'en_US', customCode: 'en_US-medical' },
          //   { title: 'English (UK)', code: 'en_UK' },
          //   { title: 'English (UK Medical)', code: 'en_UK', customCode: 'en_UK-medical' },
          //   { title: 'Spanish', code: 'es' },
          //   { title: 'French', code: 'fr' },
          //   { title: 'German', code: 'de' },
          //   { title: 'Portuguese', code: 'pt' },
          //   { title: 'Chinese', code: 'zh' },
          // ],

          // menubar: false,
          // plugins: [
          //   'advlist',
          //   'autolink',
          //   'lists',
          //   'link',
          //   'image',
          //   'charmap',
          //   'anchor',
          //   'searchreplace',
          //   'visualblocks',
          //   'code',
          //   'fullscreen',
          //   'insertdatetime',
          //   'media',
          //   'table',
          //   'preview',
          //   'help',
          //   'wordcount',
          // ],
          // toolbar:
          //   'undo redo | blocks | ' +
          //   'bold italic forecolor | alignleft aligncenter ' +
          //   'alignright alignjustify | bullist numlist outdent indent | ' +
          //   'removeformat | help',
        }}
      />
      <br />
      {/* <div className="save-btn">
        <Button type="primary" onClick={onSave}>
          Save
        </Button>
      </div>
      <br />
      <br />
      <br />
      <button onClick={log}>for Talal use</button> */}
    </Main>
  );
};

export default TextEditor;

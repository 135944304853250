/* ------------------------------------------------------ */
/*                      Tool Library                      */
/* ------------------------------------------------------ */

/**
 * Methodology
 * According to selected category tool-list will display
 */

import { Card, Col, Row } from 'antd';
import React from 'react';
import { DashboardToolsWrap, DashboardcardStyle } from '../styled';
import ToolLibraryCard from '../../../components/ToolLibraryCard';
import arrowButton from '../../../static/tool-library/arrow-button.svg';

/**
 * @categoryTools selected tool-list which is linked to the selected category-id
 * @goBack go back to the main page
 */
const ToolsLibrary = ({ categoryTools, goBack }) => {
  return (
    <DashboardToolsWrap>
      <Row
        gutter={[
          { xs: 16, sm: 16, md: 24, lg: 24 },
          { xs: 16, sm: 16, md: 24, lg: 24 },
        ]}
        style={{ overflow: 'hidden' }}
      >
        <Col xs={{ span: 12 }} sm={{ span: 8 }} md={{ span: 6 }}>
          <DashboardcardStyle>
            <Card className="goback">
              <div className="top">
                <div className="link">
                  <div className="icon-box box-secondary">
                    <h1>Go Back</h1>
                  </div>
                </div>
                <div className="title" onClick={goBack}>
                  <img src={arrowButton} alt="arrowimage" />
                </div>
              </div>
            </Card>
          </DashboardcardStyle>
        </Col>
        {categoryTools &&
          Array.isArray(categoryTools) &&
          categoryTools.length > 0 &&
          categoryTools.map(({ commonIcon, title, beta, popular, newTool, description, id }, i) => (
            <Col xs={12} sm={8} md={6}>
              <ToolLibraryCard
                new={newTool}
                popular={popular}
                title={title}
                commonIcon={commonIcon}
                description={description}
                id={id}
                setCardClick={goBack}
                beta={beta}
              />
            </Col>
          ))}
      </Row>
    </DashboardToolsWrap>
  );
};

export default ToolsLibrary;

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import ResetPassword from './ResetPassword';
import Invite from '../../../Invite';
import { firebase } from '../../../../config/firebase';
import { useLocation } from 'react-router-dom';

const HandleAuth = () => {
  const [showUI, SetShowUI] = useState(false);
  const [showResetError, setShowResetError] = useState(false);
  const RESET_PASSWORD = 'resetPassword';
  const RECOVER_EMAIL = 'recoverEmail';
  const VERIFY_EMAIL = 'verifyEmail';
  const SIGN_IN = 'signIn';
  const location = useLocation();

  // Get the one-time code from the query parameter.
  const { oobCode } = queryString.parse(location.search);

  useEffect(() => {
    let { mode } = queryString.parse(location.search);

    switch (mode) {
      case RESET_PASSWORD:
        // Display reset password handler and UI.
        // Verify the password reset code is valid.
        firebase
          .auth()
          .verifyPasswordResetCode(oobCode)
          .then((email) => {
            // var accountEmail = email;
            SetShowUI(RESET_PASSWORD);
          })
          .catch((error) => {
            SetShowUI(RESET_PASSWORD);
            setShowResetError(error.message);
            console.log(`error`, error);
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
          });

        break;
      case RECOVER_EMAIL:
        // will do later
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, lang);
        break;
      case SIGN_IN:
        SetShowUI(SIGN_IN);
        break;
      case VERIFY_EMAIL:
        // will do later
        // Display email verification handler and UI.
        // handleVerifyEmail(auth, actionCode, continueUrl, lang);
        break;
      default:
      // Error: invalid mode.
    }
  }, [location.search, oobCode]);

  return (
    <>
      {showUI === RESET_PASSWORD && <ResetPassword showResetError={showResetError} actionCode={oobCode} />}
      {showUI === SIGN_IN && <Invite />}
    </>
  );
};

export default HandleAuth;

import styled from 'styled-components';

const InfoStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px 0;
  height: 100%;
  .link-style {
    text-decoration: none;
    color: ${({ theme }) => theme['text-color-secondary']};
    box-shadow: none;
    padding: 0px 8px;
  }
  .message .ant-badge-dot {
    background: green;
  }
  .ant-badge {
    .ant-badge-dot {
      ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 50% !important;
    }
  }

  .message,
  .notification,
  .settings,
  .support,
  .nav-author {
    display: flex;
    span,
    a {
      display: block;
      line-height: normal;
      margin: 0 3px;
    }
  }
  .nav-author {
    a.ant-dropdown-trigger {
      img {
        max-width: 20px;
      }
    }
  }
`;

const AvatarPopoverStyled = styled.div`
  .user-dropdwon {
    max-width: 280px;
    .user-dropdwon__info {
      display: flex;
      align-items: flex-start;
      padding: 20px 25px;
      border-radius: 8px;
      margin-bottom: 12px;
      background: ${({ theme }) => theme['bg-color-normal']};
      img {
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
      }
      figcaption {
        h1 {
          font-size: 14px;
          margin-bottom: 2px;
          color: ${({ theme }) => theme['dark-color']};
        }
        p {
          margin-bottom: 0px;
          font-size: 13px;
          color: ${({ theme }) => theme['gray-solid']};
        }
      }
    }
    .user-dropdwon__links {
      a {
        width: calc(100% + 30px);
        left: -15px;
        right: -15px;
        display: inline-flex;
        align-items: center;
        padding: 10px 12px;
        font-size: 14px;
        transition: 0.3s;
        color: ${({ theme }) => theme['light-color']};
        &:hover {
          background: ${({ theme }) => theme['primary-color']}05;
          color: ${({ theme }) => theme['primary-color']};
          ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 22px;
        }
        svg {
          width: 16px;
          transform: ${({ theme }) => (theme.rtl ? 'rotateY(180deg)' : 'rotateY(0deg)')};
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 14px;
        }
      }
    }
    .user-dropdwon__bottomAction {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      font-weight: 500;
      text-align: center;
      position: relative;
      width: calc(100% + 30px);
      left: -15px;
      right: -15px;
      height: calc(100% + 15px);
      bottom: -15px;
      border-radius: 0 0 6px 6px;
      padding: 15px 0;
      background: ${({ theme }) => theme['bg-color-light']};
      color: ${({ theme }) => theme['light-light']};
      svg {
        width: 15px;
        height: 15px;
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
      }
    }
  }
`;

export { InfoStyled, AvatarPopoverStyled };

/* ------------------------------------------------------ */
/*                     Authentication                     */
/* ------------------------------------------------------ */

import Cookies from 'js-cookie';
import actions from './actions';

const { loginBegin, loginSuccess, loginErr, logoutBegin, logoutSuccess, logoutErr, animationBegin } = actions;

const login = () => {
  return async (dispatch) => {
    try {
      dispatch(loginBegin());
      Cookies.set('logedIn', true);
      return dispatch(loginSuccess(true));
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = () => {
  return async (dispatch) => {
    try {
      dispatch(logoutBegin());
      Cookies.remove('logedIn');
      dispatch(logoutSuccess(null));
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const animation = () => {
  return async (dispatch) => {
    try {
      dispatch(animationBegin());
    } catch (err) {}
  };
};

export { login, logOut, animation };

/* ------------------------------------------------------ */
/*                        Password                        */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * By the help of providerId in firebase object we can determine the account type
 * if provider type id 'password' or 'google'
 *
 * There are 2 types of account authentication
 * 1. Email/Password
 * 2. Google
 *
 * If the user is signed with Eamil/Password a user must provide old password and new password to change the password
 * Firebase Authetication will change the password.
 * It also verify the old password before changing to new password
 *
 *
 * if the user is signed with Google then user can set a new password. user cant change the password.
 * Setting a new password will help the user to signin with both ways either login-with-google or email/password
 * Reauthenticate with popup: google reauthentication
 */

import { Row, Col, Form, Input, Typography, Space } from 'antd';
import React from 'react';
import { Button } from '../../../components/Button';
import { SettingsWrapStyled, GoogleMessage, SettingsPasswordStyled } from './styled';
import GoogleIcon from '../../../static/settings/google.svg';

import { useDispatch, useSelector } from 'react-redux';
import { changePassword } from '../../../redux/AccountSettings/actionCreator';
import { PASSWORD_PROVIDER } from '../../../constants';

const { Text, Title, Paragraph } = Typography;

const Password = () => {
  const [form] = Form.useForm();
  const { providerData } = useSelector((state) => state.fb.auth);
  const dispatch = useDispatch();

  const accountCredentialType = providerData && providerData.length > 0 && providerData[0].providerId;

  const handleSubmit = ({ oldPassword, newPassword }) => {
    if (accountCredentialType) {
      // Firebase Change Password
      dispatch(
        changePassword({
          credentails: {
            provider: accountCredentialType,
          },
          newPassword,
          oldPassword,
        }),
      );
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    form.resetFields();
  };

  const hasPassword = accountCredentialType === PASSWORD_PROVIDER ? true : false;
  const PasswordLabel = providerData.find(({ providerId }) => providerId === 'password');

  return (
    <>
      <SettingsWrapStyled>
        <Title>Password</Title>
        <Text className="profile">Reset your password</Text>
        <Row justify="start">
          <Col xs={24} sm={20} md={20} lg={17}>
            <SettingsPasswordStyled>
              {!PasswordLabel && (
                <GoogleMessage>
                  <div className="google-wrapper">
                    <img className="google-icon" src={GoogleIcon} alt="mail-Icon" />
                    Log in with Google enabled
                  </div>
                  <div className="notice-wrapper">
                    <div className="notice">
                      <div className="notice-text">
                        <Paragraph>
                          <Text>By setting a password you can also login using Email/Password method</Text>
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                </GoogleMessage>
              )}
              <Form layout="vertical" onFinish={handleSubmit}>
                {hasPassword && (
                  <Form.Item
                    rules={[{ required: true, message: 'Enter old password' }]}
                    name="oldPassword"
                    label="Old Password"
                  >
                    <Input.Password />
                  </Form.Item>
                )}

                <Form.Item
                  name="newPassword"
                  rules={[
                    { required: true, message: 'Enter new password' },
                    {
                      min: 6,
                      message: 'Enter a valid password. Min 6 characters long.',
                    },
                  ]}
                  label="New Password"
                >
                  <Input.Password />
                </Form.Item>
                <Text>Minimum 6 characters.</Text>

                <Form.Item className="password-btn">
                  <Space>
                    <Button size="large" htmlType="submit" type="primary">
                      {PasswordLabel ? 'Change Password' : 'Set New Password'}
                    </Button>
                    <Button size="large" onClick={handleCancel} type="light">
                      Cancel
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </SettingsPasswordStyled>
          </Col>
        </Row>
      </SettingsWrapStyled>
    </>
  );
};

export default Password;

/* ------------------------------------------------------ */
/*                     Warning Popups                     */
/* ------------------------------------------------------ */

/**
 * There are 2 consumptions of warning popup modal.
 * 1. Edit Functionality
 * 2. Delete - shows the warning to verify are you sure to delete the page
 *
 *
 * 1. Two types of Edit
 *    a.  Go back to this step & lose all progress after
 *    b.  Edit only this section without starting over
 *
 */

import React from 'react';
import { Typography } from 'antd';
import { LongFormWarningStyled, WarningModalStyled } from '../styled';

const { Text } = Typography;

/**
 * @warningVisible display and hide the modal
 * @handleVisibleChange func to handle the visibility of modal
 * @onEditInput there are 2 type of edit 'all' & 'current'
 * @type either steps & summary
 * @deleteDoc func delete functionality
 *
 */
const WarningPopUp = ({ warningVisible, handleVisibleChange, onEditInput, type, deleteDoc }) => {
  return (
    <LongFormWarningStyled>
      <WarningModalStyled
        title={'WARNING!'}
        open={warningVisible}
        onOk={() => handleVisibleChange(false)}
        onCancel={() => handleVisibleChange(false)}
        closable
        footer={null}
      >
        <>
          {/* Edit functionality */}
          {type === 'steps' && (
            <>
              <Text className="first-text">
                The tool is built in sequence, so by editing a previous section it will change the results of the
                sections after
              </Text>
              <div className="second-text">
                Please choose if you want to start over or edit only that part from below:
              </div>
              <div className="warning-action-buttons">
                <div className="edit-go-back edit-button" onClick={() => onEditInput('all')}>
                  Go back to this step & lose all progress after
                </div>
                <div className="edit-current  edit-button" onClick={() => onEditInput('current')}>
                  Edit only this section without starting over{' '}
                </div>
              </div>
            </>
          )}
          {/* Summary */}
          {type === 'summary' && (
            <>
              <div className="first-text">Are you sure you’d like to start over?</div>
              <div className="second-text">If you start over, your blog post will be deleted permanently </div>
              <div className="warning-action-buttons">
                <div className="edit-go-back edit-button" onClick={deleteDoc}>
                  Delete
                </div>
              </div>
            </>
          )}
        </>
      </WarningModalStyled>
    </LongFormWarningStyled>
  );
};

export default WarningPopUp;

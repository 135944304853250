/* ------------------------------------------------------ */
/*                   TEAM MEMBER ADD                     */
/* ------------------------------------------------------ */

/**
 * Team Admin can add team member.
 * Firestore
 * Collection: team-members
 * Document: <UID>
 * data: admin email
 *       admin name
 *       admin UID
 *       members - array
 *       membersUID
 *       seatsCountAtTeamCreation
 *       teamName
 *       teamProjects - array
 *       teamStatus
 *
 * members:
 *    [{
 *      teamMemberName: name,
        teamMemberEmail: email,
        role:  admin or member
        lastActive: 'NA',
        teamInviteStatus: hold or active
        teamMemberUID: uid,
        teamMemberRef: db.doc('users/' + uid), 
      }]
 * It also check whether the eixsitng team member is present or not
 * Team admin can only add new team members if the there is space on the team members list.
 *
 *
 *
 */

import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/Button';
import { UserAddOutlined } from '@ant-design/icons';
import { checkTeamMemberAlreadyInTeam } from '../../../redux/AccountSettings/actionCreator';
import { TEAM_STATUS } from '../../../constants';

const AddMember = ({ openSeatModal, teamDeletedSuccessfully, viewResults }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { activatedPlanDetails } = useSelector((state) => state.fb.profile);
  const { addTeamMemberLoading, addTeamMemberSuccess, addTeamMemberFailed, teamMembersList } = useSelector(
    (state) => state.accountSettings,
  );

  const { quantity } = activatedPlanDetails || { quantity: 0 };
  const teamQuantity = quantity;

  useEffect(() => {
    if (!addTeamMemberLoading && addTeamMemberSuccess && !addTeamMemberFailed) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTeamMemberLoading, addTeamMemberSuccess, addTeamMemberFailed]);

  const checkActiveAccount =
    teamMembersList &&
    teamMembersList.filter(
      ({ teamInviteStatus }) =>
        teamInviteStatus === TEAM_STATUS.PENDING ||
        teamInviteStatus === TEAM_STATUS.ACTIVE ||
        teamInviteStatus === TEAM_STATUS.ACCEPTED,
    ).length;

  const handleSubmit = (values) => {
    // add team member, first check whether the team member is present or not
    dispatch(checkTeamMemberAlreadyInTeam({ email: values.teamMemberEmail }));
  };

  // Notification
  // const showQuantityText = () => {
  //   const checkQuantity = teamMembersList.length - teamQuantity;

  //   if (teamQuantity === teamMembersList.length) {
  //     return [
  //       { notice: 'You have consumed your all available seats.', noticeType: 'default' },
  //       { notice: 'To add more members, add more seats', noticeType: 'danger' },
  //     ];
  //   } else if (checkActiveAccount < teamQuantity && checkQuantity > -1) {
  //     return [
  //       { notice: 'Your seats quantity is less than the required members quantity.', noticeType: 'default' },
  //       {
  //         notice: `All members are in HOLD state.`,
  //         noticeType: 'danger',
  //       },
  //       {
  //         notice: `You need to remove any ${
  //           teamMembersList.length - teamQuantity
  //         } member(s) inorder to remove the hold`,
  //         noticeType: 'danger',
  //       },
  //     ];
  //   } else if (teamQuantity - teamMembersList.length > 0) {
  //     return [
  //       {
  //         notice: `You can add ${teamQuantity - teamMembersList.length} new team member(s)`,
  //         noticeType: 'default',
  //       },
  //     ];
  //   } else {
  //     return [];
  //   }
  // };

  return (
    <div className="invite-team-layout">
      <Form form={form} layout="inline" name="add-invite" onFinish={handleSubmit} style={{ width: '100%' }}>
        <Form.Item
          style={{ flexDirection: 'column', width: '73%' }}
          validateTrigger={['onBlur']}
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid E-mail!',
            },
            {
              required: true,
              message: 'Please enter your E-mail!',
            },
          ]}
          type="email"
          name="teamMemberEmail"
        >
          <Input size="default" className="add-member-email" placeholder="email@address.com" />
        </Form.Item>

        <Form.Item>
          <Button
            className="add-button"
            icon={<UserAddOutlined />}
            size="default"
            loading={addTeamMemberLoading}
            htmlType="submit"
            type="primary"
            disabled={teamQuantity < teamMembersList.length || teamQuantity === teamMembersList.length ? true : false}
          >
            Add New Member
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddMember;

/* ------------------------------------------------------ */
/*                  Ant-Design Component                  */
/* ------------------------------------------------------ */
// We have created our own customized Breadcrumb styles

/*
Methadology
it is used to display Breadcrumb with dynamic links

*/

/* Used In
  src/container/CaiChat
  src/container/CaiRephrase/CaiRephrase.jsx
  src/container/ToolDesign/overview/ToolDesignLeft.js
*/

import React from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbStyled } from './styled';

/**
 @string firstLink: first link, path to redirect
 @string firstLinkTitle: first link text
 @string secondLinkTitle: current page text
*/
const BreadcrumbCai = ({ firstLink, firstLinkTitle, secondLinkTitle }) => {
  return (
    <BreadcrumbStyled
      separator=">"
      items={[
        {
          title: <Link to={firstLink}>{firstLinkTitle}</Link>,
          className: 'breadcrumb-link-item',
        },
        {
          title: secondLinkTitle,
          className: 'breadcrumb-link-item',
        },
      ]}
    />
  );
};

export default BreadcrumbCai;

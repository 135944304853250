/* eslint-disable jsx-a11y/control-has-associated-label */

/* ------------------------------------------------------ */
/*                       ERORR BOARD                      */
/* ------------------------------------------------------ */

/**
 *
 * Firebase
 * Collection: mail https://console.firebase.google.com/u/1/project/craftly-d50ba/firestore/data/~2Fmail
 *
 * if any tool response doesnt able to generate any output to goes to mail collection from where it aslo sends the email to the developers.
 *
 * According to the date picker selection, the error logs will be displayed once all the data from the firestore has been collected.
 * Only the dates in the datepicker that have an error log are active.
 * The rest of the dates are disable.
 */

import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Empty, DatePicker, Tag, Descriptions, Collapse } from 'antd';
import moment from 'moment';
import { allSubscribers, convertFirebaseToDate, dateFormat } from '../../utility/utility';
import { Main } from '../styled';
import { getFirestore } from 'redux-firestore';
import dayjs from 'dayjs';

const { Text, Link, Title } = Typography;
const { Panel } = Collapse;

const ErrorBoard = () => {
  const [errorData, setErrorData] = useState([]);
  const [errorDates, setErrorDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDataToDisplay, setSelectedDataToDisplay] = useState([]);

  const db = getFirestore();

  // this function execute when the page refresh only or open 1st time.
  useEffect(() => {
    // mail collection fetch all the error bots
    const mailSubscriber = db.collection('mail').onSnapshot((querySnapshot) => {
      const allData = [];
      querySnapshot.forEach((doc) => {
        allData.push({ ...doc.data(), docId: doc.id });
      });
      const logDates = [...new Set(allData.map((item) => item.time && convertFirebaseToDate(item.time)))];
      setErrorDates(logDates);
      setErrorData(allData);
    });
    allSubscribers.findIndex((x) => x.name === 'mailSubscriber') === -1 &&
      allSubscribers.push({ func: mailSubscriber, name: 'mailSubscriber' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // when a date is changed, it filter out the error logs on date filter.
  useEffect(() => {
    if (selectedDate) {
      const selectedDateData = errorData.filter(
        ({ time, type }) => convertFirebaseToDate(time) === selectedDate && type !== 'invite',
      );
      setSelectedDataToDisplay(selectedDateData);
    }
  }, [errorData, selectedDate]);

  useEffect(() => {
    const todayDate = moment().format('YYYY-MM-DD');
    setSelectedDate(todayDate);
  }, [errorData]);

  const disabledDate = (current) => {
    const currentDate = current.format(dateFormat);
    return errorDates && !errorDates.includes(currentDate);
  };

  const onDateChange = (date, dateString) => {
    setSelectedDate(dateString);
  };

  const currentDate = dayjs().format(dateFormat);
  return (
    <>
      <Main>
        <Title level={2} style={{ marginTop: '3rem' }}>
          Error Board
        </Title>

        {/* only those dates are accessible which have data present, the rest of the dates are inactive. */}
        <DatePicker
          defaultValue={dayjs(currentDate, dateFormat)}
          format={dateFormat}
          style={{ marginBottom: '1rem' }}
          disabledDate={disabledDate}
          onChange={onDateChange}
        />
        <Row>
          <Col xs={24}>
            <Collapse defaultActiveKey={['0']}>
              {/* This selected data is crosspond to the selected date   */}
              {selectedDataToDisplay.map(({ type, message, status, user, url, to, mode }, index) => {
                const { subject, html } = message;
                const highlightSubject = subject.includes('CRASHED');
                return (
                  <Panel header={<Text type={highlightSubject ? 'danger' : 'secondary'}>{subject}</Text>} key={index}>
                    <Descriptions title="" bordered column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 1, xs: 1 }}>
                      <Descriptions.Item label="Email">{user}</Descriptions.Item>
                      <Descriptions.Item label="URL">
                        <Link href={url}>{url} </Link>{' '}
                      </Descriptions.Item>
                      <Descriptions.Item label="Type">{type}</Descriptions.Item>
                      <Descriptions.Item label="Mode">
                        <Text type="danger"> {mode} </Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Mail Status">
                        <Text type="success"> {status} </Text>
                      </Descriptions.Item>
                      <Descriptions.Item label="Informed to">
                        {to &&
                          Array.isArray(to) &&
                          to.map((email) => {
                            return <Tag color="green">{email}</Tag>;
                          })}
                      </Descriptions.Item>
                      <Descriptions.Item label="Mail">
                        {<div className="content" dangerouslySetInnerHTML={{ __html: html }}></div>}
                      </Descriptions.Item>
                    </Descriptions>
                  </Panel>
                );
              })}

              {selectedDate && selectedDataToDisplay.length === 0 && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                  <Empty description={'No Data'} />
                </div>
              )}
            </Collapse>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default ErrorBoard;

/* ------------------------------------------------------ */
/*                     Category Banner                    */
/* ------------------------------------------------------ */

/**
 * Methadology
 * The main page banner have the search input field.
 * This search apply on all the tool list
 *
 * The search result display the tool-list in the section below.
 * There will be no dropdown in the search
 *
 */

import React from 'react';
import { Typography, Row } from 'antd';
import { CatBanner } from '../styled';
import HeaderSearchTools from '../../../components/HeaderSearchTools';

const { Title, Text } = Typography;

const CategoryBanner = ({ categoryTools }) => {
  return (
    <CatBanner>
      <div className="catergory-banner">
        <Row>
          <Title>Hello human,</Title>
          <Text>what are we writing?</Text>
        </Row>

        <div className="search-tool-area">
          {/* This search is implemented on all the tool list  */}
          <HeaderSearchTools
            categoryToolList={categoryTools}
            showSearchOptionsInDropdown={false}
            placeholder="Try article, press release, anything!"
          />
        </div>
      </div>
    </CatBanner>
  );
};

export default CategoryBanner;
// 1 - 353065236154;

/* ---------------- TEAMCREATEDNOTEAMPLAN --------------- */

/**
 *
 * Methodology
 *
 * Condition:
 *
 * You have already created your team, but your active plan is not a Team Plan.
 *
 * In this UI team admin wants to renew the team admin plan.
 * so it did ask to
 *
 * If a team admin created a team and after some time team admin switch to
 *    1. Different plan and now team admin wants to activate team plan again
 *    2. Team plan expired or
 *    3. Team plan cancenlled
 *
 * so in this situation the team status is HOLD and team members cant use any tools. Their personal workspace also wont work.
 *
 * Team admin will be asked to select the number of seats
 *
 *
 * */

import React from 'react';
import { Typography, Divider } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { PLAN_STATUS, WITH_PLAN_STATUS } from '../../../constants';
import { Button } from '../../../components/Button';
import SeatsQuantity from './SeatsQuantity';
import Proration from './Proration';
import { AddMemberSubscriptionWithCheckout } from '../../../redux/Stripe/actionCreator';
import { useDispatch } from 'react-redux';

const { Text, Paragraph } = Typography;

const TeamCreatedNoTeamPlan = ({
  renewTeamLoading,
  activatedPlanDetails,
  plan,
  planStatus,
  teamName,
  onRenewPlan,
  onSeatChange,
  memberTeamsWithOutHold,
  seatCounts,
  activateTeamLoading,
  onActivateTeam,
  cancelTeamLoading,
  onCancelPlan,
  setUpdateTeamLoading,
  updateTeamLoading,
  setCreateTeamDisable,
  getTeamPlanId,
}) => {
  const dispatch = useDispatch();

  return (
    <div>
      <div className="title-wrapper">
        <div>
          <Text className="welcome-title">Your Team Name is: {teamName}</Text>
          <div className="plan-points">
            <Paragraph className="subtitle">
              <CheckOutlined className="icon" />
              You have already created your team, but your active plan is not a Team Plan.
            </Paragraph>
            <Paragraph className="subtitle">
              <CheckOutlined className="icon" />
              Every user you add to your work team will be discounted by 30%.{' '}
            </Paragraph>
          </div>
        </div>
      </div>

      {/* Expiring Soon  */}
      {planStatus === PLAN_STATUS.EXPIRING_SOON && (
        <div className="expiring-plan">
          <Paragraph>
            You already have an active <Text type="danger">{plan} </Text> plan! It will be cancelled on{' '}
            {activatedPlanDetails.expiringDate}.
            <br />
            You need to renew your existing plan.
            <br />
            <br />
            <Button loading={renewTeamLoading} size="small" onClick={onRenewPlan} type="primary">
              Click Here to Renew Existing Plan.
            </Button>
          </Paragraph>
        </div>
      )}

      {/* Crafter or Starter Plan is selected and now the user wants to swtich to team plan */}
      {planStatus === PLAN_STATUS.ACTIVE && (
        <div className="seatsWithTeamPlan">
          <div className="seats">
            <Text>Seats:</Text>
            <SeatsQuantity width={90} onSeatChange={onSeatChange} />
            <Text className="seats-note">(save 30% on each additional seat) </Text>
          </div>
          <div className="proration">
            <Proration seatCount={seatCounts} createTeamView disableCreateTeamButton={setCreateTeamDisable} />
          </div>

          <Button
            disabled={seatCounts > 0 ? false : true}
            loading={activateTeamLoading}
            size="small"
            onClick={onActivateTeam}
            type="primary"
          >
            Click Here to Activate Team Plan.
          </Button>

          {/* This accous when the team members are in on HOLD status. it gives the optiion to cancel the plan  */}
          {memberTeamsWithOutHold > 0 && (
            <>
              <Divider style={{ marginTop: '2rem' }} plain>
                Change of Mind
              </Divider>
              <Text>If you want to cancel your existing plan and dont want to Activate team plan. </Text>
              <Button loading={cancelTeamLoading} size="small" onClick={onCancelPlan} type="primary">
                Cancel your Existing ({plan}) Plan
              </Button>{' '}
            </>
          )}
        </div>
      )}

      {/* There are 2 main scenerios 
          1. If the user plan is NA. normally team-member or free user plan is NA. and these type of users wants to create a team.
          2. If the user already subscribe to a plan but subscription is cancelled or expired or error (credit-card payment) issue 
          
          This opens the stripe subscription checkout 
      */}
      {(planStatus === PLAN_STATUS.CANCELED ||
        planStatus === WITH_PLAN_STATUS.EXPIRED ||
        planStatus === PLAN_STATUS.ERROR ||
        planStatus === PLAN_STATUS.NA) && (
        <div className="seatsWithTeamPlan">
          <div className="seats">
            Seats:sss
            <SeatsQuantity width={90} onSeatChange={onSeatChange} />
            <Text className="seats-note">(save 30% on each additional seat) </Text>
          </div>

          <Button
            disabled={seatCounts > 0 ? false : true}
            loading={updateTeamLoading}
            size="small"
            onClick={() => {
              setUpdateTeamLoading(true);
              dispatch(AddMemberSubscriptionWithCheckout({ priceId: getTeamPlanId, quantity: seatCounts }));
            }}
            type="primary"
          >
            Click Here to Update to Team Plan.
          </Button>
        </div>
      )}
    </div>
  );
};

export default TeamCreatedNoTeamPlan;

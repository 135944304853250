/* ------------------------------------------------------ */
/*         Protect Routes for authenticated users         */
/* ------------------------------------------------------ */

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children }) => {
  const auth = useSelector((state) => state.auth.login);
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;

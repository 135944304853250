import styled from 'styled-components';

const AdminNotificationWrapper = styled.div`
  .ant-form-item-required {
    width: 100%;
    margin-bottom: 10px !important;
  }

  .banner-input-design {
    width: 100%;
    text-align: center;
    height: 50px;
    font-size: 16px;
    color: black;
    line-height: 50px;
    padding: 0;
    border: 1px solid #221d1d24;
  }
`;

export { AdminNotificationWrapper };

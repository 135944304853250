export const NotificationData = [
  { value: 'A', title: 'Communication', description: 'Get Craftly news, announcements, and product updates' },
  {
    value: 'B',
    title: ' Account Activity ',
    description: 'Get important notificationns about you or activity you’ve missed',
  },
  {
    value: 'C',
    title: 'Notices',
    description: 'Receive notifications on important pricing or policy changes that may affect your account',
  },
  { value: 'D', title: ' Events or Webinars', description: 'Get an email when an event is posted' },
];

import styled from 'styled-components';
import { Modal } from 'antd';

const ModalTool = styled(Modal)`
  width: 1408px !important;
  @media (max-width: 768px) {
    width: auto;
  }
  .ant-menu-vertical {
    width: 200px;
    border: none;
    display: flex;
    flex-direction: column;
    height: 40px;
    padding-left: 0px !important;
  }

  .ant-modal-close-x {
    width: 33px;
    height: 33px;
    line-height: 33px;
  }

  .ant-modal-mask {
    height: 39rem;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 10px 0 0 0;
    background: #fff;
    width: 100%;
    z-index: 1;
    position: relative;
    display: none;
  }

  .ant-modal-body {
    display: flex;
    height: calc(100vh - 80px);
    padding: 0px;
    padding-right: 30px;
    @media (max-width: 768px) {
      height: calc(100vh - 40px);
      padding: 15px 22px 0px;
    }

    // Ipad Air
    @media only screen and (min-width: 770px) and (max-width: 993px) {
      height: calc(100vh - 20px);
    }
  }

  .ant-card-body {
    display: flex;
  }

  .ant-input-clear-icon {
    margin-right: 20px !important;
  }

  .ant-input-affix-wrapper {
    padding: 5px;
    font-size: 16px;
    width: 387.26px;
    height: 48.09px;
    font-weight: 500;
    border: 1.5px solid #eeeeee;
    // box-shadow: 0px 20px 35px -18px rgba(95, 103, 117, 0.25);
    box-shadow: 0px 15px 30px -19px rgb(95 103 117 / 25%);
    border-radius: 10px;
    margin-bottom: 20px;
    @media (max-width: 768px) {
      font-size: 16px;
      height: 48.09px;
      width: 267px;
      width: calc(100% - 39px);
    }
    .ant-input-prefix {
      margin-right: 10px;
      width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-input-affix-wrapper > input.ant-input {
    font-size: 14px;
    padding: 0;
    width: 100%;
  }
  .ant-btn.ant-btn-block {
    width: 250px;
    border-radius: 8px;
    margin-top: 19px;
    margin-right: 30px;

    height: 48px;
    font-family: 'Poppins-SemiBold';
    @media (max-width: 768px) {
      width: 151px;
      margin: 0px;
      height: 42px;
      font-size: 12px;
    }
  }

  .sidebar-menu {
    padding: 43px 0px 0px 22px;
    width: 190px;
    background: #f9f9ff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 100%;

    @media (max-width: 992px) {
      display: none;
    }
    @media (max-width: 768px) {
      padding: 0px;
      width: 0px;
      background: none;
    }
  }

  .sidebar-menu .ant-menu-vertical {
    height: auto !important;
    margin-bottom: 0;
    padding: 0px 0px 11px 0 !important;
    display: flex;
    align-items: flex-start;
  }
  .sidebar-menu .ant-menu-item {
    padding: 0 !important;
    height: auto !important;
    margin: 0 8px 0 0 !important;
    line-height: inherit;
  }

  .right-topup {
    padding: 30px 0px 0px 30px;
    position: relative;
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
      padding: 0px 0px 0px;
      margin-top: 1.3rem;
    }
    margin-top: 10px;
  }
  .right-topup .modal-fotter {
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    width: 100%;
    padding-right: 30px;
    bottom: 30px;
    @media (max-width: 768px) {
      padding: 30px 0 21px 0;
      bottom: 0;
    }
  }
  .right-topup .ant-card-body {
    border: 1.7px solid #eeeeee;
    border-radius: 10px;
    min-height: 235px;
    padding: 0px !important;
    @media (max-width: 731px) {
      min-height: 170px;
    }
    @media only screen and (min-width: 732px) and (max-width: 767px) {
      min-height: 300px;
    }
    @media only screen and (min-width: 768px) and (max-width: 855px) {
      min-height: 340px;
    }
    @media only screen and (min-width: 856px) and (max-width: 932px) {
      min-height: 300px;
    }
    @media only screen and (min-width: 933px) and (max-width: 994px) {
      min-height: 280px;
    }
    @media only screen and (min-width: 993px) and (max-width: 1119px) {
      min-height: 310px;
    }
    @media only screen and (min-width: 1120px) and (max-width: 1290px) {
      min-height: 264px;
    }
  }
  .ant-menu-title-content {
    font-size: 12px;
    font-family: 'Poppins-Medium';
  }

  .right-topup .ant-card-body .top {
    padding: 20px;
    @media only screen and (max-width: 1252px) and (min-width: 1119px) {
      padding: 15px !important;
    }
  }
  .right-topup .ant-card-body .top .desc {
    font-size: 13px;
    font-weight: 500;
    font-family: 'Poppins-Regular';
    color: #282b3f;
    line-height: 20px;
    margin: 11px 0 20px 0;
    @media (max-width: 731px) {
      margin-top: 5px;
      font-size: 11px;
      margin-bottom: 0px;
      font-family: 'Poppins-Regular';
      line-height: 12px;
      padding-right: 0;
    }
  }
`;

const ToolDivPopupCard = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 226px);

  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 734px) {
    .desc {
      padding-right: 55px;
    }
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

const ToolLibPopup = styled.div``;

const Breadcrumbcontainer = styled.div`
  ::after {
    content: '';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 55px;
    height: 30px;
    right: -1px;
    position: absolute;
    z-index: 9999999;
    margin-left: auto;
    top: 77px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 40%, #fff 70%);
    @media (min-width: 767px) {
      display: none;
    }
  }
  display: flex;
  display: -webkit-box !important;
  flex-direction: row;
  // width: 281px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 837px) and (max-width: 993px) {
    width: 100%;
  }
  @media only screen and (max-width: 875px) and (min-width: 768px) {
    width: 660px;
  }

  .mobile-menu {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins-Medium';
  }

  .ant-breadcrumb ol {
    cursor: pointer;
    color: #282b3f !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    padding: 10px 0px 24px 0px;
    min-width: max-content;
  }
  .ant-breadcrumb ol li {
    display: flex;
    align-items: center;
  }
  .ant-breadcrumb:last-child {
    margin-right: 10%;
  }
`;

const RightTopupWrap = styled.div`
  .ant-typography {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    font-family: 'Poppins-SemiBold';
  }
`;

const RightTopup = styled.div`
  padding-top: 30px;
  overflow-y: scroll;
  height: calc(100vh - 160px);
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  .ant-menu-vertical {
    -webkit-flex-direction: none;
    display: flex;
    flex-direction: row;
    height: 23px !important;
    margin-bottom: 11px;
  }
  .ant-menu-item {
    padding: 0px 8px !important;
  }

  .ant-menu-item-selected {
    color: ${({ theme }) => theme['dark-desaturated-blue']};
  }
  .menu-selected {
    .ant-menu-title-content {
      color: #6c5afb;
      font-weight: 500;
      font-size: 12px;
      line-height: 23px;
      font-family: 'Poppins-Regular';
    }
  }

  .ant-typography {
    color: #282b3f;
    line-height: 30px;
    margin-bottom: 35px;
    height: 30px !important;
  }
  .ant-menu-title-content {
    color: #282b3f;
    font-weight: 500;
    font-size: 12px;
    line-height: 23px;
    font-family: 'Poppins-Regular';
  }
  .icon-image {
    margin-right: 12px;
    width: 17px !important;
    align-items: center;
    display: flex;
  }
`;

export { ModalTool, RightTopup, Breadcrumbcontainer, RightTopupWrap, ToolDivPopupCard, ToolLibPopup };

import Blog from '../../../../static/header/Blog.svg';
import Events from '../../../../static/header/Events.svg';
import Academy from '../../../../static/header/Academy.svg';
import Help from '../../../../static/header/Help.svg';
import ToolGuides from '../../../../static/header/ToolGuides.svg';
import Community from '../../../../static/header/Community.svg';

export const LongPopupData = [
  {
    key: 1,
    image: Blog,
    title: 'Blog',
    data: 'Articles, news and resources',
    url: 'https://www.craftly.ai/blog',
  },
  {
    key: 2,
    image: Events,
    title: 'Events',
    data: 'Events thrown by Craftly',
    url: 'https://www.craftly.ai/events',
  },
  {
    key: 3,
    image: Academy,
    title: 'Academy',
    data: 'Learn with tips and tricks',
    url: 'https://www.craftly.ai/academy',
  },
  {
    key: 4,
    image: Help,
    title: 'Help',
    data: 'Guidance and assistance by our experts',
    url: false,
  },
  {
    key: 5,
    image: ToolGuides,
    title: 'Tool Guides',
    data: 'Step-by-step & best practice tool guides',
    url: 'https://www.craftly.ai/writing-tool-library',
  },
  {
    key: 6,
    image: Community,
    title: 'Community',
    data: 'Share and connect with other Crafters',
    url: ' ',
  },
];

/* ------------------------------------------------------ */
/*                          EARN                          */
/* ------------------------------------------------------ */

/**
 * Earn UI
 */

import { Typography } from 'antd';
import React from 'react';
import ChatEarn from './overview/ChatEarn';
import FeatureUs from './overview/FeatureUs';
import ShareLink from './overview/ShareLink';
import { useViewport } from '../../hooks/viewPort';
import { NewEarnWrap, NewEarnContainer, NewEarnHeaderMain } from './overview/styled';

const Earn = () => {
  const { width } = useViewport();
  const { Text, Title } = Typography;
  return (
    <>
      <NewEarnHeaderMain>
        <Title level={4}>Earn</Title>
        <Text>
          Do you love our product? Has it helped you and do you think it could help others? Spread the word and earn.
        </Text>
      </NewEarnHeaderMain>
      <NewEarnContainer>
        <NewEarnWrap>
          <ShareLink />
          {width < 768 ? <ChatEarn /> : <FeatureUs />}
        </NewEarnWrap>
        <NewEarnWrap>{width < 768 ? <FeatureUs /> : <ChatEarn />}</NewEarnWrap>
      </NewEarnContainer>
    </>
  );
};

export default Earn;

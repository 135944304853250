import React from 'react';

const LogoutIcon = ({ color }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.262 5.5426V4.84285C11.262 3.3166 10.0245 2.0791 8.49823 2.0791H4.84198C3.31648 2.0791 2.07898 3.3166 2.07898 4.84285V13.1904C2.07898 14.7166 3.31648 15.9541 4.84198 15.9541H8.50573C10.0275 15.9541 11.262 14.7204 11.262 13.1986V12.4914"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M16.3571 9.0166H7.32635" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M14.1609 6.83008L16.3569 9.01633L14.1609 11.2033"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default LogoutIcon;

/* ------------------------------------------------------ */
/*                      CAI REPHRASE                      */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * This is a special tool to have a different layout from the rest of the tool layout.
 * It rephrase the input and give dropdown option in the output area.
 *
 * Only 1 output is in the reponse from openai. there is only 1 rephrase option.
 *
 * Output can easily download in a word file
 *
 * In order to proceed the rephrase tool, user input requested via tool-api and the backend-phyton-ai stores the data in 2 firetore collection called cai-rephrase-tool and users-bot
 * #Firestore Collection: cai-rephrase-tool -> Document uid ->  subCollection: craft  -> subDocument currentBotId
 * Using Firestore snapshot function we fetch the output data using the currentBotId. the data stores in the collection is chuncks
 * so that we can rephrase  paragraph by paragraph
 *
 * Openai is managed by backend-phyton-ai. and a api is provided to communicate.
 *
 * Inorder to fast the process, backend-phyton-ai stores the data in firestore as soon as the paragraph complete.
 * Input Content is rephrased Paragraph by paragraph.
 *
 * Save rephrase is a manual feature. when ever there is a change in the ouput the save button appears
 * #Firestore Collection: users-bot collection: <UID>   subCollection: craft  Document: rephrase-bot Id
 */

import { Row, Grid, Col, Input, Radio, Typography, Tooltip, Skeleton, Divider, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import { Button } from '../../components/Button';
import { ActionSummaryStyled, CaiRephraseWrapper, RephraseBoxStyled } from './styled';
import { InfoCircleOutlined } from '@ant-design/icons';
import RephraseUI from './overview/RephraseUI';
import { messageAlert } from '../../components/utilities/messages';
import CopyToClipboard from 'react-copy-to-clipboard';
import copyImg from '../../static/tool-page/paper.svg';
import { CheckOutlined } from '@ant-design/icons';
import downloadImg from '../../static/tool-page/download.png';
import deleteImg from '../../static/tool-page/delete.png';
import statisticImg from '../../static/tool-page/statistic.png';
import loadingGIF from '../../static/general/loading.gif';
import { Export2Word } from '../../utility/export';
import { useDispatch } from 'react-redux';
import { deleteRephraseOutputCraft, saveEditRephraseCraft } from '../../redux/UserProject/actionCreator';
import { BUTTON_PRIMARY } from '../../constants';
import BreadcrumbCai from '../../components/Breadcrumb';
import { getFirestore } from 'redux-firestore';

const { Text } = Typography;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

/**
 * @allToolsLoading loading of tool
 * @toolId chai chat tool id
 * @toolCharacterInput display of current input words count
 * @displayToolName tool name
 * @fields every tool have their own fields to populate the UI. overhere it is checking the type of area-text
 * @maxLimitExceed func to check the input text length of every field. every  input field have max number of characters
 * @toolBody every tool body have own set of fields
 * @onChangeText func to deduct the change of fields. it stores the data in state
 * @toolOutputLoading loading time to get the result from
 * @isFormValid is all checks are clear
 * @onSubmit on clicking the submut button
 * @allowTemplateOnPlan each plan have their access level reagarding the tools.
 * @output output from the openai
 * @currentBotId  current bot id to display the craft id
 */
const CaiRephrase = ({
  allToolsLoading,
  displayToolName,
  fields,
  maxLimitExceed,
  toolCharacterInput,
  toolBody,
  onChangeText,
  toolOutputLoading,
  isFormValid,
  onSubmit,
  allowTemplateOnPlan,
  output,
  currentBotId,
  uid,
}) => {
  const [rephraseUpdatedContent, setTephraseUpdatedContent] = useState('');
  const dispatch = useDispatch();
  const [rephraseOutput, setRephraseOutput] = useState(null);
  const [rephraseFetchOutput, setRephraseFetchOutput] = useState([]);

  const db = getFirestore();

  const [contentSummary, setContentSummary] = useState({
    totalRephraseSentences: 0,
    totalWords: 0,
  });
  const { content } = output || { content: null };
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [copySuccess, setCopySuccess] = useState(null);
  const screens = useBreakpoint();

  const modesData = fields && fields.find(({ type }) => type === 'generic-options@caiRephraseModels');
  const inputData = fields && fields.find(({ type }) => type === 'textarea');

  const onCopyIconClick = (id) => {
    messageAlert({ type: 'info', textMessage: 'Text Copied' });
  };

  // when there is a change in the rephrase output, the save button appears inorder to save the latest one.
  const onRephraseContentChange = () => {
    var text = document.getElementById('rephrase-box').innerText;
    setTephraseUpdatedContent(text);
    setShowSaveButton(true);
  };

  const onOutputDelete = () => {
    dispatch(deleteRephraseOutputCraft());
  };

  const actionSummary = () => {
    return (
      <ActionSummaryStyled>
        <div className="rephrase-summary">
          {`${contentSummary.totalRephraseSentences} Sentences `} <div className="dot" />{' '}
          {`${contentSummary.totalWords} Words`}
        </div>
        <div className="delete-rephrase" onClick={onOutputDelete}>
          <img src={deleteImg} alt="delete" className="delete-icon" /> <Text className="delete-text"> Delete</Text>
        </div>
      </ActionSummaryStyled>
    );
  };

  // calculates the summary of the rephrase output
  const calculateSummary = () => {
    let totalLen = 0;
    let totalWords = 0;
    let totalTokens = 0;
    let totalRephraseSentences = 0;
    let currentContentData = '';

    content &&
      content.forEach(
        (
          { additionalWords, botId, contentData, contentId, gen, tokens, len, words, cfr, fr, time, userUID },
          index,
        ) => {
          totalLen = totalLen + len;
          totalWords = totalWords + words;
          totalTokens = tokens + totalTokens;
          const re = /[.!?]/;
          const numOfSentences = contentData.split(re);
          totalRephraseSentences = totalRephraseSentences + (numOfSentences.length - 1);
          currentContentData = {
            contentId,
            botId,
            len: totalLen,
            words: totalWords,
            time,
            tokens: totalTokens,
            cfr,
            fr,
          };
        },
      );

    setContentSummary({ totalRephraseSentences, totalWords, currentContentData });
  };

  useEffect(() => {
    calculateSummary();
    setShowSaveButton(false);
  }, [content]);

  const onEditSave = () => {
    const { currentContentData } = contentSummary;

    dispatch(saveEditRephraseCraft({ ...currentContentData, contentData: rephraseUpdatedContent }));
  };

  const onRephraseSubmit = () => {
    // when the user submit the request, the original text of the user is displayed on the right side.
    const userInput = document.getElementById('cai-rephrase-input').innerHTML;
    const tempInput = [
      {
        additionalWords: null,
        contentData: userInput,
        contentId: 443311,
        userInput: true,
      },
    ];
    setRephraseOutput(tempInput);
    setRephraseFetchOutput([]);
    onSubmit({ extraInputs: null });
  };

  // Firestore snapshot is used to change the in firestore Collection.
  // this speed up the process so that long paragraph can be easily rephrase one by one.
  useEffect(() => {
    if (currentBotId) {
      db.collection('cai-rephrase-tool')
        .doc(uid)
        .collection('craft')
        .doc(currentBotId)
        .onSnapshot(
          (doc) => {
            if (doc.data()) {
              const data = doc.data();
              const objectKeys = Object.keys(data);

              const filterKeys = objectKeys.filter((item) => item !== 'loader');
              if (filterKeys && Array.isArray(filterKeys) && filterKeys.length > 0) {
                const tempOut = filterKeys.map((item) => {
                  const { alternative_words, out, input, type } = data[item];
                  const { contentData, contentId, len, words } = out[0];

                  return { additionalWords: alternative_words, type, input, len, words, contentData, contentId };
                });
                setRephraseFetchOutput(tempOut);
              }
            }
          },
          (error) => {
            console.log('error', error);
          },
        );
    }
  }, [currentBotId]);

  // It replace the user input with the rephrase content.
  useEffect(() => {
    if (rephraseOutput) {
      const getInput = rephraseOutput?.find(({ userInput }) => userInput === true);
      let originalInput = getInput?.contentData;
      let inputChange = getInput?.contentData;
      rephraseFetchOutput &&
        rephraseFetchOutput.forEach(({ input }, index) => {
          inputChange = originalInput.replace(input, '');
          originalInput = inputChange;
        });
      const tempDisplay = [
        {
          additionalWords: null,
          contentData: inputChange,
          contentId: 443311,
          userInput: true,
        },
        ...rephraseFetchOutput,
      ];

      setRephraseOutput(tempDisplay);
    }
  }, [rephraseFetchOutput]);

  return (
    <CaiRephraseWrapper>
      <div className="rephrase-top">
        <div className="breadcrumb-ui">
          <BreadcrumbCai firstLink={'/category'} firstLinkTitle="Tool Library" secondLinkTitle={displayToolName} />
        </div>

        <div className="modes-wrapper">
          <Skeleton loading={allToolsLoading} active title paragraph={{ rows: 2 }}>
            <Row justify={'space-between'}>
              <Col>
                {modesData && (
                  <>
                    <div>
                      <Text className="modes-text">Modes</Text>
                      {modesData.toolTipText && (
                        <Tooltip title={modesData.toolTipText}>
                          <InfoCircleOutlined className="info-icon" />
                        </Tooltip>
                      )}
                    </div>

                    <div className="mode-selection">
                      <Radio.Group name={modesData.key} value={toolBody[modesData.key]} onChange={onChangeText}>
                        {modesData.keyOptionValues.map(({ fieldName, fieldKey }) => {
                          return <Radio value={fieldKey}>{fieldName}</Radio>;
                        })}
                      </Radio.Group>
                    </div>
                  </>
                )}
              </Col>
              <Col>
                <div className="rephrase-header-summary">{actionSummary()}</div>
              </Col>
            </Row>
          </Skeleton>
        </div>
      </div>
      <div className="tool-section">
        <Skeleton loading={allToolsLoading} active title paragraph={{ rows: 5 }} className="skeleton-loading">
          <Row>
            <Col xs={24} sm={24} md={12}>
              {/* User input  */}

              {inputData && (
                <div className="input-section">
                  <div className="input-header">
                    <Text className="field-title">
                      {inputData.name}
                      <span className="required-staric">{!inputData.optional && '*'}</span>
                      <span className="optional-field">{inputData.optional && '(optional)'}</span>

                      {inputData.toolTipText && (
                        <Tooltip title={inputData.toolTipText}>
                          <InfoCircleOutlined className="info-icon" />
                        </Tooltip>
                      )}
                    </Text>
                    <div className="count-words">
                      <Text
                        className="field-count"
                        type={
                          maxLimitExceed().check && maxLimitExceed()[inputData.key] === inputData.key
                            ? 'danger'
                            : 'secondary'
                        }
                      >
                        {`${toolCharacterInput ? toolCharacterInput[inputData.key] || 0 : 0}`}/
                        {inputData.characterLimit}
                      </Text>
                    </div>
                  </div>
                  <div className="input-content">
                    <TextArea
                      id="cai-rephrase-input"
                      bordered={false}
                      name={inputData.key}
                      value={toolBody[inputData.key]}
                      placeholder={(inputData.placeholder || '').replaceAll('\\n', '\n')}
                      autoSize={{ minRows: (screens.xs ? 6 : inputData.maxRows) || 4, maxRows: inputData.maxRows || 7 }}
                      onChange={onChangeText}
                      onPaste={onChangeText}
                    />
                  </div>
                  <div className="craft-btn">
                    <Divider />

                    <Button
                      id="craftButton"
                      loading={toolOutputLoading}
                      disabled={!isFormValid() || maxLimitExceed().check || !allowTemplateOnPlan}
                      block
                      size="large"
                      type="primary"
                      onClick={onRephraseSubmit}
                    >
                      {toolOutputLoading ? 'Crafting' : 'Craft It'}
                    </Button>
                  </div>
                </div>
              )}
            </Col>
            <Col xs={24} sm={24} md={12}>
              <div className="output-section">
                <div className="rephrase-summary-output">{actionSummary()}</div>

                {/* Output   */}
                <RephraseBoxStyled>
                  {/* rephrase output is in the array form. As it rephrase paragraph so open-ai rephrase the content one by one.  */}
                  {rephraseOutput ? (
                    <>
                      <div id="rephrase-box">
                        {Array.isArray(rephraseOutput) &&
                          rephraseOutput.map(({ additionalWords, userInput, contentData, contentId }, index) => {
                            return (
                              <>
                                <div className={'rephrase-craft'} key={`${index}-rephrase`}>
                                  {React.createElement(RephraseUI, {
                                    key: `rep-${index}`,
                                    rephraseContent: contentData || '',
                                    additionalWords,
                                    onRephraseContentChange: () => onRephraseContentChange(),
                                    userInput,
                                  })}
                                </div>

                                {!userInput && <br />}
                              </>
                            );
                          })}
                      </div>
                      <Divider />
                      <div className="rephrase-summary">
                        <div style={{ cursor: 'pointer' }}>
                          <CopyToClipboard
                            text={rephraseUpdatedContent}
                            onCopy={() => onCopyIconClick('rephrase-copy-content')}
                          >
                            {copySuccess === 'rephrase-copy-content' ? (
                              <CheckOutlined className="action-rephrase-icon" />
                            ) : (
                              <img src={copyImg} alt="copy-img" className="action-rephrase-icon" />
                            )}
                          </CopyToClipboard>

                          <span onClick={() => Export2Word('rephrase-box', 'rephrase.docx')}>
                            <img src={downloadImg} className="action-rephrase-icon" alt="download" />
                          </span>
                        </div>
                        <Space>
                          {showSaveButton && (
                            <Button onClick={onEditSave} size={'small'} type={BUTTON_PRIMARY} transparentwithoutline>
                              Save
                            </Button>
                          )}

                          <div className="statistic-box">
                            <img src={statisticImg} className="statistic-icon" alt="statistic" />{' '}
                            <Text className="statistic-text">Statistics </Text>
                          </div>
                        </Space>
                      </div>
                    </>
                  ) : toolOutputLoading ? (
                    <div className="animationWrapper">
                      <img alt="" src={loadingGIF} className="loading-image" />
                    </div>
                  ) : (
                    <div className={'no-content-craft'}>Insert your crafts</div>
                  )}
                </RephraseBoxStyled>
              </div>
            </Col>
          </Row>
        </Skeleton>
      </div>
    </CaiRephraseWrapper>
  );
};

export default CaiRephrase;

/* ------------------------------------------------------ */
/*                      Favorite Card                     */
/* ------------------------------------------------------ */

/**
 * The display of fav data
 *
 * Bydefault the preview of text is shorten
 * on clicking the preview the modal opens
 *
 */

import { Card, Col, Empty, Row, Spin, Typography } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Heart from '../../../static/favourite/Heart.svg';
import Paper from '../../../static/favourite/Paper.svg';
import FavouriteModal from './FavouriteModal';
import {
  breakLineForCopy,
  breakLineIfRequired,
  convertFirebaseToDate,
  getToolIcon,
  getToolIconId,
  htmlToText,
} from '../../../utility/utility';
import { FavoriteCardStyle, CardFavHeader, CardFavContent, CardFavContentText } from './styled';
import CopyToClipboard from 'react-copy-to-clipboard';
import { messageAlert } from '../../../components/utilities/messages';

const FavoriteCards = ({ allFavoritesPageLoading, allFavoritesPage }) => {
  const { Title, Text } = Typography;
  const [favouriteModal, setFavouriteModal] = useState(false);
  const [favouriteData, setFavouriteData] = useState(false);
  const { allTools } = useSelector((store) => store.adminFeatures);

  const handleToolPopUp = () => {
    setFavouriteModal(!favouriteModal);
  };

  const handleFavouriteModal = ({
    input,
    inputFields,
    displayDate,
    lines,
    toolIcon,
    toolDisplayName,
    contentData,
    words,
    len,
  }) => {
    setFavouriteModal(!favouriteModal);
    setFavouriteData({ input, inputFields, displayDate, toolIcon, lines, toolDisplayName, contentData, words, len });
  };
  return (
    <FavoriteCardStyle>
      <Spin spinning={allFavoritesPageLoading}>
        <Row gutter={[24, 24]}>
          {allFavoritesPage &&
            allFavoritesPage.map(
              ({ toolDisplayName, commonIcon, input, inputFields, toolName, date, contentData, words, len }) => {
                const toolIconId = getToolIconId({ toolId: toolName, allTools });
                const toolIcon = getToolIcon(toolIconId);
                const displayDate = moment(convertFirebaseToDate(date)).format('MMMM DD');
                const { lines } = breakLineIfRequired(contentData);
                return (
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Card
                    // className={`${favouriteData.id === item.id ? 'highlight' : ''}`}
                    >
                      <>
                        <CardFavHeader>
                          <img src={toolIcon} alt="tool-icon" />
                          <div className="favourite-top">
                            <img alt="Heart" src={Heart} />
                            <CopyToClipboard
                              text={htmlToText(breakLineForCopy(contentData))}
                              onCopy={() => messageAlert({ type: 'info', textMessage: 'Text Copied' })}
                            >
                              <img alt="Paper" src={Paper} />
                            </CopyToClipboard>
                          </div>
                        </CardFavHeader>
                        <CardFavContent
                          onClick={(e) => {
                            handleFavouriteModal({
                              input,
                              displayDate,
                              toolIcon,
                              toolDisplayName,
                              contentData,
                              words,
                              len,
                              lines,
                              inputFields,
                            });
                          }}
                        >
                          <Title level={5} className="heading-title">
                            {toolDisplayName}
                          </Title>
                          <Text level={5} className="subhead-date">
                            {displayDate}
                          </Text>
                          <CardFavContentText>
                            <p className="output-content">{lines}</p>
                          </CardFavContentText>
                          <div className="favourite-bottom">
                            <div className="favchar-words">
                              <Text>{len} Chars </Text>
                              <span className="dot-fav">•</span>
                              <Text>{words} Words </Text>
                            </div>
                          </div>
                        </CardFavContent>
                      </>
                    </Card>
                  </Col>
                );
              },
            )}
        </Row>
      </Spin>
      {!allFavoritesPageLoading && allFavoritesPage && allFavoritesPage.length === 0 && <Empty />}
      {favouriteModal && (
        <FavouriteModal toolModal={favouriteModal} handleToolPopUp={handleToolPopUp} favouriteData={favouriteData} />
      )}
    </FavoriteCardStyle>
  );
};

export default FavoriteCards;

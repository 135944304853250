import styled from 'styled-components';

const DocumentStyled = styled.div`
  .delete-icon {
    cursor: pointer;
  }
  .document-Blog {
    color: rgba(108, 90, 251, 0.87);
    cursor: pointer;
  }
  .document-Text {
    color: #1e90ff;
    cursor: pointer;
  }
`;

export { DocumentStyled };

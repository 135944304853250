/* ------------------------------------------------------ */
/*                     Create Project                     */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * This component renders as a Modal.
 *
 * These are following actions in this component
 * 1. Add New Project
 * 2. Delete Project
 * 3. View the project list.
 * There are 3 case scenerios
 * 1. User
 *    user can create new  project, delete project and view project list.
 * 2. Team Admin
 *    a team amdin can create new  project, delete project and view project list.
 *    All the updated project list is accessible to all team members
 * 3. Team member
 *    Team member has the access to select the project.
 *    Dont have the access to delete the project and add new project.
 *
 *
 * Create Project: On Only Personal Workspace is allowed to created project. Team admin must have to shift the workspace from team to personal inorder to create new projects.
 *  Trial Plan: only allowed to create 1 project
 *  Starter Plan. only allowed to create 3 project.
 *  Crafter Plan: only allowed to create unlimited projects
 *
 * Delete Project: User cant delete all the projects. There should be atleast 1 project available all time.
 *
 * Data saved in Firestore:
 * Link: https://console.firebase.google.com/u/1/project/craftly-d50ba/firestore/data/~2Fprojects
 * Collection: projects
 * Document: <Firebase UID>
 *
 * Used In
 * src/components/ProjectSelection/index.jsx
 *
 */

import React, { useState, useEffect } from 'react';
import { Input, Dropdown, Space, Typography, notification, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { Button } from '../Button';
import { Modal } from '../Modal';
import {
  userProjectDataDelete,
  userProjectDataSubmit,
  userProjectRecentSubmit,
} from '../../redux/UserProject/actionCreator';
import {
  ROLES_LIST,
  STARTER,
  PERSONAL_WORKSPACE,
  TRIAL,
  TEAM_WORKSPACE,
  NA,
  NO_TRIAL_CC,
  STARTER_ACCESS,
  CRAFTER_ACCESS,
  ENTERPRISE_ACCESS,
} from '../../constants';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateProjectStyled } from './styled';

const { Text } = Typography;

const CreateProject = ({ open, onCancel, ProjectLists }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    open,
    modalType: 'primary',
  });

  const [displayProjects, setDisplayProjects] = useState([]);
  const [displayTeamRecentProject, setDisplayTeamRecentProject] = useState(null);
  const [newProjectName, setNewProjectName] = useState('');

  const { roles, plan } = useSelector((state) => state.fb.profile);
  const { data: projectData, recentProject, teamRecentProject, loading } = useSelector((state) => state.userProject);
  const { selectedWorkspace } = useSelector((state) => state.accountSettings);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        open,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [open]);

  // Project displays according to the selected Workspace. wither personal workspace or team
  useEffect(() => {
    if (selectedWorkspace && selectedWorkspace.workspaceType === PERSONAL_WORKSPACE) {
      setDisplayProjects(projectData);
    }
    if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE && teamRecentProject) {
      setDisplayProjects(selectedWorkspace.teamProjects);
      const { selectedUID } = selectedWorkspace;
      const { allTeamRecentProject } = teamRecentProject;
      const getTeamRecentProjectName =
        teamRecentProject && allTeamRecentProject[selectedUID] && allTeamRecentProject[selectedUID].projectId;
      setDisplayTeamRecentProject(getTeamRecentProjectName);
    }
  }, [projectData, selectedWorkspace, teamRecentProject]);

  useEffect(() => {
    // this is for team members that open their accounts for the first time, thus by default we choose the first project.
    // if the teamReact project is null then we selects the first project for team member.
    if (
      selectedWorkspace &&
      selectedWorkspace.workspaceType === TEAM_WORKSPACE &&
      teamRecentProject &&
      teamRecentProject.availableWorkSpaces &&
      teamRecentProject.availableWorkSpaces.length === 0
    ) {
      const { teamProjects } = selectedWorkspace;

      const pickFirstProject =
        teamProjects && Array.isArray(teamProjects) && teamProjects.length > 0 && teamProjects[0];
      pickFirstProject && dispatch(userProjectRecentSubmit(pickFirstProject));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace, recentProject]);

  // on Creating a new project
  const OnCreateNewProject = () => {
    // sending a request to create a new projects.
    let isAllowToCreateProject = false;

    // allowed only 1 project
    if (STARTER_ACCESS.includes(plan) && projectData.length < 1) {
      isAllowToCreateProject = true;
    }

    if (CRAFTER_ACCESS.includes(plan)) {
      isAllowToCreateProject = true;
    }
    if (ENTERPRISE_ACCESS.includes(plan)) {
      isAllowToCreateProject = true;
    }

    if (isAllowToCreateProject || ROLES_LIST.includes(roles)) {
      if (newProjectName) {
        const values = {
          project: newProjectName,
        };
        dispatch(
          userProjectDataSubmit(
            {
              ...values,
            },
            true,
          ),
        );
        setNewProjectName('');
        onCancel();
      } else {
        notification.info({
          message: 'Provide project name to create new project',
        });
      }
    } else {
      const text =
        plan === TRIAL
          ? 'Only 1 project is allowed in Trial plan'
          : plan === STARTER
          ? 'Only 3 project is allowed in Starter plan'
          : plan === NA
          ? 'Only 1 project is allowed'
          : plan === NO_TRIAL_CC
          ? 'Only 1 project is allowed'
          : '';
      notification.info({
        message: text,
      });
    }
  };

  const handleCancel = () => {
    setNewProjectName('');
    onCancel();
  };

  // Inorder to set the recent project, when the user selects the specific project,
  // we are saving the projectId.
  const setRecentProject = ({ project, projectId }) => {
    dispatch(userProjectRecentSubmit({ project, projectId }));
  };

  // the Redux to handle the delete project. Every project has its own Id.
  const onDelete = (projectId) => {
    // all the requets are handling in Actions.
    if (projectData.length < 2) {
      notification.info({
        message: 'You must create a new project before deleting this one',
      });
    } else {
      dispatch(userProjectDataDelete(projectId));
      setNewProjectName('');
    }
  };

  const projectDropdown = [
    {
      label: 'Delete',
      key: '1',
      // icon: <DeleteOutlined />,
    },
  ];
  const handleMenuClick = (e) => {
    console.log('click', e);
  };
  const projectProps = {
    items: projectDropdown,
    onClick: handleMenuClick,
  };

  const onNewProjectChange = (e) => {
    setNewProjectName(e.target.value);
  };

  return (
    <Modal
      width={620}
      type={state.modalType}
      title="Project"
      open={state.open}
      footer={[
        <div key="1" className="project-modal-footer">
          <Button size="default" type="primary" key="submit" onClick={() => onCancel()}>
            Done
          </Button>
        </div>,
      ]}
      onCancel={handleCancel}
    >
      <div className="project-modal">
        <p>
          Projects are a way to organize your results, store favourites and let the bot learn how you like it. Project
          by project. Select the project you wish to craft in:
        </p>
        {displayProjects && displayProjects.length > 0 && (
          <>
            <br />
            <Spin spinning={loading}>
              <Space size={[16, 16]} wrap>
                {/* Displays the list of projects  also set the recent project. */}
                {displayProjects.map(({ project, projectId }, index) => {
                  return selectedWorkspace && selectedWorkspace.workspaceType === PERSONAL_WORKSPACE ? (
                    <Dropdown.Button
                      icon={<DeleteOutlined onClick={() => onDelete(projectId)} />}
                      key={index}
                      type={recentProject.projectId === projectId ? 'primary' : 'default'}
                      onClick={() => setRecentProject({ project, projectId })}
                      menu={projectProps}
                      open={false}
                    >
                      {project}
                    </Dropdown.Button>
                  ) : selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE ? (
                    <Button
                      key={index}
                      type={displayTeamRecentProject === projectId ? 'primary' : 'light'}
                      onClick={() => setRecentProject({ project, projectId })}
                    >
                      {project}
                    </Button>
                  ) : null;
                })}
              </Space>
            </Spin>
            <br />
          </>
        )}

        {selectedWorkspace && selectedWorkspace.workspaceType === PERSONAL_WORKSPACE && (
          <CreateProjectStyled>
            <br />
            <Text className="project-title">Project Name</Text>
            <div className="input-button-project">
              <Input
                onPressEnter={OnCreateNewProject}
                value={newProjectName}
                onChange={onNewProjectChange}
                className="project-input"
                placeholder=""
              />
              <Button onClick={OnCreateNewProject} size="default" type="dark">
                <PlusOutlined /> Add New Project
              </Button>
            </div>

            {/* start Mobile responsive screen view  */}
            <Space direction="vertical" className="mobile-project-input">
              <Input
                onPressEnter={OnCreateNewProject}
                value={newProjectName}
                onChange={onNewProjectChange}
                className="project-input"
                placeholder=""
              />
              <Button block onClick={OnCreateNewProject} size="default" type="dark">
                <PlusOutlined /> Add New Project
              </Button>
              {/* end Mobile responsive screen view  */}
            </Space>
          </CreateProjectStyled>
        )}
      </div>
    </Modal>
  );
};

CreateProject.propTypes = {
  open: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default CreateProject;

/* ------------------------------------------------------ */
/*                       SidebarMenu                      */
/* ------------------------------------------------------ */

/**
 *
 * There are 4 type of page link navigation
 * 1. Craftly admin page links
 * 2. Dashboard and Features link
 * 3. Popular links
 * 4. Tool Category
 *
 * There is only difference in tools categories mechanism.
 * The modal opens when the tool category is clicked, displaying all of the tools in that category.
 *
 */

import React from 'react';
import { Menu } from 'antd';
import { useState } from 'react';
import { SidebarWrap } from '../styled';
import { useViewport } from '../../../hooks/viewPort';
import ToolLibraryPopup from '../../../container/ToolLibraryPopup';
import { Link, useNavigate } from 'react-router-dom';
import { SideBarLogo } from '../../../static/category/sidebar/sideBarLogo';
import { saveCategoryId } from '../../../redux/AdminFeatures/actionCreator';
import { useDispatch } from 'react-redux';

/**
 * @menudata menu of each type of links
 * @tools tool categories flag
 * @onMenuClicked on clicking on any page link
 * @activeSideNav selected page link
 *
 */
const SidebarMenu = ({ menudata = [], tools, onMenuClicked, activeSideNav }) => {
  const [toolLibraryPopupVisible, setToolLibraryPopupVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const [menuClick, setMenuClick] = useState('');

  // when a tool category clicked, the modal opens otherwise it redirect to the page
  const menuLinkClicked = ({ categoryId, pageLink, onCategoryIdClicked }) => {
    pageLink ? navigate(pageLink) : setToolLibraryPopupVisible(!toolLibraryPopupVisible);
    onMenuClicked({ mobMenuClicked: width < 991 ? true : false });
    dispatch(saveCategoryId({ selectedCategoryId: onCategoryIdClicked ? categoryId : null }));
    setMenuClick(categoryId);
  };

  const closeLibraryPopup = () => {
    setToolLibraryPopupVisible(false);
  };

  return (
    <SidebarWrap>
      {[...menudata]?.map(({ id, icon, categoryName, iconSmall, categoryId, title, iconwhite, pageLink }) => {
        return (
          <>
            {tools ? (
              // tool category clicked
              <Menu
                key={`side-bar-category-${title}`}
                selectedKeys={[activeSideNav]}
                multiple={false}
                onClick={() => menuLinkClicked({ categoryId, pageLink, onCategoryIdClicked: true })}
              >
                {iconSmall && (
                  <span className="icon-image">
                    <SideBarLogo logo={iconSmall} color={width < 991 ? '#ffff' : '#282B3F'} />
                  </span>
                )}
                <Menu.Item key={categoryId}>{categoryName} </Menu.Item>
              </Menu>
            ) : (
              <Menu
                selectedKeys={[activeSideNav]}
                key={`side-bar-${title}`}
                onClick={() => menuLinkClicked({ categoryId: id, pageLink, onCategoryIdClicked: false })}
              >
                <img alt="example" src={width < 991 ? iconwhite : icon} className="icon-image" />

                <Menu.Item key={id}>
                  <Link to={pageLink}>{title}</Link>
                </Menu.Item>
              </Menu>
            )}
          </>
        );
      })}

      {/* Tool category modal opens */}
      {toolLibraryPopupVisible && (
        <ToolLibraryPopup
          toolModalVisible={toolLibraryPopupVisible}
          handleToolPopUp={() => menuLinkClicked({ pageLink: false, onCategoryIdClicked: false })}
          closeLibraryPopup={closeLibraryPopup}
          menuClick={menuClick}
        />
      )}
    </SidebarWrap>
  );
};

export default SidebarMenu;

/* ------------------------------------------------------ */
/*              Language Selections For Craft             */
/* ------------------------------------------------------ */

/*
  The input and output of selection of languages for output crafts.
  Methodology: 
  When a user selects a language, an ant-design modal pops up. The hardcoded list is used to populate the languages list.     '../../utility/Languages' 
  
  listLangOptions: return list of languages of input and output type
*/

// Used In src/container/CommonHeader/HeaderComponent.js

import React from 'react';
import { Select, Typography, Space, Checkbox } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';
import { Button } from '../Button';
import { useViewport } from '../../hooks/viewPort';
import { Modal } from '../Modal';
import { listLangOptions } from '../../utility/Languages';
import { LanguageSelectionStyled, LanguageSelectionWrapper } from './styled';
import { INPUT_LANGUAGE, OUTPUT_LANGUAGE } from '../../constants';
import Flags from 'country-flag-icons/react/3x2'; //
import ArrowDown from '../../static/general/arrowDown';

const { Option } = Select;
const { Text } = Typography;

/** 
@boolean langVisible: Modal Visible in boolean true or false.
@function handleVisibleChange: function to display and hide the modal
@function onLanguageClick: it returns the current language change in input or output select option. it returns the array with the language type constant
@object langInput: recieves the user selected language format:  { CountryFlagCode: "US", langCode: "EN", langName: "English" }
@object langOutput: recieves the user selected language format:  { CountryFlagCode: "US", langCode: "EN", langName: "English" }
@function onIputLangDefault: It returns the checkbox of saving input current language as default, either true or false 
@boolean inputDefaultLang: boolean state 
@boolean outputDefaultLang: boolean state 
@function onOutputDefaultLang: It returns the checkbox of saving output current language as default, either true or false 
*/

const LanguageSelection = ({
  langVisible,
  handleVisibleChange,
  onLanguageClick,
  langInput,
  langOutput,
  onIputLangDefault,
  inputDefaultLang,
  outputDefaultLang,
  onOutputDefaultLang,
}) => {
  const { width } = useViewport();

  function onOutputChange(value) {
    onLanguageChange({ value, languageType: OUTPUT_LANGUAGE });
  }

  function onInputChange(value) {
    onLanguageChange({ value, languageType: INPUT_LANGUAGE });
  }

  /*
   value: langName
   languageType: INPUT_LANGUAGE OR OUTPUT_LANGUAGE
  */
  const onLanguageChange = ({ value, languageType }) => {
    const filterSearch = listLangOptions.find(({ langName }) => value === langName);
    // it fetch out the detials of language
    const { langCode, langName, CountryFlagCode } = filterSearch;
    const results = [langCode, langName, CountryFlagCode];
    onLanguageClick(results, languageType); // the lang details are for UI display
  };

  const setDefaultInputLang = (e) => {
    onIputLangDefault(e.target.checked);
  };

  const setDefaultOutputLang = (e) => {
    onOutputDefaultLang(e.target.checked);
  };

  const OutputSelectedFlag = langOutput && Flags[langOutput.CountryFlagCode];
  const InputSelectedFlag = langInput && Flags[langInput.CountryFlagCode];

  /* UI for Language Selection. It displays the language in Icons. */
  return (
    <LanguageSelectionStyled>
      {width < 768 ? ( // responsive modes. in mobile it shows only output language icon only
        <>
          {langOutput && (
            <div className="language-button" onClick={() => handleVisibleChange(true)}>
              <div className="lang">
                <OutputSelectedFlag title={langOutput.langName} className="lang-icon-mobile" />
                <ArrowDown width="21" height="21" color="#737277" />
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {/* in normal screens, 2 icons Input and output language icons displayed. */}
          {langInput && langOutput && (
            <div className="language-button" onClick={() => handleVisibleChange(true)}>
              <div className="lang">
                {/* Flag: package, use to display flag image */}
                <InputSelectedFlag title={langInput.langName} className="lang-icon" />
                <Text className="lang-text"> {langInput.langName}</Text>
              </div>
              <div className="lang">
                {/* icon */}
                <RetweetOutlined style={{ fontSize: '1.3rem', padding: '0px 10px' }} />
              </div>
              {/* output flag  */}
              <div className="lang">
                <OutputSelectedFlag title={langOutput.langName} className="lang-icon" />
                <Text className="lang-text"> {langOutput.langName}</Text>
              </div>
            </div>
          )}
        </>
      )}

      {/* Modal popups on clicking the language selection icon */}
      <Modal
        title="Select Languages"
        open={langVisible}
        onOk={() => handleVisibleChange(false)}
        onCancel={() => handleVisibleChange(false)}
        closable
        footer={null}
      >
        <LanguageSelectionWrapper>
          <Space wrap style={{ justifyContent: 'space-between' }}>
            <Text className="field-title">Select Input Language {langInput.langName}</Text>
            <Checkbox onChange={setDefaultInputLang} checked={inputDefaultLang}>
              <Text className="field-title">Set as default</Text>
            </Checkbox>
          </Space>

          {/* Input Selection */}
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select Input Language"
            optionFilterProp="children"
            onChange={onInputChange}
            filterSort={(optionA, optionB) =>
              optionA.children[1].toLowerCase().localeCompare(optionB.children[1].toLowerCase())
            }
            filterOption={(input, option) => {
              return option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            value={langInput.langName}
          >
            {listLangOptions
              .filter(({ display }) => display.includes(INPUT_LANGUAGE))
              .map(({ langCode, langName, CountryFlagCode, title }, index) => {
                const Flag = Flags[CountryFlagCode];
                return (
                  <Option value={langName} key={`input-${index}`}>
                    {/* this arrangment is important as they are children so we are picking by index number */}
                    <Flag title={langName} style={{ width: '1rem', marginRight: '10px' }} />
                    {langName}
                  </Option>
                );
              })}
          </Select>

          <Space wrap style={{ justifyContent: 'space-between', marginTop: '2rem' }}>
            <Text className="field-title output">Select Output Language (Result)</Text>
            <Checkbox onChange={setDefaultOutputLang} checked={outputDefaultLang}>
              <Text className="field-title">Set as default</Text>
            </Checkbox>
          </Space>

          {/*  */}
          <Select
            showSearch
            style={{ width: '100%', marginBottom: '2rem' }}
            placeholder="Select Output Language"
            optionFilterProp="children"
            onChange={onOutputChange}
            filterSort={(optionA, optionB) =>
              optionA.children[1].toLowerCase().localeCompare(optionB.children[1].toLowerCase())
            }
            filterOption={(input, option) => option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0}
            value={langOutput.langName}
          >
            {listLangOptions
              .filter(({ display }) => display.includes(OUTPUT_LANGUAGE))
              .map(({ langCode, langName, CountryFlagCode, title }, index) => {
                const Flag = Flags[CountryFlagCode];

                return (
                  <Option value={langName} key={`output-${index}`}>
                    <Flag title={langName} style={{ width: '1rem', marginRight: '10px' }} />
                    {langName}
                  </Option>
                );
              })}
          </Select>

          <Button type="primary" onClick={() => handleVisibleChange(false)} block>
            Enter
          </Button>
        </LanguageSelectionWrapper>
      </Modal>
    </LanguageSelectionStyled>
  );
};

export default LanguageSelection;

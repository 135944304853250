import styled from 'styled-components';

const DisplayBotsWrapper = styled.div`
  .heading-text {
    font-family: 'Poppins-SemiBold';
  }
  .tool-list {
    margin-top: 2rem;
  }
`;

const UserBotsWrapper = styled.div`
  .bot-sidebar {
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(${({ theme }) => theme['gray-color']}, 0.03);
    min-height: 1000px;
    max-height: 1000px;
    overflow: auto;

    .inputWrapper {
      padding: 1.2rem;
      border: 1px solid #e2ecf1;
      border-radius: 7px;
      background-color: #fafbfc;
      margin: 1rem 0rem;
      cursor: pointer;
      .output-summary {
        margin-top: 1rem;
        border-top: 1px solid ${({ theme }) => theme['primary-color']};
      }
    }
    .inputWrapperSelected {
      border: 1px solid ${({ theme }) => theme['primary-color']};
    }
  }

  .bot-sidebar::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .bot-sidebar::-webkit-scrollbar:vertical {
    width: 11px;
  }

  .bot-sidebar::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  .bot-sidebar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const ToolOutputContentStyled = styled.div`
  background-color: ${({ hasContent }) => (hasContent ? `#fff` : 'transparent')};
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(#9299b8, 0.03);
  min-height: 1000px;
  max-height: 1000px;
  overflow: auto;
  border: ${({ hasContent }) => (hasContent ? `1px solid #e2ecf1` : 'none')};

  .content-wrapper {
    padding-bottom: 25px;
    .content-list {
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 30px;

        &:not(:last-child) {
          border-bottom: 1px solid #e2ecf1;
        }
        @media only screen and (max-width: 575px) {
          padding: 15px 20px;
          flex-direction: column;
          align-items: flex-start;
        }

        .output-details-wrapper {
          width: 100%;
          .output-text {
            color: #455263;
            padding: 0px 0px 20px;
          }
          .output-summary {
            border-top: 1px solid ${({ theme }) => theme['primary-color']};
          }
        }
      }
    }
  }
`;

export { UserBotsWrapper, ToolOutputContentStyled, DisplayBotsWrapper };

import styled from 'styled-components';

const MobileProjectToolStyled = styled.div`
  line-height: normal;
  padding: 0px 1rem 1rem 1rem;

  .search-tools {
    padding-top: 1rem;

    .banner-text {
      color: ${({ theme }) => theme['dark-desaturated-blue']} !important;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
    }
  }
`;

const MobileFooterStyled = styled.div`
  display: ${({ collapsed }) => (!collapsed ? 'none' : 'flex')};
  background: linear-gradient(285.83deg, #8878f9 -34.95%, #6c5afb 92.08%);
  box-shadow: 0px -15px 65px -15px rgba(95, 103, 117, 0.15);
  border-radius: 0px;
  justify-content: space-around;
  padding: 0px 10px 0px 10px;
  width: 100%;
  bottom: 0px;
  position: fixed;
  height: 82px;
  left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  ${(props) => !props.sideBarClicked} {
    left: 256px !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  @media (max-width: 768px) {
    bottom: -3px;
  }

  .mobile-footer-container {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .ant-typography {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    margin-top: 2px;
    color: #ffffff;
  }
`;

export { MobileProjectToolStyled, MobileFooterStyled };

/* ------------------------------------------------------ */
/*                     Stripe Details                     */
/* ------------------------------------------------------ */

/**
 * This is the Stripe actions handler.
 *  All the stripe realated functions are over here.
 * We have used the firebase stripe extension, so Cloud-Functions are handled by the extension.
 * We have already deployed those functions.
 */
import actions from './actions';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '../../utility/stripePlan';
import { addNotificationError, addNotificationSuccess } from '../../components/utilities/notifications';
import { getInvoiceSynced } from '../../services/apiServices';
import { NA, NO_TRIAL_CC, promoCode, WITH_PLAN_STATUS } from '../../constants';
import { allSubscribers, uniqueIdByChar } from '../../utility/utility';
import { FirebaseAnalytics } from '../../config/firebase';

const {
  productReadBegin,
  productReadSuccess,
  productReadErr,
  subscriptionsReadSuccess,
  recentPaymentProgress,
  recentPaymentSuccess,
  trailStart,
  trailStop,
  noTrailccStop,
  noTrailccStart,
  crafterOffTrialStart,
  crafterOffTrialStop,
} = actions;

// All the products are created in Stripe and then they integrate to our frontend. They are handling everything, like name, price, duration type,
// For more generic we can also set the frontend-UI accoriding to stripe products.
// if the admin adds product in stripe, it will automatically show in frontend, without writing any code. (Recommended. Pending)
// IT USED IN V1 PRICING - only user anymore
const ProductGetData = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const productWithPriceList = [];
    try {
      await dispatch(productReadBegin());
      await db
        .collection('products')
        .where('active', '==', true)
        .where('stripe_metadata_live', '==', 'true')
        .get()
        .then(async (querySnapshot) => {
          await querySnapshot.forEach(async (productDoc) => {
            const productDetail = { productId: productDoc.id, productDetails: productDoc.data() };
            await productDoc.ref
              .collection('prices')
              .where('active', '==', true)
              .get()
              .then(async (priceSnapshot) => {
                priceSnapshot.forEach((priceDoc) => {
                  productWithPriceList.push({
                    priceId: priceDoc.id,
                    priceDetails: priceDoc.data(),
                    ...productDetail,
                  });
                });
                dispatch(productReadSuccess(productWithPriceList));
              })
              .catch((error) => {
                console.log(`error`, error);
              });
          });
        })
        .catch((err) => {
          dispatch(productReadErr(err));
          console.log(`err`, err);
        });
    } catch (err) {
      console.log(`err`, err);
      await dispatch(productReadErr(err));
    }
  };
};

// From here the Stripe Payment Page will open and they are handling the payment via Cards or google pay
// To subscribe the user to a specific pricing plan, create a new doc in the checkout_sessions collection for the user.
// The extension will update the doc with a Stripe Checkout session ID which you then use to redirect the user to the checkout page.
const StartSubscriptionWithCheckout = ({
  promotion_code,
  allow_promotion_codes,
  priceId,
  quantity,
  type,
  redirectURL,
}) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;
    const prompt = {
      price: priceId,
      quantity: quantity || 1,
      trial_from_plan: false, // this is for the trial mode, only at Trial page
      allow_promotion_codes: allow_promotion_codes || false,
      promotion_code: promotion_code || '',
      success_url: redirectURL || `${window.location.origin}/settings/billing`,
      cancel_url: redirectURL || `${window.location.origin}/settings/billing`,
      metadata: {
        priceId: priceId,
      },
    };
    // if (type === 'one_time') {
    //   prompt.mode = 'payment';
    // }
    const sessionID = uniqueIdByChar(20);
    try {
      // Firebase: Collection: users - Document: UID, - Collection 'checkout_sessions' - Document: sessionID
      await db
        .collection('users')
        .doc(currentUID)
        .collection('checkout_sessions')
        .doc(sessionID)
        .set({
          ...prompt,
        });

      const StartSubscriptionWithCheckoutSubscriber = await db
        .collection('users')
        .doc(currentUID)
        .collection('checkout_sessions')
        .doc(sessionID);

      // Wait for the CheckoutSession to get attached by the extension
      StartSubscriptionWithCheckoutSubscriber.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripePromise = await loadStripe(stripeKey);
          stripePromise.redirectToCheckout({ sessionId });
        }
      });
      allSubscribers.findIndex((x) => x.name === 'StartSubscriptionWithCheckoutSubscriber') === -1 &&
        allSubscribers.push({
          func: StartSubscriptionWithCheckoutSubscriber,
          name: 'StartSubscriptionWithCheckoutSubscriber',
        });
    } catch (err) {
      console.log(`err ---- `, err);
    }
  };
};

// team billing
const AddMemberSubscriptionWithCheckout = ({ priceId, quantity }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;
    const prompt = {
      trial_from_plan: false,
      allow_promotion_codes: false,
      success_url: `${window.location.origin}/team-portal`,
      cancel_url: `${window.location.origin}/team-portal`,
      line_items: [
        {
          price: priceId,
          quantity,
        },
      ],
      metadata: {
        priceId: priceId,
      },
    };

    try {
      const AddMemberSubscriptionWithCheckoutSub = await db
        .collection('users')
        .doc(currentUID)
        .collection('checkout_sessions')
        .add({
          ...prompt,
        });
      // Wait for the CheckoutSession to get attached by the extension
      AddMemberSubscriptionWithCheckoutSub.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripePromise = await loadStripe(stripeKey);
          stripePromise.redirectToCheckout({ sessionId });
        }
      });
      allSubscribers.findIndex((x) => x.name === 'AddMemberSubscriptionWithCheckoutSub') === -1 &&
        allSubscribers.push({
          func: AddMemberSubscriptionWithCheckoutSub,
          name: 'AddMemberSubscriptionWithCheckoutSub',
        });
    } catch (err) {
      console.log(`err`, err);
    }
  };
};

// trial billing, This is for the trial page. we are implementing the trial mode only on Trail page
const trailPlanSubscription = ({ priceId, allowTrialInPlan }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;
    const prompt = {
      price: priceId,
      trial_from_plan: allowTrialInPlan, // this is for the trial mode, only at Trial page
      allow_promotion_codes: true,
      success_url: `${window.location.origin}/`,
      cancel_url: `${window.location.origin}/trial`,
      metadata: {
        price: priceId,
      },
    };

    dispatch(trailStart());
    try {
      const trailPlanSubscriptionSub = await db
        .collection('users')
        .doc(currentUID)
        .collection('checkout_sessions')
        .add({
          ...prompt,
        });

      // Wait for the CheckoutSession to get attached by the extension
      trailPlanSubscriptionSub.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          console.log(`error----checkout`, error);
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          alert(`An error occured: ${error.message}`);
          dispatch(trailStop());
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripePromise = await loadStripe(stripeKey);
          stripePromise.redirectToCheckout({ sessionId });
          dispatch(trailStop());
        }
      });
      allSubscribers.findIndex((x) => x.name === 'trailPlanSubscriptionSub') === -1 &&
        allSubscribers.push({
          func: trailPlanSubscriptionSub,
          name: 'trailPlanSubscriptionSub',
        });
    } catch (err) {
      console.log(`err`, err);
      addNotificationError(JSON.stringify(err.message));
      dispatch(trailStop());
    }
  };
};

// This is for Promocode. Stripe Coupon.
const trailPlanSubscriptionWithPromoCode = ({ priceId, applyPromoCode }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;

    const prompt = {
      price: priceId,
      trial_from_plan: true, // this is for the trial mode, only at Trial page
      promotion_code: applyPromoCode ? promoCode : null,
      success_url: `${window.location.origin}/`,
      cancel_url: `${window.location.origin}/trial?crafter_thirty_off=craft30`,
      metadata: {
        priceId: priceId,
      },
    };

    dispatch(crafterOffTrialStart());
    try {
      const trailPlanSubscriptionWithPromoCodeSub = await db
        .collection('users')
        .doc(currentUID)
        .collection('checkout_sessions')
        .add({
          ...prompt,
        });

      // Wait for the CheckoutSession to get attached by the extension
      trailPlanSubscriptionWithPromoCodeSub.onSnapshot(async (snap) => {
        const { error, sessionId } = snap.data();
        if (error) {
          console.log(`error in Promo code`, error);
          // Show an error to your customer and
          // inspect your Cloud Function logs in the Firebase console.
          addNotificationError(error.message);
          dispatch(crafterOffTrialStop());
        }
        if (sessionId) {
          // We have a session, let's redirect to Checkout
          // Init Stripe
          const stripePromise = await loadStripe(stripeKey);
          stripePromise.redirectToCheckout({ sessionId });
          dispatch(crafterOffTrialStop());
        }
      });
      allSubscribers.findIndex((x) => x.name === 'trailPlanSubscriptionWithPromoCodeSub') === -1 &&
        allSubscribers.push({
          func: trailPlanSubscriptionWithPromoCodeSub,
          name: 'trailPlanSubscriptionWithPromoCodeSub',
        });
    } catch (err) {
      console.log(`err`, err);
      addNotificationError(JSON.stringify(err.message));
      dispatch(crafterOffTrialStop());
    }
  };
};

// user can access the app without giving credit card.
// CRAFTLY admin has given the offer to signup without giving credit card information
const noCreditCardRequired = (planSelect) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;
    const { email, name } = getState().fb.profile;
    dispatch(noTrailccStart());
    try {
      db.collection('users')
        .doc(currentUID)
        .update({
          plan: NO_TRIAL_CC,
          trialBillingPage: true,
          noTrialccPlanAcitivated: new Date(),
          selectedPlan: planSelect,
        })
        .then(() => {
          dispatch(noTrailccStop());
          //addNotificationSuccess(ACCOUNT_TRIAL_ACTIVATED);
          FirebaseAnalytics.logEvent('trial_no_credit_card', {
            email,
            selectedPlan: planSelect,
            name,
            uid: currentUID,
          });
        });
    } catch (err) {
      console.log(`err`, err);
    }
  };
};

// this is for team members
const skipOnboarding = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;
    try {
      db.collection('users').doc(currentUID).update({
        plan: NA,
        trialBillingPage: true,
        visitOnboarding: true,
        visitTeamPage: false,
        joinAsTeamMember: true,
      });
    } catch (err) {
      console.log(`err`, err);
    }
  };
};

const noCreditCardCancelPlan = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;
    dispatch(noTrailccStart());
    try {
      db.collection('users')
        .doc(currentUID)
        .update({
          plan: NO_TRIAL_CC,
          status: WITH_PLAN_STATUS.ACTIVE,
          planStatus: WITH_PLAN_STATUS.EXPIRED,
          displayPlanStatus: WITH_PLAN_STATUS.EXPIRED,
        })
        .then(() => {
          dispatch(noTrailccStop());
          addNotificationSuccess('Free Plan Cancelled.');
        });
    } catch (err) {
      console.log(`err`, err);
    }
  };
};

// ones the customer subscribe, here is the method to get all of user plan.
const GetCustomerSubscription = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;
    const { trialPlanAcitivated, firstSubscriptionCharged, activatedPlanDetails, currentSubscription } =
      getState().fb.profile;

    try {
      const GetCustomerSubscriptionSub = db
        .collection('users')
        .doc(currentUID)
        .collection('subscriptions')
        .orderBy('created', 'desc')
        .onSnapshot((querySnapshot) => {
          const subscriptionList = [];

          querySnapshot.forEach((doc) => {
            subscriptionList.push({ ...doc.data(), subscriptionId: doc.id });
          });
          const { status, periodStart, currcentPricingPlan, periodEnd, productName } = activatedPlanDetails || {
            status: '',
            periodStart: '',
            currcentPricingPlan: '',
            periodEnd: '',
            productName: '',
          };

          const activePlanIDs = subscriptionList
            .filter(({ status }) => status === 'active' || status === 'trialing')
            .map(({ items }) => {
              return items[0].price.id;
            });

          const currentBilling = {
            currcentPricingPlan,
            currcentProductName: productName,
            trialPlanAcitivated: trialPlanAcitivated,
            firstSubscriptionCharged,
            currentPeriodEnd: periodEnd,
            currentPeriodStart: periodStart,
            currentPlanStatus: status,
          };
          dispatch(subscriptionsReadSuccess(subscriptionList, currentBilling, activePlanIDs));

          // this is used to sync the user profile attributes.
          !currentSubscription &&
            activatedPlanDetails &&
            getInvoiceSynced()
              .then((response) => {
                // console.log('response.data', response.data);
              })
              .catch((error) => {
                console.log(error.response.data);
              });
        });
      allSubscribers.findIndex((x) => x.name === 'GetCustomerSubscriptionSub') === -1 &&
        allSubscribers.push({
          func: GetCustomerSubscriptionSub,
          name: 'GetCustomerSubscriptionSub',
        });
    } catch (err) {
      console.log(`err`, err);
    }
  };
};

// Get Rejected Payment
const GetRecentCustomerPaymentList = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const currentUID = getState().fb.auth.uid;
    // const oldRecentPaymentList = getState().stripe.recentPaymentList;

    try {
      const GetRecentCustomerPaymentList = db
        .collection('users')
        .doc(currentUID)
        .collection('payments')
        .orderBy('created', 'desc')
        .onSnapshot(async (querySnapshot) => {
          await dispatch(recentPaymentProgress());
          const recentPaymentList = [];

          querySnapshot.forEach((doc) => {
            recentPaymentList.push({ ...doc.data(), paymentId: doc.id });
          });
          // const newRecentPaymentList = [...oldRecentPaymentList, ...recentPaymentList];
          await dispatch(recentPaymentSuccess(recentPaymentList));
        });
      allSubscribers.findIndex((x) => x.name === 'GetRecentCustomerPaymentList') === -1 &&
        allSubscribers.push({
          func: GetRecentCustomerPaymentList,
          name: 'GetRecentCustomerPaymentList',
        });
    } catch (err) {
      console.log(`err`, err);
    }
  };
};

// Once a customer is subscribed you should show them a button to access the customer portal to view their invoices and manage their payment & subscription details.
// When the user clicks that button, call the createPortalLink function
// to get a portal link for them, then redirect them.
const RedirectToCustomerPortal = ({ returnUrl, config }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const functionRef = firebase
      .app()
      .functions('us-central1')
      .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
    const { data } = await functionRef({
      returnUrl: returnUrl, /// config ? `${window.location.origin}/team-portal` : `${window.location.origin}/billing`,
      configuration: config,
      //bpc_1JvnWVJfRsQcG5z99TBxoYqj   // show starter and crafter plan in customer portal. // this is for showing users plans
      // bpc_1Jvy41JfRsQcG5z9YfXUnMXs this is for membership plan
    });
    window.location.assign(data.url);
  };
};

export {
  ProductGetData,
  StartSubscriptionWithCheckout,
  GetCustomerSubscription,
  RedirectToCustomerPortal,
  trailPlanSubscription,
  GetRecentCustomerPaymentList,
  noCreditCardRequired,
  noCreditCardCancelPlan,
  trailPlanSubscriptionWithPromoCode,
  AddMemberSubscriptionWithCheckout,
  skipOnboarding,
};

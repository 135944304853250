import styled from 'styled-components';
import { Modal } from 'antd';

const ModalPop = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    padding: 0px !important;
  }
  .ant-modal-close-x {
    display: none;
  }
`;
const PricePopStyled = styled.div`
  display: flex;
  .divya .ant-popover-content {
    background: pink !important;
    .ant-popover-inner {
      padding: 0px !important;
    }
  }
`;

const ComparePriceStyled = styled.div`
  max-width: 274px;
  display: flex;
  padding: 84px 32px;
  background: #f8f9ff;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 120px;
  }
`;

const PopContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 16px 0px 32px;
  .ant-typography {
    font-size: 28px;
    font-family: 'Poppins-Medium';
    margin: 0;
    font-weight: 500;
    padding: 0;
  }
  .pop-text {
    max-width: 196px;
    width: 196px;
    font-size: 16px;
    color: #7f8186;
    font-family: 'Poppins-Medium';
    font-weight: 500;
    margin-top: 4px;
  }
`;

const Popcards = styled.div`
  display: flex;
  .ant-row {
    flex-flow: nowrap !important;
  }
  .popcard {
    cursor: pointer;
    max-width: 232px;
    border: none;
    .ant-card-body {
      padding: 0px !important;
      margin-bottom: 66px;
    }
  }
`;

const PopBottom = styled.div`
  .bottom-text {
    color: #7f8186;
    font-family: 'Poppins-Medium';
    font-weight: 500;
    font-size: 12px;
  }
`;

const PopBottomTop = styled.div`
  display: flex;
  gap: 11.5px;
  margin-top: 16px;
  margin-bottom: 4px;
  .ant-typography {
    margin: 0px;
    font-family: 'Poppins-Medium';
    font-weight: 500;
    font-size: 16px;
  }
`;

const PopWrap = styled.div`
  padding: 32px;
  .pop-bottom {
    display: flex;
    border-top: 1.5px solid #eeeeee;
    padding-top: 24px;
    gap: 20px;

    .ant-card-bordered {
      border: none;
    }

    .ant-card-body {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px !important;
      border-radius: 16px;
    }

    img {
      margin-right: 16px;
    }
  }
`;

const Cardstyled = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  justify-content: space-between;

  .price-title {
    font-family: 'Poppins-Medium';
    font-size: 15px;
    line-height: 21px;
    color: #282b3f;
  }
  .price-description {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    line-height: 18px;
    color: #7f8186;
  }

  h5.ant-typography {
    font-family: 'Poppins-SemiBold';
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #282b3f;
    margin-bottom: 0px;
  }

  .ant-typography + span {
    font-family: 'Poppins-Regular';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #7f8186;
  }

  img {
    margin-right: 0px;
    margin-left: auto;
  }
`;

const PopCardsTop = styled.div``;
export {
  PricePopStyled,
  PopContent,
  Popcards,
  PopBottom,
  ModalPop,
  PopBottomTop,
  PopCardsTop,
  ComparePriceStyled,
  PopWrap,
  Cardstyled,
};

/* ------------------------------------------------------ */
/*                   Data Studio Report                   */
/* ------------------------------------------------------ */

import React from 'react';

const Reports = () => {
  return (
    <div>
      <iframe
        title="reports"
        width={1200}
        height={900}
        src="https://datastudio.google.com/embed/reporting/f8efa062-4d34-4450-a744-aaf51843e24c/page/p_mwxm8r5fvc"
        frameBorder="0"
        style={{ border: 0, borderRadius: '15px' }}
        allowFullscreen
      />
    </div>
  );
};

export default Reports;

/* ------------------------------------------------------ */
/*                     Button Designs                     */
/* ------------------------------------------------------ */

// There are multiple button designs, each design have its own function.

import styled from 'styled-components';
import { Button } from 'antd';
import { BUTTON_BLUE_GRADIENT, BUTTON_YELLOW_GRADIENT } from '../../constants';

const ButtonGroup = Button.Group;

// outline is the button styles.
const outline = (theme, type) => {
  return `
        background: transparent;
        border: 1px solid ${type !== 'light' ? theme[`${type}-color`] : theme['border-color-normal']};
        color: ${type !== 'default' && theme[`${type}-color`]};
  
        &:hover, &:focus {
          background: transparent;
          border: 1px solid ${type !== 'default' && theme[`${type}-hover`]};
          color: ${type !== 'default' && theme[`${type}-hover`]};
        }
    `;
};

// button-design: background is transparent.
const ghosts = (theme, type) => {
  return `
          background: transparent ;
          border: 1px solid ${theme['border-color-normal']} !important;
          color: ${theme['border-color-normal']} !important;
            
          &:hover, &:focus {
              background: #ffffff50 !important;
              border: 1px solid ${theme[`${type}-color`]} !important;
              color: ${theme[`${type}-color`]}  !important;
          }
      `;
};

// transparent is the button prop to add custom button styles.
const transparents = (theme, type) => {
  return `
        background: ${type !== 'default' && theme[`${type}-color`]}15;
        border-width: 0px;
        color: ${type !== 'default' && theme[`${type}-color`]};
        &:hover, &:focus {
            background: ${type !== 'default' && theme[`${type}-hover`]}15;
            border-width: 0px;
            color: ${type !== 'default' && theme[`${type}-hover`]}; 
        }
    `;
};

const raise = (theme, type) => {
  return `
        box-shadow: 0 10px 15px ${type !== 'white' ? theme[`${type}-color`] : '#9299B8'}20;
    `;
};

const square = (theme, type) => `
      background: ${type !== 'default' && theme[`${type}-color`]};
      border: 1px solid ${type !== 'default' ? theme[`${type}-color`] : theme['disabled-color']};
      color: ${type !== 'default' && '#ffffff'};
      border-radius: 0px;
      padding: 0px 15px;
  
      &:hover, &:focus {
          background: ${type !== 'default' && theme[`${type}-hover`]};
          border: 1px solid ${type !== 'default' && theme[`${type}-hover`]};
          color: ${type !== 'default' && '#ffffff'};
      }
  `;

const squareOutline = (theme, type) => `
      background: transparent;
      border: 1px solid ${type !== 'default' ? theme[`${type}-color`] : theme['disabled-color']};
      color: ${type !== 'default' && theme[`${type}-color`]};
      border-radius: 0px;
      padding: 0px 15px;
      &:hover, &:focus {
          background: ${type !== 'default' && theme[`${type}-hover`]};
          border: 1px solid ${type !== 'default' && theme[`${type}-hover`]};
          color: ${type !== 'default' && '#ffffff'};
      }
  `;

const socialButton = (color, shape) => `
      background: ${color};
      background: ${color};
      border: 1px solid ${color};
      color: #ffffff;
      border-radius: ${!shape ? '4px' : '40px'};
      padding: 0px 12px;
      display: inline-flex;
      align-items: center;
      span {
          padding-left: 5px;
      }
      &:hover, &:focus {
          background: ${color}90;
          border: 1px solid ${color}90;
          color: #ffffff;
      }
  `;

const editButtonDesign = (theme, type) => `
  background: linear-gradient(289.65deg, #FEC724 -15.55%, #FFF148 116.77%);
  color: #6C5AFB;
  border-radius: 8px;
  padding: 20px 35px;
  &:hover, &:focus {
    background: ${type !== 'default' && theme[`${type}-hover`]};
    border: 1px solid ${type !== 'default' && theme[`${type}-hover`]};
    color: ${type !== 'default' && '#ffffff'};
  }
  &:disabled {
    background: transparent;
    color:   #8F9095;
    border: 1px solid #E2E4E8;
  } 
  &:disabled:hover {
    background: transparent;
    color:   #8F9095;
  } 
`;

const transparentWithOutlineDesign = (theme, type) => {
  return `
  background: transparent;
  color: #6C5AFB;
  border: 1px solid ${theme[`${type}-color`]};

  &:hover, &:focus {
    background: transparent;
    border: 1px solid ${theme[`${type}-color`]};
    color:  ${theme[`${type}-color`]};  
  }
  &&:disabled {
    background: transparent ;
    color: #AAAAAE;
    border: 1px solid #E2E4E8;
  } 
`;
};

// this is the main Button Styled File.
// it piorities the button designs according to the props.
const ButtonStyled = styled(Button)`
  background: ${({ type, theme }) => type !== 'default' && theme[`${type}-color`]};
  border-width: 0px;
  border-style: ${({ type }) => (type !== 'dashed' ? 'solid' : 'dashed')};
  color: ${({ type }) => {
    if (type === BUTTON_YELLOW_GRADIENT) {
      return '#705bfa';
    } else if (type !== 'default') {
      return '#ffffff';
    } else {
      return '#5A5F7D';
    }
  }};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ shape }) => (!shape ? '8px' : '40px')};
  height: ${({ size, theme }) => (size !== 'default' ? theme[`btn-height-${size}`] : '42px')};
  font-weight: 500;
  box-shadow: 0 0;
  &:hover,
  &:focus {
    background: ${({ type, theme }) => type !== 'default' && theme[`${type}-hover`]};
    color: ${({ type }) => (type !== 'default' ? '#ffffff' : '#5A5F7D')};
  }
  i,
  svg,
  img {
    width: 12px;
    height: 13px;
    + span {
      margin-left: 6px;
    }
  }
  &:disabled {
    background: ${({ type, theme }) => type === BUTTON_BLUE_GRADIENT && '#bcb3fc'};
    color: white;
  }
  &:disabled:hover {
    background: ${({ type, theme }) => type === BUTTON_BLUE_GRADIENT && '#bcb3fc'};
    color: white;
  }

  ${({ transparent, theme, type }) => transparent && transparents(theme, type)};
  ${({ outlined, theme, type }) => outlined && outline(theme, type)};
  ${({ ghost, theme, type }) => ghost && ghosts(theme, type)};
  ${({ raised, theme, type }) => raised && raise(theme, type)};
  ${({ squared, theme, type }) => squared && square(theme, type)};
  ${({ squared, outlined, theme, type }) => squared && outlined && squareOutline(theme, type)};
  ${({ social, color, shape }) => social && socialButton(color, shape)};
  ${({ editButton, theme, type }) => editButton && editButtonDesign(theme, type)};
  ${({ transparentwithoutline, theme, type }) => transparentwithoutline && transparentWithOutlineDesign(theme, type)};
`;

const ButtonStyledGroup = styled(ButtonGroup)`
  > .ant-btn:first-child {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
  }
  button {
    margin: 0px;
    padding: 0 10.75px;
    height: 30px;
    font-size: 12px;
    font-weight: 500;
  }
  .ant-btn-light:hover {
    background: #f4f5f7;
  }
`;

export { ButtonStyled, ButtonStyledGroup };

/* ------------------------------------------------------ */
/*                     Team Onboarding                     */
/* ------------------------------------------------------ */

/**
 *
 * When the user doesnt have any team, and the user wants to create a team then team onboarding steps display.
 * Its a set of questions inorder to create team.
 *
 * The onboarding questions depend on the seats selections question. proceedWithoutSeats
 * if the user doest have any team PLAN or eixisting plan is cancelled, error or expired than the selection of seats will be asked again.
 *
 * Normally for a reqgular user who has a crafter or starter plan, it will proceed without selection of seats.
 *
 * 1. Write Team name
 * 2. Selection of seats
 *
 */

import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Input, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CustomWizardWrapper, CustomStepWizard, CustomStepContent } from './styled';
import { CustomSteps } from '../../components/CustomSteps';
import { addTeamMember, createTeamWithTeamPlan } from '../../redux/AccountSettings/actionCreator';
import { SELECT_NEW_PLAN, TEAM } from '../../constants';
import { GetCustomerSubscription } from '../../redux/Stripe/actionCreator';
import Proration from '../TeamPortal/overview/Proration';
import SeatsQuantity from '../TeamPortal/overview/SeatsQuantity';
import { TEAM_ONBOARDING_TEAM_NAME } from '../../constants/content';

const { Title, Text } = Typography;

/**
 * onSeatQuantity selection of seats quantity
 */
const TeamOnboarding = ({ onSeatQuantity }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    responsive: 0,
  });
  const { responsive } = state;
  const [seatCounts, setSeatCounts] = useState([]);
  const [createTeamDisable, setCreateTeamDisable] = useState(false);
  const { addTeamMemberLoading } = useSelector((state) => state.accountSettings);
  const { companyName, plan, planStatus } = useSelector((state) => state.fb.profile);
  const { subscriptionList } = useSelector((state) => state.stripe);

  // those users who had the team plan or previous plan is cancelled, expired or payment issue
  const proceedWithoutSeats = plan === TEAM || SELECT_NEW_PLAN.includes(planStatus);

  useEffect(() => {
    dispatch(GetCustomerSubscription());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      setState({ responsive: width });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const [step, setStep] = useState({
    status: 'process',
    isFinished: false,
    current: 1,
    teamName: companyName,
  });

  const { status, current, teamName } = step;

  const next = () => {
    setStep({
      ...step,
      status: 'process',
      current: current + 1,
    });
  };

  const prev = () => {
    setStep({
      ...step,
      status: 'process',
      current: current - 1,
    });
  };

  const done = () => {
    setStep({
      ...step,
      status: 'finish',
      isFinished: true,
      current: 0,
    });
  };

  useEffect(() => {
    if (step.status === 'finish') {
      const { teamName } = step;
      if (plan === TEAM) {
        //> if use is already on a team plan.
        dispatch(addTeamMember({ details: { teamName }, createNewTeam: true, seatCounts }));
      } else {
        if (SELECT_NEW_PLAN.includes(planStatus)) {
          // if the user doesnt have an team subscribed and dont have any active plan.
          dispatch(addTeamMember({ details: { teamName }, createNewTeam: true, seatCounts }));
        } else {
          const subID = subscriptionList[0].subscriptionId;
          dispatch(createTeamWithTeamPlan({ subID, teamName, seatCounts }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seatCounts, step]);

  // Create Team name
  const onHandleProfile = (event) => {
    setStep({
      ...step,
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  const isMobile = responsive > 575 ? true : false;

  const onSeatChange = (count) => {
    setSeatCounts(count);
    // onSeatQuantity(count);
  };

  const EnterTeamNameStep = {
    title: 'Step 01',
    description: isMobile && 'Company',
    content: (
      <CustomStepContent>
        <Title className="welcome" level={3}>
          {TEAM_ONBOARDING_TEAM_NAME}
        </Title>
        <div className="question">
          <Row justify="start" align="middle">
            <Col xs={8} sm={5}>
              <Text strong className="label-onboarding">
                Team
              </Text>
            </Col>
            <Col xs={16} sm={19}>
              <Input
                autoFocus
                size="large"
                className="name-onboarding-input"
                value={teamName}
                onChange={onHandleProfile}
                name="teamName"
                placeholder="Enter team name"
              />
            </Col>
          </Row>
        </div>
      </CustomStepContent>
    ),
  };

  const stepsWithSeats = [
    EnterTeamNameStep,
    {
      title: 'Step 02',
      description: isMobile && 'Workspace',
      content: (
        <CustomStepContent>
          <Title className="welcome" level={3}>
            How many team seats would you like to add?{' '}
          </Title>
          <div className="options">
            <Row gutter={15} className="CardRow">
              <SeatsQuantity onSeatChange={onSeatChange} />
              <Text className="member-charges"> (save 30% on each additional seat) </Text>
            </Row>
          </div>
          <br />
          <Proration createTeamView={true} seatCount={seatCounts} disableCreateTeamButton={setCreateTeamDisable} />
        </CustomStepContent>
      ),
    },
  ];

  const stepsWithOutSeats = [EnterTeamNameStep];
  const validationWithSeats = () => {
    if (proceedWithoutSeats) {
      return [
        {
          step: 0,
          stepError: 'Write team name',
          value: teamName,
        },
      ];
    } else {
      return [
        {
          step: 0,
          stepError: 'Write team name',
          value: teamName,
        },
        {
          step: 1,
          stepError: 'Select Seats',
          value: !createTeamDisable && [seatCounts],
        },
      ];
    }
  };
  return (
    <CustomWizardWrapper>
      <CustomStepWizard>
        <CustomSteps
          teamOnboarding={true}
          isvertical={isMobile && true}
          current={0}
          status={status}
          direction={isMobile && 'vertical'}
          lastButton="Create Team"
          lastButtonLoading={addTeamMemberLoading}
          validation={validationWithSeats()}
          steps={proceedWithoutSeats ? stepsWithOutSeats : stepsWithSeats}
          onNext={next}
          onPrev={prev}
          onDone={done}
        />
      </CustomStepWizard>
    </CustomWizardWrapper>
  );
};

export default TeamOnboarding;

/* ------------------------------------------------------ */
/*                       MAIN ROUTES                      */
/* ------------------------------------------------------ */

/**
 * The all main routes for craftly admin and users
 */

import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getFirestore } from 'redux-firestore';
import BotModerator from '../container/BotModerator';
import PlanBilling from '../container/PlanBilling';
import UserManagement from '../container/UserManagement';
import UserDetails from '../container/UserManagement/overview/UserDetails';
import ErrorBoard from '../container/ErrorBoard';
import ErrorPage from '../container/ErrorPage';
import UpdateTool from '../container/ToolManagement/UpdateTool';
import ToolStatus from '../container/ToolStatus';
import AddNewTool from '../container/ToolManagement/AddNewTool';
import TeamPortal from '../container/TeamPortal/TeamPortal';
import MemberSummary from '../container/TeamPortal/overview/MemberSummary';
import ToolsCheck from '../container/ToolManagement/ToolsCheck';
import TeamChatApp from '../container/TeamChat/TeamChatApp';
import TeamResults from '../container/TeamPortal/overview/TeamResults';
import { FirebaseAnalytics } from '../config/firebase';
import TeamMembersPage from '../container/TeamPortal/overview/TeamMembersPage';
import TeamManagement from '../container/TeamManagement';
import GroupFields from '../container/ToolManagement/GroupFields';
import AdminTopBanner from '../container/AdminTopBanner';
import Editor from '../container/Editor';
import { checkSegmentConfig, getUTCDate } from '../utility/utility';
import { ROLES_PRIVATE, ROLES_PUBLIC, STARTER } from '../constants';
import Reports from '../container/Reports/Reports';
import DuplicateCards from '../container/DuplicateCards';
import Home from '../container/Home';
import ToolDesign from '../container/ToolDesign';
import LongForm from '../container/LongForm';
import Settings from '../container/Settings';
import Earn from '../container/Earn';
import Favorite from '../container/Favourite';
import ToolCategories from '../container/ToolManagement/ToolCategories';
import Teams from '../container/Teams/Teams';
import Documents from '../container/Documents';
import Recent from '../container/Recent';

// this component calls when there is no route to match. user inputs an incorrect url
const NotFoundPage = () => {
  return <Navigate to="/" />;
};
const SettingsLink = () => {
  return <Navigate to="/settings/edit-profile" />;
};

// This is the Main Routes for the authenticated users. When user successfully logins then only these routes will be accessible.
const MainRoutes = () => {
  const db = getFirestore();

  const {
    email,
    name,
    lastName,
    firstName,
    roles,
    displayPlanStatus,
    plan,
    activatedPlanDetails,
    settingsEmailPaidInvoice,
    planStatus,
  } = useSelector((state) => state.fb.profile);

  const { uid } = useSelector((state) => state.fb.auth);
  const { totalWordsCraft } = useSelector((state) => state.billing);

  const { productName, planInterval, trialStarts, trialEnds, periodStart, expiringDate, periodEnd } =
    activatedPlanDetails || {};

  const { readableDate: readableTrialStartDate, typeDateFormat: trialStartDateFormat } = getUTCDate(trialStarts);
  const { readableDate: readableTrialEndDate, typeDateFormat: trialEndDateFormat } = getUTCDate(trialEnds);

  const trialDuration =
    readableTrialStartDate && readableTrialEndDate
      ? Math.floor(moment.duration(trialEndDateFormat.diff(trialStartDateFormat)).asDays())
      : '-';

  const trialDurationExtended = trialDuration ? (trialDuration > 5 ? true : false) : undefined;

  useEffect(() => {
    if (email) {
      FirebaseAnalytics.setUserProperties({
        name,
        email,
        lastName,
        firstName,
        userId: uid,
        status: displayPlanStatus,
        plan,
        roles,
        productName,
        planInterval,
        trialStarts,
        trialEnds,
        periodStart,
        periodEnd,
        trialDuration,
        craftWords: totalWordsCraft,
        expiringDate,
        planStatus,
      });
      // FirebaseAnalytics.logEvent('screen_view', {
      //   firebase_screen: pathname,
      //   firebase_screen_class: pathname,
      //   email,
      //   name,
      //   plan,
      //   planStatus,
      //   uid,
      // });
      if (checkSegmentConfig()) {
        window.analytics.identify(uid, {
          name,
          email,
          lastName,
          firstName,
          userId: uid,
          status: displayPlanStatus,
          plan,
          roles,
          productName,
          planInterval,
          trialStarts,
          trialEnds,
          periodStart,
          periodEnd,
          trialDuration,
          trialDurationExtended,
          craftWords: plan === STARTER ? totalWordsCraft : undefined,
          expiringDate,
          planStatus,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    db,
    displayPlanStatus,
    email,
    name,
    periodEnd,
    periodStart,
    expiringDate,
    plan,
    planInterval,
    productName,
    roles,
    trialDuration,
    trialDurationExtended,
    trialEnds,
    trialStarts,
    uid,
    totalWordsCraft,
    lastName,
    settingsEmailPaidInvoice,
    firstName,
    planStatus,
  ]);

  return (
    <>
      {ROLES_PRIVATE.includes(roles) && (
        <Routes>
          <Route index element={<Home />} />
          <Route exact path={`/category`} element={<Home />} />
          <Route path={`/category/:categoryId`} element={<Home />} />
          <Route exact path={`/teams`} element={<Teams />} />
          <Route path={`/tool/:toolId`} element={<ToolDesign />} />
          <Route path={`/tool-categories`} element={<ToolCategories />} />
          <Route exact path={`/long-form`} element={<LongForm />} />
          <Route exact path="/settings" element={<SettingsLink />} />
          <Route exact path={`/earn`} element={<Earn />} />
          <Route exact path={`/favourite`} element={<Favorite />} />
          <Route exact path={`/recent`} element={<Recent />} />
          <Route exact path={`/settings/edit-profile`} element={<Settings />} />
          <Route exact path={`/settings/password`} element={<Settings />} />
          <Route exact path={`/settings/notifications`} element={<Settings />} />
          <Route exact path={`/settings/billing`} element={<Settings />} />
          <Route exact path={`/settings/language`} element={<Settings />} />
          <Route exact path={`/settings/account`} element={<Settings />} />
          <Route exact path={`/settings/workspace`} element={<Settings />} />
          <Route exact path={`/error-board`} element={<ErrorBoard />} />
          <Route exact path={`/user-details`} element={<UserDetails />} />
          <Route path={`/reports`} element={<Reports />} />
          <Route exact path={`/user-management`} element={<UserManagement />} />
          <Route exact path={`/user-management/:uid`} element={<UserManagement />} />
          <Route exact path={`/tool-status`} element={<ToolStatus />} />
          <Route exact path={`/tools-check`} element={<ToolsCheck />} />
          <Route exact path={`/update-tool`} element={<UpdateTool />} />
          <Route exact path={`/add-new-tool`} element={<AddNewTool />} />
          <Route exact path={`/fields-group`} element={<GroupFields />} />
          <Route exact path={`/plan-billing`} element={<PlanBilling />} />
          <Route path={`/content-moderator`} element={<BotModerator />} />
          <Route path={`/content-moderator/*`} element={<BotModerator />} />
          <Route path={`/top-banner`} element={<AdminTopBanner />} />
          <Route exact path={`/team-management`} element={<TeamManagement />} />
          <Route exact path={`/error-page`} element={<ErrorPage />} />
          <Route exact path={`/team-members`} element={<TeamMembersPage />} />
          <Route exact path={`/team-portal`} element={<TeamPortal />} />
          <Route exact path={`/team-portal/:uid`} element={<TeamPortal />} />
          <Route exact path={`/team-portal/team-management`} element={<TeamPortal />} />
          <Route exact path={`/team-member-details`} element={<MemberSummary />} />
          <Route exact path={`/team-results`} element={<TeamResults />} />
          <Route path={`/team-chat`} element={<TeamChatApp />} />
          <Route path={`/editor`} element={<Editor />} />
          <Route exact path={`/duplicate-cards`} element={<DuplicateCards />} />
          <Route exact path={`/documents`} element={<Documents />} />
          <Route exact path="*" element={<NotFoundPage />} />
        </Routes>
      )}
      {ROLES_PUBLIC.includes(roles) && (
        <Routes>
          <Route exact path={`/category/:categoryId`} element={<Home />} />
          <Route exact path={`/category`} element={<Home />} />
          <Route exact path={`/teams`} element={<Teams />} />
          <Route path={`/tool/:toolId`} element={<ToolDesign />} />
          <Route exact path={`/long-form`} element={<LongForm />} />
          <Route exact path="/settings" element={<SettingsLink />} />
          <Route exact path={`/earn`} element={<Earn />} />
          <Route exact path={`/favourite`} element={<Favorite />} />
          <Route exact path={`/recent`} element={<Recent />} />
          <Route exact path={`/settings/edit-profile`} element={<Settings />} />
          <Route exact path={`/settings/password`} element={<Settings />} />
          <Route exact path={`/settings/notifications`} element={<Settings />} />
          <Route exact path={`/settings/billing`} element={<Settings />} />
          <Route exact path={`/settings/language`} element={<Settings />} />
          <Route exact path={`/settings/account`} element={<Settings />} />
          <Route exact path={`/settings/workspace`} element={<Settings />} />
          <Route exact path={`/error-page`} element={<ErrorPage />} />
          <Route exact path={`/team-members`} element={<TeamMembersPage />} />
          <Route exact path={`/team-portal`} element={<TeamPortal />} />
          <Route exact path={`/team-portal/:uid`} element={<TeamPortal />} />
          <Route exact path={`/team-portal/team-management`} element={<TeamPortal />} />
          <Route exact path={`/team-member-details`} element={<MemberSummary />} />
          <Route exact path={`/team-results`} element={<TeamResults />} />
          <Route path={`/team-chat`} element={<TeamChatApp />} />
          <Route path={`/editor`} element={<Editor />} />
          <Route exact path={`/duplicate-cards`} component={<DuplicateCards />} />
          <Route exact path={`/documents`} element={<Documents />} />
          <Route exact path="*" element={<NotFoundPage />} />
        </Routes>
      )}
    </>
  );
};

export default MainRoutes;

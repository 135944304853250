import moment from 'moment';
import { addNotificationInfo } from '../components/utilities/notifications';
import {
  ALLOW_TOOLS_PLAN_STATUS,
  CRAFTER,
  TEAM,
  INCOMPELTE_PLAN,
  NO_PLAN_SUBSCRIPED,
  NO_PLAN_SUBSCRIPED_MESSAGE,
  NO_TRIAL_CC,
  PERSONAL_WORKSPACE,
  PLAN_CANCELED,
  PLAN_PAST_DUE,
  PLAN_STATUS,
  STARTER,
  CRAFT_WORDS_EXCEEDED_MESSAGE,
  TEAM_MEMBER_CANT_CRAFT,
  TEAM_WORKSPACE,
  TRIAL,
  TRIAL_CRAFT_EXPIRE_MESSAGE,
  TRIAL_CRAFT_NO_HITS_LEFT_MESSAGE,
  TRIAL_EXPIRED,
  TEAM_MEMBER_ON_HOLD,
  TEAM_MEMBER_CHECK_IN_TEAM,
  BILLING_SCHEME_TIERED,
  isUserRole,
  isAdminRole,
  isStaffRole,
  isFreeUserRole,
} from '../constants';
import { PLEASE_CREATE_PROJECT, PLEASE_SELECT_PROJECT, SOME_THING_WENT_WRONG } from '../constants/content';
import { convertTimeStampToDate, convertTimeStampToDateTime } from './utility';

// when a user skips the onboarding billing, we manually set Trial for 5 days
// it calculates the remaining time
export const noTrialccRemaingTime = (noTrialccPlanAcitivated) => {
  if (noTrialccPlanAcitivated) {
    const readable = convertTimeStampToDateTime(noTrialccPlanAcitivated);
    const userTime = moment(readable).toDate(); // new Date(readable.replace(/-/g, '/'));
    const trialDateEnds = moment(userTime).add(5, 'day').format('MMM DD, YYYY');
    const trialDateEndsWithYearFormat = moment(userTime).add(5, 'day').format('YYYY-MM-DD');
    const seconds = Math.floor((new Date() - userTime) / 1000);
    const daysRemaining = 5 - Math.floor(seconds / 86400);

    const daysDisplay = 5 - Math.floor(seconds / 86400);
    const daysRemaingWithZero = daysDisplay === 0 ? 'Last Day' : `${daysDisplay} Days`;
    const hoursRemaining = 120 - Math.floor(seconds / 3600);
    const mins = seconds / 60;
    const isTrialExpired = daysRemaining > -1 ? false : true;

    return {
      daysRemaingWithZero,
      hoursRemaining,
      mins,
      trialExpired: isTrialExpired,
      trialDateEnds,
      trialDateEndsWithYearFormat,
    };
  } else {
    return null;
  }
};

export const formatBillingDate = (date) => {
  return moment(date).format('MMM DD, YYYY');
};

export const getCarftWords = (userBots) => {
  const usersContent = [...new Set(userBots.map((item) => item.output && item.output.content))].filter(Boolean);
  let calculateTotalWords = 0;
  usersContent &&
    Array.isArray(usersContent) &&
    usersContent.forEach((content) => {
      const len =
        content &&
        content.reduce(function (acc, obj) {
          var wordCount = (obj?.contentData && obj?.contentData?.match(/(\w+)/g)?.length) || 0;
          return acc + wordCount;
        }, 0);
      calculateTotalWords = calculateTotalWords + len;
    });
  return calculateTotalWords;
};

export const getCarftWordsFromSentence = (sentence) => {
  return (sentence && sentence?.match(/(\w+)/g)?.length) || 0;
};

// this is the complete check user billing plan
export const checkUserBillingPlan = ({
  adminBillingSettings,
  userAllowToCraft,
  userCurrentPlan,
  todaysTotalHits,
  currentBilling,
  userBots,
  planStatus,
  noTrialccPlanAcitivated,
  selectedWorkspace,
  memberTeamsWithOutHold,
  lastCraftDate,
  activatedPlanDetails,
  totalWordsCraft,
}) => {
  let notAllowedMessage = '';
  let userCanCraft = false;
  let limit = 0;
  const { craft, code } = userAllowToCraft;
  const { canTeamMemberCraft, checkHoldAccount, checkUserIsStillInTeam, workspaceType } = selectedWorkspace || {};
  // totalWordsCraft is the object present in users-bots. words calculation is in cloud-functions now.
  let userTotalwords = totalWordsCraft;

  // for V2 quantity is already mentioned in pricing tiered model.
  const { quantity, billingScheme } = activatedPlanDetails || { quantity: null, billingScheme: null }; // Billing scheme is the pricing method. for v2 it is tiered method.

  try {
    // this direct check whether user is subscriped to any plan or not, here checking the trial and NA plan
    // PLAN STATUS should be either active or expiring
    if (workspaceType === PERSONAL_WORKSPACE) {
      if (craft && ALLOW_TOOLS_PLAN_STATUS.includes(planStatus)) {
        // TRIAL billing checking
        if (userCurrentPlan === TRIAL) {
          //fetching the admin trial details
          const adminPlan = adminBillingSettings.find(({ plan }) => plan === userCurrentPlan);
          const { totalTrialHits } = adminPlan.details;
          //? Calculating the Hit count.
          const date = moment().format('YYYY-MM-DD');

          if (todaysTotalHits < totalTrialHits || lastCraftDate !== date) {
            userCanCraft = true;
          } else {
            userCanCraft = false;
            notAllowedMessage = TRIAL_CRAFT_NO_HITS_LEFT_MESSAGE;
            limit = totalTrialHits;
          }
        }

        // STARTER billing checking
        if (userCurrentPlan === STARTER) {
          const adminPlan = adminBillingSettings.find(({ plan }) => plan === userCurrentPlan);

          const { totalWords } = adminPlan.details;
          let checkTotalWordsLimit = totalWords;

          if (billingScheme && billingScheme === BILLING_SCHEME_TIERED) {
            checkTotalWordsLimit = quantity;
          }

          if (userTotalwords === undefined) {
            const { currentPeriodEnd, currentPeriodStart } = currentBilling;

            // this is manual way of finding total number of words.
            const filterBots = userBots.filter(({ date, plan }) => {
              return plan
                ? moment(date).isBetween(moment(currentPeriodStart), moment(currentPeriodEnd), null, '[]') &&
                    plan === STARTER
                : moment(date).isBetween(moment(currentPeriodStart), moment(currentPeriodEnd), null, '[]');
            });
            //? Calculating manual words count.
            userTotalwords = getCarftWords(filterBots);
          }

          // console.log('totalUserCraftWords', totalUserCraftWords, totalWords);
          if (userTotalwords < checkTotalWordsLimit) {
            userCanCraft = true;
          } else {
            userCanCraft = false;
            notAllowedMessage = CRAFT_WORDS_EXCEEDED_MESSAGE;
            limit = checkTotalWordsLimit;
          }
        }
        // Crafter is totally free for v1 version.
        if (userCurrentPlan === CRAFTER && billingScheme !== BILLING_SCHEME_TIERED) {
          userCanCraft = true;
        }

        //? Crafter in V2, words count matter due to tiered method.
        if (userCurrentPlan === CRAFTER && billingScheme === BILLING_SCHEME_TIERED) {
          let checkTotalWordsLimit = quantity;

          if (userTotalwords < checkTotalWordsLimit) {
            userCanCraft = true;
          } else {
            userCanCraft = false;
            notAllowedMessage = CRAFT_WORDS_EXCEEDED_MESSAGE;
            limit = checkTotalWordsLimit;
          }
        }

        // TEAM is totally free
        if (userCurrentPlan === TEAM) {
          userCanCraft = true;
        }
      } else if (userCurrentPlan === NO_TRIAL_CC) {
        // NO_TRIALL_CREDIT_CARD Feature

        //fetching the admin trial details
        const adminPlan = adminBillingSettings.find(({ plan }) => plan === userCurrentPlan);
        const { totalTrialHits } = adminPlan.details;
        const { trialExpired } = noTrialccRemaingTime(noTrialccPlanAcitivated);
        //> If Trial expires.
        if (!trialExpired) {
          if (todaysTotalHits < totalTrialHits) {
            userCanCraft = true;
          } else {
            userCanCraft = false;
            notAllowedMessage = TRIAL_CRAFT_NO_HITS_LEFT_MESSAGE;
            limit = totalTrialHits;
          }
        } else {
          userCanCraft = false;
          notAllowedMessage = TRIAL_CRAFT_EXPIRE_MESSAGE;
        }
      } else {
        // different stripe plan status.
        if (planStatus !== PLAN_STATUS.ACTIVE) {
          userCanCraft = false;
          notAllowedMessage = PLAN_CANCELED;
        }
        if (code === TRIAL_EXPIRED) {
          userCanCraft = false;
          notAllowedMessage = TRIAL_CRAFT_EXPIRE_MESSAGE;
        }
        if (code === NO_PLAN_SUBSCRIPED) {
          userCanCraft = false;
          notAllowedMessage = NO_PLAN_SUBSCRIPED_MESSAGE;
        }
        if (planStatus === PLAN_STATUS.PAST_DUE) {
          userCanCraft = false;
          notAllowedMessage = PLAN_PAST_DUE;
        }
      }
      // its allow the user to craft on personal workspace as a team member is a part of active team.
      if (memberTeamsWithOutHold && memberTeamsWithOutHold.length > 0) {
        userCanCraft = true;
        notAllowedMessage = '';
      }
    }

    // For team workspace, a team member can craft without any plan subscription.
    if (workspaceType === TEAM_WORKSPACE) {
      if (canTeamMemberCraft) {
        userCanCraft = true;
      } else if (checkHoldAccount) {
        userCanCraft = false;
        notAllowedMessage = TEAM_MEMBER_ON_HOLD;
      } else if (!checkUserIsStillInTeam) {
        userCanCraft = false;
        notAllowedMessage = TEAM_MEMBER_CHECK_IN_TEAM;
      } else {
        userCanCraft = false;
        notAllowedMessage = TEAM_MEMBER_CANT_CRAFT;
      }
    }
  } catch (error) {
    console.log(`error`, error);
  }
  return { notAllowedMessage, userCanCraft, limit };
};

export const remainingWordsCount = ({
  adminBillingSettings,
  userCurrentPlan,
  activatedPlanDetails,
  totalWordsCraft,
}) => {
  const { quantity, billingScheme } = activatedPlanDetails || { quantity: null, billingScheme: null }; //! Billing scheme is the pricing method. for v2 it is tiered method.
  if (quantity && billingScheme && billingScheme === BILLING_SCHEME_TIERED && userCurrentPlan !== TEAM) {
    let checkTotalWordsLimit = 0;
    checkTotalWordsLimit = quantity;
    const remainingWordsCount = checkTotalWordsLimit - totalWordsCraft;
    const twentyFiveHundred = remainingWordsCount <= 2500;
    return { remainingWordsCount, twentyFiveHundred };
  } else {
    return { remainingWordsCount: null, twentyFiveHundred: null };
  }
};

export const extractStripeInfo = (subscriptionList) => {
  const info =
    subscriptionList &&
    subscriptionList.map(
      ({
        status,
        stripeLink,
        items,
        current_period_start,
        cancel_at_period_end,
        canceled_at,
        cancel_at,
        current_period_end,
        ended_at,
      }) => {
        const productName = items && items[0].price.product.name;
        const interval = items && items[0].price.recurring.interval;
        const priceAmount = items && items[0].price.unit_amount;

        const periodStartTimeStamp = current_period_start;
        const periodEndTimeStamp = current_period_end;
        const periodStart = periodStartTimeStamp && convertTimeStampToDate(periodStartTimeStamp);
        const periodEnd = periodEndTimeStamp && convertTimeStampToDate(periodEndTimeStamp);
        const expiringDate = cancel_at && convertTimeStampToDate(cancel_at);
        const expiredDate = canceled_at && convertTimeStampToDate(canceled_at);
        const planEndedCanceled = ended_at && convertTimeStampToDate(ended_at);
        const showStartEndDate = !INCOMPELTE_PLAN.includes(status) ? true : false;

        return {
          productName,
          periodStart,
          periodEnd,
          status,
          isCanceled: cancel_at_period_end,
          expiringDate,
          planInterval: interval,
          subscriptionLink: stripeLink,
          showStartEndDate,
          priceAmount,
          expiredDate,
          planEndedCanceled,
        };
      },
    );
  return info;
};

// it check whether the user can use tool or not
export const verifyBilling = ({
  adminBillingSettings,
  userAllowToCraft,
  userCurrentPlan: plan,
  todaysTotalHits,
  currentBilling,
  userBots,
  planStatus,
  noTrialccPlanAcitivated,
  selectedWorkspace,
  memberTeamsWithOutHold,
  lastCraftDate,
  activatedPlanDetails,
  totalWordsCraft,
  recentProjectId,
  roles,
}) => {
  const { userCanCraft, notAllowedMessage, limit } = checkUserBillingPlan({
    adminBillingSettings,
    userAllowToCraft,
    userCurrentPlan: plan,
    todaysTotalHits,
    currentBilling,
    userBots,
    planStatus,
    noTrialccPlanAcitivated,
    selectedWorkspace,
    memberTeamsWithOutHold,
    lastCraftDate,
    activatedPlanDetails,
    totalWordsCraft,
  });
  let verify = false;
  if (roles && isUserRole(roles)) {
    // this enable the user to craft or not.
    if (userCanCraft) {
      // Here is the action For hitting the tool api and fetch the results   // TOOL-GET-API
      if (recentProjectId) {
        // First we create a doc in users-bol collection and then we send the request to backend.
        verify = true;
      } else {
        if (selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
          addNotificationInfo(PLEASE_SELECT_PROJECT);
        } else if (selectedWorkspace.workspaceType === PERSONAL_WORKSPACE) {
          addNotificationInfo(PLEASE_CREATE_PROJECT);
        } else {
          addNotificationInfo(SOME_THING_WENT_WRONG);
        }
      }
    } else {
      verify = { notAllowedMessage, limit };
    }
  }
  // Admin role is always run without any billing checks in Personal workspace.
  if (isAdminRole(roles)) {
    // on team workspace all the security checks are enabled.
    if (selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
      if (userCanCraft) {
        verify = true;
      } else {
        verify = { notAllowedMessage, limit };
      }
    } else {
      verify = true;
    }
  }
  if (isStaffRole(roles) || isFreeUserRole(roles)) {
    verify = true;
  }
  return verify;
};

/* ------------------------------------------------------ */
/*                JOIN TEAM FOR TEAM MEMBER               */
/* ------------------------------------------------------ */
/**
 * If the user is invited to any team, after the signup this page will open.
 * The team member has the option to accept the team request.
 *
 * if the team member has 1 invite and the user decline it so it will be redirected to trial page for billing
 * Billing is not applicable for team members
 *
 * all the premium features are accessible for team members
 * team members can also use their personal workspace just because theya are a part of active team
 *
 * If any team's invitation is not accepted and on clicking the continue it will be redictect to trial page
 *
 */
import React, { useEffect, useState } from 'react';
import { Divider, Typography, Row, Col, Spin, Tooltip } from 'antd';
import { JoinTeamWrapper } from './style';
import { useNavigate } from 'react-router-dom';
import Signout from '../../components/Signout';
import { useSelector, useDispatch } from 'react-redux';
import { CheckOutlined, CloseOutlined, UndoOutlined } from '@ant-design/icons';
import { checkUserPresentInTeam, updateTeamJoinAction } from '../../redux/AccountSettings/actionCreator';
import { capitalizeFirstLetter } from '../../utility/utility';
import { Button } from '../../components/Button';
import { TEAM_STATUS } from '../../constants';
import { skipOnboarding } from '../../redux/Stripe/actionCreator';

const { Text, Title, Paragraph } = Typography;

const JoinTeam = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [displayError, setDisplayError] = useState(false);
  const { usersTeams, teamInviteActionLoading } = useSelector((state) => state.accountSettings);

  useEffect(() => {
    dispatch(checkUserPresentInTeam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // To show all the team pending invites.
  const teamPending = ({ teamAdminUID, memberEmail }) => {
    setDisplayError(false);
    dispatch(updateTeamJoinAction({ action: TEAM_STATUS.PENDING, teamAdminUID, memberEmail }));
  };

  // To delcine the team request
  const teamDecline = ({ teamAdminUID, memberEmail }) => {
    setDisplayError(false);
    // this is generic function. action denotes the action for the team member.
    dispatch(updateTeamJoinAction({ action: TEAM_STATUS.DECLINE, teamAdminUID, memberEmail }));
  };
  const teamAccept = ({ teamAdminUID, memberEmail, adminUID, teamName }) => {
    setDisplayError(false);
    dispatch(
      updateTeamJoinAction({
        action: TEAM_STATUS.ACCEPTED,
        teamAdminUID,
        memberEmail,
        selectedUID: adminUID,
        selectedName: teamName,
      }),
    );
  };

  // This check the conditions to what to do next
  const onContinue = () => {
    if (usersTeams && Array.isArray(usersTeams)) {
      const getPending = usersTeams.filter(({ teamInviteStatus }) => teamInviteStatus === TEAM_STATUS.PENDING);
      const displayError = getPending && getPending.length > 0 ? true : false;
      if (!displayError) {
        const checkAllDecline = usersTeams.filter(
          ({ teamInviteStatus }) => teamInviteStatus === TEAM_STATUS.DECLINE,
        ).length;
        if (checkAllDecline === usersTeams.length) {
          // if user didnt accept any condition then it will be move to trial - paid page.
          navigate('./trial');
        } else {
          // if the user select any team plan, the team member will skip onboarding and move to team portal page.
          dispatch(skipOnboarding());
        }
      } else setDisplayError(displayError);
    }
  };

  return (
    <JoinTeamWrapper>
      <Row justify="space-between">
        <Col xs={6} sm={3} md={2}>
          <img
            className="logo"
            style={{ width: '100%', margin: '2rem' }}
            src={require('../../static/general/craftly_final.png')}
            alt="logo"
          />
        </Col>
        <Col>
          <div style={{ margin: '2rem' }}>
            <Signout />
          </div>
        </Col>
      </Row>

      <div className="box-wrapper">
        <div className="verify-box">
          <Text className="early-text">Welcome to the Craftly.AI</Text>
          <Title level={3} className="invited-text">
            You’ve Been Invited!
          </Title>
          <br></br>
          <Text className="text-notice">75+ Tools - Unlimited Content Creations - Team Collaboration</Text>

          <Divider />
          <div className="points">
            <div className="plan-points">
              <Paragraph className="subtitle">
                <CheckOutlined className="icon" />
                Shared Content Creations
              </Paragraph>
              <Paragraph className="subtitle">
                <CheckOutlined className="icon" />
                Team Dashboard{' '}
              </Paragraph>
              <Paragraph className="subtitle">
                <CheckOutlined className="icon" />
                Collaborative Workspace{' '}
              </Paragraph>
              <Paragraph className="subtitle">
                <CheckOutlined className="icon" />
                Member Activity Monitoring{' '}
              </Paragraph>
              <Paragraph className="subtitle">
                <CheckOutlined className="icon" />
                Multiple Projects{' '}
              </Paragraph>
            </div>
          </div>

          <Divider />

          <Text>Pending Invites </Text>
          <Spin spinning={teamInviteActionLoading}>
            <Row gutter={16} align="middle" justify="center">
              {usersTeams &&
                Array.isArray(usersTeams) &&
                usersTeams.map(
                  ({ teamAdminUID, teamMemberUID, adminUID, teamName, teamInviteStatus, teamMemberEmail }) => {
                    return (
                      <Col xs={24} lg={15}>
                        <div className="count-box">
                          <Text>{capitalizeFirstLetter(teamName)}</Text>
                          {teamInviteStatus === TEAM_STATUS.PENDING && (
                            <>
                              <Button
                                type="light-purple"
                                onClick={() =>
                                  teamAccept({
                                    adminUID,
                                    teamName,
                                    teamAdminUID: adminUID,
                                    memberEmail: teamMemberEmail,
                                  })
                                }
                              >
                                Accept
                              </Button>
                              <Button
                                type="light"
                                onClick={() => teamDecline({ teamAdminUID: adminUID, memberEmail: teamMemberEmail })}
                              >
                                Decline
                              </Button>
                            </>
                          )}

                          {teamInviteStatus === TEAM_STATUS.DECLINE && (
                            <Text className="accepted">
                              <CloseOutlined className="icon" /> Invite Declined{' '}
                              <Tooltip title="undo">
                                <Button
                                  type="info"
                                  size="small"
                                  shape="circle"
                                  icon={<UndoOutlined />}
                                  className="undo"
                                  onClick={() => teamPending({ teamAdminUID: adminUID, memberEmail: teamMemberEmail })}
                                />
                              </Tooltip>
                            </Text>
                          )}
                          {teamInviteStatus === TEAM_STATUS.ACCEPTED && (
                            <Text className="accepted">
                              <CheckOutlined className="icon" /> Invite Accepted{' '}
                              <Tooltip title="undo">
                                <Button
                                  type="info"
                                  size="small"
                                  shape="circle"
                                  icon={<UndoOutlined />}
                                  className="undo"
                                  onClick={() => teamPending({ teamAdminUID: adminUID, memberEmail: teamMemberEmail })}
                                />
                              </Tooltip>
                            </Text>
                          )}
                        </div>
                      </Col>
                    );
                  },
                )}
            </Row>
          </Spin>
          {displayError && <Text type="danger">Please accept or decline of all team(s) request</Text>}
          <br />
          <Button type="primary" onClick={onContinue}>
            Continue
          </Button>
          {/* <div className="note-text">
            <Text className="text-notice">
              Every time a friend clicks your link, and then signs up, you get even closer to receiving early access.
              For affiliates please message us at <Link> hello@craftly.ai!</Link>
            </Text>
          </div> */}
        </div>
      </div>
    </JoinTeamWrapper>
  );
};

export default JoinTeam;

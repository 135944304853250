/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import { Checkbox, DatePicker, Typography } from 'antd';
import React from 'react';
import { CraftedContentWrap, CraftedWrap, LabelsWrap } from './styled';

const { Title } = Typography;

const CraftedContent = () => {
  return (
    <CraftedContentWrap>
      <Title level={5}>Crafted Content</Title>
      <CraftedWrap>
        <DatePicker />
        <LabelsWrap>
          <Checkbox>Favourites</Checkbox>
          <Checkbox>Copied</Checkbox>
        </LabelsWrap>
      </CraftedWrap>
    </CraftedContentWrap>
  );
};

export default CraftedContent;

/* ------------------------------------------------------ */
/*                        Favourite                       */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * It shows the tool output favourite,
 * The fav outputs are directly dependable to the workspace and project selection
 *
 * Every tool craft have a recentProjectId object. it goes with the selection project id
 * It obtains the preferred output that they hasFavorites object are true
 *
 * Firestore
 * Collection: users-bot
 * Document : UID
 * SubCollection: crafts
 * subDocument: BotID  - means craft or outputID
 *
 * allFavoritesPage have the favourite outputs only which have hasFavourite object true
 *
 */

import React, { useState, useEffect } from 'react';
import FavoriteCards from './overview/FavoriteCards';
import FavouriteHeader from './overview/FavouriteHeader';
import { useDispatch, useSelector } from 'react-redux';
import { FavoriteContainer } from './styled';
import { TEAM_WORKSPACE } from '../../constants';
import { fetchFavProjectsPage } from '../../redux/UserProject/actionCreator';
import PageHeader from '../../components/PageHeader';

const Favorite = () => {
  const dispatch = useDispatch();
  const [dateRangeFav, setDateRange] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState();
  const [displayAllFavorites, setDisplayAllFavorites] = useState([]);
  const { selectedWorkspace } = useSelector((state) => state.accountSettings);
  const { teamRecentProject, recentProject, allFavoritesPage, allFavoritesPageLoading } = useSelector(
    (state) => state.userProject,
  );

  let recentProjectId = recentProject.projectId;

  // Recent project is required.
  if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
    const { allTeamRecentProject } = teamRecentProject || {};
    const { selectedUID } = selectedWorkspace || {};
    const teamRecentProjectId =
      teamRecentProject && allTeamRecentProject[selectedUID] && allTeamRecentProject[selectedUID].projectId;
    recentProjectId = teamRecentProjectId;
  }

  useEffect(() => {
    recentProjectId && dateRangeFav && Array.isArray(dateRangeFav) && dateRangeFav.length > 0 && fetchFav();
  }, [recentProjectId, dateRangeFav]);

  const fetchFav = () => {
    // it fetch the output according to data range
    dispatch(fetchFavProjectsPage(dateRangeFav));
  };

  useEffect(() => {
    if (inputSearchValue) {
      const searchResults = allFavoritesPage.filter(
        ({ input, toolDisplayName }) =>
          input.includes(inputSearchValue) ||
          (toolDisplayName && toolDisplayName.toLowerCase().includes(inputSearchValue.toLowerCase())),
      );
      setDisplayAllFavorites(searchResults);
    } else {
      setDisplayAllFavorites(allFavoritesPage);
    }
  }, [allFavoritesPage, inputSearchValue]);

  return (
    <>
      <PageHeader hideTranslation={true} title="Favourites" />

      <FavoriteContainer>
        <FavouriteHeader favDateRange={setDateRange} onInputSearch={setInputSearchValue} />
        <FavoriteCards allFavoritesPageLoading={allFavoritesPageLoading} allFavoritesPage={displayAllFavorites} />
      </FavoriteContainer>
    </>
  );
};

export default Favorite;

/* ------------------------------------------------------ */
/*                     SidebarTemplate                    */
/* ------------------------------------------------------ */

/**
 * Display of Category list in mobile view
 * The view would be in Horizontal and slideable
 */

import { Menu, Typography } from 'antd';
import React from 'react';
import { SideBarLogo } from '../../static/category/sidebar/sideBarLogo';
import { RightTopup, RightTopupWrap } from './styled';

const { Text } = Typography;

const SidebarTemplate = ({ displayCategories, selectedCategory, onCategoryClick }) => {
  const handleClick = (id) => {
    onCategoryClick(id);
  };
  return (
    <RightTopupWrap>
      <Text>Tools</Text>
      <RightTopup>
        {displayCategories.map(({ categoryName, categoryId, iconSmall }) => (
          <Menu onClick={() => handleClick(categoryId)} style={{ background: '#F9F9FF', width: '168px' }}>
            <span className="icon-image">
              <SideBarLogo
                key={`temp-${categoryId}`}
                logo={iconSmall}
                color={`${categoryId === selectedCategory ? '#6c5afb' : '#282B3F'}`}
              />
            </span>

            <Menu.Item
              key={`template-${categoryId}`}
              className={`${categoryId === selectedCategory ? 'menu-selected' : ''}`}
            >
              {categoryName}
            </Menu.Item>
          </Menu>
        ))}
      </RightTopup>
    </RightTopupWrap>
  );
};

export default SidebarTemplate;

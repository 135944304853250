import actions from './actions';

const {
  FB_LOGIN_BEGIN,
  FB_LOGIN_SUCCESS,
  FB_LOGIN_ERR,
  FB_LOGOUT_BEGIN,
  FB_LOGOUT_SUCCESS,
  FB_LOGOUT_ERR,
  FB_SIGNUP_BEGIN,
  FB_SIGNUP_SUCCESS,
  FB_SIGNUP_ERR,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILED,
  RESET_ERROR_MESSAGE,
} = actions;

const initialState = {
  data: null,
  loading: false,
  isLogout: false,
  isLogin: false,
  error: false,
  isSignUpError: false,
  isSignUpLoading: false,
  setNewPasswordLoading: false,
  loginLoading: false,
  logoutLoading: false,
};

const firebaseAuth = (state = initialState, action) => {
  const { type, data, err } = action;
  switch (type) {
    case FB_LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        loginLoading: true,
      };

    case FB_LOGIN_SUCCESS:
      return {
        ...state,
        isLogin: true,
        error: false,
        loading: false,
        loginLoading: false,
      };

    case FB_LOGIN_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        isLogin: false,
        loginLoading: false,
        logoutLoading: false,
      };

    case FB_LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
        logoutLoading: true,
      };

    case FB_LOGOUT_SUCCESS:
      return {
        ...state,
        data,
        isLogout: true,
        isLogin: false,
        error: false,
        loading: false,
        logoutLoading: false,
      };

    case FB_LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
        isLogout: false,
        // loginLoading: false,
      };

    case FB_SIGNUP_BEGIN:
      return {
        ...state,
        isSignUpLoading: true,
      };

    case FB_SIGNUP_SUCCESS:
      return {
        ...state,
        data,
        isSIGNUP: true,
        isSignUpError: false,
        isSignUpLoading: false,
      };

    case FB_SIGNUP_ERR:
      return {
        ...state,
        isSignUpError: err,
        isSignUpLoading: false,
      };

    case SET_NEW_PASSWORD:
      return {
        ...state,
        setNewPasswordLoading: true,
      };
    case SET_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        setNewPasswordLoading: false,
      };
    case SET_NEW_PASSWORD_FAILED:
      return {
        ...state,
        setNewPasswordLoading: false,
      };
    case RESET_ERROR_MESSAGE:
      return {
        ...state,
        error: false,
        loading: false,
      };

    default:
      return state;
  }
};

export default firebaseAuth;

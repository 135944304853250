/* ------------------------------------------------------ */
/*                       Page Title                       */
/* ------------------------------------------------------ */

// Page title or heading of every page.

import { Avatar, Divider } from 'antd';
import React from 'react';
import { PageTitleStyled } from './styled';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

/** 
@string title: string
@string subtitle: string
@object extra: extra UI on the right side of road.
@icon backIcon dsiplay back icon
@tags UI tags
@avatar avatar
*/

const PageTitle = ({ title, subtitle, extra, backIcon, tags, avatar }) => {
  const navigate = useNavigate();

  /*
    Each page has its own title. there is an option to display back-icon to goto previous page.
  */

  return (
    <PageTitleStyled>
      <div className="left-side">
        {backIcon && <ArrowLeftOutlined onClick={() => navigate(-1)} className="page-back-icon" />}

        <div className="page-title">
          {avatar && <Avatar size={30} src={avatar} className="page-title-icon" />}
          {title}
          <span className="page-title-tags"> {tags}</span>
        </div>
        <Divider type="vertical" />
        <div className="page-subtitle">{subtitle}</div>
      </div>
      <div className="right-side">{extra}</div>
    </PageTitleStyled>
  );
};

export default PageTitle;

/* ------------------------------------------------------ */
/*                   Ant-Design Popover                   */
/* ------------------------------------------------------ */
//

/*
AntD Component: https://ant.design/components/popover
  
Methadology
The component is used as multiple purposes. The control of popover ability can be control by parent. 
The content inside popover, can be a custom UI or pregenerated Content UI
*/

/*
Used In 
  src/components/utilities/auth-info/help.js
  src/components/utilities/auth-info/info.js
  src/components/utilities/auth-info/notificationList.js
  src/container/CaiRephrase/RephraseUI.jsx
  src/container/UserManagement/overview/usersColumn.js
  src/container/WorkspaceSelection
*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Content, PopoverStyle, Title } from './styled';

/**
@ReactNode content: This display the React-UI  
@boolean customVisible: it handles the visibility control by parent component or by component itself.
@boolean customVisibleChange: func to change the visibility of popover, it does control by 
*/

const Popover = ({
  content,
  placement,
  title,
  trigger,
  overlayClassName,
  children,
  customVisibleChange,
  customVisible,
  customContent,
}) => {
  const [open, setOpen] = useState(false);

  // handle the visibility of popover
  const handleVisibleChange = (open) => {
    setOpen(open);
  };

  const defaultContent = <Content>{content}</Content>;
  return (
    <PopoverStyle
      overlayClassName={overlayClassName}
      open={customVisible ? customVisible : open}
      placement={placement}
      title={title && <Title>{title}</Title>}
      content={customContent ? customContent : defaultContent}
      trigger={trigger}
      onOpenChange={customVisibleChange ? customVisibleChange : handleVisibleChange}
    >
      {children}
    </PopoverStyle>
  );
};

Popover.defaultProps = {
  trigger: 'hover',
  placement: 'bottomCenter',
  customVisible: false,
};

Popover.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string,
  trigger: PropTypes.string,
  children: PropTypes.node.isRequired,
  customVisible: PropTypes.bool,
  customVisibleChange: PropTypes.func,
};

export { Popover };

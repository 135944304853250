/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import { Table } from 'antd';
import React, { useEffect } from 'react';
import { RowDataItems, RowTitle } from './mock';
import { MemberTableContainer } from './styled';

const MemberTable = () => {
  const index = RowTitle?.findIndex((item) => item?.key === 'email');
  useEffect(() => {
    if (window.innerWidth < 768) {
      RowTitle.splice(index, 1);
    }
  }, [RowTitle]);
  return (
    <MemberTableContainer>
      <div className="member-table">
        <Table columns={RowTitle} dataSource={RowDataItems} />
      </div>
    </MemberTableContainer>
  );
};

export default MemberTable;

/* ------------------------------------------------------ */
/*                     TrailMobileCard                    */
/* ------------------------------------------------------ */

/**
 * On mobile view there is a carousel antd component to slides to different plan
 */

import React from 'react';
import { Carousel } from 'antd';
import { TrailMobilCard } from '../styled';
import MobileCarousel from './MobelCarousel';
import { CRAFTER, ENTERPRISE, STARTER } from '../../../constants';

const TrailMobileCard = ({ setTabClickMobile, tabClickMobile }) => {
  const handleCarousel = (planId) => {
    setTabClickMobile(planId);
  };

  const cardCarousel = [
    { text: 'Starter', subText: 'For individuals getting started', planId: STARTER },
    { text: 'Crafter', subText: 'For writers and small businesses', planId: CRAFTER },
    { text: 'Enterprise', subText: 'Run your company on your terms', planId: ENTERPRISE },
  ];

  return (
    <TrailMobilCard>
      <div
        style={{
          display: 'block',
          width: 400,
          padding: 30,
        }}
      >
        <>
          <Carousel slidesToShow={2} arrow={true}>
            {cardCarousel?.map((item, index) => {
              const { text, subText, planId } = item;
              return (
                <MobileCarousel
                  tabClick={tabClickMobile}
                  handleCarousel={handleCarousel}
                  text={text}
                  planId={planId}
                  subText={subText}
                />
              );
            })}
          </Carousel>
        </>
      </div>
    </TrailMobilCard>
  );
};

export default TrailMobileCard;

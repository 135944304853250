import styled from 'styled-components';

const NotificationStyled = styled.div`
  .title .title-text {
    margin-left: 120px;
  }
  .notification-icon {
    width: 39.2px;
    height: 32px;
    ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification-list-wrapper {
    display: flex;
    .notification-icon {
      background: #5f63f215;
      color: #5f63f2;
      svg {
        width: 18px;
        height: 18px;
      }
    }
    .notification-content {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .notification-text h1 {
      font-size: 14px;
      font-weight: 400;
      color: #5a5f7d;
      margin-bottom: 4px;
    }

    .notification-text h1 span {
      color: #5f63f2;
      font-weight: 500;
      cursor: pointer;
      padding-left: 0;
    }

    .notification-text p {
      font-size: 12px;
      color: #adb4d2;
      margin-bottom: 0;
      text-align: 'right';
    }
    .message {
      margin-bottom: 20px !important;
    }
  }
`;

export { NotificationStyled };

import Styled from 'styled-components';
import { Steps } from 'antd';

const StepsStyle = Styled(Steps)`
    .steps-action{
        margin-top: 40px;
        button{
            height: 44px;
        }
    }
`;

const StepAction = Styled.div`
    width: 100%;

    .step-action-wrap {
        display: flex;
        justify-content: center;
        .step-action-inner {
            width: 580px;
            padding: 0 25px;
            @media only screen and (max-width: 575px){
                width: 100%;
                padding: 0;
            }
        }
    }
    .steps-action {
        margin: 1rem 0px;
        width: 100%; 
        @media only screen and (max-width: 575px){
            padding: 0 1rem;
        }
    }
`;

export { StepsStyle, StepAction };

/* ------------------------------------------------------ */
/*                    Favourite Header                    */
/* ------------------------------------------------------ */

/**
 * The Header has the datepicker
 * search also apply on the content and tool input
 *
 */

import { Input, DatePicker, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  DateWrap,
  FavouriteDateWrap,
  // FavouriteHeadingWrap,
  FavouriteHearderWrap,
  // FilterWrap,
  SearchWrap,
} from './styled';
// import ArrowDown from '../../../static/favourite/ArrowDown.svg';
// import Filter from '../../../static/favourite/Filter.svg';
// import Icon from '../../../static/favourite/Icon.svg';
import Search from '../../../static/favourite/search-input.svg';
// import { Button } from '../../../components/Button';
import { dateFormat } from '../../../utility/utility';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const FavouriteHeader = ({ favDateRange, onInputSearch }) => {
  const startOfMonth = moment().startOf('month').format(dateFormat);

  const endOfMonth = moment().format(dateFormat);
  const [favRange, setFavRange] = useState([startOfMonth, endOfMonth]);
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  useEffect(() => {
    favRange && favDateRange(favRange);
  }, []);

  const onRangeChange = (dates, dateStrings, info) => {
    setFavRange(dateStrings);
  };
  const onOpen = (open) => {
    if (open === false) {
      favDateRange(favRange);
    }
  };
  const startDate = dayjs(favRange[0], dateFormat);
  const endDate = dayjs(favRange[1], dateFormat);
  return (
    <FavouriteHearderWrap>
      <FavouriteDateWrap>
        <DateWrap>
          <Space direction="vertical" size={12}>
            <RangePicker
              defaultValue={[startDate, endDate]}
              format={dateFormat}
              disabledDate={disabledDate}
              onChange={onRangeChange}
              onOpenChange={onOpen}
              value={[dayjs(favRange[0]), dayjs(favRange[1])]}
              allowClear={false}
            />
          </Space>
        </DateWrap>
        <div className="fav-header-wrap">
          {/* <FilterWrap>
            <Button>
              <img src={Filter} alt="Filter"></img>
              Filter
              <img src={ArrowDown} alt="ArrowDown"></img>
            </Button>
            <Button size="small" type="light" className="copied-fav">
              <img src={Icon} alt="Icon"></img>
              Copied
            </Button>
          </FilterWrap> */}

          <SearchWrap>
            <Input
              allowClear
              onChange={(e) => onInputSearch(e.target.value)}
              size="middle"
              placeholder="Search Input"
              suffix={<img src={Search} alt="Search" />}
            />
          </SearchWrap>
        </div>
      </FavouriteDateWrap>
    </FavouriteHearderWrap>
  );
};

export default FavouriteHeader;

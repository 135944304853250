/* ------------------------------------------------------ */
/*                 Long Form Populate JSON                */
/* ------------------------------------------------------ */

import {
  FIELD_TYPE_CONCLUSION_POINTS,
  FIELD_TYPE_MULTIPLE_OPTIONS,
  FIELD_TYPE_OUTLINE_POINTS,
  FIELD_TYPE_SECTION_BUILDER,
  FIELD_TYPE_SECTION_POINTS,
  FIELD_TYPE_TEXTAREA,
  STEP_CHOOSE_INTRO,
  STEP_CHOOSE_TITLE,
  STEP_CONCLUSION,
  STEP_KEY_TOPICS,
  STEP_PARAGRAGH_POINT,
  STEP_PARAGRAPH_BUILDER,
  STEP_TITLE_DESCRIPTION,
  TOOL_ID_BLOG_INTRODUCTION,
  TOOL_ID_BLOG_OUTLINE,
  TOOL_ID_BLOG_TITLES,
  TOOL_ID_CONCLUSION,
  TOOL_ID_OUTLINE_BULLET_POINT,
  TOOL_ID_PRODUCT_BULLET,
} from '../../../constants';

// There are total 7 steps and each step is linked with the tool id
/**
 * stepId: current step Id
 * nextStepId: the next step id
 * toolId: when a user submit the request, it trigger the next step tool-id inorder to generate results according to the second step description.
 * currentToolId: This is the tool-id for identification that this toolId is being called to generate the results of this step
 * customInput: to provide the custom text input field if the output is not according to the desire requirements
 * previousStepId: the step id of the previous step, this help to revert back to the specific step
 */
export const LongFormSteps = [
  {
    id: 1,
    index: 0,
    order: 1,
    stepId: STEP_TITLE_DESCRIPTION,
    nextStepId: STEP_CHOOSE_TITLE,
    header: 'Blog Topic',
    title:
      'Describe your blog topic in one or two sentences. This will help us curate your title, topics and call to action.',
    field: {
      type: FIELD_TYPE_TEXTAREA,
      label: 'What will this blog be about?',
      placeholder: 'Start typing...',
    },
    buttonText: 'Next',
    wordsLimit: 300,
    toolId: TOOL_ID_BLOG_TITLES,
    modelSelect: 'modelC',
    output: {
      header: 'Description',
      editLabel: 'Edit Description',
    },
    customInput: false,
  },
  {
    id: 2,
    index: 1,
    order: 2,
    stepId: STEP_CHOOSE_TITLE,
    previousStepId: STEP_TITLE_DESCRIPTION,
    nextStepId: STEP_CHOOSE_INTRO,
    header: 'Choose the title?',
    title: 'Craft a title that intrigues your reader.',
    field: {
      type: FIELD_TYPE_MULTIPLE_OPTIONS,
    },
    buttonText: 'Next',
    output: {
      header: 'Title',
    },
    generateMoreBtn: 'Generate more',
    customInput: 'title',
    toolId: TOOL_ID_BLOG_INTRODUCTION,
    currentToolId: TOOL_ID_BLOG_TITLES,
    modelSelect: 'modelD',
  },
  {
    id: 3,
    index: 2,
    order: 3,
    stepId: STEP_CHOOSE_INTRO,
    previousStepId: STEP_CHOOSE_TITLE,
    nextStepId: STEP_KEY_TOPICS,
    header: 'Write that catchy intro',
    title: 'Hook your reader with an intro paragraph that makes them want to keep reading.',
    field: {
      type: FIELD_TYPE_MULTIPLE_OPTIONS,
      label: 'Select your intro:',
    },
    buttonText: 'Next',
    output: {
      header: 'Intro',
    },
    customInput: 'title',
    generateMoreBtn: 'Generate more',
    modelSelect: 'modelD',
    toolId: TOOL_ID_BLOG_OUTLINE,
    currentToolId: TOOL_ID_BLOG_INTRODUCTION,
  },
  {
    id: 4,
    index: 3,
    order: 4,
    stepId: STEP_KEY_TOPICS,
    previousStepId: STEP_CHOOSE_INTRO,
    nextStepId: STEP_PARAGRAGH_POINT,
    header: 'What are your key topics? Generate sections of an outline',
    title: 'Use Craftly to generate a fool proof outline for each section of the blog.',
    field: {
      type: FIELD_TYPE_OUTLINE_POINTS,
      multipleSelect: true,
      max: 10,
    },
    buttonText: 'Next',
    output: {
      header: 'Outline',
    },
    generateMoreBtn: 'Generate more',
    toolId: TOOL_ID_OUTLINE_BULLET_POINT,
    modelSelect: 'modelD',
    currentToolId: TOOL_ID_BLOG_OUTLINE,
  },
  {
    id: 5,
    index: 4,
    order: 5,
    stepId: STEP_PARAGRAGH_POINT,
    previousStepId: STEP_KEY_TOPICS,
    nextStepId: STEP_PARAGRAPH_BUILDER,
    header: 'Paragraph Builder',
    title: 'Turn bullet point ideas into full paragraphs for each section of your blog.',
    field: {
      type: FIELD_TYPE_SECTION_POINTS,
      multipleSelect: true,
      max: 5,
      minPerSection: 1,
    },
    buttonText: 'Next',
    output: {
      header: 'Paragraph Builder',
    },
    generateMoreBtn: 'Generate more',
    toolId: TOOL_ID_PRODUCT_BULLET,
    modelSelect: 'modelD',
    currentToolId: TOOL_ID_OUTLINE_BULLET_POINT,
  },
  {
    id: 6,
    index: 5,
    order: 6,
    stepId: STEP_PARAGRAPH_BUILDER,
    previousStepId: STEP_PARAGRAGH_POINT,
    nextStepId: STEP_CONCLUSION,
    header: 'Body Paragraphs',
    title:
      'Choose at least one paragraph from each of your sections below. These paragraphs have been written based on your previous section selections ',
    field: {
      type: FIELD_TYPE_SECTION_BUILDER,
      multipleSelect: true,
      minPerSection: 1,
    },
    buttonText: 'Next',
    output: {
      header: 'Paragraph',
    },
    generateMoreBtn: 'Generate more',
    toolId: TOOL_ID_CONCLUSION,
    modelSelect: 'modelE',
    currentToolId: TOOL_ID_PRODUCT_BULLET,
  },
  {
    id: 7,
    index: 6,
    order: 7,
    stepId: STEP_CONCLUSION,
    previousStepId: STEP_PARAGRAPH_BUILDER,
    header: 'Conclusion',
    title: 'Wrap up your argument and leave your readers with  something to think about',
    field: {
      type: FIELD_TYPE_CONCLUSION_POINTS,
      multipleSelect: false,
    },
    buttonText: 'Next',
    output: {
      header: 'Conclusion',
    },
    modelSelect: 'modelD',
    generateMoreBtn: 'Generate more',
    currentToolId: TOOL_ID_CONCLUSION,
  },
];

export const saveCompletedStepIdsMockData = [
  {
    content: 'loose weight exercise',
    stepId: 'title-description',
    selectedOutput: 'loose weight exercise',
    selectedOutputId: 'text-field',
  },
  {
    content: [
      {
        contentData: 'HIIT Workouts for Loose Weight and Tone',
        contentId: 'efb88523',
        len: 39,
        cfr: '0',
      },
      {
        contentData: 'How to Lose Weight and Keep It Off - the Ultimate Diet Plan',
        contentId: 'dd18c7c2',
        len: 59,
        cfr: '0',
      },
      {
        contentData: 'How to Lose Weight and keep it off - The Ultimate Guide',
        contentId: '1c540d9f',
        len: 55,
        cfr: '0',
      },
      {
        contentData: 'How to Lose Weight Exercise - the Right Way',
        contentId: '8dd3dc66',
        len: 43,
        cfr: '0',
      },
      {
        contentData: 'How to Lose Weight Fast - The Ultimate Guide',
        contentId: '371f9c09',
        len: 44,
        cfr: '0',
      },
      {
        contentData: 'Lose Weight and Get Fit with These Simple Exercises',
        contentId: 'd96c0fcd',
        len: 51,
        cfr: '0',
      },
      {
        contentData: 'How to Lose Weight the Right Way - with exercise',
        contentId: 'b9afe2fa',
        len: 48,
        cfr: '0',
      },
      {
        contentData: '3 Simple Exercises to Loose Weight Quickly',
        contentId: 'cdc31f7e',
        len: 42,
        cfr: '0',
      },
    ],
    stepId: 'choose-title',
    selectedOutput: 'How to Lose Weight Fast - The Ultimate Guide',
    selectedOutputId: '371f9c09',
  },
  {
    content: [
      {
        contentData:
          'Everybody wants to lose weight, but most people struggle to do so. For some, it’s a matter of diet and exercise. But for others, it’s a battle against temptation. And that battle can often feel insurmountable. That’s why we wrote this guide. In it, you’ll learn about the different methods for losing weight and how to use them in a way that works for you. And most importantly, you’ll find out how to lose weight fast – without hardship or pain. So if you want to slim down, read on!',
        contentId: '0fcdb5db',
        len: 484,
        cfr: '0',
      },
      {
        contentData:
          'Having a trim figure is one of the body-conscious things people strive for. Losing weight can be a long, difficult process, but it doesn’t have to be. Here are the complete steps you need to take in order to lose weight quickly and effectively. You won’t regret it!',
        contentId: '25b5b43d',
        len: 265,
        cfr: '0',
      },
      {
        contentData:
          'If you want to lose weight quickly and easily, then this is the guide for you. By following the advice in this article, you’ll be able to lose weight quickly and without much effort. In fact, most of the techniques in this guide are beginner- friendly, so anyone can use them. And best of all, most of the techniques are simple enough that you can do them at home without any expensive equipment. So read on to learn how to lose weight fast!',
        contentId: '95e9daf7',
        len: 441,
        cfr: '0',
      },
    ],
    stepId: 'choose-intro',
    selectedOutput:
      'Having a trim figure is one of the body-conscious things people strive for. Losing weight can be a long, difficult process, but it doesn’t have to be. Here are the complete steps you need to take in order to lose weight quickly and effectively. You won’t regret it!',
    selectedOutputId: '25b5b43d',
  },
  {
    content: [
      {
        contentData: 'Shed pounds with these 5 simple tips.',
        contentId: '6H6EXASD',
      },
      {
        contentData: 'Eliminate stress and eat healthier.',
        contentId: 'JWVDMGRQ',
      },
      {
        contentData: 'Make your diet more fun.',
        contentId: 'Z3W4LIHD',
      },
      {
        contentData: 'Get some exercise in before breakfast.',
        contentId: 'UXY47Z0Y',
      },
      {
        contentData: 'Create a weight loss plan and stick to it!',
        contentId: '7TJ758F3',
      },
      {
        contentData:
          'The key to losing weight is following a balanced, nutritious diet and incorporating regular exercise into your day. These five tips will help you get started on the road to weight loss success!',
        contentId: '32A0TGEP',
      },
      {
        contentData: 'Start by identifying your goals and setting realistic expectations.',
        contentId: 'NAZDVCY4',
      },
      {
        contentData: 'Eliminate processed foods and sugars from your diet.',
        contentId: 'B1R01ML7',
      },
      {
        contentData: 'Eat more fruits and vegetables.',
        contentId: 'C485TQ7E',
      },
    ],
    stepId: 'key-topics',
    selectedOutput: [
      {
        value: 'Eliminate stress and eat healthier.',
        id: 'JWVDMGRQ',
      },
      {
        value: 'Make your diet more fun.',
        id: 'Z3W4LIHD',
      },
    ],
    selectedOutputId: [
      {
        value: 'Eliminate stress and eat healthier.',
        id: 'JWVDMGRQ',
      },
      {
        value: 'Make your diet more fun.',
        id: 'Z3W4LIHD',
      },
    ],
  },
  {
    content: [
      {
        sectionTitle: 'Eliminate stress and eat healthier.',
        contentId: 'MUYSIRP5',
        order: 1,
        contentData: [
          {
            pointValue: 'Eat a balanced diet and avoid processed foods.',
            pointId: 'R6IQFR7Y',
            sectionId: 'MUYSIRP5',
          },
          {
            pointValue: 'Eliminate stress by taking breaks, exercise, and engaging in healthy activities.',
            pointId: 'ME2ZQJ0E',
            sectionId: 'MUYSIRP5',
          },
          {
            pointValue: 'Making simple changes to your diet can help you lose weight quickly.',
            pointId: 'O0YCDYS6',
            sectionId: 'MUYSIRP5',
          },
          {
            pointValue: 'Eating more fruits and vegetables will give you the nutrients your body needs to burn fat.',
            pointId: 'KZI6SYA1',
            sectionId: 'MUYSIRP5',
          },
          {
            pointValue: 'Simple changes to your diet c nutrients your body needs to burn fat.',
            pointId: 'sdsdsd',
            sectionId: 'MUYSIRP5',
          },
          {
            pointValue: 'Your body needs to burn fat.',
            pointId: 'qweqweqwe',
            sectionId: 'MUYSIRP5',
          },
        ],
      },
      {
        sectionTitle: 'Make your diet more fun.',
        contentId: 'Y6OW0TKF',
        order: 2,
        contentData: [
          {
            pointValue: 'Use fun foods to make your diet more interesting.',
            pointId: '32BAQ6V7',
            sectionId: 'Y6OW0TKF',
          },
          {
            pointValue: 'Try out new recipes and snacks that you wouldn’t typically eat.',
            pointId: 'F6TXRDWL',
            sectionId: 'Y6OW0TKF',
          },
          {
            pointValue: 'Get creative with your diet and make it more fun.',
            pointId: 'CQ431581',
            sectionId: 'Y6OW0TKF',
          },
          {
            pointValue: 'Try different foods and flavors that you may not have tried before.',
            pointId: 'Z4T7EMJC',
            sectionId: 'Y6OW0TKF',
          },
          {
            pointValue: 'Make your diet more interesting by incorporating different foods into your meals.',
            pointId: 'FY0F5KJP',
            sectionId: 'Y6OW0TKF',
          },
          {
            pointValue: 'Find foods that you enjoy and make it a habit to eat them every day.',
            pointId: 'ROY29ZMA',
            sectionId: 'Y6OW0TKF',
          },
        ],
      },
    ],
    stepId: 'paragraph-point',
    selectedOutput: [
      {
        value: 'Eat a balanced diet and avoid processed foods.',
        id: 'R6IQFR7Y',
        sectionId: 'MUYSIRP5',
        sectionTitle: 'Eliminate stress and eat healthier.',
      },
      {
        value: 'Eliminate stress by taking breaks, exercise, and engaging in healthy activities.',
        id: 'ME2ZQJ0E',
        sectionId: 'MUYSIRP5',
        sectionTitle: 'Eliminate stress and eat healthier.',
      },
      {
        value: 'Use fun foods to make your diet more interesting.',
        id: '32BAQ6V7',
        sectionId: 'Y6OW0TKF',
        sectionTitle: 'Make your diet more fun.',
      },
      {
        value: 'Try out new recipes and snacks that you wouldn’t typically eat.',
        id: 'F6TXRDWL',
        sectionId: 'Y6OW0TKF',
        sectionTitle: 'Make your diet more fun.',
      },
    ],
    selectedOutputId: [
      {
        value: 'Eat a balanced diet and avoid processed foods.',
        id: 'R6IQFR7Y',
        sectionId: 'MUYSIRP5',
        sectionTitle: 'Eliminate stress and eat healthier.',
      },
      {
        value: 'Eliminate stress by taking breaks, exercise, and engaging in healthy activities.',
        id: 'ME2ZQJ0E',
        sectionId: 'MUYSIRP5',
        sectionTitle: 'Eliminate stress and eat healthier.',
      },
      {
        value: 'Use fun foods to make your diet more interesting.',
        id: '32BAQ6V7',
        sectionId: 'Y6OW0TKF',
        sectionTitle: 'Make your diet more fun.',
      },
      {
        value: 'Try out new recipes and snacks that you wouldn’t typically eat.',
        id: 'F6TXRDWL',
        sectionId: 'Y6OW0TKF',
        sectionTitle: 'Make your diet more fun.',
      },
    ],
  },
  {
    content: [
      {
        sectionTitle: 'Eliminate stress and eat healthier.',
        contentId: 'MUYSIRP5',
        order: 1,
        contentData: [
          {
            pointValue:
              "Trying to shed a few extra pounds can be uncomfortable, time-consuming, and frustrating. But it doesn't have to be that way. In this ultimate guide, you'll learn about the basics of weight loss, including: what to eat, when to eat it, and how to avoid common mistakes. You'll also find out about the importance of a balanced diet and lifestyle choices that will help you lose weight fast. By following these guidelines, you can achieve your weight loss goals in no time. Finally, take some essential steps to ensure your Weight Loss journey continues on a healthy and successful path.",
            pointId: 'QV1C27F0',
            sectionId: 'MUYSIRP5',
          },
          {
            pointValue:
              "A healthy weight is not only important for your physical health, but it can also increase your lifespan. Losing weight can be a difficult task, but with the right guidance it can be done. In this guide, we will provide you with all the information you need to lose weight fast and maintain your new weight for the long term. The first step is to make sure that you're eating a balanced diet. Eat plenty of fruits and vegetables, whole grains, and lean meats. Avoid processed foods and foods that are high in sugar and salt. Exercise is another essential component of weight loss. Exercise not only burns calories, but it also helps to regulate your blood sugar levels and improve your cardiovascular health. Finally, take breaks every once in a while.",
            pointId: 'HPNOQA0Z',
            sectionId: 'MUYSIRP5',
          },
        ],
      },
      {
        sectionTitle: 'Make your diet more fun.',
        contentId: 'Y6OW0TKF',
        order: 2,
        contentData: [
          {
            pointValue:
              'One of the best ways to lose weight quickly is by making your diet more interesting and fun. Try out new recipes and snacks that you wouldn’t typically eat. This will help to keep you on track and make it more enjoyable to stick to your weight loss goals. Additionally, use fun foods to make your diet more interesting. This can be anything from indulging in some delicious dessert after dinner to trying out a new breakfast choice. By incorporating fun foods into your diet, you will be more engaged with your weight loss goals and more likely to stick to them.',
            pointId: 'N69PM51K',
            sectionId: 'Y6OW0TKF',
          },
          {
            pointValue:
              'If you’re looking to lose weight quickly and effectively, then you need to start incorporating more fun foods into your diet. Fun foods are those that you would typically avoid but that have the ability to help you feel more full and satisfied. This will make it easier to stick to your daily calorie and carb count. Try out new recipes and snacks that you wouldn’t typically eat. Not only will this add some variety and excitement to your diet, but it will also help curb your cravings. Another tip is to try and keep your food as interesting as possible. This means using spices and flavorings that you usually wouldn’t use. This will help keep your taste buds stimulated and focused on the food you’re eating',
            pointId: '4XXSLIDI',
            sectionId: 'Y6OW0TKF',
          },
        ],
      },
    ],
    stepId: 'paragraph-builder',
  },
];
export const blogMockData = {
  'title-description': {
    value: 'loose weight exercise',
    id: 'text-field',
  },
  'choose-title': {
    value: 'How to Lose Weight Fast - The Ultimate Guide',
    id: '371f9c09',
  },
  'choose-intro': {
    value:
      'Having a trim figure is one of the body-conscious things people strive for. Losing weight can be a long, difficult process, but it doesn’t have to be. Here are the complete steps you need to take in order to lose weight quickly and effectively. You won’t regret it!',
    id: '25b5b43d',
  },
  'key-topics': [
    {
      value: 'Eliminate stress and eat healthier.',
      id: 'JWVDMGRQ',
    },
    {
      value: 'Make your diet more fun.',
      id: 'Z3W4LIHD',
    },
  ],
  'paragraph-point': [
    {
      value: 'Eat a balanced diet and avoid processed foods.',
      id: 'R6IQFR7Y',
      sectionId: 'MUYSIRP5',
      sectionTitle: 'Eliminate stress and eat healthier.',
    },
    {
      value: 'Eliminate stress by taking breaks, exercise, and engaging in healthy activities.',
      id: 'ME2ZQJ0E',
      sectionId: 'MUYSIRP5',
      sectionTitle: 'Eliminate stress and eat healthier.',
    },
    {
      value: 'Use fun foods to make your diet more interesting.',
      id: '32BAQ6V7',
      sectionId: 'Y6OW0TKF',
      sectionTitle: 'Make your diet more fun.',
    },
    {
      value: 'Try out new recipes and snacks that you wouldn’t typically eat.',
      id: 'F6TXRDWL',
      sectionId: 'Y6OW0TKF',
      sectionTitle: 'Make your diet more fun.',
    },
  ],
};

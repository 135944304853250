import styled from 'styled-components';

const V2PopUpStyled = styled.div`
  padding: 1rem 3rem;
  padding-bottom: 10px;
  .v2-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 1rem;
    .first-title {
      font-size: 26px;
      font-family: 'Poppins-SemiBold';
    }
    .second-title {
      font-size: 24px;
      font-family: 'Poppins-Regular';
    }
  }
  .v2-video {
    border: 5px solid #6c5afb;
    border-radius: 10px;
  }
  .v2-plan-comparison {
    margin-top: 2rem;
    .v2-points {
      margin-top: 1rem;
      padding: 5px 20px 2rem;
      .point {
        padding: 5px;
        .point-text {
          font-size: 14px;
        }
      }
    }
  }
`;

export { V2PopUpStyled };

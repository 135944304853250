const actions = {
  PRODUCT_READ_BEGIN: 'PRODUCT_READ_BEGIN',
  PRODUCT_READ_SUCCESS: 'PRODUCT_READ_SUCCESS',
  PRODUCT_READ_ERR: 'PRODUCT_READ_ERR',

  TRIAL_START: 'TRIAL_START',
  TRIAL_STOP: 'TRIAL_STOP',

  NO_CREDIT_CARD_TRIAL_START: 'NO_CREDIT_CARD_TRIAL_START',
  NO_CREDIT_CARD_TRIAL_STOP: 'NO_CREDIT_CARD_TRIAL_STOP',

  SUBSCRIPTION_READ_SUCCESS: 'SUBSCRIPTION_READ_SUCCESS',
  FIXED_PAYMENT_READ_SUCCESS: 'FIXED_PAYMENT_READ_SUCCESS',

  RECENT_PAYMENT_PROGRESS: 'RECENT_PAYMENT_PROGRESS',
  RECENT_PAYMENT_SUCCESS: 'RECENT_PAYMENT_SUCCESS',

  CRAFTER_OFF_TRIAL_START: 'CRAFTER_OFF_TRIAL_START',
  CRAFTER_OFF_TRIAL_STOP: 'CRAFTER_OFF_TRIAL_STOP',

  productReadBegin: () => {
    return {
      type: actions.PRODUCT_READ_BEGIN,
    };
  },

  productReadSuccess: productList => {
    return {
      type: actions.PRODUCT_READ_SUCCESS,
      productList,
    };
  },

  productReadErr: err => {
    return {
      type: actions.PRODUCT_READ_ERR,
      err,
    };
  },

  subscriptionsReadSuccess: (subscriptionList, currentBilling, activePlanIDs) => {
    return {
      type: actions.SUBSCRIPTION_READ_SUCCESS,
      subscriptionList,
      currentBilling,
      activePlanIDs,
    };
  },
  fixedPaymentReadSuccess: fixedPaymentList => {
    return {
      type: actions.FIXED_PAYMENT_READ_SUCCESS,
      fixedPaymentList,
    };
  },
  recentPaymentProgress: () => {
    return {
      type: actions.RECENT_PAYMENT_PROGRESS,
    };
  },
  recentPaymentSuccess: recentPaymentList => {
    return {
      type: actions.RECENT_PAYMENT_SUCCESS,
      recentPaymentList,
    };
  },

  trailStart: () => {
    return {
      type: actions.TRIAL_START,
    };
  },

  trailStop: () => {
    return {
      type: actions.TRIAL_STOP,
    };
  },

  noTrailccStart: () => {
    return {
      type: actions.NO_CREDIT_CARD_TRIAL_START,
    };
  },

  noTrailccStop: () => {
    return {
      type: actions.NO_CREDIT_CARD_TRIAL_STOP,
    };
  },

  crafterOffTrialStart: () => {
    return {
      type: actions.CRAFTER_OFF_TRIAL_START,
    };
  },

  crafterOffTrialStop: () => {
    return {
      type: actions.CRAFTER_OFF_TRIAL_STOP,
    };
  },
};

export default actions;

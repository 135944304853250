/* ------------------------------------------------------ */
/*                 STRIPE PLAN FOR V1 & V2                */
/* ------------------------------------------------------ */

/**
 * In .ENV files there are two enviournment vairable
 * 1. REACT_APP_V1_PRICE_MODEL
 * 2. REACT_APP_V2_PRICE_MODEL
 * these 2 variables are either true or false.
 */

import React from 'react';
import {
  CRAFTER,
  MONTHLY_DURATION,
  STARTER,
  STRIPE_CRAFTER_MONTHLY_PRICEID_V1,
  STRIPE_CRAFTER_YEARLY_PRICEID_V1,
  STRIPE_STARTER_MONTHLY_PRICEID_V1,
  STRIPE_STARTER_YEARLY_PRICEID_V1,
  YEARLY_DURATION,

  // v2 pricing
  STRIPE_CRAFTER_MONTHLY_PRICEID,
  STRIPE_STARTER_YEARLY_PRICEID,
  STRIPE_CRAFTER_YEARLY_PRICEID,
  STRIPE_STARTER_MONTHLY_PRICEID,
} from '../constants';

export const stripeKey = process.env.REACT_APP_STRIPE_KEY;

const planTiersV1 = [
  {
    plan: STARTER,
    interval: MONTHLY_DURATION,
    stripePriceId: STRIPE_STARTER_MONTHLY_PRICEID_V1,
    amount: 35,
    quantity: 1,
    planWords: 20000,
    planPrice: 35,
  },
  {
    plan: STARTER,
    interval: YEARLY_DURATION,
    stripePriceId: STRIPE_STARTER_YEARLY_PRICEID_V1,
    amount: 29,
    quantity: 1,
    planWords: 240000,
    planPrice: 350,
    yearlyWordsPerMonth: 20000,
  },
  {
    plan: CRAFTER,
    interval: MONTHLY_DURATION,
    stripePriceId: STRIPE_CRAFTER_MONTHLY_PRICEID_V1,
    amount: 79,
    quantity: 1,
    planWords: 'unlimited',
    planPrice: 79,
  },
  {
    plan: CRAFTER,
    interval: YEARLY_DURATION,
    stripePriceId: STRIPE_CRAFTER_YEARLY_PRICEID_V1,
    amount: 66,
    quantity: 1,
    planWords: 'unlimited',
    planPrice: 790,
    yearlyWordsPerMonth: 'unlimited',
  },
];

const planTiersV2 = [
  {
    plan: STARTER,
    interval: MONTHLY_DURATION,
    stripePriceId: STRIPE_STARTER_MONTHLY_PRICEID,
    tiers: [
      {
        amount: 29,
        words: 20000,
        markKey: 0,
        markValue: '20,000',
      },
      {
        amount: 49,
        words: 50000,
        markKey: 100,
        markValue: '50,000+',
      },
    ],
  },
  {
    plan: STARTER,
    interval: YEARLY_DURATION,
    stripePriceId: STRIPE_STARTER_YEARLY_PRICEID,
    tiers: [
      {
        amount: 24,
        words: 20000,
        markKey: 0,
        markValue: '20,000',
      },
      {
        amount: 41,
        words: 50000,
        markKey: 100,
        markValue: '50,000+',
      },
    ],
  },
  {
    plan: CRAFTER,
    interval: MONTHLY_DURATION,
    stripePriceId: STRIPE_CRAFTER_MONTHLY_PRICEID,
    tiers: [
      {
        amount: 69,
        words: 70000,
        markKey: 0,
        markValue: '70,000',
      },
      {
        amount: 99,
        words: 100000,
        markKey: 40,
        markValue: {
          style: {
            color: '#fffff',
          },
          label: <span style={{ display: 'none' }}>100,000</span>,
        },
      },
      {
        amount: 259,
        words: 300000,
        markKey: 65,
        markValue: {
          style: {
            color: '#fffff',
          },
          label: <span style={{ display: 'none' }}>300,000</span>,
        },
      },
      {
        amount: 579,
        words: 700000,
        markKey: 100,
        markValue: '700,000+',
      },
    ],
  },
  {
    plan: CRAFTER,
    interval: YEARLY_DURATION,
    stripePriceId: STRIPE_CRAFTER_YEARLY_PRICEID,
    tiers: [
      {
        amount: 58,
        words: 70000,
        markKey: 0,
        markValue: '70,000',
      },
      {
        amount: 82,
        words: 100000,
        markKey: 40,
        markValue: {
          style: {
            color: '#fffff',
          },
          label: <span style={{ display: 'none' }}>100,000</span>,
        },
      },
      {
        amount: 216,
        words: 300000,
        markKey: 65,
        markValue: {
          style: {
            color: '#fffff',
          },
          label: <span style={{ display: 'none' }}>300,000</span>,
        },
      },
      {
        amount: 483,
        words: 700000,
        markKey: 100,
        markValue: '700,000',
      },
    ],
  },
];

export const planTiers =
  process.env.REACT_APP_V1_PRICE_MODEL === 'true'
    ? planTiersV1
    : process.env.REACT_APP_V2_PRICE_MODEL === 'true'
    ? planTiersV2
    : [];

// This check which pricing model is active
export const currentPricingModel = () => {
  return process.env.REACT_APP_V1_PRICE_MODEL === 'true'
    ? { v1PriceModal: true }
    : process.env.REACT_APP_V2_PRICE_MODEL === 'true'
    ? { v2PriceModal: true }
    : { v1PriceModal: false, v2PriceModal: false };
};

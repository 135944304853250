/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */

/* ------------------------------------------------------ */
/*                           APP                          */
/* ------------------------------------------------------ */

/**
 *
 * Every page route is controlled from this page.
 * User control page link are decided on the user profile data
 */

import React, { useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Navigate, Routes } from 'react-router-dom';
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase';
import { ConfigProvider, Spin } from 'antd';
import store, { rrfProps } from './redux/store';
import MainRoutes from './routes/mainRoutes';
import ProtectedRoute from './routes/ProtectedRoute';
import { isFreeUserRole, isUserRole, STATUS } from './constants';
import ErrorPage from './container/ErrorPage';
import AfterSignInRoutes from './routes/AfterSignInRoutes';
import { fetchBlockedFingerprints } from './redux/AdminFeatures/actionCreator';

import AuthLayout from './container/profile/authentication/Index';
import ForgotPassword from './container/profile/authentication/overview/ForgotPassword';
import SignUp from './container/profile/authentication/overview/FbSignup';
import SignIn from './container/profile/authentication/overview/FbSignIn';
import Invite from './container/Invite';
import HandleAuth from './container/profile/authentication/overview/HandleAuth';
import HomePage from './container/profile/authentication/overview/HomePage';
import Onboarding from './container/Onboarding';
import ThemeLayout from './layout';
import Home from './container/Home';
import Pause from './container/Pause';
import Deleted from './container/Deleted/Deleted';
import Deactivate from './container/Deactivate';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { theme } from './config/theme/themeVariables';
import './app.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

TimeAgo.addDefaultLocale(en);

const ProviderConfig = () => {
  const { isLoggedIn, auth, uid, profileLoaded, profile, logoutLoading, blockNow } = useSelector((state) => {
    return {
      isLoggedIn: state.auth.login,
      auth: state.fb,
      uid: state.fb.auth.uid,
      profile: state.fb.profile,
      blockNow: state.adminFeatures.blockNow,
      logoutLoading: state.firebaseAuth.logoutLoading,
      profileLoaded: state.fb.profile.isLoaded, // this profile is controlled by the react-redux-library
      loginLoading: state.firebaseAuth.loginLoading, // this profile is controlled by the react-redux-library
    };
  });
  const dispatch = useDispatch();

  const {
    isApproved,
    name,
    deactivateByAdmin,
    requiredSetPassword,
    visitOnboarding,
    trialBillingPage,
    roles,
    email,
    status,
    creditCards,
  } = profile;
  // isApproved object is to check whether user is allowed.
  // Here is the main logic for the users process. for eg, user have to pass
  // through Admin Approval then Onboaridng and Billing and then APP ACCESS
  // changing IF ELSE positions will change the piority of the next pages  <---

  const NotFound = () => {
    return <Navigate to="/auth" />;
  };

  const checkNextRoutes = () => {
    if (!isLoggedIn) {
      return (
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<SignIn />} />
          <Route path="forgotPassword" element={<ForgotPassword />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="invite" element={<Invite />} />
          <Route path="auth" element={<SignIn />} />
          <Route path="handle_auth" element={<HandleAuth />} />
          <Route path="craftly" element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      );
    } else if (deactivateByAdmin === true) {
      // this is HIGH piority
      // this handles when admin deactive the user account
      return (
        <>
          <Route path="deactivate" element={<Deactivate />} />
          <Route path="*" element={<Navigate to="deactivate" replace />} />
        </>
      );
    } else if (blockNow === true && isUserRole(roles)) {
      // this is HIGH piority
      // this handles when admin block the user account
      return (
        <>
          <Route path="deactivate" element={<Deactivate />} />
          <Route path="*" element={<Navigate to="deactivate" replace />} />
        </>
      );
    } else if (status === STATUS.DELETED) {
      // this handles when the user account is deleted.
      return (
        <>
          <Route path="delete-account" element={<Deleted />} />
          <Route path="*" element={<Navigate to="delete-account" replace />} />
        </>
      );
    } else if (requiredSetPassword) {
      // when admin creates the user account and then user must have to set their password.
      return (
        <>
          <Route path="set-password" element={<Invite showSetPassword={true} />} />
          <Route path="*" element={<Navigate to="/set-password" replace />} />
        </>
      );
    } else if (isApproved === false) {
      // when a new user signup, user by default is set to true.
      // isApproved is related to PAUSE condition.
      return (
        <>
          <Route path="pause" element={<Pause />} />
          <Route path="*" element={<Navigate to="/pause" replace />} />
        </>
      );
    } else if (!trialBillingPage && email && isUserRole(roles)) {
      // NO ONBOARDING and TRIAL BILLING FOR FreeUser
      // this trialpage is for USERS roles only
      return (
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <AfterSignInRoutes />
            </ProtectedRoute>
          }
        />
      );

      // this is the condition which should be match to access the core-app
    } else if (!visitOnboarding && email && (isUserRole(roles) || isFreeUserRole(roles))) {
      // this is the condition where the USER & FREE-USER must go through the onboarding page
      return (
        <>
          <Route path="onboarding" element={<Onboarding />} />
          <Route path="*" element={<Navigate to="/onboarding" replace />} />
        </>
      );
    } else if (name && isApproved === true) {
      // all user profile is ok
      return (
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <ThemeLayout />
            </ProtectedRoute>
          }
        >
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <MainRoutes />
              </ProtectedRoute>
            }
          />
        </Route>
      );
    } else {
      return <Route element={<ErrorPage />} path="*" />;
    }
  };

  useEffect(() => {
    uid && profileLoaded && dispatch(fetchBlockedFingerprints());
  }, [creditCards, profileLoaded, uid]);

  return (
    // antd 5 version theme provider
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#6c5afb',
          fontFamily: 'var(--font-Poppins)',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        },
        components: {
          Card: {
            borderRadiusLG: 10,
            boxShadowCard: '0 5px 20px rgba(146,153,184,0.03)',
            padding: 12,
            colorTextHeading: '#272B41',
          },
          Modal: {
            paddingMD: 32,
            paddingContentHorizontalLG: 24,
          },
          Button: {
            controlHeight: 38,
            controlHeightLG: 48,
            controlHeightSM: 30,
            borderRadiusSM: 8,
            paddingContentHorizontal: 20,
            paddingXS: 17,
          },
          Input: {
            paddingLG: 30,
            lineHeightLG: 1.5,
            controlHeight: 48,
            controlHeightLG: 50,
            controlHeightSM: 30,
            colorBorder: '#EEEEEE',
          },
          Select: {
            controlHeight: 48,
            controlHeightLG: 50,
            controlHeightSM: 30,
            colorBorder: '#EEEEEE',
          },
          Menu: {
            colorItemBgSelected: 'transparent',
            colorItemBgHover: 'transparent',
            colorItemTextHover: '#6c5afb',
          },
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          {!isLoaded(auth) ? (
            <div className="spin">
              {/* this spin is to wait indication to verify user is loggedin or not.  */}
              <Spin />
            </div>
          ) : profileLoaded ? (
            <Spin spinning={logoutLoading}>
              <Router>
                <Routes>{checkNextRoutes()}</Routes>
              </Router>
            </Spin>
          ) : (
            <div className="spin">
              <Spin />
              {/* this spin is the wait that user profile is fetch from firebase or not. This is the pure solution of UNDEFINED of profile name */}
            </div>
          )}
        </ReactReduxFirebaseProvider>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default App;

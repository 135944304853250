import styled from 'styled-components';

const SettingHeaderStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 19.5px 13px;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }

  .ant-typography {
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 28px !important;
    font-family: 'Poppins-Semibold';
    color: #282b3f !important;
    line-height: 42px !important;
    padding-bottom: 0px;
    border-bottom: none !important;
  }
`;

const NewTabStyle = styled.div`
  display: flex;
  .ant-tabs-top {
    width: 100%;
  }
  .ant-tabs-nav {
    margin-bottom: 0px !important;
  }
  .ant-tabs-nav-wrap {
    padding: 0 14px;
    font-family: 'Poppins-Regular';
    color: #848484;

    .ant-tabs-tab {
      padding: 16px 6px;
      margin: 0 50px 0 0;
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
    display: none;
  }
`;

export { SettingHeaderStyle, NewTabStyle };

/* ------------------------------------------------------ */
/*             LongForm, UserBots and Document            */
/* ------------------------------------------------------ */

import { addNotificationError, addNotificationInfo } from '../../components/utilities/notifications';
import { STEP_TITLE_DESCRIPTION } from '../../constants';
import { DOCUMENT_NOT_FOUND } from '../../constants/content';
import { allSubscribers } from '../../utility/utility';
import actions from './actions';

const {
  documentReadStart,
  documentReadFailed,
  documentReadSuccess,
  userRecentBotsStart,
  userRecentBotsSuccess,
  userRecentBotsFailed,
  documentReadByIdStart,
  documentReadByIdSuccess,
} = actions;

/**
 * Fetch the team members all tool outputs
 */
const getUserRecentBots = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(userRecentBotsStart());
      const getUserRecentBotsSubscriber = await db
        .collection('team-members')
        .document(uid)
        .collection('craft')
        .where('toolName', '==', '')
        .onSnapshot(async (snapshot) => {
          const userBots = [];
          snapshot.forEach((doc) => {
            userBots.push({ ...doc.data(), botId: doc.id });
          });

          await dispatch(userRecentBotsSuccess(userBots));
        });
      allSubscribers.findIndex((x) => x.name === 'getUserRecentBotsSubscriber') === -1 &&
        allSubscribers.push({
          func: getUserRecentBotsSubscriber,
          name: 'getUserRecentBotsSubscriber',
        });
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(userRecentBotsFailed());
    }
  };
};

// Fetch blogBuilder or longForm documents.
// Firestore:
// Collection: long-form-documents, Document: uid
// SubCollection: documents
// SubDocument Document ID
// it fetch the data according to date parameter
const getDocumentList = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await dispatch(documentReadStart());
      const getDocumentListSubs = await db
        .collection('long-form-documents')
        .doc(uid)
        .collection('documents')
        .orderBy('date', 'desc')
        .onSnapshot(async (snapshot) => {
          const list = [];
          snapshot.forEach((doc) => {
            list.push({ data: doc.data(), id: doc.id });
          });
          const documentList = list.map(({ data, id }) => {
            const { editor, showStep, storageBlogPostStep, storageCompletedSteps, summary, date, title } = data;
            let blogPostStep = {};
            let completedSteps = [];
            let summaryData = false;
            if (storageBlogPostStep && Object.keys(storageBlogPostStep).length > 0) {
              blogPostStep = JSON.parse(storageBlogPostStep);
            }
            if (storageCompletedSteps && storageCompletedSteps.length > 0) {
              completedSteps = JSON.parse(storageCompletedSteps);
            }
            if (summary) {
              if (Object.keys(summary)) {
                summaryData = summary;
              } else {
                summaryData = JSON.parse(summary);
              }
            }
            return {
              blogPostStep,
              completedSteps,
              showStep,
              editor,
              summary: summaryData,
              date,
              title,
              id,
            };
          });

          await dispatch(documentReadSuccess({ documentList }));
        });
      allSubscribers.findIndex((x) => x.name === 'getDocumentListSubs') === -1 &&
        allSubscribers.push({
          func: getDocumentListSubs,
          name: 'getDocumentListSubs',
        });
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(documentReadFailed());
    }
  };
};

/**
 * Long form details by Id and UID
 * this is to populate the Blog builder
 */
const getDocumentById = ({ uid, id }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    try {
      await dispatch(documentReadByIdStart());
      await db
        .collection('long-form-documents')
        .doc(uid)
        .collection('documents')
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.data() === undefined) {
            addNotificationInfo(DOCUMENT_NOT_FOUND);
          }
          const { editor, showStep, storageBlogPostStep, storageCompletedSteps, summary, date, title } = doc.data() || {
            editor: undefined,
            showStep: STEP_TITLE_DESCRIPTION,
            storageBlogPostStep: {},
            storageCompletedSteps: [],
            summary: false,
            date: undefined,
            title: '',
          };

          // console.log(' doc.data()', doc.data());
          let blogPostStep = {};
          let completedSteps = [];
          let summaryData = false;
          if (storageBlogPostStep && Object.keys(storageBlogPostStep).length > 0) {
            blogPostStep = JSON.parse(storageBlogPostStep);
          }
          if (storageCompletedSteps && storageCompletedSteps.length > 0) {
            completedSteps = JSON.parse(storageCompletedSteps);
          }
          try {
            if (summary) {
              summaryData = JSON.parse(summary);
            }
          } catch {
            if (summary) {
              summaryData = summary;
            }
          }

          const readDocumentById = {
            blogPostStep,
            completedSteps,
            showStep,
            editor,
            summary: summaryData,
            date,
            title,
            id: doc.id,
            dataFound: doc.data() === undefined ? false : true,
          };

          dispatch(documentReadByIdSuccess({ readDocumentById }));
        });
    } catch (err) {
      console.log('err', err);
      await addNotificationError(err.message);
      await dispatch(documentReadFailed());
    }
  };
};

// LongForm document is saved in firestore
// Collection: long-form-documents
// Document: UID
// Collection: documents
const saveDocument = ({ uid, data }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await db
        .collection('long-form-documents')
        .doc(uid)
        .collection('documents')
        .doc(data.id)
        .set({ ...data }, { merge: true });
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(documentReadFailed());
    }
  };
};

// Deleting the long-form
const deleteDocument = ({ uid, documentId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      await db.collection('long-form-documents').doc(uid).collection('documents').doc(documentId).delete();
    } catch (err) {
      await addNotificationError(err.message);
      await dispatch(documentReadFailed());
    }
  };
};
export { getUserRecentBots, getDocumentList, saveDocument, getDocumentById, deleteDocument };

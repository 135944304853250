/* ------------------------------------------------------ */
/*                       Tool Status                      */
/* ------------------------------------------------------ */

/**
 *
 * It handles  the tool status of every tool
 *
 * It controls the visibility of each tool whether to show the tool to users or not
 *
 */

import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { Main } from '../styled';
import PageTitle from '../../components/PageTitle';
import ToolCards from './overview/ToolCards';

const ToolStatus = () => {
  const { allTools: toolList } = useSelector((store) => store.adminFeatures);

  const liveTools = toolList.filter(({ isVisible }) => isVisible === true);

  return (
    <>
      <PageTitle
        title="Tools"
        subTitle={
          <>
            <div>
              <span className="title-counter">Tools: </span>{' '}
              <span style={{ marginLeft: '2rem' }}>{toolList.length} </span>
              <br />
              <span className="title-counter">Live Tools: {liveTools.length} </span>
            </div>
          </>
        }
      />

      <Main>
        <Row gutter={15}>
          <Col md={24}>
            <ToolCards selectedCategory={'all'} />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default ToolStatus;

/* ------------------------------------------------------ */
/*                  Plan Duration Select                  */
/* ------------------------------------------------------ */
/**
 * Used Antd-Design https://ant.design/components/switch
 *
 * Methadology
 * The plan duration is chosen between monthly and yearly.
 * the styles of the antd switch is fully modified.
 * The component's layout is also altered. The optional text on the left and right sides has been added.
 *
 * By default the switch will false.
 */

/*
Used In 
  src/container/Trial/Trial.jsx
  src/container/Trial/Trialv2.jsx
*/

import React from 'react';
import PropTypes from 'prop-types';
import { SwitchStyled, SwitchStyledWrapper } from './styled';
import { Typography } from 'antd';

const { Text } = Typography;

/**
 * @boolean disabled: to disabled the switch
 * @object style: custom style
 * @boolean defaultChecked: it effect on the render
 * @string currentId: the current-id of the right and left text. currentId is used to highlight the selection
 * @string leftTextId: the left-text-id
 * @string rightTextId: the right-text-id
 * @string rightText: the text of right side
 * @string leftText: the text of left side
 * @func onChange: the change of switch
 * @boolean checked: the value of checked
 */

const PlanDurationSwitch = (props) => {
  const {
    disabled,
    style,
    defaultChecked,
    currentId,
    rightTextId,
    leftTextId,
    rightText,
    onChange,
    leftText,
    className,
    checked,
  } = props;

  return (
    <SwitchStyledWrapper checked>
      {/* checked and unchecked crosspond the color of the text.  */}
      {leftText && <Text className={`position-text ${currentId === leftTextId && 'active'} `}> {leftText} </Text>}
      {/* the checked value differentiate the selection of right and left side  */}
      <SwitchStyled
        checked={checked}
        disabled={disabled}
        defaultChecked={defaultChecked}
        style={style}
        className={className}
        onChange={onChange}
      />
      {/* right text */}
      {rightText && <Text className={`position-text ${currentId === rightTextId && 'active'} `}> {rightText} </Text>}
    </SwitchStyledWrapper>
  );
};

PlanDurationSwitch.defaultProps = {
  style: {},
  className: '',
  leftText: '',
  rightText: '',
  leftTextId: '',
  rightTextId: '',
  checked: false,
};

PlanDurationSwitch.propTypes = {
  defaultChecked: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  leftTextId: PropTypes.string,
  rightTextId: PropTypes.string,
};

export { PlanDurationSwitch };

/* ------------------------------------------------------ */
/*                   Prompt Modification                  */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * When a user selects the prompts form the modal, a prompt result is being sent here.
 * it modifies the text render UI, and it highlights the specific text. Plus a user can edit the higlighted text
 * Prompt text is specified around square brakcet like [ prompt text example ]
 *
 * if the promptResult have clear-input it means the there is chat is being initiated and there is atleast 1 message
 */

import React, { useState, useEffect } from 'react';
import { uniqueId } from '../../../utility/utility';

/**
 * inputCursor & startTyping:
 * promptResult: a prompt text is selected from the modal.
 * promptInput: if the user clicks on the highlighted text then it remove the text.
 * handleKeyDown: on Pressing the Enter button, a request is being sent. on pressing Shift + enter a new line enter
 */
const PromptModify = ({ inputCursor, startTyping, promptResult, promptInput, handleKeyDown }) => {
  const [promptResultDisplay, setpromptResultDisplay] = useState(null);

  // there is a html tag of span around the highlighted prompt text to differentiate the process.
  const clickPrompt = (e) => {
    var el = e.target;
    const { id, innerText, nodeName } = el;
    if (innerText.includes('[') && id && nodeName === 'SPAN') {
      document.getElementById(id).innerHTML = ' ';
      const element = document.getElementById(id);
      element.classList.add('remove-highlight');
    }
  };

  // this highlight the specific text of the prompt
  // on the bases of [ we are spliting the prompt text.
  useEffect(() => {
    if (!inputCursor) {
      const convertToHTML =
        promptResult &&
        promptResult.split(/(\[[^\]]+\])/).map((text, index) => {
          text.trim();
          if (text.includes('[')) {
            const uniId = uniqueId();
            return `<span id=${uniId} class="highlight-prompt-display">${text}</span>`;
          } else {
            // if the promptResult have clear-input it means the there is chat is being initiated and there is atleast 1 message
            if (text === 'clear-input') {
              document.getElementById('prompt-input-text').innerHTML = '<div> &nbsp </div>';
              return '<div> &nbsp </div>';
            } else {
              return text;
            }
          }
        });
      setpromptResultDisplay(convertToHTML);
    }
  }, [promptResult]);

  const onInput = (e) => {
    promptInput(e?.target?.innerText || '');
    startTyping(true);
  };

  return (
    // this a is customized input area with innerHTMl
    promptResultDisplay && (
      <div
        id="prompt-input-text"
        onClick={clickPrompt}
        className="prompt-editable-input"
        contentEditable={true}
        dangerouslySetInnerHTML={{ __html: promptResultDisplay.join('') }}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        onInput={onInput}
      />
    )
  );
};

export default PromptModify;

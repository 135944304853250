import styled from 'styled-components';

const ProjectSelectionStyled = styled.div`
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: 10px;
  }
  .sidetop-title {
    justify-content: space-between;
    padding-bottom: 2px;
    display: flex;
    width: calc(100% - 22px);
    .selected-project-text {
      color: #262262;
      font-size: 18px;
      font-family: 'Poppins-SemiBold';
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .project-text {
    font-size: 10px;
    color: #9979fd;
    font-family: 'Poppins-Regular';
  }
`;

export { ProjectSelectionStyled };

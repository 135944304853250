/* ------------------------------------------------------ */
/*                      BILLING UI V1                     */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * REACT_APP_V2_PRICE_MODEL is the enviourment variable to decide which pricing model should be active.
 * This Page renders the v1 functionality
 *
 *
 * This Page handles the plan subscriptions and able to redirect to stripe customer portal.
 * V1 pricing is the Starter and Crafter plan with fixed price.
 *
 * In Team Workspace, no user has access to the billing capability.
 * Team members are unable to view their team's invoices.
 * Team admin can only access its billing via Personal workspace.
 *
 * Upgrade Plan is used to upgrade the plan. The request is send to backend.
 *
 *
 * If the plan is already active and the user want to switch then PRORATION rate would apply
 */

import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Spin,
  Space,
  Switch as AntSwitch,
  Divider,
  Typography,
  Radio,
  Tooltip,
  Alert,
  Input,
  Tag,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TagOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { Button } from '../../components/Button';
import { planTiers } from '../../utility/stripePlan';
import CurrentPlan from './overview/CurrentPlan';
import {
  changeSubscription,
  changeSubscriptionStatus,
  checkPromoCode,
  deleteSubscription,
  getCustomerBalance,
  previewPriceChange,
} from '../../services/apiServices';
import { RedirectToCustomerPortal, StartSubscriptionWithCheckout } from '../../redux/Stripe/actionCreator';
import { changeProfileDetails } from '../../redux/AccountSettings/actionCreator';
import { addNotificationError, addNotificationInfo } from '../../components/utilities/notifications';
import BillingPopUps from './overview/BillingPopUps';
import { crafterPlanFeatures, starterPlanFeatures } from '../../utility/planFeaturePoints';
import { apiErrorMessage, capitalizeFirstLetter, currencyFormatting } from '../../utility/utility';
import {
  BILLING_PLAN_STATUS,
  BUTTON_YELLOW_GRADIENT,
  CRAFTER,
  INCOMPELTE_PLAN,
  MONTHLY_DURATION,
  PERSONAL_WORKSPACE,
  SELECT_NEW_PLAN,
  SHOW_ACTIVATE_NEW_PLAN_BUTTON,
  SHOW_BILLING_PLANS,
  DONT_SHOW_CANCEL_BUTTONS,
  SHOW_CANCEL_NOW_BUTTONS,
  SHOW_UPGRADE_BUTTONS,
  STARTER,
  TEAM_WORKSPACE,
  TRIAL,
  TRIAL_PLAN_CHANGE_DOWNGRADE,
  YEARLY_DURATION,
} from '../../constants';
import {
  PAGETILE,
  PLAN_BEING_UPDATED,
  SHOW_ADMIN_HANDLE_BILLING,
  SOME_THING_WENT_WRONG,
  CODE_EXPIRED,
  CODE_INVALID,
  CODE_MINIMUM_NOT_MEET,
  PROMO_CODE_VALID_BUT_NOT_APPLY_FOR_SELECTED_PRODUCT,
} from '../../constants/content';
import { Main } from '../styled';
import { BillingHeaderWrap, BillingStyled } from './styled';
import twoUsersImage from '../../static/settings/twoUsers.png';
import tickImage from '../../static/settings/tick.png';

const { Text, Title, Link } = Typography;

const Billing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planSelect, setPlanSelect] = useState(CRAFTER);
  const {
    activatedPlanDetails,
    reasonSubscriptionFailed,
    noTrialccPlanAcitivated,
    firstSubscriptionCharged,
    planStatus,
    plan,
    currentSubscription,
    currentInvoiceId,
    stripeId,
  } = useSelector((state) => state.fb.profile);
  const { adminBillingSettings } = useSelector((state) => state.billing);
  const { memberTeamsWithOutHold } = useSelector((state) => state.accountSettings);
  const { selectedWorkspace } = useSelector((state) => state.accountSettings);
  const { totalWordsCraft } = useSelector((state) => state.billing);
  const [dueToday, setDueToday] = useState(0);
  const [appliedBalance, setAppliedBalance] = useState(null);
  const [dueTodayLoading, setDueTodayLoading] = useState(false);
  const [subscriptionStatusLoading, setChangeSubscriptionLoading] = useState(false);
  const [activateNewPlanLoading, setActivateNewPlanLoading] = useState(false);
  const [upgradePlanLoading, setUpgradePlanLoading] = useState(false);
  const [codeParameters, setCodeParameters] = useState({
    id: '',
  });
  const [codeDiscountPrice, setCodeDiscountPrice] = useState(0);
  const [codeMessage, setCodeMessage] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [codeCouponId, setCodeCouponId] = useState(false);
  const [stripePortalLoading, setStripePortalLoading] = useState(false);
  const [stripeBillingLoading, setStripeBillingLoading] = useState(false);
  const [code, setCode] = useState('');
  const [popUpDisplay, setPopUpDisplay] = useState(false);

  const [starterSelectedPlan, setStarterSelectedPlan] = useState({
    quantity: 0,
    amount: 0,
  });
  const [crafterSelectedPlan, setCrafterSelectedPlan] = useState({
    quantity: 0,
    amount: 0,
  });
  const [displayBill, setDisplayBill] = useState({
    quantity: 0,
    amount: 0,
    planPriceWithoutDiscount: 0,
    planWords: 0,
  });

  const [planChecked, setPlanChecked] = useState({
    checked: false,
    selectedPlanInterval: MONTHLY_DURATION,
  });

  const {
    quantity: subscribedPlanQuantity,
    isCanceled,
    status: stripePlanStatus,
    planInterval,
    subscriptionLink,
  } = activatedPlanDetails || {};
  const totalLink = subscriptionLink && subscriptionLink.split('/');
  const subscriptionId_fromLink = totalLink && totalLink[totalLink - 1];

  const { invoiceStatus, paidStatus, amountDue, invoiceURL, description } = currentSubscription || {};

  // planTiers: It fetch the v1 pricing object array with all the required details in it such as quantity, amount and priceId
  const displayStarterTiers =
    planTiers &&
    planTiers.find(({ plan, interval }) => plan === STARTER && interval === planChecked.selectedPlanInterval);

  const displayCrafterTiers =
    planTiers &&
    planTiers.find(({ plan, interval }) => plan === CRAFTER && interval === planChecked.selectedPlanInterval);

  // when page loads first time.
  useEffect(() => {
    if (planSelect === CRAFTER) {
      const { quantity: crafterWords, planPrice: crafterAmount, planWords } = displayCrafterTiers;

      setCrafterSelectedPlan({
        quantity: crafterWords,
        amount: crafterAmount,
        priceId: displayCrafterTiers.stripePriceId,
      });

      // it is used to display the Bill details
      setDisplayBill({
        amount: crafterAmount,
        quantity: crafterWords,
        priceId: displayCrafterTiers.stripePriceId,
        planPriceWithoutDiscount: crafterAmount,
        planWords,
      });
    }

    if (planSelect === STARTER) {
      // By default it would be monthly
      const { quantity: starterWords, planPrice: starterAmount, planWords } = displayStarterTiers;
      setStarterSelectedPlan({
        quantity: starterWords,
        amount: starterAmount,
        priceId: displayStarterTiers.stripePriceId,
      });

      setDisplayBill({
        amount: starterAmount,
        quantity: starterWords,
        priceId: displayStarterTiers.stripePriceId,
        planPriceWithoutDiscount: starterAmount,
        planWords,
      });
    }
  }, [displayCrafterTiers, displayStarterTiers]);

  // cancel button doesnt show when the stripe plan is canceled or
  // when the user already cancelled the plan but there is some time left to be cancelled
  // Note we need to use the status for stripe status as it is canceled status from the stipe, but in visual it is expired.
  const showCancelButton = () => {
    let showCancelbtn = true;
    if (stripePlanStatus === undefined || DONT_SHOW_CANCEL_BUTTONS.includes(stripePlanStatus)) {
      showCancelbtn = false;
    } else {
      if (isCanceled) {
        showCancelbtn = false;
      }
    }
    return showCancelbtn;
  };

  useEffect(() => {
    // this has to change Annual Billing switch. true is to set Annual-billing interval
    `${planInterval}ly` === YEARLY_DURATION && onPlanIntervalChange(true);
  }, [planInterval]);

  const onPlanChange = (planSelect) => {
    setPlanSelect(planSelect);
    onCodeRemove();
    if (planSelect === CRAFTER) {
      const { quantity: crafterWords, planPrice: crafterAmount, planWords } = displayCrafterTiers;

      setCrafterSelectedPlan({
        quantity: crafterWords,
        amount: crafterAmount,
        priceId: displayCrafterTiers.stripePriceId,
      });
      setDisplayBill({
        amount: crafterAmount,
        quantity: crafterWords,
        priceId: displayCrafterTiers.stripePriceId,
        planPriceWithoutDiscount: crafterAmount,
        planWords,
      });
    }

    if (planSelect === STARTER) {
      // By default it would be monthly
      const { quantity: starterWords, planPrice: starterAmount, planWords } = displayStarterTiers;
      setStarterSelectedPlan({
        quantity: starterWords,
        amount: starterAmount,
        priceId: displayStarterTiers.stripePriceId,
      });
      setDisplayBill({
        amount: starterAmount,
        quantity: starterWords,
        priceId: displayStarterTiers.stripePriceId,
        planPriceWithoutDiscount: starterAmount,
        planWords,
      });
    }
  };

  const onPlanIntervalChange = (value) => {
    const selectedPlanInterval = value === true ? YEARLY_DURATION : MONTHLY_DURATION;
    setPlanChecked({
      checked: value,
      selectedPlanInterval,
    });
    onCodeRemove();
  };

  useEffect(() => {
    const { selectedPlanInterval } = planChecked;
    let selectedPlanPrice = 0;
    let selectedWords = 0;
    let planWords = 0;
    let selectedPriceId = null;
    if (planSelect === STARTER) {
      selectedPlanPrice = starterSelectedPlan.amount;
      selectedWords = displayStarterTiers.quantity;
      selectedPriceId = displayStarterTiers.stripePriceId;
      planWords = displayStarterTiers.planWords;
    } else if (planSelect === CRAFTER) {
      selectedPlanPrice = crafterSelectedPlan.amount;
      selectedWords = displayCrafterTiers.quantity;
      selectedPriceId = displayCrafterTiers.stripePriceId;
      planWords = displayCrafterTiers.planWords;
    }
    if (selectedPlanPrice && selectedWords) {
      if (selectedPlanInterval === YEARLY_DURATION) {
        const displayDiscountAmount = parseFloat(selectedPlanPrice) - parseFloat(codeDiscountPrice);
        setDisplayBill({
          amount: displayDiscountAmount,
          quantity: selectedWords,
          priceId: selectedPriceId,
          planPriceWithoutDiscount: selectedPlanPrice,
          planWords,
        });

        checkStripeBilling({
          quantity: selectedWords,
          priceId: selectedPriceId,
          amount: displayDiscountAmount,
        });
      } else if (selectedPlanInterval === MONTHLY_DURATION) {
        const displayDiscountAmount = parseFloat(selectedPlanPrice) - parseFloat(codeDiscountPrice);
        setDisplayBill({
          amount: displayDiscountAmount,
          quantity: selectedWords,
          priceId: selectedPriceId,
          planPriceWithoutDiscount: selectedPlanPrice,
          planWords,
        });

        checkStripeBilling({
          quantity: selectedWords,
          priceId: selectedPriceId,
          amount: displayDiscountAmount,
        });
      } else {
        setDisplayBill('');
      }
    }
  }, [planChecked, starterSelectedPlan, isCanceled, crafterSelectedPlan, stripePlanStatus, codeDiscountPrice]);

  // this checked whether to look for proration rate or whether to look for customer credit invoice
  const checkStripeBilling = ({ priceId, quantity, amount }) => {
    // check preview Proration will only be access to an active account or plan

    if (SHOW_UPGRADE_BUTTONS.includes(stripePlanStatus)) {
      setDueTodayLoading(true);
      const proration_date = Math.floor(Date.now() / 1000);

      // API /preview-price-change
      previewPriceChange({
        priceId,
        quantity,
        proration: proration_date,
        code: codeCouponId,
      })
        .then((response) => {
          const { dueToday, noUpcomingInvoice } = response.data;
          if (noUpcomingInvoice) {
            setDueToday('No further invoice');
            setDueTodayLoading(false);
            setAppliedBalance(null);
          } else {
            setDueToday(dueToday / 100);
            setDueTodayLoading(false);
            setAppliedBalance(null);
          }
        })
        .catch((error) => {
          console.log('errorr', error.message);
          setDueTodayLoading(false);
        });
    } else if ((stripePlanStatus === undefined || SELECT_NEW_PLAN.includes(stripePlanStatus)) && stripeId) {
      setDueTodayLoading(true);

      // API /balance
      getCustomerBalance()
        .then(({ data }) => {
          const { balance } = data.customer;
          const accountBalance = balance / 100;
          const calculateDueToday = amount + accountBalance;
          if (calculateDueToday <= 0) {
            setAppliedBalance(currencyFormatting.format(-amount));
            setDueToday(0);
          } else {
            setAppliedBalance(currencyFormatting.format(accountBalance));
            setDueToday(calculateDueToday);
          }
          setDueTodayLoading(false);
        })
        .catch((err) => {
          const { error } = apiErrorMessage(err);
          addNotificationError(error);
          setDueTodayLoading(false);
        });
    } else {
      setDueToday(amount); // those users who are not registered in stripe.
    }
  };

  const onPopUpCancel = () => {
    setPopUpDisplay({
      open: false,
      popUpType: null,
    });
  };

  // If the user chooses not to proceed with the specified plan choice, the popup occurs.
  // It serves as a reminder to the user that you choose a different plan.
  // This only displays on trial plans, and only if the user first chose the Crafter plan before switching to the Starter plan.
  const onUpgradeAction = () => {
    const { quantity } = displayBill;
    if (plan === TRIAL && quantity < subscribedPlanQuantity) {
      setPopUpDisplay({
        open: true,
        popUpType: TRIAL_PLAN_CHANGE_DOWNGRADE,
      });
    } else {
      upgradePlan();
    }
  };

  const clickChangePlan = () => {
    upgradePlan();
    setPopUpDisplay({
      open: false,
      popUpType: null,
    });
  };

  // Apply Subscription, This sends the subscription request to cloud-function
  const upgradePlan = () => {
    const activateNow = Math.floor(Date.now() / 1000);
    const { subscriptionId } = currentSubscription;
    const { quantity, priceId } = displayBill;
    setUpgradePlanLoading(true);
    const checkSubId = subscriptionId || subscriptionId_fromLink;

    // API /change-subscription
    changeSubscription({
      subID: checkSubId,
      quantity,
      activateNow,
      priceId,
      code: codeCouponId,
    })
      .then((response) => {
        setUpgradePlanLoading(false);
        addNotificationInfo(PLAN_BEING_UPDATED);
      })
      .catch((error) => {
        setUpgradePlanLoading(false);
        addNotificationError(SOME_THING_WENT_WRONG);
        console.log(error.response);
      });
  };

  // Cancel Subscription ,this cancel the current subscription
  const onChangeSubscriptionStatus = ({ status }) => {
    const { subscriptionId } = currentSubscription;
    const checkSubId = subscriptionId || subscriptionId_fromLink;

    setChangeSubscriptionLoading(true);

    // API /subscription-status
    changeSubscriptionStatus({
      subId: checkSubId,
      status,
    })
      .then((response) => {
        setChangeSubscriptionLoading(false);
        addNotificationInfo(PLAN_BEING_UPDATED);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  // DELETE Subscription option appears when there the subscription status is INCOMPLETE or PAST_DUE
  const deleteSubscriptionNow = () => {
    const { subscriptionId } = currentSubscription;
    const checkSubId = subscriptionId || subscriptionId_fromLink;

    setChangeSubscriptionLoading(true);

    // API: /cancel-now Cloud-Function
    deleteSubscription({
      subId: checkSubId,
      currentInvoiceId,
    })
      .then((response) => {
        setChangeSubscriptionLoading(false);
        addNotificationInfo(PLAN_BEING_UPDATED);
      })
      .catch((error) => {
        addNotificationError(SOME_THING_WENT_WRONG);
        console.log(error.response);
      });
  };

  // Activate plan option appears when plan is CANCELED, EXPIRED and INCOMPLETE_EXPIRED or NA
  // user can start the subscription with stripe checkout
  const onActivateNewPlan = () => {
    const { priceId } = displayBill;
    setActivateNewPlanLoading(true);

    // redirect to stripe checkout. opens the stripe checkout
    dispatch(
      StartSubscriptionWithCheckout({
        quantity: displayBill.quantity,
        priceId,
        redirectURL: `${window.location.origin}/settings/billing`,
        allow_promotion_codes: false,
        promotion_code: codeParameters.id,
      }),
    );
  };

  const setOriginalPlan = () => {
    const getPlanPrice = planTiers
      .filter(({ interval }) => interval === `${planInterval}ly`)
      .map(({ tiers, plan }) => {
        const getAmountPlanName = tiers.find(({ quantity }) => quantity === subscribedPlanQuantity);
        return getAmountPlanName && { plan, amount: getAmountPlanName.amount };
      })
      .filter(Boolean)[0];

    setPopUpDisplay({
      open: false,
      popUpType: null,
    });
  };

  // Billing page is not show to team workspace
  const showTeamBilling =
    selectedWorkspace &&
    memberTeamsWithOutHold &&
    memberTeamsWithOutHold.length > 0 &&
    selectedWorkspace.workspaceType === TEAM_WORKSPACE;

  // showNoTeamPlan is not visible to team workspace
  // This also includes for team members. Team members cant access billing page
  const showNoTeamPlan =
    selectedWorkspace &&
    memberTeamsWithOutHold &&
    memberTeamsWithOutHold.length === 0 &&
    selectedWorkspace.workspaceType === TEAM_WORKSPACE;

  // show billing page is visible.
  const showPersonalOtherPlanBilling =
    memberTeamsWithOutHold &&
    memberTeamsWithOutHold.length > 0 &&
    SHOW_BILLING_PLANS.includes(plan) &&
    !BILLING_PLAN_STATUS.includes(planStatus) &&
    selectedWorkspace &&
    selectedWorkspace.workspaceType === PERSONAL_WORKSPACE;

  // billing page is visible
  const showPersonalTeamBilling =
    memberTeamsWithOutHold &&
    memberTeamsWithOutHold.length > 0 &&
    !SHOW_BILLING_PLANS.includes(plan) &&
    selectedWorkspace &&
    selectedWorkspace.workspaceType === PERSONAL_WORKSPACE;

  // Normal user is with peronal workspace
  const NormalUser =
    memberTeamsWithOutHold &&
    memberTeamsWithOutHold.length === 0 &&
    selectedWorkspace &&
    selectedWorkspace.workspaceType === PERSONAL_WORKSPACE;

  const userAlreadyActivePlan =
    memberTeamsWithOutHold &&
    memberTeamsWithOutHold.length > 0 &&
    BILLING_PLAN_STATUS.includes(planStatus) &&
    selectedWorkspace &&
    selectedWorkspace.workspaceType === PERSONAL_WORKSPACE;

  const checkCurrentPlanSelected =
    displayBill.quantity === subscribedPlanQuantity &&
    planChecked.selectedPlanInterval === `${planInterval}ly` &&
    planSelect === plan &&
    plan !== TRIAL;

  const showOpenInvoice =
    paidStatus === false && invoiceStatus === 'open' && INCOMPELTE_PLAN.includes(stripePlanStatus);

  const onSendInvoiceChange = (values) => {
    dispatch(changeProfileDetails({ settingsEmailPaidInvoice: values }, true));
  };

  const onCodeClick = ({ autoCode }) => {
    let approve = true;
    let promoCode = autoCode || code;
    const planDetails = adminBillingSettings.find(({ plan }) => plan === planSelect);
    const { productID } = planDetails.details;

    setCodeCouponId(false);

    if (approve) {
      setCodeLoading(true);
      // check Promo Code API /check-promotion-code
      checkPromoCode({ checkCode: promoCode })
        .then((response) => {
          if (response.data.error) {
            setCodeLoading(false);
            console.log('response.data.error', response.data.error);
          }
          setCodeLoading(false);
          if (response.data) {
            const {
              coupon: { id: couponId, amount_off, duration, duration_in_months, percent_off, valid, applies_to },
              restrictions: { minimum_amount },
              expires_at,
              customer,
              active,
              id,
            } = response.data;

            let allCheckClear = true;
            // Check all validations.

            //! check if the promocode is valid or not.
            if (valid === false && allCheckClear) {
              setCodeMessage({ message: CODE_INVALID, approveType: false });
              allCheckClear = false;
            }
            //! check if the promocode is active or not. if promoCode is expire it turns active into false.
            if (active === false && allCheckClear) {
              setCodeMessage({ message: CODE_EXPIRED, approveType: false });

              allCheckClear = false;
            }

            //! check if promoCode has applied on some specific products.
            if (applies_to && allCheckClear) {
              const { products } = applies_to;
              if (!products.includes(productID)) {
                setCodeMessage({
                  message: PROMO_CODE_VALID_BUT_NOT_APPLY_FOR_SELECTED_PRODUCT,
                  approveType: false,
                });

                allCheckClear = false;
              }
            }
            const planPriceInCents = parseFloat(displayBill.planPriceWithoutDiscount) * 100;
            if (minimum_amount && planPriceInCents < minimum_amount) {
              setCodeMessage({
                message: CODE_MINIMUM_NOT_MEET,
                approveType: false,
              });

              allCheckClear = false;
            }

            // If all promoCode validates
            if (allCheckClear) {
              setCodeParameters({
                duration,
                duration_in_months,
                percent_off,
                valid,
                minimum_amount,
                customer,
                expires_at,
                amount_off,
                active,
                id,
              });
              setCodeMessage(false);
              setCodeCouponId(couponId);

              if (amount_off) {
                const fixedAmount = parseFloat(amount_off / 100);
                setCodeDiscountPrice(`${parseFloat(fixedAmount.toFixed(2))}`); // this is discount price on the selected plan price
                setCodeMessage({ message: `$${amount_off / 100} off once`, approveType: true });
                setCodeCouponId(couponId);
              }
              if (percent_off) {
                const percentAmount = parseFloat(displayBill.planPriceWithoutDiscount) * parseFloat(percent_off / 100);
                if (duration && duration === 'once') {
                  setCodeMessage({ message: `${percent_off} off once,`, approveType: true });
                  setCodeDiscountPrice(`${parseFloat(percentAmount.toFixed(2))}`);
                }
                if (duration && duration === 'repeating') {
                  setCodeMessage({
                    message: `${percent_off}% off next ${duration_in_months} billing cycles.`,
                    approveType: true,
                  });
                  setCodeDiscountPrice(`${parseFloat(percentAmount.toFixed(2))}`);
                }
                if (duration && duration === 'forever') {
                  setCodeMessage({
                    message: `${percent_off}% off`,
                    approveType: true,
                  });
                  setCodeDiscountPrice(`${parseFloat(percentAmount.toFixed(2))}`);
                }
                setCodeCouponId(couponId);
              }
            }
          } else {
            setCodeMessage({
              message: CODE_INVALID,
              approveType: false,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            const { data } = error.response;
            setCodeMessage(false);
            setCodeLoading(false);
            console.log('error', data);
          }
        });
    } else {
      setCodeMessage({ message: CODE_INVALID, approveType: false });
    }
  };

  const onCodeRemove = () => {
    setCodeParameters(false);
    setCode('');
    setCodeDiscountPrice(0);
    setCodeMessage(false);
    setCodeCouponId(false);
  };

  const stripePortalRedirect = () => {
    setStripePortalLoading(true);
    dispatch(RedirectToCustomerPortal({ returnUrl: `${window.location.origin}/settings/billing` }));
  };
  const stripePortalBillingRedirect = () => {
    setStripeBillingLoading(true);
    dispatch(RedirectToCustomerPortal({ returnUrl: `${window.location.origin}/settings/billing` }));
  };

  const onCodeChange = (code) => {
    setCodeMessage(false);
    setCode(code);
  };

  // console.log('stripePlanStatus', stripePlanStatus);
  // console.log('planStatus', planStatus);

  const TeamAdministratorView = (
    <Main>
      <BillingHeaderWrap>
        <div className="page-title">Plan Details</div>
      </BillingHeaderWrap>
      <br />
      <Title level={4}> {SHOW_ADMIN_HANDLE_BILLING}</Title>{' '}
    </Main>
  );

  const showNextInvoice =
    plan === planSelect &&
    `${planInterval}ly` === planChecked.selectedPlanInterval &&
    !SHOW_ACTIVATE_NEW_PLAN_BUTTON.includes(stripePlanStatus);

  return (
    <>
      <Helmet>
        <title>{PAGETILE('Billing')}</title>
      </Helmet>
      {(showNoTeamPlan || showTeamBilling) && TeamAdministratorView}

      {/* these are those conditions to display Billing Users  */}
      {(showPersonalTeamBilling || showPersonalOtherPlanBilling || NormalUser || userAlreadyActivePlan) && (
        <BillingStyled planSelect={planSelect}>
          <div className="top-heading">
            <div className="heading">
              <Text className="plan-text"> Plan & Pricing</Text>
              <Text className="plan-description"> Simple pricing. No hidden fees. Customizable to you.</Text>
            </div>
            {/* only visible to those who have the subscription */}
            {activatedPlanDetails && (
              <Space className="action-billing-btn">
                <Button type="white" outlined loading={stripeBillingLoading} onClick={stripePortalBillingRedirect}>
                  View Billing History
                </Button>
                <Button
                  type="light"
                  className="cancel-subscription"
                  loading={stripePortalLoading}
                  onClick={stripePortalRedirect}
                >
                  Edit Payment Details
                </Button>
              </Space>
            )}
          </div>
          <div className="current-plan">
            <Row gutter={[18, 24]}>
              <Col xs={24} sm={24} md={16}>
                <CurrentPlan
                  selectedWorkspace={selectedWorkspace}
                  noTrialccPlanAcitivated={noTrialccPlanAcitivated}
                  firstSubscriptionCharged={firstSubscriptionCharged}
                  reasonSubscriptionFailed={reasonSubscriptionFailed}
                  activatedPlanDetails={activatedPlanDetails}
                  plan={plan}
                  planStatus={planStatus}
                  totalWordsCraft={totalWordsCraft}
                  description={description}
                />
                {showOpenInvoice && (
                  <div className="pending-invoice">
                    <Alert
                      showIcon
                      message="Payment Error"
                      description={
                        <Text>
                          <Text type="warning">{currencyFormatting.format(amountDue / 100)}</Text> payment was initiated
                          which requires an additional action.
                          <br /> You have 2 options. <br /> 1. You must complete an additional authentication step.
                          Click{' '}
                          <Link href={invoiceURL} target="_blank">
                            here
                          </Link>{' '}
                          to check on verify payment method
                          <br /> 2. Cancel subscription now and select plan again.
                        </Text>
                      }
                      type="error"
                    />
                  </div>
                )}
              </Col>
              {activatedPlanDetails && (
                <Col xs={24} sm={24} md={8}>
                  <div className="team-section">
                    <div className="top">
                      <img src={twoUsersImage} alt="twoUserImage" className="userImage" />
                      <div className="crafter-access-btn">Crafter Access Only</div>
                    </div>
                    <div className="team-action">
                      <Text className="team-text">Craftly for Teams </Text>
                      <Button
                        block
                        type={BUTTON_YELLOW_GRADIENT}
                        loading={false}
                        className="configure-btn"
                        onClick={() => navigate('/team-portal')}
                      >
                        Configure Teams
                      </Button>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </div>
          <div className="plan-type">
            <Text className="team-text">Edit Plan </Text>
            {/* <div className="plan-duration">
                  <Switch
                    currentId={planChecked.selectedPlanInterval}
                    leftTextId={MONTHLY_DURATION}
                    rightTextId={YEARLY_DURATION}
                    onChange={onPlanIntervalChange}
                    checked={planChecked.checked}
                  />
                </div> */}

            <Row gutter={16}>
              <Col sm={24} md={12}>
                <div
                  className={`plan-feature ${planSelect === CRAFTER && 'selected-plan'} `}
                  onClick={() => onPlanChange(CRAFTER)}
                >
                  <Row justify="space-between">
                    <Col xs={24} sm={18}>
                      <Radio
                        defaultChecked
                        checked={planSelect === CRAFTER ? true : false}
                        value={CRAFTER}
                        onChange={(e) => onPlanChange(e.target.value)}
                      >
                        <Text className="radio-text">Crafter</Text>
                      </Radio>
                      <div className={`feature-section`}>
                        <Text className="title"> {crafterPlanFeatures.title}</Text>
                        {crafterPlanFeatures.features.map((feature) => (
                          <div className="feature-text">
                            <img src={tickImage} alt="tickImage" className="tick" />
                            {feature}
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col xs={24} sm={6}>
                      <div className="starts-at">
                        <Text className="starts"> STARTS AT</Text>
                        <br />
                        <div className="start-at-price">
                          <Text className="plan-price">
                            {planChecked.selectedPlanInterval === YEARLY_DURATION
                              ? '$66'
                              : planChecked.selectedPlanInterval === MONTHLY_DURATION
                              ? '$79'
                              : ''}
                          </Text>
                          <Text> /mo </Text>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={24} md={12}>
                <div
                  className={`plan-feature ${planSelect === STARTER && 'selected-plan'}`}
                  onClick={() => onPlanChange(STARTER)}
                >
                  <Row justify="space-between">
                    <Col xs={24} sm={18}>
                      <Radio
                        defaultChecked
                        checked={planSelect === STARTER ? true : false}
                        value={STARTER}
                        onChange={(e) => onPlanChange(e.target.value)}
                      >
                        <Text className="radio-text"> Starter</Text>
                      </Radio>
                      <div className={`feature-section`}>
                        <Text className="title"> {crafterPlanFeatures.title}</Text>

                        {starterPlanFeatures.features.map((feature) => (
                          <div className="feature-text">
                            <img src={tickImage} alt="tickImage" className="tick" />
                            {feature}
                          </div>
                        ))}
                      </div>
                    </Col>
                    <Col xs={24} sm={6}>
                      <div className="starts-at">
                        <Text className="starts"> STARTS AT</Text>
                        <br />
                        <div className="start-at-price">
                          <Text className="plan-price">
                            {planChecked.selectedPlanInterval === YEARLY_DURATION
                              ? '$29'
                              : planChecked.selectedPlanInterval === MONTHLY_DURATION
                              ? '$35'
                              : ''}
                          </Text>
                          <Text> /mo </Text>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          <div className="detail-plan">
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <div className="detail">
                  <Text className="parameter-name">Plan Type</Text>
                  <div className="parameter">
                    <Text className="parameter-type">{capitalizeFirstLetter(planSelect)}</Text>
                    <div>
                      <Text className="amount">{currencyFormatting.format(displayBill.planPriceWithoutDiscount)}</Text>
                      <Text>
                        {planChecked.selectedPlanInterval === YEARLY_DURATION
                          ? '/year'
                          : planChecked.selectedPlanInterval === MONTHLY_DURATION
                          ? '/mo'
                          : ''}
                      </Text>
                    </div>
                  </div>
                  <Divider className="plan-divider" />
                </div>
                <div className="detail">
                  <Text className="parameter-name">
                    Credits{' '}
                    <Tooltip title="Delivered monthly">
                      <img src={require('../../static/trial/notice.svg')} alt="notice" />
                    </Tooltip>
                  </Text>
                  <div className="parameter">
                    <Text className="parameter-type">
                      {displayBill.planWords.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                      {planChecked.selectedPlanInterval === YEARLY_DURATION
                        ? '/year'
                        : planChecked.selectedPlanInterval === MONTHLY_DURATION
                        ? '/mo'
                        : ''}
                    </Text>
                  </div>
                  <Divider className="plan-divider" />
                </div>

                <div className="detail">
                  <div className="parameter-single">
                    <Text className="parameter-name">
                      Total{' '}
                      <Tooltip title="Total">
                        <img src={require('../../static/trial/notice.svg')} alt="notice" />
                      </Tooltip>
                    </Text>
                    <div>
                      <Text className="amount">{currencyFormatting.format(displayBill.amount)}</Text>
                      <Text>
                        {planChecked.selectedPlanInterval === YEARLY_DURATION
                          ? '/year'
                          : planChecked.selectedPlanInterval === MONTHLY_DURATION
                          ? '/mo'
                          : ''}
                      </Text>
                    </div>
                  </div>
                  <Divider className="plan-divider" />
                </div>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <div className="detail">
                  <div className="parameter-single">
                    <Text className="parameter-name">Annual Billing</Text>
                    <div>
                      <AntSwitch loading={codeLoading} onChange={onPlanIntervalChange} checked={planChecked.checked} />
                    </div>
                  </div>
                  <Divider className="plan-divider" />
                </div>

                <div className="detail">
                  <div className="discount-input-wrapper">
                    <Text className="discount-name">Discount Code</Text>

                    {codeParameters.id ? (
                      <div className="code-correct">
                        <Tag className="code-tag" closable onClose={onCodeRemove}>
                          <TagOutlined />
                          <Text className="tag-text"> {code}</Text>
                        </Tag>
                        <div>
                          <Text> -${codeDiscountPrice}</Text>
                        </div>
                      </div>
                    ) : (
                      <div className="discount-code-wrapper">
                        <div className="discount-field">
                          <Row gutter={[8, 8]}>
                            <Col xs={24} sm={24} md={14} lg={12}>
                              <Input
                                size="small"
                                placeholder="Input code"
                                className="code-input"
                                value={code}
                                onChange={(e) => {
                                  onCodeChange(e.target.value);
                                }}
                              />
                            </Col>
                            <Col flex="auto">
                              <div className="discount-code-input-field">
                                <Button
                                  disabled={code.length === 0 ? true : false}
                                  onClick={onCodeClick}
                                  outlined
                                  type="white"
                                  loading={codeLoading}
                                >
                                  Use Code
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div>
                          {codeMessage && (
                            <Text type={`${codeMessage.approveType === true ? 'success' : 'danger'}`}>
                              {codeMessage.message}
                            </Text>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <Divider className="plan-divider" />
                </div>

                <div className="detail">
                  {showNextInvoice ? (
                    <Text className="parameter-name">Next Invoice</Text>
                  ) : (
                    <Text className="parameter-name">Due Today</Text>
                  )}
                  <div className="parameter">
                    {stripePlanStatus === TRIAL ? (
                      <Text className="parameter-type">You will be not charged until your trial ends.</Text>
                    ) : appliedBalance ? (
                      <Text className="parameter-type">Applied Balance: {appliedBalance}</Text>
                    ) : (
                      <Text className="parameter-type">Payable Amount</Text>
                    )}
                    <div>
                      {dueTodayLoading ? (
                        <Spin size="small" />
                      ) : (
                        <Text className="amount">
                          {typeof dueToday === 'string' ? dueToday : currencyFormatting.format(dueToday)}
                        </Text>
                      )}
                    </div>
                  </div>
                  <Divider className="plan-divider" />
                </div>
              </Col>
            </Row>

            <Row justify="space-between" gutter={[32, 32]}>
              <Col>
                {showCancelButton() && (
                  <div className="action-button">
                    <Button
                      size={'large'}
                      type={BUTTON_YELLOW_GRADIENT}
                      loading={subscriptionStatusLoading}
                      onClick={() => onChangeSubscriptionStatus({ status: true })}
                      className="btn-renew"
                    >
                      Cancel Plan
                    </Button>
                  </div>
                )}
                {SHOW_CANCEL_NOW_BUTTONS.includes(stripePlanStatus) && (
                  <div className="action-button">
                    <Button
                      size={'large'}
                      type={BUTTON_YELLOW_GRADIENT}
                      loading={subscriptionStatusLoading}
                      onClick={deleteSubscriptionNow}
                      className="btn-renew"
                    >
                      Cancel Now
                    </Button>
                  </div>
                )}
                {isCanceled && !SHOW_ACTIVATE_NEW_PLAN_BUTTON.includes(stripePlanStatus) && (
                  <div className="action-button">
                    <Button
                      size={'large'}
                      type={BUTTON_YELLOW_GRADIENT}
                      loading={subscriptionStatusLoading}
                      onClick={() => onChangeSubscriptionStatus({ status: false })}
                      className="btn-renew"
                    >
                      Renew Plan
                    </Button>
                    <Text className="plan-status-text">
                      You already have an active plan! It is being cancelled on {activatedPlanDetails.expiringDate}.
                      <br />
                      To upgrade or downgrade your plan, please renew your existing plan and then select the plan that
                      best suits your needs.
                    </Text>
                  </div>
                )}
              </Col>
              <Col>
                {SHOW_UPGRADE_BUTTONS.includes(stripePlanStatus) && !isCanceled && (
                  <Col>
                    <Space>
                      {/* <div className="action-button">
                  <Button type="primary" loading={false} onClick={false} className="btn-upgrade">
                    One-time Top-up
                  </Button>
                  <Text className="text">Upgrade this month only</Text>
                </div> */}
                      <div className="action-button">
                        <Button
                          disabled={
                            dueTodayLoading || codeLoading || checkCurrentPlanSelected || (plan === TRIAL && false)
                          }
                          type="primary"
                          loading={upgradePlanLoading}
                          onClick={onUpgradeAction}
                          className="btn-upgrade"
                        >
                          Upgrade Plan
                        </Button>
                        <Text className="text"> Upgrade this plan permenantly</Text>
                      </div>
                    </Space>
                  </Col>
                )}
                {(stripePlanStatus === undefined || SHOW_ACTIVATE_NEW_PLAN_BUTTON.includes(stripePlanStatus)) && (
                  <Col>
                    <Space>
                      <div className="action-button">
                        <Button
                          type={'primary'}
                          loading={activateNewPlanLoading}
                          onClick={onActivateNewPlan}
                          className="btn-upgrade"
                        >
                          Activate Plan
                        </Button>
                        <Text className="text">Activate New Plan</Text>
                      </div>
                    </Space>
                  </Col>
                )}
              </Col>
            </Row>
          </div>
        </BillingStyled>
      )}

      {/* Billing Popups display to notify the users about the selections */}
      <BillingPopUps
        open={popUpDisplay.open}
        onCancel={onPopUpCancel}
        onChangePlan={() => clickChangePlan()}
        onOriginalPlan={setOriginalPlan}
        popUpType={popUpDisplay.popUpType}
      />
    </>
  );
};

export default Billing;

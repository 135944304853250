/* ------------------------------------------------------ */
/*                      CAI-CHAT TOOL                     */
/* ------------------------------------------------------ */

/* ---- THE COMPLETE UI OF CAI CHAT TOOL RENDER FROM HERE.  --- */

/**
 * Methadology
 * This UI is designed to render when a user selects a CAI CHAT TOOL. Normally we do have a static UI of every TOOL but for CAI CHAT we have different UI
 *
 * Firestore:
 * Collection: cai-chat-tool -> Document: <UID> -> subCollection: history -> subDocument: botId or output Id
 *
 * The UI is desgined to work as a messages. All the previous requests are sent to the open-ai inorder to produce outputs.
 *
 * PLAN CRAFTER or TEAM or TRIAL are allowed to access this tool
 *
 * Fetch the previous CAI-CHAT data according to selected project id
 * Each output is connected to the project, and each worksapace has its own projects.
 * It always retrieves outputs created for a specific project.-id
 *
 */

import React, { useState, useRef, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Skeleton, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CHAT_TOOL_KEY } from '../../constants';
import { Modal } from '../../components/Modal';
import Prompts from './overview/Prompts';
import DefaultCaiChat from './overview/DefaultCaiChat';
import PromptModify from './overview/PromptModify';
import { Button } from '../../components/Button';
import ChatMessage from './overview/ChatMessage';
import { delay, uniqueId } from '../../utility/utility';
import BreadcrumbCai from '../../components/Breadcrumb';
import { deleteSavedCaiChat, fetchCaiChatHistory, saveCaiChatHistory } from '../../redux/UserProject/actionCreator';
import { BUTTON_PRIMARY, CAI_CHAT_INPUT, CAI_CHAT_OUTPUT } from '../../constants';
import { CaiChatStyled } from './styled';
import SendImg from '../../static/caichat/cai-chat-send.png';
import ChatImg from '../../static/caichat/chat.png';
import DeleteImg from '../../static/general/delete.png';
import ChatNewImg from '../../static/caichat/chat-new.png';
import PromptBrowseImg from '../../static/caichat/prompt-browse.png';
import saveImg from '../../static/general/save.png';

const { Text } = Typography;

// The props are coming from ToolDesign File

/**
 * @toolId chai chat tool id
 * @displayToolName tool name
 * @fields every tool have their own fields to populate the UI. overhere it is checking the type of area-text
 * @maxLimitExceed func to check the input text length of every field. every  input field have max number of characters
 * @toolBody every tool body have own set of fields
 * @onChangeText func to deduct the change of fields. it stores the data in state
 * @toolOutputLoading loading time to get the result from
 * @isFormValid is all checks are clear
 * @onSubmit on clicking the submut button
 * @allowTemplateOnPlan each plan have their access level reagarding the tools.
 * @output output from the openai
 * @toolHitFailed field response from open ai. this reset the process
 * @craftedToolId flag to identify the response of this tool response if this toolid
 */
const CaiChat = ({
  toolId,
  displayToolName,
  fields,
  maxLimitExceed,
  toolBody,
  onChangeText,
  toolOutputLoading,
  isFormValid,
  onSubmit,
  allowTemplateOnPlan,
  output,
  toolHitFailed,
  craftedToolId,
}) => {
  const [copySuccess, setCopySuccess] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const [promptInputText, setPromptInputText] = useState(null);
  const [inputCursor, setInputCursor] = useState(null);

  // recentProject: This project is either the team workspace or personal workspace project.
  // caiChatHistoryOutput : this is the history output from the firestore collection
  const { recentProject, caiChatHistoryOutput } = useSelector((state) => state.userProject);

  const dispatch = useDispatch();
  const { content } = output || { content: null };
  const { html } = content || { html: false };
  let chatLatestContentId = '';
  let chatOutput = '';

  // this is to identify the response of this tool is cai-chat
  if (content && Array.isArray(content) && content.length > 0 && craftedToolId === CHAT_TOOL_KEY) {
    chatLatestContentId = content[0].contentId;
    chatOutput = content[0].contentData;
  }

  // this is used to render those chat who are save on specific projects.
  useEffect(() => {
    const projectId = recentProject?.projectId;
    // this is fetching the previous records of the cai-chat
    // it fetch the chats accordin to selected projectid
    dispatch(fetchCaiChatHistory({ recentProjectId: projectId }));
    setChatHistory([]);
  }, [recentProject]);

  useEffect(() => {
    if (toolHitFailed) {
      const updateLoadingChat = chatHistory.filter(({ loading }) => loading !== true);
      setChatHistory([...updateLoadingChat]);
    }
  }, [toolHitFailed]);

  useEffect(() => {
    const checkContentId = chatHistory.find(({ contentId }) => contentId === chatLatestContentId);
    if (content && Array.isArray(content) && chatLatestContentId && !checkContentId) {
      const updateHistory = {
        displayType: CAI_CHAT_OUTPUT,
        text: chatOutput,
        loading: false,
        contentId: chatLatestContentId,
      };
      const updateLoadingChat = chatHistory.filter(({ loading }) => loading !== true);
      setChatHistory([...updateLoadingChat, updateHistory]);
      scrollToBottom(400);
    }
  }, [chatLatestContentId]);

  const messagesEndRef = useRef(null);

  const scrollToBottom = async (timerDelay) => {
    await delay(timerDelay);
    const scroll = messagesEndRef.current.scrollHeight - messagesEndRef.current.clientHeight;

    messagesEndRef.current.scrollTo({
      top: scroll + 200,
      behavior: 'smooth',
      block: 'end',
    });
  };

  const onChatSubmit = () => {
    const updateChat = [
      {
        displayType: CAI_CHAT_INPUT,
        text: toolBody[inputData.key],
        loading: false,
      },
      {
        displayType: CAI_CHAT_OUTPUT,
        text: null,
        loading: true,
      },
    ];

    chatHistory && chatHistory.length > 0
      ? setChatHistory([...chatHistory, ...updateChat])
      : setChatHistory([...updateChat]);
    let inputHistoryRequest = '';
    if (chatHistory && chatHistory.length > 0) {
      inputHistoryRequest = chatHistory
        .filter(({ text }) => text)
        .map(({ text, displayType }) => {
          return { text, displayType };
        });
    }

    onSubmit({
      extraInputs: { input_2: inputHistoryRequest },
      clearInputField: 'input_1',
    });
    scrollToBottom(100);
  };

  const inputData = fields && fields.find(({ type }) => type === 'textarea');

  const onCaiChatClear = () => {
    setSelectedChatId(null);
    setChatHistory([]);
  };

  // chat data is saved according to selected workspace and project id
  const onCaiChatSave = () => {
    const title = chatHistory[0].text;
    const caiChatDocId = selectedChatId || uniqueId();
    setSelectedChatId(caiChatDocId);
    dispatch(saveCaiChatHistory({ title, caiChatOutput: chatHistory, caiChatId: caiChatDocId, toolId }));
  };

  // it deletes the chat from firebase store
  const deleteChat = (caiChatId) => {
    dispatch(deleteSavedCaiChat({ caiChatId }));
    setSelectedChatId(null);
    setChatHistory([]);
  };

  // on Clicking on the previous chat, it display all the chat messages in the center block
  const displaySavedChat = (savedCaiChatId) => {
    setSelectedChatId(savedCaiChatId);
    const getSavedChat =
      caiChatHistoryOutput &&
      Array.isArray(caiChatHistoryOutput) &&
      caiChatHistoryOutput.find(({ caiChatId }) => {
        return caiChatId === savedCaiChatId;
      });

    const displayChat = getSavedChat ? JSON.parse(getSavedChat.history) : [];
    setChatHistory(displayChat);
  };

  const onBrowsePromptClick = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // When a user select the prompt from the modal, it automatically replace the text in the input area
  const builtInPrompt = (selectedPrompt) => {
    inputData &&
      onChangeText({
        target: {
          name: inputData.key,
          value: selectedPrompt.replaceAll('<br/>', '\n'),
        },
      });
    setIsModalOpen(false);
    setInputCursor(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      getLatestInput();
    }
  };

  // when the user writes any thing on the input text area.
  // due to it is cusomtized we are getting the text from elementID
  useEffect(() => {
    if (inputData) {
      const inputTextInput = document.getElementById('prompt-input-text')?.innerText;
      inputTextInput &&
        onChangeText({
          target: {
            name: inputData.key,
            value: inputTextInput,
          },
        });
    }
  }, [promptInputText]);

  const getLatestInput = () => {
    setInputCursor(false);
    toolBody[inputData.key] && onChatSubmit();
  };

  // to reset the copy icon
  useEffect(() => {
    !copySuccess &&
      setInterval(function () {
        setCopySuccess(null);
      }, 6000);
  }, [copySuccess]);
  return (
    <CaiChatStyled chatSelected>
      <Row>
        <Col xs={24} sm={24} md={17}>
          <div className="chat-side">
            <div className="top">
              <BreadcrumbCai firstLink={'/category'} firstLinkTitle="Tool Library" secondLinkTitle={displayToolName} />
              <Space>
                {chatHistory && chatHistory.length > 0 && (
                  <>
                    <Button
                      onClick={onCaiChatClear}
                      icon={<CloseOutlined />}
                      size={'small'}
                      type={BUTTON_PRIMARY}
                      transparentwithoutline
                      className="header-action-btn"
                    >
                      Clear
                    </Button>

                    <Button
                      onClick={onCaiChatClear}
                      size={'small'}
                      type={BUTTON_PRIMARY}
                      transparentwithoutline
                      className="header-action-btn"
                    >
                      <img src={ChatNewImg} alt="new-chat" /> New Chat
                    </Button>

                    <Button
                      disable={toolOutputLoading}
                      onClick={onCaiChatSave}
                      size={'small'}
                      type={BUTTON_PRIMARY}
                      className="header-action-btn"
                    >
                      <img src={saveImg} alt="new-chat" /> Save
                    </Button>
                  </>
                )}
              </Space>
            </div>
            <div ref={messagesEndRef} className="cai-chat-messages">
              {chatHistory && Array.isArray(chatHistory) && chatHistory.length > 0 ? (
                chatHistory.map(({ displayType, text, loading, contentId }, index) => {
                  return (
                    <ChatMessage
                      displayType={displayType}
                      copySuccess={copySuccess}
                      text={text}
                      setCopySuccess={setCopySuccess}
                      index={index}
                      html={html}
                      loading={loading}
                      contentId={contentId}
                    />
                  );
                })
              ) : (
                <DefaultCaiChat />
              )}
            </div>

            <div className="input-section">
              <div className="input-text">
                <Skeleton loading={!inputData} block active size={'small'} paragraph={{ rows: 1 }} />
                {inputData && (
                  <div className="prompt-input">
                    <div>
                      <Button onClick={onBrowsePromptClick} className="prompt-btn" type={BUTTON_PRIMARY}>
                        <img style={{ width: '16.67px', height: '18.41px' }} src={PromptBrowseImg} alt="new-chat" />
                        Browse Prompts
                      </Button>
                    </div>
                    <div style={{ width: '100%' }}>
                      {React.createElement(PromptModify, {
                        key: `rep`,
                        promptResult:
                          toolBody[inputData.key] ||
                          (chatHistory && chatHistory.length > 0 ? 'clear-input' : inputData.placeholder),
                        toolOutputLoading: { toolOutputLoading },
                        handleKeyDown: (e) => handleKeyDown(e),
                        promptInput: (e) => setPromptInputText(e),
                        startTyping: (e) => setInputCursor(e),
                        inputCursor,
                      })}
                    </div>
                  </div>
                )}
                <Button
                  onClick={getLatestInput}
                  loading={toolOutputLoading}
                  disabled={!isFormValid() || maxLimitExceed().check || !allowTemplateOnPlan}
                  className="cai-send-btn"
                  type="primary"
                  icon={<img src={SendImg} alt="SendImg" size="large" />}
                />
              </div>
            </div>
          </div>
        </Col>

        {/* Saved Chats fetched from firestore collections */}
        <Col xs={24} sm={24} md={7}>
          <div className="history-side">
            <div className="saved-chat">
              <Text className="saved-chat-text"> Saved Chats</Text>
            </div>
            <div className="history-messages">
              {caiChatHistoryOutput &&
                caiChatHistoryOutput.map(({ title, caiChatId }) => {
                  return (
                    <div className={`history-chat  ${selectedChatId === caiChatId && 'history-chat-selected'}`}>
                      <div className="history-text-wrapper">
                        <img className="chat-img" src={ChatImg} alt="chat-img" />{' '}
                        <Text onClick={() => displaySavedChat(caiChatId)} className="history-text">
                          {title}
                        </Text>
                      </div>
                      <div className={`history-action ${selectedChatId === caiChatId && 'history-action-selected'}`}>
                        <img onClick={() => deleteChat(caiChatId)} src={DeleteImg} alt="delete-img" />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Col>
      </Row>
      {/* Prompt Modal Display */}
      <Modal
        style={{ top: 30 }}
        width={1000}
        title=""
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Prompts builtInPrompt={builtInPrompt} />
      </Modal>
    </CaiChatStyled>
  );
};

export default CaiChat;

/* ------------------------------------------------------ */
/*                    Project Selection                   */
/* ------------------------------------------------------ */

/*
Methadology:

This component opens the Project Modal.
When you click the Project button, Modal appears.

The display of the project list is bases on the selected workspace.
Each Workspace have their own project list. 
There are 2 types of workspace. 
1. Personal Workspace 
2. Team workspace

Workspace Selection UI displays for only team admin or team members. Normal users dont have multiple workspace. 
The default workspace for team admin and user is Personal Workspace.

These are following actions  
1. Add New Project
2. Delete Project
3. View the project list.

There are 3 case scenerios  
1. User
    a User can create new  project, delete project and view project list.
2. Team Admin 
    A team admin can create new projects, delete existing ones, and browse the project list while on the PERSONAL WORKSPACE.
    All team members have access to the all project list.
    A team admin can do all these actions on personal workspace. if the admin switch workspace to team workspace then the user will be treated as team member.
3. Team member
    Each team member has the ability to choose the project. 
    Dont have permission to delete the project and create a new one. 

*/

/*
Used In 
  src/layout/new-designs/sidebar/Sidebar.jsx
  src/layout/MobileProjectToolSelection.js
*/

import React, { useEffect, useState } from 'react';
import { Skeleton, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { PERSONAL_WORKSPACE, TEAM_WORKSPACE } from '../../constants';
import CreateProject from '../CreateProject';
import { saveSelectedCurrentProject } from '../../redux/UserProject/actionCreator';
import { ProjectSelectionStyled } from './styled';
import Swap from '../../static/sidebar/Swap.svg';

const { Text } = Typography;

const ProjectSelection = () => {
  const dispatch = useDispatch();

  const {
    data: userProjectsList, // personal workspace userProjectList
    recentProject, // recentProject is the personal workspace current selected project
    teamRecentProject, // team workspace teamRecentProject is the current team selected project
    loading: projectLoading,
  } = useSelector((store) => store.userProject);

  // this determine whether the project modal is open or not
  const [projectModalOpen, setProjectModalOpen] = useState(false);

  // selected workspace is either team or personal workspace.  selectedWorkspace object have workspace type and selectedUID.
  // selectedUID in team workspace is Team-owner-UID and on Personal workspace is the users UID
  const { selectedWorkspace } = useSelector((store) => store.accountSettings);

  const { selectedUID } = selectedWorkspace || {};
  const { allTeamRecentProject } = teamRecentProject || {};
  const getTeamRecentProjectName =
    teamRecentProject && allTeamRecentProject[selectedUID] && allTeamRecentProject[selectedUID].project;

  const onProjectModalOpen = () => {
    setProjectModalOpen(true);
  };

  const onProjectModalClose = () => {
    setProjectModalOpen(false);
  };

  let currentSelectedProject = recentProject; // it is the personal workspace current selected project

  // If the workspace is Teamworkspace, it fetch the team recent from team workspace
  if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
    const { allTeamRecentProject } = teamRecentProject || {}; // it contains all the team workspaces.
    const { selectedUID } = selectedWorkspace || {};

    // To get team workspace projects, it would be filtered by team-owner UID, which is specified by 'selectedUID'.
    currentSelectedProject =
      teamRecentProject && allTeamRecentProject[selectedUID] && allTeamRecentProject[selectedUID];
  }

  useEffect(() => {
    currentSelectedProject && dispatch(saveSelectedCurrentProject({ currentSelectedProject }));
  }, [currentSelectedProject]);

  /* 
    This component is renders on the sidebar of menu. 
    It highlights the current Selected Project, regardless of whether the workspace is team or personal.
  */
  return (
    <div>
      <ProjectSelectionStyled onClick={onProjectModalOpen}>
        <Text className="project-text">Projects</Text>

        {projectLoading ? (
          <Skeleton.Input block active size="small" /> // antd loading
        ) : (
          <div className="sidetop-title">
            {/* A User or Team Owner can access project features on Personal workspace */}
            {selectedWorkspace && selectedWorkspace.workspaceType === PERSONAL_WORKSPACE && (
              <Text className="selected-project-text">
                {userProjectsList && userProjectsList.length > 0 ? recentProject.project : 'Add New Project'}
              </Text>
            )}

            {/* Team Workspace can only change the projects. */}
            {selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE && (
              <Text className="selected-project-text">
                {teamRecentProject ? getTeamRecentProjectName : 'Select Project'}
              </Text>
            )}

            <img src={Swap} className="dropdown" alt="" />
          </div>
        )}
      </ProjectSelectionStyled>

      <CreateProject onCancel={onProjectModalClose} open={projectModalOpen} />
    </div>
  );
};

export default ProjectSelection;

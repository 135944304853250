/* ------------------------------------------------------ */
/*                     AUTHENTICATION                     */
/* ------------------------------------------------------ */

/**
 * It handled the authentication actions
 * New user account creates here
 */
import { addNotificationError, addNotificationSuccess } from '../../../components/utilities/notifications';
import { NA, ROLES, STATUS, WITH_PLAN_STATUS } from '../../../constants';
import { userMappingStatus } from '../../../utility/userProperties';
import { userProjectDataRead } from '../../UserProject/actionCreator';
import actions from './actions';
import firebase from 'firebase/app';
import axios from 'axios';
import platform from 'platform';
import { allSubscribers, checkSegmentConfig } from '../../../utility/utility';
import { FirebaseAnalytics } from '../../../config/firebase';
import { getUserTodaysActivity } from '../../Billing/actionCreator';

const {
  fbLoginBegin,
  fbLoginSuccess,
  fbLoginErr,
  fbLogOutBegin,
  fbLogOutSuccess,
  fbLogOutErr,
  fbSignUpBegin,
  fbSignUpSuccess,
  fbSignUpErr,
  setNewPassword,
  setNewPasswordSuccess,
  setNewPasswordFailed,
  resetErrorMessage,
} = actions;

/**
 * This function handles 3 conditions in creating a new user
 * 1. When a new user signup.
 * 2. When admin sends invite to user
 * 3. When user account is deleted and he try to signup again.
 */
const createNewUser = ({ uid, newUser }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { email, name, terms, firstName, lastName, referralLink } = newUser;
    // this check the user, if it is already present or the admin sents the invite or not
    await db
      .collection('users')
      .where('email', '==', email)
      .get()
      .then(async (querySnapshot) => {
        let userAdminData = null;
        let userUID = '';
        querySnapshot.forEach((doc) => {
          userAdminData = doc.data();
          userUID = doc.id;
        });
        // 2. When admin sends invite to user, if admin invite has already send invite to this specific email
        if (userAdminData && userAdminData.inviteDocId && userAdminData.status !== STATUS.DELETED) {
          await db
            .collection('users')
            .doc(uid)
            .set(
              {
                email,
                firstName,
                lastName,
                terms,
                name: userAdminData.name,
                referralLink,
                requiredSetPassword: false,
                inviteDocId: userAdminData.inviteDocId,
                acceptInvite: true,
                username: '',
                picture: newUser.picture || '',
                isApproved: userAdminData.isApproved,
                createdDate: new Date(),
                position: '',
                plan: NA,
                roles: userAdminData.roles,
                status: userAdminData.status,
                planStatus: NA,
                displayPlanStatus: userMappingStatus({ status: userAdminData.status }),
                thirtyFiveStarter: true,
                loggedStatus: true,
                displayV2Popup: false,
              },
              { merge: true },
            )
            .then(async () => {
              await db.collection('users').doc(userAdminData.inviteDocId).delete();
              setupCustomerIO({ uid, name, firstName, lastName, email });
            });
        } else if (userAdminData && userAdminData.status === STATUS.DELETED) {
          // 3.  When user account is deleted and the user try to signup with the same account
          db.collection('users')
            .doc(uid)
            .set(
              {
                email,
                name,
                terms,
                firstName,
                lastName,
                username: '',
                picture: newUser.picture || '',
                provider: newUser.provider,
                isApproved: false,
                createdDate: new Date(),
                position: '',
                plan: NA,
                roles: ROLES.USER,
                status: userAdminData.status,
                planStatus: NA,
                displayPlanStatus: userMappingStatus({ status: userAdminData.status }),
                thirtyFiveStarter: true,
                loggedStatus: true,
                displayV2Popup: false,
              },
              { merge: true },
            )
            .then(async () => {
              await db.collection('users').doc(userUID).delete();
              setupCustomerIO({ uid, name, firstName, lastName, email });
            });
        } else {
          // 1.  This condition is when a NEW user signup.
          db.collection('users')
            .doc(uid)
            .set(
              {
                email,
                name,
                firstName,
                lastName,
                terms,
                referralLink,
                username: '',
                picture: newUser.picture || '',
                provider: newUser.provider,
                isApproved: newUser.isApproved,
                createdDate: new Date(),
                position: '',
                plan: NA,
                roles: ROLES.USER,
                status: STATUS.ACTIVE,
                planStatus: NA,
                deactivateByAdmin: false,
                displayPlanStatus: WITH_PLAN_STATUS.APPROVED,
                thirtyFiveStarter: true,
                loggedStatus: true,
                phoneNumberRequired: false,
                displayV2Popup: false,
              },
              { merge: true },
            )
            .then(async () => {
              if (checkSegmentConfig()) {
                setupCustomerIO({ uid, name, firstName, lastName, email });
              }
            });
        }
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
      });
  };
};

const setupCustomerIO = ({ uid, name, firstName, lastName, email }) => {
  window.analytics.identify(uid, {
    name,
    firstName,
    lastName,
    email,
    userId: uid,
    roles: ROLES.USER,
    plan: NA,
    status: WITH_PLAN_STATUS.APPROVED, //this is for customer.io
  });
  window.analytics.track('New User SignUp', {
    email,
    name,
    firstName,
    lastName,
    userId: uid,
    roles: ROLES.USER,
    plan: NA,
    status: WITH_PLAN_STATUS.APPROVED, //this is for customer.io
  });

  FirebaseAnalytics.logEvent('new_sign_up', {
    uid,
    name,
    firstName,
    lastName,
    email,
  });
  // Event snippet for Sign-up conversion page In your html page, add the snippet and call  when someone clicks on the chosen link or button.

  window.gtag('event', 'conversion', {
    send_to: 'AW-10848880884/UUjrCKDbv50DEPSRk7Uo',
    event_callback: () => {
      console.log('user came from ad');
      FirebaseAnalytics.logEvent('signup_with_ad', {
        uid,
        name,
        firstName,
        lastName,
        email,
      });
    },
  });
};

const emailLinkSignUp = (userDetails) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { email, provider, uid } = userDetails;
    try {
      await dispatch(fbSignUpBegin());
      await db
        .collection('users')
        .where('email', '==', email)
        .get()
        .then((querySnapshot) => {
          let userAdminData = null;
          querySnapshot.forEach((doc) => {
            userAdminData = doc.data();
          });
          if (userAdminData) {
            db.collection('users')
              .doc(uid)
              .set(
                {
                  requiredSetPassword: true,
                  inviteDocId: userAdminData.inviteDocId,
                  acceptInvite: true,
                  email,
                  name: userAdminData.name,
                  terms: false,
                  username: '',
                  picture: '',
                  provider: provider,
                  isApproved: userAdminData.isApproved,
                  createdDate: new Date(),
                  trailRemaining: '',
                  position: '',
                  roles: userAdminData.roles,
                  status: userAdminData.status,
                  planStatus: 'na',
                  displayPlanStatus: userMappingStatus({ status: userAdminData.status }),
                },
                { merge: true },
              )
              .then(() => {
                dispatch(fbSignUpSuccess());
                dispatch(firebaseAuthChange());
              });
          }
        })
        .catch((error) => {
          console.log('Error getting documents: ', error);
        });
    } catch (err) {
      console.log(`error`, err);
    }
  };
};

/**
 * Login with email / password
 * @param {email and password} data
 * @returns
 */
const fbAuthLogin = (data) => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    await dispatch(resetErrorMessage());
    await dispatch(fbLoginBegin());
    try {
      await fb
        .auth()
        .signInWithEmailAndPassword(data.email, data.password)
        .then(async () => {
          await dispatch(firebaseAuthChange());
          await dispatch(fbLoginSuccess(data));
        })
        .catch(async (error) => {
          dispatch(fbLoginErr(error));
        });
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

// SignOut Functionality.

const fbAuthLogout = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // All snapshots functions should have to be unsubscribed.
    allSubscribers.forEach((subscriber) =>
      subscriber && typeof subscriber.func === 'function' ? subscriber.func() : console.log(subscriber),
    );

    // setting the redux state like loading-state
    dispatch(fbLogOutBegin());
    const fb = getFirebase();

    try {
      // Firebase signout function
      fb.auth()
        .signOut()
        .then(() => {
          dispatch(fbLogOutSuccess());
          checkSegmentConfig() && window.analytics.reset();
        })
        .catch((err) => {
          console.log(`err signout`, err);
        });
    } catch (err) {
      console.log(`err`, err);
      await dispatch(fbLogOutErr(err));
    }
  };
};

/**
 * when a user enter email/password for signup.
 * @param { newUser - detials}  newUser
 * @returns
 */
// Email/Password Signup ActionCreator
const fbAuthSignUp = (newUser) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const fb = getFirebase();

    try {
      await dispatch(fbSignUpBegin());
      const resp = await fb.auth().createUserWithEmailAndPassword(newUser.email, newUser.password);
      const { email, name, terms, firstName, lastName, referralLink, phoneNumber } = newUser;
      const userDetails = {
        email,
        name,
        firstName,
        lastName,
        terms,
        picture: 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png',
        provider: 'email/password',
        isApproved: true,
        referralLink: referralLink || null,
        phoneNumber: phoneNumber || '',
      };
      const uid = resp.user.uid;
      // to set user details in user collection.
      await dispatch(createNewUser({ uid, newUser: userDetails }));
      await dispatch(fbSignUpSuccess());
      await dispatch(firebaseAuthChange());
    } catch (err) {
      await dispatch(fbSignUpErr(err));
    }
  };
};

// Google with SignUp and Login. Google automatically deduct whether the person has an account or not
const fbAuthLoginWithGoogle = (referralLink) => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.GoogleAuthProvider();
    await dispatch(resetErrorMessage());
    await dispatch(fbLoginBegin());
    try {
      await fb
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          const {
            additionalUserInfo: { isNewUser, profile, providerId },
            user,
          } = result;
          const newUser = {
            firstName: profile.given_name || '',
            lastName: profile.family_name || '',
            email: profile.email,
            name: profile.name,
            terms: false,
            picture: profile.picture,
            provider: providerId,
            isApproved: true,
            referralLink: referralLink || null,
          };

          const uid = user.uid;
          if (isNewUser) {
            await dispatch(createNewUser({ uid, newUser }));
            await dispatch(fbLoginSuccess(result));
            await dispatch(firebaseAuthChange());
          } else {
            await dispatch(fbLoginSuccess(result));
            await dispatch(firebaseAuthChange());
          }
        });
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const fbAuthLoginWithFacebook = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const fb = getFirebase();
    const provider = new fb.auth.FacebookAuthProvider();
    try {
      await dispatch(fbLoginBegin());
      const result = await fb.auth().signInWithPopup(provider);
      const {
        additionalUserInfo: { profile, providerId },
        user,
      } = result;
      const newUser = {
        email: profile.email,
        name: profile.name,
        terms: false,
        username: '',
        picture: profile.picture,
        provider: providerId,
      };
      const uid = user.uid;
      await dispatch(createNewUser({ uid, newUser }));
      await dispatch(fbLoginSuccess(result));
    } catch (err) {
      await dispatch(fbLoginErr(err));
    }
  };
};

const firebaseAuthChange = () => {
  return async (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        dispatch(userProjectDataRead());
        dispatch(getUserTodaysActivity());
      }
    });
  };
};

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    firebase
      .auth()
      .currentUser.getIdToken(/* forceRefresh */ true)
      .then(function (idToken) {
        // console.log(`idToken-----`, idToken);
        // Send token to your backend via HTTPS
      })
      .catch(function (error) {
        console.log(`error.message`, error.message);
        allSubscribers.forEach((subscriber) =>
          subscriber && typeof subscriber.func === 'function' ? subscriber.func() : console.log(subscriber),
        );
        if (error.code !== 'auth/network-request-failed') {
          window.location.reload(true);
          addNotificationError(error.message);
        }
      });

    const response = await getIPData();
    if (response !== null) {
      const { countryName, city, state, IPaddress } = response;
      firebase
        .firestore()
        .collection('users')
        .doc(user.uid)
        .set(
          {
            email: user.email,
            lastLoginDate: new Date(),
            trackingInfo: {
              countryName,
              city,
              state,
              IPaddress,
              browserName: platform.name,
              browserVersion: platform.version,
              device: platform.description,
              browserLanguage: navigator.language || navigator.userLanguage,
            },
          },
          { merge: true },
        );
    }
  } else {
    // console.log(`user`, user);
  }
});

const getIPData = async () => {
  return await axios
    .get('https://geolocation-db.com/json/')
    .then((res) => {
      const { country_name, city, state, IPv4 } = res.data;
      return { countryName: country_name, city, state, IPaddress: IPv4 };
    })
    .catch((error) => {
      console.log(`error`, error.message);
      return null;
    });
};

// we need to set the password for those users who are invited via sign-up link
const setNewPasswordEmailLink = (newPassword) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const db = getFirestore();
    dispatch(setNewPassword());
    const user = firebase.auth().currentUser;
    const uid = getState().fb.auth.uid;
    const inviteDocId = getState().fb.profile.inviteDocId;

    try {
      user
        .updatePassword(newPassword)
        .then(function () {
          db.collection('users')
            .doc(uid)
            .set(
              {
                requiredSetPassword: false,
              },
              { merge: true },
            )
            .then(() => {
              db.collection('users').doc(inviteDocId).delete();
              dispatch(setNewPasswordSuccess());
              addNotificationSuccess('Password successfully set');
            });
        })
        .catch(function (error) {
          // An error happened.
          console.log(`error`, error);
          addNotificationError(error.message);
        });
    } catch (err) {
      // await addNotificationError(err);
      addNotificationError(err);
      await dispatch(setNewPasswordFailed());
    }
  };
};

export {
  fbAuthLogin,
  fbAuthLogout,
  fbAuthSignUp,
  fbAuthLoginWithGoogle,
  fbAuthLoginWithFacebook,
  emailLinkSignUp,
  setNewPasswordEmailLink,
  firebaseAuthChange,
};

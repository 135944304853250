/* ------------------------------------------------------ */
/*                      PROMPT MODAL                      */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * When a user clicks on the Browser Prompt button, it modal appears.
 * There is section wise data.
 * category -> subCategory -> list of prompts.
 * each category have multiple sub-categories and each sub-category have different prompts.
 *
 * Search apply on the main category
 *
 */

import { Col, Empty, Input, Row, Select, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import Icon from '@ant-design/icons';
import { PromptStyled } from '../styled';
import { PromptData } from './promptsData';
import { Button } from '../../../components/Button';
import { BUTTON_PRIMARY } from '../../../constants';
import SearchSvg from '../../../static/tools/svg/search.svg';

const SearchIcon = () => <img src={SearchSvg} alt="search" />;

const { Text } = Typography;

const Prompts = ({ builtInPrompt }) => {
  const [categorySelected, setCategorySelected] = useState('social-media');
  const [subCategorySelected, setSubCategorySelected] = useState('captions');
  const [subCategoryList, setSubCategoryList] = useState();
  const [searchCategory, setSearchCategory] = useState(null);
  const [promptResult, setPromptResult] = useState(PromptData[0].subCategory[0].prompts);
  const [selectedPromptResult, setSelectedPromptResult] = useState(0);
  const [selectedPromptText, setSelectedPromptText] = useState();

  const promptCategory = PromptData.map(({ value, label }) => {
    return { value, label };
  });
  const [displayPromptCategory, setDisplayPromptCategory] = useState(promptCategory);

  const onCategoryClick = (promptCategorySelects) => {
    setCategorySelected(promptCategorySelects);
    setSubCategorySelected(null);
    setPromptResult(null);
  };

  useEffect(() => {
    if (categorySelected) {
      const findSubCategory = PromptData.find(({ label, value }) => {
        return categorySelected === value;
      });
      setSubCategoryList(findSubCategory.subCategory);
    }
  }, [categorySelected]);

  const onSubCategoryClick = (promptSubCategory) => {
    if (subCategoryList) {
      const findPrompt = subCategoryList.find(({ label, value }) => {
        return promptSubCategory === value;
      });
      setSelectedPromptResult(0);
      setPromptResult(findPrompt.prompts);
      setSelectedPromptText(findPrompt.prompts[0]);
    }
    setSubCategorySelected(promptSubCategory);
  };

  const onPromptClick = () => {
    if (selectedPromptText) {
      builtInPrompt(selectedPromptText);
    } else {
      const findselectedPromptText = promptResult[0];
      builtInPrompt(findselectedPromptText);
    }
  };

  const onCategorySearch = (e) => {
    const value = e.target.value;
    setSearchCategory(value);
    setCategorySelected(null);
    setSubCategorySelected(null);
    setPromptResult(null);
    setSubCategoryList([]);
  };

  useEffect(() => {
    if (searchCategory) {
      const d = promptCategory.filter(({ label }) => {
        return label.toLocaleLowerCase().includes(searchCategory.toLocaleLowerCase());
      });
      setDisplayPromptCategory(d);
    } else {
      setCategorySelected('social-media');
      setSubCategorySelected('captions');
      setPromptResult(PromptData[0].subCategory[0].prompts);
      setDisplayPromptCategory(promptCategory);
    }
  }, [searchCategory]);

  const categorySelect = displayPromptCategory
    ? displayPromptCategory.map(({ label, value }) => {
        return { label, value };
      })
    : [];

  const subCategorySelect = subCategoryList
    ? subCategoryList.map(({ label, value }) => {
        return { label, value };
      })
    : [];

  const onPromptResultClick = ({ promptId, promptText }) => {
    setSelectedPromptResult(promptId);
    setSelectedPromptText(promptText);
  };

  return (
    <PromptStyled>
      <div className="prompt-header">
        <Text className="prompt-text-heading"> Prompts</Text>
        <div>
          <Input
            allowClear
            onChange={onCategorySearch}
            prefix={<Icon component={SearchIcon} />}
            placeholder='Try "Sales" or "Email"'
          />
        </div>
      </div>
      <div className="prompt-section">
        {displayPromptCategory && displayPromptCategory.length > 0 && (
          <Row>
            <Col xs={24} sm={5} className="prompt-list">
              <div className="first-list">
                {displayPromptCategory &&
                  displayPromptCategory.map(({ label, value }) => {
                    return (
                      <div
                        className={`prompt-suggestion-wrapper ${categorySelected === value && 'categorySelected'} `}
                        onClick={() => onCategoryClick(value)}
                      >
                        <Text className="prompt-suggestion-text">{label}</Text>
                      </div>
                    );
                  })}
              </div>

              <div className="select-list">
                <Select
                  defaultValue={categorySelected}
                  style={{
                    width: '90%',
                  }}
                  onChange={onCategoryClick}
                  options={categorySelect}
                />
              </div>
            </Col>
            <Col xs={24} sm={7} className="prompt-list sub-category">
              <div className="second-list">
                {subCategoryList &&
                  subCategoryList.map(({ label, value }) => {
                    return (
                      <div
                        className={`prompt-suggestion-wrapper ${
                          subCategorySelected === value && 'sub-category-selected'
                        }`}
                        onClick={() => onSubCategoryClick(value)}
                      >
                        <Text className="prompt-suggestion-text">{label}</Text>
                      </div>
                    );
                  })}
              </div>

              <div className="select-list">
                <Select
                  defaultValue={subCategorySelected}
                  style={{
                    width: '90%',
                  }}
                  onChange={onSubCategoryClick}
                  options={subCategorySelect}
                />
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div className="prompt-review-wrapper">
                <div className="prompt-review">
                  {promptResult &&
                    promptResult.map((promptText, index) => {
                      const convertToHTML = promptText
                        .split(/(\[[^\]]+\])/)
                        .map((s) => {
                          s.trim();
                          if (s.includes('[')) {
                            return `<span class='highlight-prompt-text'>${s} </span>`;
                          } else {
                            return s;
                          }
                        })
                        .filter(Boolean);
                      const htmlString = convertToHTML.join('');
                      return (
                        <div
                          onClick={() => onPromptResultClick({ promptId: index, promptText })}
                          className={`prompt-text-preview ${index === selectedPromptResult && 'text-preview-selected'}`}
                          dangerouslySetInnerHTML={{ __html: htmlString }}
                        />
                      );
                    })}
                </div>
                <Button
                  disabled={!promptResult && selectedPromptResult}
                  onClick={onPromptClick}
                  block
                  type={BUTTON_PRIMARY}
                  size={'large'}
                >
                  Use Prompt
                </Button>
              </div>
            </Col>
          </Row>
        )}
        {displayPromptCategory && displayPromptCategory.length === 0 && (
          <div className="empty">
            <Empty />
          </div>
        )}
      </div>
    </PromptStyled>
  );
};

export default Prompts;

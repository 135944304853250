/* ------------------------------------------------------ */
/*                     Notification UI                    */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * There are 3 types of notifications. Antd Notification component is used.
 * 1. Info
 * 2. Error
 * 3. Success
 *
 */

import React from 'react';
import { notification } from 'antd';

const addNotificationError = (err) => {
  notification.error({
    message: err,
  });
};
const addNotificationSuccess = (text) => {
  notification.success({
    message: text,
  });
};
const addNotificationInfo = (text, duration = 4.5) => {
  notification.info({
    message: text,
    duration,
  });
};

export { addNotificationError, addNotificationSuccess, addNotificationInfo };

/* ------------------------------------------------------ */
/*                      BILLING MODAL                     */
/* ------------------------------------------------------ */

/**
 * Methadology:
 *
 * This component display the modal when there is an issue with billing. The possible issues are
 * 1. TRIAL_CRAFT_NO_HITS_LEFT_MESSAGE: In trial there is not unlimited hits.
 * 2. TRIAL_CRAFT_EXPIRE_MESSAGE: Trial period expires. Normally there is 5 days trial plan.
 * 3. CRAFT_WORDS_EXCEEDED_MESSAGE: In Starter plan there is a limit of words crafts.
 * 4. NO_PLAN_SUBSCRIPED_MESSAGE: When a user dont have any subscription of any plan.
 * 5. TEAM_MEMBER_CANT_CRAFT: Team account is not active. This happens to team members on a Team workspace.
 * 6. TEAM_MEMBER_ON_HOLD: Team member is on HOLD position. a team member shift to HOLD status when there is an issue with team plan or
 * 7. TEAM_MEMBER_CHECK_IN_TEAM:  Team members is no longer be a team of this team.
 * 8. PLAN_CANCELED: The plan is cancelled.
 * 9. PLAN_PAST_DUE: subscription doesnt charged successfully.
 */

import React from 'react';
import { Typography, Space } from 'antd';
import { Modal } from '../Modal';
import { Button } from '../Button';

import {
  TRIAL_CRAFT_NO_HITS_LEFT_MESSAGE,
  TRIAL_CRAFT_EXPIRE_MESSAGE,
  CRAFT_WORDS_EXCEEDED_MESSAGE,
  NO_PLAN_SUBSCRIPED_MESSAGE,
  PLAN_CANCELED,
  TEAM_MEMBER_CANT_CRAFT,
  PLAN_PAST_DUE,
  TEAM_MEMBER_ON_HOLD,
  TEAM_MEMBER_CHECK_IN_TEAM,
} from '../../constants';
import {
  TOOL_ACCOUNT_HOLD_POSITION,
  TOOL_CHECK_TRANSACTION,
  TOOL_CONTACT_TEAM_MEMBER,
  TOOL_DAILY_LIMIT_EXCEEDED,
  TOOL_DENIAL,
  TOOL_NO_PLAN,
  TOOL_NO_PLAN_TITLE,
  TOOL_PLAN_ENDED,
  TOOL_START_PLAN_EXCEEDED,
  TOOL_START_PLAN_EXCEEDED_MESSAGE,
  TOOL_SUBSCRIPTION_FAILED,
  TOOL_TEAM_ACCOUNT_NOT_ACTIVE,
  TOOL_TEAM_MEMBER_NO_LONGER,
  TOOL_TIME_UP_TRIAL,
  TOOL_TRIAL_JOIN_US,
  TOOL_UPGRADE,
  TOOL_WORD_DAILY_LIMIT_REACHED,
  TOOL_WRITERS_BLOCK,
} from '../../constants/content';
import { BillingModalStyled } from './styled';
const { Text } = Typography;

/**
 * @boolean open: to display the modal.
 * @string messageType: speficific message error to show the modal content
 * @number wordsLimit: This word limit apply for starter and trial plans.
 * @func onRedirectClick: on the specific action of modal it redirect to that page.
 * @func onClose: close the modal
 */
const BillingModal = ({ open, messageType, wordsLimit, onRedirectClick, onClose }) => {
  return (
    <>
      {messageType === TRIAL_CRAFT_NO_HITS_LEFT_MESSAGE && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <BillingModalStyled>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                fontSize: '20px',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img src={require('../../static/general/trial-daily-limit.png')} alt="limit-exceed" />
              <Text style={{ marginTop: '1rem' }}> {TOOL_DAILY_LIMIT_EXCEEDED} </Text>
            </div>
            <Text className="content">{TOOL_WORD_DAILY_LIMIT_REACHED(wordsLimit)}</Text>
            <Space className="button-action" align="center">
              <Button
                size="large"
                type="primary"
                style={{ marginBottom: '1rem' }}
                onClick={() => onRedirectClick('/settings/billing')}
              >
                {TOOL_UPGRADE}
              </Button>
              <Button size="large" type="primary" style={{ marginBottom: '1rem' }} onClick={onClose}>
                {TOOL_WRITERS_BLOCK}
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}

      {messageType === TRIAL_CRAFT_EXPIRE_MESSAGE && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: '20px',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={require('../../static/general/trial-expire.png')} alt="limit-exceed" />
            <Text style={{ marginTop: '1rem' }}> {TOOL_TIME_UP_TRIAL}</Text>
          </div>
          <BillingModalStyled>
            <Text className="content">{TOOL_TRIAL_JOIN_US}</Text>
            <Space className="button-action" align="center">
              <Button
                size="large"
                type="primary"
                style={{ marginBottom: '1rem' }}
                onClick={() => onRedirectClick('/settings/billing')}
              >
                {TOOL_UPGRADE}
              </Button>
              <Button size="large" type="primary" style={{ marginBottom: '1rem' }} onClick={onClose}>
                {TOOL_DENIAL}
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}

      {messageType === CRAFT_WORDS_EXCEEDED_MESSAGE && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <Text style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            {TOOL_START_PLAN_EXCEEDED}
          </Text>
          <BillingModalStyled>
            <Text className="content">{TOOL_START_PLAN_EXCEEDED_MESSAGE(wordsLimit)}</Text>
            <Space className="button-action" align="center">
              <Button
                size="large"
                type="primary"
                style={{ marginBottom: '1rem' }}
                onClick={() => onRedirectClick('/settings/billing')}
              >
                {TOOL_UPGRADE}
              </Button>
              <Button size="large" type="primary" style={{ marginBottom: '1rem' }} onClick={onClose}>
                {TOOL_WRITERS_BLOCK}
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}

      {/* This normally occur when we skip the plan selection in onboarding process or we convert the user to Free-User in onboarding process. Free user doesnt need to have any plans */}
      {/* This also happen to team members. They joined as team members but later on they are removed from team so in this case they dont have any plan subscription */}
      {messageType === NO_PLAN_SUBSCRIPED_MESSAGE && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <Text style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>{TOOL_NO_PLAN_TITLE}</Text>

          <BillingModalStyled>
            <Text className="content">{TOOL_NO_PLAN()}</Text>
            <Space className="button-action" align="center">
              <Button
                size="large"
                type="primary"
                style={{ marginBottom: '1rem' }}
                onClick={() => onRedirectClick('/settings/billing')}
              >
                {TOOL_UPGRADE}
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}

      {/* When team account is not active */}
      {messageType === TEAM_MEMBER_CANT_CRAFT && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <Text style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>Contact Team Admin</Text>

          <BillingModalStyled>
            <Text className="content">{TOOL_TEAM_ACCOUNT_NOT_ACTIVE}</Text>
            <Space className="button-action" align="center">
              <Button size="large" type="primary" style={{ marginBottom: '1rem' }} onClick={onClose}>
                Ok
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}

      {/* Team account is active but a team member cant use any tools. This might happen when a team admin try to add more team members and it shift the existing team members on hold */}
      {messageType === TEAM_MEMBER_ON_HOLD && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <Text style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            {TOOL_ACCOUNT_HOLD_POSITION}
          </Text>

          <BillingModalStyled>
            <Text className="content">{TOOL_CONTACT_TEAM_MEMBER}</Text>
            <Space className="button-action" align="center">
              <Button size="large" type="primary" style={{ marginBottom: '1rem' }} onClick={onClose}>
                Ok
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}

      {/* Team admin removed the team member from the team */}
      {messageType === TEAM_MEMBER_CHECK_IN_TEAM && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <Text style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            {TOOL_CONTACT_TEAM_MEMBER}
          </Text>

          <BillingModalStyled>
            <Text className="content">{TOOL_TEAM_MEMBER_NO_LONGER}</Text>
            <Space className="button-action" align="center">
              <Button size="large" type="primary" style={{ marginBottom: '1rem' }} onClick={onClose}>
                Ok
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}

      {messageType === PLAN_CANCELED && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <Text style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>Plan Ended.</Text>

          <BillingModalStyled>
            <Text className="content">{TOOL_PLAN_ENDED}</Text>
            <Space className="button-action" align="center">
              <Button
                size="large"
                type="primary"
                style={{ marginBottom: '1rem' }}
                onClick={() => onRedirectClick('/settings/billing')}
              >
                {TOOL_UPGRADE}
              </Button>
              <Button size="large" type="primary" style={{ marginBottom: '1rem' }} onClick={onClose}>
                {TOOL_WRITERS_BLOCK}
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}

      {messageType === PLAN_PAST_DUE && (
        <Modal footer={null} open={open} title={null} closable onCancel={onClose}>
          <Text style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>
            {TOOL_SUBSCRIPTION_FAILED}{' '}
          </Text>

          <BillingModalStyled>
            <Text className="content">{TOOL_CHECK_TRANSACTION}</Text>

            <Space className="button-action" align="center">
              <Button
                size="large"
                type="primary"
                style={{ marginBottom: '1rem' }}
                onClick={() => onRedirectClick('/settings/billing')}
              >
                Check Now
              </Button>
            </Space>
          </BillingModalStyled>
        </Modal>
      )}
    </>
  );
};

export default BillingModal;
// 30356799125 hbl tracking num

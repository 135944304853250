import React, { useEffect, useCallback } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Checkbox, Typography } from 'antd';
import { Button } from '../../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from './style';
import { fbAuthSignUp, fbAuthLoginWithGoogle } from '../../../../redux/firebase/auth/actionCreator';
import { animation, login } from '../../../../redux/authentication/actionCreator';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { PAGETILE } from '../../../../constants/content';

const { Text, Paragraph } = Typography;

const SignUp = () => {
  const { isSignUpError, isSignUpLoading, isFbAuthenticate } = useSelector((state) => {
    return {
      isSignUpError: state.firebaseAuth.isSignUpError,
      isSignUpLoading: state.firebaseAuth.isSignUpLoading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();

  // fetch the via object from the url, it is embedded from rewardful
  const { via } = queryString.parse(location.search);

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    navigate('/');
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }
  }, [isFbAuthenticate, handleFbLogin]);

  const handleSubmit = (values) => {
    const trimFirstName = values.firstName.replace(/\s+/g, ' ');
    const trimLastName = values.lastName.replace(/\s+/g, ' ');

    dispatch(
      fbAuthSignUp({
        ...values,
        firstName: trimFirstName,
        lastName: trimLastName,
        name: `${trimFirstName} ${trimLastName}`,
        referralLink: via,
      }),
    );
  };

  const errorMessage = isSignUpError && isSignUpError.code !== 'auth/popup-closed-by-user' && isSignUpError.message;

  const getAnimation = () => {
    dispatch(animation());
  };

  return (
    <AuthWrapper>
      <Helmet>
        <title>{PAGETILE('Sign Up')}</title>
      </Helmet>

      <div className="auth-contents">
        <Form name="register" form={form} onFinish={handleSubmit} layout="vertical">
          <div className="heading">Sign up and start writing.</div>
          <div className="auth-social" onClick={() => dispatch(fbAuthLoginWithGoogle(via))}>
            <div className="google-logo">
              <img className="google-icon" src={require('../../../../static/img/google.png')} alt="" />
              <div className="logo-divider" />
            </div>
            <span className="google-text">Sign up with Google</span>
          </div>
          <div className="sign-with-email">
            <Text className="sign-with-email-text">Or create a new account</Text>
          </div>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'Please input your first name', whitespace: true }]}
          >
            <Input placeholder="First Name" className="auth-input" onClick={getAnimation} onFocus={getAnimation} />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Please input your last name', whitespace: true }]}
          >
            <Input placeholder="Last Name" className="auth-input" onClick={getAnimation} onFocus={getAnimation} />
          </Form.Item>
          <Form.Item
            name="email"
            validateTrigger={['onBlur']}
            rules={[
              {
                type: 'email',
                message: 'Please use a valid email address',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              },
            ]}
          >
            <Input placeholder="Email" className="auth-input" onClick={getAnimation} onFocus={getAnimation} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Please input your password!' },
              { min: 7, message: 'Password should be at least 7 characters' },
            ]}
          >
            <Input.Password
              placeholder="Password"
              className="auth-input"
              onClick={getAnimation}
              onFocus={getAnimation}
            />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Your passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" onClick={getAnimation} onFocus={getAnimation} />
          </Form.Item>
          <div className="terms-check">
            <Form.Item
              name="terms"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Please see our terms and conditions')),
                },
              ]}
            >
              <Checkbox>
                I agree to Craftly{' '}
                <Link
                  onClick={() => window.open('https://craftly.ai/terms-of-use.html', '_blank')}
                  style={{ color: '#25a9f1', textDecoration: 'underline' }}
                >
                  Terms{' '}
                </Link>
                and{' '}
                <Link
                  onClick={() => window.open('https://craftly.ai/privacy-policy.html', '_blank')}
                  style={{ color: '#25a9f1', textDecoration: 'underline' }}
                >
                  Privacy Policy
                </Link>
              </Checkbox>
            </Form.Item>
          </div>
          <Text type="danger">{errorMessage}</Text>
          <Form.Item>
            <Button
              loading={isSignUpLoading}
              className="btn-create"
              htmlType="submit"
              size="large"
              type="purple-gradient"
              block
            >
              Start Crafting
            </Button>
          </Form.Item>

          <div onClick={getAnimation}>
            <Paragraph className="auth-notice">
              Have a craftly account?{' '}
              <NavLink to="/auth" style={{ marginLeft: '5px' }}>
                Login
              </NavLink>
            </Paragraph>
          </div>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignUp;

/* ------------------------------------------------------ */
/*                     Team Members Page                  */
/* ------------------------------------------------------ */

/**
 *
 * Team admin has the ability to add team member, see the progress of active members , pending users and available seats
 *
 *
 *
 */

import React, { useState } from 'react';
import { Row, Col, Typography, Progress, Input } from 'antd';
import { Focard, TeamMembersPageWrapper } from '../style';
import { Cards } from '../../../components/Card';
import { Button } from '../../../components/Button';
import { minTwoDigits } from '../../../utility/utility';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { TEAM_STATUS } from '../../../constants';
import AddMember from './AddMember';
import MembersList from './MembersList';

const { Text, Title } = Typography;

const TeamMembersPage = ({
  teamMembersList,
  teamQuantity,
  teamMembersLoading,
  onMemberSelect,
  selectedMember,
  wordCraftMemberList,
  teamAdminControlOnly,
  makeTeamMemberAction,
  teamInviteActionLoading,
  openSeatModal,
}) => {
  const [searchTeamMemberValue, setSearchTeamMemberValue] = useState('');
  const activeTeamMembers = teamMembersList
    ? teamMembersList.filter(
        ({ teamInviteStatus }) => teamInviteStatus === TEAM_STATUS.ACTIVE || teamInviteStatus === TEAM_STATUS.ACCEPTED,
      )
    : 0;
  const activeMembers = activeTeamMembers ? minTwoDigits(activeTeamMembers.length) : 0;
  const availableSeats = teamMembersList && teamQuantity ? minTwoDigits(teamQuantity - teamMembersList.length) : 0;
  // pending members includes 1. those who doesnt accept the request 2. those who are on hold.
  const pendingTeamMembers = teamMembersList
    ? teamMembersList.filter(
        ({ teamInviteStatus }) => teamInviteStatus === TEAM_STATUS.PENDING || teamInviteStatus === TEAM_STATUS.HOLD,
      )
    : 0;
  const pendingMembers = pendingTeamMembers ? minTwoDigits(pendingTeamMembers.length) : 0;

  const activeMembersPercent =
    teamQuantity && teamMembersList && teamMembersList.length > 0 ? (activeMembers / teamQuantity) * 100 : 0;
  const pendingMembersPercent =
    teamQuantity && teamMembersList && teamMembersList.length > 0 ? (pendingMembers / teamQuantity) * 100 : 0;

  const onSeachTeamMembers = (e) => {
    const { value } = e.target;
    setSearchTeamMemberValue(value);
  };
  return (
    <TeamMembersPageWrapper>
      <Row gutter={25} style={{ marginTop: '1rem' }}>
        <Col xs={24} md={8}>
          <Focard>
            <div className="team-member-card-box">
              <Cards title={'Active'}>
                <div className="member-count">
                  <Progress
                    width={90}
                    type="circle"
                    percent={activeMembersPercent}
                    format={() => `${activeMembers}`}
                    strokeColor={'#f457c6'}
                    trailColor={'#fdddf4'}
                  />
                  <div className="details">
                    <Title className="count"> {activeMembers} </Title>
                    <Text className="description"> Active Members </Text>
                  </div>
                </div>
              </Cards>
            </div>
          </Focard>
        </Col>

        {teamAdminControlOnly && (
          <>
            <Col xs={24} md={8}>
              <Focard>
                <div className="team-member-card-box">
                  <Cards title={'Pending'}>
                    <div className="member-count">
                      <Progress
                        width={90}
                        type="circle"
                        percent={pendingMembersPercent}
                        format={() => `${pendingMembers}`}
                        strokeColor={'#9078fc'}
                        trailColor={'#e9e4fe'}
                      />
                      <div className="details">
                        <Title className="count"> {pendingMembers} </Title>
                        <Text className="description"> Pending Request </Text>
                      </div>
                    </div>
                  </Cards>
                </div>
              </Focard>
            </Col>
            <Col xs={24} md={8}>
              <Focard>
                <div className="team-member-card-box">
                  <Cards title={'Available Seats'}>
                    <div className="member-count">
                      <Progress
                        width={90}
                        type="circle"
                        percent={20}
                        format={() => `${availableSeats}`}
                        strokeColor={'#87dde8'}
                        trailColor={'#e7f8fa'}
                      />
                      <div className="details">
                        <Title className="count"> {availableSeats} </Title>
                        <Text className="description"> Available Seats </Text>
                      </div>
                    </div>
                  </Cards>
                </div>
              </Focard>
            </Col>
          </>
        )}
      </Row>

      {teamAdminControlOnly && (
        <Row>
          <Col sm={24}>
            <div className="add-member">
              <AddMember />
              <Button
                onClick={() => openSeatModal(true)}
                className="add-button"
                type="primary"
                style={{ marginLeft: '10px' }}
                icon={<PlusOutlined />}
              >
                Manage Seats
              </Button>
            </div>
          </Col>
        </Row>
      )}

      <Row style={{ marginTop: '1rem' }}>
        <Col sm={12} md={8}>
          <div className="add-member">
            <Input
              onChange={onSeachTeamMembers}
              prefix={<SearchOutlined style={{ color: '#9882fc', fontSize: '1rem' }} />}
              placeholder="Search by name or email"
              className="add-member-email"
            />
          </div>
        </Col>
      </Row>
      <MembersList
        searchTeamMemberValue={searchTeamMemberValue}
        teamMembersLoading={teamMembersLoading}
        teamAdminControlOnly={teamAdminControlOnly}
        teamMembersList={teamMembersList}
        onMemberSelect={onMemberSelect}
        selectedMember={selectedMember}
        wordCraftMemberList={wordCraftMemberList}
        teamQuantity={teamQuantity}
        makeTeamMemberAction={makeTeamMemberAction}
        teamInviteActionLoading={teamInviteActionLoading}
      />
    </TeamMembersPageWrapper>
  );
};

export default TeamMembersPage;

/* ------------------------------------------------------ */
/*                         Content                        */
/* ------------------------------------------------------ */

/**
 * All the website content or text to be found here.
 */

import { NavLink } from 'react-router-dom';
import React from 'react';
import { Typography } from 'antd';
const { Text, Link } = Typography;

/* ------------------------------------------------------ */
/*                       TEAM PORTAL                      */
/* ------------------------------------------------------ */
export const DECLINE_INVITATION = 'Team Invite Decline';
export const ACCEPT_INVITATION = 'Team Invite Accepted';
export const PENDING_INVITATION = 'You have received a team invitation';
export const NO_DATA_FOUND = 'No Data Found';
export const SHOW_ADMIN_HANDLE_BILLING = 'Your teams billing is managed by your administrator';
export const TEAM_SUCCESSFULLY_DELETED = 'Team Successfully Deleted';
export const LOGIN_AGAIN = 'Please login again. Current session has expired.';
export const PRORATION_NOTE = 'You will be charged a part of the fee for the remaining billing cycle.';
export const PRORATION_ADMIN = '1 Admin x Team ( $79.00/ month )';
export const PRORATION_MEMBERS = (totalSeats) => {
  return `${totalSeats} Seat(s) x Team ( $56.00/ month )`;
};
export const ORIGINAL_PRORATION_ADMIN = 'Tier 1 at $79.00 / month';
export const ORIGINAL_PRORATION_MEMBER = 'Tier 2 at $56.00 / month';
export const PRORATION_SEATS_UPDATE_EQUAL = 'Add or Subtract Seats';
export const PRORATION_SEATS_UPDATE_INCREASE = 'Increase Seats Now';
export const PRORATION_SEATS_UPDATE_DECREASE = 'Decrease Seats Now';
export const PLEASE_SELECT_PROJECT = 'Please Select a Project';
export const PLEASE_CREATE_PROJECT = 'Please Create a New Project';

// Team Portal dashboard - Card Title
export const TEAM_TOTAL_WORDS_CRAFTED_THIS_CYCLE = 'Total Words Crafted This Cycle';
export const TEAM_TOTAL_WORDS_CRAFTED = 'Total Words Crafted';
export const TEAM_ADMIN_VIEW_PORTAL = () => {
  return (
    <div style={{ marginTop: '4rem' }}>
      <Text>Please visit your</Text> <NavLink to={'../settings/workspace'}>PERSONAL WORKSPACE </NavLink>
      <Text>to access administrative functions </Text>{' '}
    </div>
  );
};

// Team Portal Onboarding
export const TEAM_ONBOARDING_TEAM_NAME = 'Enter your Team Name';

// When user accept the team request, the personal plan of user get cancelled.
export const PLAN_CANCEL_TEAM_JOIN =
  'Congrats! As long as you are a part of a Team you can also craft on your Personal Workspace, absolutely free.';

export const TEAM_PORTAL_INVITE_SEND = 'Invite Successfully Sent';
export const TEAM_PORTAL_INVITE_CREATED_SUCCESS = 'Team Successfully Created';
export const TEAM_PORTAL_ALREADY_TEAM_MEMBER = (email) => {
  return `${email} is already a part of your team`;
};
export const TOP_USED_TOOLS = 'Start crafting to see top used tools!';

/* ------------------------------------------------------ */
/*                         Top bar                        */
/* ------------------------------------------------------ */
//> When team member selects the Team workspace and team plan is not active.
export const TOP_BAR_NO_TEAM_PLAN_ACTIVE_TEAM_MEMBER = () => {
  return (
    <Text>
      There is no Team Plan in place. Please
      <NavLink to="../team-portal" className="link">
        contact
      </NavLink>
      your administrator for help
    </Text>
  );
};

//> When team admin select the Team workspace and team plan is not active.
export const TOP_BAR_NO_TEAM_PLAN_ACTIVE_TEAM_ADMIN = () => {
  return (
    <Text>
      There is no Team Plan in place. Please
      <NavLink to="../team-portal" className="link">
        subscribe to a
      </NavLink>
      Team Plan.
    </Text>
  );
};

export const TOP_BAR_TRAIL_LEFT = (remainingHits) => {
  return (
    <Text>
      {remainingHits} Runs left on your trial account for the day!{' '}
      <Link href="../settings/billing" className="link">
        Click Here
      </Link>
      to upgrade earlier and craft without limitations.
    </Text>
  );
};

export const TOP_BAR_TRIAL_PERIOD_EXPIRE = () => {
  return (
    <Text>
      Your trial has expired. Upgrade
      <Link href="../settings/billing" className="link">
        here
      </Link>
      to continue crafting!
    </Text>
  );
};

export const TOP_BAR_NO_ACTIVE_PLAN = () => {
  return (
    <Text>
      You do not have an active plan!
      <Link href="../settings/billing" className="link">
        Click Here
      </Link>
      to upgrade
    </Text>
  );
};
export const LIMIT_APPROACHING = () => {
  return (
    <Text>
      Your account is approaching its word limit!
      <Link href="../settings/billing" className="link">
        Upgrade{' '}
      </Link>
      your plan to keep crafting.
    </Text>
  );
};

export const TOP_BAR_ACCOUNT_HOLD = () => {
  return (
    <Text>
      Your account is on hold.
      <Link href="../team-portal" className="link">
        Contact
      </Link>
      your administrator for help
    </Text>
  );
};

export const TOP_BAR_TEAM_MEMBER_REMOVE = () => {
  return (
    <Text>
      You have been removed from team.
      <Link href="../settings/workspace" className="link">
        Click here
      </Link>
      to activate your personal workspace
    </Text>
  );
};

export const TOP_BAR_NO_INTERNET_CONNECTION = 'No Internet Connection';

/* ------------------------------------------------------ */
/*                      Billing Page                      */
/* ------------------------------------------------------ */
export const BILLING_TEAM_MEMBER = 'Your teams billing is managed by your administrator';
export const BILLING_NO_ACTIVE_PLAN = 'No Active Plan';
export const BILLING_AUTOMATIC_SUBSCRIPTION_FAILED = 'Automatic subscription failed. Please check billing portal.';
export const BILLING_CREDIT_CARD_ATTENTION = 'Your credit card needs attention! Please check billing.';
export const BILLING_CREDIT_CARD_NO_OFFER =
  'You have activated your account using No Credit Card offer. You have 20 Hits per day for 5 days.';
export const ACCOUNT_EXPIRED = 'You Free account is expired';
export const TOTAL_SEATS = 'Total Seats';
export const START_FROM = 'Start From';
export const TRIAL_ENDS = 'Trial Ends';
export const TRIAL_DOWNGRADE_PLAN_HEADING = ` Are you sure you'd like to change your plan?`;
export const TRIAL_DOWNGRADE_PLAN_DESCRIPTION = `This is not the original plan you selected while creating your account`;
export const PLAN_BEING_UPDATED = 'Your plan is being updated';
/* ------------------------------------------------------ */
/*                     AUTHENTICATION                     */
/* ------------------------------------------------------ */

export const AUTH_FORGET_PASSWORD = 'Instructions have been successfully sent to your email';
export const AUTH_FORGET_PASSWORD_TEXT =
  'Hate it when that happens! Whats your email address? We will send you a link to reset it ';
export const AUTH_PASSWORD_CHANGE_SUCCESS = 'Your password has been successfully changed';
export const AUTH_EMAIL_SEND_PASSWORD_CHANGE = 'An email is being sent with reset instructions';
/* ------------------------------------------------------ */
/*                       TOOL CRAFT                       */
/* ------------------------------------------------------ */

export const TOOL_BLOCK_WORDS =
  'Please remove it from your input and try again. If Cai is being overly sensitive, let us know in support and we will talk to her about it!';
export const TOOL_CREATE_PROJECT_FAV = 'To save favourites first add project';
export const TOOL_TEAM_MEMBER_NO_LONGER = 'Apologies, you are no longer be a team of this team.';
export const TOOL_DAILY_LIMIT_EXCEEDED = 'Daily Limit Exceeded!';
export const TOOL_SUBSCRIPTION_FAILED = 'Subscription doesnt charge successfully.';
export const TOOL_START_PLAN_EXCEEDED = 'Limit Exceeded!';
export const TOOL_START_PLAN_EXCEEDED_MESSAGE = (wordsLimit) => {
  return `This is the pop-up when limits have been reached`;
};
export const TOOL_TRIAL_JOIN_US = `Dont know how you'll keep writing without us? You know what they say- If you can't beat'em, join'em! Upgrade or check oout out our Earn Page to keep Crafting for FREE!`;
export const TOOL_WORD_DAILY_LIMIT_REACHED = (wordsLimit) => {
  return `Your trial limit has been reached for today. Please upgrade to your selected plan now or come back tomorrow!
  `;
};
export const TOOL_TIME_UP_TRIAL = `Time's up! Is this goodbye?`;
export const TOOL_PLAN_ENDED = `Oops - looks like your plan has ended. Upgrade now`;
export const TOOL_WRITERS_BLOCK = 'No, I like writers block.';
export const TOOL_CHECK_TRANSACTION = 'Billing error! Please check your transaction';
export const TOOL_DENIAL = `No, I'm in denial`;
export const TOOL_UPGRADE = `Upgrade Now`;
export const TOOL_CONTACT_TEAM_MEMBER = 'Contact Team Administrator';
export const TOOL_ACCOUNT_HOLD_POSITION = `Your account is on hold position.`;
export const TOOL_TEAM_ACCOUNT_NOT_ACTIVE = `Team account is not active`;
export const TOOL_NO_PLAN_TITLE = 'No Active Plan';
export const TOOL_NO_PLAN = () => {
  return (
    <Text>
      Subscribe now to our exciting{' '}
      <Link href="../settings/billing" target="_blank">
        plans
      </Link>
    </Text>
  );
};
/* ------------------------------------------------------ */
/*                         ACCOUNT                        */
/* ------------------------------------------------------ */

export const ACCOUNT_SETTINGS_UPDATE = 'Account settings have been updated';
export const ACCOUNT_TRIAL_ACTIVATED = 'Trial successfully activated for 5 days';
export const SOME_THING_WENT_WRONG = 'Something went wrong. Please try again.';

/* ------------------------------------------------------ */
/*                       PAGE TITLE                       */
/* ------------------------------------------------------ */

export const PAGETILE = (page) => {
  return `Craftly | ${page}`;
};

/* ------------------------------------------------------ */
/*                       VERIFY PAGE                      */
/* ------------------------------------------------------ */

export const ALREADY_ACCOUNT = 'This phone number is being used on another Craftly account';
export const RESEND_CODE = `Didn’t receive a code?`;
export const LIMIT_EXCEED = `You have reached your daily limit! Please try again tomorrow or contact support@craftly.ai`;
export const USER_LIMIT_EXCEED = `You have reached your limit! Please contact support@craftly.ai`;
export const INVALID_CODE = 'Invalid code. Please try again or contact support@craftly.ai';
export const VERIFY_WRONG = `Something went wrong. Please try again or ${(<Link> contact support@craftly.ai </Link>)}`;
export const SAME_PHONE_NUMBER = ` Uh oh! This number has already redeemed a free trial. We limit 1 free trial per user. If you like what you see, please register for an account or contact us if you believe this is a mistake.`;

/* ------------------------------------------------------ */
/*                       TRIAL PAGE                       */
/* ------------------------------------------------------ */

export const USER_CREDIT_CARD_EXIST =
  'This card has already used its free trial. By selecting continue, you agree that your selected plan will begin immediately, and your card will be charged.';

/* ------------------------------------------------------ */
/*                       DOCUMENT PAGE                    */
/* ------------------------------------------------------ */

export const BLOG_BUILDER = 'Blog Builder';
export const EDITOR = 'Text Editor';
export const GO_TO_TEXT_EDITOR = 'Go to Text Editor';
export const GO_TO_BLOG_POST = 'Go to Blog Builder';

/* ------------------------------------------------------ */
/*                          Extra                         */
/* ------------------------------------------------------ */

// This is for stripe promocode. when the user apply the promo-code and promo-code is valid
// but code cant apply to the selected product (plan)
export const PROMO_CODE_VALID_BUT_NOT_APPLY_FOR_SELECTED_PRODUCT =
  'This code is valid, but does not apply to items in your order.';

//stripe promocode is not correct.
export const CODE_INVALID = 'This code is invalid';

//stripe promocode expired.
export const CODE_EXPIRED = 'This code has expired.';

//stripe promocode is valid but the minimum amount of the plan is not enough to use this code.
export const CODE_MINIMUM_NOT_MEET = `This code is valid, but your order doesn't meet the minimum amount`;

export const PROJECT_CREATED = 'Your project has been created';
export const PROJECT_DELETED = 'Project successfully deleted';

// when google user change email successfully
export const CHANGE_GOOGLE_EMAIL = 'Email is successfully changed. Sign with Google using new email';

// those users who have signed using emial/password and changed their email
export const CHANGE_EMAIL_PASSWORD = 'Email is successfully changed. First reset your password and then sign in again';

export const PASSWORD_SUCCESS_CHANGE = 'Password successfully changed.';

// Team admin removes a team members
export const TEAM_MEMBER_DELETED = 'Team Member Successfully Deleted';

// when admin deletes the team and so this messages shows on team member;
export const TEAM_NO_LONGER_PRESENT = 'Team no longer present';
export const TEAM_NAME_CHANGED = 'Team name changed';

// when user deletes his/her own account
export const ACCOUNT_DELETED = 'Account is now closed and all data is removed.';

export const CRAFTLY_DOWN_DUE_TO_OPENAI = 'Craftly is down. Please try again';
export const CRAFT_ERROR_TRY_AGAIN = 'Please try again.';

//when user wants to access the document which is not present in our database.
export const DOCUMENT_NOT_FOUND = 'No data found';

// when craftly admin invite any team-member -- very old feature
export const INVITED_USER = 'Account successfully created for invited user.';

// When in user craft there are bad words.
export const badWordsDisplay = (alertBadWords) => {
  return (
    <>
      <Text>Cai is sensitive to certain words like</Text> <Text type="danger">“{alertBadWords.join(', ')}”. </Text>{' '}
    </>
  );
};

export const ANNUALLY_TEXT = 'Annually';
export const MONTHLY_TEXT = 'Monthly';

export const STARTER_UPGRADE = 'Unlock more features now! Long-form, text editor and more!';
export const CRAFTER_UPGRADE = 'New launch! Build a blog in under 5 minutes.';

export const STARTER_V2_POINTS = ['Better Training', 'Enhanced UI', 'Categorized Tool Sets', 'Favorites', 'Recents'];
export const CRAFTER_V2_POINTS = [
  'Long-form Blog Builder',
  'Text Editor',
  'Document Centre',
  'Image Generation',
  'Command Tool [Beta]',
  'One-click Long-form Rewriter (March 1)',
];

export const PREPAID_CARD_MESSAGE_TITLE = 'Card Type Not Supported';
export const PREPAID_CARD_MESSAGE_DESCRIPTION =
  'We do not support prepaid cards to mitigate our risk against fraud. Please contact us directly if you believe this is a mistake.';

export const TEAM_DELETE_CONFIRMATION_TITLE = 'Are you sure you want to reset your team. ';
export const TEAM_DELETE_CONFIRMATION_CONTENT =
  'All team members will be removed, but your team plan will remain same. You can change your plan from billing section. Please reduce your plan to crafter';

import styled from 'styled-components';

const StripeCustomization = styled.div`
  margin-top: 1rem;

  .stripe-email-textbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 20px;
    width: 100%;
    height: 48px;
    background: #f7f7f7;
    border: 2px solid #e5e5e5;
    border-radius: 8px;
    margin-bottom: 20px;
    .stripe-email {
      margin-left: 7%;
    }
    @media (max-width: 576px) {
      padding: 15px 7px;
    }
  }

  .stripe-card {
    padding-top: 2rem;
    @media (max-width: 576px) {
      padding: 10px !important;
    }
    .ant-card-body {
      padding: 0px 8px !important;
    }
  }

  .hidden {
    display: none;
  }

  .card-element {
    padding: 12px;
    max-height: 49px;
    width: 100%;
    background: white;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .cvc-element {
    border-left: 1px solid rgba(50, 50, 93, 0.1);
    border-radius: 0px;
  }

  #payment-request-button {
    margin-bottom: 32px;
  }

  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }

  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }

  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }

  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }

  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .changePlanText {
    cursor: pointer;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
  }
  .row-margin {
    margin-top: 20px;
  }

  .ant-form-item {
    margin-bottom: 0px;

    .ant-form-item-label > label {
      font-family: 'Poppins-SemiBold';
    }
    .ant-form-item-explain-error {
      padding: 0 12px;
      font-size: 10px;
    }
  }

  .stripe-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .total-amount {
      font-size: 26px;
      font-family: 'Poppins-Regular';
      color: rgb(36, 38, 38);
      margin-top: 0.5rem;
      margin-bottom: 2rem;
    }
    .logo {
      width: 100%;
    }
  }
  .billing-info {
    margin: 1rem 0;
  }

  .pay-button {
    margin-top: 1rem;
  }
  .stripe-logo {
    display: flex;
    justify-content: center;
    .logo {
      width: 8rem;
      margin-top: 1.5rem;
    }
  }
  .card-error {
    color: red;
    font-size: 16px;
    line-height: 20px;
    margin: 12px 0px;
    text-align: center;
  }
  .trial-payment-text {
    font-size: 9px;
  }
  .trial-continue {
    display: flex;
    justify-content: center;
  }
  .stripe-lock {
    margin-top: 15px;
    margin-left: 10px;
    display: flex;
    align-items: center;

    .stripe-payment-text {
      padding-left: 7px;
      color: #737277;
      font-size: 12px;
    }
  }
  .billing-divider {
    margin: 0px;
  }

  .billing-title {
    margin-top: 26px;
    margin-bottom: 10px;
    .text {
      font-family: 'Poppins-SemiBold';
    }
    ::before {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 12px;
      line-height: 1;
      content: '*';
    }
  }
  .payment-card-name {
    border-radius: 8px;
    padding: 12px 11px !important;
  }

  .billing-wrapper {
    border: 1px solid #eeeeee;
    border-radius: 8px;
    .select-country {
      margin-top: 0px;
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 44px !important;
      }

      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 44px !important;
      }
    }
    .address {
      margin-top: 0px;
      padding: 10px 4px !important;
    }
  }

  .selected-plan {
    border: 1px solid #eeeeee;
    border-radius: 6px;
    padding: 30px 20px 20px;
    height: 100%;
    @media (max-width: 576px) {
      margin-top: 2rem;
    }
    .text {
      color: #282b3f;
      font-family: 'Poppins-SemiBold';
      font-size: 15px;
      margin-bottom: 10px;
    }
    .plan-type {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: 10%;
      .details {
        font-size: 14px;
        color: #737277;
        font-family: 'Poppins-Medium';
        text-align: right;
      }
      .two-months-free {
        background: #dbd8ff;
        border-radius: 4px;
        color: #705bfa;
        font-size: 12px;
        padding: 4px 10px;
        margin: 0px 10px;
      }
      .code-input {
        height: 32px;
        background: #eeeff2;
        border: 1px solid #e2e4e8;
        border-radius: 6px;
      }
      .code-correct {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        width: 100%;

        .code-tag {
          align-items: center;
          background-color: hsla(0, 0%, 10%, 0.05);
          border-radius: 6px;
          cursor: default;
          display: flex;
          height: 36px;
          padding: 8px 12px;

          .tag-text {
            font-family: 'Poppins-Regular';
            font-size: 14px;
            color: #6c5afb;
          }
        }
      }
    }
    .divider-details {
      margin: 14px 0px 1rem 0px;
    }
    .colorful {
      background: #705bfa;
      margin: 5px;
    }
  }
  .result-message {
    line-height: 22px;
    font-size: 16px;
    a {
      color: rgb(89, 111, 214);
      text-decoration: none;
    }
  }
`;

export { StripeCustomization };

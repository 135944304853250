/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/control-has-associated-label */

/* ------------------------------------------------------ */
/*                      Bot Moderator                     */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * Firebase Collection: analyze-bots
 * Link: https://console.firebase.google.com/u/1/project/craftly-d50ba/firestore/data/~2Fanalyze-bots
 *
 * BigQuery: Analyze_bots_view
 * Link: https://console.cloud.google.com/bigquery?ws=!1m5!1m4!4m3!1scraftly-d50ba!2scraftly!3sanalyze_bots_view&project=craftly-d50ba&pli=1&authuser=1
 *
 * the analyze bots have all the crafts data.
 * Data is fetch from BigQuery. it speed up the process for big data.
 * Firebase Collection: analyze-bots.
 * It is immediately synchronised with bigQuery.
 * Every time a craft is created, the output of every tool is likewise saved for administrative purposes in Collection Analyze-Bots.
 *
 *
 * This is used for craftly admins. Craftly admin can review all users tools input and outputs
 * according to date filters.
 *
 * It also shows the Summary in the top.
 * Summary is directly dependent on the selection of the date data
 *
 */

import React, { useState, Suspense, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spin, Menu, DatePicker, Typography, Descriptions, Collapse } from 'antd';
import { SidebarWrapModeratorStyled } from './styled';
import { Main } from '../styled';
import {
  readAllSavedBots,
  getBotToolsName,
  getBotInputName,
  getBotOutput,
} from '../../redux/AdminFeatures/actionCreator';
import BotOutput from './BotOutput';
import moment from 'moment';
import { dateFormat, dateFormatWithSlash } from '../../utility/utility';
import { EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Text, Title } = Typography;
const { Panel } = Collapse;

const BotModerator = () => {
  const dispatch = useDispatch();
  const { loading, botsUserName, botsToolName, botsInput, botsOutput, botsSummary } = useSelector(
    (state) => state.adminFeatures,
  );
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedToolName, setSelectedToolName] = useState('');
  const [selectedInput, setSelectedInput] = useState('');

  useEffect(() => {
    const todayDate = moment().format('YYYY-MM-DD');
    setSelectedDate(todayDate);

    // this fetch the users-bot data according to the date selected.
    dispatch(readAllSavedBots(todayDate));
  }, []);

  // this further filter the data when a name is selected.
  const onUserNameClick = (uid) => {
    setSelectedUserName(uid);
    dispatch(getBotToolsName({ date: selectedDate, userName: uid }));
  };

  // tool name is clicked
  const onToolNameClick = (toolName) => {
    setSelectedToolName(toolName);
    dispatch(getBotInputName({ date: selectedDate, userName: selectedUserName, toolName }));
  };

  // it filters the output on the bases of the input clicked
  const onInputClick = (botId) => {
    setSelectedInput(botId);
    dispatch(getBotOutput({ date: selectedDate, userName: selectedUserName, toolName: selectedToolName, botId }));
  };

  // Fetching the userName by the date.
  function onDateChange(date, dateString) {
    setSelectedDate(dateString);
    date && dispatch(readAllSavedBots(dateString));
  }

  const currentDate = selectedDate
    ? moment(selectedDate).format(dateFormatWithSlash)
    : moment().format(dateFormatWithSlash);

  return (
    <Main>
      <Title level={2} style={{ marginTop: '3rem' }}>
        Content Moderator
      </Title>
      {/* <Button onClick={fetchData}> fetchData</Button> */}

      <Collapse accordion>
        <Panel header="Summary" key="1">
          <Descriptions size="small" column={3}>
            {botsSummary &&
              botsSummary
                .sort((a, b) => {
                  return a.order - b.order;
                })
                .map(({ label, value }) => {
                  return <Descriptions.Item label={label}>{value}</Descriptions.Item>;
                })}
          </Descriptions>
        </Panel>
      </Collapse>

      <br />
      <DatePicker
        defaultValue={dayjs(currentDate, dateFormat)}
        value={dayjs(currentDate, dateFormat)}
        format={dateFormat}
        style={{ marginBottom: '1rem' }}
        disabledDate={(current) => current.isAfter(moment().add(1, 'day'))}
        onChange={onDateChange}
      />
      <Row gutter={25}>
        {/* All type of users  */}
        <Col xl={5} lg={5} md={6} sm={12} xs={24}>
          <Spin spinning={loading}>
            <SidebarWrapModeratorStyled className="mb-30">
              <div className="bot-sidebar">
                <div className="bot-sidebar_title">
                  <Text>Runs</Text>
                </div>

                <Menu
                  className="bot-sidebar_menu"
                  selectedKeys={[selectedUserName]}
                  defaultSelectedKeys={[selectedUserName]}
                  mode="inline"
                >
                  {botsUserName &&
                    botsUserName.map(({ name, uid, deleted }, index) => {
                      return (
                        <Menu.Item
                          className="bot-sidebar_menu_item"
                          key={uid}
                          onClick={() => {
                            onUserNameClick(uid);
                            // navigate(`${path}/${selectedDate}/${uid}`);
                          }}
                        >
                          <span className="bot-sidebar_menu_link" style={{ color: deleted && 'red' }}>{`${name}`}</span>
                          {!deleted && <EyeOutlined onClick={() => navigate(`/user-management/${uid}`)} />}
                        </Menu.Item>
                      );
                    })}
                </Menu>
              </div>
            </SidebarWrapModeratorStyled>
          </Spin>
        </Col>

        {/* Tool list */}
        <Col xl={5} lg={5} md={8} sm={12} xs={24}>
          <SidebarWrapModeratorStyled className="mb-30">
            <div className="bot-sidebar">
              <div className="bot-sidebar_title">
                <Text>Tools</Text>
              </div>

              <Menu
                className="bot-sidebar_menu"
                selectedKeys={[selectedToolName]}
                defaultSelectedKeys={[selectedToolName]}
                mode="inline"
              >
                {botsToolName &&
                  botsToolName.map((toolName, index) => {
                    return (
                      <Menu.Item
                        className="bot-sidebar_menu_item"
                        key={toolName}
                        onClick={() => onToolNameClick(toolName)}
                      >
                        <NavLink className="bot-sidebar_menu_link">
                          <span className="nav-item-text">{toolName}</span>
                        </NavLink>
                      </Menu.Item>
                    );
                  })}
              </Menu>
            </div>
          </SidebarWrapModeratorStyled>
        </Col>

        {/* Input of tool name */}
        <Col xl={6} lg={6} md={10} sm={12} xs={24}>
          <SidebarWrapModeratorStyled className="mb-30">
            <div className="bot-sidebar">
              <div className="bot-sidebar_title">
                <Text>Input</Text>
              </div>

              <Menu
                className="bot-sidebar_menu"
                selectedKeys={[selectedInput]}
                defaultSelectedKeys={[selectedInput]}
                mode="inline"
              >
                {botsInput &&
                  botsInput.map(({ botId, input }, index) => {
                    return (
                      <Menu.Item className="bot-sidebar_menu_item" key={botId} onClick={() => onInputClick(botId)}>
                        <NavLink className="bot-sidebar_menu_link">
                          <span className="nav-item-text">{input}</span>
                        </NavLink>
                      </Menu.Item>
                    );
                  })}
              </Menu>
            </div>
          </SidebarWrapModeratorStyled>
        </Col>
        <Col xl={8} lg={8} md={24} sm={12} xs={24}>
          <>
            <Suspense
              fallback={
                <div className="spin">
                  <Spin />
                </div>
              }
            >
              <BotOutput botsOutput={botsOutput} />
            </Suspense>
          </>
        </Col>
      </Row>
    </Main>
  );
};

export default BotModerator;

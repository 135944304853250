import styled from 'styled-components';

const AddNewToolWrapper = styled.div`
  .add-new-form {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 48px !important;
    }
  }
`;

const CategoryBoxStyled = styled.div`
  height: 80vh;
  overflow: auto;
  .faq-badge {
    font-weight: 400;
    color: ${({ theme }) => theme['light-color']};
    background: ${({ theme }) => theme['bg-color-light']};
  }
  ul {
    li {
      a {
        display: inline-block;
        font-weight: 500;
        position: relative;
        padding: ${({ theme }) => (!theme.rtl ? '12px 0 12px 20px' : '12px 20px 12px 0')};
        transition: all 0.3s ease;
        color: ${({ theme }) => theme['gray-color']};
        &.active {
          padding-left: 28px;
          &:before {
            opacity: 1;
            visibility: visible;
            ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: -15px;
          }
          &:after {
            ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 5px;
          }
          &.primary {
            &:after {
              background: ${({ theme }) => theme['primary-color']};
            }
          }
          &.secondary {
            &:after {
              background: ${({ theme }) => theme['secondary-color']};
            }
          }
          &.success {
            &:after {
              background: ${({ theme }) => theme['success-color']};
            }
          }
          &.warning {
            &:after {
              background: ${({ theme }) => theme['warning-color']};
            }
          }
          &.info {
            &:after {
              background: ${({ theme }) => theme['info-color']};
            }
          }
          &.danger {
            &:after {
              background: ${({ theme }) => theme['danger-color']}5;
            }
          }
        }
        &:before {
          position: absolute;
          ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: -25px;
          top: 0;
          height: 100%;
          width: 2px;
          border-radius: 10px;
          opacity: 0;
          visibility: hidden;
          content: '';
          background: ${({ theme }) => theme['primary-color']};
          transition: all 0.3s ease;
        }
        &:after {
          position: absolute;
          ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: 0;
          top: 50%;
          transform: translatey(-50%);
          width: 8px;
          height: 8px;
          border-radius: 50%;
          content: '';
          background: ${({ theme }) => theme['primary-color']}50;
          transition: all 0.3s ease;
        }
        &.secondary {
          &:after {
            background: ${({ theme }) => theme['secondary-color']}50;
          }
          &:before {
            background: ${({ theme }) => theme['secondary-color']};
          }
        }
        &.success {
          &:after {
            background: ${({ theme }) => theme['success-color']}50;
          }
          &:before {
            background: ${({ theme }) => theme['success-color']};
          }
        }
        &.warning {
          &:after {
            background: ${({ theme }) => theme['warning-color']}50;
          }
          &:before {
            background: ${({ theme }) => theme['warning-color']};
          }
        }
        &.info {
          &:after {
            background: ${({ theme }) => theme['info-color']}50;
          }
          &:before {
            background: ${({ theme }) => theme['info-color']};
          }
        }
        &.danger {
          &:after {
            background: ${({ theme }) => theme['danger-color']}50;
          }
          &:before {
            background: ${({ theme }) => theme['danger-color']};
          }
        }
      }
    }
  }
`;

const ToolsCheckWrapper = styled.div`
  .ant-list-item {
    padding: 6px 0px;
    .ant-list-item-meta {
      padding: 0px 0px;
    }
  }
`;
export { CategoryBoxStyled, AddNewToolWrapper, ToolsCheckWrapper };

import { allSubscribers } from '../../utility/utility';
import actions from './actions';

const { fetchTeamChatStart, fetchTeamChatSuccess, fetchTeamChatFailed } = actions;

const fetchTeamChatData = ({ teamID }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();

    try {
      dispatch(fetchTeamChatStart());
      const fetchTeamChatDataSub = await db
        .collection('team-chat')
        .doc(teamID)
        .onSnapshot(async snapshot => {
          const newData = snapshot.data();
          // const updatedData = [];
          // newData &&
          //   newData.content &&
          //   newData.content.map(async (item, index) => {
          //     await item.userRef.get().then(res => {
          //       updatedData.push({ ...item, name: res.data().name });
          //     });
          //     console.log(`updatedData---`, updatedData);
          //   });
          newData && dispatch(fetchTeamChatSuccess(newData));
        });
      allSubscribers.findIndex(x => x.name === 'fetchTeamChatDataSub') === -1 &&
        allSubscribers.push({
          func: fetchTeamChatDataSub,
          name: 'fetchTeamChatDataSub',
        });
    } catch (err) {
      dispatch(fetchTeamChatFailed(err));
    }
  };
};

const updateTeamChat = ({ teamID, messageContent }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const chatMessage = { ...messageContent, userRef: db.doc('users/' + firebase.auth().currentUser.uid) };
    try {
      await db
        .collection('team-chat')
        .doc(teamID)
        .set(
          {
            content: firebase.firestore.FieldValue.arrayUnion(chatMessage),
          },
          { merge: true },
        );
    } catch (err) {
      console.log(`err.message`, err.message);
    }
  };
};

export { fetchTeamChatData, updateTeamChat };

/* ------------------------------------------------------ */
/*                   TRIAL BILLING PAGE.                  */
/* ------------------------------------------------------ */
/**
 * Currently V1 Pricing model is active.
 * The main difference between pricing model is V2 pricing model has tiers pricing according to words.
 *
 * This page is visible to normal users. This page is not visible for team members, as they join craftly without any credit card info and plan
 *
 * REACT_APP_V1_PRICE_MODEL & REACT_APP_V2_PRICE_MODEL is the env enviournments.
 *
 * Currently there are 2 type of plans in v1 pricing model
 * 1. Starter: fixed number of words
 * 2. Crafter: unlimited words
 *
 * There is monthly and yearly duration plan.
 *
 * To create the subscription, we used the Stripe API. We took the user's information and created the payment method using the Stripe multiple API,
 * as well as utilising Stripe to authenticate the card information.
 * Stripe also provides the form for collecting card details. Stripe components are used to obtain card information.
 *
 * We store the fingerprint of the card in our database as soon as the user uses it. This fingerprint information, which is unique to each card, is provided by the stripe
 *
 *
 * -----------------  TRIALBILLINGPAGE -----------------
 * trialBillingPage: its a flag to determinine whether to show this page to the user or not. if it is true then it will be redirected to home page.
 * if it is false then it will be remain on this page.
 * it is present on every user firebase data. when a user account is created it is bydefault false
 * -----------------  TRIALBILLINGPAGE -----------------
 *
 * ---------------  CHECK DUPLICATE CARD ---------------
 * To determine whether the user has previously used the credit card, we first compare the card fingerprint to the rest of the cards.
 * If the card has already been used, there will be no trial period for the user, and the notification window will prompt the user to purchase a plan.
 *
 * Credit Card information is also stores in BigQuery in called "all-creadits"
 * ---------------  CHECK DUPLICATE CARD ---------------
 *
 *
 * ---------------  Pricing Object Array   ---------------
 *
 * '../../utility/stripePlan' : from this file v1 and v2 pricing model object array fetched.
 * planTiers is the variable which has the detials
 *
 * ---------------  Pricing Object Array   ---------------
 *
 *
 * ---------------  Acess  ---------------
 * When a user signups, bydefault every user is a normal user.
 * Craftly admin has the authority to change the tpye of user. it can be change to FREE_USER or STAFF or ADMIN
 * These type of profile will skip the onboarding and trial Billing page.
 *
 * Team member also skil the onboarding and trial Billing page
 * ---------------  Acess  ---------------
 *
 * ---------------  adminBillingSettings  ---------------
 * Admin Stripe collection.
 * Firestore:
 * Collection: stripe-plan.
 * Document: crafter | starter | team | trial | noCreditCard  -> Each plan has their own document and each document has its own properties lile
 *
 * In this page Stripe Product Id is needed so that subscription starts on the specific product
 * ---------------  adminBillingSettings  ---------------
 *
 *
 * Crafter plan has 2 months free
 */

import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import PlanPoints from '../../components/PlanPoints';
import Signout from '../../components/Signout';
import {
  CALENDLY,
  CRAFTER,
  ENTERPRISE,
  MONTHLY_DURATION,
  ROLES_LIST,
  STARTER,
  STRIPE_STARTER_MONTHLY_PRICEID,
  TEAM_STATUS,
  YEARLY_DURATION,
} from '../../constants';
import { adminBillingSettingAction } from '../../redux/Billing/actionCreator';
import { checkUserPresentInTeam } from '../../redux/AccountSettings/actionCreator';
import { fetchAppSettings } from '../../redux/AdminFeatures/actionCreator';
import { PlanDurationSwitch } from '../../components/PlanDurationSwitch';
import { TrialBilling, TrialBillingWrapper, MobileTrail } from './styled';
import { TRAIL_STARTER_DATA, TRAIL_CUSTOM_DATA, TRAIL_CRAFTER_DATA } from '../../constants/trailprice';
import PriceList from './overview/PriceList';
import TrialFaq from './overview/TrailFaq';
import TrailMobileCard from './overview/TrailMobileCard';
import { useViewport } from '../../hooks/viewPort';
import { currentPricingModel, planTiers, stripeKey } from '../../utility/stripePlan';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutFormV2 from './overview/StripeElement/CheckoutFormV2';
import CheckoutFormV1 from './overview/StripeElement/CheckoutForm';
import AppFooter from '../../components/AppFooter';

const { Title, Text } = Typography;

// check the pricing model which model is active
const { v1PriceModal, v2PriceModal } = currentPricingModel();
const DisplayCheckoutForm = v1PriceModal ? CheckoutFormV1 : v2PriceModal ? CheckoutFormV2 : null;

const Trial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();

  const stripePromise = loadStripe(stripeKey);

  // default plan select, Crafter is default plan selected.
  const [planSelect, setPlanSelect] = useState(CRAFTER);
  const [tabClickMobile, setTabClickMobile] = useState(CRAFTER);
  const [planChecked, setPlanChecked] = useState({
    checked: false,
    planInterval: MONTHLY_DURATION,
  });

  const [starterPrice, setStarterPrice] = useState(35); // bydefault 35$ is set
  const [starterWords, setStarterWords] = useState(1);
  const [selectedStripePriceId, setSelectedStripePriceId] = useState(STRIPE_STARTER_MONTHLY_PRICEID); // by default monthly period is selected
  const [selectedPlanWords, setSelectedPlanWords] = useState(1);
  const [selectedPlanDisplayWords, setSelectedPlanDisplayWords] = useState('unlimited');
  const [selectedPlanPrice, setSelectedPlanPrice] = useState(79);
  const [selectedProductId, setSelectedProductId] = useState(false);
  const [crafterPrice, setCrafterPrice] = useState(0);
  const [crafterWords, setCrafterWords] = useState(1);
  const { uid } = useSelector((state) => state.fb.auth);
  const { adminBillingSettings } = useSelector((state) => state.billing); //
  const { usersTeams } = useSelector((state) => state.accountSettings);

  const {
    profile: { email, trialBillingPage, roles, stripeId, activatedPlanDetails, currentInvoiceId, firstName, lastName },
  } = useSelector((state) => {
    return {
      profile: state.fb.profile,
    };
  });

  useEffect(() => {
    // trialBillingPage is a flag to not to show this page again
    if (trialBillingPage === true) {
      navigate('/');
    }
    if (ROLES_LIST.includes(roles)) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, trialBillingPage]);

  const PriceCardData =
    tabClickMobile === STARTER
      ? TRAIL_STARTER_DATA
      : tabClickMobile === CRAFTER
      ? TRAIL_CRAFTER_DATA
      : TRAIL_CUSTOM_DATA;

  useEffect(() => {
    dispatch(checkUserPresentInTeam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, uid]);

  // This executes when page reload.
  useEffect(() => {
    // In firebase we have a collection stripe-plan, from which a stripe plan id is fetched.
    dispatch(adminBillingSettingAction());

    //? This fetch the settings of Trial methods. if noCredit Card is enable then user can go without giving credit card information.
    dispatch(fetchAppSettings());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // it checks whether the user is a part of any team or not.
  useEffect(() => {
    if (usersTeams && usersTeams.length > 0) {
      const checkAllDecline = usersTeams.filter(
        ({ teamInviteStatus }) => teamInviteStatus === TEAM_STATUS.DECLINE,
      ).length;

      // if a team member has a active team invitation. it redirect to the team join page
      if (usersTeams.length !== checkAllDecline) {
        navigate('/join-team');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersTeams]);

  // the duration select of the plan. either it will be yearly or monthly
  const onPlanIntervalChange = (value) => {
    setPlanChecked({
      checked: value,
      planInterval: value === true ? YEARLY_DURATION : MONTHLY_DURATION,
    });
  };

  // when a user change the plan or pricing or selects the different plan it triggers
  useEffect(() => {
    // planTiers has the details of pricing
    const getPlanTiers = planTiers.find(
      ({ plan, interval }) => plan === STARTER && interval === planChecked.planInterval,
    );
    const { quantity, amount, planPrice, planWords, yearlyWordsPerMonth } = getPlanTiers || {
      amount: '',
      planPrice: '',
      quantity: '',
    };
    setStarterPrice(amount);

    planChecked.planInterval === YEARLY_DURATION ? setStarterWords(yearlyWordsPerMonth) : setStarterWords(planWords);

    if (planSelect === STARTER) {
      const getPlanTiers = planTiers.find(
        ({ plan, interval }) => plan === STARTER && interval === planChecked.planInterval,
      );
      const { stripePriceId } = getPlanTiers;

      setSelectedStripePriceId(stripePriceId);
      setSelectedPlanWords(quantity);

      setSelectedPlanDisplayWords(planWords);
      setSelectedPlanPrice(planPrice);
      const planDetails = adminBillingSettings.find(({ plan }) => plan === planSelect);
      const { productID } = planDetails.details;
      setSelectedProductId(productID);
    }

    const getCrafterPlanTiers = planTiers.find(
      ({ plan, interval }) => plan === CRAFTER && interval === planChecked.planInterval,
    );

    const {
      quantity: crafterWord,
      amount: crafterDisplayAmount,
      planPrice: crafterAmount,
      planWords: crafterPlanWords,
    } = getCrafterPlanTiers || {
      planPrice: '',
      quantity: '',
      amount: '',
    };
    setCrafterWords(crafterPlanWords);
    setCrafterPrice(crafterDisplayAmount);

    if (planSelect === CRAFTER) {
      const getPlanTiers = planTiers.find(
        ({ plan, interval }) => plan === CRAFTER && interval === planChecked.planInterval,
      );
      const { stripePriceId } = getPlanTiers;
      setSelectedStripePriceId(stripePriceId);
      setSelectedPlanWords(crafterWord);
      setSelectedPlanDisplayWords(crafterPlanWords);

      setSelectedPlanPrice(crafterAmount);

      const planDetails = adminBillingSettings.find(({ plan }) => plan === planSelect);
      const { productID } = (planDetails && planDetails.details) || { productID: '' };
      setSelectedProductId(productID);
    }
  }, [adminBillingSettings, planChecked, planSelect]);

  // on clicking the trial button
  const handleClickScroll = () => {
    const element = document.getElementById('payment');
    if (element) {
      // 👇 Will scroll smoothly to the top of the billing section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const planChangeMobile = (plan) => {
    setTabClickMobile(plan);
    setPlanSelect(plan);
  };

  return (
    <TrialBillingWrapper>
      <Row justify="space-between">
        <Col xs={6} sm={3} md={2}>
          <img
            className="logo"
            style={{ width: '100%', margin: '2rem' }}
            src={require('../../static/general/craftly_final.png')}
            alt="logo"
          />
        </Col>
        <Col>
          <div style={{ margin: '2rem' }}>
            <Signout />
          </div>
        </Col>
      </Row>

      <TrialBilling>
        <div className="top-section">
          <div className="heading-wrapper">
            <Title level={3} className="heading">
              Pricing plans designed <br /> to fit your needs!
              <img alt="like" className="thumbs-icon" src={require('../../static/trial/like.png')} />{' '}
            </Title>
          </div>
          <div className="plan-type-switch">
            {/* plan duration yearly or monthly */}
            <PlanDurationSwitch
              rightText={width < 731 ? 'Annually' : 'Billed Annually'}
              leftText={width < 731 ? 'Monthly' : 'Billed Monthly'}
              currentId={planChecked.planInterval}
              leftTextId={MONTHLY_DURATION}
              rightTextId={YEARLY_DURATION}
              onChange={onPlanIntervalChange}
              checked={planChecked.checked}
            />
          </div>
        </div>
        <div className="plan-comparison">
          <Row className="row-price" gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8}>
              <PriceList
                v1Pricing={true}
                highlight={false}
                price={starterPrice}
                startBtn="Start 5-day Free Trial"
                words={`${starterWords.toLocaleString('en-US')}  word credits`}
                title="Starter"
                titleId={STARTER}
                description="For individuals getting started"
                titleClick={planSelect}
                selectedPrice={(titleId) => {
                  setPlanSelect(titleId);
                  handleClickScroll();
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <PriceList
                v1Pricing={true}
                highlight={true}
                price={crafterPrice}
                startBtn="Start 5-day Free Trial"
                words={`${crafterWords.toLocaleString('en-US')}  word credits`}
                title="Crafter"
                titleId={CRAFTER}
                description="For writers, marketers & small businesses"
                titleClick={planSelect}
                selectedPrice={(titleId) => {
                  setPlanSelect(titleId);
                  handleClickScroll();
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} lg={8}>
              <PriceList
                startBtn="Book a Demo"
                words="custom word credits"
                title="Enterprise"
                titleId={ENTERPRISE}
                description="Run your company on your terms"
                titleClick={planSelect}
                // on enterprice it opens the calendly invite link
                selectedPrice={() => {
                  window.open(CALENDLY, '_blank', 'noopener,noreferrer');
                }}
              />
            </Col>
          </Row>
        </div>

        {/* Mobile starts  */}

        <TrailMobileCard setTabClickMobile={planChangeMobile} tabClickMobile={tabClickMobile} />

        <MobileTrail>
          {tabClickMobile === STARTER && <Text className="custom">{`$${starterPrice}/mo`}</Text>}
          {tabClickMobile === CRAFTER && <Text className="custom">{`$${crafterPrice}/mo`}</Text>}
          {tabClickMobile === ENTERPRISE && (
            <div
              className="custom-tag"
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text className="custom-head">Custom</Text>
              <Text className="reachText">Reach out for a quote</Text>
              <Text className="text-head">unlimited word credits</Text>
            </div>
          )}

          <div className="plan-slider">
            {tabClickMobile === STARTER && (
              <>
                <Text className="text-head">{`${starterWords.toLocaleString('en-US')}  word credits`}</Text>
              </>
            )}
            {tabClickMobile === CRAFTER && (
              <>
                <Text className="text-head">{`${crafterWords.toLocaleString('en-US')}  word credits`}</Text>
              </>
            )}
          </div>

          <Card>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text className="plan-list-title">Plan Inclusions List </Text>
              {PriceCardData?.map(({ recommended, name, flag, hover }) => (
                <PlanPoints recommended={recommended} text={name} condition={flag} hover={hover} />
              ))}
            </div>
            <Button type="primary" className="free-trial" onClick={() => handleClickScroll()}>
              {tabClickMobile === 'Starter'
                ? 'Start 5-day Free Trial'
                : tabClickMobile !== 'Enterprise'
                ? 'Start 5-day Free Trial'
                : 'Book a Demo'}
            </Button>
          </Card>
        </MobileTrail>

        {/* Mobile end  */}

        {/* Stripe Elements   Billing Card section */}
        <div className="stripe-form" id="payment">
          <Title className="billing-summary">Billing Summary</Title>
          <Elements stripe={stripePromise}>
            <DisplayCheckoutForm
              selectedPlanPrice={selectedPlanPrice}
              planSelect={planSelect}
              selectedProductId={selectedProductId}
              uid={uid}
              showPlanDuration={
                planChecked.planInterval === YEARLY_DURATION
                  ? 'year'
                  : planChecked.planInterval === MONTHLY_DURATION
                  ? 'mo'
                  : ''
              }
              planDuration={planChecked.planInterval}
              stripeId={stripeId}
              selectedPriceId={selectedStripePriceId}
              selectedPlanWords={selectedPlanWords}
              email={email}
              activatedPlanDetails={activatedPlanDetails}
              currentInvoiceId={currentInvoiceId}
              firstName={firstName}
              lastName={lastName}
              selectedPlanDisplayWords={selectedPlanDisplayWords}
              // changePlan={() => slider.current.prev()}
            />
          </Elements>
        </div>
        <TrialFaq />
        <AppFooter />
      </TrialBilling>
    </TrialBillingWrapper>
  );
};

export default Trial;

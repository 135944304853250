import { ALL_TOOLS } from '../constants';

// this is to group the sidemenu tool links
export const displayCategoryList = [
  { categoryName: 'All Tools', categoryId: ALL_TOOLS, icon: 'all-tools', categoryOrder: 1 },
  { categoryName: 'Personal & Blogs', categoryId: 'personal-&-blogs', icon: 'blog', categoryOrder: 1 },
  { categoryName: 'Socials', categoryId: 'socials', icon: 'social', categoryOrder: 2 },
  { categoryName: 'Writing', categoryId: 'writing', icon: 'webcopy', categoryOrder: 3 },
  { categoryName: 'Company & Branding', categoryId: 'company-&-branding', icon: 'company', categoryOrder: 4 },
  { categoryName: 'Email', categoryId: 'email', icon: 'email', categoryOrder: 5 },
  { categoryName: 'Web Copy', categoryId: 'web-copy', icon: 'webcopy', categoryOrder: 6 },
  { categoryName: 'SEO', categoryId: 'seo', icon: 'seo', categoryOrder: 7 },
  { categoryName: 'Sales', categoryId: 'sales', icon: 'sales', categoryOrder: 8 },
  { categoryName: 'Student', categoryId: 'student', icon: 'student', categoryOrder: 9 },
  { categoryName: 'Fun', categoryId: 'fun', icon: 'fun', categoryOrder: 10 },
  { categoryName: 'Real Estate', categoryId: 'AI image generation', icon: 'real-estate', categoryOrder: 11 },
  {
    categoryName: 'AI image generation',
    categoryId: 'ai-image-generation ',
    icon: 'img-generation',
    categoryOrder: 12,
  },
];

export const engines = [
  { title: 'Davinci', value: 'davinci' },
  { title: 'Curie', value: 'curie' },
  { title: 'Davinci-Instruct-Beta', value: 'davinci-instruct-beta' },
  { title: 'Curie-Instruct-Beta', value: 'curie-instruct-beta' },
];
export const modelList = [
  { title: 'Model A', value: 'modelA' },
  { title: 'Model B', value: 'modelB' },
  { title: 'Model C', value: 'modelC' },
];
export const numberOfOutput = [
  { title: '1', value: 1 },
  { title: '2', value: 2 },
  { title: '3', value: 3 },
  { title: '4', value: 4 },
  { title: '5', value: 5 },
  { title: '6', value: 6 },
  { title: '7', value: 7 },
  { title: '8', value: 8 },
  { title: '9', value: 9 },
  { title: '10', value: 10 },
  { title: '11', value: 11 },
  { title: '12', value: 12 },
];

export const dynamicPhotoToolList = [
  'photo-caption-holiday-seasonal',
  'photo-caption-customer-testimonial',
  'photo-caption-contest-giveaway',
  'short-n-sweet',
  'photo-caption-tips-knowledge',
  'personal',
  'photo-caption-service-launch',
  'photo-caption-event-recap',
  'photo-caption-service-highlight',
  'photo-caption-special-promotion',
  'photo-caption-event-launch',
];

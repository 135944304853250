/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import { Tabs, Typography } from 'antd';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TeamsContainer, TeamsHeader } from './styled';
import { NewTabStyle } from '../Settings/styled';
import TeamComponent from './overview/Teams/Team';

import { TabItemData } from './overview/mock';
import Result from './overview/Result/Result';

const { TabPane } = Tabs;
const Teams = () => {
  const Title = Typography;
  // let history = useHistory();
  let location = useLocation();

  // const onChange = key => {
  //   navigate({
  //     pathname: `/settings/${key}`,
  //     state: {
  //       key,
  //     },
  //   });
  // };

  const handletab = (item) => {
    if (item?.key === 'Team') {
      return <TeamComponent />;
    } else if (item?.key === 'Results') {
      return <Result />;
    }
  };

  return (
    <TeamsContainer>
      <TeamsHeader>
        <Title level={2}>
          Hello John,<span className="welcome">Welcome Back</span>
        </Title>
      </TeamsHeader>
      <NewTabStyle>
        <Tabs
          // onTabClick={onChange}
          defaultActiveKey={location?.state ? location?.state?.key : 'Team'}
          transition={true}
        >
          {TabItemData.map((item) => {
            return (
              <TabPane tab={item.title} key={item.key}>
                {handletab(item)}
              </TabPane>
            );
          })}
        </Tabs>
      </NewTabStyle>
    </TeamsContainer>
  );
};

export default Teams;

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';

import Iframe from 'react-iframe';
import { useLocation } from 'react-router-dom';

const HomePage = () => {
  const [referralLink, setReferralLink] = useState('');
  const location = useLocation();
  const { via } = queryString.parse(location.search);

  useEffect(() => {
    if (via) {
      setReferralLink(window.location.search);
    }
  }, [via]);
  return (
    <div style={{ height: '100vh' }}>
      <Iframe
        url={`https://craftly.ai/${referralLink}`}
        width="100%"
        height="100%"
        id="homePage"
        display="initial"
        position="relative"
        frameBorder={'0'}
      />
    </div>
  );
};

export default HomePage;

/* ------------------------------------------------------ */
/*                     Tool Design Left                   */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * The left side of the tool has the Form input fields.
 * The fields are render according to each tool.
 *
 * Every input has its own limit on characters.
 * Tool fields are fetched from Firebase
 * Collection: tools
 * Document: <tool-id>
 *
 */

import React from 'react';
import { ToolDesignLeftWrap } from '../styled.js';
import { Input, Radio, Typography, Tag, Tooltip, Slider, Select, Switch, Skeleton } from 'antd';
import { Button } from '../../../components/Button/index.jsx';
import { InfoCircleOutlined } from '@ant-design/icons';
import BreadcrumbCai from '../../../components/Breadcrumb/index.jsx';

const { Option } = Select;
const { CheckableTag } = Tag;

/**
 * @allToolsLoading loading flag, when tool data is fetching from firestore
 * @checkGroupIsPresent a set of tools which have a common group of tools
 * @toolId tool id
 * @displayToolName few tools have their different tool name
 * @onDynamicToolChange when a group of tools are in dropdown we change by toolName
 * @fields tool input field
 * @maxLimitExceed max limit of each input
 * @toolCharacterInput number of characters are input by the user
 * @toolBody tool input body details
 * @onChangeText  on any field text change
 * @engines predeifine text change
 * @onSelectTones option on selecting tones
 * @numberOfOutput object array for the multiple options
 * @sliderRange antd slider, craftly admin set the marks
 * @modelList multiple type of model
 * @toolOutputLoading when fetching tool output from open-ai
 * @isFormValid is form is valid or not
 * @onSubmit func to send the request to api
 * @allowTemplateOnPlan is tool is avaialble for the user
 */
const ToolDesignLeft = ({
  allToolsLoading,
  checkGroupIsPresent,
  toolId,
  displayToolName,
  onDynamicToolChange,
  fields,
  maxLimitExceed,
  toolCharacterInput,
  toolBody,
  onChangeText,
  engines,
  onSelectTones,
  numberOfOutput,
  sliderRange,
  modelList,
  toolOutputLoading,
  isFormValid,
  onSubmit,
  allowTemplateOnPlan,
}) => {
  const { Text } = Typography;
  const { TextArea } = Input;

  return (
    <ToolDesignLeftWrap>
      <div className="menu">
        <BreadcrumbCai firstLink={'/category'} firstLinkTitle="Tool Library" secondLinkTitle={displayToolName} />
      </div>
      <Skeleton loading={allToolsLoading} active title paragraph={{ rows: 6 }}>
        {checkGroupIsPresent && (
          <>
            {checkGroupIsPresent.groupTitle && (
              <Text style={{ fontSize: '15px' }}>{checkGroupIsPresent.groupTitle}</Text>
            )}
            <Select
              name={'dynamicTool'}
              value={toolId}
              style={{ width: '100%', marginTop: '8px' }}
              onChange={onDynamicToolChange}
            >
              {checkGroupIsPresent.optionFields &&
                checkGroupIsPresent.optionFields.map(({ fieldName, fieldKey }) => {
                  return <Option value={fieldKey}>{fieldName}</Option>;
                })}
            </Select>
          </>
        )}
        {/* tool fields  */}
        {fields &&
          fields.map((field, index) => {
            return (
              <div key={`${field.key}-${index}`}>
                <div className="product-headings">
                  <>
                    <Text className="field-title">
                      {field.name}
                      <span className="text-danger" style={{ padding: '0px 5px' }}>
                        {!field.optional && '*'}
                      </span>
                      <span className="optional-field">{field.optional && '(optional)'}</span>

                      {field.toolTipText && (
                        <Tooltip title={field.toolTipText}>
                          <InfoCircleOutlined className="info-icon" />
                        </Tooltip>
                      )}
                    </Text>

                    {!['radio', 'engine-select', 'number-of-output', 'model-radio-button', 'generic-options'].includes(
                      field.type,
                    ) ? (
                      <Text
                        className="field-count"
                        type={
                          maxLimitExceed().check && maxLimitExceed()[field.key] === field.key ? 'danger' : 'secondary'
                        }
                      >
                        {`${toolCharacterInput ? toolCharacterInput[field.key] || 0 : 0}`}/{field.characterLimit}
                      </Text>
                    ) : (
                      <Text></Text>
                    )}
                  </>
                </div>
                {field.subtitle && (
                  <div style={{ marginBottom: '1rem' }}>
                    <Text style={{ fontSize: '12px' }}>{field.subtitle}</Text>{' '}
                  </div>
                )}
                {field.type === 'input' && (
                  <Input
                    name={field.key}
                    value={toolBody[field.key]}
                    onChange={onChangeText}
                    placeholder={field.placeholder}
                    onPaste={onChangeText}
                  />
                )}
                {field.type === 'textarea' && (
                  <TextArea
                    name={field.key}
                    value={toolBody[field.key]}
                    placeholder={(field.placeholder || '').replaceAll('\\n', '\n')}
                    autoSize={{ minRows: field.maxRows || 4, maxRows: field.maxRows || 7 }}
                    onChange={onChangeText}
                    onPaste={onChangeText}
                  />
                )}
                {field.type === 'radio' && (
                  <Switch
                    defaultChecked={false}
                    name={field.key}
                    onClick={() =>
                      onChangeText({
                        target: {
                          name: field.key,
                          value: toolBody[field.key] === 'true' ? 'false' : 'true',
                        },
                      })
                    }
                  />
                )}
                {field.type === 'select' && (
                  <>
                    {field.options.map(({ value, title }) => (
                      <CheckableTag
                        key={value}
                        className={'tone-tag'}
                        name={value}
                        checked={toolBody.tone && toolBody.tone.indexOf(value) > -1}
                        onChange={(checked) => onSelectTones(value, checked)}
                      >
                        {title}
                      </CheckableTag>
                    ))}
                  </>
                )}
                {field.type === 'engine-select' && (
                  <Select
                    name={field.key}
                    style={{ width: '100%' }}
                    onChange={(value) =>
                      onChangeText({
                        target: {
                          name: field.key,
                          value,
                        },
                      })
                    }
                  >
                    {engines.map(({ value, title }) => (
                      <Option value={value}>{title}</Option>
                    ))}
                  </Select>
                )}
                {field.type === 'slider' && (
                  <Slider
                    className="slider-marks"
                    name={field.key}
                    marks={sliderRange}
                    step={null}
                    defaultValue={0.5}
                    onChange={(sliderValue) => {
                      const value = sliderRange[sliderValue];
                      onChangeText({
                        target: {
                          name: field.key,
                          value,
                        },
                      });
                    }}
                    tooltip={{
                      open: false,
                    }}
                  />
                )}
                {field.type === 'number-of-output' && (
                  <Select
                    name={field.key}
                    style={{ width: '100%' }}
                    onChange={(value) =>
                      onChangeText({
                        target: {
                          name: field.key,
                          value,
                        },
                      })
                    }
                  >
                    {numberOfOutput.map(({ value, title }) => (
                      <Option value={value}>{title}</Option>
                    ))}
                  </Select>
                )}
                {field.type === 'model-radio-button' && (
                  <Radio.Group buttonStyle="solid" name={field.key} style={{ width: '100%' }} onChange={onChangeText}>
                    {modelList.map(({ value, title }) => (
                      <Radio.Button value={value}>{title}</Radio.Button>
                    ))}
                  </Radio.Group>
                )}
                {field.type.includes('generic-options') && (
                  <Select
                    name={field.key}
                    style={{ width: '100%' }}
                    onChange={(value) =>
                      onChangeText({
                        target: {
                          name: field.key,
                          value,
                        },
                      })
                    }
                  >
                    {field.keyOptionValues &&
                      field.keyOptionValues.map(({ fieldKey, fieldName }) => (
                        <Option value={fieldKey}>{fieldName}</Option>
                      ))}
                  </Select>
                )}
              </div>
            );
          })}
      </Skeleton>

      {/* submit  tool fields data  */}
      {fields && (
        <div className="generate-btn">
          <Button
            id="craftButton"
            loading={toolOutputLoading}
            disabled={!isFormValid() || maxLimitExceed().check || !allowTemplateOnPlan}
            block
            size="large"
            type="primary"
            onClick={onSubmit}
          >
            {toolOutputLoading ? 'Crafting' : 'Craft It'}
          </Button>
        </div>
      )}
      {/* {width < 769 ? null : (
        <>
          <Text className="text-bottom">How were your results?</Text>
          <Rate className="star" defaultValue={0} disabled={!disable} />
        </>
      )} */}
    </ToolDesignLeftWrap>
  );
};

export default ToolDesignLeft;

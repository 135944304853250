/* ------------------------------------------------------ */
/*                         Headings                       */
/* ------------------------------------------------------ */
// Custom Heading Component
// Styles are present in styled-component

/*
Used In 
src/components/utilities/auth-info/help.js
src/components/utilities/auth-info/notificationList.js
src/container/Invite/Invite.js
src/container/profile/authentication/overview/ResetPassword.js
src/container/profile/authentication/overview/VerifyPhone.js
src/container/profile/settings/overview/BillingSettings.js
src/container/TeamChat/overview/TeamChat.js
src/container/ToolStatus/ToolCards.js
*/

import React from 'react';
import PropTypes from 'prop-types';
import * as headings from './styled';

const Heading = ({ as, children, className, id }) => {
  const StyledHeading = as ? headings[as.toUpperCase()] : headings.H1;

  return (
    <StyledHeading className={className} id={id}>
      {children}
    </StyledHeading>
  );
};

Heading.defaultProps = {
  as: 'h1',
};

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  id: PropTypes.string,
};

export default Heading;

/* ------------------------------------------------------ */
/*                      Member Summary                     */
/* ------------------------------------------------------ */

/**
 * Team admin and team member can reivew other team member progress also.
 *
 * Team admin has the ability to delete team member from the team
 * total words and total hots in a current month will be dislayed
 *
 *
 * MostToolUsed is calculate from all the team members tool output
 * all tool outout data store in the team-members collection
 *
 *
 * on deleting the team member the data wont of the team member wont delete. only team member will be removed from
 * the team
 *
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Space, Typography, Modal, Tag, Avatar } from 'antd';
import { Cards } from '../../../components/Card';
import { capitalizeFirstLetter, firstTwoLetters, getToolIcon, getToolIconId } from '../../../utility/utility';
import { MemberSummaryWrapper, ModalContentWrapper } from '../style';
import WordCarft from '../../../components/WordCraft';
import { fetchTeamMembers, getMemberBots, updateTeamJoinAction } from '../../../redux/AccountSettings/actionCreator';
import { DeleteOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from '../../../components/Button';
import TeamHeader from '../../../components/TeamPageHeader/TeamHeader';
import { TEAM_MEMBERS_PAGE, TEAM_RESULTS, TEAM_STATUS } from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';

const { Text, Title, Paragraph } = Typography;

const MemberSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [mostToolUsed, setmostToolUsed] = useState([]);
  const { allTools } = useSelector((store) => store.adminFeatures);
  const { uid } = useSelector((state) => state.fb.auth);

  const { wordCraftMemberList, selectedMember, workspaceAdminUID } = location.state || {
    wordCraftMemberList: null,
    selectedMember: '',
    workspaceAdminUID: null,
  };

  if (location.state === undefined || workspaceAdminUID === null) {
    navigate('/team-portal');
  }

  // it is used to calcualte from the team-memnbers collection and craft in sub-collection craft.
  const { memberBots } = useSelector((state) => state.accountSettings);

  useEffect(() => {
    workspaceAdminUID && dispatch(fetchTeamMembers(workspaceAdminUID));
    selectedMember &&
      selectedMember.teamMemberUID &&
      workspaceAdminUID &&
      uid &&
      dispatch(getMemberBots({ workspaceAdminUID, teamMemberUID: selectedMember.teamMemberUID }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMember, workspaceAdminUID, uid]);

  useEffect(() => {
    if (memberBots && memberBots.length > 0) {
      const memberUsedTools = memberBots
        .map((item) => item.toolName)
        .map((tool, index, data) => {
          const count = data.filter((toolName) => toolName === tool).length;
          return { tool, count };
        });
      const mostUsedTools = [...new Set(memberUsedTools.map(JSON.stringify))]
        .map(JSON.parse)
        .sort(function (a, b) {
          return parseFloat(b.count) - parseFloat(a.count);
        })
        .slice(0, 3)
        .map(({ tool }) => {
          return allTools.find(({ id, title, description, icon }) => id === tool);
        })
        .filter(Boolean);
      setmostToolUsed(mostUsedTools);
    } else {
      setmostToolUsed([]);
    }
  }, [memberBots, selectedMember.teamMemberUID, allTools]);

  const memberIdentification = selectedMember ? selectedMember.teamMemberName || selectedMember.teamMemberEmail : '';

  const onTeamMemberRemove = (uid) => {
    TeamMemberDeleteConfirmation();
  };

  const TeamMemberDeleteConfirmation = () => {
    const confirmDelete = () => {
      dispatch(
        updateTeamJoinAction({
          action: TEAM_STATUS.REMOVE,
          teamAdminUID: uid,
          memberEmail: selectedMember.teamMemberEmail,
          teamAdminControl: true,
        }),
      );
      navigate('/team-portal/team-members');
      warningModal.destroy();
    };

    //confirmation on deleting the team member
    // on deleting the team member the data wont delete
    const warningModal = Modal.warning({
      icon: null,
      centered: true,
      closable: true,
      width: 600,
      okButtonProps: { style: { display: 'none' } },
      content: (
        <ModalContentWrapper>
          <Text className="content">
            All user data will be removed and the user will be removed from your team billing. Are you sure you'd like
            to continue?
          </Text>
          <Space className="button-action" align="center">
            <Button onClick={confirmDelete} size="large" type="primary">
              Delete User <ArrowRightOutlined />
            </Button>
          </Space>
        </ModalContentWrapper>
      ),
    });
  };

  return (
    <>
      <MemberSummaryWrapper>
        <Row>
          <Col xs={24}>
            <TeamHeader showPageName="Overview" currentPageToDisplay={TEAM_MEMBERS_PAGE} />
          </Col>
        </Row>

        <Cards headless>
          <Row justify="space-between" gutter={12}>
            <Col xs={24} sm={18} xxl={19} className="member-summary-wrapper">
              <div className="summary">
                <Avatar size={100} style={{ color: '#ffff', backgroundColor: '#9D79FD' }}>
                  {firstTwoLetters(memberIdentification)}
                </Avatar>
              </div>
              <div className="member">
                <div>
                  <Title level={3} className="name">
                    {capitalizeFirstLetter(memberIdentification)}
                  </Title>
                  {selectedMember.teamMemberName && <Text className="text"> {selectedMember.teamMemberEmail}</Text>}
                </div>
                <Paragraph className="date">
                  <Tag color="purple"> {capitalizeFirstLetter(selectedMember && selectedMember.teamInviteStatus)}</Tag>
                  {/* <div className="red-dot"></div> <Text className="text">Date Added: </Text> */}
                  {/* <Text className="date-text">02-Nov-2021</Text> */}
                </Paragraph>
              </div>
            </Col>

            <Col xs={24} sm={6} xxl={3}>
              {workspaceAdminUID === uid && selectedMember.teamMemberUID !== uid && (
                <Button
                  icon={<DeleteOutlined />}
                  size="default"
                  // loading={addTeamMemberLoading}
                  htmlType="submit"
                  type="danger"
                  onClick={() => onTeamMemberRemove(selectedMember.teamMemberUID)}
                >
                  Delete
                </Button>
              )}
            </Col>
          </Row>
        </Cards>

        <Row justify="space-between" gutter={15}>
          <Col sm={12}>
            <Cards headless>
              <Text> Current Month Words Crafted</Text>
              <WordCarft
                wordCraftMemberList={wordCraftMemberList}
                teamMemberUID={selectedMember && selectedMember.teamMemberUID}
                displayTitle="words"
                subHeading="Words Crafted"
              />
            </Cards>
          </Col>
          <Col sm={12}>
            <Cards headless>
              <Text> Current Month Total Hits</Text>
              <WordCarft
                wordCraftMemberList={wordCraftMemberList}
                teamMemberUID={selectedMember && selectedMember.teamMemberUID}
                displayTitle="totalHits"
                subHeading="Total Hit"
              />
            </Cards>
          </Col>
        </Row>

        <div className="most-tool">
          <Text>Most Tool Used</Text>
        </div>

        <Row gutter={15}>
          {mostToolUsed &&
            mostToolUsed.length > 0 &&
            mostToolUsed.map(({ id, title, description, img }) => {
              const toolIconId = getToolIconId({ toolId: id, allTools });
              const toolIcon = getToolIcon(toolIconId);
              return (
                <Col sm={8}>
                  <Cards headless>
                    <div className="top-tool">
                      <div className="tool-image">
                        <img width="100%" src={toolIcon} alt="" />
                      </div>
                      <div className="tool-details">
                        <Title level={5} className="title">
                          {title}
                        </Title>
                        <Text className="description"> {description} </Text>
                      </div>
                    </div>
                  </Cards>
                </Col>
              );
            })}
        </Row>

        <Space size={15}>
          <Button size="large" type="primary" onClick={() => navigate(`/${TEAM_RESULTS}`)}>
            Recent Results <ArrowRightOutlined />{' '}
          </Button>
        </Space>
      </MemberSummaryWrapper>
    </>
  );
};

export default MemberSummary;

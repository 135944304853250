/* ------------------------------------------------------ */
/*                      Custom Slider                     */
/* ------------------------------------------------------ */

/* ------- THIS FEATURE IS DESIGNED FOR V2 PRICING ------ */
/**
 * Thesea are 2 varibales to control the display of Pricing. These value of these variables are either true or false
 * REACT_APP_V1_PRICE_MODEL
 * REACT_APP_V2_PRICE_MODEL
 */

/*
Methadology.
This component is modified over antd slider component. https://ant.design/components/slider

The slider picks the words and filters the price based on the number of words.
Each plan has tier pricing model. 
V2 pricing model: src/utility/stripePlan.js

The slider controls the word count, and the price is determined by the word count.
The slider component is a generic component, for crafter and starter.

The tier of each plan can be easily accomodated via object array in stripePlan.js. the display of price is control by these env variables 
REACT_APP_V1_PRICE_MODEL
REACT_APP_V2_PRICE_MODEL

Used In 
src/container/Trial/Trialv2.jsx
src/container/Trial/TrialPriceCards/PriceList.js
 
*/

import React from 'react';
import PropTypes from 'prop-types';
import { SliderStyle } from './styled';

/**
 *
 * @func onAfterChange: it trigger when there is a change in slider value or slider position
 * @boolean tooltipVisible: to show tooltip or not
 * @ReactNode tipFormatter: custom tooltip data
 * @number fixValue: it's a flag for
 * @object marks: required the object to create the steps in slider
 * @number defaultValue: the value on page renders
 * @number step: in our case it is null, normally its the increment in the step.
 * @string trackColor: it can be different
 * @string railColor: it can be different
 */

const CustomSlider = (props) => {
  const {
    onAfterChange,
    tooltipVisible,
    tipFormatter = null,
    fixValue,
    marks,
    trackColor = null,
    railColor = null,
    step,
    defaultValue,
  } = props;

  // when the slider position change
  const onAfterChanges = (values) => {
    if (onAfterChange) onAfterChange(values);
  };
  return (
    <SliderStyle
      marks={marks}
      defaultValue={defaultValue}
      step={step}
      onAfterChange={onAfterChanges}
      value={fixValue}
      tooltipVisible={tooltipVisible}
      getTooltipPopupContainer={(triggerNode) => triggerNode}
      tipFormatter={tipFormatter}
      trackColor={trackColor}
      railColor={railColor}
    />
  );
};

CustomSlider.propTypes = {
  marks: PropTypes.object,
  defaultValue: PropTypes.number,
  step: PropTypes.number,
  onAfterChange: PropTypes.func,
  tooltipVisible: PropTypes.bool,
  trackColor: PropTypes.string,
};

export { CustomSlider };

import styled from 'styled-components';

const ToolLibraryCardStyled = styled.div`
  cursor: pointer;
  .ant-card-bordered {
    border: 0px;
  }

  .icon-box {
    width: 36px;
    height: 36px;
  }
  .icon-box img {
    width: 100%;
  }
  .second-img {
    position: absolute;
    right: 0;
    top: 19px;
    @media (max-width: 731px) {
      height: 25px;
    }
  }

  .top {
    padding: 20px 17px 0px;
    @media (max-width: 731px) {
      padding: 16px 17px 0px !important;
    }
  }

  .link {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24px;
    @media (max-width: 731px) {
      height: 25px;
    }
  }

  .box-secondary h1 {
    font-family: 'Poppins-SemiBold';
    font-weight: 500 !important;
    font-size: 1rem;
    color: ${({ theme }) => theme['white-color']};
    @media (max-width: 786px) {
      font-size: 1rem;
    }
  }

  .title {
    width: 100% !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1rem;
    .tool-title-text {
      color: #282b3f;
      font-weight: 500 !important;
      font-family: 'Poppins-SemiBold';
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 0;
      margin-bottom: 0px;

      @media (max-width: 731px) {
        padding-right: 0px;
        font-family: 'Poppins-SemiBold';
        font-size: 12px;
      }
    }
  }

  .desc {
    font-size: 13px;
    font-weight: 500;
    font-family: 'Poppins-Regular';
    color: #282b3f;
    line-height: 20px;
    margin: 11px 0 20px 0;
    @media (max-width: 731px) {
      margin-top: 5px;
      font-size: 12px;
      margin-bottom: 0px;
      font-family: 'Poppins-Regular';
      line-height: 12px;
      padding-bottom: 15px;
    }
  }

  &:hover {
    box-shadow: 0px 23.4967px 36.7135px -23.4967px rgba(95, 103, 117, 0.25);
    border: 2px solid #8878f9 !important;
    border-radius: 10px;
  }
`;

const ToolPopCardWrapper = styled.div`
  @media (min-width: 768px) and (max-width: 1080px) {
    .desc {
      font-size: 11px;
      margin-right: 29px;
    }
  }

  @media (min-width: 992px) and (max-width: 1080px) {
    .title h1 {
      margin-right: 30px;
      font-size: 12px;
    }
  }
`;
export { ToolLibraryCardStyled, ToolPopCardWrapper };

import actions from './actions';

const initialState = {
  productList: [],
  subscriptionList: [],
  fixedPaymentList: [],
  loading: false,
  error: null,
  currentBilling: {
    currcentPricingPlan: '',
    currcentProductName: '',
    trialPlanAcitivated: '',
    firstSubscriptionCharged: null,
    currentPeriodEnd: '',
    currentPeriodStart: '',
  },
  recentPaymentList: [],
  activePlanIDs: [],
  trialLoading: false,
  noTrialccLoading: false,
  crafterOffLoading: false,
};

const {
  PRODUCT_READ_BEGIN,
  PRODUCT_READ_SUCCESS,
  PRODUCT_READ_ERR,
  SUBSCRIPTION_READ_SUCCESS,
  FIXED_PAYMENT_READ_SUCCESS,
  RECENT_PAYMENT_PROGRESS,
  RECENT_PAYMENT_SUCCESS,
  TRIAL_START,
  TRIAL_STOP,
  NO_CREDIT_CARD_TRIAL_STOP,
  NO_CREDIT_CARD_TRIAL_START,
  CRAFTER_OFF_TRIAL_START,
  CRAFTER_OFF_TRIAL_STOP,
} = actions;

const stripReducer = (state = initialState, action) => {
  const {
    type,
    productList,
    subscriptionList,
    activePlanIDs,
    fixedPaymentList,
    currentBilling,
    recentPaymentList,
    err,
  } = action;
  switch (type) {
    case PRODUCT_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_READ_SUCCESS:
      return {
        ...state,
        productList,
        loading: false,
      };
    case PRODUCT_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case SUBSCRIPTION_READ_SUCCESS:
      return {
        ...state,
        subscriptionList,
        currentBilling,
        activePlanIDs,
      };
    case FIXED_PAYMENT_READ_SUCCESS:
      return {
        ...state,
        fixedPaymentList,
      };
    case RECENT_PAYMENT_PROGRESS:
      return {
        ...state,
        recentPaymentList: [],
      };
    case RECENT_PAYMENT_SUCCESS:
      return {
        ...state,
        recentPaymentList,
      };
    case TRIAL_START:
      return {
        ...state,
        trialLoading: true,
      };
    case TRIAL_STOP:
      return {
        ...state,
        trialLoading: false,
      };

    case NO_CREDIT_CARD_TRIAL_STOP:
      return {
        ...state,
        noTrialccLoading: true,
      };
    case NO_CREDIT_CARD_TRIAL_START:
      return {
        ...state,
        noTrialccLoading: false,
      };

    case CRAFTER_OFF_TRIAL_START:
      return {
        ...state,
        crafterOffLoading: true,
      };
    case CRAFTER_OFF_TRIAL_STOP:
      return {
        ...state,
        crafterOffLoading: false,
      };

    default:
      return state;
  }
};

export default stripReducer;

/* ------------------------------------------------------ */
/*                       ThemeLayout                      */
/* ------------------------------------------------------ */

/**
 *
 * This is the main file when a user passes all the pages of onboarding.
 * Any type of user whoes status is active and landing on this file.
 *
 * It fetch the list of data from the firestore when the app loads
 *
 * -> getAllTools()
 *      Fetch all the tools data from collection 'tools' https://console.firebase.google.com/u/1/project/craftly-d50ba/firestore/data/~2Ftools~2F0aGHCO7kdi23h9Xe9kLW
 *
 * -> getAllGroupFields()
 *      Fetch type of group fields 1. tool-categories  2. tool-group-fields
 *
 * -> userWorkspaceRead()
 *      Fetch all the users personal workspace.
 *
 * -> userProjectDataRead()
 *      Fetch all the users personal project.
 *
 * -> getUserTodaysActivity()
 *      Fetch user todau activity, it includes the total progress in tool crafting
 *
 * -> getTopBannerNotifications()
 *      Fetch data of craftly admin notification
 *
 * -> adminBillingSettingAction()
 *      Fetch the plan details which is controlled by craftly admin
 *
 * -> checkUserPresentInTeam()
 *      Checking whether a user is a part of any team.
 *      A team member may be a part of more than one team, but there must be only one active team for the tool to operate on the personal workspace.
 *
 * -> GetCustomerSubscription()
 *      get all user subscriptions
 *
 *
 *
 *
 */

import React, { useEffect, useState } from 'react';
import { Layout, Col } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Div, MenuStyleComPonent, SidebarHeaderStyle } from './style';
import { userProjectDataRead, userWorkspaceRead } from '../redux/UserProject/actionCreator';
import { adminBillingSettingAction, getUserTodaysActivity } from '../redux/Billing/actionCreator';
import { GetCustomerSubscription } from '../redux/Stripe/actionCreator';
import {
  getAllTools,
  getAllGroupFields,
  getTopBannerNotifications,
  getFilteredUsers,
} from '../redux/AdminFeatures/actionCreator';
import { changeProfileDetails, checkUserPresentInTeam } from '../redux/AccountSettings/actionCreator';
import {
  ALLOW_TOOLS_PLAN_STATUS,
  CRAFTER,
  isUserRole,
  NO_TRIAL_CC,
  PERSONAL_WORKSPACE,
  SHOW_NO_ACTIVE_PLAN_TOPBAR,
  SHOW_REMAINING_HITS_BAR,
  STARTER,
  SUPER_ROLES_LIST,
  TEAM_WORKSPACE,
  TRIAL,
} from '../constants';
import TopBar from './overview/TopBar';
import { remainingTrialHits } from '../utility/userProperties';
import { noTrialccRemaingTime, remainingWordsCount } from '../utility/calculateBilling';
import { fetchAllTeams } from '../redux/team/actionCreator';
import moment from 'moment';
import MobileProjectToolSelection from '../components/MobileViewOnly/MobileProjectToolSelection';
import MobileFooter from '../components/MobileViewOnly/MobileFooter';
import AppHeader from '../components/AppHeader';
import Sidebar from '../components/Sidebar';
import V2Popup from '../components/v2PopUp/V2Popup';
import { Modal } from '../components/Modal';
import { Outlet } from 'react-router-dom';
import { withParams } from '../utility/utility';

const { theme } = require('../config/theme/themeVariables');

const { Header, Sider, Content } = Layout;

const ThemeLayout = ({ location }) => {
  const dispatch = useDispatch();
  const [width, setWidth] = useState(0);
  const [showMobileBanner, setShowMobileBanner] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [showNoInternetBanner, setShowNoInternetBanner] = useState(false);
  const [showTopBanner, setShowTopBanner] = useState(false);
  const [showWordsLimitGettingFinish, setShowWordsLimitGettingFinish] = useState(false);
  const [noTeamActivePlan, setNoTeamActivePlan] = useState(false);
  const [noActivePlan, setNoActivePlan] = useState(false);
  const [trialModeExpire, setTrialModeExpire] = useState(false);
  const [trialModeLive, setTrialModeLive] = useState(false);
  const [sideBarClicked, setSideBarClicked] = useState(false);
  const [showV2Popup, setShowV2Popup] = useState(false);
  const [remainingTrialPlanHits, setRemainingTrialPlanHits] = useState();

  const { roles, planStatus, plan, activatedPlanDetails, displayV2Popup, noTrialccPlanAcitivated } = useSelector(
    (state) => state.fb.profile,
  );
  const { uid } = useSelector((state) => state.fb.auth);
  const { todaysTotalHits, lastCraftDate, totalWordsCraft, adminBillingSettings } = useSelector(
    (state) => state.billing,
  );
  const { selectedWorkspace, memberTeamsWithOutHold } = useSelector((state) => state.accountSettings);
  const { topBannerNotifications, userManagmentFilters } = useSelector((state) => state.adminFeatures);

  console.log('adminBillingSettings-----', adminBillingSettings);
  console.log('totalWordsCraft-----', totalWordsCraft);
  console.log('lastCraftDate-----', lastCraftDate);
  console.log('todaysTotalHits-----', todaysTotalHits);

  useEffect(() => {
    if (roles && SUPER_ROLES_LIST.includes(roles)) {
      fetchAllTeams();
      getFilteredUsers(userManagmentFilters);
    }

    dispatch(getAllTools());
    dispatch(getAllGroupFields());
    dispatch(userWorkspaceRead({ setNewRecentTeamProject: false }));
    dispatch(userProjectDataRead());
    dispatch(getUserTodaysActivity());
    dispatch(getTopBannerNotifications());
    dispatch(adminBillingSettingAction());
    dispatch(checkUserPresentInTeam());
    dispatch(GetCustomerSubscription());

    checkPlanStatus();

    // display v2popup conditions
    if (displayV2Popup === undefined && showV2Popup === false) {
      (plan === STARTER || plan === CRAFTER || plan === TRIAL) && setShowV2Popup(true);
    }

    window.addEventListener('resize', updateDimensions());
    // return () => {
    //   window.addEventListener('resize', updateDimensions);
    // };
  }, []);

  useEffect(() => {
    checkPlanStatus();
  }, [
    planStatus,
    noTrialccPlanAcitivated,
    plan,
    adminBillingSettings,
    todaysTotalHits,
    selectedWorkspace,
    topBannerNotifications,
    totalWordsCraft,
  ]);

  useEffect(() => {
    dispatch(getUserTodaysActivity());
  }, [selectedWorkspace, uid]);

  useEffect(() => {
    if (showMobileBanner === null) {
      if (location.pathname.includes('/category') || location.pathname === '/') {
        setShowMobileBanner(true);
      } else {
        setShowMobileBanner(false);
      }
    }
  }, [location, showMobileBanner]);

  // handle multiple states, most important top bar notification
  const updateStates = ({
    showTopBanner,
    trialModeExpire,
    trialModeLive,
    noTeamActivePlan,
    showWordsLimitGettingFinish,
    noActivePlan,
  }) => {
    showTopBanner !== undefined && setShowTopBanner(showTopBanner);
    trialModeExpire !== undefined && setTrialModeExpire(trialModeExpire);
    trialModeLive !== undefined && setTrialModeLive(trialModeLive);
    noTeamActivePlan !== undefined && setNoTeamActivePlan(noTeamActivePlan);
    showWordsLimitGettingFinish !== undefined && setShowWordsLimitGettingFinish(showWordsLimitGettingFinish);
    noActivePlan !== undefined && setNoActivePlan(noActivePlan);
  };

  console.log('adminBillingSettings', adminBillingSettings);
  const checkPlanStatus = () => {
    const checkActivePlan = ALLOW_TOOLS_PLAN_STATUS.includes(planStatus);
    const currentDate = moment().format('YYYY-MM-DD');
    const remainingHits = remainingTrialHits({
      todaysTotalHits,
      currentDate,
      lastCraftDate,
      adminBilling: adminBillingSettings,
      plan,
    });
    const { trialExpired } = noTrialccRemaingTime(noTrialccPlanAcitivated) || { trialExpired: false };

    const { twentyFiveHundred } = remainingWordsCount({
      adminBillingSettings: adminBillingSettings,
      userCurrentPlan: plan,
      totalWordsCraft,
      activatedPlanDetails,
    });

    setRemainingTrialPlanHits(remainingHits);

    // it deals the top banner notifications
    if (selectedWorkspace && selectedWorkspace.workspaceType === PERSONAL_WORKSPACE) {
      if (isUserRole(roles) && memberTeamsWithOutHold && memberTeamsWithOutHold.length === 0) {
        // a  team member can craft on personal workspace as it is an active member of any team.
        if (plan === TRIAL || plan === NO_TRIAL_CC) {
          if (checkActivePlan) {
            // console.log(`adminBillingSettings`, remainingHits, adminBillingSettings);
            remainingHits <= SHOW_REMAINING_HITS_BAR && adminBillingSettings.length !== 0
              ? updateStates({
                  showTopBanner: true,
                  trialModeExpire: false,
                  trialModeLive: true,
                  noTeamActivePlan: false,
                })
              : updateStates({
                  showTopBanner: false,
                  trialModeExpire: false,
                  trialModeLive: false,
                  noTeamActivePlan: false,
                });
          } else {
            if (plan === NO_TRIAL_CC) {
              if (trialExpired) {
                updateStates({
                  showTopBanner: true,
                  trialModeExpire: true, // NO_TRIAL_CC Trial Expired
                  trialModeLive: false,
                  noTeamActivePlan: false,
                });
              } else if (remainingHits <= SHOW_REMAINING_HITS_BAR && adminBillingSettings.length !== 0) {
                updateStates({
                  showTopBanner: true,
                  trialModeExpire: false,
                  trialModeLive: true, // NO_TRIAL_CC Consumed all Hits
                  noTeamActivePlan: false,
                });
              } else {
                updateStates({
                  showTopBanner: false,
                  trialModeExpire: false,
                  trialModeLive: false,
                  noTeamActivePlan: false,
                });
              }
            } else {
              updateStates({
                showTopBanner: true,
                trialModeExpire: true, /// Trial with NoCreditCard Expires
                trialModeLive: false,
                noTeamActivePlan: false,
                showWordsLimitGettingFinish: false,
              });
            }
          }
        } else {
          if (checkActivePlan) {
            if (twentyFiveHundred) {
              updateStates({
                showTopBanner: true,
                noActivePlan: false,
                noTeamActivePlan: false,
                showWordsLimitGettingFinish: true,
              });
            } else {
              updateStates({
                showTopBanner: false,
                noActivePlan: false,
                noTeamActivePlan: false,
                showWordsLimitGettingFinish: false,
              });
            }
          } else {
            SHOW_NO_ACTIVE_PLAN_TOPBAR.includes(planStatus) &&
              updateStates({
                showTopBanner: true,
                noActivePlan: true,
                noTeamActivePlan: false,
                showWordsLimitGettingFinish: false,
              });
          }
        }
      } else {
        updateStates({
          showTopBanner: false,
          noActivePlan: false,
          noTeamActivePlan: false,
        });
      }
    } else if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
      const { canTeamMemberCraft } = selectedWorkspace;

      updateStates({
        showTopBanner: !canTeamMemberCraft && true,
        trialModeExpire: false,
        trialModeLive: false,
        noActivePlan: false,
        noTeamActivePlan: true,
      });
    }
    if (topBannerNotifications && topBannerNotifications.bannerList && topBannerNotifications.bannerList.length > 0) {
      const checkUserPlanBannerExist = topBannerNotifications.bannerList.find(
        ({ showPlan }) => showPlan && showPlan.includes(plan),
      );
      checkUserPlanBannerExist && setShowTopBanner(true);
    }
  };

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    console.log('collapsed', collapsed);
    if (window.innerWidth > 992) {
      setSideBarClicked(false);
      setCollapsed(false);
      if (collapsed) {
        document.body.setAttribute('id', 'mobileToggleOpen');
      } else {
        document.body.removeAttribute('id', 'mobileToggleOpen');
      }
    }
  };

  const left = 'left';
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleSidebarClicked = () => {
    setSideBarClicked(!sideBarClicked);
  };

  const onSiderCollapse = (collapsed, type) => {
    setCollapsed(!collapsed);
  };

  const renderThumbHorizontal = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  var ifConnected = window.navigator.onLine;
  if (ifConnected) {
    if (showNoInternetBanner === true) {
      setShowNoInternetBanner(false);
      setShowTopBanner(false);
    }
  } else {
    if (showNoInternetBanner === false) {
      setShowNoInternetBanner(true);
      setShowTopBanner(true);
    }
  }

  // handle the sideBar navigation
  const handleSidebar = ({ mobMenuClicked }) => {
    if (mobMenuClicked) {
      if (width < 992) {
        handleSidebarClicked();
        if (collapsed) {
          document.body.setAttribute('id', 'mobileToggleOpen');
        } else {
          document.body.removeAttribute('id', 'mobileToggleOpen');
        }
      }
      toggleCollapsed();
    }
  };

  // handle the sideBar navigation
  const closeSideBar = ({ mobMenuClicked }) => {
    if (mobMenuClicked) {
      setSideBarClicked(false);
      if (!collapsed) {
        document.body.removeAttribute('id', 'mobileToggleOpen');
      }

      setCollapsed(true);
    }
  };

  // the v2 video display is only 1 time only
  const onV2PopUpClose = () => {
    setShowV2Popup(false);
    // it updates the displayV2Popup in user firestore profile, not to show again
    dispatch(changeProfileDetails({ displayV2Popup: false }, false));
  };

  const SideBarStyle = {
    margin: showTopBanner ? '117px 0 0 0' : '70px 0 0 0',
    padding: '15px 10px 15px 5px',
    height: showTopBanner ? '95vh' : 'calc(100vh - 70px)',
    position: window.innerWidth < 992 ? 'fixed' : 'fixed',
    background: window.innerWidth < 992 ? '#fff' : '#fff',
    [left]: 0,
    zIndex: 999,
    borderRight: '1px solid #eeeeee',
  };

  const renderView = ({ style, ...props }) => {
    return <div {...props} style={{ ...style }} />;
  };

  const renderThumbVertical = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      right: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };
  return (
    <Div
      showTopBanner={showTopBanner}
      showMobileBanner={showMobileBanner}
      sideBarClicked={sideBarClicked}
      className="sidetop-div"
    >
      <Layout hasSider className="sidebartop-section">
        <SidebarHeaderStyle sideBarClicked={sideBarClicked}>
          <Header
            style={{
              position: 'fixed',
              width: '100%',
              top: 0,
              left: 0,
              backgroundColor: window.scrollY > 1 ? '#fbfbfb' : 'transparent',
              padding: '0px',
              // height: showTopBanner ? '118px' : '70px',
              // lineHeight: showTopBanner ? '163px' : '70px',
            }}
            className="SidebarHeader"
          >
            {showTopBanner && (
              <Col xs={24} style={{ height: '0px', zIndex: 9999 }}>
                <TopBar
                  showWordsLimitGettingFinish={showWordsLimitGettingFinish}
                  noTeamActivePlan={noTeamActivePlan}
                  remainingHits={remainingTrialPlanHits}
                  trialModeLive={trialModeLive}
                  trialModeExpire={trialModeExpire}
                  noActivePlan={noActivePlan}
                  showNoInternetBanner={showNoInternetBanner}
                  roles={roles}
                  selectedWorkspace={selectedWorkspace}
                  userUID={uid}
                  topBannerNotifications={topBannerNotifications}
                  plan={plan}
                />
              </Col>
            )}
            {/* this is the main App Header of the app */}
            <AppHeader handleSidebar={handleSidebar} showTopBanner={showTopBanner} />

            {/* only visible for mobile */}
            {showMobileBanner && window.innerWidth <= 768 && <MobileProjectToolSelection />}
          </Header>
        </SidebarHeaderStyle>
        <Layout>
          <MenuStyleComPonent showMobileBanner={showMobileBanner}>
            <ThemeProvider theme={theme}>
              {/* side navigation bar */}
              <Sider
                breakpoint="lg"
                collapsedWidth={0}
                style={SideBarStyle}
                onCollapse={onSiderCollapse}
                collapsed={collapsed}
              >
                <Scrollbars
                  className="sidebar_inner_scroll"
                  renderThumbHorizontal={renderThumbHorizontal}
                  renderThumbVertical={renderThumbVertical}
                  renderView={renderView}
                  renderTrackVertical={renderTrackVertical}
                >
                  <Sidebar onMenuClicked={handleSidebar} />
                </Scrollbars>
              </Sider>
            </ThemeProvider>

            {/* the main content of the app */}
            <Layout className="atbd-main-layout">
              <Content
                className="antd-content-wrapper"
                onClick={() => closeSideBar({ mobMenuClicked: width < 991 && width > 10 ? true : false })}
              >
                <Outlet />
              </Content>
            </Layout>
          </MenuStyleComPonent>
        </Layout>
      </Layout>

      {/* mobile footer  */}
      {window.innerWidth < 768 && <MobileFooter collapsed={collapsed} sideBarClicked={sideBarClicked} />}

      {/* V2 pricing display pop to show the latest features video */}
      {showV2Popup && (
        <Modal centered width={900} maskClosable={false} open={showV2Popup} footer={null} onCancel={onV2PopUpClose}>
          <V2Popup plan={plan} onV2PopUpClose={onV2PopUpClose} />
        </Modal>
      )}
    </Div>
  );
};

export default withParams(ThemeLayout);

import styled from 'styled-components';
import { Button } from 'antd';

const PauseWrapper = styled.div`
  min-height: 100vh;

  .box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .verify-box {
      background-color: rgb(255, 255, 255);

      box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.07);
      padding: 2rem;
      font-family: 'Poppins-Regular';
      margin-left: auto;
      margin-right: auto;
      width: 43%;
      margin-bottom: 2rem;

      @media only screen and (max-width: 576px) {
        padding: 1rem;
      }

      @media only screen and (max-width: 768px) {
        width: 100%;
      }

      .early-text {
        font-size: 24px;
        color: rgb(36, 38, 38);
        padding: 0 3rem;
        @media only screen and (max-width: 1200px) {
          padding: 0;
        }
      }
      .free-text {
        font-size: 24px;
        font-family: 'Poppins-Regular';
        color: rgb(36, 38, 38);
      }

      .text-notice {
        font-size: 12px;
        color: ${({ theme }) => theme['gray-color']};
      }
      .text-contact {
        font-size: 16px;
        color: ${({ theme }) => theme['gray-color']};
      }

      .link {
        font-size: 12px;
      }

      .ant-divider-horizontal {
        margin: 40px 0;
      }

      .count-box {
        border-width: 1px;
        border-color: rgb(216, 216, 225);
        border-style: solid;
        background-color: rgb(255, 255, 255);
        height: 60px;
        border-radius: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        .count {
          font-size: 32px;
          font-family: 'Poppins-SemiBold';
        }
      }

      .social-buttons {
        margin-top: 1rem;
      }

      .note-text {
        background-color: rgb(255, 255, 255);
        opacity: 0.4;
        margin: 3rem -2rem -2rem;
        padding: 2rem;
        border-top: 2px solid rgb(255, 255, 255);
      }
    }
  }
`;

const SocialButtons = styled(Button)`
  background: ${({ background }) => background};
  .social-text {
    color: white;
    font-family: 'Poppins-Regular';
    font-size: 11px !important;
    margin-left: 8px;
  }
  .icon {
    width: 13px;
  }
  &:hover,
  &:focus {
    background: ${({ background }) => background};
  }
`;

export { PauseWrapper, SocialButtons };

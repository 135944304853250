import actions from './actions';

const {
  CHANGE_EMAIL_PROGRESS,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_EMAIL_FAILED,
  CHANGE_USERNAME_FAILED,
  CHANGE_USERNAME_PROGRESS,
  CHANGE_USERNAME_SUCCESS,
  CLOSE_ACCOUNT_FAILED,
  CLOSE_ACCOUNT_SUCCESS,
  CLOSE_ACCOUNT_PROGRESS,
  ONBOARDING_PROGRESS,
  ONBOARDING_SUCCESS,
  CHANGE_PROFILE_FAILED,
  CHANGE_PROFILE_SUCCESS,
  CHANGE_PROFILE_START,

  SET_ADD_TEAM_DISPLAY,
  ADD_TEAM_MEMBER_START,
  ADD_TEAM_MEMBER_SUCCESS,
  ADD_TEAM_MEMBER_FAILED,

  TEAM_MEMBER_EXIST,

  FETCH_TEAM_MEMBER_SUCCESS,
  FETCH_TEAM_MEMBER_FAILED,
  FETCH_TEAM_MEMBER_START,

  USERS_TEAMS,
  USERS_TEAMS_LOADING,

  SELECTED_WORKSPACE,

  MEMBERS_CRAFT_LIST,

  MEMBER_BOTS_START,
  MEMBER_BOTS_SUCCESS,
  MEMBER_BOTS_FAILED,

  WORKSPACE_BOTS_START,
  WORKSPACE_BOTS_SUCCESS,
  WORKSPACE_BOTS_FAILED,

  CURRENT_MONTH_CRAFT_TEAM,

  TEAM_PLAN_UPDATE_LOADING,

  TEAM_INVITE_ACTION_START,
  TEAM_INVITE_ACTION_SUCCESS,
  TEAM_INVITE_ACTION_FAILED,
  UPDATE_TEAM_NAME_START,
  UPDATE_TEAM_NAME_SUCCESS,
  MEMBERS_COPIED_CONTENT,
} = actions;

const initialState = {
  emailEditLoading: false,
  usernameEditLoading: false,
  error: null,
  closeAccountLoading: false,
  onBoardingLoading: false,
  todaysTotalHits: 0,
  changeProfileLoading: false,
  addTeamMemberLoading: false,
  addTeamMemberSuccess: false,
  addTeamMemberFailed: false,
  checkTeamMemberExists: undefined,
  checkTeamMemberDetails: null,
  teamMembersLoading: false,
  teamMembersList: null,
  usersTeams: null,
  usersTeamsLoading: false,
  selectedWorkspace: null,
  wordCraftMemberList: [],
  teamName: '',
  teamAdminName: '',
  teamAdminUID: null,
  displayAddTeam: false,
  memberBotsLoading: false,
  memberBots: [],
  memberBotsSuccess: false,
  teamPlanUpdateLoading: null,
  teamInviteActionLoading: false,
  updateTeamNameLoading: false,
  workspaceBots: [],
  workspaceBotsSuccess: false,
  workspaceBotsLoading: false,
  memberTeamsWithOutHold: [],
  memberCopiedContent: [],
  teamProjectsList: [],
  topToolsUsedByTeamMembers: [],
  teamStatus: null,
};

const accountSettings = (state = initialState, action) => {
  const {
    type,
    err,
    usersTeams,
    checkTeamMemberExists,
    checkTeamMemberDetails,
    teamMembersList,
    selectedWorkspace,
    teamName,
    wordCraftMemberList,
    teamAdminUID,
    memberBots,
    teamAdminName,
    teamPlanUpdateLoading,
    workspaceBots,
    memberTeamsWithOutHold,
    memberCopiedContent,
    teamProjectsList,
    topToolsUsedByTeamMembers,
    teamStatus,
  } = action;
  switch (type) {
    case CHANGE_EMAIL_PROGRESS:
      return {
        ...state,
        emailEditLoading: true,
      };

    case CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        error: false,
        emailEditLoading: false,
      };
    case CHANGE_EMAIL_FAILED:
      return {
        ...state,
        error: err,
        emailEditLoading: false,
      };
    case CHANGE_USERNAME_PROGRESS:
      return {
        ...state,
        usernameEditLoading: true,
      };

    case CHANGE_USERNAME_SUCCESS:
      return {
        ...state,
        error: false,
        usernameEditLoading: false,
      };
    case CHANGE_USERNAME_FAILED:
      return {
        ...state,
        error: err,
        usernameEditLoading: false,
      };
    case CLOSE_ACCOUNT_PROGRESS:
      return {
        ...state,
        closeAccountLoading: true,
      };

    case CLOSE_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: false,
        closeAccountLoading: false,
      };
    case CLOSE_ACCOUNT_FAILED:
      return {
        ...state,
        error: err,
        closeAccountLoading: false,
      };

    case ONBOARDING_PROGRESS:
      return {
        ...state,
        onBoardingLoading: true,
      };

    case ONBOARDING_SUCCESS:
      return {
        ...state,
        error: false,
        onBoardingLoading: false,
      };

    case CHANGE_PROFILE_START:
      return {
        ...state,
        changeProfileLoading: true,
      };

    case CHANGE_PROFILE_SUCCESS:
      return {
        ...state,
        changeProfileLoading: false,
      };
    case CHANGE_PROFILE_FAILED:
      return {
        ...state,
        changeProfileLoading: false,
      };

    case SET_ADD_TEAM_DISPLAY:
      return {
        ...state,
        displayAddTeam: true,
      };
    case ADD_TEAM_MEMBER_START:
      return {
        ...state,
        addTeamMemberLoading: true,
        addTeamMemberSuccess: false,
      };

    case ADD_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        addTeamMemberLoading: false,
        addTeamMemberSuccess: true,
        checkTeamMemberExists: undefined,
        checkTeamMemberDetails: null,
        displayAddTeam: false,
      };

    case ADD_TEAM_MEMBER_FAILED:
      return {
        ...state,
        addTeamMemberLoading: false,
        addTeamMemberFailed: true,
        displayAddTeam: false,
      };

    case TEAM_MEMBER_EXIST:
      return {
        ...state,
        checkTeamMemberExists,
        checkTeamMemberDetails,
      };

    case FETCH_TEAM_MEMBER_START:
      return {
        ...state,
        teamMembersLoading: true,
      };

    case FETCH_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        teamMembersLoading: false,
        teamMembersList,
        teamName,
        teamAdminUID,
        teamAdminName,
        teamProjectsList,
        teamStatus,
      };

    case FETCH_TEAM_MEMBER_FAILED:
      return {
        ...state,
        teamMembersLoading: false,
      };

    case USERS_TEAMS:
      return {
        ...state,
        usersTeams,
        usersTeamsLoading: false,
        memberTeamsWithOutHold,
      };
    case USERS_TEAMS_LOADING:
      return {
        ...state,
        usersTeamsLoading: true,
      };
    case SELECTED_WORKSPACE:
      return {
        ...state,
        selectedWorkspace,
        usersTeamsLoading: false,
      };
    case MEMBERS_CRAFT_LIST:
      return {
        ...state,
        wordCraftMemberList,
      };

    case MEMBER_BOTS_START:
      return {
        ...state,
        memberBotsLoading: true,
        memberBotsSuccess: false,
      };
    case MEMBER_BOTS_SUCCESS:
      return {
        ...state,
        memberBotsLoading: false,
        memberBotsSuccess: true,
        memberBots,
      };
    case MEMBER_BOTS_FAILED:
      return {
        ...state,
        memberBotsLoading: false,
        memberBotsSuccess: false,
        memberBots: [],
      };

    case WORKSPACE_BOTS_START:
      return {
        ...state,
        workspaceBotsLoading: true,
        workspaceBotsSuccess: false,
      };
    case WORKSPACE_BOTS_SUCCESS:
      return {
        ...state,
        workspaceBotsLoading: false,
        workspaceBotsSuccess: false,
        workspaceBots,
      };
    case WORKSPACE_BOTS_FAILED:
      return {
        ...state,
        workspaceBotsLoading: false,
        workspaceBotsSuccess: false,
        workspaceBots: [],
      };
    case CURRENT_MONTH_CRAFT_TEAM:
      return {
        ...state,
        topToolsUsedByTeamMembers,
      };

    case TEAM_PLAN_UPDATE_LOADING:
      return {
        ...state,
        teamPlanUpdateLoading,
      };

    case TEAM_INVITE_ACTION_START:
      return {
        ...state,
        teamInviteActionLoading: true,
      };

    case TEAM_INVITE_ACTION_SUCCESS:
      return {
        ...state,
        teamInviteActionLoading: false,
      };

    case TEAM_INVITE_ACTION_FAILED:
      return {
        ...state,
        teamInviteActionLoading: false,
      };
    case UPDATE_TEAM_NAME_START:
      return {
        ...state,
        updateTeamNameLoading: true,
      };

    case UPDATE_TEAM_NAME_SUCCESS:
      return {
        ...state,
        updateTeamNameLoading: false,
      };

    case MEMBERS_COPIED_CONTENT:
      return {
        ...state,
        memberCopiedContent,
      };

    default:
      return state;
  }
};

export { accountSettings };

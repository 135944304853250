/* ------------------------------------------------------ */
/*                          Theme                         */
/* ------------------------------------------------------ */

const primaryColor = '#6C5AFB';
const primaryHover = '#855bf7';
const secondaryColor = '#FF69A5';
const secondaryHover = '#E34A87';
const linkColor = '#1890ff';
const linkHover = '#0D79DF';
const headingColor = 'rgba(0, 0, 0, 0.85)';
const successColor = '#20C997';
const successHover = '#0CAB7C';
const warningColor = '#FA8B0C';
const warningHover = '#D47407';
const errorColor = '#f5222d';
const errorHover = '#E30D0F';
const infoColor = '#2C99FF';
const infoHover = '#0D79DF';
const darkColor = '#272B41';
const darkHover = '#131623';
const grayColor = '#5A5F7D';
const grayHover = '#363A51';
const lightColor = '#9299B8';
const lightPurple = '#B18EFE';
const lightPurpleHover = '#8253e8';
const lightHover = '#e2e6ea';
const whiteColor = '#ffffff';
const dashColor = '#E3E6EF';
const whiteHover = '#5A5F7D';
const extraLightColor = '#ADB4D2';
const dangerColor = '#FF4D4F';
const dangerHover = '#E30D0F';
const borderColorLight = '#F1F2F6';
const borderColorNormal = '#EEEEEE';
const borderColorDeep = '#C6D0DC';
const bgGrayColorDeep = '#EFF0F3';
const bgGrayColorLight = '#F8F9FB';
const bgGrayColorNormal = '#F4F5F7';
const lightGrayColor = '#868EAE';
const sliderRailColor = 'rgba(95,99,242,0.2)';
const graySolid = '#9299b8';
const pinkColor = '#F63178';
const btnlg = '48px';
const btnsm = '36px';
const btnxs = '29px';
const LightGreyColor = '#6a727f';
const blackPearl = '#242627';
const regentGrey = '#7c8387';
const swamp = '#242626';
const black = '#282B3F';
const white = '#fff';
const cello = '#455263';
const lightGray = '#d6d6d6';
const mediumtolight_slateBlue = 'linear-gradient(to right, #7e65f9, #856ef9, #8c76fa, #937ffa, #9a87fa)';
const royalParpleToPersianRose = '-webkit-linear-gradient(left, rgba(121,42,166,1) 0%, rgba(250,33,160,1) 100%)';
const lightWhiteColor = 'rgba(255, 255, 255, 0.6)';
const greyTextColor = '#959ca8';
const lightBlueRailColor = '#8f82fc';
const greenSliderColor = ' #58DFD7 ';
const graySliderColor = '#E0E0F0';
const darkGreyColor = '#848484';
const darkDesaturatedBlue = '#282b3f';
const BlueButton = 'linear-gradient(285.83deg, #8878f9 -34.95%, #6c5afb 92.08%)';

const theme = {
  'primary-color': primaryColor, // primary color for all components
  'primary-hover': primaryHover, // primary color for all components
  'secondary-color': secondaryColor, // secondary color for all components
  'secondary-hover': secondaryHover, // secondary color for all components
  'link-color': linkColor, // link color
  'link-hover': linkHover, // link color
  'success-color': successColor, // success state color
  'success-hover': successHover, // success state color
  'warning-color': warningColor, // warning state color
  'warning-hover': warningHover, // warning state color
  'error-color': errorColor, // error state color
  'error-hover': errorHover, // error state color
  'info-color': infoColor, // info state color
  'info-hover': infoHover, // info state color
  'dark-color': darkColor, // info state color
  'dark-hover': darkHover, // info state color
  'gray-color': grayColor, // info state color
  'gray-hover': grayHover, // info state color
  'light-color': lightColor, // info state color
  'light-hover': lightHover, // info state color
  'white-color': whiteColor, // info state color
  'white-hover': whiteHover, // info state color
  'light-purple-color': lightPurple,
  'light-purple-hover': lightPurpleHover,
  white: whiteColor,
  pink: pinkColor,
  'dash-color': dashColor, // info state color
  'extra-light-color': extraLightColor, // info state color
  'danger-color': dangerColor,
  'danger-hover': dangerHover,
  'font-family': "'Inter', sans-serif",
  'font-size-base': '14px', // major text font size
  'heading-color': headingColor, // heading text color
  'text-color': darkColor, // major text color
  'text-color-secondary': grayColor, // secondary text color
  'disabled-color': 'rgba(0, 0, 0, 0.25)', // disable state color
  'disabled-btn-color': 'rgba(95, 99, 242, 0.376)', // disable button color
  'border-radius-base': '8px', // major border radius
  'border-color-base': '#EEEEEE', // major border color
  'box-shadow-base': '0 2px 8px rgba(0, 0, 0, 0.15)', // major shadow for layers
  'border-color-light': borderColorLight,
  'border-color-normal': borderColorNormal,
  'border-color-deep': borderColorDeep,
  'bg-color-light': bgGrayColorLight,
  'bg-color-normal': bgGrayColorNormal,
  'bg-color-deep': bgGrayColorDeep,
  'light-gray-color': lightGrayColor,
  'gray-solid': graySolid,
  'btn-height-large': btnlg,
  'btn-height-small': btnsm,
  'btn-height-extra-small': btnxs,
  'btn-default-color': darkColor,
  'light-grey-color': LightGreyColor,

  'aliceBlue-color': '#eff4f7',
  'amber-color': '#ffbf00',
  'frenchLilac-color': '#e9bee4',
  'gradient-color': '-webkit-linear-gradient( 54deg, rgb(248,33,159) 0%, rgb(121,42,166) 100%)',
  'gradient-hover': '-webkit-linear-gradient( 54deg,rgb(248 33 159 / 52%) 0%,rgb(121 42 166 / 77%) 100%)',
  'gradient-color-ms': '-ms-linear-gradient( 54deg, rgb(248,33,159) 0%, rgb(121,42,166) 100%)',
  'gradient-color-moz': '-moz-linear-gradient( 54deg, rgb(248,33,159) 0%, rgb(121,42,166) 100%)',

  'purple-gradient-color': '-webkit-linear-gradient(  0deg, rgb(140,73,247) 0%, rgb(108,83,255) 100%)',
  'purple-gradient-hover': '-webkit-linear-gradient(  0deg, rgb(140,73,247) 0%, rgb(108,83,255) 100%)',
  'purple-gradient-color-ms': '-ms-linear-gradient(  0deg, rgb(140,73,247) 0%, rgb(108,83,255) 100%)',
  'purple-gradient-color-moz': '-moz-linear-gradient( 0deg, rgb(140,73,247) 0%, rgb(108,83,255) 100%)',

  'blue-gradient-color-moz': '-moz-linear-gradient( 0.862deg, rgb(108,83,255) 0%, rgb(140,73,247) 100%)',
  'blue-gradient-color': '-webkit-linear-gradient( 0.862deg, rgb(108,83,255) 0%, rgb(140,73,247) 100%)',
  'blue-gradient-color-ms': '-ms-linear-gradient( 0.862deg, rgb(108,83,255) 0%, rgb(140,73,247) 100%)',
  'blue-gradient-hover': '-webkit-linear-gradient( 0.862deg, rgb(108 83 255 / 52%) 0%,rgb(140 73 247 / 77%) 100%)',

  'yellow-gradient-color-moz': '-moz-linear-gradient( 289.65deg, rgb(254, 199, 36) -15.55%, rgb(255, 241, 72) 116.77%)',
  'yellow-gradient-color': '-webkit-linear-gradient( 289.65deg, rgb(254, 199, 36) -15.55%, rgb(255, 241, 72) 116.77%)',
  'yellow-gradient-color-ms':
    '-ms-linear-gradient( 289.65deg, rgb(254, 199, 36) -15.55%, rgb(255, 241, 72) 116.77% 100%)',
  'yellow-gradient-hover':
    '-webkit-linear-gradient( 289.65deg, rgb(254, 199, 36) -15.55%, rgb(255, 241, 72) 36.77% 50%)',

  'auth-form-lable': blackPearl,
  'auth-form-input': regentGrey,
  'auth-text': swamp,
  'color-black': black,
  'color-white': white,
  'auth-subheading': cello,
  'auth-input-border': lightGray,
  'auth-side-button': mediumtolight_slateBlue,
  'auth-button': royalParpleToPersianRose,
  'royal-Purple-To-Persian-Rose': royalParpleToPersianRose,
  swamp: swamp,
  cello: cello,
  LightSlateBlue: '#9278fd',
  'light-text-color': lightWhiteColor,
  'dark-grey-color': darkGreyColor,
  'dark-desaturated-blue': darkDesaturatedBlue,

  // Standard Tool
  'auth-craft-button': BlueButton,

  // cards
  'card-head-background': '#ffffff',
  'card-head-color': darkColor,
  'card-background': '#ffffff',
  'card-head-padding': '16px',
  'card-padding-base': '12px',
  'card-radius': '10px',
  'card-shadow': '0 5px 20px rgba(146,153,184,0.03)',

  // Layout
  'layout-body-background': 'transparent',
  'layout-header-background': 'transparent',
  'layout-footer-background': '#fafafa',
  'layout-header-height': '70px',
  'layout-header-padding': '0 15px',
  'layout-footer-padding': '24px 15px',
  'layout-sider-background': 'transparent',
  'layout-trigger-height': '48px',
  'layout-trigger-background': '#002140',
  'layout-trigger-color': '#fff',
  'layout-zero-trigger-width': '36px',
  'layout-zero-trigger-height': '42px',
  // Layout light theme
  // 'layout-sider-background-light': '#fff',
  'layout-sider-background-light': 'transparent',
  'layout-trigger-background-light': 'transparent',
  'layout-trigger-color-light': 'rgba(0, 0, 0, 0.65)',

  // Popover body background color
  'popover-color': darkColor,

  // alert
  'alert-success-border-color': successColor,
  'alert-success-bg-color': successColor + 15,
  'alert-error-bg-color': errorColor + 15,
  'alert-warning-bg-color': warningColor + 15,
  'alert-info-bg-color': infoColor + 15,

  // radio btn
  'radio-button-checked-bg': primaryColor,
  'radio-border-color': primaryColor,

  // gutter width
  'grid-gutter-width': 25,

  // skeleton
  'skeleton-color': borderColorLight,

  // slider
  'slider-rail-background-color': sliderRailColor,
  'slider-rail-background-color-hover': sliderRailColor,
  'slider-track-background-color': primaryColor,
  'slider-track-background-color-hover': primaryColor,
  'slider-handle-color': primaryColor,
  'slider-handle-size': '16px',

  // input
  'input-height-base': '48px',
  'input-border-color': borderColorNormal,
  'input-height-sm': '30px',
  'input-height-lg': '50px',

  // rate
  'rate-star-color': warningColor,
  'rate-star-size': '13px',

  // Switch
  'switch-min-width': '35px',
  'switch-sm-min-width': '30px',
  'switch-height': '18px',
  'switch-sm-height': '15px',

  // result
  'result-title-font-size': '20px',
  'result-subtitle-font-size': '12px',
  'result-icon-font-size': '50px',

  // tabs
  'tabs-horizontal-padding': '12px 15px',
  'tabs-horizontal-margin': '0',

  // list
  'list-item-padding': '10px 24px',

  // Tags
  'tag-default-bg': '#EFF0F3',
  'tag-default-color': darkColor,
  'tag-font-size': '11px',

  // Slider Cards
  'grey-text-color': greyTextColor,
  'green-slider-color': greenSliderColor,
  'gray-slider-rail-color': graySliderColor,
  'lightblue-slider-rail-color': lightBlueRailColor,
};

export { theme };

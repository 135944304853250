/* ------------------------------------------------------ */
/*                   SEATS UI COMPONENT                   */
/* ------------------------------------------------------ */
/**
 *
 * Sests with + - signs to add and substract the seats.
 *
 * Seats add the subscription price. In stripe Team plan is according to the number of seats
 * First seat has a fix price which is a admin seats.
 * the rest of the seats have 30% discount and this is added in the stripe pricing model
 */

import React, { useState, useCallback, useEffect } from 'react';
import { InputNumber, Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';

const SeatsQuantity = ({ width, onSeatChange, existingSeats }) => {
  const [seatsAmount, setSeatsAmount] = useState();

  useEffect(() => {
    !existingSeats && onSeatChange(2);
    existingSeats && setSeatsAmount(existingSeats ? existingSeats : 1);
    !existingSeats && setSeatsAmount(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (amount) => {
    // // it references the same debouncedSave that was created initially
    debouncedSave(amount + 1);
    setSeatsAmount(amount);
  };

  const userDisplaySeats = (amount) => {
    setSeatsAmount(amount);
    handleChange(amount);
  };

  const debouncedSave = useCallback(
    debounce((nextValue) => onSeatChange(nextValue), 600),
    [], // will be created only once initially
  );
  const selectBefore = (
    <Button
      disabled={seatsAmount < 2 ? true : false}
      size="small"
      type="text"
      onClick={() => userDisplaySeats(seatsAmount - 1)}
      icon={<MinusOutlined />}
    />
  );
  const addonAfter = (
    <Button size="small" type="text" onClick={() => userDisplaySeats(seatsAmount + 1)}>
      <PlusOutlined />
    </Button>
  );

  return (
    <InputNumber
      min={1}
      // style={{ width: `36%` }}
      onChange={handleChange}
      addonBefore={selectBefore}
      addonAfter={addonAfter}
      defaultValue={existingSeats ? existingSeats : 1}
      value={seatsAmount}
      controls={false}
    />
  );
};

export default SeatsQuantity;

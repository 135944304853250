/* ------------------------------------------------------ */
/*               Long Form / Blog Builder ˛               */
/* ------------------------------------------------------ */

/**
 * Methodology
 * Long-Form: it is baciscally called Blog Builder. In Code level it is called LONG FORM
 * There are 3 main sub-files.
 * Long-Form Data is the sequence to populate the UI step by step. Every step has its own toolId and step Id.
 * SummaryPage is the last page of long-form
 * WarningPopup displays when user clicks on Edit button
 *
 * There are total 7 steps and each step is dependent to the previous step.
 * Inorder to edit the previous step, there are 2 options. these options appear in the modal
 * 1. Go Back to this step and lose al lthe progress afer
 * 2. Edit only this section without starting over
 *
 * 1. If the use selects the first option then all the data is lost and user starts from the edit selected step.
 * 2. It wont lose any data but only edit the edit step data.
 *
 *
 * There are 2 main mechanism are running here.
 * 1. when the step data is requested with tool-id the normal request is being sent just like the rest of the tools.
 * 2. the data also save in the long-form-document collection. the reason is to make all the long-form document data in one place, under one collection and document.
 *
 * So the main data of the long-form is saved in the collections.
 *
 * In every update the data is saved in Firestore
 * Collection: long-form-documents
 * Document: <UID>
 * Collection: documents
 * document : <LONGFORM-ID>
 *
 *
 * From Document Page we can restore the Long-Form data using documentId in location.search
 * from the URL we can get documentId inorder to fetch the detial of long-form
 *
 * if the user refresh the form the draft will be created. it gives the option to the user to start a new one or to continue with the previous one.
 *
 * By default there are 4 to 5 response in each step, if the user doesnt like any options, on clicking GENERATE MORE
 *
 */
import React, { useState, useEffect } from 'react';
import { Col, Row, Typography, Input, Space, Avatar, Divider, Spin, message, Result } from 'antd';
import queryString from 'query-string';
import { ReactSVG } from 'react-svg';
import PageHeader from '../../components/PageHeader';
import { Button } from '../../components/Button';

import { blogMockData, LongFormSteps, saveCompletedStepIdsMockData } from './overview/LongFormData';
import { useDispatch, useSelector } from 'react-redux';
import { userSaveBot } from '../../redux/UserProject/actionCreator';
import { getDocumentList, getDocumentById, saveDocument, deleteDocument } from '../../redux/features/actionCreator';

import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { breakLineIfRequired, uniqueId } from '../../utility/utility';
import { maskBadWords } from '../../utility/filterWords';
import { useViewport } from '../../hooks/viewPort.js';
import SummaryPage from './overview/SummaryPage';
import WarningPopUp from './overview/WarningPopUp';
import { useLocation, useNavigate } from 'react-router-dom';
import { verifyBilling } from '../../utility/calculateBilling';
import BillingModal from '../../components/BillingModal';
import edit from '../../static/longForm/edit.svg';
import cross from '../../static/longForm/Cross.svg';
import plus from '../../static/longForm/Plus.svg';
import deleteIcon from '../../static/longForm/Delete.svg';
import { MainFormStyle, StepFieldWrapper, LongFormWrapper, OpenEditorStyled, UnSavedChangesStyled } from './styled';

import {
  BUTTON_PRIMARY,
  FIELD_TYPE_MULTIPLE_OPTIONS,
  FIELD_TYPE_TEXTAREA,
  STEP_CHOOSE_TITLE,
  STEP_KEY_TOPICS,
  STEP_CHOOSE_INTRO,
  STEP_PARAGRAPH_BUILDER,
  STEP_TITLE_DESCRIPTION,
  TOOL_ID_BLOG_INTRODUCTION,
  TOOL_ID_BLOG_OUTLINE,
  TOOL_ID_OUTLINE_BULLET_POINT,
  TOOL_ID_CONCLUSION,
  TOOL_ID_PRODUCT_BULLET,
  FIELD_TYPE_SECTION_POINTS,
  FIELD_TYPE_OUTLINE_POINTS,
  STEP_PARAGRAGH_POINT,
  FIELD_TYPE_SECTION_BUILDER,
  FIELD_TYPE_CONCLUSION_POINTS,
  STEP_CONCLUSION,
  TOOL_ID_BLOG_TITLES,
  NEXT_BUTTON_CLICK,
  GENERATE_BUTTON_CLICK,
  SUMMARY_UI,
  CRAFTER_ACCESS,
  ENTERPRISE_ACCESS,
  STARTER_ACCESS,
  isAdminRole,
  isFreeUserRole,
} from '../../constants';

const { Text } = Typography;
const { TextArea } = Input;

const LongForm = () => {
  const dispatch = useDispatch();
  const { width } = useViewport();
  const navigate = useNavigate();
  const location = useLocation();

  const { todaysTotalHits, lastCraftDate, totalWordsCraft, userAllowToCraft, adminBillingSettings, userBots } =
    useSelector((state) => state.billing);
  const { currentBilling } = useSelector((state) => state.stripe);
  const { selectedWorkspace, memberTeamsWithOutHold } = useSelector((state) => state.accountSettings);

  const [showBillingModal, setShowBillingModal] = useState({
    modalVisible: false,
    messageType: null,
  });

  // const [blogPostStep, setBlogPostStep] = useState(blogMockData);
  // const [saveCompletedStepIds, setSaveCompletedStepIds] = useState(saveCompletedStepIdsMockData);
  // const [showStep, setShowStep] = useState(STEP_PARAGRAPH_BUILDER);
  const { documentId } = queryString.parse(location.search);

  const [blogPostId, setBlogPostId] = useState(null);

  /**
   * These 3 states are very important, all the data of the long form are saved here.
   * blogPostStep - saveCompletedStepIds - showStep
   */
  const [blogPostStep, setBlogPostStep] = useState({}); //
  const [saveCompletedStepIds, setSaveCompletedStepIds] = useState([]);
  const [showStep, setShowStep] = useState(STEP_TITLE_DESCRIPTION);

  const [translationCode, setTranslationCode] = useState({});
  const [saveOnEditUserOption, setOnEditUserOption] = useState();
  const [summaryData, setSummaryData] = useState(false);
  const [warningVisible, setWarningVisible] = useState({
    type: 'steps',
    open: false,
  });
  const [saveEditData, setSaveEditData] = useState({ craftOutput: '', editStepId: '' });
  const [selectedStepEdit, setSelectedStepEdit] = useState(false);
  const [saveDataForGenerate, setSaveDataForGenerate] = useState(false);
  const [craftButtonClicked, setCraftButtonClicked] = useState('');
  const [selectedSectionId, setSelectedSection] = useState('');
  const [customAddMore, setCustomAddMore] = useState({
    stepId: null,
    data: null,
    sectionId: null,
  });
  const [customInputWordCount] = useState(200); // custom word count
  const [checkUnsavedChanged, setCheckUnsavedChanges] = useState(true);
  const { allTools } = useSelector((store) => store.adminFeatures);
  const { uid } = useSelector((state) => state.fb.auth);
  const { plan, planStatus, roles, activatedPlanDetails, noTrialccPlanAcitivated } = useSelector(
    (state) => state.fb.profile,
  );
  const { lastDocumentData, lastDocumentLoading, readDocumentById, readDocumentByIdLoading } = useSelector(
    (state) => state.features,
  );
  const { longFormOutput, toolOutputLoading, toolHitFailed, currentStepOutputId, selectedCurrentProject } = useSelector(
    (state) => state.userProject,
  );

  useEffect(() => {
    documentId ? dispatch(getDocumentById({ uid, id: documentId })) : dispatch(getDocumentList(uid));
    documentId ? setBlogPostId(documentId) : setBlogPostId(uniqueId());
  }, [uid, documentId]);

  const onRedirectClick = (onRedirectLink) => {
    setShowBillingModal({ modalVisible: false });
    navigate(onRedirectLink);
  };

  const onBillingModalClose = () => {
    setShowBillingModal({ modalVisible: false });
  };

  // if the user refresh the page the existing data will be removed.
  const startOver = () => {
    setSaveCompletedStepIds([]);
    setBlogPostStep({});
    setShowStep(STEP_TITLE_DESCRIPTION);
    setBlogPostId(uniqueId());
    setCheckUnsavedChanges(true);
    setSummaryData(false);
  };

  useEffect(() => {
    const { pathname } = location;
    if (pathname === '/long-form') {
      startOver();
    }
  }, [location]);

  // this happen when a user is being redirected from the document page.
  useEffect(() => {
    if (documentId && readDocumentById) {
      const { blogPostStep, completedSteps, showStep, summary, id, dataFound } = readDocumentById;

      if (dataFound === false) {
        navigate(`/documents`);
      }

      // it helps to populate the fetch longform data and give to option to the user to continue it
      const getSaveCompletedStepIds = completedSteps;
      const getBlogPostStep = blogPostStep;
      const getshowStep = showStep;
      const getSummary = summary;

      setSaveCompletedStepIds(getSaveCompletedStepIds);
      setBlogPostStep(getBlogPostStep);
      setShowStep(getshowStep);
      // setSummaryData(getSummary);
      setBlogPostId(id);
    }
  }, [documentId, readDocumentById]);

  // This called when user clicks on MULTIPLE OPTION which results from a tool craft. User can select single or multiple options.
  const onOptionClick = ({ max, stepId, contentData, contentId, multipleSelect, sectionId, sectionTitle }) => {
    //? This step has mentioned multiple select then this will be true
    if (multipleSelect) {
      const alreadySelected = blogPostStep[stepId];
      if (stepId === STEP_KEY_TOPICS) {
        if (alreadySelected) {
          // check if alreadyContentId is present.
          const contentIdAlreadyExist = alreadySelected.find(({ id }) => contentId === id);
          if (contentIdAlreadyExist && contentIdAlreadyExist.id) {
            // if user wants to unchecked the selected option
            const remainingContent = alreadySelected.filter(({ id }) => contentId !== id);
            setBlogPostStep({
              ...blogPostStep,
              [stepId]: remainingContent,
            });
          } else {
            // limit of selected options
            if (alreadySelected.length + 1 <= max) {
              // User has selected a new option, it will be added to a previous one
              alreadySelected.push({
                value: contentData,
                id: contentId,
              });
              setBlogPostStep({
                ...blogPostStep,
                [stepId]: alreadySelected,
              });
            } else {
              message.error(`Choose ${max} topics for your paragraph.`);
            }
          }
        } else {
          setBlogPostStep({
            ...blogPostStep,
            [stepId]: [
              {
                value: contentData,
                id: contentId,
              },
            ],
          });
        }
      }
      if (stepId === STEP_PARAGRAGH_POINT) {
        if (alreadySelected) {
          const contentIdAlreadyExist = alreadySelected.find(({ id }) => contentId === id);
          if (contentIdAlreadyExist && contentIdAlreadyExist.id) {
            //remove contentId
            const remainingContent = alreadySelected.filter(({ id }) => contentId !== id);
            setBlogPostStep({
              ...blogPostStep,
              [stepId]: remainingContent,
            });
          } else {
            // limit of selected options
            const sectionCount =
              alreadySelected &&
              Array.isArray(alreadySelected) &&
              alreadySelected.filter((v) => v.sectionId === sectionId).length;

            if (sectionCount + 1 <= max) {
              alreadySelected.push({
                value: contentData,
                id: contentId,
                sectionId,
                sectionTitle,
              });
              setBlogPostStep({
                ...blogPostStep,
                [stepId]: alreadySelected,
              });
            } else {
              message.error(`Choose ${max} per section.`);
            }
          }
        } else {
          setBlogPostStep({
            ...blogPostStep,
            [stepId]: [
              {
                value: contentData,
                id: contentId,
                sectionId,
                sectionTitle,
              },
            ],
          });
        }
      }
      // Each step have its own dynamics
      if (stepId === STEP_PARAGRAPH_BUILDER) {
        const alreadySelected = blogPostStep[stepId];

        if (alreadySelected) {
          const sameSectionIDExist = alreadySelected.find((v) => contentId === v.id);
          if (sameSectionIDExist && sameSectionIDExist.sectionId) {
            // this is multiselect option, user can also deselect the option
            const remainingContent = alreadySelected.filter((v) => contentId !== v.id);

            setBlogPostStep({
              ...blogPostStep,
              [stepId]: remainingContent,
            });
          } else {
            alreadySelected.push({
              value: contentData,
              id: contentId,
              sectionId,
              sectionTitle,
            });
            setBlogPostStep({
              ...blogPostStep,
              [stepId]: alreadySelected,
            });
          }
        } else {
          setBlogPostStep({
            ...blogPostStep,
            [stepId]: [
              {
                value: contentData,
                id: contentId,
                sectionId,
                sectionTitle,
              },
            ],
          });
        }
      }
    } else {
      setBlogPostStep({
        ...blogPostStep,
        [stepId]: {
          value: contentData,
          id: contentId,
        },
      });
    }
  };

  // When user writes on custom textfield
  const onTextFieldChange = (e, stepId) => {
    setOnEditUserOption();
    const value = e.target.value;
    setBlogPostStep({
      ...blogPostStep,
      [stepId]: {
        value: value,
        id: 'text-field',
      },
    });
  };

  // When user writes on custom textfield
  const onChangeCustomAddMore = (e, stepId, contentId) => {
    const value = e.target.value;
    setCustomAddMore({
      stepId,
      data: value,
      contentId: contentId,
    });
  };

  // When user writes on custom textfield
  const onClickCustomAddMore = ({ AddMoreStepId, contentId, sectionTitle }) => {
    const checkSavedStepValue = saveCompletedStepIds.find((savedStepIds) => savedStepIds.stepId === AddMoreStepId);
    const { stepId, data } = customAddMore;

    if (AddMoreStepId === STEP_CHOOSE_INTRO && stepId === STEP_CHOOSE_INTRO && data) {
      const id = uniqueId();

      if (checkSavedStepValue) {
        const update = saveCompletedStepIds.map((obj) => {
          if (AddMoreStepId === obj.stepId) {
            obj.content.push({
              contentData: data,
              contentId: id,
            });
          }
          return obj;
        });
        setSaveCompletedStepIds(update);
      }
      const selectedkeyPoints = blogPostStep[AddMoreStepId];

      if (selectedkeyPoints) {
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: {
            value: data,
            id,
          },
        });
      } else {
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: {
            value: data,
            id,
          },
        });
      }
    }
    if (AddMoreStepId === STEP_KEY_TOPICS && stepId === STEP_KEY_TOPICS && data) {
      const id = uniqueId();

      if (checkSavedStepValue) {
        const update = saveCompletedStepIds.map((obj) => {
          if (AddMoreStepId === obj.stepId) {
            obj.content.push({
              contentData: data,
              contentId: id,
            });
          }
          return obj;
        });
        setSaveCompletedStepIds(update);
      }
      const selectedkeyPoints = blogPostStep[AddMoreStepId];

      if (selectedkeyPoints) {
        selectedkeyPoints.push({
          value: data,
          id,
        });
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: selectedkeyPoints,
        });
      } else {
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: [
            {
              value: data,
              id,
            },
          ],
        });
      }
    }
    if (AddMoreStepId === STEP_PARAGRAGH_POINT && stepId === STEP_PARAGRAGH_POINT && data) {
      const uniqId = uniqueId();

      if (checkSavedStepValue) {
        const update = saveCompletedStepIds.map((obj) => {
          if (AddMoreStepId === obj.stepId) {
            const updateSectionData = obj.content.map((v) => {
              if (v.contentId === contentId) {
                v.contentData.push({
                  pointId: uniqId,
                  pointValue: data,
                  sectionId: contentId,
                });
                return v;
              } else {
                return v;
              }
            });

            return {
              content: updateSectionData,
              stepId: AddMoreStepId,
            };
          }
          return obj;
        });
        setSaveCompletedStepIds(update);
      }
      const selectedkeyPoints = blogPostStep[AddMoreStepId];
      if (selectedkeyPoints) {
        selectedkeyPoints.push({
          value: data,
          sectionId: contentId,
          sectionTitle,
          id: uniqId,
        });
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: selectedkeyPoints,
        });
      } else {
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: [
            {
              value: data,
              sectionId: contentId,
              sectionTitle,
              id: uniqId,
            },
          ],
        });
      }
    }
    if (AddMoreStepId === STEP_CONCLUSION && stepId === STEP_CONCLUSION && data) {
      const id = uniqueId();

      if (checkSavedStepValue) {
        const update = saveCompletedStepIds.map((obj) => {
          if (AddMoreStepId === obj.stepId) {
            obj.content.push({
              contentData: data,
              contentId: id,
            });
          }
          return obj;
        });
        setSaveCompletedStepIds(update);
      }
      const selectedkeyPoints = blogPostStep[AddMoreStepId];

      if (selectedkeyPoints) {
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: {
            value: data,
            id,
          },
        });
      } else {
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: {
            value: data,
            id,
          },
        });
      }
    }
    if (AddMoreStepId === STEP_CHOOSE_TITLE && stepId === STEP_CHOOSE_TITLE && data) {
      const id = uniqueId();

      if (checkSavedStepValue) {
        const update = saveCompletedStepIds.map((obj) => {
          if (AddMoreStepId === obj.stepId) {
            obj.content.push({
              contentData: data,
              contentId: id,
            });
          }
          return obj;
        });
        setSaveCompletedStepIds(update);
      }
      const selectedkeyPoints = blogPostStep[AddMoreStepId];

      if (selectedkeyPoints) {
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: {
            value: data,
            id,
          },
        });
      } else {
        setBlogPostStep({
          ...blogPostStep,
          [stepId]: {
            value: data,
            id,
          },
        });
      }
    }

    setCustomAddMore({
      stepId: null,
      data: null,
    });
  };

  // if the user doesnt like the  output results, user have the option to delete it
  const onDelete = ({ stepId, contentId, pointId }) => {
    if (stepId === STEP_KEY_TOPICS) {
      const updateDelete = saveCompletedStepIds.map((obj) => {
        if (stepId === obj.stepId) {
          const remainingContent = obj.content.filter((v) => {
            return v.contentId !== contentId;
          });
          return {
            ...obj,
            content: remainingContent,
          };
        } else {
          return obj;
        }
      });
      setSaveCompletedStepIds(updateDelete);
    }
    if (stepId === STEP_CHOOSE_TITLE) {
      const updateDelete = saveCompletedStepIds.map((obj) => {
        if (stepId === obj.stepId) {
          const remainingContent = obj.content.filter((v) => {
            return v.contentId !== contentId;
          });
          return {
            ...obj,
            content: remainingContent,
          };
        } else {
          return obj;
        }
      });
      setSaveCompletedStepIds(updateDelete);
    }
    if (stepId === STEP_CHOOSE_INTRO) {
      const updateDelete = saveCompletedStepIds.map((obj) => {
        if (stepId === obj.stepId) {
          const remainingContent = obj.content.filter((v) => {
            return v.contentId !== contentId;
          });
          return {
            ...obj,
            content: remainingContent,
          };
        } else {
          return obj;
        }
      });
      setSaveCompletedStepIds(updateDelete);
    }
    if (stepId === STEP_PARAGRAGH_POINT) {
      const updateDelete = saveCompletedStepIds.map((obj) => {
        if (stepId === obj.stepId) {
          const remainingContent = obj.content.map((v) => {
            if (v.contentId === contentId) {
              const filt = v.contentData.filter((b) => b.pointId !== pointId);
              return {
                ...v,
                contentData: filt,
              };
            } else {
              return { ...v };
            }
          });
          return {
            ...obj,
            content: remainingContent,
          };
        } else {
          return obj;
        }
      });
      setSaveCompletedStepIds(updateDelete);
    }
    if (stepId === STEP_PARAGRAPH_BUILDER) {
      const updateDelete = saveCompletedStepIds.map((obj) => {
        if (stepId === obj.stepId) {
          const remainingContent = obj.content.map((v) => {
            if (v.contentId === contentId) {
              const filt = v.contentData.filter((b) => b.pointId !== pointId);
              return {
                ...v,
                contentData: filt,
              };
            } else {
              return { ...v };
            }
          });
          return {
            ...obj,
            content: remainingContent,
          };
        } else {
          return obj;
        }
      });
      setSaveCompletedStepIds(updateDelete);
    }
    if (stepId === STEP_CONCLUSION) {
      const updateDelete = saveCompletedStepIds.map((obj) => {
        if (stepId === obj.stepId) {
          const remainingContent = obj.content.filter((v) => {
            return v.contentId !== contentId;
          });
          return {
            ...obj,
            content: remainingContent,
          };
        } else {
          return obj;
        }
      });
      setSaveCompletedStepIds(updateDelete);
    }
  };

  const generateMore = ({
    sectionOrder,
    selectedContentId,
    currentToolId,
    stepId,
    previousStepId,
    type,
    toolId,
    sectionTitle,
    clicked,
  }) => {
    setSelectedSection(selectedContentId);
    const modelSelect = LongFormSteps.find((item) => item.stepId === previousStepId)?.modelSelect;
    if (stepId === STEP_PARAGRAGH_POINT) {
      const selectedStepId = blogPostStep.hasOwnProperty(stepId) && blogPostStep[stepId];

      if (selectedStepId && Array.isArray(selectedStepId) && selectedStepId.length > 0) {
        // handling section by section
        const checkUserSelectedOption = selectedStepId.filter(({ sectionId }) => selectedContentId === sectionId);

        const contentOption =
          checkUserSelectedOption &&
          Array.isArray(checkUserSelectedOption) &&
          checkUserSelectedOption.map(({ sectionId, id }) =>
            saveCompletedStepIds
              .find((v) => v.stepId === stepId)
              .content.find(({ contentId }) => contentId === sectionId)
              .contentData.find(({ pointId }) => pointId === id),
          );
        setSaveDataForGenerate({
          sectionOrder,
          blogPostSelected: checkUserSelectedOption,
          completedStepIds: contentOption,
          stepId,
          selectedContentId,
        });

        onNextStepClick({
          type,
          toolId: currentToolId,
          modelSelect,
          stepId: previousStepId,
          generateMoreData: sectionTitle,
          clicked,
        });
      } else {
        setSaveDataForGenerate({
          sectionOrder,
          stepId,
          selectedContentId,
        });
        onNextStepClick({
          type,
          toolId: currentToolId,
          modelSelect,
          stepId: previousStepId,
          generateMoreData: sectionTitle,
          clicked,
        });
      }
    } else if (stepId === STEP_PARAGRAPH_BUILDER) {
      const selectedStepId = blogPostStep.hasOwnProperty(stepId) && blogPostStep[stepId];
      const stepValues = blogPostStep.hasOwnProperty(STEP_PARAGRAGH_POINT) && blogPostStep[STEP_PARAGRAGH_POINT];

      const sectionData =
        stepValues && stepValues.filter(({ sectionId }) => selectedContentId === sectionId).map(({ value }) => value);
      const sectionPoints = sectionData && sectionData.join('\n');
      const generateMoreData = `${selectedContentId}#####${sectionTitle}\n${sectionPoints}`;

      if (selectedStepId && Array.isArray(selectedStepId) && selectedStepId.length > 0) {
        // handling section by section
        const checkUserSelectedOption = selectedStepId.filter(({ sectionId }) => selectedContentId === sectionId);

        const contentOption = checkUserSelectedOption.map(({ sectionId, id }) =>
          saveCompletedStepIds
            .find((v) => v.stepId === stepId)
            .content.find(({ contentId }) => contentId === sectionId)
            .contentData.find(({ pointId }) => pointId === id),
        );
        setSaveDataForGenerate({
          sectionOrder,
          blogPostSelected: checkUserSelectedOption,
          completedStepIds: contentOption,
          stepId,
          selectedContentId,
        });

        onNextStepClick({
          type,
          toolId: currentToolId,
          modelSelect,
          stepId: previousStepId,
          generateMoreData,
          clicked,
        });
      } else {
        setSaveDataForGenerate({
          sectionOrder,
          stepId,
          selectedContentId,
        });
        onNextStepClick({
          type,
          toolId: currentToolId,
          modelSelect,
          stepId: previousStepId,
          generateMoreData,
          clicked,
        });
      }
    } else {
      if (blogPostStep.hasOwnProperty(stepId)) {
        const checkUserSelectedOption = blogPostStep.hasOwnProperty(stepId) && blogPostStep[stepId];

        const contentOption = saveCompletedStepIds
          .find((v) => v.stepId === stepId)
          .content.filter(({ contentId }) => {
            if (Array.isArray(checkUserSelectedOption)) {
              const check = checkUserSelectedOption.find(({ id }) => contentId === id);
              return check;
            } else {
              return contentId === checkUserSelectedOption.id;
            }
          });
        setSaveDataForGenerate({ blogPostSelected: checkUserSelectedOption, completedStepIds: contentOption, stepId });
      }
      const markOldCraft =
        saveCompletedStepIds &&
        Array.isArray(saveCompletedStepIds) &&
        saveCompletedStepIds.map((obj) => {
          if (stepId === obj.stepId) {
            return { ...obj, oldCraft: true };
          }
          return obj;
        });

      setSaveCompletedStepIds(markOldCraft);
      onNextStepClick({
        type,
        toolId: currentToolId,
        modelSelect,
        stepId: previousStepId,
        clicked,
      });
    }
  };

  // when user clicks on Next, This Next button option is present in every step. this will fire the next step and existing tool id is called.
  const onNextStepClick = ({ clicked, generateMoreData, type, toolId, modelSelect, stepId }) => {
    setCraftButtonClicked(clicked);

    const details = allTools.find((el) => el.id === toolId);
    const { fields } = details || {
      fields: [],
    };

    if (toolId === TOOL_ID_BLOG_TITLES || type === FIELD_TYPE_TEXTAREA) {
      const toolRequestData = {
        input_2: blogPostStep[stepId].value,
        model_name: toolId,
        model_select: modelSelect,
        n_outputs: 5,
        ...translationCode,
      };
      //! this is the main existing function is called on evert tool or step id. This is the same function which is already called in tool pages.

      onApiHit({
        toolBody: toolRequestData,
        fields,
        toolID: toolId,
        currentStepOutputId: stepId,
      });
    } else if (toolId === TOOL_ID_BLOG_INTRODUCTION) {
      const toolRequestData = {
        input_1: blogPostStep[stepId].value,
        input_2: blogPostStep[STEP_TITLE_DESCRIPTION].value,
        model_name: toolId,
        model_select: modelSelect,
        ...translationCode,
      };
      onApiHit({ toolBody: toolRequestData, fields, toolID: toolId, currentStepOutputId: stepId });
    } else if (toolId === TOOL_ID_BLOG_OUTLINE) {
      const toolRequestData = {
        input_1: blogPostStep[STEP_CHOOSE_TITLE].value,
        input_2: blogPostStep[STEP_TITLE_DESCRIPTION].value,
        model_name: toolId,
        model_select: modelSelect,
        n_outputs: 1,
        ...translationCode,
      };
      onApiHit({ toolBody: toolRequestData, fields, toolID: toolId, currentStepOutputId: stepId });
    } else if (toolId === TOOL_ID_OUTLINE_BULLET_POINT) {
      let finalInput = '';
      if (generateMoreData) {
        finalInput = generateMoreData;
      } else {
        const blogStepOutput = blogPostStep[stepId];
        const constructInput2 =
          blogStepOutput && Array.isArray(blogStepOutput) && blogStepOutput.map(({ value }) => value);
        finalInput = constructInput2.join('\n');
      }
      const toolRequestData = {
        input_1: finalInput,
        model_name: toolId,
        model_select: modelSelect,
        n_outputs: 1,
        ...translationCode,
      };
      onApiHit({ toolBody: toolRequestData, fields, toolID: toolId, currentStepOutputId: stepId });
    } else if (toolId === TOOL_ID_PRODUCT_BULLET) {
      const blogStepOutput = blogPostStep[stepId];
      if (blogStepOutput && Array.isArray(blogStepOutput)) {
        let constructInput2 = null;
        if (generateMoreData) {
          constructInput2 = generateMoreData;
        } else {
          const getSectionIds = blogStepOutput.map(({ sectionId, sectionTitle }) => {
            return { sectionID: sectionId, sectionTitle };
          });

          const uniqueSectionIds = [...new Map(getSectionIds.map((v) => [JSON.stringify([v.sectionID]), v])).values()];

          const filterInput2 = uniqueSectionIds.map(({ sectionID, sectionTitle }) => {
            const filterSectionValues = blogStepOutput.filter(({ sectionId, sectionTitle }) => sectionID === sectionId);
            return { sectionTitle, sectionId: sectionID, sectionValues: filterSectionValues };
          });

          filterInput2.forEach(({ sectionTitle, sectionId, sectionValues }) => {
            constructInput2 = constructInput2
              ? constructInput2 + `\n\n${sectionId}#####${sectionTitle}\n`
              : `${sectionId}#####${sectionTitle}\n`;
            const join = sectionValues && Array.isArray(sectionValues) && sectionValues.map(({ value }) => value);
            const finalInput = join.join('\n');
            constructInput2 = constructInput2 + finalInput;
          });
        }

        const toolRequestData = {
          input_1: blogPostStep[STEP_CHOOSE_TITLE].value,
          input_2: constructInput2,
          model_name: toolId,
          model_select: modelSelect,
          ...translationCode,
        };
        onApiHit({ toolBody: toolRequestData, fields, toolID: toolId, currentStepOutputId: stepId });
      }
    } else if (toolId === TOOL_ID_CONCLUSION) {
      const blogStepOutput = blogPostStep[STEP_KEY_TOPICS];
      const constructInput2 =
        blogStepOutput && Array.isArray(blogStepOutput) && blogStepOutput.map(({ value }) => value);
      const finalInput = constructInput2.join('\n');

      const toolRequestData = {
        input_1: blogPostStep[STEP_CHOOSE_TITLE].value,
        input_2: blogPostStep[STEP_TITLE_DESCRIPTION].value,
        input_3: finalInput,
        model_name: toolId,
        model_select: modelSelect,
        ...translationCode,
      };
      onApiHit({ toolBody: toolRequestData, fields, toolID: toolId, currentStepOutputId: stepId });
    }
    setCustomAddMore({
      stepId: null,
      data: null,
    });
    setOnEditUserOption();
  };

  // This sends the request when a submit button is clicked on any step
  const onApiHit = (apiPayload) => {
    const verify = verifyBilling({
      adminBillingSettings,
      userAllowToCraft,
      userCurrentPlan: plan,
      todaysTotalHits,
      currentBilling,
      userBots,
      planStatus,
      noTrialccPlanAcitivated,
      selectedWorkspace,
      memberTeamsWithOutHold,
      lastCraftDate,
      activatedPlanDetails,
      totalWordsCraft,
      recentProjectId: selectedCurrentProject && selectedCurrentProject.currentProjectId,
      roles,
    });

    if (verify === true) {
      // this is the save reducer is being used for the rest of the tools.
      dispatch(userSaveBot({ ...apiPayload }));
    } else if (typeof verify === 'object') {
      // if there is any issue with the user account then a billing modal appears
      const { notAllowedMessage, limit } = verify;
      setShowBillingModal({ modalVisible: true, messageType: notAllowedMessage, wordsLimit: limit });
    }
  };

  // this triggers when there is change in the longFormOutput. handles the new tool request.
  useEffect(() => {
    const { content } = longFormOutput || { content: null };
    if (toolOutputLoading === false && longFormOutput && content && blogPostStep && blogPostStep[currentStepOutputId]) {
      // as the user request the data for the next step so this make sure the new result display on the new step
      const getNextStepId = LongFormSteps.find(({ stepId }) => currentStepOutputId === stepId).nextStepId;

      // show the next step and make it active
      setShowStep(getNextStepId);
      setCraftButtonClicked('');
      setSelectedSection('');

      if (saveCompletedStepIds && currentStepOutputId === showStep) {
        const checkExitingStepId =
          saveCompletedStepIds &&
          saveCompletedStepIds.length > 0 &&
          saveCompletedStepIds.find(({ stepId }) => stepId === showStep);
        if (checkExitingStepId) {
          const elementIndex = saveCompletedStepIds.findIndex((obj) => obj.stepId === showStep);
          if (Array.isArray(blogPostStep[currentStepOutputId])) {
            saveCompletedStepIds[elementIndex].selectedOutput = blogPostStep[currentStepOutputId];
            saveCompletedStepIds[elementIndex].selectedOutputId = blogPostStep[currentStepOutputId];
          } else {
            saveCompletedStepIds[elementIndex].selectedOutput = blogPostStep[currentStepOutputId].value;
            saveCompletedStepIds[elementIndex].selectedOutputId = blogPostStep[currentStepOutputId].id;
          }
        } else {
          // this will be only on 1st step
          const stepOutput = {
            content: blogPostStep[currentStepOutputId].value,
            stepId: currentStepOutputId,
            selectedOutput: blogPostStep[currentStepOutputId].value,
            selectedOutputId: blogPostStep[currentStepOutputId].id,
          };
          saveCompletedStepIds.push(stepOutput);
        }
      }

      // Finding the next step via stepID to display and active
      switch (getNextStepId) {
        case STEP_KEY_TOPICS:
          const stepOutput_STEP_KEY_TOPICS = [];

          content &&
            content.forEach(({ contentData, contentId }) => {
              const newPoint = contentData.split('\n');
              if (Array.isArray(newPoint)) {
                newPoint.forEach((item) => {
                  stepOutput_STEP_KEY_TOPICS.push({ contentData: item, contentId: uniqueId() });
                });
              } else {
                stepOutput_STEP_KEY_TOPICS.push({ contentData, contentId });
              }
            });

          saveCompletedStepIds.push({
            content: stepOutput_STEP_KEY_TOPICS,
            stepId: getNextStepId,
          });
          break;
        case STEP_PARAGRAGH_POINT:
          const stepOutput_STEP_PARAGRAGH_POINT = [];

          let sectionUniqueId = null;
          // this is used to check whether user is generating data through GENERATE MORE
          if (saveDataForGenerate) {
            const { selectedContentId } = saveDataForGenerate;
            sectionUniqueId = selectedContentId;
          }

          const generateSectionPoints = (newParagraph) => {
            return newParagraph.map((item, index) => {
              const sectionTitle = item.split('####')[0];
              const sectionId = sectionUniqueId || uniqueId();
              const points = item.split('####')[1];
              const sectionArray = {};
              sectionArray.sectionTitle = sectionTitle;
              sectionArray.contentId = sectionId;
              if (saveDataForGenerate) {
                const { sectionOrder } = saveDataForGenerate;
                sectionArray.order = sectionOrder;
              } else {
                sectionArray.order = index + 1;
              }

              const sectionData = points
                .split('\n')
                .filter(Boolean)
                .map((item) => {
                  return item && item.length > 0 && { pointValue: item, pointId: uniqueId(), sectionId };
                });

              // if user has selected the option and clicks on GENERATE MORE
              const { completedStepIds } = saveDataForGenerate || {
                completedStepIds: null,
              };
              if (completedStepIds) {
                sectionData.push(...completedStepIds);
              }

              sectionArray.contentData = sectionData;
              return sectionArray;
            });
          };
          content &&
            content.forEach(({ contentData, contentId }) => {
              const newParagraph = contentData.split('\n\n\n');
              if (Array.isArray(newParagraph)) {
                const outp = generateSectionPoints(newParagraph);
                stepOutput_STEP_PARAGRAGH_POINT.push(...outp);
              } else {
                stepOutput_STEP_PARAGRAGH_POINT.push({ contentData, contentId, order: 1 });
              }
            });

          if (saveDataForGenerate) {
            const { selectedContentId } = saveDataForGenerate || { selectedContentId: null };
            if (selectedContentId) {
              const stepId = getNextStepId;
              const updated_stepOutput_STEP_PARAGRAGH_POINT = saveCompletedStepIds.map((obj) => {
                if (stepId === obj.stepId) {
                  const result = obj.content.filter((v) => {
                    return selectedContentId !== v.contentId;
                  });
                  result.push(...stepOutput_STEP_PARAGRAGH_POINT);
                  return {
                    content: result,
                    stepId: STEP_PARAGRAGH_POINT,
                  };
                } else {
                  return { ...obj };
                }
              });
              setSaveCompletedStepIds(updated_stepOutput_STEP_PARAGRAGH_POINT);
              setSaveDataForGenerate(false);
            }
          } else {
            saveCompletedStepIds.push({
              content: stepOutput_STEP_PARAGRAGH_POINT,
              stepId: getNextStepId,
            });
            setSaveCompletedStepIds(saveCompletedStepIds);
            setSaveDataForGenerate(false);
          }

          break;
        case STEP_PARAGRAPH_BUILDER:
          const stepOutput_STEP_PARAGRAPH_BUILDER = [];

          // If user wants to generate more data.
          const { blogPostSelected, selectedContentId } = saveDataForGenerate || {
            blogPostSelected: null,
            selectedContentId: null,
          };

          /// this is a function which is calling down below
          const generateSectionPointsBuilder = (newParagraph, saveGenerateText) => {
            return newParagraph.map((item, index) => {
              const sectionTitleAndSectionId = item.split('####')[0];
              let sectionId = sectionTitleAndSectionId.split('|')[0];

              const sectionTitle = sectionTitleAndSectionId.split('|')[1];

              const points = item.split('####')[1];
              const sectionArray = {};
              sectionArray.sectionTitle = sectionTitle;
              sectionArray.contentId = sectionId;

              const { sectionOrder } = saveDataForGenerate;
              if (sectionOrder) {
                sectionArray.order = sectionOrder;
              } else {
                sectionArray.order = index + 1;
              }

              if (saveGenerateText) {
                const sectionData = JSON.parse(points).map(({ value, id }) => {
                  return value && value.length > 0 && { pointValue: value, pointId: id, sectionId };
                });
                sectionArray.contentData = sectionData;
              } else {
                const sectionData = points
                  .split('\n')
                  .filter(Boolean)
                  .map((item) => {
                    return item && item.length > 0 && { pointValue: item, pointId: uniqueId(), sectionId };
                  });

                sectionArray.contentData = sectionData;
              }

              return sectionArray;
            });
          };

          if (blogPostSelected && Array.isArray(blogPostSelected) && blogPostSelected.length > 0 && selectedContentId) {
            const { sectionTitle } = blogPostSelected.find(({ sectionId }) => selectedContentId === sectionId);
            const valueData = blogPostSelected.map(({ value, id }) => {
              return { value, id };
            });
            const format = `${selectedContentId}|${sectionTitle}####\n${JSON.stringify(valueData)}`;
            content.push({ contentData: format, contentId: uniqueId(), cfr: '0', len: 555, saveGenerateText: true });
          }

          content &&
            content.forEach(({ contentData, contentId, saveGenerateText }) => {
              const newParagraph = contentData.split('\n\n\n');
              if (Array.isArray(newParagraph)) {
                const outp = generateSectionPointsBuilder(newParagraph, saveGenerateText);
                stepOutput_STEP_PARAGRAPH_BUILDER.push(...outp);
              } else {
                const sectionTitleAndID = newParagraph.split('####')[0];
                const sectionId = sectionTitleAndID.split('|')[0];
                const sectionTitle = sectionTitleAndID.split('|')[1];
                stepOutput_STEP_PARAGRAPH_BUILDER.push({ contentData, contentId: sectionId, order: 1, sectionTitle });
              }
            });

          const finalStepOutput = [];
          stepOutput_STEP_PARAGRAPH_BUILDER.forEach(({ sectionTitle, contentId, contentData, order }) => {
            const check = finalStepOutput.find((v) => contentId === v.contentId);
            if (check) {
              const checkIndex = finalStepOutput.findIndex((v) => contentId === v.contentId);
              finalStepOutput[checkIndex].contentData.push(...contentData);
            } else {
              finalStepOutput.push({ sectionTitle, contentId, contentData, order });
            }
          });

          // Check if the user have clicked on generateMore button.
          if (selectedContentId) {
            const stepId = getNextStepId;

            const stepOutput_STEP_PARAGRAPH_BUILDER = saveCompletedStepIds.map((obj) => {
              if (stepId === obj.stepId) {
                const result = obj.content.map((v) => {
                  if (selectedContentId === v.contentId) {
                    const checkContentData = finalStepOutput.find(
                      ({ contentId }) => selectedContentId === contentId,
                    )?.contentData;
                    return { ...v, contentData: checkContentData };
                  } else {
                    return { ...v };
                  }
                });
                return {
                  content: result,
                  stepId: getNextStepId,
                };
              } else {
                return { ...obj };
              }
            });
            setSaveCompletedStepIds(stepOutput_STEP_PARAGRAPH_BUILDER);
          } else {
            saveCompletedStepIds.push({
              content: finalStepOutput,
              stepId: getNextStepId,
            });
            setSaveCompletedStepIds(saveCompletedStepIds);
          }

          setSaveDataForGenerate(false);

          break;
        default:
          const stepOutput = {
            content: content,
            stepId: getNextStepId,
          };
          saveCompletedStepIds.push(stepOutput);
      }
      // speciall condition to accomodate
      if (getNextStepId !== STEP_PARAGRAGH_POINT && getNextStepId !== STEP_PARAGRAPH_BUILDER) {
        // now add user already selected option from previous craft.
        let finalSaveCompletedStepIds = saveCompletedStepIds;
        // check if user has clicked on Generate More.
        // oldCraft object will be present in object array.
        const checkOldCraft = saveCompletedStepIds.filter(({ oldCraft }) => oldCraft !== true);
        const { completedStepIds } = saveDataForGenerate || { completedStepIds: false };
        if (completedStepIds) {
          finalSaveCompletedStepIds = checkOldCraft.map((obj) => {
            if (getNextStepId === obj.stepId) {
              obj.content.push(...completedStepIds);
            }
            return obj;
          });
        } else {
          finalSaveCompletedStepIds = checkOldCraft;
        }

        setSaveCompletedStepIds(finalSaveCompletedStepIds);
        setSaveDataForGenerate(false);
      }
    }
  }, [currentStepOutputId, longFormOutput, toolOutputLoading]);

  useEffect(() => {
    setCraftButtonClicked('');
    setSelectedSection('');
  }, [toolHitFailed]);

  // THe data is saved on firestore under collection long-form-documents
  useEffect(() => {
    if (blogPostId && showStep !== STEP_TITLE_DESCRIPTION) {
      const storageCompletedSteps = JSON.stringify(saveCompletedStepIds);
      const storageBlogPostStep = JSON.stringify(blogPostStep);
      dispatch(
        saveDocument({
          uid,
          data: {
            date: new Date(),
            storageCompletedSteps,
            storageBlogPostStep,
            showStep,
            summary: summaryData,
            id: blogPostId,
            title: blogPostStep[STEP_TITLE_DESCRIPTION].value,
          },
        }),
      );
      setCheckUnsavedChanges(false);
    }
  }, [saveCompletedStepIds, blogPostStep, showStep]);

  const onContinueWithDraft = ({ type }) => {
    if (type === 'continue') {
      const { blogPostStep, completedSteps, showStep, summary, id } = lastDocumentData;

      const getSaveCompletedStepIds = completedSteps;
      const getBlogPostStep = blogPostStep;
      const getshowStep = showStep;
      const getSummary = summary;

      setSaveCompletedStepIds(getSaveCompletedStepIds);
      setBlogPostStep(getBlogPostStep);
      setShowStep(getshowStep);
      setCheckUnsavedChanges(false);
      // setSummaryData(getSummary);
      setBlogPostId(id);
    } else {
      setSaveCompletedStepIds([]);
      setBlogPostStep({});
      setShowStep(STEP_TITLE_DESCRIPTION);
      setCheckUnsavedChanges(false);
      // navigate(`/long-form`);
    }
  };

  const onEdit = ({ editStepId, craftOutput }) => {
    const currentStepData = saveCompletedStepIds.find(({ stepId }) => stepId === editStepId);
    setOnEditUserOption(craftOutput);
    setWarningVisible({ type: 'steps', open: true });
    setSaveEditData({
      currentStepData: { ...currentStepData },
      editStepId,
    });
  };

  const onEditInput = (editType) => {
    setWarningVisible({ type: 'steps', open: false });
    const { editStepId } = saveEditData;
    if (editType === 'all') {
      const getLongStepId = LongFormSteps.find(({ stepId }) => stepId === editStepId).id;
      const sequenceSteps = LongFormSteps.filter(({ id }) => id > getLongStepId);

      setShowStep(editStepId);

      sequenceSteps.forEach((sequence) => {
        const checkStepPresent = saveCompletedStepIds.find(({ stepId }) => sequence.stepId === stepId);
        if (checkStepPresent) {
          const indexOfObject = saveCompletedStepIds.findIndex(({ stepId }) => sequence.stepId === stepId);
          saveCompletedStepIds.splice(indexOfObject, 1);
        }
        blogPostStep.hasOwnProperty(sequence.stepId) && delete blogPostStep[sequence.stepId];
      });
      saveCompletedStepIds.forEach((v) => {
        if (v.stepId === editStepId) {
          delete v.selectedOutput;
          delete v.selectedOutputId;
        }
      });
      setSaveCompletedStepIds(saveCompletedStepIds);
    } else if (editType === 'current') {
      setSelectedStepEdit(editStepId);
      saveCompletedStepIds.forEach((v) => {
        if (v.stepId === editStepId) {
          delete v.selectedOutput;
          delete v.selectedOutputId;
        }
      });
      setSaveCompletedStepIds(saveCompletedStepIds);
    }
  };

  const showSummaryDesign = () => {
    const title = saveCompletedStepIds.find(({ stepId }) => stepId === STEP_CHOOSE_TITLE).selectedOutput;
    const intro = saveCompletedStepIds.find(({ stepId }) => stepId === STEP_CHOOSE_INTRO).selectedOutput;
    const sections = saveCompletedStepIds.find(({ stepId }) => stepId === STEP_PARAGRAPH_BUILDER).selectedOutput;
    const remainingValues = saveCompletedStepIds.filter(({ stepId }) => STEP_CONCLUSION !== stepId);
    const conclusionValues = saveCompletedStepIds.find(({ stepId }) => STEP_CONCLUSION === stepId);
    const saveConclusionStep = {
      ...conclusionValues,
      selectedOutput: blogPostStep[STEP_CONCLUSION].value,
      selectedOutputId: blogPostStep[STEP_CONCLUSION].id,
    };
    setShowStep(SUMMARY_UI);
    remainingValues.push(saveConclusionStep);
    setSaveCompletedStepIds(remainingValues);

    const uniqueSectionId = [...new Set(sections.map((item) => item.sectionId))];
    const getSectionValue = [];
    uniqueSectionId.forEach((id, index) => {
      const filter = sections.filter(({ sectionId }) => sectionId === id);
      let temp = [];
      filter.forEach((v) => {
        temp.push(v.value);
      });
      getSectionValue.push({
        value: temp,
        sectionId: filter[0].sectionId,
        sectionTitle: filter[0].sectionTitle,
        id: filter[0].id,
        order: index,
      });
    });

    setSummaryData({
      title,
      intro,
      sections: getSectionValue,
      conclusion: blogPostStep[STEP_CONCLUSION].value,
    });
  };
  const handleVisibleChange = (visible) => {
    setWarningVisible({ type: 'steps', open: false });
  };

  const onSaveNewEdit = ({ toolId, stepId }) => {
    const {
      currentStepData: { content, selectedOutputId },
    } = saveEditData;
    const filterOtherStepsData = saveCompletedStepIds.filter((v) => v.stepId !== stepId);
    const newData = Array.isArray(blogPostStep[stepId]) ? blogPostStep[stepId] : blogPostStep[stepId].value;
    filterOtherStepsData.push({
      content: content,
      stepId,
      selectedOutput: newData,
      selectedOutputId: selectedOutputId,
    });
    setSaveCompletedStepIds(filterOtherStepsData);
    setSelectedStepEdit(false);
    setSaveEditData(false);

    setOnEditUserOption();
  };

  const onCancelEdit = ({ toolId, editStepId }) => {
    const {
      currentStepData: { selectedOutput, selectedOutputId },
    } = saveEditData;

    blogPostStep[editStepId].value = selectedOutput;
    const resetSaveCompletedStepIds = saveCompletedStepIds.map((obj) => {
      if (editStepId === obj.stepId) {
        return { ...obj, selectedOutput, selectedOutputId };
      }
      return obj;
    });
    setSaveCompletedStepIds(resetSaveCompletedStepIds);
    setBlogPostStep(blogPostStep);
    setSelectedStepEdit(false);
    setSaveEditData(false);
    setOnEditUserOption();
  };

  const buttonLoading = ({ stepId, selectedContentId, activeStep }) => {
    if (stepId === STEP_PARAGRAGH_POINT || stepId === STEP_PARAGRAPH_BUILDER) {
      return selectedContentId === selectedSectionId && toolOutputLoading;
    } else {
      return activeStep && craftButtonClicked === GENERATE_BUTTON_CLICK && toolOutputLoading;
    }
  };

  const buttonDisable = ({ stepId, multipleSelect, minPerSection }) => {
    let sectionMininum = false;
    if ((stepId === STEP_PARAGRAPH_BUILDER || stepId === STEP_PARAGRAGH_POINT) && blogPostStep[stepId]) {
      let uniqueSectionId = [];
      const uniqueSections = saveCompletedStepIds.filter((v) => v.stepId === stepId);
      uniqueSections.forEach(({ content }) => content.forEach(({ contentId }) => uniqueSectionId.push(contentId)));

      const check =
        uniqueSectionId &&
        uniqueSectionId
          .map((id) => {
            return blogPostStep[stepId].find(({ sectionId }) => sectionId === id);
          })
          .filter(Boolean);
      sectionMininum = check.length === uniqueSectionId.length;
      return craftButtonClicked === GENERATE_BUTTON_CLICK || !sectionMininum;
    } else {
      return craftButtonClicked === GENERATE_BUTTON_CLICK || multipleSelect
        ? blogPostStep[stepId]
          ? blogPostStep[stepId].length <= 0
          : true
        : !blogPostStep[stepId];
    }
  };

  const actionButtons = ({
    buttonText,
    activeStep,
    currentEditActiveStep,
    modelSelect,
    toolId,
    stepId,
    type,
    generateMoreBtn,
    currentToolId,
    previousStepId,
    selectedContentId,
    sectionOrder,
    sectionTitle,
    multipleSelect,
    minPerSection,
  }) => {
    return (
      <>
        {generateMoreBtn && !currentEditActiveStep && (
          <Button
            onClick={() =>
              generateMore({
                currentToolId,
                stepId,
                previousStepId,
                type,
                selectedContentId,
                sectionOrder,
                sectionTitle,
                clicked: GENERATE_BUTTON_CLICK,
              })
            }
            block
            className="generate-btn"
            type={BUTTON_PRIMARY}
            loading={buttonLoading({ stepId, selectedContentId, activeStep })}
            disabled={
              craftButtonClicked === NEXT_BUTTON_CLICK || (selectedContentId && selectedContentId === selectedSectionId)
            } // there are 2 conditions, if NEXT button is active or other section is active
          >
            {generateMoreBtn}
          </Button>
        )}
        {buttonText && !currentEditActiveStep && (
          <Button
            onClick={() => onNextStepClick({ modelSelect, toolId, stepId, type, clicked: NEXT_BUTTON_CLICK })}
            disabled={buttonDisable({ stepId, multipleSelect, minPerSection })}
            block
            type={BUTTON_PRIMARY}
            loading={activeStep && craftButtonClicked === NEXT_BUTTON_CLICK && toolOutputLoading}
            className="next-btn"
          >
            {buttonText}
          </Button>
        )}

        {currentEditActiveStep && (
          <Button
            onClick={() => onSaveNewEdit({ toolId, stepId })}
            disabled={!blogPostStep[stepId]}
            block
            type={BUTTON_PRIMARY}
            loading={activeStep && toolOutputLoading}
            className="next-btn"
          >
            Save
          </Button>
        )}
      </>
    );
  };

  const populateField = ({
    field,
    wordsLimit,
    checkFieldValue,
    buttonText,
    title,
    header,
    image,
    stepId,
    toolId,
    modelSelect,
    activeStep,
    generateMoreBtn,
    customInput,
    currentToolId,
    previousStepId,
    currentEditActiveStep,
  }) => {
    const { type, multipleSelect, max, minPerSection } = field;
    const wordsCount = (blogPostStep[stepId] && blogPostStep[stepId].value && blogPostStep[stepId].value.length) || 0;
    const wordsCountCheck = wordsCount > wordsLimit;
    const getSavedStepValue = currentEditActiveStep ? selectedStepEdit : showStep;
    const checkSavedStepValue = saveCompletedStepIds.find((savedStepIds) => savedStepIds.stepId === getSavedStepValue);
    const checkLimit = blogPostStep[stepId] && max <= blogPostStep[stepId].length;

    if (type === FIELD_TYPE_TEXTAREA) {
      const { label, placeholder } = field;
      const textValueField = (blogPostStep && blogPostStep[stepId] && blogPostStep[stepId].value) || '';

      return (
        <StepFieldWrapper stepId={stepId}>
          {label && (
            <div className="field-heading">
              <p className="field-label">{label}</p>
              <p
                className={`field-wordslimit ${wordsCountCheck && 'field-wordlimit-error'}`}
              >{`${wordsCount}/${wordsLimit}`}</p>
            </div>
          )}
          <TextArea
            status={wordsCountCheck && 'error'}
            maxLength={wordsLimit + 1}
            onChange={(e) => onTextFieldChange(e, stepId)}
            className="field-textarea"
            placeholder={placeholder}
            autoSize={{ minRows: 5, maxRows: 7 }}
            allowClear
            value={saveOnEditUserOption || textValueField}
          />
          {buttonText && (
            <div className="input-button">
              {actionButtons({
                modelSelect,
                toolId,
                currentEditActiveStep,
                stepId,
                type,
                buttonText,
                activeStep,
              })}
            </div>
          )}
        </StepFieldWrapper>
      );
    }
    if (type === FIELD_TYPE_MULTIPLE_OPTIONS) {
      const { label } = field;

      return (
        <StepFieldWrapper stepId={stepId}>
          {label && (
            <div className="field-heading">
              <div className="field-label">{label}</div>
            </div>
          )}
          <Spin spinning={toolOutputLoading}>
            <div className="list">
              {checkSavedStepValue &&
                checkSavedStepValue.content &&
                Array.isArray(checkSavedStepValue.content) &&
                checkSavedStepValue.content.map(({ contentData, len, contentId }) => {
                  const selectedOption = blogPostStep[stepId] && contentId === blogPostStep[stepId].id;
                  const { lines, singleChangeNewLine } = breakLineIfRequired(maskBadWords(contentData));
                  return (
                    <div
                      className={`field-optionList ${selectedOption && 'field-optionList-selected'}`}
                      key={contentId}
                    >
                      <div
                        className="content-data"
                        onClick={() => onOptionClick({ stepId, contentData: singleChangeNewLine, contentId })}
                      >
                        {lines}
                        {label ? <div className="record">{len} Words</div> : null}
                      </div>
                      {!selectedOption && (
                        <div className="icon-delete" onClick={() => onDelete({ stepId, contentId })}>
                          <ReactSVG src={deleteIcon} />
                        </div>
                      )}
                    </div>
                  );
                })}
              {customInput && (
                <>
                  <div className="words-limit">
                    <Text
                      type={
                        customAddMore.data && customAddMore.data.length === customInputWordCount
                          ? 'danger'
                          : 'secondary'
                      }
                    >
                      {(customAddMore.data && customAddMore.data.length) || 0} / {customInputWordCount}
                    </Text>
                  </div>
                  <div className="field-optionList">
                    <div className="field-add-text">
                      <img
                        style={{ cursor: checkLimit && 'not-allowed' }}
                        onClick={() => !checkLimit && onClickCustomAddMore({ AddMoreStepId: stepId })}
                        src={plus}
                        alt="plus"
                      ></img>
                      <TextArea
                        disabled={checkLimit}
                        onChange={(e) => onChangeCustomAddMore(e, stepId)}
                        autoSize={{ minRows: 1 }}
                        bordered={false}
                        className="add-text"
                        placeholder="Add your own"
                        value={(customAddMore && customAddMore.data) || ''}
                        maxLength={customInputWordCount}
                      />
                      <Button
                        type={'primary'}
                        size="small"
                        disabled={
                          !checkLimit && customAddMore && customAddMore.data && customAddMore.data.length > 0
                            ? false
                            : true
                        }
                        onClick={() => !checkLimit && onClickCustomAddMore({ AddMoreStepId: stepId })}
                      >
                        Add +
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </Spin>

          <div className={`action-buttons `}>
            <div className="field-button">
              {actionButtons({
                modelSelect,
                toolId,
                currentEditActiveStep,
                stepId,
                type,
                buttonText,
                activeStep,
                generateMoreBtn,
                previousStepId,
                currentToolId,
              })}
            </div>
          </div>
        </StepFieldWrapper>
      );
    }
    if (type === FIELD_TYPE_OUTLINE_POINTS) {
      if (checkSavedStepValue && checkSavedStepValue.content && Array.isArray(checkSavedStepValue.content)) {
        return (
          <StepFieldWrapper stepId={stepId}>
            <Spin spinning={toolOutputLoading}>
              <div className="list">
                {checkSavedStepValue.content &&
                  Array.isArray(checkSavedStepValue.content) &&
                  checkSavedStepValue.content.map(({ contentData, contentId }) => {
                    let selectedOption = null;
                    if (blogPostStep[stepId]) {
                      selectedOption = blogPostStep[stepId].find((item) => item.id === contentId);
                    }
                    return (
                      <div
                        className={`field-optionList ${selectedOption && 'field-optionList-selected'}`}
                        key={contentId}
                      >
                        <div
                          className="content-data"
                          onClick={() => onOptionClick({ max, stepId, contentData, contentId, multipleSelect })}
                        >
                          {contentData}
                        </div>
                        {checkSavedStepValue.content.length > 1 && !selectedOption && (
                          <div className="icon-delete">
                            <ReactSVG
                              className="delete-icon"
                              src={deleteIcon}
                              onClick={() => onDelete({ stepId, contentId })}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}

                <div className="words-limit">
                  <Text
                    type={
                      customAddMore.data && customAddMore.data.length === customInputWordCount ? 'danger' : 'secondary'
                    }
                  >
                    {(customAddMore.data && customAddMore.data.length) || 0} / {customInputWordCount}
                  </Text>
                </div>
                <div className="field-optionList">
                  <div className="field-add-text">
                    <img
                      style={{ cursor: checkLimit && 'not-allowed' }}
                      onClick={() => !checkLimit && onClickCustomAddMore({ AddMoreStepId: stepId })}
                      src={plus}
                      alt="plus"
                    ></img>
                    <TextArea
                      disabled={checkLimit}
                      onChange={(e) => onChangeCustomAddMore(e, stepId)}
                      autoSize={{ minRows: 1 }}
                      bordered={false}
                      className="add-text"
                      placeholder="Add your own"
                      value={(customAddMore && customAddMore.data) || ''}
                      maxLength={customInputWordCount}
                    />
                    <Button
                      type={'primary'}
                      disabled={
                        !checkLimit && customAddMore && customAddMore.data && customAddMore.data.length > 0
                          ? false
                          : true
                      }
                      onClick={() => !checkLimit && onClickCustomAddMore({ AddMoreStepId: stepId })}
                    >
                      Add +
                    </Button>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="action-buttons">
              <div className="field-button">
                {actionButtons({
                  buttonText,
                  activeStep,
                  modelSelect,
                  toolId,
                  stepId,
                  type,
                  generateMoreBtn,
                  currentEditActiveStep,
                  previousStepId,
                  currentToolId,
                  multipleSelect,
                })}
              </div>
            </div>
          </StepFieldWrapper>
        );
      }
    }
    if (type === FIELD_TYPE_SECTION_POINTS) {
      if (checkSavedStepValue && checkSavedStepValue.content && Array.isArray(checkSavedStepValue.content)) {
        const contentOutput = checkSavedStepValue.content;
        return (
          <StepFieldWrapper stepId={stepId} STEP_KEY_TOPICS={STEP_KEY_TOPICS}>
            <Spin spinning={toolOutputLoading}>
              <div className="list">
                {contentOutput &&
                  Array.isArray(contentOutput) &&
                  contentOutput
                    .sort((a, b) => a.order - b.order)
                    .map(({ contentData, contentId, sectionTitle, order }, index) => {
                      const count =
                        blogPostStep &&
                        Array.isArray(blogPostStep[stepId]) &&
                        blogPostStep[stepId].filter((v) => v.sectionId === contentId).length;
                      const sectionCount = max <= count;
                      return (
                        <>
                          <div className={`field-section-points`}>
                            <Text className="section-word">Section {index + 1} </Text>
                            <Text className="section-name">{sectionTitle}</Text>
                          </div>
                          {contentData &&
                            Array.isArray(contentData) &&
                            contentData.map(({ pointId, pointValue, sectionId }) => {
                              let selectedOption = null;
                              if (blogPostStep[stepId]) {
                                selectedOption = blogPostStep[stepId].find((item) => item.id === pointId);
                              }

                              return (
                                <div
                                  className={`field-optionList ${selectedOption && 'field-optionList-selected'}`}
                                  key={pointId}
                                >
                                  <div
                                    className="content-data"
                                    onClick={() =>
                                      onOptionClick({
                                        stepId,
                                        contentData: pointValue,
                                        contentId: pointId,
                                        sectionId,
                                        multipleSelect,
                                        sectionTitle,
                                        max,
                                      })
                                    }
                                  >
                                    {pointValue}
                                  </div>
                                  {!selectedOption && (
                                    <div
                                      className="icon-delete"
                                      onClick={() => onDelete({ stepId, contentId, pointId })}
                                    >
                                      <ReactSVG className="delete-icon" src={deleteIcon} />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          <div className="words-limit">
                            <Text
                              type={
                                customAddMore.data &&
                                customAddMore.data.length &&
                                (customAddMore.contentId === contentId) === customInputWordCount
                                  ? 'danger'
                                  : 'secondary'
                              }
                            >
                              {(customAddMore.contentId === contentId &&
                                customAddMore.data &&
                                customAddMore.data.length) ||
                                0}
                              / {customInputWordCount}
                            </Text>
                          </div>
                          <div className="field-optionList">
                            <div className="field-add-text">
                              <img
                                style={{ cursor: sectionCount && 'not-allowed' }}
                                onClick={() =>
                                  !sectionCount &&
                                  onClickCustomAddMore({ AddMoreStepId: stepId, contentId, sectionTitle })
                                }
                                src={plus}
                                alt="plus"
                              ></img>
                              <TextArea
                                disabled={sectionCount}
                                onChange={(e) => onChangeCustomAddMore(e, stepId, contentId)}
                                autoSize={{ minRows: 1 }}
                                bordered={false}
                                value={
                                  (customAddMore && customAddMore.contentId === contentId && customAddMore.data) || ''
                                }
                                className="add-text"
                                placeholder="Add your own"
                                maxLength={customInputWordCount}
                              />
                              <Button
                                type={'primary'}
                                disabled={
                                  !sectionCount &&
                                  customAddMore &&
                                  customAddMore.contentId === contentId &&
                                  customAddMore.data &&
                                  customAddMore.data.length > 0
                                    ? false
                                    : true
                                }
                                onClick={() =>
                                  !sectionCount &&
                                  onClickCustomAddMore({ AddMoreStepId: stepId, contentId, sectionTitle })
                                }
                              >
                                Add +
                              </Button>
                            </div>
                          </div>
                          <div className={`action-buttons`}>
                            <div className="field-button">
                              {actionButtons({
                                modelSelect,
                                toolId,
                                stepId,
                                type,
                                generateMoreBtn,
                                previousStepId,
                                currentToolId,
                                activeStep,
                                selectedContentId: contentId,
                                sectionOrder: order,
                                sectionTitle,
                              })}
                            </div>
                          </div>
                        </>
                      );
                    })}
                <>
                  {width > 768 && <Divider />}
                  <div className={`action-buttons`}>
                    <div className="field-button">
                      {actionButtons({
                        buttonText,
                        activeStep,
                        modelSelect,
                        toolId,
                        stepId,
                        type,
                        currentEditActiveStep,
                        multipleSelect,
                        minPerSection,
                      })}
                    </div>
                  </div>
                </>
              </div>
            </Spin>
          </StepFieldWrapper>
        );
      }
    }
    if (type === FIELD_TYPE_SECTION_BUILDER) {
      if (checkSavedStepValue && checkSavedStepValue.content && Array.isArray(checkSavedStepValue.content)) {
        const contentOutput = checkSavedStepValue.content;
        return (
          <StepFieldWrapper stepId={stepId} STEP_KEY_TOPICS={STEP_KEY_TOPICS}>
            <Space direction="vertical" style={{ width: '100%' }} gap="20px">
              {contentOutput.map(({ contentData, order, sectionTitle, contentId }, index) => {
                return (
                  <div className="para-builder">
                    <Spin spinning={toolOutputLoading}>
                      <div className={`field-section-points`}>
                        <Text className="section-word">Section {index + 1} </Text>
                        <Text className="section-name"> {sectionTitle} </Text>
                      </div>

                      <Space direction="vertical" style={{ width: '100%' }}>
                        {contentData &&
                          Array.isArray(contentData) &&
                          contentData.map(({ pointId, pointValue, sectionId }) => {
                            let selectedOption = null;
                            if (blogPostStep[stepId]) {
                              selectedOption = blogPostStep[stepId].find((item) => item.id === pointId);
                            }
                            return (
                              <>
                                <div
                                  className={`field-optionList ${selectedOption && 'field-optionList-selected'}`}
                                  key={pointId}
                                >
                                  <div
                                    className="content-data"
                                    onClick={() =>
                                      onOptionClick({
                                        stepId,
                                        contentData: pointValue,
                                        contentId: pointId,
                                        sectionId,
                                        multipleSelect,
                                        sectionTitle,
                                      })
                                    }
                                  >
                                    {pointValue}
                                    <div className="record">
                                      <p>{pointValue && pointValue.split(' ').length} words</p>
                                    </div>
                                  </div>
                                  {!selectedOption && (
                                    <div
                                      className="icon-delete"
                                      onClick={() => onDelete({ stepId, contentId: sectionId, pointId })}
                                    >
                                      <ReactSVG className="delete-icon" src={deleteIcon} />
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })}
                      </Space>

                      <div className={`action-buttons content}`}>
                        <div className="field-button">
                          {actionButtons({
                            modelSelect,
                            toolId,
                            stepId,
                            type,
                            generateMoreBtn,
                            selectedContentId: contentId,
                            sectionOrder: order,
                            currentToolId,
                            previousStepId,
                            activeStep,
                            sectionTitle,
                            multipleSelect,
                          })}
                        </div>
                      </div>
                    </Spin>
                  </div>
                );
              })}
            </Space>
          </StepFieldWrapper>
        );
      }
    }
    // final step
    if (type === FIELD_TYPE_CONCLUSION_POINTS) {
      const { label } = field;
      return (
        <StepFieldWrapper stepId={stepId}>
          {label && (
            <div className="field-heading">
              <div className="field-label">{label}</div>
            </div>
          )}
          <Space direction="vertical" style={{ width: '100%', marginTop: '20px' }}>
            {checkSavedStepValue &&
              checkSavedStepValue.content &&
              Array.isArray(checkSavedStepValue.content) &&
              checkSavedStepValue.content.map(({ contentData, contentId }) => {
                const selectedOption = blogPostStep[stepId] && contentId === blogPostStep[stepId].id;
                const { lines, singleChangeNewLine, lineBr } = breakLineIfRequired(maskBadWords(contentData));
                return (
                  <div className={`field-optionList ${selectedOption && 'field-optionList-selected'}`} key={contentId}>
                    <div
                      className="content-data"
                      onClick={() => onOptionClick({ stepId, contentData: singleChangeNewLine, contentId })}
                    >
                      {lines}
                      <div className="record">
                        <p>{lineBr && lineBr.split(' ').length} words</p>
                      </div>
                    </div>
                    {!selectedOption && (
                      <div className="icon-delete" onClick={() => onDelete({ stepId, contentId })}>
                        <ReactSVG className="delete-icon" src={deleteIcon} />
                      </div>
                    )}
                  </div>
                );
              })}
          </Space>

          <Divider />
          <div className="words-limit">
            <Text type={customAddMore.data && customAddMore.data.length === 2000 ? 'danger' : 'secondary'}>
              {(customAddMore.data && customAddMore.data.length) || 0}/ 2000
            </Text>
          </div>
          <div className="field-optionList">
            <div className="field-add-text">
              <img
                style={{ cursor: checkLimit && 'not-allowed' }}
                onClick={() => onClickCustomAddMore({ AddMoreStepId: stepId })}
                src={plus}
                alt="plus"
              ></img>
              <TextArea
                disabled={checkLimit}
                value={(customAddMore && customAddMore.data) || ''}
                onChange={(e) => onChangeCustomAddMore(e, stepId)}
                autoSize={{ minRows: 1 }}
                bordered={false}
                className="add-text"
                placeholder="Add your own"
                maxLength={2000}
              />
              <Button
                type={'primary'}
                disabled={customAddMore && customAddMore.data && customAddMore.data.length > 0 ? false : true}
                onClick={() => onClickCustomAddMore({ AddMoreStepId: stepId })}
              >
                Add +
              </Button>
            </div>
          </div>

          <div className={`action-buttons `}>
            <div className="field-button">
              {actionButtons({
                modelSelect,
                toolId,
                stepId,
                type,
                currentEditActiveStep,
                generateMoreBtn,
                currentToolId,
                previousStepId,
                activeStep,
              })}

              {buttonText && !currentEditActiveStep && (
                <Button
                  onClick={showSummaryDesign}
                  disabled={!blogPostStep[stepId]}
                  className="next-btn"
                  block
                  type={BUTTON_PRIMARY}
                  loading={activeStep && toolOutputLoading}
                >
                  {buttonText}
                </Button>
              )}
            </div>
          </div>
        </StepFieldWrapper>
      );
    }
  };

  // This redirect the user to the Editor page when all the steps will be in a editor page.
  const onEditOpen = () => {
    const { title, intro, sections, conclusion } = summaryData;

    // it happens only ones all the steps of longform is complete.
    if (blogPostId && title && intro && sections && conclusion && saveCompletedStepIds && blogPostStep && showStep) {
      let sectionText = '';
      sections.forEach(({ value, sectionTitle }) => {
        sectionText = sectionText + `<h4>${sectionTitle}</h4><p>${value}</p>`;
      });

      const editorString = `<h2 style="text-align: center;" id='title'>${title}</h2><h3>Intro<p id='intro'>${intro}</p></h3>${sectionText}<h3>Conclusion</h3><p>${conclusion}</p>`;
      const storageCompletedSteps = JSON.stringify(saveCompletedStepIds);
      const storageBlogPostStep = JSON.stringify(blogPostStep);
      const summary = JSON.stringify(summaryData);

      dispatch(
        saveDocument({
          uid,
          data: {
            editor: editorString,
            title,
            date: new Date(),
            storageCompletedSteps,
            storageBlogPostStep,
            showStep,
            summary,
            id: blogPostId,
          },
        }),
      );
      navigate(`/editor/?blogPostId=${blogPostId}`);
    }
  };

  const goToSteps = () => {
    setSummaryData(false);
  };

  // Summary page has 2 options 1. draft 2. delete
  const summaryActions = ({ type }) => {
    if (type === 'draft') {
      startOver();
    } else if (type === 'delete') {
      // modal will open
      setWarningVisible({ type: 'summary', open: true });
    }
  };

  // Summary Page has the option to delete the entire document
  const deleteDoc = () => {
    setWarningVisible({ type: 'summary', open: false });
    dispatch(deleteDocument({ uid, documentId: blogPostId }));
    startOver();
  };

  const checkConclusion =
    saveCompletedStepIds &&
    Array.isArray(saveCompletedStepIds) &&
    saveCompletedStepIds.find((savedStepIds) => savedStepIds.stepId === STEP_CONCLUSION)?.selectedOutput;

  const allowAccess =
    CRAFTER_ACCESS.includes(plan) ||
    ENTERPRISE_ACCESS.includes(plan) ||
    isAdminRole(roles) ||
    isFreeUserRole(roles) ||
    (memberTeamsWithOutHold && memberTeamsWithOutHold.length > 0)
      ? true
      : STARTER_ACCESS.includes(plan)
      ? false
      : false;

  return (
    <>
      <PageHeader translation={setTranslationCode} title="Blog Post" />
      {allowAccess && (
        <Spin spinning={readDocumentByIdLoading}>
          <MainFormStyle>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={24}>
                {/* this display the draft  */}
                {checkUnsavedChanged && (
                  <div>
                    {lastDocumentLoading ? (
                      <Spin spinning={lastDocumentLoading}>
                        <UnSavedChangesStyled>
                          <p className="complete-step-text">Checking Last Document Changes</p>
                        </UnSavedChangesStyled>
                      </Spin>
                    ) : (
                      lastDocumentData &&
                      lastDocumentData.editor === undefined && (
                        <UnSavedChangesStyled>
                          <p className="complete-step-text">Last Document Changes</p>
                          <div className="unsaved-action">
                            <Button type="primary" outlined onClick={() => onContinueWithDraft({ type: 'continue' })}>
                              Continue with Draft
                            </Button>

                            <div className="new-blog">
                              <CloseCircleOutlined
                                className="cross-icon"
                                onClick={() => onContinueWithDraft({ type: 'new' })}
                              />
                            </div>
                          </div>
                        </UnSavedChangesStyled>
                      )
                    )}
                  </div>
                )}

                {!summaryData &&
                  LongFormSteps.sort((a, b) => a.order - b.order).map(
                    ({
                      image,
                      header,
                      id,
                      buttonText,
                      toolId,
                      wordsLimit,
                      stepId,
                      field,
                      title,
                      modelSelect,
                      output,
                      generateMoreBtn,
                      customInput,
                      currentToolId,
                      previousStepId,
                    }) => {
                      const checkSavedStepValue =
                        saveCompletedStepIds &&
                        saveCompletedStepIds.find((savedStepIds) => savedStepIds.stepId === stepId);
                      const activeStep = showStep === stepId || selectedStepEdit === stepId;
                      const currentEditActiveStep = selectedStepEdit === stepId;
                      const showRemaingSteps = showStep !== stepId && checkSavedStepValue === undefined;

                      const isSelectedOutputIsArray =
                        checkSavedStepValue && Array.isArray(checkSavedStepValue.selectedOutput);
                      const { multipleSelect, minPerSection } = field;
                      let paragraphBuilderPoints = null;
                      if (
                        stepId === STEP_PARAGRAGH_POINT &&
                        checkSavedStepValue &&
                        checkSavedStepValue.selectedOutput
                      ) {
                        const uniqueSectionId = [
                          ...new Set(checkSavedStepValue.selectedOutput.map((item) => item.sectionId)),
                        ];
                        paragraphBuilderPoints = uniqueSectionId.map((id) => {
                          let temp = [];
                          let section_Title = null;
                          checkSavedStepValue.selectedOutput
                            .filter(({ sectionId }) => sectionId === id)
                            .forEach(({ value, sectionTitle }) => {
                              temp.push(value);
                              section_Title = sectionTitle;
                            });

                          return {
                            sectionTitle: section_Title,
                            value: temp,
                          };
                        });
                      }

                      return (
                        <div>
                          {/* This is saved Step display */}
                          {checkSavedStepValue && checkSavedStepValue.selectedOutput && (
                            <>
                              <div className="output" data={id}>
                                <div className="output-header-wrapper">
                                  <Avatar size={24} className="check-icon" icon={<CheckOutlined />} />
                                  {isSelectedOutputIsArray ? (
                                    <div style={{ width: '100%' }}>
                                      <p className="output-header">
                                        {output.header}
                                        <ReactSVG
                                          className="edit-icon"
                                          src={edit}
                                          onClick={() =>
                                            onEdit({
                                              editStepId: stepId,
                                              craftOutput: checkSavedStepValue.selectedOutput,
                                            })
                                          }
                                        />
                                      </p>
                                      {/* this step is only for   STEP_KEY_TOPICS*/}
                                      {checkSavedStepValue.selectedOutput &&
                                        stepId === STEP_KEY_TOPICS &&
                                        checkSavedStepValue.selectedOutput.map(({ value }, index) => {
                                          return (
                                            <div className={`step-key-topic`}>
                                              <div>
                                                <Text className="title-name">Section {index + 1} </Text>
                                                <Text className="section-value"> {value} </Text>
                                              </div>
                                            </div>
                                          );
                                        })}

                                      {/* this step is only for   STEP_PARAGRAGH_POINT*/}
                                      {stepId === STEP_PARAGRAGH_POINT &&
                                        paragraphBuilderPoints &&
                                        Array.isArray(paragraphBuilderPoints) && (
                                          <div className={`step-paragraph-point`}>
                                            {paragraphBuilderPoints.map(({ sectionTitle, value }, index) => {
                                              return (
                                                <div>
                                                  <div className="section-title">
                                                    <Text className="title-name">Sections {index + 1} </Text>
                                                    <Text className="section-value"> {sectionTitle} </Text>
                                                  </div>
                                                  <div className="point-wrapper">
                                                    {value &&
                                                      value.map((point) => {
                                                        return <Text className="points">{point}</Text>;
                                                      })}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        )}
                                      {/* this step is only for   STEP_KEY_TOPICS*/}
                                      {checkSavedStepValue.selectedOutput &&
                                        stepId === STEP_PARAGRAPH_BUILDER &&
                                        checkSavedStepValue.selectedOutput.map(({ value }, index) => {
                                          return (
                                            <div className={`step-key-topic`}>
                                              <div>
                                                <Text className="section-value"> {value} </Text>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  ) : (
                                    <div style={{ width: '100%' }}>
                                      <p className="output-header">
                                        {output.header}
                                        <ReactSVG
                                          className="edit-icon"
                                          src={edit}
                                          onClick={() =>
                                            onEdit({
                                              editStepId: stepId,
                                              craftOutput: checkSavedStepValue.selectedOutput,
                                            })
                                          }
                                        />
                                      </p>
                                      <p className="output-content">{checkSavedStepValue.selectedOutput}</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}

                          {(activeStep || currentEditActiveStep) && (
                            <LongFormWrapper stepActive={activeStep} stepId={stepId}>
                              <div className="form-wrapper">
                                <div className="form-container">
                                  <Avatar size={24} className="avatarStep">
                                    <Text className="stepCount"> {id} </Text>
                                  </Avatar>
                                  <div>
                                    <p className="form-header">{header}</p>
                                    <p className="form-title">{title}</p>
                                  </div>
                                </div>
                                {currentEditActiveStep && (
                                  <ReactSVG
                                    className="edit-icon"
                                    src={cross}
                                    onClick={() =>
                                      onCancelEdit({
                                        editStepId: stepId,
                                        craftOutput: checkSavedStepValue.selectedOutput,
                                      })
                                    }
                                  />
                                )}
                              </div>

                              <div className={'content-wrapper'}>
                                <div className="field">
                                  {populateField({
                                    buttonText,
                                    field,
                                    wordsLimit,
                                    stepId,
                                    title,
                                    header,
                                    image,
                                    toolId,
                                    modelSelect,
                                    activeStep,
                                    generateMoreBtn,
                                    customInput,
                                    currentToolId,
                                    previousStepId,
                                    currentEditActiveStep,
                                    minPerSection,
                                  })}
                                </div>
                              </div>
                              {stepId === STEP_PARAGRAPH_BUILDER && (
                                <StepFieldWrapper>
                                  <div className="action-container">
                                    {width > 768 && <Divider />}
                                    <div className={`action-buttons`}>
                                      <div className="field-button">
                                        {actionButtons({
                                          modelSelect,
                                          toolId,
                                          stepId,
                                          currentEditActiveStep,
                                          buttonText,
                                          activeStep,
                                          multipleSelect,
                                          minPerSection,
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </StepFieldWrapper>
                              )}
                            </LongFormWrapper>
                          )}
                          {showRemaingSteps && (
                            <LongFormWrapper stepActive={activeStep}>
                              <div className="form-wrapper">
                                <div className="form-container">
                                  <Avatar size={24} className={'avatarStep'}>
                                    <Text className="stepCount"> {id} </Text>
                                  </Avatar>
                                  <div>
                                    <p className="form-header">{header}</p>
                                    <p className="form-title">{title}</p>
                                  </div>
                                </div>
                              </div>
                            </LongFormWrapper>
                          )}
                        </div>
                      );
                    },
                  )}
                {summaryData && (
                  <SummaryPage summaryData={summaryData} goToSteps={goToSteps} summaryActions={summaryActions} />
                )}

                {checkConclusion && !summaryData && (
                  <div className="summary-button">
                    <Button onClick={showSummaryDesign} className="next-btn" type={BUTTON_PRIMARY}>
                      Go to summary page
                    </Button>
                  </div>
                )}

                {/* Open editor button is disable, once it has the summary data it will be active */}
                <OpenEditorStyled highlight={summaryData}>
                  <p className="complete-step-text">Complete the steps above...</p>
                  <Button editButton disabled={!summaryData} className="edit-button" onClick={onEditOpen}>
                    <div>
                      <img src={edit} alt="edit" />
                      <span>Open Editor</span>
                    </div>
                  </Button>
                </OpenEditorStyled>
              </Col>
            </Row>
            <WarningPopUp
              warningVisible={warningVisible.open}
              type={warningVisible.type}
              onEditInput={onEditInput}
              handleVisibleChange={handleVisibleChange}
              deleteDoc={deleteDoc}
            />
          </MainFormStyle>
        </Spin>
      )}
      {!allowAccess && (
        <Result
          status="403"
          title="Limited Access"
          subTitle="Sorry, you are not authorized to access this page."
          extra={
            <Button type="primary" onClick={() => navigate('/settings/billing')}>
              Upgrade Now
            </Button>
          }
        />
      )}

      <BillingModal
        open={showBillingModal.modalVisible}
        onRedirectClick={onRedirectClick}
        onClose={onBillingModalClose}
        messageType={showBillingModal.messageType}
        wordsLimit={showBillingModal.limit}
      />
    </>
  );
};

export default LongForm;

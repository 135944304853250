/* ------------------------------------------------------ */
/*                    Account Settings                    */
/* ------------------------------------------------------ */

/**
 *
 * User wants to change the Email address.
 *
 * 2 Types of authentication type
 * 1. password
 * 2. google
 *
 * email/password is striaght forward. it does require the password to verify.
 * Authentication Modal to verify the password
 *
 *
 * Google need the autentications to change the email.
 * the new email is whether the google email or some other email then the process is change.
 * If your new email is also associated with google, you can simply Sign In with your new Google account.
 * If your new email is other than Google then we will send you email to reset the password.
 * After resetting your password you can login again.
 *
 */

import React, { useState } from 'react';
import { Col, Form, Input, Row, Typography, notification, Radio, Space } from 'antd';
import { SettingsWrapStyled } from './styled';
import { Helmet } from 'react-helmet';
import GoogleIcon from '../../../static/settings/google.svg';
import MailIcon from '../../../static/settings/mail.svg';
import { Button } from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_TYPE } from '../../../constants';
import { PAGETILE } from '../../../constants/content';

import { changeEmail, changeProfileFields, closeUserAccount } from '../../../redux/AccountSettings/actionCreator';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { WarningOutlined } from '@ant-design/icons';
import { GOOGLE_PROVIDER, PASSWORD_PROVIDER } from '../../../constants';
import { Modal } from '../../../components/Modal';

const AccountSettings = () => {
  const dispatch = useDispatch();

  const { Text, Title, Paragraph } = Typography;

  const [form] = Form.useForm();
  const { emailEditLoading, closeAccountLoading } = useSelector((state) => state.accountSettings);
  const { email } = useSelector((state) => state.fb.profile);
  const { providerData } = useSelector((state) => state.fb.auth);

  const [passwordAuthModal, setPasswordAuthModal] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [confirmText, setConfirmText] = useState('');

  const accountCredentialType = providerData && providerData.length > 0 && providerData[0].providerId;

  const editAccountForm = ({ newEmail, isGoogleAccount }) => {
    if (accountCredentialType === GOOGLE_PROVIDER) {
      dispatch(
        changeEmail({
          newEmail,
          isGoogleAccount,
          credentails: {
            provider: accountCredentialType,
          },
        }),
      );
    }
    if (accountCredentialType === PASSWORD_PROVIDER) {
      setModalType(MODAL_TYPE.CHANGE_USERNAME_EMAIL);
      setPasswordAuthModal(true);
    }
  };

  const handleCancel = (e) => {
    form.resetFields();
  };

  const handlePasswordAuth = async () => {
    if (modalType === MODAL_TYPE.CHANGE_USERNAME_EMAIL) {
      const { newEmail } = form.getFieldsValue();
      dispatch(
        changeEmail({
          newEmail,
          credentails: {
            provider: accountCredentialType,
            currentPassword,
          },
        }),
      );
    }
    if (modalType === MODAL_TYPE.CLOSE_ACCOUNT) {
      dispatch(
        closeUserAccount({
          credentails: {
            provider: accountCredentialType,
            currentPassword,
          },
        }),
      );
    }
    setCurrentPassword('');
    setPasswordAuthModal(false);
  };

  const handleModalCancel = () => {
    setPasswordAuthModal(false);
  };

  const onCurrentPassword = (e) => {
    const currentPass = e.target.value;
    setCurrentPassword(currentPass);
  };
  const onConfirmTextChange = (e) => {
    setConfirmText(e.target.value);
  };

  const handleConfirmModalCancel = () => {
    setIsConfirmVisible(false);
  };

  // confirmation to delete the account
  const handleConirmModalOK = () => {
    if (confirmText === 'delete-everything-oh-nose') {
      dispatch(closeUserAccount());
    } else {
      notification.error({
        message: 'The confirmation text is not correct.',
      });
    }
  };

  const closeAccount = () => {
    // setIsConfirmVisible(true);
    setModalType(MODAL_TYPE.CLOSE_ACCOUNT);
    if (accountCredentialType === GOOGLE_PROVIDER) {
      dispatch(
        closeUserAccount({
          credentails: {
            provider: accountCredentialType,
          },
        }),
      );
    } else if (accountCredentialType === PASSWORD_PROVIDER) {
      setPasswordAuthModal(true);
    }
  };

  const emailHelperMessage = accountCredentialType === PASSWORD_PROVIDER ? false : true;
  const currentEmailTypeIcon = accountCredentialType === GOOGLE_PROVIDER ? GoogleIcon : MailIcon;

  return (
    <>
      <Helmet>
        <title>{PAGETILE('Account')}</title>
      </Helmet>
      <SettingsWrapStyled>
        <Title>Account Settings</Title>
        <Text className="profile">Manage your account</Text>

        <Form layout="vertical" form={form} name="edit-account" onFinish={editAccountForm}>
          <Row>
            <Col xxl={15} lg={20} md={24} xs={24}>
              <div className="google-message">
                <div className="social-accounts">
                  <div className="google">
                    <img src={currentEmailTypeIcon} alt="Google-Icon" />
                    <div>
                      <p>Current Email</p>
                      <Text style={{ marginTop: '0px' }}> {email}</Text>
                    </div>
                  </div>
                </div>

                {emailHelperMessage && (
                  <>
                    <div className="google-wrapper">
                      <img className="google-icon" src={GoogleIcon} alt="mail-Icon" />
                      Log in with Google enabled
                    </div>
                    <div className="notice-wrapper">
                      <div className="notice">
                        <WarningOutlined style={{ fontSize: '32px', marginTop: '5px', color: ' rgb(255, 139, 0)' }} />
                        <div className="notice-text">
                          <Title level={2}>Read Carefully</Title>
                          <Paragraph>
                            <Text>
                              If your new email is also associated with google, you can simply Sign In with your new
                              Google account.
                            </Text>
                            <Text>
                              If your new email is other than Google then we will send you email to reset the password.
                              After resetting your password you can login again.
                            </Text>
                          </Paragraph>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {emailHelperMessage && (
                <Form.Item
                  name="isGoogleAccount"
                  label="New Email is Associated with Google"
                  rules={[{ required: emailHelperMessage, message: 'Please select one option' }]}
                >
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              <Form.Item
                name="newEmail"
                rules={[
                  { type: 'email', message: 'This is not a valid email' },
                  { required: true, message: 'Please enter email' },
                ]}
                label="New Email"
              >
                <Input placeholder="enter new email" />
              </Form.Item>
              <Text>
                {emailHelperMessage &&
                  'To change email, you are required to authenticate your current email address. Please re verify your current login credentials via the popup. '}{' '}
              </Text>
              <Text type="warning">
                {emailHelperMessage && 'When new email successfully update, you will be automatically logout.'}
              </Text>

              <Form.Item>
                <Space>
                  <Button size="large" loading={emailEditLoading} htmlType="submit" type="primary">
                    Save Change
                  </Button>
                  <Button size="large" onClick={handleCancel} type="light">
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className="account-form-bottom">
          <Row justify="start">
            <Col xs={24}>
              <div className="account-closing">
                <Row>
                  <Col lg={18} md={24} sm={18} xs={24}>
                    <Title> Close Account</Title>
                    <p>Delete Your Account and Account data.</p>
                    <Text type="danger">
                      {emailHelperMessage &&
                        'To change email, you are required to authenticate your current email address. Please re verify your current login credentials via the popup.'}
                    </Text>
                    <br />
                  </Col>
                  <Col xs={24}>
                    <Button loading={closeAccountLoading} onClick={closeAccount} type="danger">
                      Close Account
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>

        {/* Authentication Modal */}
        <Modal
          title="Authentication Required"
          open={passwordAuthModal}
          onOk={handlePasswordAuth}
          onCancel={handleModalCancel}
        >
          <Input.Password
            value={currentPassword}
            onChange={onCurrentPassword}
            placeholder="Enter your current password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Modal>

        {/* Delete Modal */}
        <Modal
          title="Are you sure sure ?"
          open={isConfirmVisible}
          onOk={handleConirmModalOK}
          onCancel={handleConfirmModalCancel}
        >
          <Paragraph>
            Bad things will happen if you don’t read.
            <p>
              This <Text strong>cannot</Text> be undone.
            </p>
            This will permanently delete your workspaces, recents, favourites, all your settings and remove all team
            members.
          </Paragraph>
          <Paragraph>
            This will not change your billing plan. If you want to downgrade, you can do it in your Billing Settings.
          </Paragraph>
          <Paragraph>
            Please type <Text strong>delete-everything-oh-nose</Text>  to confirm.
          </Paragraph>
          <Input
            value={confirmText}
            onChange={onConfirmTextChange}
            placeholder="Enter confirmation text"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Modal>
      </SettingsWrapStyled>
    </>
  );
};

export default AccountSettings;

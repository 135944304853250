/* eslint-disable jsx-a11y/control-has-associated-label */

/* ------------------------------------------------------ */
/*                   PLAN BILLING ADMIN                   */
/* ------------------------------------------------------ */

/**
 * There are 3 type of plans
 * 1. Crafter  - unlimited
 * 2. Starter  - limited
 * 3. Trail    - limited
 *
 *
 * Craftly Admin has the control to change the following parameters in Starter and Trial plan
 * 1. Total words
 * 2. Total worksapce
 *
 * Firestore
 * Collection: stripe-plan
 * Document: trial
 */

import React from 'react';
import { Row, Col } from 'antd';
import { Main } from '../styled';
import TrialPlan from './overview/TrialPlanBilling';
import StarterPlan from './overview/StarterPlanBilling';
import PageTitle from '../../components/PageTitle';

const PlanBilling = () => {
  return (
    <>
      <PageTitle title="Billing Plan"> </PageTitle>
      <Main>
        <Row gutter={10}>
          <Col sm={24} lg={12}>
            <TrialPlan />
          </Col>
          <Col sm={24} lg={12}>
            <StarterPlan />
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default PlanBilling;

import React from 'react';
import { Col, Row } from 'antd';
import './AuthRightSideStyle.css';
import { ReactSVG } from 'react-svg';
import Logo from '../../../../../static/auth/clogo.svg';

export const AuthRightSide = ({ children }) => {
  return (
    <>
      <div className="sidebar-section">
        <Row className="">
          <Col xs="24" className="sidebar-content">
            <ReactSVG className="craftly-logo" src={Logo} />
            {children}
          </Col>
        </Row>
      </div>
    </>
  );
};

/* ------------------------------------------------------ */
/*                       Deleted                       */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * The user is no longer able to redirect to any page when a Craftly admin DELETE the user from the user management user profile.
 * The user will only be able to see this page.
 * To leave the account, click the Signout option.
 */

import React from 'react';
import { DeletedWrapper } from './styled';
import { Row, Col, Typography } from 'antd';
import Signout from '../../components/Signout';

const { Title, Link } = Typography;

const Deleted = () => {
  return (
    <DeletedWrapper>
      <Row justify="space-between">
        <Col xs={6} sm={3} md={2}>
          <img
            className="logo"
            style={{ width: '100%', margin: '2rem' }}
            src={require('../../static/general/craftly_final.png')}
            alt="logo"
          />
        </Col>
        <Col>
          <div style={{ margin: '2rem' }}>
            <Signout />
          </div>
        </Col>
      </Row>

      <div className="box-wrapper">
        <div className="verify-box">
          <Title level={4} className="early-text">
            Your account has been deleted. If you believe this is a mistake, Please contact our administration at{' '}
            <Link>support@craftly.ai</Link>
          </Title>
        </div>
      </div>
    </DeletedWrapper>
  );
};

export default Deleted;

import styled from 'styled-components';

const PageTitleStyled = styled.div`
  padding: 26px 30px;
  font-size: 1.5rem;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;

  .left-side {
    display: flex;
    align-items: center;
    overflow: hidden;
    .page-title {
      color: rgb(40, 43, 63);
      font-size: 25px;
      padding-right: 15px;
      .page-title-icon {
        margin: 0 1rem;
      }
      .page-title-tags {
        margin: 0 1rem;
      }
    }
    .page-subtitle {
      margin-right: 12px;
      color: #5a5f7d;
      font-size: 14px;
      line-height: 1.5715;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .page-back-icon {
      font-size: 1rem;
      cursor: pointer;
    }
  }
  .right-side {
    margin: 4px 0;
    white-space: nowrap;
  }

  .title-counter {
    @media only screen and (max-width: 575px) {
      margin-bottom: 16px;
    }
  }
  .user-search {
    .ant-input-wrapper {
      margin-right: 3rem;
    }
  }
`;

export { PageTitleStyled };

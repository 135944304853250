/* ------------------------------------------------------ */
/*                     Main App Header                    */
/* ------------------------------------------------------ */

/**
 * Main App Header
 *
 * It contains the tool search bar in web view, user profile avatar, settings button and etc
 * The tool search results appears in the dropdown.
 *
 * In mobile view there are only 2 things are visible
 * 1. mobile side bar collapse icon
 * 2. logo in the center
 * 3. user profile button
 *
 * search-tool input UI hides in mobile view
 *
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Typography } from 'antd';
import { HeaderWrapStyled, PopoverStyled } from './styled';
import UserAvatar from '../UserAvatar';
import LearnPopup from './overview/LearnPop';
import PricePop from './overview/PricePop';
import HeaderSearchTools from '../HeaderSearchTools';
import { useBeforeRender } from '../../hooks/viewPort';

import ArrowDown from '../../static/general/arrowDown';
import Menu from '../../static/header/Menu.svg';
import Logo from '../../static/header/logo2.png';

const { Text } = Typography;

const AppHeader = ({ handleSidebar, showTopBanner }) => {
  const navigate = useNavigate();

  const onlogoClick = () => {
    navigate('/category');
  };

  useBeforeRender(() => {
    window.addEventListener('error', (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div');
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) resizeObserverErr.className = 'hide-resize-observer';
        if (resizeObserverErrDiv) resizeObserverErrDiv.className = 'hide-resize-observer';
      }
    });
  }, []);

  return (
    <HeaderWrapStyled className="sidebar-newtop" showTopBanner={showTopBanner}>
      <Row>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24} className="logo">
            <Button type="link" onClick={() => handleSidebar({ mobMenuClicked: true })}>
              <img src={Menu} alt="menu" />
            </Button>
            <img src={Logo} alt="Logo" className="craftly-logos" onClick={() => onlogoClick()} />
            {window.innerWidth < 768 && (
              <div className="nav-author">
                <UserAvatar isMobile={true} />
              </div>
            )}
          </Col>
        </Row>

        <Row className="top-header">
          <Col xl={5} lg={6} md={10} className="pricing-learn">
            {/* only visible in web view  */}

            <PopoverStyled overlayClassName="PopoverContainerPrice" placement="bottomRight" content={<PricePop />}>
              <div className="pricing">
                <Text>Pricing</Text>
                <ArrowDown width="21" height="21" color="#848484" />
              </div>
            </PopoverStyled>
            <PopoverStyled overlayClassName="PopoverContainerLearn" placement="bottomRight" content={<LearnPopup />}>
              <div className="learn">
                <Text>Learn</Text>
                <ArrowDown width="21" height="21" color="#848484" />
              </div>
            </PopoverStyled>
          </Col>
          <Col xl={12} lg={8} md={0} sm={0} xs={0}>
            <HeaderSearchTools showSearchOptionsInDropdown={true} placeholder="Search Tools" />
          </Col>
          <Col xl={7} lg={10} md={14} sm={0} xs={0}>
            <UserAvatar />
          </Col>
        </Row>
      </Row>
    </HeaderWrapStyled>
  );
};

export default AppHeader;

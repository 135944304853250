import styled from 'styled-components';
import { Switch } from 'antd';

const Content = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 2px #888;
  a i,
  a svg,
  a img {
    ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 8px;
  }
  a {
    display: block;
    color: #888;
    padding: 6px 12px;
  }
  a:hover {
    background: ${({ theme }) => theme['primary-color']}10;
    color: ${({ theme }) => theme['primary-color']};
  }
  .dropdown-theme-2 {
    a:hover {
      background: ${({ theme }) => theme.pink}10;
      color: ${({ theme }) => theme.pink};
    }
  }
`;

const SwitchStyledWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 574px) {
    padding-top: 0px;
  }
  .position-text {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-size: 1.13rem;
    line-height: 1.69rem;
    text-align: center;
    color: ${({ theme }) => theme['dark-grey-color']};
    padding: 0 1rem;
  }
  .active {
    color: ${({ theme }) => theme['dark-desaturated-blue']};
  }
`;

const SwitchStyled = styled(Switch)`
  &&&.ant-switch {
    line-height: 32px;
    width: 65px;
    height: 32px;
    outline: solid;
    outline-color: ${({ theme }) => theme['primary-color']};
    outline-width: 2px;
    border-radius: 15px;
    background: ${({ theme }) => theme['white-color']};

    @media (max-width: 576px) {
      outline: none;
      background: ${({ theme }) => theme['primary-color']};
    }
  }
  &&&.ant-switch-checked {
    background: ${({ theme }) => theme['primary-color']};
    outline: none;
    .ant-switch-handle {
      left: calc(100% - 28px - 2px);
    }
    .ant-switch-handle::before {
      background: ${({ theme }) => theme['white-color']};
    }
  }

  .ant-switch-handle {
    width: 28px;
    height: 28px;
  }
  .ant-switch-handle::before {
    background: ${({ theme }) => theme['primary-color']};
    border-radius: 14px;
    @media (max-width: 576px) {
      background: ${({ theme }) => theme['white-color']};
    }
  }
`;

export { Content, SwitchStyled, SwitchStyledWrapper };

/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import { Typography } from 'antd';
import React from 'react';
import { TabItemData } from './mock';
import { InviteWrap, InviteHeader, InviteContainer, InviteButton } from './styled';
import { Button } from '../../../../components/Button';
import Item from 'antd/lib/list/Item';

const Invite = () => {
  const { Title, Text } = Typography;
  return (
    <InviteContainer>
      <InviteHeader>
        <Title level={2}>A great team starts with great collaboration!</Title>
        <Text className="invite-heading">Invite team members and manage them here</Text>
      </InviteHeader>
      <InviteWrap>
        {TabItemData.map((item) => {
          return (
            <div className="invite-wrap">
              <img src={item.icon} alt="dddd" />
              <Text>{item.title}</Text>
            </div>
          );
        })}
        <Button size="default" type="primary">
          Invite people
        </Button>
      </InviteWrap>
    </InviteContainer>
  );
};

export default Invite;

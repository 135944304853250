const actions = {
  USER_PROJECT_ADD_BEGIN: 'USER_PROJECT_ADD_BEGIN',
  USER_PROJECT_ADD_SUCCESS: 'USER_PROJECT_ADD_SUCCESS',
  USER_PROJECT_ADD_ERR: 'USER_PROJECT_ADD_ERR',

  USER_PROJECT_RECENT_SUCCESS: 'USER_PROJECT_RECENT_SUCCESS',
  USER_PROJECT_FAVORITE_SUCCESS: 'USER_PROJECT_FAVORITE_SUCCESS',

  USER_PROJECT_READ_BEGIN: 'USER_PROJECT_READ_BEGIN',
  USER_PROJECT_READ_SUCCESS: 'USER_PROJECT_READ_SUCCESS',
  USER_PROJECT_READ_ERR: 'USER_PROJECT_READ_ERR',

  USER_PROJECT_UPDATE_BEGIN: 'USER_PROJECT_UPDATE_BEGIN',
  USER_PROJECT_UPDATE_SUCCESS: 'USER_PROJECT_UPDATE_SUCCESS',
  USER_PROJECT_UPDATE_ERR: 'USER_PROJECT_UPDATE_ERR',

  USER_PROJECT_DELETE_BEGIN: 'USER_PROJECT_DELETE_BEGIN',
  USER_PROJECT_DELETE_SUCCESS: 'USER_PROJECT_DELETE_SUCCESS',
  USER_PROJECT_DELETE_ERR: 'USER_PROJECT_DELETE_ERR',

  USER_PROJECT_SINGLE_DATA_BEGIN: 'USER_PROJECT_SINGLE_DATA_BEGIN',
  USER_PROJECT_SINGLE_DATA_SUCCESS: 'USER_PROJECT_SINGLE_DATA_SUCCESS',
  USER_PROJECT_SINGLE_DATA_ERR: 'USER_PROJECT_SINGLE_DATA_ERR',

  USER_PROJECT_SEARCH_BEGIN: 'USER_PROJECT_SEARCH_BEGIN',
  USER_PROJECT_SEARCH_SUCCESS: 'USER_PROJECT_SEARCH_SUCCESS',
  USER_PROJECT_SEARCH_ERR: 'USER_PROJECT_SEARCH_ERR',

  TOOL_HIT_BACKEND_PROGRESS: 'TOOL_HIT_BACKEND_PROGRESS',
  TOOL_HIT_BACKEND_SUCCESS: 'TOOL_HIT_BACKEND_SUCCESS',
  TOOL_HIT_BACKEND_FAILED: 'TOOL_HIT_BACKEND_FAILED',

  TOOL_HIT_NULL: 'TOOL_HIT_NULL',
  FILTER_TOOL_OUPUT: 'FILTER_TOOL_OUPUT',

  TOOL_FAV_LOADING: 'TOOL_FAV_LOADING',

  TEAM_RECENT_PROJECT: 'TEAM_RECENT_PROJECT',
  UPDATE_CRAFT_SUCCESS: 'UPDATE_CRAFT_SUCCESS',

  FAVORITE_PAGE: 'FAVORITE_PAGE',
  FAVORITE_PAGE_SUCCESS: 'FAVORITE_PAGE_SUCCESS',

  RECENT_PAGE: 'RECENT_PAGE',
  RECENT_PAGE_SUCCESS: 'RECENT_PAGE_SUCCESS',

  SELECTED_CURRENT_PROJECT: 'SELECTED_CURRENT_PROJECT',

  CAI_CHAT_HISTORY_START: 'CAI_CHAT_HISTORY_START',
  CAI_CHAT_HISTORY_SUCCESS: 'CAI_CHAT_HISTORY_SUCCESS',
  CAI_CHAT_HISTORY_FAILED: 'CAI_CHAT_HISTORY_FAILED',

  userProjectSearchBegin: () => {
    return {
      type: actions.USER_PROJECT_SEARCH_BEGIN,
    };
  },

  userProjectSearchSuccess: (data) => {
    return {
      type: actions.USER_PROJECT_SEARCH_SUCCESS,
      data,
    };
  },

  userProjectSearchErr: (err) => {
    return {
      type: actions.USER_PROJECT_SEARCH_ERR,
      err,
    };
  },

  userProjectAddBegin: () => {
    return {
      type: actions.USER_PROJECT_ADD_BEGIN,
    };
  },

  userProjectAddSuccess: (data) => {
    return {
      type: actions.USER_PROJECT_ADD_SUCCESS,
      data,
    };
  },

  userProjectRecentSuccess: (recentProject) => {
    return {
      type: actions.USER_PROJECT_RECENT_SUCCESS,
      recentProject,
    };
  },
  userProjectFavoriteSuccess: (favorites) => {
    return {
      type: actions.USER_PROJECT_FAVORITE_SUCCESS,
      favorites,
    };
  },

  userProjectAddErr: (err) => {
    return {
      type: actions.USER_PROJECT_ADD_ERR,
      err,
    };
  },

  userProjectReadBegin: () => {
    return {
      type: actions.USER_PROJECT_READ_BEGIN,
    };
  },

  userProjectReadSuccess: ({ data, recentProject }) => {
    return {
      type: actions.USER_PROJECT_READ_SUCCESS,
      data,
      recentProject,
    };
  },

  userProjectReadErr: (err) => {
    return {
      type: actions.USER_PROJECT_READ_ERR,
      err,
    };
  },

  userProjectUpdateBegin: () => {
    return {
      type: actions.USER_PROJECT_UPDATE_BEGIN,
    };
  },

  userProjectUpdateSuccess: (data) => {
    return {
      type: actions.USER_PROJECT_UPDATE_SUCCESS,
      data,
    };
  },

  userProjectUpdateErr: (err) => {
    return {
      type: actions.USER_PROJECT_UPDATE_ERR,
      err,
    };
  },

  userProjectDeleteBegin: () => {
    return {
      type: actions.USER_PROJECT_DELETE_BEGIN,
    };
  },

  userProjectDeleteSuccess: ({ data, recentProject }) => {
    return {
      type: actions.USER_PROJECT_DELETE_SUCCESS,
      data,
      recentProject,
    };
  },

  userProjectDeleteErr: (err) => {
    return {
      type: actions.USER_PROJECT_DELETE_ERR,
      err,
    };
  },

  userProjectSingleDataBegin: () => {
    return {
      type: actions.USER_PROJECT_SINGLE_DATA_BEGIN,
    };
  },

  userProjectSingleDataSuccess: (data) => {
    return {
      type: actions.USER_PROJECT_SINGLE_DATA_SUCCESS,
      data,
    };
  },

  userProjectSingleDataErr: (err) => {
    return {
      type: actions.USER_PROJECT_SINGLE_DATA_ERR,
      err,
    };
  },

  toolHitBackendProgress: ({ currentBotId }) => {
    return {
      type: actions.TOOL_HIT_BACKEND_PROGRESS,
      currentBotId,
    };
  },

  toolHitBackendSuccess: ({ toolOutput, longFormOutput, highlightRowsId, currentStepOutputId, craftedToolId }) => {
    return {
      type: actions.TOOL_HIT_BACKEND_SUCCESS,
      toolOutput,
      highlightRowsId,
      currentStepOutputId,
      longFormOutput,
      craftedToolId,
    };
  },

  EditCraftContent: ({ editOutput }) => {
    return {
      type: actions.UPDATE_CRAFT_SUCCESS,
      editOutput,
    };
  },

  toolHitBackendFailed: () => {
    return {
      type: actions.TOOL_HIT_BACKEND_FAILED,
    };
  },

  toolOutputNull: () => {
    return {
      type: actions.TOOL_HIT_NULL,
    };
  },
  toolOutputFiltered: ({ filterToolOutputs }) => {
    return {
      type: actions.FILTER_TOOL_OUPUT,
      filterToolOutputs,
    };
  },

  teamRecentProject: ({ availableWorkSpaces, allTeamRecentProject }) => {
    return {
      type: actions.TEAM_RECENT_PROJECT,
      teamRecentProject: {
        availableWorkSpaces,
        allTeamRecentProject,
      },
    };
  },

  userProjectFavLoading: ({ favLoading }) => {
    return {
      type: actions.TOOL_FAV_LOADING,
      favLoading,
    };
  },

  favoritesPageLoading: (allFavoritesPageLoading) => {
    return {
      type: actions.FAVORITE_PAGE,
      allFavoritesPageLoading,
    };
  },
  favoritesPageSuccess: (allFavoritesPage) => {
    return {
      type: actions.FAVORITE_PAGE_SUCCESS,
      allFavoritesPage,
    };
  },

  recentPageLoading: (allRecentPageLoading) => {
    return {
      type: actions.RECENT_PAGE,
      allRecentPageLoading,
    };
  },
  recentPageSuccess: (allRecentPage) => {
    return {
      type: actions.RECENT_PAGE_SUCCESS,
      allRecentPage,
    };
  },

  selectedCurrentProject: ({ selectedCurrentProject }) => {
    return {
      type: actions.SELECTED_CURRENT_PROJECT,
      selectedCurrentProject,
    };
  },

  fetchCaiChatHistoryLoading: () => {
    return {
      type: actions.CAI_CHAT_HISTORY_START,
      caiChatHistoryLoading: true,
    };
  },
  fetchCaiChatHistorySuccess: (caiChatHistoryOutput) => {
    return {
      type: actions.CAI_CHAT_HISTORY_SUCCESS,
      caiChatHistoryOutput,
    };
  },
  fetchCaiChatHistoryFailed: () => {
    return {
      type: actions.CAI_CHAT_HISTORY_FAILED,
    };
  },
};

export default actions;

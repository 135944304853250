/* ------------------------------------------------------ */
/*                     ONBOARDING UI                      */
/* ------------------------------------------------------ */

/**
 * Methodology
 * When user signup and after the trial onboarding page will displayed.
 * This onboarding page is not avialable for the team members.
 *
 * There are 3 steps
 * 1. Company
 * 2. Jam Selection
 * 3. Workspace name    -> in real this is project name
 *
 *
 * Ant-Design Step is used to change the change the Onboarding step
 *
 * Firestore
 * Collection: users
 * Document: <UID>
 * Payload: jam, companyName, visitOnboarding: true
 * 
 * set visitOnboarding true means that user will not have to see onboarding page again in any case scenerio
 * 
 * Firestore
 * Collection: projects
 * Document: <UID>
 * Payload:   projects: firebase.firestore.FieldValue.arrayUnion(data), // this is the object array. contain multiple projects, with project name and project Id.
              recentProject: { project: data.project, projectId: projectId }, // the moment when user creates a new project, we are also setting this project as Recent Project
 *
 */

import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Row, Col, Input, Typography, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomStepWizard, CustomStepContent } from './styled';
import { CustomSteps } from '../../components/CustomSteps';
import { arrayRemove } from '../../utility/utility';
import { boardingSteps } from '../../redux/AccountSettings/actionCreator';
import {
  ADS_ECOMMERCE,
  BLOG_WRITING,
  EMAIL_OUTREACH,
  OTHER,
  SEO,
  SOCIAL_MEDIA,
  STUDENT,
  WEB_COPY,
} from '../../constants/index.js';

const { Title, Text } = Typography;
const checkedIcon = require(`../../static/onboarding/checked.png`);
const unCheckedIcon = require(`../../static/onboarding/unchecked.png`);

const Onboarding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState({
    responsive: 0,
  });
  const { responsive } = state;

  const [jam, setJam] = useState([]);
  const { alreadyName, onBoardingLoading, visitOnboarding } = useSelector((state) => {
    return {
      profile: state.fb.profile,
      alreadyName: state.fb.profile.name,
      visitOnboarding: state.fb.profile.visitOnboarding,
      onBoardingLoading: state.accountSettings.onBoardingLoading,
    };
  });

  useLayoutEffect(() => {
    function updateSize() {
      const width = window.innerWidth;
      setState({ responsive: width });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const [step, setStep] = useState({
    status: 'process',
    isFinished: false,
    current: 1,
    companyName: '',
    projectName: '',
  });

  const { status, current, projectName, companyName } = step;

  const next = () => {
    setStep({
      ...step,
      status: 'process',
      current: current + 1,
    });
  };

  const prev = () => {
    setStep({
      ...step,
      status: 'process',
      current: current - 1,
    });
  };

  // On the last step of the onboarding.
  const done = () => {
    setStep({
      ...step,
      status: 'finish',
      isFinished: true,
      current: 0,
      jam,
    });
  };

  useEffect(() => {
    visitOnboarding && navigate('/');
  }, [navigate, visitOnboarding]);

  useEffect(() => {
    if (step.status === 'finish') {
      dispatch(boardingSteps(step));
    }
  }, [dispatch, step]);

  const onHandleProfile = (event) => {
    setStep({
      ...step,
      [event.target.name]: event.target.value.trimStart(),
    });
  };

  const onJamClick = (jamValue) => {
    jam.includes(jamValue) ? setJam(arrayRemove(jam, jamValue)) : setJam([...jam, jamValue]);
  };

  const validation = [
    {
      step: 0,
      stepError: 'Company name is required',
      value: companyName,
    },
    {
      step: 1,
      stepError: 'Choose at least one!',
      value: jam,
    },
    {
      step: 2,
      stepError: 'Please name your workspace',
      value: projectName,
    },
  ];
  const isMobile = responsive > 575 ? true : false;

  return (
    <>
      <CustomStepWizard>
        {/* Steps is a component, antd design which is customized according to our UI. */}
        <CustomSteps
          isvertical={isMobile && true}
          current={0}
          status={status}
          direction={isMobile ? 'vertical' : 'horizontal'}
          lastButton="Create"
          lastButtonLoading={onBoardingLoading}
          validation={validation}
          steps={[
            {
              title: 'Step 01',
              description: isMobile && 'Company',
              content: (
                <CustomStepContent>
                  <Title className="title" level={2}>
                    Hey Human! 👋 <br />
                    Welcome to Craftly.AI
                  </Title>
                  <Text className="statement">
                    We won’t keep you long! We have 3 questions to help us craft your experience.
                  </Text>
                  <div className="question">
                    <Row justify="start" align="start" style={{ flexDirection: 'column' }}>
                      <Col xs={24} sm={24} style={{ margin: '0px 0px 0.75rem' }}>
                        <Text className="label-onboarding">
                          Company Name <span className="required-staric">*</span>
                        </Text>
                      </Col>
                      <Col xs={24} sm={24}>
                        <Input
                          autoFocus
                          size="large"
                          className="name-onboarding-input"
                          value={step.companyName}
                          onChange={onHandleProfile}
                          name="companyName"
                          placeholder="Enter company name"
                        />
                      </Col>
                    </Row>
                  </div>
                </CustomStepContent>
              ),
            },
            {
              title: 'Step 02',
              description: isMobile && 'Workspace',
              content: (
                <CustomStepContent>
                  <Title className="title" level={3}>
                    Nice to meet you, {alreadyName} 🤝 <br /> I’m Cai! What's your jam?{' '}
                  </Title>
                  <Text className="statement">Tell us what you do so we can better curate your experience</Text>
                  <div className="options">
                    <Row gutter={15} className="CardRow">
                      <Col xs={24} sm={12}>
                        <Card
                          onClick={() => onJamClick(BLOG_WRITING)}
                          className={jam.includes(BLOG_WRITING) && 'selected'}
                        >
                          <img
                            className="image-icon"
                            src={jam.includes(BLOG_WRITING) ? checkedIcon : unCheckedIcon}
                            alt="step"
                          />
                          <Text className="noselect option-text">{BLOG_WRITING}</Text>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Card
                          onClick={() => onJamClick(SOCIAL_MEDIA)}
                          className={jam.includes(SOCIAL_MEDIA) && 'selected'}
                        >
                          <img
                            className="image-icon"
                            src={jam.includes(SOCIAL_MEDIA) ? checkedIcon : unCheckedIcon}
                            alt="step"
                          />
                          <Text className="noselect option-text">{SOCIAL_MEDIA}</Text>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Card
                          onClick={() => onJamClick(EMAIL_OUTREACH)}
                          className={jam.includes(EMAIL_OUTREACH) && 'selected'}
                        >
                          <img
                            className="image-icon"
                            src={jam.includes(EMAIL_OUTREACH) ? checkedIcon : unCheckedIcon}
                            alt="step"
                          />
                          <Text className="noselect option-text">{EMAIL_OUTREACH}</Text>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Card
                          onClick={() => onJamClick(ADS_ECOMMERCE)}
                          className={jam.includes(ADS_ECOMMERCE) && 'selected'}
                        >
                          <img
                            className="image-icon"
                            src={jam.includes(ADS_ECOMMERCE) ? checkedIcon : unCheckedIcon}
                            alt="step"
                          />
                          <Text className="noselect option-text">{ADS_ECOMMERCE}</Text>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Card onClick={() => onJamClick(WEB_COPY)} className={jam.includes(WEB_COPY) && 'selected'}>
                          <img
                            className="image-icon"
                            src={jam.includes(WEB_COPY) ? checkedIcon : unCheckedIcon}
                            alt="step"
                          />
                          <Text className="noselect option-text">{WEB_COPY}</Text>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Card onClick={() => onJamClick(SEO)} className={jam.includes(SEO) && 'selected'}>
                          <img
                            className="image-icon"
                            src={jam.includes(SEO) ? checkedIcon : unCheckedIcon}
                            alt="step"
                          />
                          <Text className="noselect option-text">{SEO}</Text>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Card onClick={() => onJamClick(STUDENT)} className={jam.includes(STUDENT) && 'selected'}>
                          <img
                            className="image-icon"
                            src={jam.includes(STUDENT) ? checkedIcon : unCheckedIcon}
                            alt="step"
                          />
                          <Text className="noselect option-text">{STUDENT}</Text>
                        </Card>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Card onClick={() => onJamClick(OTHER)} className={jam.includes(OTHER) && 'selected'}>
                          <img
                            className="image-icon"
                            src={jam.includes(OTHER) ? checkedIcon : unCheckedIcon}
                            alt="step"
                          />
                          <Text className="noselect option-text">{OTHER}</Text>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </CustomStepContent>
              ),
            },
            {
              title: 'Step 03',
              description: isMobile && 'Workspace',
              content: (
                <CustomStepContent>
                  <Title className="title" level={2}>
                    Let’s start crafting! 👍 <br />
                    Name your workspace:
                  </Title>
                  <Text className="statement">Your workspace is the place where all your projects will live. </Text>
                  <div className="question">
                    <Row justify="start" align="start" style={{ flexDirection: 'column' }}>
                      <Col xs={24} style={{ paddingBottom: '0.5rem' }}>
                        <Text className="label-onboarding">
                          My Workspace: <span className="required-staric">*</span>
                        </Text>
                      </Col>
                      <Col xs={24}>
                        <Input
                          size="large"
                          className="name-onboarding-input"
                          onChange={onHandleProfile}
                          name="projectName"
                          placeholder="Enter Workspace name"
                        />
                      </Col>
                    </Row>
                  </div>
                </CustomStepContent>
              ),
            },
          ]}
          onNext={next}
          onPrev={prev}
          onDone={done}
        />
      </CustomStepWizard>
    </>
  );
};

export default Onboarding;

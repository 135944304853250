import Styled from 'styled-components';

const AuthWrapper = Styled.div`
   width: 100%;
   
  .heading {
    color:${({ theme }) => theme['swamp']};
    font-size: 24px;
    margin-bottom: 22px;
    font-weight: 400;
    @media only screen and (max-width: 576px){
        display: flex;
        justify-content: center;
    }
  }
  
  .verify {
    margin-top: 1.5rem;
    .existing-user {
      margin-top: 1rem;
      font-size: 15px;
      font-weight: normal;
      .mail-link {
        padding: 5px;
      }
    }
  }

  .verify-statement{
    margin-bottom: 1.5rem;
  }
  .verify-statement-code {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
  }

  .subheading{
    font-size: 14px;
    color:${({ theme }) => theme['auth-subheading']};
    margin-top: 13px;
  }
  .code-sent {
    margin-top: 10px; 
  } 
  .auth-notice {
    margin-top: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    color: #8c8c8c;
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
  }
  
  .auth-contents {
    width: 100%;
    display: flex;
    align-items: center;
    
    @media only screen and (max-width: 767px){
      text-align: center;
      margin-bottom: 10px;
    }
     
    .verify-line-divider {
      margin-top: 24px ;
      margin-bottom: 5px; 
     }
    
    form {
      margin: auto;
      width: 400px;
      @media only screen and (max-width: 576px){
        width: 100%;
      }
      .ant-form-item-explain.ant-form-item-explain-error {
        padding-top: 10px;
        padding-bottom: 5px;
      }

      .ant-form-item {
        margin-bottom: 18px;
        @media only screen and (min-width: 1536px){
          margin-bottom: 34px;
        }
        .ant-form-item-control-input-content {
           .ant-input {
            background-color: #f5f5f5;
            border: none;
            padding: 1rem 1.3rem;
          }
          .ant-input-affix-wrapper {
            background-color: #f5f5f5;
            padding: 0px 15px 0px 0px;
            border: none;
             
          }
        }
      } 
       
      .auth-form-action{
        margin-bottom: 30px;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 379px){
          flex-flow: column;
          .forgot-pass-link{
            margin-top: 15px;
          } 
        }
        .keep-logged {
          color: #8c8c8c;
        }
      }
      .phone-input {
        width: 100%;
        min-width: 0;
        padding: 12px 11px;
        color: #272B41;
        font-size: 14px;
        line-height: 1.5715;
        background-color: #fff;
        background-image: none;
        border: 1px solid #E3E6EF;
        border-radius: 4px;
        transition: all 0.3s;
        display: inline-flex;
        min-height: 48px;
        padding-left: 3rem;
      }
      .terms-check{
        margin-top: 1.7rem;
        display: flex;
        justify-content: space-between;
        .ant-form-item-control-input {
          min-height: 12px;
        }
      }
       
    }
    #forgotPass{
      .forgot-text{
        font-size: 15px;
        margin-top: 25px;
        color: #8c8c8c;
        margin: 2rem 0px;
      } 
    }

    .auth-social {
      cursor: pointer;
      display: flex;
      background-color: transparent;
      border: 1px solid #e6e9ec;
      border-radius: 6px;
      padding: 14px;
      .google-logo {
        display: flex;
        padding-left: 0.5rem;
      }
      .google-icon {
        height: 20px;
        margin: auto;
      }
      .google-text {
        font-weight: 500;
        font-size: 15px;
      }
      .logo-divider {
        border-left: 1px solid #e6e9ec;
        margin: 0px 15px;
        height: 23px;
      }
    } 
    .sign-with-email {
      margin: 1.5rem 0px;
      .sign-with-email-text {
        color: #8c8c8c;
      }
    }
    .social-login {
      display: flex;
      align-items: center;
       
      @media only screen and (max-width: 767px){
        justify-content: center;
      }
      &.signin-social{
        li{
          width:100%;
          a{
            background-color: rgb(255, 255, 255);
            box-shadow: 2.828px 2.828px 12px 0px rgba(0, 0, 0, 0.14);
          }
        }
      }
      li{
        padding:6px;
        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 48px;
          padding: 0 15px;
          border: 1px solid ${({ theme }) => theme['border-color-light']};
          background: ${({ theme }) => theme['bg-color-light']};
          color: ${({ theme }) => theme['text-color']};
          font-weight: 500;
          @media only screen and (max-width: 379px){
            height: 44px;
            padding: 0 12px;
          }
          span:not(.anticon){
            display: inline-block;
            margin-left: 5px;
          }
          svg,
          i{
            width: 20px;
            height: 20px;
          }
          &.google-signup,
          &.google-signin{
            display: flex;
            align-items: center;
            padding: 0 30px;
            @media only screen and (max-width: 379px){
              padding: 0 5px;
            }
            img{
              margin-right: 8px;
              @media only screen and (max-width: 379px){
                margin-right: 4px;
              }
            }
          }
        }
      }
    }
  }
`;

const PhoneNumberModalContentWrapper = Styled.div`

.ant-modal-body {
    border: 2px solid white;
    border-radius: 3px;
}

.content {
    text-align: left;
    display: flex;
    font-size: 16px;  
    margin-top: 2rem; 
    margin-bottom: 2rem;
}
.button-action {
    display: flex;
    justify-content: center;
}

`;

export { AuthWrapper, PhoneNumberModalContentWrapper };

/* ------------------------------------------------------ */
/*                     Tool Categories                    */
/* ------------------------------------------------------ */

/**
 *
 * Tools are store as in Categories.
 *
 * Firestore
 *  Colleciton: tool-categories
 *  Document: <ID>
 *  object :
 *        type: tool-categories
 *        category
 *        categoryName
 *        categoryOrder
 *        icon
 *        iconSmall
 *        optionalFields : [{ toolIds: [<tool-id>] }]
 *
 *
 * Add and View both functionalities can be performed here.
 *
 * On clicking on the card Edit functionality is also present
 *
 */

import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Select, Skeleton, InputNumber, Button, Space, Card, Typography, Spin, Tag } from 'antd';
import { MinusCircleOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Main } from '../styled';
import { getFirestore } from 'redux-firestore';
import {
  addNotificationError,
  addNotificationInfo,
  addNotificationSuccess,
} from '../../components/utilities/notifications';
import { TOOL_CATEGORIES } from '../../constants';
import { useSelector } from 'react-redux';
import PageTitle from '../../components/PageTitle';

const { Text } = Typography;
const { Option } = Select;

const ToolCategories = () => {
  const [addLoading, setAddLoading] = useState(false);
  const [toolCategories, setToolCategories] = useState([]);
  const [toolCategoriesLoading, setToolCategoriesLoading] = useState(false);
  const [categoryEditId, setCategoryEditId] = useState(null);
  const { allTools } = useSelector((store) => store.adminFeatures);

  const [form] = Form.useForm();
  const db = getFirestore();
  // console.log('allTools', allTools);

  useEffect(() => {
    setToolCategoriesLoading(true);
    db.collection('tools-option-fields').onSnapshot((querySnapshot) => {
      var allGroups = [];
      querySnapshot.forEach((doc) => {
        allGroups.push({ ...doc.data(), categoryDocId: doc.id });
      });
      const filterToolCategories = allGroups
        .filter(({ type }) => type === TOOL_CATEGORIES)
        .sort((a, b) => a.categoryOrder - b.categoryOrder);
      setToolCategories(filterToolCategories);
      setToolCategoriesLoading(false);
    });
  }, []);

  const onFinish = (values) => {
    setAddLoading(true);
    const categoryId = values.categoryName.replace(/\s+/g, '-').toLowerCase();
    const { optionFields } = values;

    // the same form is being used for edit and add tool categories

    try {
      if (optionFields) {
        categoryEditId
          ? db
              .collection('tools-option-fields')
              .doc(categoryEditId)
              .set(
                {
                  ...values,
                  categoryId: categoryId,
                  type: TOOL_CATEGORIES,
                },
                { merge: true },
              )
              .then(() => {
                addNotificationInfo('Category Updated');
                setAddLoading(false);
                setCategoryEditId(null);
                form.resetFields();
              })
          : db
              .collection('tools-option-fields')
              .add(
                {
                  ...values,
                  type: TOOL_CATEGORIES,
                  categoryId: categoryId,
                },
                { merge: true },
              )
              .then(() => {
                addNotificationInfo('Category Added');
                setAddLoading(false);
                form.resetFields();
              });
      } else {
        addNotificationError('Min 1 tool is required');
        setAddLoading(false);
      }
      setAddLoading(false);
    } catch (err) {
      setAddLoading(false);
      addNotificationError(err.message);
    }
  };

  const deleteGroupField = (categoryDocId) => {
    db.collection('tools-option-fields')
      .doc(categoryDocId)
      .delete()
      .then(() => {
        addNotificationSuccess('Group Fields Successfully deleted.');
      })
      .catch((error) => {
        addNotificationError(`Error in deleting group fields. ${error.message}`);
      });
  };
  const editGroupField = (groupId) => {
    const getSpecificTool =
      toolCategories &&
      toolCategories.length > 0 &&
      toolCategories.find(({ categoryDocId }) => categoryDocId === groupId);

    form.setFieldsValue({
      groupTitle: null,
    });
    Object.keys(getSpecificTool).forEach((data) => {
      form.setFieldsValue({
        [data]: getSpecificTool[data],
      });
    });
    setCategoryEditId(groupId);
    handleClickScroll();
  };

  const tagRender = (props) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        color={'#9d79fd'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
        }}
      >
        {label}
      </Tag>
    );
  };

  const toolListOptions = allTools.map(({ title, id }) => {
    return { value: id, label: title };
  });

  const getToolName = (toolIdOption) => {
    return allTools.find(({ id }) => id === toolIdOption)?.title;
  };

  // on clicking the trial button.
  const handleClickScroll = () => {
    const element = document.getElementById('tool-category');
    if (element) {
      // 👇 Will scroll smoothly to the top of the billing section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div id={`tool-category`}>
      <PageTitle title="Tools Categories" />
      <Main>
        <Spin spinning={allTools.length === 0}>
          <Row gutter={[10, 10]}>
            <Col sm={24} lg={20}>
              <Form
                form={form}
                name="groupFields"
                onFinish={onFinish}
                onReset={() => {
                  form.resetFields();
                  setCategoryEditId(null);
                }}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  name="categoryName"
                  label="Category Name"
                  rules={[{ required: true, message: 'Category name is required' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="categoryOrder"
                  label="Category order"
                  rules={[{ required: true, message: 'Category order is required' }]}
                >
                  <InputNumber min={1} style={{ width: '10%' }} placeholder="Order" />
                </Form.Item>
                <Form.Item
                  name="icon"
                  label="Large Icon"
                  rules={[{ required: true, message: 'Large icon is required' }]}
                >
                  <Select style={{ width: '30%' }}>
                    <Option value="email">email </Option>
                    <Option value="social">social </Option>
                    <Option value="student">student </Option>
                    <Option value="webcopy">webcopy</Option>
                    <Option value="sales">sales</Option>
                    <Option value="seo">seo</Option>
                    <Option value="blog">blog</Option>
                    <Option value="fun">fun</Option>
                    <Option value="company">company</Option>
                    <Option value="ecommerce">ecommerce</Option>
                    <Option value="real-estate">real-estate</Option>
                    <Option value="img-generation">image-generation</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="iconSmall"
                  label="Small Icon"
                  rules={[{ required: true, message: 'Small icon is required' }]}
                >
                  <Select style={{ width: '30%' }}>
                    <Option value="all-tools">allTools</Option>
                    <Option value="email">email</Option>
                    <Option value="social">social</Option>
                    <Option value="student">student</Option>
                    <Option value="webcopy">webcopy</Option>
                    <Option value="sales">sales</Option>
                    <Option value="seo">seo</Option>
                    <Option value="blog">blog</Option>
                    <Option value="fun">fun</Option>
                    <Option value="company">company</Option>
                    <Option value="ecommerce">ecommerce</Option>
                    <Option value="estate">real-estate</Option>
                    <Option value="ai-image-generation">image-generation</Option>
                  </Select>
                </Form.Item>

                <Form.List name="optionFields">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <div style={{ display: 'flex' }}>
                          <Form.Item
                            {...restField}
                            name={[name, 'toolIds']}
                            rules={[{ required: true, message: 'Missing Tool name' }]}
                            style={{ width: '98%' }}
                            label={'Add Tool'}
                          >
                            <Select
                              mode="multiple"
                              showArrow
                              tagRender={tagRender}
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select Tools"
                              options={toolListOptions}
                            />
                          </Form.Item>

                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </div>
                      ))}
                      {fields.length < 1 && (
                        <Form.Item>
                          <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add Tools
                          </Button>
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.List>

                <Form.Item>
                  <Space>
                    <Button loading={addLoading} type="primary" htmlType="submit">
                      {categoryEditId ? 'Update' : 'Submit'}
                    </Button>
                    <Button loading={addLoading} type="dashed" htmlType="reset">
                      Reset
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>

            <Col sm={24} lg={24}>
              <Row gutter={[10, 10]}>
                {toolCategories &&
                  toolCategories.length > 0 &&
                  toolCategories.map(
                    ({ categoryName, iconSmall, icon, categoryId, categoryOrder, optionFields, categoryDocId }) => {
                      return (
                        <Col sm={24} lg={10}>
                          <Card
                            title={categoryName}
                            extra={
                              <>
                                <span
                                  onClick={() => deleteGroupField(categoryDocId)}
                                  style={{ color: 'red', cursor: 'pointer', marginRight: '10px' }}
                                >
                                  <DeleteOutlined />
                                </span>
                                <span
                                  onClick={() => editGroupField(categoryDocId)}
                                  style={{ color: 'blue', cursor: 'pointer' }}
                                >
                                  <EditOutlined />
                                </span>
                              </>
                            }
                          >
                            <Text> CategoryID: </Text>
                            <Text> {categoryId} </Text>
                            <br />
                            <Text> Category Order: </Text>
                            <Text> {categoryOrder} </Text>
                            <br />
                            <Text>Small Icon: </Text>
                            <Text>{iconSmall}</Text>
                            <br />
                            <Text>Large Icon: </Text>
                            <Text>{icon}</Text>
                            {optionFields.map(({ toolIds }) => {
                              return (
                                <div>
                                  {toolIds &&
                                    toolIds.map((toolId) => {
                                      return (
                                        <Tag
                                          color="blue"
                                          style={{
                                            marginRight: 3,
                                            marginTop: 3,
                                          }}
                                        >
                                          {getToolName(toolId)} <br /> <span style={{ color: 'red' }}>{toolId} </span>
                                        </Tag>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </Card>
                        </Col>
                      );
                    },
                  )}
                {toolCategoriesLoading === true && <Skeleton active />}
              </Row>
            </Col>
          </Row>
        </Spin>
      </Main>
    </div>
  );
};

export default ToolCategories;

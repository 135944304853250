/* ------------------------------------------------------ */
/*                    TEAM DASHBOARD                      */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * Team details are shown here
 * 1. Top 3 tools used by all team members
 * 2. active members
 * 3. ongoing projects
 * 4. all wordds counts of all team members
 *
 * These word counts are only referred when a team member use tool on tool workspace.
 *
 */

import React from 'react';
import { Row, Col, Typography } from 'antd';
import { TeamDashboardWrapper } from '../style';
import { Cards } from '../../../components/Card';
import { Button } from '../../../components/Button';
import { getToolIcon, getToolIconId, minTwoDigits } from '../../../utility/utility';
import { useSelector } from 'react-redux';
import { ArrowRightOutlined } from '@ant-design/icons';
import { TEAM_STATUS } from '../../../constants';
import { TOP_USED_TOOLS } from '../../../constants/content';

const { Text, Title } = Typography;

const TeamDashboard = ({
  teamMembersList,
  wordCraftMemberList,
  teamAdminControlOnly,
  teamProjectsList,
  addNewMember,
  topToolsUsedByTeamMembers,
}) => {
  const { allTools } = useSelector((store) => store.adminFeatures);

  const activeTeamMembers = teamMembersList
    ? teamMembersList.filter(
        ({ teamInviteStatus }) => teamInviteStatus === TEAM_STATUS.ACTIVE || teamInviteStatus === TEAM_STATUS.ACCEPTED,
      )
    : 0;
  const activeMembers = activeTeamMembers ? minTwoDigits(activeTeamMembers.length) : 0;
  const ongoingProjects = teamProjectsList ? minTwoDigits(teamProjectsList.length) : 0;

  // it calculates the word of each team member
  const getWordsByUID = (userUID) => {
    const words =
      wordCraftMemberList &&
      wordCraftMemberList.find(({ uid }) => {
        return userUID === uid;
      });
    return words?.currentTeamCraftedWords || 0;
  };

  // all team member crafts
  const overallWorkCrafted =
    teamMembersList &&
    Array.isArray(teamMembersList) &&
    teamMembersList
      .map(({ teamMemberUID }) => {
        return teamMemberUID ? getWordsByUID(teamMemberUID) : 0;
      })
      .reduce(function (accumulator, currentValue) {
        return accumulator + currentValue;
      }, 0);

  const teamTopTools = topToolsUsedByTeamMembers
    .map(({ tool }) => {
      return allTools.find(({ id }) => id === tool);
    })
    .filter(Boolean);
  return (
    <TeamDashboardWrapper>
      <Title level={5}> Top Tools</Title>

      <Row gutter={25}>
        <Col xs={24} md={8}>
          <div className="team-dashboard-card-box">
            <div className="top-tools">
              {teamTopTools.map(({ id, title, description, img }) => {
                const toolIconId = getToolIconId({ toolId: id, allTools });
                const toolIcon = getToolIcon(toolIconId);
                return (
                  <div className="top-tool">
                    <div className="tool-image">
                      <img src={toolIcon} alt="" />
                    </div>
                    <div className="tool-details">
                      <Title className="title">{title}</Title>
                      <Text className="description"> {description} </Text>
                    </div>
                  </div>
                );
              })}
              {teamTopTools && teamTopTools.length === 0 && TOP_USED_TOOLS}
            </div>
          </div>
        </Col>
        <Col xs={24} md={8}>
          <Row>
            <Col xs={24}>
              <Cards headless>
                <div className="team-details">
                  <div className="img">
                    <img className="icon" src={require(`../../../static/teams/member-icon.png`)} alt="" />
                  </div>
                  <div className="detail">
                    <Title className="count">{activeMembers}</Title>
                    <Text className="active-team"> Active Team Members</Text>
                  </div>
                </div>
              </Cards>
            </Col>
            <Col xs={24}>
              <Cards headless>
                <div className="team-details">
                  <div className="img">
                    <img className="icon" src={require(`../../../static/teams/projects.png`)} alt="" />
                  </div>
                  <div className="detail">
                    <Title className="count">{ongoingProjects}</Title>
                    <Text className="active-team">Ongoing Projects</Text>
                  </div>
                </div>
              </Cards>
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={8}>
          <Row gutter={25}>
            <Col xs={24}>
              <Cards headless>
                <div className="overall-craft">
                  <Title className="amount">{overallWorkCrafted}</Title>
                  <Text className="description"> Overall Work Crafted</Text>
                </div>
              </Cards>
            </Col>
            <Col xs={24}>
              <Cards headless bodypadding={'17px 1rem'}>
                <div className="add-new-member">
                  <img className="add-icon" src={require(`../../../static/teams/add-member.png`)} alt="" />
                  <Button onClick={() => addNewMember()} type="primary">
                    Add New Member <ArrowRightOutlined />
                  </Button>
                </div>
              </Cards>
            </Col>
          </Row>
        </Col>
      </Row>
    </TeamDashboardWrapper>
  );
};

export default TeamDashboard;

import { Typography } from 'antd';
import React from 'react';
import UpgradeProImg from '../../static/sidebar/UpgradeProImg.svg';
import { UpgradeProWrap } from '../../components/Sidebar/styled';
import { Button } from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import { CRAFTER, STARTER, TRIAL } from '../../constants';
import { CRAFTER_UPGRADE, STARTER_UPGRADE } from '../../constants/content';

const { Text } = Typography;

const UpgradePro = ({ plan }) => {
  const navigate = useNavigate();

  const onUpgradeLink = () => {
    let link = '';
    if (plan === STARTER || plan === TRIAL) {
      link = '../settings/billing';
    } else if (plan === CRAFTER) {
      link = '../long-form';
    }
    navigate(link);
  };

  let description = '';
  if (plan === STARTER || plan === TRIAL) {
    description = STARTER_UPGRADE;
  } else if (plan === CRAFTER) {
    description = CRAFTER_UPGRADE;
  }

  let buttonText = '';
  if (plan === STARTER || plan === TRIAL) {
    buttonText = 'Upgrade Now';
  } else if (plan === CRAFTER) {
    buttonText = 'Explore Blog Builder';
  }

  return (
    <UpgradeProWrap>
      <img src={UpgradeProImg} alt="UpgradePro" />
      <Text>{description}</Text>
      <Button size="default" type="light-purple" onClick={onUpgradeLink}>
        {buttonText}
      </Button>
    </UpgradeProWrap>
  );
};

export default UpgradePro;

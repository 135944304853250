/* ------------------------------------------------------ */
/*                   Workspace Selection                  */
/* ------------------------------------------------------ */

import React from 'react';
import { Row, Col, Typography } from 'antd';
import { Helmet } from 'react-helmet';
import { PAGETILE } from '../../../constants/content';
import WorkspaceSelection from '../../WorkspaceSelection';
import { SettingsWrapStyled } from './styled';

const { Text, Title } = Typography;

const WorkspaceSettings = () => {
  return (
    <>
      <Helmet> {PAGETILE('Workspace')}</Helmet>
      <SettingsWrapStyled>
        <Title>Workspace</Title>
        <Text className="profile">Set your default workspace</Text>

        <Row justify="start">
          <Col xl={12} lg={16} xs={24}>
            <WorkspaceSelection settingsView={true} />
          </Col>
        </Row>
      </SettingsWrapStyled>
    </>
  );
};

export default WorkspaceSettings;

import axios, { AxiosInstance } from 'axios';
import { getFirebase } from 'react-redux-firebase';
import { CLOUD_FUNCTION, LOCAL_BACKEND, TOOL_CRAFT } from '../constants';
import { getAPI } from './gpt3services';

let cancel;
const createAxiosInstance = ({ apiType, cancelRequest }): AxiosInstance => {
  // It decide which cloud function api to hit.
  // tool craft is donw with safee on his cloud function
  // the rest of APIs are in diff cloud function

  let baseURL = '';
  switch (apiType) {
    case TOOL_CRAFT:
      baseURL = getAPI();
      break;
    case LOCAL_BACKEND:
      baseURL = process.env.REACT_APP_NODE_LOCAL_BACKEND;
      break;
    case CLOUD_FUNCTION:
      baseURL = process.env.REACT_APP_CLOUD_FUNCTION_BASEURL;
      break;
    default:
      baseURL = process.env.REACT_APP_CLOUD_FUNCTION_BASEURL;
  }
  const firebase = getFirebase();

  const instance = axios.create({
    baseURL: baseURL,
  });
  const CancelToken = axios.CancelToken;

  // Cancel mechanism, When user change the page,tool craft api get cancelled.
  if (cancelRequest) {
    cancel('Request is canceled'); // cancel request
  }
  instance.interceptors.request.use(
    async config => {
      config.cancelToken = new CancelToken(function executor(c) {
        cancel = c;
      });
      //! Firebase user authentication is used to send as token
      const token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true);
      config.headers.authorization = `Bearer ${token}`;
      return { ...config };
    },
    error => {
      return Promise.reject(error);
    },
  );
  return instance;
};

export default createAxiosInstance;

/* ------------------------------------------------------ */
/*                    STRIPE PRORATION                    */
/* ------------------------------------------------------ */

/**
 *
 * Proration apply if the team admin already have an acitve subscription either crafter or starter and now the team admin
 * wants to switch to team plan.
 *
 * Protation is the stripe mechanism to calculate the exisitng price and the conversion price of new plan.
 * This calculate the stripe proration charges for the new plan
 * Stripe API is called.
 *
 *
 *
 */

import React, { useState, useEffect } from 'react';
import { Descriptions, Typography, Spin, Divider } from 'antd';
import { getProration, updateSubscriptionQuantity } from '../../../services/apiServices';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { convertToDollar } from '../../../utility/utility';
import { Button } from '../../../components/Button';
import { ProrationWrapper } from '../style';
import SeatsQuantity from './SeatsQuantity';
import { changeProrationText } from '../../../utility/filterWords';
import {
  PRORATION_NOTE,
  PRORATION_SEATS_UPDATE_DECREASE,
  PRORATION_SEATS_UPDATE_EQUAL,
  PRORATION_SEATS_UPDATE_INCREASE,
} from '../../../constants/content';

const { Text, Title } = Typography;

const Proration = ({
  disableCreateTeamButton,
  reset,
  seatCount,
  onCancel,
  activatedPlanDetails,
  createTeamView,
  showNextMonth,
}) => {
  const { stripeId, allowTeamStarterPlan } = useSelector((state) => state.fb.profile);
  const { subscriptionList } = useSelector((state) => state.stripe);
  const [amountDueToday, setAmountDueToday] = useState([]);
  const [amountForNextMonth, setAmountForNextMonth] = useState([]);
  const [proparateSummary, setProparateSummary] = useState(null);
  const [prorationLoading, setProrationLoading] = useState(false);
  const [payNowLoading, setPayNowLoading] = useState(false);
  const [showError, setShowError] = useState(null);

  const subID = subscriptionList && subscriptionList.length > 0 && subscriptionList[0].subscriptionId;
  const subItemId = subscriptionList && subscriptionList.length > 0 && subscriptionList[0].items[0].id;
  const { quantity } = activatedPlanDetails || { quantity: 0 };
  const [count, setCounts] = useState(quantity);

  useEffect(() => {
    seatCount && seatCount > 0 && calculateProration(seatCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seatCount]);

  // when seats management modal is closed.
  useEffect(() => {
    if (!reset) {
      setAmountDueToday([]);
      setAmountForNextMonth([]);
      setProparateSummary([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  // Seats Change with + - signs
  const onSeatChange = (count) => {
    setCounts(count);
    calculateProration(count);
  };

  // The actual STRIPE  API calls.
  const calculateProration = (count) => {
    setProrationLoading(true);
    const proration_date = Math.floor(Date.now() / 1000);
    setShowError(null);
    disableCreateTeamButton(false);

    // Cloud-Function to call stripe api.
    getProration({
      subID,
      quantity: count,
      customer: stripeId,
      optionalFields: {
        // subscription_trial_end:
        // subscription_proration_behavior: 'always_invoice',
        subscription_proration_date: proration_date,
      },
      allowTeamStarterPlan,
    })
      .then((response) => {
        setProrationLoading(false);
        const { invoice } = response.data;
        const { lines, starting_balance, next_payment_attempt } = invoice;
        const dueToday = lines.data.filter(({ proration }) => proration === true);
        setAmountDueToday(dueToday);
        let dueTodayTotal = dueToday.reduce((accumulator, pilot) => {
          return accumulator + pilot.amount;
        }, starting_balance);

        const nextMonth = lines.data.filter(({ proration }) => proration === false);
        setAmountForNextMonth(nextMonth);

        if (dueToday.length === 0) {
          setAmountDueToday(nextMonth);
          dueTodayTotal = nextMonth.reduce((accumulator, pilot) => {
            return accumulator + pilot.amount;
          }, starting_balance);
        }

        const nextMonthTotal = nextMonth.reduce((accumulator, pilot) => {
          return accumulator + pilot.amount;
        }, 0);

        const nextPaymentDate = next_payment_attempt ? moment.unix(next_payment_attempt).format('MMM DD YYYY') : '';
        setProparateSummary({ starting_balance, nextMonthTotal, nextPaymentDate, dueTodayTotal });
      })
      .catch((err) => {
        setProrationLoading(false);
        disableCreateTeamButton(true);
        const error = (err.response.data && err.response.data.error) || {};
        if (error && (error.statusCode === 404 || error.statusCode === 400) && error.raw && error.raw.message) {
          const {
            raw: { message },
          } = error;
          // console.log(`message`, message);
          setShowError(message);
        } else {
          setShowError('There is some thing wrong.');
        }
      });
  };

  const onPayNow = () => {
    setPayNowLoading(true);
    const proration_date = Math.floor(Date.now() / 1000);

    updateSubscriptionQuantity({
      subID: subItemId,
      quantity: count,
      activateNow: proration_date,
    })
      .then((response) => {
        setPayNowLoading(false);
        onCancel();
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const PayButtonText =
    count > quantity
      ? PRORATION_SEATS_UPDATE_INCREASE
      : count < quantity
      ? PRORATION_SEATS_UPDATE_DECREASE
      : PRORATION_SEATS_UPDATE_EQUAL;

  return (
    <ProrationWrapper>
      {!createTeamView && (
        <div className="seatsWithTeamPlan">
          <Title level={4}>Seats Management </Title>
          <Divider />
          <Text strong>Update </Text> <Text>Total number of seats: </Text>
          <div className="seats">
            <SeatsQuantity width={100} onSeatChange={onSeatChange} existingSeats={quantity - 1} />
            <Text className="seats-note">(save 30% on each additional seat) </Text>
          </div>
        </div>
      )}
      <div className="note">
        <Text>{PRORATION_NOTE} </Text>
      </div>
      {showError && (
        <div className="note">
          <Text type="danger">{showError}</Text>
        </div>
      )}

      {!showError && (
        <div className="spinner">
          <Spin spinning={prorationLoading}>
            {amountForNextMonth && proparateSummary && showNextMonth && (
              <Descriptions
                title={`What you will pay monthly starting at ${proparateSummary.nextPaymentDate}`}
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                bordered
              >
                {amountForNextMonth.map(({ amount, description }, index) => {
                  return (
                    <Descriptions.Item key={`p-${index}`} label={`${changeProrationText(description)}`}>
                      {convertToDollar(amount)}
                    </Descriptions.Item>
                  );
                })}

                <Descriptions.Item label="Next Month you will pay">
                  {convertToDollar(proparateSummary.nextMonthTotal)}
                </Descriptions.Item>
              </Descriptions>
            )}
            {amountDueToday && proparateSummary && (
              <div className="amount-due-today">
                <Descriptions
                  title={`Amount Due Today`}
                  column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                  bordered
                >
                  {amountDueToday.map(({ amount, description }, index) => {
                    return (
                      <Descriptions.Item key={index} label={`${changeProrationText(description)}`}>
                        {convertToDollar(amount)}
                      </Descriptions.Item>
                    );
                  })}
                  {proparateSummary && proparateSummary.starting_balance && (
                    <Descriptions.Item label="Applied Account Balance">
                      {convertToDollar(proparateSummary.starting_balance)}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Total Amount Due Today">
                    {convertToDollar(proparateSummary.dueTodayTotal > 0 ? proparateSummary.dueTodayTotal : 0.0)}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            )}
            {!createTeamView && (
              <div className="action-proration-button">
                <Button
                  loading={payNowLoading}
                  disabled={count ? count === quantity : true ? true : false}
                  type="primary"
                  block
                  onClick={onPayNow}
                >
                  {PayButtonText}
                </Button>

                <Button type={'default'} block onClick={onCancel}>
                  Cancel
                </Button>
              </div>
            )}
          </Spin>
        </div>
      )}
    </ProrationWrapper>
  );
};

export default Proration;

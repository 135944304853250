/* eslint-disable jsx-a11y/control-has-associated-label */

/* ------------------------------------------------------ */
/*                     DUPLICATE CARDS                    */
/* ------------------------------------------------------ */

/**
 * This is used to deduct the duplication of credit cards
 *
 * Cards on stripe are being check whether they are already used or not
 * the user card info is being requested to the cloud-function api to fetch card details from stripe
 *
 * The usage of the same credit card across several accounts is checked.
 */

import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Input, Descriptions, Spin, Collapse } from 'antd';
import { Main } from '../styled';
import { UsersListBox, CardDetails, DuplicateCardDesign } from './styled';
import { Cards } from '../../components/Card';
import { useNavigate } from 'react-router-dom';
import { getUserCards } from '../../services/apiServices';
import { Button } from '../../components/Button';
import { STATUS } from '../../constants';
import { EyeOutlined } from '@ant-design/icons';
import { getFirestore } from 'redux-firestore';
import moment from 'moment';
import { allSubscribers, dateFormatWithTime } from '../../utility/utility';

const { Text, Title, Paragraph, Link } = Typography;
const { Search } = Input;
const { Panel } = Collapse;

const DuplicateCards = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [allUsers, setAllUsers] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [displayUsersList, setDisplayUsersList] = useState([]);
  const [usersCards, setUsersCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDuplicateUsers, setShowDuplicateUsers] = useState([]);
  const [blockedList, setBlockedList] = useState([]);
  const [buttonloading, setButtonloading] = useState(false);
  const navigate = useNavigate();
  const db = getFirestore();

  // this function execute when the page refresh only or open 1st time.
  useEffect(() => {
    setButtonloading(true);
    //fetch all the user data
    db.collection('users').onSnapshot((snapshot) => {
      const fetchAllUsers = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        fetchAllUsers.push({ uid: doc.id, ...data });
      });
      setAllUsers(fetchAllUsers);
    });
    const blockCardSubscriber = db.collection('blocked-cards').onSnapshot((snapshot) => {
      const allBlockedFingerprint = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        allBlockedFingerprint.push({ fingerprint: doc.id, ...data });
      });
      setBlockedList(allBlockedFingerprint);
      setButtonloading(false);
    });
    allSubscribers.findIndex((x) => x.name === 'blockCardSubscriber') === -1 &&
      allSubscribers.push({ func: blockCardSubscriber, name: 'blockCardSubscriber' });
  }, []);

  // Selected user request to fetch the card details from stripe via cloud-fuction api
  useEffect(() => {
    if (selectedUser) {
      setLoading(true);
      // cloud-function api to fetch the card details
      getUserCards({ customerId: selectedUser.stripeId, userUID: selectedUser.userUID })
        .then((response) => {
          const { data } = response;
          if (data && Array.isArray(data)) {
            const cardList = data.map(({ billing_details, card }) => {
              const { name } = billing_details;
              const { brand, exp_month, exp_year, fingerprint, funding, last4 } = card;
              return {
                name,
                brand,
                exp_month,
                exp_year,
                fingerprint,
                funding,
                last4,
              };
            });
            setUsersCards(cardList);
            setShowDuplicateUsers([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }, [selectedUser]);

  useEffect(() => {
    const activeUserList =
      allUsers &&
      allUsers.filter(
        ({ activatedPlanDetails, displayPlanStatus, creditCards }) =>
          activatedPlanDetails !== undefined && displayPlanStatus !== STATUS.DELETED && creditCards,
      );
    if (searchValue !== '') {
      const searchedList = activeUserList.filter(
        ({ name }) => name && name.toLowerCase().includes(searchValue.toLowerCase()),
      );
      searchedList && setDisplayUsersList(searchedList);
    } else {
      activeUserList && setDisplayUsersList([...activeUserList]);
    }
  }, [allUsers, searchValue]);

  const handleChange = (e, { stripeId, userUID, email }) => {
    setSelectedUser({ stripeId, userUID, email });
    e.preventDefault();
    try {
      selectedUser !== undefined && e.target.closest('ul').querySelector('a.active').classList.remove('active');
    } catch {}

    e.target.classList.add('active');
  };
  const tagsColor = ['success', 'primary', 'info', 'warning', 'danger'];

  const onSearch = (value) => {
    setSearchValue(value);
  };
  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  // it checks the if the same credit card is being used in multiple accounts
  const checkDuplicate = () => {
    // setDuplicateLoading(true);
    const filterList = [];
    allUsers &&
      allUsers
        .filter(
          ({ activatedPlanDetails, displayPlanStatus, creditCards }) =>
            activatedPlanDetails !== undefined && displayPlanStatus !== STATUS.DELETED && creditCards,
        )
        .sort((a, b) => {
          return b.createdDate.seconds - a.createdDate.seconds;
        })
        .map(({ creditCards, email, name, createdDate, plan, planStatus, stripeLink }) => {
          return creditCards.map((a) => {
            return filterList.push({ ...a, email, name, createdDate, plan, planStatus, stripeLink });
          });
        });
    const showDuplicates = [];
    filterList.forEach((detail) => {
      const checkAlreadyInList = showDuplicates.filter((alreadyExist) => alreadyExist.email === detail.email);
      if (checkAlreadyInList.length === 0) {
        const alreadyCard = filterList.filter((alreadyExist) => alreadyExist.fingerprint === detail.fingerprint);
        showDuplicates.push(...alreadyCard);
      }
    });

    const duplicateUsers = [...new Map(showDuplicates.map((item) => [item['fingerprint'], item])).values()]
      .map(({ fingerprint }) => {
        const userSet = showDuplicates.filter((detail) => detail.fingerprint === fingerprint);
        return userSet.length > 1 && { fingerprint, sameUsers: userSet };
      })
      .filter(Boolean);
    setShowDuplicateUsers(duplicateUsers);
    setUsersCards([]);
  };

  const blockCard = ({ fingerprint, sameUsers }) => {
    setButtonloading(true);

    const list = [...sameUsers];
    const orderedDates = list
      .sort(function (a, b) {
        return b.createdDate.seconds - a.createdDate.seconds;
      })
      .pop();
    db.collection('blocked-cards')
      .doc(fingerprint)
      .set({ allowed: orderedDates })
      .then(() => {
        setButtonloading(false);
      });
  };

  const unBlockCard = ({ fingerprint }) => {
    setButtonloading(true);
    db.collection('blocked-cards')
      .doc(fingerprint)
      .delete()
      .then(() => {
        setButtonloading(false);

        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
      });
  };

  const checkAlreadyBlocked = (cardFingerprint) => {
    return blockedList.find(({ fingerprint }) => {
      return fingerprint === cardFingerprint;
    });
  };

  return (
    <>
      <Main>
        <Title level={2} style={{ marginTop: '3rem' }}>
          Duplicate Cards
        </Title>
        <div style={{ float: 'right' }}>
          <Button className="btn-add_new" onClick={() => checkDuplicate()} size="default" type="success" key="2">
            Check Duplicate Cards
          </Button>
        </div>

        <Row justify="start" style={{ width: '100%' }}>
          <Col xs={6}>
            <Search
              allowClear
              onChange={onSearchChange}
              placeholder="Search User"
              onSearch={onSearch}
              style={{ width: '100%' }}
            />
            <UsersListBox>
              <Cards headless>
                <ul>
                  Count : {displayUsersList.length}
                  {displayUsersList && displayUsersList.length ? (
                    displayUsersList.map((data, index) => {
                      const { stripeId, uid, email, name } = data;
                      return (
                        <li key={index}>
                          <Link
                            className={`${selectedUser === uid && 'active'}  ${tagsColor[index]}`}
                            onClick={(e) => {
                              handleChange(e, { stripeId, userUID: uid, email });
                            }}
                            to="#"
                          >
                            {name}
                          </Link>
                        </li>
                      );
                    })
                  ) : (
                    <Col md={24}>
                      <Text>Data Not Found!</Text>
                    </Col>
                  )}
                </ul>
              </Cards>
            </UsersListBox>
          </Col>

          <Col xs={18}>
            {usersCards.length > 0 && (
              <CardDetails>
                {selectedUser && (
                  <div style={{ display: 'flex' }}>
                    <Text style={{ marginRight: '6px' }}> Email:</Text>{' '}
                    <Paragraph copyable>{`${selectedUser.email}`}</Paragraph>{' '}
                  </div>
                )}
                {usersCards &&
                  !loading &&
                  usersCards.map(({ name, brand, exp_month, exp_year, fingerprint, funding, last4 }) => {
                    return (
                      <Cards headless>
                        <Descriptions
                          title="Card Details"
                          bordered
                          column={{ xxl: 3, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        >
                          <Descriptions.Item label="Name">
                            <Text type="danger">{name} </Text>{' '}
                          </Descriptions.Item>
                          <Descriptions.Item label="Brand">{brand}</Descriptions.Item>
                          <Descriptions.Item label="Exping Month">{exp_month} </Descriptions.Item>
                          <Descriptions.Item label="Exping Year"> {exp_year}</Descriptions.Item>
                          <Descriptions.Item label="Fingerprint">{fingerprint} </Descriptions.Item>
                          <Descriptions.Item label="Funding"> {funding}</Descriptions.Item>
                          <Descriptions.Item label="Last4">{last4} </Descriptions.Item>
                        </Descriptions>
                      </Cards>
                    );
                  })}
                {loading && (
                  <div className="loading">
                    <Spin />{' '}
                  </div>
                )}
              </CardDetails>
            )}
            {showDuplicateUsers.length > 0 && (
              <DuplicateCardDesign>
                <Collapse defaultActiveKey={['0']}>
                  {showDuplicateUsers.map(({ fingerprint, sameUsers }, index) => {
                    return (
                      <Panel
                        header={
                          <>
                            <Text>Credit Card FingerPrint: </Text>
                            <Text className="fingerprint" type="danger">
                              {fingerprint}
                            </Text>
                            <Paragraph>
                              Total Users Matched: <Text type="warning"> {sameUsers.length}</Text>
                            </Paragraph>
                          </>
                        }
                        key={index}
                      >
                        <div className="action-button">
                          <Button
                            className="btn-add_new"
                            loading={buttonloading}
                            onClick={() =>
                              checkAlreadyBlocked(fingerprint)
                                ? unBlockCard({ fingerprint })
                                : blockCard({ fingerprint, sameUsers })
                            }
                            size="small"
                            // disabled={checkAlreadyBlocked(fingerprint)}
                            type={checkAlreadyBlocked(fingerprint) ? 'primary' : 'danger'}
                            key={`block-${index}`}
                          >
                            {checkAlreadyBlocked(fingerprint) ? 'Unblock Now' : 'Block card from trial access'}
                          </Button>
                        </div>
                        {sameUsers.map(
                          ({
                            email,
                            brand,
                            exp_month,
                            exp_year,
                            createdDate,
                            fingerprint,
                            funding,
                            last4,
                            plan,
                            planStatus,
                            stripeLink,
                            name,
                          }) => {
                            return (
                              <Cards headless>
                                <Descriptions
                                  title="Card Details"
                                  bordered
                                  column={{ xxl: 3, xl: 2, lg: 1, md: 1, sm: 1, xs: 1 }}
                                >
                                  <Descriptions.Item label="Email">
                                    <Paragraph copyable style={{ color: 'red' }}>
                                      {`${email}`}{' '}
                                    </Paragraph>
                                    View Profile{' '}
                                    <EyeOutlined
                                      style={{ color: 'black' }}
                                      onClick={() => navigate(`/user-management/${email}`)}
                                    />
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Name">{name}</Descriptions.Item>
                                  <Descriptions.Item label="Created Date">
                                    {moment.unix(createdDate.seconds).format(dateFormatWithTime)}{' '}
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Plan">{plan} </Descriptions.Item>
                                  <Descriptions.Item label="Plan Status">{planStatus}</Descriptions.Item>
                                  <Descriptions.Item label="Stripe Link">
                                    <Link href={stripeLink} target={'_blank'}>
                                      Stripe Profile
                                    </Link>
                                  </Descriptions.Item>
                                  <Descriptions.Item label="Brand">{brand}</Descriptions.Item>
                                  <Descriptions.Item label="Exping Month">{exp_month} </Descriptions.Item>
                                  <Descriptions.Item label="Exping Year"> {exp_year}</Descriptions.Item>
                                  <Descriptions.Item label="Fingerprint">{fingerprint} </Descriptions.Item>
                                  <Descriptions.Item label="Funding"> {funding}</Descriptions.Item>
                                  <Descriptions.Item label="Last4">{last4} </Descriptions.Item>
                                </Descriptions>
                              </Cards>
                            );
                          },
                        )}
                      </Panel>
                    );
                  })}
                </Collapse>
              </DuplicateCardDesign>
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default DuplicateCards;

import styled from 'styled-components';

const NewHeaderMain = styled.div`
  .banner-cta .banner-cta__content .action-buttons .language-button-wrapper .language-button .lang-text {
    color: ${({ theme }) => theme['color-black']} !important;
  }

  .text-editor {
    background-color: #9979fd;
    // background-color: #282b3f;
    margin: 0 !important;
    text-align: center;
    color: #ffffff;
    border: none;
    font-weight: 600;
    margin: 10px;
    align-items: center;
    height: 56px;
    width: 100%;
    border-radius: 0%;
    bottom: -3px;
    font-size: 16px;
    @media (max-width: 768px) {
      margin-top: 20px !important;
    }
  }

  .wrapper-web {
    padding: 0px 14px 22px 14px !important;
    @media (max-width: 768px) {
      gap: 24px;
      padding: 0px 14px 0px !important;
    }
  }

  .banner-cta {
    background-color: #fff;
    min-height: 0px !important;
  }

  .banner-cta .banner-cta__content {
    padding: 18px 0px 18px 0px;
    border-bottom: 1.5px solid #eeeeee;
    @media (max-width: 768px) {
      border: none;
      display: flex;
      flex-direction: column;
      padding: 0px;
    }
  }
`;
const ToolDesignLeftWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 1062px) {
    width: 100% !important;
  }

  .menu {
    padding-bottom: 5px;
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }

  .product-headings {
    margin-bottom: 8px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    .field-title {
      color: #282b3f;
      font-size: 14px;
      margin: 0 0 4px 0;
      font-family: 'Poppins-SemiBold';
    }

    .field-count {
      font-size: 10px;
      line-height: 15px;
    }
    .optional-field {
      color: #a5a5a5;
      font-size: 10px;
      margin-left: 3px;
      font-weight: 500;
      font-family: 'Poppins-Regular';
    }
    .info-icon {
      cursor: pointer;
      margin-left: 3px;
    }
  }

  .tone-tag {
    font-size: 14px;
    font-weight: 400;
    margin: 5px;
  }
  .text-danger {
    color: red;
  }
  .slider-marks {
    line-height: 2.4rem;
  }
  .ant-input:focus {
    border-color: #6c5afb;
  }

  .ant-input {
    border: 1.5px solid #eeeeee;
    font-size: 13px;
    font-family: 'Poppins-Regular';
    border-radius: 8px;
    margin-bottom: 15px;
    height: 48px;
    padding: 14px 20px;
  }
  .ant-input:placeholder-shown {
    color: #8189a2;
  }

  .star {
    text-align: center;
    margin-right: 1rem;
    @media only screen and (max-width: 1013px) and (min-width: 993px) {
      margin-right: 0rem !important;
    }
  }

  /* .anticon svg {
    width: 15px;
    padding: -10px;
    height: 15px;
    text-align: center;
  } */

  .custom-button-container {
    margin-top: 10px;
  }

  .ant-rate-star.ant-rate-star-zero span svg {
    color: #eee;
  }

  .text-bottom {
    justify-content: center;
    text-align: center;
    color: #282b3f;
    margin: 23px 10px 10px;
    font-family: 'Poppins-SemiBold';
  }
  .generate-btn {
    margin-top: 1.5rem;
  }
`;

const DataWrap = styled.div`
  max-height: calc(100vh - 20rem);
  overflow: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eeee #fff !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(238, 238, 238, 0.928) !important;
    border-radius: 15px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    /* background: blue;  */
  }

  .output-highlight {
    background-color: rgba(191, 163, 255, 0.15);
  }
  .image-generation {
    padding: 1.3rem;
    .ant-image-img {
      border-radius: 6px;
    }
  }
  .tool-craft {
    border-bottom: 1.5px solid #eeeeee;
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 15px;

    @media (max-width: 768px) {
      border-radius: 18px;
      border: 1px solid #eeeeee;
      margin: 12px 12px 16px 12px;
    }

    .craft-wrapper {
      width: 100%;
      padding: 15px 3px 3px 12px;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .time {
        color: #848484;
        font-weight: 500;
        font-size: 10px;
        line-height: 15px;
      }
      .char-words {
        @media (max-width: 768px) {
          display: none;
        }
        .count {
          font-weight: 500;
          font-size: 10px;
          color: #848484;
          margin-left: 15px;
        }
      }

      .mobile-buttons {
        right: 15px;
        .output-icon-mobile {
          padding: 0;
          width: 35px;
          height: 34px;
          margin: 1px 0;
          @media (max-width: 768px) {
            padding-right: 8px !important;
            padding-left: 8px !important;
          }
        }
      }
    }
    .craft-output {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
      .craft-output-text {
        font-weight: 400;
        font-size: 16px;
        color: #282b3f;
        margin-top: 5px;
        font-family: 'Poppins-Regular';
        margin-bottom: 5px;
        h1 {
          font-size: 24px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          margin: 10px 0px;
          margin-bottom: 10px;
          margin-bottom: 0px;
        }
        h3 {
          font-size: 16px;
          margin-bottom: -24px;
          padding-top: 20px;
        }
      }
    }
    .action-icons {
      display: flex;
      flex-direction: column;
      padding-top: 2rem;
      padding-right: 15px;
      @media (max-width: 768px) {
        .ant-btn {
          display: block !important;
        }
      }
      .output-icon {
        padding: 0;
        width: 35px;
        height: 34px;
        margin: 1px 0;
        @media (max-width: 768px) {
          padding-right: 8px !important;
          padding-left: 8px !important;
        }
        img,
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .custom-button-container {
    margin: 20px;
    @media (max-width: 768px) {
      padding: 0px;
    }
  }

  .ant-btn {
    border: none;
  }

  .plus-btn {
    padding: 0px;
  }
`;

const ToolRightSideWrap = styled.div`
  text-align: center;
  height: 100%;
  padding: 25px 25px 29px 25px;
  @media (max-width: 768px) {
    padding: 0;
  }
  .right-text {
    background: #f9f9ff;
    border: 1.5px dashed #eeeeee !important;
    border-radius: 8px;
    border: none;
    margin-bottom: 37px !important;
    height: 269px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      margin: 20px 16px 30px 15px !important;
      height: 175px !important;
    }
  }

  .ant-typography {
    font-size: 14px;
    line-height: 19px;
    color: #848484;
    font-family: 'Poppins-Medium';
    @media (max-width: 768px) {
      color: #8189a2;
      font-size: 12px;
    }
  }

  .example {
    line-height: 21px;
    color: #282b3f;
    font-family: 'Poppins-SemiBold';
  }

  .icons-wrapper {
    margin-top: 30px;
    padding: 0 20px;
    @media (max-width: 1062px) {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      padding-top: 30px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding: 30px 30px 0px;
    }

    .examples-icons {
      margin-bottom: 30px;
      padding: 10px;
      width: 20% !important;
      display: inline-block;
      text-align: center;
      vertical-align: top;
      @media (max-width: 1062px) {
        width: 100% !important;
      }

      .icons-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #282b3f;
        font-family: 'Poppins-Medium';
      }
      .icon-svg {
        cursor: pointer;
        height: 55px;
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }

  .button-div {
    text-align: center;
    @media (max-width: 768px) {
      margin: 0 16px 16px 16px;
    }
    .button-crafting {
      border-radius: 8px;
      width: 100%;
      height: 48px;
    }
  }
`;
const RightSideStyle = styled.div`
  margin-top: 1.5rem;
  .product-tabs {
    height: 100%;
    width: 100%;
    .ant-card-head {
      padding: 0 10px !important;
    }
    .product-tabs-web {
      @media (max-width: 768px) {
        height: 100%;
        width: 100%;
      }
    }
    .ant-card-body {
      padding: 0px !important;
      .craft-btn {
        padding: 10px 1rem;
      }
    }
    .ant-tabs-tab {
      padding: 15px 5px !important;
      margin-right: 51px;
    }
    .animationWrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .loading-image {
        width: 100%;
        border-radius: 10px;
      }
    }
    @media (max-width: 768px) {
      border: none;
      width: calc(100% + 30px);
      margin-left: -15px;
    }
  }

  .ant-tabs-tab-btn {
    color: #848484;
    font-size: 14px;
  }
`;

export { NewHeaderMain, ToolDesignLeftWrap, DataWrap, ToolRightSideWrap, RightSideStyle };

/* ------------------------------------------------------ */
/*                      Billing PopUps                    */
/* ------------------------------------------------------ */

/**
 * This feature is for V2 Pricing
 *
 * Methadology
 * The utility of this component is in V2 pricing model. On onboarding the user must have to select the plan. This renders when the user switch plan from trial to paid plan. If the user selected higher tier plan and the user selected low tier
 * plan then this component will render, inform the user to whether go with original plan or new selected plan.
 *
 */

import React, { useState, useEffect } from 'react';
import { Space, Typography } from 'antd';
import { Modal } from '../../../components/Modal';
import { TRIAL_DOWNGRADE_PLAN_DESCRIPTION, TRIAL_DOWNGRADE_PLAN_HEADING } from '../../../constants/content';
import { Button } from '../../../components/Button';
import { TRIAL_PLAN_CHANGE_DOWNGRADE } from '../../../constants';
import { PopUpContentWrapper } from '../styled';

const { Text } = Typography;

const BillingPopUps = ({ open, onCancel, popUpType, onOriginalPlan, onChangePlan }) => {
  const [state, setState] = useState({
    open,
    modalType: 'primary',
  });

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        open,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [open]);

  const handleCancel = () => {
    onCancel();
  };

  return (
    <>
      {popUpType === TRIAL_PLAN_CHANGE_DOWNGRADE && (
        <Modal type={state.modalType} open={state.open} footer={null} onCancel={handleCancel}>
          <PopUpContentWrapper>
            <Text className="content">{TRIAL_DOWNGRADE_PLAN_HEADING}</Text>
            <Text className="content">{TRIAL_DOWNGRADE_PLAN_DESCRIPTION}</Text>
            <Space className="button-action" align="center">
              <Button size="large" onClick={onChangePlan} type="primary" style={{ marginBottom: '1rem' }}>
                Change Plan
              </Button>
              <Button size="large" onClick={onOriginalPlan} type="primary" style={{ marginBottom: '1rem' }}>
                Original Plan
              </Button>
            </Space>
          </PopUpContentWrapper>
        </Modal>
      )}
    </>
  );
};

export default BillingPopUps;

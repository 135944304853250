/* eslint-disable jsx-a11y/control-has-associated-label */

/* ------------------------------------------------------ */
/*                     Team Management                    */
/* ------------------------------------------------------ */

/* ----------------- CRAFTLY ADMIN PAGE ----------------- */

/**
 * Methodology
 *
 * Craftly Admin can review the team details of users.
 *
 * On the left side there is a list team list
 * On the right side there will be selected team details.
 *
 * In team details
 * 1. Team Name
 * 2. Admin Name
 * 3. Team Projects - multiple list
 * 4. Team Status
 * 5. Members Name
 *
 * on clicking on the teamname, the craftly admin view will be active.
 * mirrorTeamUID is the state in reducer which controls the admin view on team portal
 *
 */

import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Input, Descriptions, Spin, Tag } from 'antd';
import { Main } from '../styled';
import { UsersListBox, CardDetails } from './styled';
import { Cards } from '../../components/Card';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMirrorTeamUID } from '../../redux/team/actionCreator';

const { Text, Title, Link } = Typography;
const { Search } = Input;

const TeamManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTeam, setSelectedTeam] = useState({
    adminUID: '',
    teamName: '',
  });
  // fetch team-list from the reducers
  const { teamList } = useSelector((state) => state.team);
  const [searchValue, setSearchValue] = useState('');
  const [displayUsersList, setDisplayUsersList] = useState([]);
  const [teamDetails, setTeamDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedTeam) {
      setLoading(true);

      // it filter out the the team details of the user
      const teamDetails =
        teamList &&
        teamList.filter(
          ({ teamName, adminUID }) => teamName === selectedTeam.teamName && adminUID === selectedTeam.adminUID,
        );
      setTeamDetails(teamDetails);
      setLoading(false);
    }
  }, [selectedTeam, teamList]);

  // search team by team name
  useEffect(() => {
    if (searchValue !== '') {
      const searchedList =
        teamList &&
        teamList.filter(({ teamName }) => teamName && teamName.toLowerCase().includes(searchValue.toLowerCase()));
      setDisplayUsersList(searchedList);
    } else {
      setDisplayUsersList(teamList);
    }
  }, [teamList, searchValue]);

  const handleChange = (e, { adminUID, teamName }) => {
    setSelectedTeam({ adminUID, teamName });
    e.preventDefault();
    try {
      selectedTeam !== undefined && e.target.closest('ul').querySelector('a.active').classList.remove('active');
    } catch {}

    e.target.classList.add('active');
  };
  const tagsColor = ['success', 'primary', 'info', 'warning', 'danger'];

  const onSearch = (value) => {
    setSearchValue(value);
  };
  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  // teamID and adminUID is same
  // we are setting teamID inorder to see team details by craftly admin
  const onTeamDetailsClick = (teamID) => {
    // mirrorTeamUID is to mirrior the team admin team detials
    dispatch(setMirrorTeamUID(teamID));
    navigate('/team-portal');
  };

  return (
    <>
      <Main>
        <Title level={2} style={{ marginTop: '3rem' }}>
          Team Management
        </Title>

        <Row justify="start" style={{ width: '100%' }}>
          <Col xs={6}>
            <Search
              allowClear
              onChange={onSearchChange}
              placeholder="Search Team Name"
              onSearch={onSearch}
              style={{ width: '100%' }}
            />
            <UsersListBox>
              <Cards headless>
                <ul>
                  Count : {displayUsersList.length}
                  {displayUsersList && displayUsersList.length ? (
                    displayUsersList.map((data, index) => {
                      const { adminUID, teamName } = data;
                      return (
                        <li key={index}>
                          <Link
                            className={`${selectedTeam.adminUID === adminUID && 'active'}  ${tagsColor[index]}`}
                            onClick={(e) => {
                              handleChange(e, { adminUID, teamName });
                            }}
                            to="#"
                          >
                            {teamName}
                          </Link>
                        </li>
                      );
                    })
                  ) : (
                    <Col md={24}>
                      <Text>Data Not Found!</Text>
                    </Col>
                  )}
                </ul>
              </Cards>
            </UsersListBox>
          </Col>

          <Col xs={18}>
            {teamDetails.length > 0 && (
              <CardDetails>
                {teamDetails &&
                  !loading &&
                  teamDetails.map(({ teamName, adminName, teamProjects, teamStatus, members, adminUID }) => {
                    return (
                      <Cards headless>
                        <Descriptions
                          title="Team Details"
                          bordered
                          column={{ xxl: 3, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}
                        >
                          <Descriptions.Item label="Team Name">
                            <Link onClick={() => onTeamDetailsClick(adminUID)}>{teamName} </Link>
                          </Descriptions.Item>
                          <Descriptions.Item label="Admin Name">{adminName}</Descriptions.Item>
                          <Descriptions.Item label="team Projects">
                            {teamProjects &&
                              teamProjects.length > 0 &&
                              teamProjects.map(({ project }) => (
                                <Tag style={{ fontSize: '14px' }} color="blue">
                                  {project}
                                </Tag>
                              ))}
                          </Descriptions.Item>
                          <Descriptions.Item label="Team Status">
                            <Tag style={{ fontSize: '14px' }} color="green">
                              {teamStatus}
                            </Tag>{' '}
                          </Descriptions.Item>
                          <Descriptions.Item label="Members Name">
                            {members &&
                              members.length > 0 &&
                              members.map(({ teamMemberName, teamMemberEmail }) => (
                                <Tag style={{ fontSize: '14px' }} color="blue">
                                  {teamMemberName} - {teamMemberEmail}
                                </Tag>
                              ))}
                          </Descriptions.Item>
                        </Descriptions>
                      </Cards>
                    );
                  })}
                {loading && (
                  <div className="loading">
                    <Spin />{' '}
                  </div>
                )}
              </CardDetails>
            )}
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default TeamManagement;

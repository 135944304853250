/* ------------------------------------------------------ */
/*                    Recent Header                       */
/* ------------------------------------------------------ */

/**
 * The Header has the datepicker
 * search also apply on the content and tool input
 *
 */

import { Input, DatePicker, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { RecentHearderWrap, RecentDateWrap, DateWrap, SearchWrap } from './styled';

import Search from '../../../static/favourite/search-input.svg';
import { dateFormat } from '../../../utility/utility';
import { CRAFTER_ACCESS, ENTERPRISE_ACCESS, STARTER_ACCESS } from '../../../constants';
import dayjs from 'dayjs';

// import ArrowDown from '../../../static/favourite/arrowDown.svg';
// import Filter from '../../../static/favourite/Filter.svg';
// import Icon from '../../../static/favourite/Icon.svg';

const { RangePicker } = DatePicker;

const RecentHeader = ({ plan, recentDateRange, onInputSearch }) => {
  const startOfMonth = moment().startOf('month').format(dateFormat);
  const lastSevenDates = moment().subtract(7, 'd').format(dateFormat);

  const startDate = CRAFTER_ACCESS.includes(plan) ? startOfMonth : lastSevenDates;
  const disableDates = () => {
    if (CRAFTER_ACCESS.includes(plan)) {
      return moment().subtract(6, 'months');
    } else if (STARTER_ACCESS.includes(plan)) {
      return moment().subtract(7, 'd');
    } else if (ENTERPRISE_ACCESS.includes(plan)) {
      return null;
    }
  };

  const currentDate = moment().format(dateFormat);
  const [favRange, setFavRange] = useState([startDate, currentDate]);
  const disabledDate = (current) => {
    return current && (current > moment().endOf('day') || current < disableDates());
  };

  useEffect(() => {
    favRange && recentDateRange(favRange);
  }, []);

  const onRangeChange = (dates, dateStrings, info) => {
    setFavRange(dateStrings);
  };
  const onOpen = (open) => {
    if (open === false) {
      recentDateRange(favRange);
    }
  };

  return (
    <RecentHearderWrap>
      <RecentDateWrap>
        <DateWrap>
          <Space direction="vertical" size={12}>
            <RangePicker
              defaultValue={[dayjs(favRange[0], dateFormat), dayjs(favRange[1], dateFormat)]}
              format={dateFormat}
              disabledDate={disabledDate}
              onChange={onRangeChange}
              onOpenChange={onOpen}
              value={[dayjs(favRange[0]), dayjs(favRange[1])]}
              allowClear={false}
            />
          </Space>
        </DateWrap>
        <div className="recent-header-wrap">
          {/* <FilterWrap>
            <Button>
              <img src={Filter} alt="Filter"></img>
              Filter
              <img src={ArrowDown} alt="ArrowDown"></img>
            </Button>
            <Button size="small" type="light" className="copied-fav">
              <img src={Icon} alt="Icon"></img>
              Copied
            </Button>
          </FilterWrap> */}

          <SearchWrap>
            <Input
              allowClear
              onChange={(e) => onInputSearch(e.target.value)}
              size="middle"
              placeholder="Search Input"
              suffix={<img src={Search} alt="Search" />}
            />
          </SearchWrap>
        </div>
      </RecentDateWrap>
    </RecentHearderWrap>
  );
};

export default RecentHeader;

/* ------------------------------------------------------ */
/*                     Default CHAT UI                    */
/* ------------------------------------------------------ */

/**
 * Methadology
 * This is the default UI of the page. when user visits the page first time.
 */

import React from 'react';
import { DefaultCaiChatStyled } from '../styled';
import { Avatar, Col, Row, Typography } from 'antd';
import ContentCreationImg from '../../../static/caichat/content-creation.png';
import LongFormImg from '../../../static/caichat/long-form.png';
import seoImg from '../../../static/caichat/seo.png';
import socialIdeaImg from '../../../static/caichat/social-idea.png';

const { Text } = Typography;
const DefaultCaiChatUI = () => {
  return (
    <DefaultCaiChatStyled>
      <div className="heading-cai-chat">
        <Text className="welcome">Welcome to</Text>
        <Text className="cai-chat-heading">CaiChat </Text>

        <div className="sub-heading-wrapper">
          <Text className="sub-heading-text">
            Cai is one step closer to making you that cup of coffee. Use CaiChat <br /> to get answers, ideas, content,
            and more! Start by typing a request below.
          </Text>
          <div className="second-heading">
            <Text className="sub-heading-text">
              Not sure where to start? Click a prompt below or view all in the bottom left corner!{' '}
            </Text>
          </div>
        </div>
      </div>

      <div className="feature-section">
        <div className="feature-wrapper">
          <Row>
            <Col xs={3} sm={2}>
              <Avatar size={45} src={<img src={LongFormImg} alt="avatar" />} />
            </Col>
            <Col xs={20} sm={22}>
              <div className="cai-chat-features">
                <Text className="title">Long-form Writing </Text>
                <Text className="description">
                  "Write a blog post about the impact of [specific event/news] on [specific industry or community]."{' '}
                </Text>
              </div>
            </Col>
          </Row>
        </div>
        <div className="feature-wrapper">
          <Row>
            <Col xs={3} sm={2}>
              <Avatar size={45} src={<img src={socialIdeaImg} alt="avatar" />} />
            </Col>
            <Col xs={20} sm={22}>
              <div className="cai-chat-features">
                <Text className="title">Social Idea Generation </Text>
                <Text className="description">
                  "I want to promote my new [product] on Instagram. Can you suggest some creative ways to showcase it in
                  my posts?"{' '}
                </Text>
              </div>
            </Col>
          </Row>
        </div>
        <div className="feature-wrapper">
          <Row>
            <Col xs={3} sm={2}>
              <Avatar size={45} src={<img src={ContentCreationImg} alt="avatar" />} />
            </Col>
            <Col xs={20} sm={22}>
              <div className="cai-chat-features">
                <Text className="title">Content Creation </Text>
                <Text className="description">
                  “Can you create a content calendar for the next [month/quarter/year] that includes [specific number]
                  of blog posts, [specific number] of social media posts, and [specific number] of email newsletters?”{' '}
                </Text>
              </div>
            </Col>
          </Row>
        </div>
        <div className="feature-wrapper">
          <Row>
            <Col xs={3} sm={2}>
              <Avatar size={45} src={<img src={seoImg} alt="avatar" />} />
            </Col>
            <Col xs={20} sm={22}>
              <div className="cai-chat-features">
                <Text className="title">SEO </Text>
                <Text className="description">
                  “Help me generate ‘how-to schema markup’ for the following steps on [topic]. Pin down your target
                  audience, Choose a Topic, Research Keywords”{' '}
                </Text>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </DefaultCaiChatStyled>
  );
};

export default DefaultCaiChatUI;

/* ------------------------------------------------------ */
/*                   HEADER SEARCH TOOL                   */
/* ------------------------------------------------------ */
/* 
This component is used as a search for tools from selected tool-list and from all-tools.
Search works on Tool Description.

Methodology: 
There are 2 ways of search tools 
1. Display tools via search input 
2. Display tools via category selects

When a user searches by input field, the category is changed to All_TOOLS, and the tools that match the search are shown. 
When a user selects a category, the tools for that category are displayed.

*/

/* Used In  
src/container/Category/CategoryBanner.js
src/container/CommonHeader/HeaderComponent.js
src/container/ToolLibraryPopup/index.js
src/layout/MobileProjectToolSelection.js
src/layout/new-designs/header/AppHeader.jsx
*/
import React, { useState, useEffect } from 'react';
import { Input, AutoComplete, Typography } from 'antd';
import Icon from '@ant-design/icons';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { saveCategoryId, searchTools } from '../../redux/AdminFeatures/actionCreator';
import { ALL_TOOLS } from '../../constants';
import SearchSvg from '../../static/tools/svg/search.svg';

const { Paragraph } = Typography;

// ant-design component
const AutoCompleteWrapper = styled(AutoComplete)`
  width: 100%;
`;

/** 
@string placeholder: default text
@boolean categoryToolList: list of tools of specfic category
@boolean showSearchOptionsInDropdown: When a user searches for tool information, a dropdown menu appears. Currently, the choice only appears in the Header Search.
*/

const HeaderSearchTools = ({ placeholder, categoryToolList, showSearchOptionsInDropdown }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allTools } = useSelector((state) => state.adminFeatures);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null); // set search value.
  const [dropDownOpen, setDropDownOpen] = useState(false); // set dropdown open and close.

  // this saved the searched tool-ids in redux state
  const onSearchToolIds = (toolIds) => {
    dispatch(searchTools({ searchToolIds: toolIds }));
  };

  useEffect(() => {
    if (showSearchOptionsInDropdown) {
      setDropDownOpen(true);
    } else {
      setDropDownOpen(false);
    }
  }, [showSearchOptionsInDropdown, searchValue]);

  // the dropdown will close when a user clicks on any tool link
  const dropdownClose = () => {
    setDropDownOpen(false);
    showSearchOptionsInDropdown && setSearchValue('');
  };

  // It is triggered when a user writes something in the input field or
  // when a user clears it by clicking on the cross that appears at the end of the input field.
  const handleSearch = (searchText) => {
    setSearchValue(searchText);
    // as soon the user input the search value, the category shift to All_TOOLS.
    // means the search will be on all tool-list
    searchText && dispatch(saveCategoryId({ selectedCategoryId: ALL_TOOLS }));

    // This is only triggered when a user eliminates all search values or clicks the cross symbol to clear the field.
    if (searchText === '') {
      dispatch(saveCategoryId({ selectedCategoryId: null }));
      navigate(`/category`);
    }
  };

  useEffect(() => {
    searchValue ? setOptions(displayOptions(searchValue)) : setOptions([]);
  }, [searchValue]);

  const linkClikcked = (toolId) => {
    navigate(`/tool/${toolId}`);
    dropdownClose();
  };

  // if the search value is not present then it pick the category-tool-list.
  const searchCategoryToolList = searchValue ? allTools : categoryToolList;

  /* func: this return 2 things. 
    if showSearchOptionsInDropdown is true then the dropdown will be displayed. 
    if not then it return the tool-list of that specific category
  */
  const displayOptions = (searchText) => {
    if (showSearchOptionsInDropdown) {
      const display = allTools
        .filter((item) => {
          return (
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase())
          );
        })
        .map(({ title, description, id }) => {
          return dropdownSelect({ description, id, title });
        });
      return display;
    } else {
      // it filters out those tool-list according to category.
      const display = searchCategoryToolList
        .filter((item) => {
          return (
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase())
          );
        })
        .map(({ title, description, id }) => {
          return { id };
        });

      // it displays the searched tool-list of the specific category
      onSearchToolIds(display);
    }
  };

  // it renders the dropdown when a user search about tools
  const dropdownSelect = ({ description, title, id }) => {
    return {
      value: title,
      label: (
        <div className="search-description" onClick={() => linkClikcked(id)}>
          <NavLink className="title-link" key={'id'} to={`/tool/${id}`} onClick={dropdownClose}>
            {title}
          </NavLink>
          <Paragraph className="description">{description}</Paragraph>
        </div>
      ),
    };
  };

  const archive = () => <img src={SearchSvg} alt="search" />;

  //It is used to search for tools by their tool description or by category.
  return (
    <AutoCompleteWrapper
      className="search-tool"
      popupClassName="dropdown-tools"
      style={{
        width: '100%',
      }}
      options={options}
      onSearch={handleSearch}
      onClear={handleSearch}
      open={dropDownOpen}
      onBlur={dropdownClose}
      value={searchValue}
    >
      <Input
        allowClear
        prefix={<Icon component={archive} />}
        size="large"
        placeholder={placeholder}
        style={{ borderRadius: '8px' }}
      />
    </AutoCompleteWrapper>
  );
};

export default HeaderSearchTools;

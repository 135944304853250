/* ------------------------------------------------------ */
/*                      GPT API CALL                      */
/* ------------------------------------------------------ */

// @ Make note where API is controlled
// this is the file where the backend apis are hit.
import { TOOL_CRAFT, TOOL_USER_CANCEL_RESULTS } from '../constants';
import createAxiosInstance from './axiosConfriguration';

const OLD_CRAFTLY = 'https://app.craftly.ai';
const FIXED_CRAFTLY = 'https://craftly.web.app'; // this is for open.ai representatives.
const DEVELOPMENT = 'https://craftly-development.web.app';
const DEVELOPMENT_LOCAL = 'http://localhost:3001';
const DEVELOPMENT_LIVE = 'http://localhost:3000';

export const getAPI = () => {
  // it check from where the api is hit
  const browserURL = window.location.origin;
  const {
    REACT_APP_OLD_BACKEND_LINK,
    REACT_APP_FIXED_BACKEND_LINK,
    REACT_APP_DEVELOPMENT_LIVE_LINK,
    REACT_APP_DEVELOPMENT_LINK,
    REACT_APP_SAFEE_WORKING,
    REACT_APP_SAFEE_LOCAL_SERVER,
  } = process.env;
  switch (browserURL) {
    case OLD_CRAFTLY:
      return REACT_APP_OLD_BACKEND_LINK;
    case FIXED_CRAFTLY:
      return REACT_APP_FIXED_BACKEND_LINK;
    case DEVELOPMENT:
      return REACT_APP_DEVELOPMENT_LINK;
    case DEVELOPMENT_LIVE:
      return REACT_APP_DEVELOPMENT_LIVE_LINK;
    case DEVELOPMENT_LOCAL:
      return REACT_APP_SAFEE_WORKING === 'true' ? REACT_APP_SAFEE_LOCAL_SERVER : REACT_APP_DEVELOPMENT_LINK;
    default:
      return REACT_APP_DEVELOPMENT_LINK;
  }
};
/**
 * @param {the tool name  and input paramets } data
 * @returns output
 */
const gpt3Service = async ({ data, cancelRequest }) => {
  if (cancelRequest) {
    createAxiosInstance({ cancelRequest });
  } else {
    return await createAxiosInstance({ apiType: TOOL_CRAFT, cancelRequest })
      .post('/', data)
      .then(async (res) => {
        //< Success response
        // data is the prompts are coming from tools page and sending a request to the backend.
        const {
          data: { outputs, error, output_test, html },
        } = res;
        return { outputs, error, output_test, html };
      })
      .catch((err) => {
        // Error Handling
        if (err) {
          // when user switch the tool during tool crafting
          if (err.message === 'Request is canceled') {
            return {
              outputs: null,
              error: TOOL_USER_CANCEL_RESULTS,
            };
          } else {
            const { response } = err;
            // success api error response from backend
            if (response) {
              const errorMessage = err.response.data ? err.response.data.message : JSON.stringify(err);
              return {
                outputs: null,
                error: errorMessage,
              };
            } else {
              return {
                outputs: null,
                error: null,
              };
            }
          }
        } else {
          return {
            outputs: null,
            error: null,
          };
        }
      });
  }
};

export { gpt3Service };

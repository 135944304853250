import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Input, Checkbox, Typography } from 'antd';
import { Button } from '../../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthWrapper } from './style';
import { animation, login } from '../../../../redux/authentication/actionCreator';
import { fbAuthLogin, fbAuthLoginWithGoogle } from '../../../../redux/firebase/auth/actionCreator';
import { addNotificationError } from '../../../../components/utilities/notifications';
import { Helmet } from 'react-helmet';
import { PAGETILE } from '../../../../constants/content';
import { BUTTON_BLUE_GRADIENT } from '../../../../constants';

const { Text, Paragraph } = Typography;

const SignIn = () => {
  const dispatch = useDispatch();
  const { isLoading, error, isFbAuthenticate } = useSelector((state) => {
    return {
      error: state.firebaseAuth.error,
      isLoading: state.firebaseAuth.loading,
      isFbAuthenticate: state.fb.auth.uid,
    };
  });

  const [form] = Form.useForm();
  const [state, setState] = useState({
    checked: null,
  });

  const handleFbLogin = useCallback(() => {
    dispatch(login());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFbAuthenticate) {
      handleFbLogin();
    }
  }, [handleFbLogin, isFbAuthenticate]);

  const handleSubmit = (values) => {
    dispatch(fbAuthLogin(values));
  };

  const onChange = (checked) => {
    setState({ ...state, checked });
  };

  // these are auto generated errors which are not displayed on frontend
  const errorCodes = ['auth/popup-closed-by-user', 'auth/cancelled-popup-request'];

  const errorMessage = error && !errorCodes.includes(error.code) && error.message;
  const displayErrorMessage =
    errorMessage && error.code === 'auth/user-not-found' ? 'We couldn’t find your Craftly account!' : errorMessage;

  useEffect(() => {
    error && !errorCodes.includes(error.code) && addNotificationError(displayErrorMessage);
  }, [error]);

  const getAnimation = () => {
    dispatch(animation());
  };
  return (
    <AuthWrapper>
      <Helmet>
        <title>{PAGETILE('Sign In')}</title>
      </Helmet>

      <div className="auth-contents">
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
          <div className="heading">Login</div>

          <div className="auth-social" onClick={() => dispatch(fbAuthLoginWithGoogle())}>
            <div className="google-logo">
              <img className="google-icon" src={require('../../../../static/img/google.png')} alt="" />
              <div className="logo-divider" />
            </div>
            <span className="google-text">Sign in with Google</span>
          </div>

          <div className="sign-with-email">
            <Text className="sign-with-email-text">Or sign in with email</Text>
          </div>

          <Form.Item
            name="email"
            validateTrigger={['onBlur']}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid E-mail!',
              },
              {
                required: true,
                message: 'Please enter your E-mail!',
              },
              {
                validator: (_, value) => {
                  const emailAllow =
                    window.location.origin === 'https://app.craftly.ai' && value === 'hello@craftly.ai';
                  return emailAllow ? Promise.reject(new Error(' ')) : Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="Email" onClick={getAnimation} onFocus={getAnimation} />
          </Form.Item>

          <Form.Item rules={[{ message: 'Please input your password!', required: true }]} name="password">
            <Input.Password placeholder="Password" onClick={getAnimation} onFocus={getAnimation} />
          </Form.Item>
          <div className="auth-form-action">
            <Checkbox onChange={onChange} defaultChecked>
              <span className="keep-logged"> Keep me logged in</span>
            </Checkbox>
            <NavLink className="forgot-pass-link" to="/forgotPassword">
              Forgot password?
            </NavLink>
          </div>
          <Text type="danger">{displayErrorMessage}</Text>
          <Form.Item>
            <Button loading={isLoading} htmlType="submit" type={BUTTON_BLUE_GRADIENT} size="large" block>
              Login
            </Button>
          </Form.Item>

          <div onClick={getAnimation}>
            <Paragraph className="auth-notice">
              Don&rsquo;t have an account?{' '}
              <NavLink to="/signup" style={{ paddingLeft: '10px' }}>
                Sign up
              </NavLink>
            </Paragraph>
          </div>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default SignIn;

import Styled from 'styled-components';

const AuthStyleWrapper = Styled.div`

.right-side-section{
    height: calc(100vh - 220px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 650px;
    overflow-x: hidden;
}
.autho-txt{
    font-weight: 600;
    font-size: 70px;
    text-align: center;
    color: #242626;
    margin: 0 auto 0;
    max-width: 535px;
}
.autho-txt::after{
    content: '';
    background-image: url(${require('../../../../static/auth/animation-img/dotted02-shape.png')});
    width: 195px;
    height: 147px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    top: -70px;
    right: 0;
    z-index: -1;
}
.autho-txt::before{
    content: '';
    background-image: url(${require('../../../../static/auth/animation-img/shape04.png')});
    width: 121px;
    height: 109px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
     z-index: -1;
    transition: all 0.5s;
    bottom: 0;
    left:  ${({ animation }) => (animation ? `-${animation + 0}px` : '-0px')}; 

}
 
.ani-img0{
    background-image: url(${require('../../../../static/auth/animation-img/shape01.png')});
    width: 330px;
    height: 330px;
    max-width: 430px;
    max-height: 430px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    top: -120px;
    left:  ${({ animation }) => (animation ? `${animation + 470}px` : '470px')}; 
    transition: all .5s ease-in-out;
    z-index: -1;
 }
 
.ani-img1{
    background-image: url(${require('../../../../static/auth/animation-img/shape02.png')});
    width: 280px;
    height: 280px;
    max-width: 380px;
    max-height: 380px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    top: -174px;
     z-index: -1;
}
.ani-img2{
    background-image: url(${require('../../../../static/auth/animation-img/shape03.png')});
    width: 188px;
    height: 171px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: fixed;
    top: 50px;
    right: -85px;
    z-index: -1;
}
.ani-img4{
    background-image: url(${require('../../../../static/auth/animation-img/shape07.png')});
    width: 150px;
    height: 144px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
     bottom:  ${({ animation }) => (animation ? `${animation + 50}px` : '50px')}; 
    right:  ${({ animation }) => (animation ? `${animation + 75}px` : '75px')}; 
    z-index: -1;
    transition: all .5s ease-in-out;
}
 
.ani-img5{
    background-image: url(${require('../../../../static/auth/animation-img/shape06.png')});
    width: 188px;
    height: 171px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;
    bottom: -65px;
    left: 50%;
    left:  ${({ animation }) => (animation ? `${animation + 900}px` : '900px')}; 
     transition: all .5s ease-in-out;
    z-index: -1;
}
.ani-img6{
    background-image: url(${require('../../../../static/auth/animation-img/shape05.png')});
    width: 230px;
    height: 220px;
    max-width: 305px;
    max-height: 294px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    position: absolute;     
    left:  ${({ animation }) => (animation ? `${animation + 470}px` : '470px')}; 
    bottom:  ${({ animation }) => (animation ? `-${animation + 10}px` : '-10px')}; 
    transition: all .5s ease-in-out;
    z-index: -1;
}
 
/* responsive css start */


@media (min-width:2000px) {
    .ani-img0{
        width: 540px;
        height: 540px;
    }
    .ani-img1{
        width: 461px;
        height: 460px;
        top: -184px;
    }
    .ani-img2{
        width: 243px;
        height: 222px;
    }
    .ani-img4{
        width: 285px;
        height: 274px;    
    }
   
    .ani-img5 {
        width: 260px;
        height: 240px;
        bottom: -40px;
        left: 60%
    }
    .ani-img6{    
        width: 396px;
        height: 381px;
        bottom: -20px;
        left: 550px;
    }
    .autho-txt{
        font-size: 60px;
        max-width: 676px;
    }
    .autho-txt::before{
        width: 206px;
        height: 186px;
    }
    .autho-txt::after{
        width: 350px;
        height: 124px;
    }
}

@media (max-width:1599px) {
    .ani-img0{
        width: 300px;
        height: 300px;
    }
    .ani-img1{
        width: 231px;
        height: 250px;
        top: -174px;

     }
    .ani-img2{
        width: 188px;
        height: 171px;
    }
    .ani-img4{
        width: 130px;
        height: 124px;
    }
    .autho-txt::before{
        width: 85px;
        height: 77px;
    }
    .ani-img5 {
        width: 160px;
        height: 140px;
        bottom: -40px;
        left: 60%
    }
    .ani-img6{    
        width: 196px;
        height: 187px;
        bottom: -20px;
        left: 550px;
    }
    .autho-txt{
        font-size: 40px;
        max-width: 375px;
    }
    .autho-txt::after{
        width: 150px;
        height: 124px;
    }
}
@media (max-width:1199px){
    .ani-img0{
        width: 250px;
        height: 250px;
    }
    
    .ani-img4{
        right: 100px;
    }
    
    .right-side-section{
        padding-left: 600px;
    }
}
@media (max-width:991px){
   
    .right-side-section {
        height: calc(100vh - 240px);
        padding-left: 550px;
    }
    .footer{
        padding: 20px 12px;
        margin-left: 550px;
        height: 240px;
    }
    .custom-btn{
        min-width:200px;
        min-height: 45px;
    }
    footer h6{
        font-size: 18px;
    }
    .ani-img0{
        width: 230px;
        height: 230px;
        left: 303px;
        top: -60px;
        left: 420px;
    }
    .ani-img1 {
        width: 200px;
        height: 209px;
        top: -162px;
        left: 72%;
        
    }
    .ani-img2 {
        width: 125px;
        height: 110px;
    }
    .ani-img4 {
        width: 103px;
        height: 100px;
        bottom: 95px;
        right: 50px;
         
    }
    .ani-img5 {
        width: 105px;
        height: 93px;
        bottom: -35px;
        left: 70%;
    }
    .ani-img6 {
        width: 148px;
        height: 140px;
        bottom: -30px;
        left: 48%;
    }
}

@media (max-width:800px) {
     
    .right-side-section{
        height: 50%;
        padding-left: 0;
        position: relative;
        min-height: calc(100vh - 800px);
        overflow: hidden;
    }
   
    .ani-img0 {
        width: 100px;
        height: 100px;
        left: -55px;
        top: 0px;
    }
    .ani-img1 {
        width: 100px;
        height: 100px;
        top: -65px;
        left: 50%;
    }
   
    .ani-img2 {
        width: 80px;
        height: 70px;
        right: -45px;
    }
    .ani-img4 {
        display: none;
    }
    .ani-img5 {
        width: 80px;
        height: 70px;
        bottom: -15px;
        left: 65px;
    }
    .ani-img6 {
        width: 90px;
        height: 85px;
        bottom: -20px;
        left: -50px;
    }
    .autho-txt::after{
        width: 120px;
        height: 100px;
        top: -25px;
    }
    .autho-txt {
        font-size: 30px;
        max-width: 100%;
    }
    .autho-txt::before {
        display:none;

    }
}
@media (max-width:576px){
    .right-side-section {
        height: 100%;
        min-height: calc(100vh - 600px);
    }
    .autho-txt{
        font-size: 24px;
    }
}
`;

export { AuthStyleWrapper };

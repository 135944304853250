import React from 'react';
import { Typography } from 'antd';
import Capterra from '../../../static/earn/Capterra.svg';
import Google from '../../../static/earn/Google.svg';
import Instagram from '../../../static/earn/Instagram.svg';
import Twitter from '../../../static/earn/Twitter.svg';
import YouTube from '../../../static/earn/YouTube.svg';
import TikTok from '../../../static/earn/TikTok.svg';
import ProductHunt from '../../../static/earn/ProductHunt.svg';
import Link from '../../../static/earn/Link.svg';
import Submit from '../../../static/earn/Submit.svg';
import Mention from '../../../static/earn/Mention.svg';
import first from '../../../static/earn/Done.svg';
import second from '../../../static/earn/Done2.svg';
import third from '../../../static/earn/Done3.svg';
import FbIcon from '../../../static/earn/FbIcon.svg';
import LinkdinIcon from '../../../static/earn/LinkdinIcon.svg';
import InstaIcon from '../../../static/earn/InstaIcon.svg';
import TwitterIcon from '../../../static/earn/TwitterIcon.svg';

export const IconImagesData = [
  {
    src: FbIcon,
    alt: 'FbIcon',
    classname: '',
  },
  {
    src: TwitterIcon,
    alt: 'TwitterIcon',
    classname: 'tIcon',
  },
  {
    src: LinkdinIcon,
    alt: 'LinkdinIcon',
    classname: '',
  },
  {
    src: InstaIcon,
    alt: 'InstaIcon',
    classname: 'iIcon',
  },
];

export const ChatData = [
  { key: 'Google', img: Google, title: 'Google', days: '5 Days Free', link: 'Write Review' },
  { key: 'ProductHunt', img: ProductHunt, title: 'ProductHunt', days: '7 Days Free', link: 'Upvote & Comment' },
  { key: 'Twitter', img: Twitter, title: 'Twitter', days: '5 Days Free', link: 'Tweet Us' },
  { key: 'Instagram', img: Instagram, title: 'Intagram Story', days: '7 Days Free', link: 'Create' },
  { key: 'TikTok', img: TikTok, title: 'TikTok', days: '14 Days Free', link: 'Create Video' },
  { key: 'YouTube', img: YouTube, title: 'YouTube', days: '14 Days Free', link: 'Create' },
  { key: 'Capterra', img: Capterra, title: 'Capterra', days: '5 Days Free', link: 'Write Review' },
];

export const ShareLinkData = [
  {
    text: 'Visit',
    number: 0,
  },
  {
    text: 'Lead',
    number: 0,
  },
  {
    text: 'Conversion',
    number: 0,
  },
];

export const FeaturesCard = [
  {
    key: 'link',
    des: (
      <Typography>
        Link our website to your in a new or existing article using{' '}
        <a href="https://craftly.ai" className="craftly-link">
          https://craftly.ai{' '}
        </a>{' '}
      </Typography>
    ),
    icon: Link,
    icon1: first,
  },
  { key: 'chat', des: 'Mention us!', icon: Mention, icon1: second },
  { key: 'submit', des: 'Submit the URL via our chatbot have your creadits applylied', icon: Submit, icon1: third },
];

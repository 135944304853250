/* ------------------------------------------------------ */
/*                       Card Design                      */
/* ------------------------------------------------------ */

/*
Methadology
We have created our own customized Breadcrumb styles
there are two type of antd cards. 
1. headless  
2. With heading and Description.

Styled-component is using to customized the antd card design
*/

import React from 'react';
import { NavLink } from 'react-router-dom';
import { SmallDashOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import Heading from '../Headings';
import { DropdownOptions } from '../DropdownOptions';
import { CardFrame } from './style';

const Cards = (props) => {
  const {
    title,
    children,
    more,
    moreText,
    size,
    headless,
    caption,
    isbutton,
    bodyStyle,
    headStyle,
    border,
    loading,
    bodypadding,
    borderRadius,
    cursor,
    hover,
    background,
    borderColor,
  } = props;
  return (
    <>
      {!headless ? (
        <CardFrame
          size={size}
          title={title}
          bodyStyle={bodyStyle && bodyStyle}
          headStyle={headStyle && headStyle}
          bordered={border}
          loading={loading}
          bodypadding={bodypadding && bodypadding}
          borderRadius={borderRadius && borderRadius}
          cursor={cursor && cursor}
          extra={
            <>
              {more && (
                <DropdownOptions content={more} placement="bottomCenter">
                  <NavLink to="#">{!moreText ? <SmallDashOutlined /> : 'More'}</NavLink>
                </DropdownOptions>
              )}

              {isbutton && isbutton}
            </>
          }
          style={{ width: '100%' }}
        >
          {children} {/* the card body content */}
        </CardFrame>
      ) : (
        <CardFrame
          loading={loading}
          bodypadding={bodypadding && bodypadding}
          bodyStyle={bodyStyle && bodyStyle}
          size={size}
          style={{ width: '100%' }}
          bordered={border}
          borderRadius={borderRadius && borderRadius}
          cursor={cursor && cursor}
          hover={hover && hover}
          background={background && background}
          bordercolor={borderColor && borderColor}
        >
          {/* on the top of the contnet there will be title and description  */}
          {title && <Heading as="h4">{title}</Heading>}
          {caption && <p>{caption}</p>}
          {children}
        </CardFrame>
      )}
    </>
  );
};

Cards.defaultProps = {
  border: false,
};

Cards.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  size: PropTypes.string,
  more: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
  bodyStyle: PropTypes.object,
  headStyle: PropTypes.object,
  isbutton: PropTypes.node,
  headless: PropTypes.bool,
  border: PropTypes.bool,
  caption: PropTypes.string,
  bodypadding: PropTypes.string,
  moreText: PropTypes.bool,
  cursor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

export { Cards };

/* ------------------------------------------------------ */
/*                       TEAM HEADER                      */
/* ------------------------------------------------------ */

/**
 * Methadology
 * This component is used in Team /team-portal
 * This a team header to navigate the team related pages.
 *
 * This component is being used on every team page. on the selection of the menu link the page redirects
 * it also highlights the current page selection.
 *
 * Admin view is also control from here. Craftly admin can review any team user details.
 * mirrorTeamUID is the main state to determine to enable craftly-admin view in team portal
 *
 */

/**
 * Used In
 * src/container/TeamPortal/overview/TeamResults.js
 * src/container/TeamPortal/overview/MemberSummary.js
 * src/container/TeamPortal/TeamPortal.js
 */

import React, { useState, useEffect } from 'react';
import { Typography, Menu, Row, Col, Divider, Select } from 'antd';
import { TeamHeaderWrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import { TEAM_DASHBOARD_PAGE, TEAM_MEMBERS_PAGE, TEAM_RESULTS } from '../../constants';
import { capitalizeFirstLetter } from '../../utility/utility';
import { useDispatch, useSelector } from 'react-redux';
import { setMirrorTeamUID } from '../../redux/team/actionCreator';
import { Button } from '../Button';

const { Title, Text } = Typography;
const { Option } = Select;

/**
 * @string name: current team name
 * @boolean isCraftlyAdminView: check whether the admin is reviewing team or not
 * @string currentPageToDisplay: save current page id for menu navigation
 * @string showPageName: its flag to redirect to the other origin page routes.
 * @string currentPageLink: its a link for current page
 */

const TeamHeader = ({ name, isCraftlyAdminView, currentPageToDisplay, showPageName, currentPageLink, viewAsUser }) => {
  const [current, setCurrent] = useState(TEAM_DASHBOARD_PAGE);
  const dispatch = useDispatch();
  const { teamList, mirrorTeamUID } = useSelector((state) => state.team);

  const navigate = useNavigate();

  // it controls the navigation
  const handleClick = (e) => {
    const link = e.key;
    setCurrent(link);
    if (link === TEAM_DASHBOARD_PAGE) {
      navigate('/team-portal');
    } else if (link === TEAM_MEMBERS_PAGE) {
      navigate(`/team-portal/team-members`);
    } else if (link === TEAM_RESULTS) {
      navigate(`/${link}`);
    } else if (showPageName) {
      navigate('/team-portal');
    } else {
      currentPageLink(link);
    }
  };

  useEffect(() => {
    setCurrent(currentPageToDisplay);
  }, [currentPageToDisplay]);

  const titleDisplay = [
    {
      id: TEAM_DASHBOARD_PAGE,
      text: `Hello ${name}, Welcome Back`,
    },
    { id: TEAM_MEMBERS_PAGE, text: `Team Members` },
    { id: TEAM_RESULTS, text: `Results` },
  ];

  function handleChange(value) {
    dispatch(setMirrorTeamUID(value));
    navigate('/team-portal');
  }

  return (
    <TeamHeaderWrapper>
      {/* this is for super (craftly) admin */}
      {isCraftlyAdminView && (
        <div className="admin-view">
          <Row justify="space-between" align={'middle'}>
            <Col>
              <Title level={4}>Craftly Admin View</Title>
              <Text strong>Team: {capitalizeFirstLetter(name)} </Text>
            </Col>
            <Col>
              <Select
                style={{ width: 300, marginRight: '1rem' }}
                defaultValue={[mirrorTeamUID]}
                onChange={handleChange}
              >
                {teamList &&
                  teamList.map(({ teamName, adminUID }) => {
                    return <Option value={adminUID}>{`${teamName}`}</Option>;
                  })}
              </Select>

              <Button size="small" type="primary" onClick={viewAsUser}>
                {`Cancel View`}
              </Button>
            </Col>
          </Row>
        </div>
      )}
      {isCraftlyAdminView && <Divider />}
      <Row>
        <Col xs={24} sm={8}>
          {titleDisplay
            .filter(({ id }) => id === current)
            .map(({ text }) => {
              return (
                <Title className="title" level={4}>
                  {text}
                </Title>
              );
            })}
        </Col>
        <Col xs={24} sm={16}>
          <Menu className="team-menu" onClick={handleClick} selectedKeys={[current]} mode="horizontal">
            <Menu.Item key={TEAM_DASHBOARD_PAGE}>Dashboard</Menu.Item>
            <Menu.Item key={TEAM_MEMBERS_PAGE}>Team</Menu.Item>
            <Menu.Item key={TEAM_RESULTS}>Results</Menu.Item>
          </Menu>
        </Col>
      </Row>
    </TeamHeaderWrapper>
  );
};

export default TeamHeader;

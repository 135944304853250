import React from 'react';

export const SideBarLogo = ({ logo, color = '#282B3F' }) => {
  return (
    <>
      {logo === 'blog' && (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0534 2.98183H6.0636C4.5036 2.97583 3.22485 4.21933 3.1881 5.77858V13.8133C3.1536 15.3981 4.40985 16.7113 5.9946 16.7466C6.01785 16.7466 6.04035 16.7473 6.0636 16.7466H12.0554C13.6259 16.6828 14.8634 15.3853 14.8521 13.8133V6.93883L11.0534 2.98183Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.8563 2.97302V5.15477C10.8563 6.21977 11.7173 7.08302 12.7823 7.08602H14.8486"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.7161 12.4294H6.66608"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.18241 9.61499H6.66541"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {logo === 'all-tools' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.40494 0.5H4.93994C5.99744 0.5 6.84494 1.3625 6.84494 2.42075V4.9775C6.84494 6.0425 5.99744 6.8975 4.93994 6.8975H2.40494C1.35494 6.8975 0.499939 6.0425 0.499939 4.9775V2.42075C0.499939 1.3625 1.35494 0.5 2.40494 0.5ZM2.40494 9.10227H4.93994C5.99744 9.10227 6.84494 9.95802 6.84494 11.023V13.5798C6.84494 14.6373 5.99744 15.4998 4.93994 15.4998H2.40494C1.35494 15.4998 0.499939 14.6373 0.499939 13.5798V11.023C0.499939 9.95802 1.35494 9.10227 2.40494 9.10227ZM13.595 0.5H11.06C10.0025 0.5 9.15501 1.3625 9.15501 2.42075V4.9775C9.15501 6.0425 10.0025 6.8975 11.06 6.8975H13.595C14.645 6.8975 15.5 6.0425 15.5 4.9775V2.42075C15.5 1.3625 14.645 0.5 13.595 0.5ZM11.06 9.10227H13.595C14.645 9.10227 15.5 9.95802 15.5 11.023V13.5798C15.5 14.6373 14.645 15.4998 13.595 15.4998H11.06C10.0025 15.4998 9.15501 14.6373 9.15501 13.5798V11.023C9.15501 9.95802 10.0025 9.10227 11.06 9.10227Z"
            fill={color}
          />
        </svg>
      )}
      {logo === 'ecommerce' && (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.06258 3.3479L3.62258 3.6179L4.34483 12.2227C4.40258 12.9254 4.98983 13.4647 5.69483 13.4624H13.8766C14.5493 13.4639 15.1201 12.9689 15.2153 12.3029L15.9271 7.3844C16.0066 6.83465 15.6248 6.32465 15.0758 6.24515C15.0278 6.2384 3.87308 6.23465 3.87308 6.23465"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.5938 9.00659H12.6736"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.36579 16.0624C5.59154 16.0624 5.77379 16.2454 5.77379 16.4704C5.77379 16.6961 5.59154 16.8791 5.36579 16.8791C5.14004 16.8791 4.95779 16.6961 4.95779 16.4704C4.95779 16.2454 5.14004 16.0624 5.36579 16.0624Z"
            fill="#282B3F"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.826 16.0624C14.0518 16.0624 14.2348 16.2454 14.2348 16.4704C14.2348 16.6961 14.0518 16.8791 13.826 16.8791C13.6003 16.8791 13.418 16.6961 13.418 16.4704C13.418 16.2454 13.6003 16.0624 13.826 16.0624Z"
            fill="#282B3F"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {logo === 'webcopy' && (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.61923 3.0022H5.81498C3.50873 3.0022 2.06273 4.63495 2.06273 6.94645V13.1819C2.06273 15.4934 3.50198 17.1262 5.81498 17.1262H12.433C14.7467 17.1262 16.186 15.4934 16.186 13.1819V10.1609"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.62085 9.10114L12.2256 3.49639C12.9238 2.79889 14.0556 2.79889 14.7538 3.49639L15.6666 4.40914C16.3648 5.10739 16.3648 6.23989 15.6666 6.93739L10.0348 12.5691C9.7296 12.8744 9.3156 13.0461 8.8836 13.0461H6.0741L6.1446 10.2111C6.1551 9.79414 6.32535 9.39664 6.62085 9.10114Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.3739 4.3623L14.7984 7.7868"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {logo === 'company' && (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.63037 13.0075V11.1047"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2763 4.49756C15.5438 4.49756 16.5638 5.52506 16.5638 6.79256V9.37256C14.7188 10.4526 12.2813 11.1051 9.62629 11.1051C6.97129 11.1051 4.54129 10.4526 2.69629 9.37256V6.78506C2.69629 5.51756 3.72379 4.49756 4.99129 4.49756H14.2763Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.2554 4.49426V4.21976C12.2554 3.30476 11.5129 2.56226 10.5979 2.56226H8.66287C7.74787 2.56226 7.00537 3.30476 7.00537 4.21976V4.49426"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.71484 12.1121L2.85659 13.9938C2.95259 15.2621 4.00934 16.2423 5.28059 16.2423H13.9798C15.2511 16.2423 16.3078 15.2621 16.4038 13.9938L16.5456 12.1121"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {logo === 'seo' && (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.5137 13.7744L15.1568 16.4106"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14.5174 7.88478C14.9278 7.83267 15.3068 8.12351 15.3177 8.53712C15.3533 9.88846 15.0227 11.2294 14.3543 12.4151C13.5628 13.8191 12.3399 14.9306 10.8668 15.5847C9.39377 16.2388 7.74912 16.4006 6.17684 16.0462C4.60456 15.6918 3.18839 14.84 2.13843 13.6172C1.08846 12.3944 0.460622 10.8657 0.348048 9.25792C0.235474 7.65012 0.64416 6.04886 1.51348 4.69167C2.3828 3.33448 3.66645 2.29366 5.17401 1.72361C6.44712 1.2422 7.82264 1.11819 9.15306 1.35772C9.56027 1.43104 9.79046 1.8496 9.67688 2.24747C9.5633 2.64533 9.14876 2.87079 8.73985 2.80767C7.72162 2.65049 6.67552 2.75773 5.70396 3.1251C4.49777 3.5812 3.47073 4.41395 2.77519 5.49983C2.07965 6.58571 1.75266 7.86687 1.84273 9.15326C1.9328 10.4397 2.43513 11.6627 3.27521 12.6411C4.11528 13.6195 5.24834 14.301 6.50632 14.5845C7.76429 14.8681 9.08017 14.7386 10.2587 14.2153C11.4373 13.6919 12.4158 12.8026 13.049 11.6793C13.5591 10.7745 13.8233 9.7566 13.8219 8.72631C13.8214 8.31256 14.1069 7.93689 14.5174 7.88478Z"
            fill={color}
          />
          <path
            d="M3.89127 10.5197L6.49108 7.1407L9.45661 9.4702L12.0008 6.18665"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            cx="14.1833"
            cy="2.70818"
            r="1.6696"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {logo === 'sales' && (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.59618 4.2025C2.59618 3.26575 3.35518 2.50675 4.29193 2.506H5.06368C5.51143 2.506 5.94043 2.32825 6.25843 2.01325L6.79768 1.47325C7.45843 0.808751 8.53243 0.805751 9.19693 1.4665L9.19768 1.46725L9.20443 1.47325L9.74443 2.01325C10.0624 2.329 10.4914 2.506 10.9392 2.506H11.7102C12.6469 2.506 13.4067 3.265 13.4067 4.2025V4.97275C13.4067 5.4205 13.5837 5.85025 13.8994 6.16825L14.4394 6.70825C15.1039 7.369 15.1077 8.443 14.4469 9.1075L14.4462 9.10825L14.4394 9.115L13.8994 9.655C13.5837 9.97225 13.4067 10.4013 13.4067 10.849V11.6208C13.4067 12.5575 12.6477 13.3165 11.7109 13.3165H11.7102H10.9377C10.4899 13.3165 10.0602 13.4943 9.74293 13.81L9.20293 14.3493C8.54293 15.0138 7.46968 15.0175 6.80518 14.3583C6.80443 14.3575 6.80368 14.3568 6.80293 14.356L6.79618 14.3493L6.25693 13.81C5.93968 13.4943 5.50993 13.3173 5.06218 13.3165H4.29193C3.35518 13.3165 2.59618 12.5575 2.59618 11.6208V10.8475C2.59618 10.3998 2.41843 9.97075 2.10268 9.6535L1.56343 9.1135C0.89893 8.4535 0.89518 7.38025 1.55518 6.71575C1.55518 6.715 1.55593 6.71425 1.55668 6.7135L1.56343 6.70675L2.10268 6.16675C2.41843 5.84875 2.59618 5.41975 2.59618 4.97125V4.2025"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.0737 9.83925L9.9287 5.98425"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.87162 9.78552H9.87837"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.12162 6.03552H6.12837"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}

      {logo === 'email' && (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.427 7.13672L10.0945 9.8465C9.46488 10.346 8.57903 10.346 7.94941 9.8465L4.58882 7.13672"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.6816 16.25C14.9627 16.2563 16.5 14.3822 16.5 12.0788V6.92751C16.5 4.62412 14.9627 2.75 12.6816 2.75H5.31835C3.03734 2.75 1.5 4.62412 1.5 6.92751V12.0788C1.5 14.3822 3.03734 16.2563 5.31835 16.25H12.6816Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {logo === 'student' && (
        <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.16371 6.08247L1.39142 3.54483L8.17118 1.0798C8.35056 1.01458 8.54731 1.01533 8.72619 1.08193L15.3418 3.54483L8.73359 6.08024C8.55028 6.15057 8.34757 6.15136 8.16371 6.08247Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3417 5.84924V10.6433"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.7311 8.65211L8.7315 8.65196L12.842 7.05175C12.8417 8.11077 12.8417 9.47704 12.8417 9.82304C12.8368 9.83026 12.8307 9.83881 12.8233 9.8487C12.7647 9.92666 12.6581 10.0367 12.4914 10.1726C12.1604 10.4423 11.681 10.743 11.1344 11.027C10.5918 11.3089 10.009 11.5609 9.48462 11.7406C8.94053 11.927 8.52754 12.0115 8.2991 12.0115C8.07329 12.0115 7.66214 11.9257 7.11728 11.7358C6.59279 11.553 6.00958 11.2971 5.46602 11.0121C4.91874 10.7252 4.43741 10.4224 4.10454 10.1528C3.93671 10.0169 3.82906 9.90741 3.76991 9.83036C3.76461 9.82346 3.76001 9.81723 3.75604 9.81167C3.756 9.4479 3.75576 8.09943 3.75547 7.05182L7.86414 8.65192L7.86464 8.65211C8.14348 8.76048 8.45226 8.76048 8.7311 8.65211ZM12.8596 9.79274C12.8597 9.79273 12.8592 9.79401 12.8578 9.79661C12.8589 9.79405 12.8596 9.79275 12.8596 9.79274ZM3.73376 9.7761C3.73388 9.77612 3.73484 9.77787 3.73632 9.78129C3.73438 9.7778 3.73365 9.77609 3.73376 9.7761Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {logo === 'social' && (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.0498 8.17255C15.0961 8.02555 15.9016 7.12855 15.9038 6.0418C15.9038 4.9708 15.1231 4.08279 14.0993 3.91479"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.4302 10.6877C16.4434 10.8392 17.1507 11.194 17.1507 11.9252C17.1507 12.4285 16.8177 12.7555 16.2792 12.961"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.54884 10.998C7.13834 10.998 5.07959 11.3633 5.07959 12.822C5.07959 14.28 7.12559 14.6558 9.54884 14.6558C11.9593 14.6558 14.0173 14.2943 14.0173 12.8348C14.0173 11.3753 11.9721 10.998 9.54884 10.998Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.54884 8.916C11.1306 8.916 12.4131 7.63425 12.4131 6.05175C12.4131 4.47 11.1306 3.1875 9.54884 3.1875C7.96709 3.1875 6.68459 4.47 6.68459 6.05175C6.67859 7.62825 7.95134 8.91075 9.52784 8.916H9.54884Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.04736 8.17255C4.00036 8.02555 3.19561 7.12855 3.19336 6.0418C3.19336 4.9708 3.97411 4.08279 4.99786 3.91479"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.66679 10.6877C2.65354 10.8392 1.94629 11.194 1.94629 11.9252C1.94629 12.4285 2.27929 12.7555 2.81779 12.961"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {logo === 'fun' && (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.63619 10.146V12.9547"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.06928 11.5506H5.20343"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.5246 10.2315H11.4443"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.8847 12.9125H12.8044"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.05412 2.41052C6.05412 2.96588 6.51349 3.41609 7.08014 3.41609H7.8725C8.74672 3.41921 9.45483 4.11322 9.45881 4.97002V5.47631"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3212 17.3825C10.0673 17.4206 7.85477 17.4191 5.67956 17.3825C3.26513 17.3825 1.5 15.6603 1.5 13.294V9.80679C1.5 7.44047 3.26513 5.7183 5.67956 5.7183C7.8667 5.68091 10.0809 5.68169 12.3212 5.7183C14.7357 5.7183 16.5 7.44125 16.5 9.80679V13.294C16.5 15.6603 14.7357 17.3825 12.3212 17.3825Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}

      {logo === 'estate' && (
        <svg width="19" height="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <g data-name="13-House">
            <path
              stroke={color}
              d="m31.62 12.22-15-12a1 1 0 0 0-1.25 0l-15 12A1 1 0 0 0 1 14h2v17a1 1 0 0 0 1 1h20v-2h-4v-9a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v9H5V14h17v-2H3.85L16 2.28 28.15 12H28a1 1 0 0 0-1 1v7h2v-6h2a1 1 0 0 0 .62-1.78zM14 22h4v8h-4z"
            />
            <path stroke={color} d="M23 25h8v2h-8z" />
          </g>
        </svg>
      )}
      {logo === 'ai-image-generation' && (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M7.51689 15.5H11.1169C14.1169 15.5 15.3169 14.3 15.3169 11.3V7.7C15.3169 4.7 14.1169 3.5 11.1169 3.5H7.51689C4.51689 3.5 3.31689 4.7 3.31689 7.7V11.3C3.31689 14.3 4.51689 15.5 7.51689 15.5Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.19189 13.25H10.4419C12.3169 13.25 13.0669 12.5 13.0669 10.625V8.375C13.0669 6.5 12.3169 5.75 10.4419 5.75H8.19189C6.31689 5.75 5.56689 6.5 5.56689 8.375V10.625C5.56689 12.5 6.31689 13.25 8.19189 13.25Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M6.3244 3.5V2" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.31689 3.5V2" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.3169 3.5V2" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M15.3169 6.5H16.8169"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3169 9.5H16.8169"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3169 12.5H16.8169"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M12.3169 15.5V17" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.3244 15.5V17" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.3244 15.5V17" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path
            d="M1.81689 6.5H3.31689"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.81689 9.5H3.31689"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.81689 12.5H3.31689"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </>
  );
};

/* ------------------------------------------------------ */
/*                      Mobile Footer                     */
/* ------------------------------------------------------ */

/**
 *
 * MobileFooter only visible in mobile view
 */

import { Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Tools from '../../static/img/mobile-footer/Category.svg';
import Time from '../../static/img/mobile-footer/TimeCircle.svg';
import Folder from '../../static/img/mobile-footer/Folder.svg';
import Vector from '../../static/img/mobile-footer/Vector.svg';
import { MobileFooterStyled } from './styled';

const { Text } = Typography;

const MobileFooter = ({ sideBarClicked, collapsed }) => {
  const navigate = useNavigate();
  const FOOTER_MENU = [
    {
      title: 'Tools',
      image: Tools,
      height: '26px',
      pageLink: '/',
    },

    {
      title: 'Recent',
      image: Time,
      height: '26px',
      pageLink: '/favourite',
    },
    {
      title: 'Saved',
      image: Folder,
      height: '26px',
      pageLink: '/',
    },
    {
      title: 'Help',
      image: Vector,
      height: '22px',
      pageLink: '/',
    },
  ];
  const onFooterMenuClick = (pageLink) => {
    navigate(pageLink);
  };

  return (
    <MobileFooterStyled sideBarClicked={sideBarClicked} collapsed={collapsed}>
      {FOOTER_MENU.map(({ title, image, height, pageLink }) => (
        <div className="mobile-footer-container" onClick={() => onFooterMenuClick(pageLink)}>
          <img width="26px" height={height} src={image} alt="tools" />
          <Text>{title}</Text>
        </div>
      ))}
    </MobileFooterStyled>
  );
};

export default MobileFooter;

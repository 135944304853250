/* ------------------------------------------------------ */
/* TRIAL BILLING PAGE. */
/* ------------------------------------------------------ */
// | This page is for normal users. This page is not for team members,
// ! as they join craftly without any credit card info and plan

import React, { useState, useEffect, Fragment } from 'react';
import { Card, Row, Col, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button';
import PlanPoints from '../../components/PlanPoints';
import Signout from '../../components/Signout';
import {
  CRAFTER,
  ENTERPRISE,
  MONTHLY_DURATION,
  ROLES_LIST,
  STARTER,
  STRIPE_STARTER_MONTHLY_PRICEID,
  TEAM_STATUS,
  YEARLY_DURATION,
} from '../../constants';
import { adminBillingSettingAction } from '../../redux/Billing/actionCreator';
import { checkUserPresentInTeam } from '../../redux/AccountSettings/actionCreator';
import { fetchAppSettings } from '../../redux/AdminFeatures/actionCreator';
import { PlanDurationSwitch } from '../../components/PlanDurationSwitch';
import { TrialBilling, TrialBillingWrapper, MobileTrail } from './styled';
import { CustomSlider } from '../../components/CustomSlider';
import { TRAIL_STARTER_DATA, TRAIL_CUSTOM_DATA, TRAIL_CRAFTER_DATA } from '../../constants/trailprice';
import PriceList from './overview/PriceList';
import TrialFaq from './overview/TrailFaq';
import TrailMobileCard from './overview/TrailMobileCard';
import { useViewport } from '../../hooks/viewPort';
import { currentPricingModel, planTiers } from '../../utility/stripePlan';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKey } from '../../utility/stripePlan';
import CheckoutFormV2 from './overview/StripeElement/CheckoutFormV2';
import CheckoutFormV1 from './overview/StripeElement/CheckoutForm';
import AppFooter from '../../components/AppFooter';

const { Title, Text } = Typography;

const { v1PriceModal, v2PriceModal } = currentPricingModel();
const DisplayCheckoutForm = v1PriceModal ? CheckoutFormV1 : v2PriceModal ? CheckoutFormV2 : null;

const Trial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();
  const stripePromise = loadStripe(stripeKey);

  // default plan select, Crafter is default plan selected.
  const [planSelect, setPlanSelect] = useState(CRAFTER);
  const [tabClickMobile, setTabClickMobile] = useState(CRAFTER);
  const [planChecked, setPlanChecked] = useState({
    checked: false,
    planInterval: MONTHLY_DURATION,
  });

  const [starterPrice, setStarterPrice] = useState(0);
  const [starterWords, setStarterWords] = useState(500000);
  const [selectedStripePriceId, setSelectedStripePriceId] = useState(STRIPE_STARTER_MONTHLY_PRICEID);
  const [selectedPlanWords, setSelectedPlanWords] = useState(100000);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState(99);
  const [selectedProductId, setSelectedProductId] = useState(false);
  const [starterSliderValue, setStarterSliderValue] = useState(100);

  const [crafterPrice, setCrafterPrice] = useState(0);
  const [crafterWords, setCrafterWords] = useState(100000);
  const [crafterSliderValue, setCrafterSliderValue] = useState(40);

  const { uid } = useSelector((state) => state.fb.auth);
  const { adminBillingSettings } = useSelector((state) => state.billing);
  const { usersTeams } = useSelector((state) => state.accountSettings);

  const {
    profile: { email, trialBillingPage, roles, stripeId, activatedPlanDetails, currentInvoiceId, firstName, lastName },
  } = useSelector((state) => {
    return {
      profile: state.fb.profile,
    };
  });

  useEffect(() => {
    if (trialBillingPage === true) {
      navigate('/');
    }
    if (ROLES_LIST.includes(roles)) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, trialBillingPage]);

  const PriceCardData =
    tabClickMobile === STARTER
      ? TRAIL_STARTER_DATA
      : tabClickMobile === CRAFTER
      ? TRAIL_CRAFTER_DATA
      : TRAIL_CUSTOM_DATA;

  useEffect(() => {
    dispatch(checkUserPresentInTeam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, uid]);

  // This executes when page reload.
  useEffect(() => {
    // In firebase we have a collection stripe-plan, from which a stripe plan id is fetched.
    dispatch(adminBillingSettingAction());

    //? This fetch the settings of Trial methods. if noCredit Card is enable then user can go without giving credit card information.
    dispatch(fetchAppSettings());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (usersTeams && usersTeams.length > 0) {
      const checkAllDecline = usersTeams.filter(
        ({ teamInviteStatus }) => teamInviteStatus === TEAM_STATUS.DECLINE,
      ).length;

      if (usersTeams.length !== checkAllDecline) {
        navigate('/join-team');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersTeams]);

  const onPlanIntervalChange = (value) => {
    setPlanChecked({
      checked: value,
      planInterval: value === true ? YEARLY_DURATION : MONTHLY_DURATION,
    });
  };
  const displayStarterTiers =
    planTiers && planTiers.find(({ plan, interval }) => plan === STARTER && interval === planChecked.planInterval);

  const displayCrafterTiers = planTiers.find(
    ({ plan, interval }) => plan === CRAFTER && interval === planChecked.planInterval,
  );

  const selectedSliderTier = ({ sliderKeyId, planType }) => {
    if (planType === STARTER) {
      const getPlanTiers =
        planTiers && planTiers.find(({ plan, interval }) => plan === planType && interval === planChecked.planInterval);

      const getWordsPerPrice =
        getPlanTiers &&
        Array.isArray(getPlanTiers.tiers) &&
        getPlanTiers.tiers.find(({ markKey }) => markKey === sliderKeyId);
      const { amount, words } = getWordsPerPrice;
      const { stripePriceId } = getPlanTiers;
      setStarterPrice(amount);
      setStarterWords(words);
      setSelectedStripePriceId(stripePriceId);
      setSelectedPlanWords(words);
      setSelectedPlanPrice(amount);
      setStarterSliderValue(sliderKeyId);
      setPlanSelect(STARTER);
    } else if (planType === CRAFTER) {
      const getPlanTiers =
        planTiers && planTiers.find(({ plan, interval }) => plan === planType && interval === planChecked.planInterval);

      const getWordsPerPrice =
        getPlanTiers &&
        Array.isArray(getPlanTiers.tiers) &&
        getPlanTiers.tiers.find(({ markKey }) => markKey === sliderKeyId);

      const { amount, words } = getWordsPerPrice;
      const { stripePriceId } = getPlanTiers;

      setCrafterPrice(amount);
      setCrafterWords(words);
      setSelectedPlanWords(words);
      setSelectedPlanPrice(amount);
      setSelectedStripePriceId(stripePriceId);
      setCrafterSliderValue(sliderKeyId);
      setPlanSelect(CRAFTER);
    }
  };

  useEffect(() => {
    const getPlanTiers = planTiers.find(
      ({ plan, interval }) => plan === STARTER && interval === planChecked.planInterval,
    );
    const getWordsPerPrice =
      getPlanTiers &&
      Array.isArray(getPlanTiers.tiers) &&
      getPlanTiers.tiers.find(({ markKey }) => markKey === starterSliderValue);
    const { words, amount } = getWordsPerPrice || { amount: '', words: '' };
    setStarterPrice(amount);
    setStarterWords(words);
    if (planSelect === STARTER) {
      const getPlanTiers = planTiers.find(
        ({ plan, interval }) => plan === STARTER && interval === planChecked.planInterval,
      );
      const { stripePriceId } = getPlanTiers;
      setSelectedStripePriceId(stripePriceId);
      setSelectedPlanWords(words);
      setSelectedPlanPrice(amount);
      const planDetails = adminBillingSettings.find(({ plan }) => plan === planSelect);
      const { productID } = planDetails.details;
      setSelectedProductId(productID);
    }

    const getCrafterPlanTiers = planTiers.find(
      ({ plan, interval }) => plan === CRAFTER && interval === planChecked.planInterval,
    );

    const getCrafterWordsPerPrice =
      getCrafterPlanTiers &&
      Array.isArray(getCrafterPlanTiers.tiers) &&
      getCrafterPlanTiers.tiers.find(({ markKey }) => markKey === crafterSliderValue);
    const { words: crafterWord, amount: crafterAmount } = getCrafterWordsPerPrice || { amount: '', words: '' };
    setCrafterWords(crafterWord);
    setCrafterPrice(crafterAmount);

    if (planSelect === CRAFTER) {
      const getPlanTiers = planTiers.find(
        ({ plan, interval }) => plan === CRAFTER && interval === planChecked.planInterval,
      );
      const { stripePriceId } = getPlanTiers;
      setSelectedStripePriceId(stripePriceId);
      setSelectedPlanWords(crafterWord);
      setSelectedPlanPrice(crafterAmount);

      const planDetails = adminBillingSettings.find(({ plan }) => plan === planSelect);
      const { productID } = (planDetails && planDetails.details) || { productID: '' };
      setSelectedProductId(productID);
    }
  }, [adminBillingSettings, crafterSliderValue, planChecked, planSelect, starterSliderValue]);

  // this is just to make fix value on slider
  const onStarterSliderChange = (value) => {
    if (starterSliderValue === 0) {
      setStarterSliderValue(100);
      selectedSliderTier({ sliderKeyId: 100, planType: STARTER });
    } else {
      setStarterSliderValue(0);
      selectedSliderTier({ sliderKeyId: 0, planType: STARTER });
    }
  };

  const generateStarterMarks = () => {
    const marks = {};
    displayStarterTiers &&
      Array.isArray(displayStarterTiers.tiers) &&
      displayStarterTiers.tiers.forEach(({ markKey, markValue }) => {
        marks[markKey] = markValue;
      });
    return marks;
  };
  const generateCrafterMarks = () => {
    const marks = {};
    displayCrafterTiers &&
      Array.isArray(displayCrafterTiers.tiers) &&
      displayCrafterTiers.tiers.forEach(({ markKey, markValue }) => {
        marks[markKey] = markValue;
      });
    return marks;
  };

  // on clicking the trial button.
  const handleClickScroll = () => {
    const element = document.getElementById('payment');
    if (element) {
      // 👇 Will scroll smoothly to the top of the billing section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const planChangeMobile = (plan) => {
    setTabClickMobile(plan);
    setPlanSelect(plan);
  };
  return (
    <TrialBillingWrapper>
      <Row justify="space-between">
        <Col xs={6} sm={3} md={2}>
          <img
            className="logo"
            style={{ width: '100%', margin: '2rem' }}
            src={require('../../static/general/craftly_final.png')}
            alt="logo"
          />
        </Col>
        <Col>
          <div style={{ margin: '2rem' }}>
            <Signout />
          </div>
        </Col>
      </Row>

      <TrialBilling>
        <div className="top-section">
          <div className="heading-wrapper">
            <Title level={3} className="heading">
              Pricing plans designed <br /> to fit your needs!
              <img alt="like" className="thumbs-icon" src={require('../../static/trial/like.png')} />{' '}
            </Title>
          </div>
          <div className="plan-type-switch">
            <PlanDurationSwitch
              rightText={width < 731 ? 'Annually' : 'Billed Annually'}
              leftText={width < 731 ? 'Monthly' : 'Billed Monthly'}
              currentId={planChecked.planInterval}
              leftTextId={MONTHLY_DURATION}
              rightTextId={YEARLY_DURATION}
              onChange={onPlanIntervalChange}
              checked={planChecked.checked}
            />
          </div>
        </div>
        <div className="plan-comparison">
          <Row className="row-price" wrap>
            <Col xs={24} sm={12} md={8} xl={10}>
              <PriceList
                highlight={false}
                // highlight={planSelect === STARTER ? true : false}
                price={starterPrice}
                startBtn="Start 5-day Free Trial"
                words={`${starterWords?.toLocaleString('en-US')}  word credits`}
                title="Starter"
                titleId={STARTER}
                description="For individuals getting started"
                titleClick={planSelect}
                marks={displayStarterTiers && Array.isArray(displayStarterTiers.tiers) && generateCrafterMarks()}
                starterSliderValue={starterSliderValue}
                setStarterSliderValue={setStarterSliderValue}
                onStarterSliderChange={onStarterSliderChange}
                selectedPrice={(titleId) => {
                  setPlanSelect(titleId);
                  handleClickScroll();
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} xl={10}>
              <PriceList
                // highlight={planSelect === CRAFTER ? true : false}
                highlight={true}
                price={crafterPrice}
                startBtn="Start 5-day Free Trial"
                words={`${crafterWords?.toLocaleString('en-US')}  word credits`}
                title="Crafter"
                titleId={CRAFTER}
                description="For writers, marketers & small businesses"
                titleClick={planSelect}
                marks={displayCrafterTiers && Array.isArray(displayCrafterTiers.tiers) && generateCrafterMarks()}
                crafterSliderValue={crafterSliderValue}
                setCrafterSliderValue={setCrafterSliderValue}
                selectedSliderTier={(sliderKeyId) => selectedSliderTier({ sliderKeyId, planType: CRAFTER })}
                selectedPrice={(titleId) => {
                  setPlanSelect(titleId);
                  handleClickScroll();
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} xl={10}>
              <PriceList
                startBtn="Book a Demo"
                words="700,000+ word credits"
                title="Enterprise"
                titleId={ENTERPRISE}
                description="Run your company on your terms"
                titleClick={planSelect}
                selectedPrice={setPlanSelect}
              />
            </Col>
          </Row>
        </div>

        {/* Mobile   */}

        <TrailMobileCard setTabClickMobile={planChangeMobile} tabClickMobile={tabClickMobile} />

        <MobileTrail>
          {tabClickMobile === STARTER && <Text className="custom">{`$${starterPrice}/mo`}</Text>}
          {tabClickMobile === CRAFTER && <Text className="custom">{`$${crafterPrice}/mo`}</Text>}
          {tabClickMobile === ENTERPRISE && (
            <div
              className="custom-tag"
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text className="custom-head">Custom</Text>
              <Text className="reachText">Reach out for a quote</Text>
              <Text className="text-head">700,000+ word credits</Text>
            </div>
          )}

          <div className="plan-slider">
            {tabClickMobile === STARTER && (
              <>
                <CustomSlider
                  onAfterChange={onStarterSliderChange}
                  tooltipVisible={false}
                  fixValue={starterSliderValue}
                  marks={generateStarterMarks()}
                  step={null}
                />
                <Text className="text-head">{`${starterWords?.toLocaleString('en-US')}  word credits`}</Text>
              </>
            )}
            {tabClickMobile === CRAFTER && (
              <>
                <CustomSlider
                  onAfterChange={(sliderKeyId) => selectedSliderTier({ sliderKeyId, planType: CRAFTER })}
                  step={null}
                  tooltipVisible={false}
                  defaultValue={crafterSliderValue}
                  marks={generateCrafterMarks()}
                />
                <Text className="text-head">{`${crafterWords?.toLocaleString('en-US')}  word credits`}</Text>
              </>
            )}
          </div>

          <Card>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text className="plan-list-title">Plan Inclusions List </Text>
              {PriceCardData?.map((key) => (
                <PlanPoints text={key.name} condition={key.flag} hover={key.hover} />
              ))}
            </div>
            <Button type="primary" className="free-trial" onClick={() => handleClickScroll()}>
              {tabClickMobile === 'Starter'
                ? 'Start 5-day Free Trial'
                : tabClickMobile !== 'Enterprise'
                ? 'Start 5-day Free Trial'
                : 'Book a Demo'}
            </Button>
          </Card>
        </MobileTrail>

        {/* Stripe Elements   */}

        <div className="stripe-form" id="payment">
          <Title className="billing-summary">Billing Summary</Title>
          <Elements stripe={stripePromise}>
            {DisplayCheckoutForm ? (
              <DisplayCheckoutForm
                selectedPlanPrice={selectedPlanPrice}
                planSelect={planSelect}
                selectedProductId={selectedProductId}
                uid={uid}
                showPlanDuration={
                  planChecked.planInterval === YEARLY_DURATION
                    ? 'year'
                    : planChecked.planInterval === MONTHLY_DURATION
                    ? 'mo'
                    : ''
                }
                planDuration={planChecked.planInterval}
                stripeId={stripeId}
                selectedPriceId={selectedStripePriceId}
                selectedPlanWords={selectedPlanWords}
                // stopProcessing={stopProcessing}
                email={email}
                activatedPlanDetails={activatedPlanDetails}
                // changePlan={() => slider.current.prev()}
                currentInvoiceId={currentInvoiceId}
                // planSubscriptionComplete={planSubscriptionComplete}
                firstName={firstName}
                lastName={lastName}
              />
            ) : (
              <Title type="danger" level={4}>
                There is no active pricing model.
              </Title>
            )}
          </Elements>
        </div>

        <TrialFaq />
        <AppFooter />
      </TrialBilling>
    </TrialBillingWrapper>
  );
};

export default Trial;

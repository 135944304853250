import styled from 'styled-components';

const ListStyle = styled.div`
  padding: 0px 0px 15px;
  display: flex;
  gap: 10px;
  align-items: center;

  .cross-circle {
    svg {
      height: 18.43px;
      width: 18.43px;
    }
  }
  .fa-x {
    font-size: 18.43px;
  }
  .text {
    font-weight: 500;
    font-family: 'Poppins-Regular';
    line-height: 21px;
  }
`;

export { ListStyle };

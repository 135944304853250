/* ------------------------------------------------------ */
/*                    Language Settings                   */
/* ------------------------------------------------------ */

/**
 *
 * There is only a differnet in UI, beside it is the same.
 *
 * This is to set default languages
 */

import React, { useState, useEffect } from 'react';
import { Typography, Select } from 'antd';
import { SettingsWrapStyled } from './styled';
import { Helmet } from 'react-helmet';
import { Button } from '../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { changeProfileDetails } from '../../../redux/AccountSettings/actionCreator';
import { PAGETILE } from '../../../constants/content';
import { INPUT_LANGUAGE, OUTPUT_LANGUAGE } from '../../../constants';
import { listLangOptions } from '../../../utility/Languages';
import Flags from 'country-flag-icons/react/3x2';

const LanguageSettings = () => {
  const dispatch = useDispatch();

  const { Text, Title } = Typography;
  const { changeProfileLoading } = useSelector((state) => state.accountSettings);
  const { defaultOutputLang, defaultInputLang } = useSelector((state) => state.fb.profile);
  const [langOutput, setLangOutput] = useState();
  const [langInput, setLangInput] = useState();

  useEffect(() => {
    if (defaultInputLang) {
      const { langCode, langName, CountryFlagCode } = defaultInputLang;
      setLangInput({ langCode, langName, CountryFlagCode });
    } else {
      setLangInput({ langCode: 'EN', langName: 'English', CountryFlagCode: 'US' });
    }
    if (defaultOutputLang) {
      const {
        langCode: outputLangCode,
        langName: outputLangName,
        CountryFlagCode: outputCountryFlagCode,
      } = defaultOutputLang;
      setLangOutput({ langCode: outputLangCode, langName: outputLangName, CountryFlagCode: outputCountryFlagCode });
    } else {
      setLangOutput({ langCode: 'EN', langName: 'English', CountryFlagCode: 'US' });
    }
  }, [defaultOutputLang, defaultInputLang]);

  const onInputChange = (value, option) => {
    const CountryFlagCode = listLangOptions.find(({ langCode }) => langCode === value).CountryFlagCode;
    const langName = option.label.props.children[1];
    setLangInput({ langCode: value, langName, CountryFlagCode });
  };
  const onOutputChange = (value, option) => {
    const CountryFlagCode = listLangOptions.find(({ langCode }) => langCode === value).CountryFlagCode;
    const langName = option.label.props.children[1];
    setLangOutput({ langCode: value, langName, CountryFlagCode });
  };

  const setLang = () => {
    langOutput &&
      langInput &&
      dispatch(changeProfileDetails({ defaultInputLang: langInput, defaultOutputLang: langOutput }, true));
  };

  const inputList = () => {
    const optionList = listLangOptions
      .filter(({ display }) => display.includes(INPUT_LANGUAGE))
      .map(({ langCode, langName, CountryFlagCode, title }, index) => {
        const Flag = Flags[CountryFlagCode];

        return {
          label: (
            <>
              <Flag title={langCode} style={{ width: '1rem', marginRight: '10px' }} />
              {langName}
            </>
          ),
          value: langCode,
        };
      });
    return optionList;
  };

  const outputList = () => {
    return listLangOptions
      .filter(({ display }) => display.includes(OUTPUT_LANGUAGE))
      .map(({ langCode, langName, CountryFlagCode, title }, index) => {
        const Flag = Flags[CountryFlagCode];

        return {
          label: (
            <>
              <Flag title={langCode} style={{ width: '1rem', marginRight: '10px' }} />
              {langName}
            </>
          ),
          value: langCode,
        };
      });
  };
  return (
    <>
      <Helmet>
        <title>{PAGETILE('Profile')}</title>
      </Helmet>
      <SettingsWrapStyled>
        <Title>Default Languages</Title>
        <Text className="profile">Manage your craft input and output languages</Text>

        {langInput && (
          <Select
            showSearch
            style={{ width: '50%', marginTop: '1rem' }}
            placeholder="Select Input Language"
            optionFilterProp="children"
            onChange={onInputChange}
            filterSort={(optionA, optionB) => {
              const listA = optionA.label.props.children[1]?.toLowerCase() || ' ';
              const listB = optionB.label.props.children[1]?.toLowerCase() || ' ';
              return listA.localeCompare(listB);
            }}
            filterOption={(input, option) => {
              const listOption = option.label.props.children[1]?.toLowerCase() || ' ';
              return listOption.indexOf(input.toLowerCase()) >= 0;
            }}
            value={langInput.langCode}
            options={inputList()}
          />
        )}
        <br />
        {langOutput && (
          <Select
            showSearch
            style={{ width: '50%', marginTop: '1rem' }}
            placeholder="Select Output Language"
            optionFilterProp="children"
            onChange={onOutputChange}
            filterSort={(optionA, optionB) => {
              const listA = optionA.label.props.children[1]?.toLowerCase() || ' ';
              const listB = optionB.label.props.children[1]?.toLowerCase() || ' ';
              return listA.localeCompare(listB);
            }}
            filterOption={(input, option) => {
              const listOption = option.label.props.children[1]?.toLowerCase() || ' ';
              return listOption.indexOf(input.toLowerCase()) >= 0;
            }}
            value={langOutput.langCode}
            options={outputList()}
          />
        )}
        <br />
        <br />
        <Button loading={changeProfileLoading} onClick={setLang} size="large" type="primary">
          Set Default Languages
        </Button>
      </SettingsWrapStyled>
    </>
  );
};

export default LanguageSettings;

/* ------------------------------------------------------ */
/*                    Customized Steps                    */
/* ------------------------------------------------------ */

/* 
Steps are used in onboarding steps.

Methadology
This component is being used on 2 different parts. 
  1. User Onboarding 
  2. Creation of Team

we have modified the Ant-Design Step according to our needs

*/

/*
Used In 
  src/container/Onboarding
  src/container/TeamOnboarding
*/

import React, { useState } from 'react';
import { Row, Col, message, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { StepsStyle, StepAction } from './style';
import { Button } from '../Button';
import Signout from '../Signout';
import { BUTTON_BLUE_GRADIENT } from '../../constants';

const { Step } = StepsStyle;

/**
 Customized AntD Step Component
  @prop  teamOnboarding: when it used in teamOnboarding steps
  @prop isvertical: responsive mode
  @prop current: current step
  @prop status: status 1. process and 2. finish 
  @prop direction: responsive mode
  @prop lastButton: last button name display
  @prop lastButtonLoading: last button loading status
  @prop validation: check whether all the required fields are filled 
  @prop steps: returns the current step UI 
  @prop onNext:function next step
  @prop onPrev: function prev step
  @prop onDone: function onDone, it is last step
  */
const CustomSteps = ({
  isvertical, // it is used for responsive screens.
  current,
  direction,
  status,
  steps,
  onNext,
  onPrev,
  onDone,
  onChange,
  children,
  height,
  lastButton,
  lastButtonLoading,
  validation,
  teamOnboarding,
}) => {
  const [state, setState] = useState({
    currents: current,
  });

  const next = () => {
    const currents = state.currents + 1;
    setState({ currents });
    onNext(currents);
  };

  const check = validation && validation.find(({ step }) => step === state.currents);
  const disable = check ? check.value && check.value.length > 0 : false;

  const nextValidation = check && check.stepError; // it checks the required fields of current step
  return (
    <Row style={{ width: '100%', flexDirection: direction === 'vertical' ? 'row' : 'column-reverse' }} align="start">
      <Col xs={24}>
        {isvertical ? (
          <div className="steps-content-wrapper">
            {/* signout is only visible for onboarding steps */}
            {!teamOnboarding && (
              <div className="actions">
                <img className="onboarding-logo" src={require('../../static/general/craftly_final.png')} alt="logo" />
                <div className="onboarding-signout">
                  <Signout />
                </div>
              </div>
            )}
            <div className="steps-wrapper">
              <div className="steps-content">{steps[state.currents].content}</div>
              <StepAction current={state.currents} teamOnboarding={teamOnboarding}>
                <div className="step-action-wrap">
                  <div className="step-action-inner">
                    <Row>
                      <Col xs={24}>
                        <div className="steps-action">
                          {state.currents === 0 && state.currents !== steps.length - 1 && (
                            <Tooltip placement={'bottom'} title={!disable ? nextValidation : ''}>
                              <Button
                                // disabled={!disable}
                                type={BUTTON_BLUE_GRADIENT}
                                onClick={() => disable && next()}
                                block
                                size="large"
                              >
                                Continue
                              </Button>
                            </Tooltip>
                          )}

                          {state.currents < steps.length - 1 && state.currents !== 0 && (
                            <Tooltip placement={'bottom'} title={!disable ? nextValidation : ''}>
                              <Button
                                // disabled={!disable}
                                type={BUTTON_BLUE_GRADIENT}
                                onClick={() => disable && next()}
                                block
                                size="large"
                              >
                                Confirm
                              </Button>
                            </Tooltip>
                          )}

                          {state.currents === steps.length - 1 && (
                            <Tooltip placement={'bottom'} title={!disable ? nextValidation : ''}>
                              <Button
                                type={BUTTON_BLUE_GRADIENT}
                                // disabled={!disable}
                                loading={lastButtonLoading}
                                className="btn-last"
                                onClick={() => disable && onDone()}
                                block
                                size="large"
                              >
                                {lastButton}
                              </Button>
                            </Tooltip>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </StepAction>
            </div>
          </div>
        ) : (
          <div className="steps-content-wrapper">
            {!teamOnboarding && (
              <div className="actions">
                <img className="onboarding-logo" src={require('../../static/general/craftly_final.png')} alt="logo" />
                <div className="onboarding-signout">
                  <Signout />
                </div>
              </div>
            )}
            <div
              className="steps-content"
              style={{ minHeight: height, display: 'flex', justifyContent: 'center', marginTop: 100 }}
            >
              {steps[state.currents].content}
            </div>
            <StepAction current={state.currents}>
              <div className="step-action-wrap">
                <div className="step-action-inner">
                  <Row>
                    <Col xs={24}>
                      <div className="steps-action">
                        {state.currents === 0 && (
                          <Button
                            disabled={!disable}
                            size="large"
                            type={BUTTON_BLUE_GRADIENT}
                            onClick={() => next()}
                            block
                          >
                            Continue
                          </Button>
                        )}
                        {state.currents === 1 && (
                          <Button
                            disabled={!disable}
                            size="large"
                            type={BUTTON_BLUE_GRADIENT}
                            onClick={() => next()}
                            block
                          >
                            Confirm
                          </Button>
                        )}

                        {state.currents === steps.length - 1 && (
                          <Button
                            type={BUTTON_BLUE_GRADIENT}
                            disabled={!disable}
                            loading={lastButtonLoading}
                            className="btn-last"
                            onClick={onDone}
                            size="large"
                            block
                          >
                            {lastButton}
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </StepAction>
          </div>
        )}
      </Col>
    </Row>
  );
};

CustomSteps.defaultProps = {
  current: 0,
  height: 150,
  onDone: () => message.success('Processing complete!'),
};

CustomSteps.propTypes = {
  current: PropTypes.number,
  direction: PropTypes.string,
  status: PropTypes.string,
  lastButton: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.object),
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onDone: PropTypes.func,
  onChange: PropTypes.func,
  height: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.string]),
};

export { Step, CustomSteps };

/* eslint-disable jsx-a11y/control-has-associated-label */

/* ------------------------------------------------------ */
/*                  Trial Plan Billing                    */
/* ------------------------------------------------------ */

/**
 * Fetch and Insert Start Plan Detials
 *
 * 1. Total words  - Total words a the user can utilized in a month
 * 2. Total worksapce - total workspace a user can create
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, InputNumber } from 'antd';
import { Button } from '../../../components/Button';
import { PlanFormStyle } from '../styled';
import { Cards } from '../../../components/Card';
import { BasicFormWrapper } from '../../styled';
import { fetchTrialBilling, planTrialBilling } from '../../../redux/Billing/actionCreator';

const TrialPlan = () => {
  const dispatch = useDispatch();
  const { planTrialLoading, fetchPlanTrial, fetchPlanTrialLoading } = useSelector((state) => state.billing);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchTrialBilling());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchPlanTrial) {
      form.setFieldsValue(fetchPlanTrial);
    }
  }, [form, fetchPlanTrial]);

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <BasicFormWrapper>
        <PlanFormStyle>
          <Cards title="Trial Plan" loading={fetchPlanTrialLoading}>
            <Form
              initialValues={fetchPlanTrial}
              name="trial-plans"
              form={form}
              layout="horizontal"
              onFinish={(values) => {
                // add trial plan details
                dispatch(planTrialBilling(values));
              }}
            >
              <Row align="middle">
                <Col lg={8} md={9} xs={24}>
                  <label htmlFor="totalTrialHits">Total Hits Per Day</label>
                </Col>
                <Col lg={16} md={15} xs={24}>
                  <Form.Item name="totalTrialHits" rules={[{ required: true, message: 'Please enter total hits!' }]}>
                    <InputNumber type="number" />
                  </Form.Item>
                </Col>
              </Row>
              <Row align="middle">
                <Col lg={8} md={9} xs={24}>
                  <label htmlFor="totalWorkspaces">Total Workspaces</label>
                </Col>
                <Col lg={16} md={15} xs={24}>
                  <Form.Item
                    name="totalWorkspaces"
                    rules={[{ required: true, message: 'Please enter total workspaces!' }]}
                  >
                    <InputNumber type="number" placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              {/* <Row align="middle">
                <Col lg={8} md={9} xs={24}>
                  <label htmlFor="trialDays">Trial Days </label>
                </Col>
                <Col lg={16} md={15} xs={24}>
                  <Form.Item name="trialDays" rules={[{ required: true, message: 'Please input the trial days!' }]}>
                    <InputNumber type="number" />
                  </Form.Item>
                </Col>
              </Row> */}

              <Row>
                <Col lg={{ span: 16, offset: 8 }} md={{ span: 15, offset: 9 }} xs={{ span: 24, offset: 0 }}>
                  <div className="sDash_form-action">
                    <Button className="btn-signin" onClick={onReset} type="light" size="large">
                      Cancel
                    </Button>
                    <Button
                      loading={planTrialLoading}
                      htmlType="submit"
                      className="btn-signin"
                      type="primary"
                      size="large"
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Cards>
        </PlanFormStyle>
      </BasicFormWrapper>
    </>
  );
};

export default TrialPlan;

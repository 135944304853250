import { Modal } from 'antd';
import styled from 'styled-components';

const MainFormStyle = styled.div`
  // padding: 0px 14px 86px 14px;
  padding: 20px 24px 86px 24px;
  background: #ffffff;

  .output {
    justify-content: space-between;
    align-items: baseline;
    padding: ${({ id }) => (id === 'conclude' ? '20px 20px' : '30px 20px')};
    gap: 10px;
    background: #ffffff;
    border: 1px solid #e2e4e8;
    border-radius: 10px;
    margin-bottom: 30px;
    display: block !important;

    .output-header-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      align-items: start;

      .output-header {
        font-size: 16px;
        font-family: 'Poppins-SemiBold';
        margin-left: 12px;
        display: flex;
        justify-content: space-between;
        .edit-icon {
          cursor: pointer;
        }
      }

      .output-content {
        font-family: 'Poppins-Medium';
        font-size: 14px;
        line-height: 20px;
        color: #737277;
        margin-top: 20px;
        margin-bottom: 0px !important;
        margin-left: 12px;
        word-break: break-word;
      }
      .step-key-topic {
        display: flex;
        padding: 14px 16px;
        align-items: flex-start;
        border-radius: 8px;
        width: 100%;
        gap: 20px;
      }
      .title-name {
        color: #6c5afb;
        font-family: 'Poppins-SemiBold';
        font-size: 14px;
        margin-right: 20px;
        display: inline;
        white-space: nowrap;
        @media (max-width: 768px) {
          width: 100%;
          margin-right: 0px;
          min-width: fit-content;
        }
      }
      .section-value {
        color: #282b3f;
        font-size: 14px;
        font-family: 'Poppins-Medium';
      }
      .step-paragraph-point {
        .section-title {
          background: #f9f8ff;
          border-radius: 8px;
          padding: 14px 20px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          width: 100%;
          @media (max-width: 768px) {
            flex-direction: column;
          }
        }
        .point-wrapper {
          margin-left: 6rem;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          padding: 14px 16px;
          .points {
            padding: 10px;
            color: #705bfa;
            background: #dbd8ff;
            border-radius: 6px;
            font-size: 12px;
          }
          @media (max-width: 768px) {
            margin-left: 0rem;
            padding: 14px 0px;
          }
        }
      }
    }

    .check-icon {
      background: linear-gradient(285.83deg, #8878f9 -34.95%, #6c5afb 92.08%);
      min-width: 24px;
    }
    .output-editlabel {
      font-family: 'Poppins-Medium';
      font-size: 14px;
      line-height: 22px;
      margin-right: 10px;
      color: ${({ theme }) => theme['primary-color']};
    }
  }

  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    display: block;
  }
  .ant-collapse-icon-position-start {
    background-color: ${({ theme }) => theme['white-color']};
    /* width: 100%; */
  }

  .ant-collapse-header-text {
    font-size: 16px;
    line-height: 20px;
    color: #282b3f;
    font-family: 'Poppins-SemiBold';
  }
  .ant-collapse-content-box {
    background-color: ${({ theme }) => theme['white-color']};
    padding: 0px;
  }

  .ant-collapse-extra {
    margin-left: 35px !important;
    color: #737277;
    font-weight: 500;
    font-family: 'Poppins-Regular';
    font-size: 12px;
  }
  .ant-collapse-header {
    padding-left: 12px;
    display: inline !important;
    width: 100%;
    color: #282b3f;
    padding: 0px;
    font-family: 'Poppins-SemiBold';
    flex-direction: column !important;
  }

  .ant-collapse-item {
    /* border-bottom: 1px solid #d9d9d9 !important; */
    /* padding: 0px 12px 0px 12px; */
  }

  .radio-wrapper {
    gap: 10px;
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    border-radius: 8px;
    padding: 14px 2px 14px 16px;
    width: 39.5rem !important;
    font-size: 14px;
    line-height: 21px;
    color: #282b3f;
  }

  .form-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #6c5afb;
    border-radius: 10px;
    border-color: #6c5afb;
    background-color: ${({ theme }) => theme['white-color']};
  }

  .main-form-right {
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme['white-color']};
    border: 1.5px solid #eeeeee;
    border-radius: 15px;
    height: 211px;
    max-width: 327px;
    align-items: center;
    justify-content: center;
    padding: 25px 22px 4px 22px;
  }
  .right-button {
    /* background: linear-gradient(285.83deg, #8878F9 -34.95%, #6C5AFB 92.08%); */
    margin-top: 1rem;
    border-radius: 8px;
    padding: 12px 18px;
    color: white;
    height: 48px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
  }

  .summary-button {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 1rem;
  }
`;
const OpenEditorStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: ${({ highlight }) => (highlight ? `#6c5afb` : '#ffffff')};
  border: 1px solid #e2e4e8;
  border-radius: 10px;

  .complete-step-text {
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
    margin-bottom: 0px;
    color: ${({ highlight }) => (highlight ? `#FFFFFF` : '#696b79')};
  }
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const UnSavedChangesStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 1rem;
  background: ${({ highlight }) => (highlight ? `#6c5afb` : '#ffffff')};
  border: 1px solid #e2e4e8;
  border-radius: 10px;

  .complete-step-text {
    font-family: 'Poppins-SemiBold';
    font-size: 16px;
    margin-bottom: 0px;
    color: #696b79;
  }

  .unsaved-action {
    gap: 1%;
    display: flex;
    .new-blog {
      padding: 10px;
      .cross-icon {
        position: absolute;
        top: 10px;
        right: 20px;
      }
    }
  }
  @media (max-width: 767px) {
    flex-direction: column;
    .unsaved-action {
      display: flex;
      flex-direction: column;

      .new-blog {
        padding: 0px;
        margin-top: 10px;
        .cross-icon {
          position: absolute;
          top: 10px;
          right: 20px;
        }
      }
    }
  }
`;

const StepFieldWrapper = styled.div`
  .ant-space {
    gap: ${({ stepId }) => (stepId === 'choose-title' ? '0px' : '20px')} !important;
  }

  .ant-space.ant-space-vertical {
    margin-top: ${({ stepId }) => (stepId === 'choose-intro' ? '0px !important' : '20px')};
  }

  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 20px;
  }

  .action-container {
    margin-left: 36px;
    margin-top: 40px !important;
    .ant-typography {
      margin-top: 0px !important;
    }
    @media (max-width: 768px) {
      margin-left: 36px;
      margin-top: 40px !important;
    }
    .action-buttons > .field-button > .ant-btn {
      @media (max-width: 767px) {
        max-width: 100% !important;
      }
    }
  }
  .para-builder {
     border: 1px solid #e2e4e8;
    border-radius: 10px;
    margin-left: 36px;
    margin-top: 26px;
    padding: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
  .words-limit {
    display: flex;
    justify-content: end;

    .ant-typography {
      font-size: 11px;
      font-family: 'Poppins-Medium';
    }
  }

  .field-section-points {
    padding: 14px 20px;
    background: #f9f8ff;
    border-radius: 8px;
    display: flex;
    gap: 20px;
    @media (max-width: 768px) {
      gap: ${({ stepId }) =>
        stepId === 'paragraph-point' || stepId === 'paragraph-builder' ? '20px' : '0px'} !important;
      flex-direction: column;  
  }

    .section-tag {
      font-family: 'Poppins-Regular';
      font-size: 13px;
      color: #978cfb;
      background-color: #f5f4ff;
      border-radius: 6px;
      border: 1px solid #f5f4ff;
    }
    .ant-typography {
      font-family: 'Poppins-SemiBold';
    }
    .section-word {
      color: #6c5afb;
      font-size: 14px;
      white-space: nowrap;

      @media (max-width: 767px) {
        width: 100%;
        min-width: fit-content;
      }
    }
    .bottom-section {
      margin-bottom: '0px !important';
    }
  }
  .field-heading {
    display: flex;
    justify-content: space-between;
    align-items: end;

    .field-label {
      margin-top: 20px;
      font-size: 14px;
      line-height: 21px;
      color: #282b3f;
      font-family: 'Poppins-SemiBold';
      margin-bottom: 4px;
    }
    .field-wordslimit {
      margin-top: 1rem;
      font-size: 11px;
      color: #848484;
      font-family: 'Poppins-Medium';
      margin-bottom: 6px;
      padding-right: 8px;
    }
    .field-wordlimit-error {
      color: red;
    }
  }
  .field-textarea {
    border: 1.5px solid #eeeeee;
    border-radius: 8px;
  }
  .input-button {
    margin-top: 24px;
    display: flex;
    justify-content: end;
    .ant-btn {
      height: 44px;
      width: 20%;
      @media (max-width: 767px) {
        float: none;
        width: 100%;
      }
    }
    .ant-btn-primary[disabled] {
      color: #978cfb;
      background: #f5f4ff;
    }
  }
  .custom-input-wrapper {
    margin-top: 1rem;
    .custom-input {
      color: #848484;
      font-size: 14px;
      line-height: 22px;
      font-family: 'Poppins-Regular';
    }
    .field-textarea {
      margin-top: 4px;
      border: 1.5px solid #6c5afb;
      box-shadow: 0px 0px 0px 3px #dcd7ff;
      border-radius: 8px;
    }
  }
  .content {
    justify-content: flex-end !important;
    display: flex;
  }

  .bottom {
    margin-top: 0px !important;
    justify-content: space-between !important;
    @media (max-width: 768px) {
      .ant-typography {
        margin-bottom: 10px !important;
      }
    }
    .field-button {
      @media (max-width: 767px) {
        width: ${({ stepId }) => stepId === 'paragraph-builder' && '200%'};
        justify-content: start;
      }
    }
  }

  .bottom-points {
    margin-top: 0px !important;
    .ant-typography {
      margin-top: 0px !important;
    }
  }
  .button-content {
    margin-top: 20px !important;
    justify-content: end !important;
  }
  .styled-text {
    margin-top: -25px !important;
    @media (max-width: 768px) {
      margin-top: 0px !important;
      margin-bottom: 20px !important;
    }
  }
  .action-buttons {
    margin-top: ${({ stepId }) => (stepId === 'paragraph-point' ? '0px' : '20px')} !important;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 767px) {
      align-items: start;
      flex-direction: column;
    }

    .field-button {
      display: flex;
      justify-content: end;
      width: 50%;
      gap: 10px;
      @media (max-width: 767px) {
        width: ${({ stepId }) => (stepId === 'paragraph-point' || stepId === 'paragraph-builder' ? '200%' : '100%')};
        justify-content: start;
      }
      .ant-btn {
        height: 44px;
        max-width: 50%;
        @media (max-width: 767px) {
          float: none;
          max-width: 50%;
        }
      }
      .generate-btn {
        background: linear-gradient(289.65deg, #fec724 -15.55%, #fff148 116.77%);
        color: #6c5afb;
      }
    }
    .custom-input {
      color: #6c5afb;
      font-size: 14px;
      font-family: 'Poppins-Regular';
      cursor: pointer;
      margin-top: ${({ stepId }) => (stepId === 'paragraph-point' ? '0px !important' : '1px ')};
    }
  }
  .go-back {
    display: flex;
    justify-content: space-between;
  }

  .add-text {
    color: black;
    font-family: 'Poppins-Medium';
    padding: 0;
    width: 100%;
    height: 50px;
    min-height: 50px;
    padding: 14px 0px;
  }

  .field-add-text {
    display: flex;
    gap: 12px;
    width: 100%;
    padding: 0px 16px;
    align-items: center;
  }

  .field-optionList {
    font-family: 'Poppins-Medium';
    display: flex;
    flex-direction: row;
    /* color: ${({ stepId }) => (stepId === 'choose-intro' || stepId === 'paragraph-builder' ? 'black' : '#282B3F')}; */
    color: black;
    justify-content: space-between;
    align-items: flex-start;
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    word-break: break-word;
    @media (max-width: 768px) {
      align-items: ${({ stepId }) => stepId === 'key-topics' && 'center'};
    }

    .content-data {
      width: 96%;
      padding: 11px 15px;
      padding-right: 0px;
      .record {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        font-size: 12px;
        color: #848484;
      }
    }

    .icon-delete {
      cursor: pointer;
      margin-top: auto;
      margin-bottom: auto;
      padding-right: 14px;
      
    }

    &:hover {
      cursor: pointer;
      /* box-shadow: 6px 8px 35px -23.4967px rgba(95, 103, 117, 0.8); */
      box-shadow: 0px 0 6px 1px rgba(95, 103, 117, 0.2);
    }
  }
  .field-optionList-selected {
    border: 1.5px solid #6c5afb !important;
  }
`;

const LongFormWrapper = styled.div`
  padding: 30px 20px;
  gap: 10px;
  background: #ffffff;
  border: 1px solid #e2e4e8;
  border-radius: 10px;
  margin-bottom: 30px;
  box-shadow: ${({ stepActive }) => stepActive && `6px 8px 35px -23.4967px rgba(95, 103, 117, 0.8)`};
  .edit-button {
    height: 44px;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
  }
  .form-wrapper {
    display: flex;
    justify-content: space-between;
    .form-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      // margin-top: 1.5rem;
      align-items: start;

      .avatarStep {
        background: ${({ stepActive }) =>
          stepActive ? `linear-gradient(285.83deg, #8878F9 -34.95%, #6C5AFB 92.08%)` : '#9c8ffb'};
        min-width: 24px;
        .stepCount {
          display: flex;
          font-size: 14px;
          font-family: 'Poppins-SemiBold';
          color: #ffffff;
        }
      }
      .form-title,
      .form-header {
        margin: 0 0 0 12px !important;
      }

      .form-header {
        font-family: 'Poppins-SemiBold';
        font-style: normal;
        font-size: 16px;
        margin-bottom: 4px !important;
        color: ${({ stepActive }) => (stepActive ? `#282B3F` : 'rgba(40, 43, 63, 0.75)')};
      }

      .form-title {
        font-size: 14px;
        font-family: 'Poppins-Regular';
        color: #737277;
      }
    }
  }
  .field {
    margin: ${({ stepId }) => (stepId === 'paragraph-builder' ? `0px !important` : '0 0 0 35px !important;')};
    @media (max-width: 767px) {
      margin: 0px !important;
    }
  }

  .content-wrapper {
    @media (max-width: 768px) {
      margin-left: 36px;
    }
  }

  .ant-divider-horizontal {
    margin: ${({ stepId }) => (stepId === 'paragraph-point' ? '0px' : '20px 0px')} !important;
  }
`;

const SummaryPageWrapper = styled.div`
  .top {
    background: #f1efff;
    border-radius: 8px;
    padding: 14px 24px;
    display: flex;
    justify-content: space-between;
    .title {
      display: flex;
      flex-direction: row;
      .text {
        font-family: 'Poppins-Medium';
        color: #6c5afb;
        font-size: 17px;
      }
    }
    .total-words-count {
      color: #705bfa;
      display: flex;
      align-items: center;
      text-align: right;
    }
  }
  .intro-wrapper {
    margin-top: 25px;
    .intro {
      font-family: 'Poppins-SemiBold';
      font-size: 16px;
    }
    .intro-wrapper {
      width: 100%;
      margin-top: 5px;
      background: #ffffff;
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      padding: 14px 16px;
      &:hover {
        cursor: pointer;
        box-shadow: 6px 8px 35px -23.4967px rgba(95, 103, 117, 0.8);
      }
      .intro-text {
        color: #282b3f;
        font-family: 'Poppins-Regular';
        font-size: 15px;
      }
    }
  }
  .edit-icon {
    display: flex;
    justify-content: center;
    margin: 17px;
    cursor: pointer;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  .section-list-wrapper {
    display: flex;
    flex-direction: row;

    .highlight {
      animation: blinker 4s linear infinite;
      background: #5f63f260 !important;
    }
    .section-list {
      background: #ffffff;
      border: 1px solid #e2e4e8;
      border-radius: 8px;
      &:hover {
        cursor: pointer;
        box-shadow: 6px 8px 35px -23.4967px rgba(95, 103, 117, 0.8);
      }
      .heading-title {
        border-bottom: 1px solid #e2e4e8;
        padding: 14px 16px;
        .heading {
          font-family: 'Poppins-SemiBold';
          font-size: 16px;
        }
      }
      .content {
        padding: 14px 16px;
        .text {
          font-size: 15px;
          font-family: 'Poppins-Regular';
        }
      }
    }
    .action-icons {
      padding: 1rem;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      visibility: hidden;
      .summary-icon {
        cursor: pointer;
        padding: 6px 0;
      }
    }
    @media (max-width: 768px) {
      .action-icons {
        visibility: visible;
      }
    }
    &:hover {
      .action-icons {
        visibility: visible;
      }
    }
  }
`;
const LongFormWarningStyled = styled.div`
  .warning-popup {
    .ant-modal-header {
      background-color: red;
      display: flex !important;
      justify-content: center !important;
      .ant-modal-title {
        font-family: 'Poppins-SemiBold' !important;
        font-size: 20px !important;
      }
    }
  }
`;
const WarningModalStyled = styled(Modal)`
  .ant-modal-header {
    display: flex;
    justify-content: center;
    .ant-modal-title {
      font-family: 'Poppins-SemiBold';
      font-size: 20px;
    }
  }
  .ant-modal-body {
    .first-text {
      font-family: 'Poppins-Regular';
      font-size: 16px;
      display: flex;
      text-align: center;
      justify-content: center;
    }
    .second-text {
      margin-top: 35px;
      margin-bottom: 16px;
      font-family: 'Poppins-Medium';
      font-size: 12px;
      color: #7f8186;
      text-align: center;
      justify-content: center;
    }

    .warning-action-buttons {
      display: flex;
      justify-content: space-around;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .edit-button {
        border-radius: 8px;
        padding: 10px 16px;
        text-align: center;
        cursor: pointer;
      }

      .edit-go-back {
        width: 45%;
        color: #6c5afb;
        background: linear-gradient(289.65deg, #fec724 -15.55%, #fff148 116.77%);
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .edit-current {
        width: 50%;
        background: #6c5afb;
        color: #fff;
        @media (max-width: 768px) {
          width: 100%;
          margin-top: 1rem;
        }
      }
    }
  }
`;

export {
  MainFormStyle,
  StepFieldWrapper,
  LongFormWrapper,
  OpenEditorStyled,
  SummaryPageWrapper,
  LongFormWarningStyled,
  WarningModalStyled,
  UnSavedChangesStyled,
};

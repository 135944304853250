/* ------------------------------------------------------ */
/*                      CURRENT PLAN                      */
/* ------------------------------------------------------ */

/**
 * Methadology
 * Current Plan shows the details of current stripe subscription
 * The following are the details of the plan
 * 1. Current Plan: Plan-name & Words limit
 * 2. Status: Stripe status
 * 3. Billing Cycle: Monthly or yearly
 * 4. Next Cycle: Data for next billing cycle
 *
 */

import React from 'react';
import { Typography, Space } from 'antd';
import {
  BILLING_SCHEME_PER_UNIT,
  CRAFTER,
  MONTHLY_DURATION,
  NA,
  PERSONAL_WORKSPACE,
  PLAN_STATUS,
  STARTER,
  TEAM,
  TEAM_WORKSPACE,
  TRIAL,
  YEARLY_DURATION,
} from '../../../../constants';
import { getPlanStatus } from '../../../../utility/userProperties';
import {
  ANNUALLY_TEXT,
  BILLING_AUTOMATIC_SUBSCRIPTION_FAILED,
  BILLING_CREDIT_CARD_ATTENTION,
  BILLING_NO_ACTIVE_PLAN,
  BILLING_TEAM_MEMBER,
  MONTHLY_TEXT,
} from '../../../../constants/content';
import dotImage from '../../../../static/settings/dot.png';
import { noTrialccRemaingTime } from '../../../../utility/calculateBilling';
import { numberFormating, capitalizeFirstLetter, getUTCDate } from '../../../../utility/utility';
import { CurrentPlanStyled } from './styled';
import { useSelector } from 'react-redux';

const { Text, Title } = Typography;

const CurrentPlan = ({
  planSelect,
  activatedPlanDetails,
  noTrialccPlanAcitivated,
  plan,
  reasonSubscriptionFailed,
  firstSubscriptionCharged,
  planStatus,
  selectedWorkspace,
  trialPlanAcitivated,
  memberTeamsWithOutHold,
  totalWordsCraft,
  description,
}) => {
  const {
    expiringDate,
    isCanceled,
    periodEnd,
    periodStart,
    planInterval,
    productName,
    status,
    trialEnds,
    trialStarts,
    planEnded_Canceled,
    quantity,
    UTC_current_period_end,
    UTC_current_period_start,
    UTC_cancel_at_expiringDate,
    UTC_ended_at_canceledDate,
    billingScheme,
    metadata,
  } = activatedPlanDetails || {};
  const { adminBillingSettings } = useSelector((state) => state.billing);

  // there is OR because in some old profiles there is no UTC dates, but in New profiles UTC dates are present.
  const { readableDate: utcPeriodEnd } = getUTCDate(UTC_current_period_end || periodEnd);
  const { readableDate: utcPeriodStart } = getUTCDate(UTC_current_period_start || periodStart);
  const { readableDate: utcExpirinDate } = getUTCDate(UTC_cancel_at_expiringDate || expiringDate);
  const { readableDate: utcEnded_at } = getUTCDate(UTC_ended_at_canceledDate || planEnded_Canceled);

  const endDateToShow = status === PLAN_STATUS.CANCELED ? utcEnded_at : isCanceled ? utcExpirinDate : utcPeriodEnd;

  // const { readableDate: readableTrialStartDate, typeDateFormat: trialStartDateFormat } = getUTCDate(trialStarts);
  // const { readableDate: readableTrialEndDate, typeDateFormat: trialEndDateFormat } = getUTCDate(trialEnds);
  // const { readableDate: readableNoCCTrialStartDate } = getUTCDate(noTrialccPlanAcitivated);

  // const trialDuration =
  //   readableTrialStartDate && readableTrialEndDate
  //     ? Math.floor(moment.duration(trialEndDateFormat.diff(trialStartDateFormat)).asDays())
  //     : '-';

  const { trialExpired, daysRemaingWithZero, trialDateEndsWithYearFormat } =
    noTrialccRemaingTime(noTrialccPlanAcitivated) || {};
  const { workspaceType } = selectedWorkspace || {};

  const formatter = () => {
    return <Text className="tooltip-slider">{`${numberFormating(totalWordsCraft)} words`}</Text>;
  };
  // this quantity is 1 because v1 stripe pricing has not dealing with words quantity
  const percentageSlider = quantity === 1 ? 50 : (totalWordsCraft / quantity) * 100;

  const getStarterFixWords = (planInterval) => {
    const planDetails = adminBillingSettings.find(({ plan }) => plan === STARTER);
    const { totalWords } = (planDetails && planDetails.details) || { totalWords: null };
    if (totalWords) {
      return `${planInterval}ly` === YEARLY_DURATION
        ? totalWords * 12
        : `${planInterval}ly` === MONTHLY_DURATION
        ? totalWords
        : '';
    }
  };

  const duration =
    `${planInterval}ly` === YEARLY_DURATION
      ? ANNUALLY_TEXT
      : `${planInterval}ly` === MONTHLY_DURATION
      ? MONTHLY_TEXT
      : '';
  return (
    <>
      {/* For those who has any Plan subscription attempt */}
      {activatedPlanDetails && (
        <CurrentPlanStyled>
          <div className="plan-stats">
            <Space size={[32, 8]} wrap>
              <div>
                <Text className="plan-text"> Current Plan</Text>
                <div className="name-words">
                  {plan !== TRIAL && <Text className="plan-name"> {capitalizeFirstLetter(productName)}</Text>}
                  {plan === TRIAL && (
                    <Text className="plan-name">
                      {metadata
                        ? capitalizeFirstLetter(metadata && metadata.userSelectedPlanName)
                        : description.split(' ')[3]}
                    </Text>
                  )}
                  {plan !== TEAM && <img src={dotImage} alt="dotImage" className="dot" />}

                  {plan === STARTER && (billingScheme === BILLING_SCHEME_PER_UNIT || billingScheme === undefined) && (
                    <Text className="words">{numberFormating(getStarterFixWords(planInterval))} words</Text>
                  )}
                  {plan === CRAFTER && (billingScheme === BILLING_SCHEME_PER_UNIT || billingScheme === undefined) && (
                    <Text className="words">Unlimited</Text>
                  )}
                  {plan === TEAM && (
                    <>
                      &nbsp; <Text className="plan-name">of {quantity}</Text>
                      <img src={dotImage} alt="dotImage" className="dot" />
                      <Text className="words">Unlimited</Text>
                    </>
                  )}
                  {plan === TRIAL && <Text className="description">(Trial)</Text>}
                </div>
              </div>
              <div>
                <Text className="plan-text"> Status </Text>
                <div className="name-words">
                  <Text className="plan-name">
                    {capitalizeFirstLetter(
                      getPlanStatus({
                        currentPlanStatus: status,
                        currentPeriodWillEnds: isCanceled,
                        displayStatusUI: true,
                      }),
                    )}
                  </Text>
                </div>
              </div>
              <div>
                <Text className="plan-text"> Billing Cycle </Text>
                <div className="name-words">
                  <Text className="plan-name">{duration}</Text>
                </div>
              </div>
            </Space>

            <div className="cycle-details">
              <Text className="cycle-text">
                {status === PLAN_STATUS.ACTIVE || status === PLAN_STATUS.TRIAL
                  ? isCanceled
                    ? 'Expiring'
                    : 'Next Cycle'
                  : ''}
                {(status === PLAN_STATUS.INCOMPLETE || status === PLAN_STATUS.PAST_DUE) && 'Will be Ended'}
                {(status === PLAN_STATUS.INCOMPLETE_EXPIRED || status === PLAN_STATUS.CANCELED) && 'Ended'}
              </Text>
              <Text className="cycle-date">{endDateToShow}</Text>
            </div>
          </div>
        </CurrentPlanStyled>
      )}
      {workspaceType === PERSONAL_WORKSPACE && plan === NA && (
        <div>
          <Title level={5} style={{ marginLeft: '1rem' }}>
            {BILLING_NO_ACTIVE_PLAN}{' '}
          </Title>
          {firstSubscriptionCharged === 'unpaid' && <Text strong> {BILLING_AUTOMATIC_SUBSCRIPTION_FAILED} </Text>}
          <br /> {reasonSubscriptionFailed === 'incomplete' && <Text strong>{BILLING_CREDIT_CARD_ATTENTION}</Text>}
        </div>
      )}
      {workspaceType === TEAM_WORKSPACE && (
        <div>
          <Title level={5} style={{ marginLeft: '1rem' }}>
            {BILLING_TEAM_MEMBER}
          </Title>
        </div>
      )}
    </>
  );
};

export default CurrentPlan;

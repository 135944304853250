/* ------------------------------------------------------ */
/*                       Recent Card                      */
/* ------------------------------------------------------ */

/**
 * Recent Card is the preview of recent data
 */

import { Card, Col, Empty, Row, Spin, Typography } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Paper from '../../../static/favourite/Paper.svg';
import RecentModal from './RecentModal';
import {
  breakLineForCopy,
  breakLineIfRequired,
  convertFirebaseToDate,
  getToolIcon,
  getToolIconId,
  htmlToText,
} from '../../../utility/utility';
import { RecentCardStyle, CardRecentHeader, CardRecentContent, CardRecentContentText } from './styled';
import CopyToClipboard from 'react-copy-to-clipboard';
import { messageAlert } from '../../../components/utilities/messages';

const RecentCards = ({ allRecentPageLoading, allRecentPage }) => {
  const { Title, Text } = Typography;
  const [recentModal, setRecentModal] = useState(false);
  const [recentData, setRecentData] = useState(false);
  const { allTools } = useSelector((store) => store.adminFeatures);

  const handleToolPopUp = () => {
    setRecentModal(!recentModal);
  };

  const handleRecentModal = ({
    input,
    inputFields,
    displayDate,
    lines,
    toolIcon,
    toolDisplayName,
    contentData,
    words,
    len,
  }) => {
    setRecentModal(!recentModal);
    setRecentData({ input, inputFields, displayDate, toolIcon, lines, toolDisplayName, contentData, words, len });
  };
  return (
    <RecentCardStyle>
      <Spin spinning={allRecentPageLoading}>
        <Row gutter={[24, 24]}>
          {allRecentPage &&
            allRecentPage.map(
              ({ commonIcon, input, toolDisplayName, inputFields, toolName, date, contentData, words, len }) => {
                const toolIconId = getToolIconId({ toolId: toolName, allTools });
                const toolIcon = getToolIcon(toolIconId);
                const displayDate = moment(convertFirebaseToDate(date)).format('MMMM DD');
                const { lines } =
                  contentData && typeof contentData === 'string' ? breakLineIfRequired(contentData) : { lines: '' };
                return (
                  <Col xs={24} sm={12} md={8} lg={6}>
                    <Card
                    // className={`${recentData.id === item.id ? 'highlight' : ''}`}
                    >
                      <>
                        <CardRecentHeader>
                          <img className="tool-recent-icon" src={toolIcon} alt="tool-icon" />
                          <div className="recent-top">
                            {contentData && typeof contentData === 'string' && (
                              <CopyToClipboard
                                text={htmlToText(breakLineForCopy(contentData))}
                                onCopy={() => messageAlert({ type: 'info', textMessage: 'Text Copied' })}
                              >
                                <img alt="Paper" src={Paper} />
                              </CopyToClipboard>
                            )}
                          </div>
                        </CardRecentHeader>
                        <CardRecentContent
                          onClick={(e) => {
                            handleRecentModal({
                              input,
                              displayDate,
                              toolIcon,
                              toolDisplayName,
                              contentData,
                              words,
                              len,
                              lines,
                              inputFields,
                            });
                          }}
                        >
                          <Title level={5} className="heading-title">
                            {toolDisplayName}
                          </Title>
                          <Text level={5} className="subhead-date">
                            {displayDate}
                          </Text>
                          <CardRecentContentText>
                            <p className="output-content">{lines}</p>
                          </CardRecentContentText>
                          <div className="recent-bottom">
                            <div className="char-words">
                              <Text>{len} Chars </Text>
                              <span className="dot-fav">•</span>
                              <Text>{words} Words </Text>
                            </div>
                          </div>
                        </CardRecentContent>
                      </>
                    </Card>
                  </Col>
                );
              },
            )}
        </Row>
      </Spin>
      {!allRecentPageLoading && allRecentPage.length === 0 && (
        <div className="empty-design">
          <Empty />{' '}
        </div>
      )}

      {recentModal && <RecentModal toolModal={recentModal} handleToolPopUp={handleToolPopUp} recentData={recentData} />}
    </RecentCardStyle>
  );
};

export default RecentCards;

import styled from 'styled-components';

const CurrentPlanStyled = styled.div`
  margin-top: 3rem;
  padding-right: 1rem;
  .plan-stats {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 400px) {
      flex-direction: column;
    }

    .plan-name-status {
      flex-direction: row;
      display: flex;
      @media only screen and (max-width: 576px) {
        flex-direction: column;
      }
    }

    .plan-text {
      color: #705bfa;
      font-size: 14px;
      font-family: 'Poppins-Regular';
    }
    .name-words {
      display: flex;
      align-items: center;
      .plan-name {
        color: #282b3f;
        font-family: 'Poppins-Medium';
        font-size: 18px;
      }
      .dot {
        padding: 0px 10px;
      }
      .words {
        color: #705bfa;
        font-size: 18px;
        font-family: 'Poppins-Medium';
      }
      .description {
        color: #705bfa;
        font-size: 13px;
        font-family: 'Poppins-Medium';
      }
    }
    .cycle-details {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @media only screen and (max-width: 400px) {
        align-items: flex-start;
        margin-top: 10px;
      }
      .cycle-text {
        color: #705bfa;
        font-size: 15px;
        font-family: 'Poppins-Regular';
      }
      .cycle-date {
        color: #282b3f;
        font-size: 18px;
        font-family: 'Poppins-Medium';
      }
    }
  }
  .slider-progress {
    margin-top: 4.5rem;
    .tooltip-slider {
      background: black;
      color: white;
      border-radius: 7px;
      padding: 6px 12px;
      font-size: 12px;
    }
    .ant-tooltip-inner {
      padding: 0px;
      background-color: none;
      min-height: 0px;
    }
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: linear-gradient(to right bottom, rgb(0, 0, 0), rgb(0, 0, 0));
    }
    .ant-tooltip-arrow-content::before {
      top: -12.314px;
    }
    .ant-slider-mark {
      top: 25px;
      left: 0px !important;
      span:last-child {
        left: 100% !important;
      }
      .ant-slider-mark-text,
      .ant-slider-mark-text-active {
        color: #282b3f;
        font-size: 16px;
        font-family: 'Poppins-Medium';
      }
    }
  }
`;
export { CurrentPlanStyled };

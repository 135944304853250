/* ------------------------------------------------------ */
/*                    Header Component                    */
/* ------------------------------------------------------ */

/**
 * HeaderComponent
 * This UI renders in the language Modal
 *
 */

import React from 'react';
import { Row, Col, Typography, Breadcrumb } from 'antd';
import { HeaderNewDesignStyle, NewHeaderMain } from '../styled';
import LanguageSelection from '../../LanguageSelection';
import { useViewport } from '../../../hooks/viewPort';
import { useNavigate } from 'react-router-dom';
import HeaderSearchTools from '../../HeaderSearchTools';

/**
 * @title Page Name
 * @langInput selected input language name
 * @langVisible Modal open or close
 * @handleVisibleChange func to handle the modal visibility
 * @onLanguageClick on input ot output language selects
 * @langOutput language ouput selected
 * @setInputDefaultLang input default
 * @outputDefaultLang default language to set the selected language for future also
 * @setOutputDefaultLang
 * @hideTranslation few pages do not have the need to show the languages like Recent or Fav pages
 */
const HeaderComponent = ({
  title,
  langInput,
  langVisible,
  handleVisibleChange,
  onLanguageClick,
  langOutput,
  setInputDefaultLang,
  inputDefaultLang,
  outputDefaultLang,
  setOutputDefaultLang,
  hideTranslation,
}) => {
  const { width } = useViewport();
  const { Text } = Typography;
  const navigate = useNavigate();
  const handleCallback = () => {
    navigate('/category');
  };
  return (
    <NewHeaderMain>
      <Row gutter={16}>
        <Col lg={0} md={24} sm={24} xs={24}>
          {!hideTranslation && (
            <div style={{ marginBottom: '1.5rem', padding: '0 1rem' }}>
              <HeaderSearchTools showSearchOptionsInDropdown={true} placeholder="Search Tools" />
            </div>
          )}
        </Col>
        <Col md={24} sm={24} xs={24}>
          <div className="banner-cta align-center-v">
            <div className="banner-cta__content">
              {/* Mobile view  */}
              {width < 769 && !hideTranslation && (
                <div style={{ marginLeft: '15px' }}>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item className="standard-breadcum" onClick={handleCallback}>
                      Tool Library
                    </Breadcrumb.Item>
                    <Breadcrumb.Item style={{ color: '#8C49F7' }}>{title}</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              )}
              <HeaderNewDesignStyle>
                <div className="header-navbar">
                  <Text className="page-title">{title}</Text>
                  {width < 769 && !hideTranslation && (
                    <div className="action-buttons">
                      {langInput && langOutput && (
                        <LanguageSelection
                          langVisible={langVisible}
                          handleVisibleChange={handleVisibleChange}
                          onLanguageClick={onLanguageClick}
                          langOutput={langOutput}
                          langInput={langInput}
                          onIputLangDefault={setInputDefaultLang}
                          inputDefaultLang={inputDefaultLang}
                          outputDefaultLang={outputDefaultLang}
                          onOutputDefaultLang={setOutputDefaultLang}
                        />
                      )}
                    </div>
                  )}
                </div>
              </HeaderNewDesignStyle>
              {width > 769 && !hideTranslation && (
                <div className="action-buttons">
                  {langInput && langOutput && (
                    <LanguageSelection
                      langVisible={langVisible}
                      handleVisibleChange={handleVisibleChange}
                      onLanguageClick={onLanguageClick}
                      langOutput={langOutput}
                      langInput={langInput}
                      onIputLangDefault={setInputDefaultLang}
                      inputDefaultLang={inputDefaultLang}
                      outputDefaultLang={outputDefaultLang}
                      onOutputDefaultLang={setOutputDefaultLang}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </NewHeaderMain>
  );
};

export default HeaderComponent;

const actions = {
  FETCH_TEAM_CHAT_START: 'FETCH_TEAM_CHAT_START',
  FETCH_TEAM_CHAT_SUCCESS: 'FETCH_TEAM_CHAT_SUCCESS',
  FETCH_TEAM_CHAT_ERROR: 'FETCH_TEAM_CHAT_ERROR',

  fetchTeamChatStart: () => {
    return {
      type: actions.FETCH_TEAM_CHAT_START,
    };
  },

  fetchTeamChatSuccess: teamChat => {
    return {
      type: actions.FETCH_TEAM_CHAT_SUCCESS,
      teamChat,
    };
  },

  fetchTeamChatFailed: err => {
    return {
      type: actions.FETCH_TEAM_CHAT_ERROR,
      err,
    };
  },
};

export default actions;

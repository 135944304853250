/* ------------------------------------------------------ */
/*                   Craft Data Display                   */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * On the right side the display of tool craft data.
 * Many tools have multiple outputs.
 *
 * User can also set and remove favourite specific tool output.
 * User can copy the content and saves in clipboard.
 *
 * Fav data is stored within a tool output data in firestore.
 * Inorder to save Favorite, the action has been set in firestore, it triggers the cloud function
 * and hasFavorites flag is being on tool output data from cloud function
 * Favorites is an array, containing contentId of the fav output.
 * A single data in firestore tool output have the multiple tool output
 *
 */

import React from 'react';
import { Typography, Image, Row, Col } from 'antd';
import ReactTimeAgo from 'react-time-ago';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSelector, useDispatch } from 'react-redux';
import { DataWrap } from '../styled';
import { Button } from '../../../components/Button/index.jsx';
import { HeartOutlined, HeartFilled, CheckOutlined, LoadingOutlined, DeleteOutlined } from '@ant-design/icons';
import { useViewport } from '../../../hooks/viewPort';
import { breakLineIfRequired, htmlToText, breakLineForCopy } from '../../../utility/utility';
import { maskBadWords } from '../../../utility/filterWords';
import {
  userProjectFavouriteSubmit,
  userProjectFavouriteRemove,
  saveCopyContent,
} from '../../../redux/UserProject/actionCreator';
import { addNotificationInfo } from '../../../components/utilities/notifications';
import { TOOL_CREATE_PROJECT_FAV } from '../../../constants/content';
import { messageAlert } from '../../../components/utilities/messages';
import copyImg from '../../../static/tool-page/paper.svg';

const CraftDataDisplay = ({ content, categoryId, setCopySuccess, copySuccess, onOutputDelete, highlightRowsId }) => {
  const { Text } = Typography;
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { favorites, favLoading } = useSelector((state) => {
    return {
      favorites: state.userProject.favorites,
      favLoading: state.userProject.favLoading,
    };
  });
  const { selectedCurrentProject } = useSelector((state) => state.userProject);

  // if the user clicks on the fav icon it might be add or remove the fav from tool ouput
  const onFavIconClick = ({ botId, date, contentData, categoryId, contentId, len }, isFavorit) => {
    if (selectedCurrentProject.currentProjectId) {
      isFavorit
        ? dispatch(userProjectFavouriteRemove({ botId, contentId }))
        : dispatch(userProjectFavouriteSubmit({ botId, categoryId, contentId }));
    } else {
      addNotificationInfo(TOOL_CREATE_PROJECT_FAV);
    }
  };

  // When the user copy any content, the content Id saves in firebase "copied-content"
  const onCopyIconClick = (rowId, contentId) => {
    messageAlert({ type: 'info', textMessage: 'Text Copied' });
    setCopySuccess(rowId);
    dispatch(saveCopyContent({ categoryId, contentId }));
  };
  const checkForImageContent = content.find(({ image }) => image === true);

  return (
    <>
      {/* content is present as an object in tooloutput */}
      <DataWrap>
        {!checkForImageContent &&
          content &&
          Array.isArray(content) &&
          content.map(
            (
              {
                additionalWords,
                botId,
                html,
                contentData,
                contentId,
                len,
                words,
                cfr,
                time,
                userUID,
                isFavorit = false,
              },
              index,
            ) => {
              let displayContentDataOutput = '';
              if (html) {
                displayContentDataOutput = contentData;
              } else {
                const { lines } = breakLineIfRequired(maskBadWords(contentData));
                displayContentDataOutput = lines;
              }

              isFavorit = favorites[categoryId] && favorites[categoryId].find((fav) => fav.contentId === contentId);

              const date =
                favorites &&
                favorites[categoryId] &&
                favorites[categoryId].find((fav) => fav.contentId === contentId)?.date;

              // it highlights the new tool output data. it diff the old and the new output data
              const highlight = highlightRowsId && highlightRowsId.includes(contentId);

              return (
                <div className={`tool-craft ${highlight && 'output-highlight'}`} key={index}>
                  <>
                    <div className="craft-wrapper">
                      <div className="header">
                        <Text className="time">
                          <ReactTimeAgo date={time || new Date()} timeStyle="round-minute" />{' '}
                        </Text>
                        <div className="char-words">
                          <Text className="count">{len} Chars </Text>
                          <Text className="count">{words} Words </Text>
                        </div>
                        {width < 768 && (
                          <div className="mobile-buttons">
                            {isFavorit && favLoading.spin && (
                              <Button
                                ghost={highlight}
                                className="output-icon-mobile"
                                onClick={() =>
                                  onFavIconClick({ len, userUID, contentData, categoryId, contentId }, isFavorit)
                                }
                              >
                                <HeartFilled style={{ color: '#6C5AFB' }} />
                              </Button>
                            )}
                            {!isFavorit && favLoading.spin && (
                              <Button
                                ghost={highlight}
                                className="output-icon"
                                onClick={() =>
                                  onFavIconClick({ len, userUID, date, contentData, categoryId, contentId }, isFavorit)
                                }
                              >
                                <HeartOutlined style={{ color: 'black' }} />
                              </Button>
                            )}
                            {favLoading.spin && favLoading.contentId === contentId && (
                              <Button ghost={highlight} className="output-icon">
                                <LoadingOutlined style={{ color: 'black' }} />
                              </Button>
                            )}

                            <Button ghost={highlight} className="output-icon-mobile">
                              <CopyToClipboard
                                text={htmlToText(breakLineForCopy(maskBadWords(contentData)))}
                                onCopy={() => onCopyIconClick(index, contentId)}
                              >
                                {copySuccess === index ? (
                                  <CheckOutlined style={{ color: 'black' }} />
                                ) : (
                                  <img src={copyImg} alt="copy-img" />
                                )}
                              </CopyToClipboard>
                            </Button>
                            {!isFavorit && (
                              <Button
                                ghost={highlight}
                                className="output-icon"
                                onClick={() => onOutputDelete({ contentId })}
                              >
                                <DeleteOutlined style={{ color: 'black' }} />
                              </Button>
                            )}
                          </div>
                        )}
                      </div>

                      {html ? (
                        <div className="craft-output">
                          <div
                            className="craft-output-text"
                            dangerouslySetInnerHTML={{ __html: displayContentDataOutput }}
                          />
                        </div>
                      ) : (
                        <div className="craft-output">
                          <p className="craft-output-text">{displayContentDataOutput} </p>
                        </div>
                      )}
                    </div>
                    <div className="action-icons">
                      {/* <Button className="plus-btn action-icon-button">
                             <img src={plus} alt="plus" />
                        </Button> */}
                      {width > 768 && (
                        <>
                          <Button ghost={highlight} className="output-icon">
                            <CopyToClipboard
                              text={htmlToText(breakLineForCopy(maskBadWords(contentData)))}
                              onCopy={() => onCopyIconClick(index, contentId)}
                            >
                              {copySuccess === index ? (
                                <CheckOutlined style={{ color: 'black' }} />
                              ) : (
                                <img src={copyImg} alt="copy-img" />
                              )}
                            </CopyToClipboard>
                          </Button>
                          {isFavorit && favLoading.contentId !== contentId && (
                            <Button
                              ghost={highlight}
                              className="output-icon"
                              onClick={() =>
                                onFavIconClick(
                                  { botId, len, userUID, date, contentData, categoryId, contentId },
                                  isFavorit,
                                )
                              }
                            >
                              <HeartFilled style={{ color: '#6C5AFB' }} />
                            </Button>
                          )}

                          {!isFavorit && favLoading.contentId !== contentId && (
                            <Button
                              ghost={highlight}
                              className="output-icon"
                              onClick={() =>
                                onFavIconClick(
                                  { botId, len, userUID, date, contentData, categoryId, contentId },
                                  isFavorit,
                                )
                              }
                            >
                              <HeartOutlined style={{ color: 'black' }} />
                            </Button>
                          )}
                          {favLoading.spin && favLoading.contentId === contentId && (
                            <Button ghost={highlight} className="output-icon">
                              <LoadingOutlined style={{ color: 'black' }} />{' '}
                            </Button>
                          )}
                          {!isFavorit && (
                            <Button
                              ghost={highlight}
                              className="output-icon"
                              onClick={() => onOutputDelete({ contentId })}
                            >
                              <DeleteOutlined style={{ color: 'black' }} />
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </>
                </div>
              );
            },
          )}
        {checkForImageContent && content && Array.isArray(content) && (
          <div className="image-generation">
            <Image.PreviewGroup>
              <Row gutter={[16, 16]}>
                {content.map(({ contentData }, index) => {
                  return (
                    <Col xs={24} sm={12}>
                      <Image
                        width={'100%'}
                        src={`data:image/jpeg;base64,${contentData.slice(2, -1)}`}
                        alt={`${index}-ai-image`}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Image.PreviewGroup>
          </div>
        )}
      </DataWrap>
    </>
  );
};

export default CraftDataDisplay;

import styled from 'styled-components';

const MenuStyleComPonent = styled.div`
  width: 100%;
  .ant-layout-sider {
    max-width: 256px !important;
    min-width: 256px !important;
    width: 256px !important;
    flex: 0 0 256px !important;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  @media (max-width: 768px) {
    .antd-content-wrapper {
      margin-top: ${(props) => (props.showMobileBanner ? '9rem' : '0px')};
    }
  }
`;

const SidebarHeaderStyle = styled.div`
  .SidebarHeader {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
    ${(props) => !props.sideBarClicked} {
      @media (max-width: 991px) {
        margin-left: 0;
        left: 256px !important;
      }
    }
  }
`;
const Div = styled.div`
  @media (max-width: 992px) {
    overflow: hidden;
  }

  .tool-modal .ant-modal-centered {
    overflow: inherit;
    display: none;
  }
  position: relative;
  height: auto;
  min-height: 100vh;
  @media (min-width: 768px) {
    .kFvobX {
      padding: 15px 15px 30px 15px;
    }
  }
  header {
    z-index: 999;

    .ant-menu-sub.ant-menu-vertical {
      .ant-menu-item {
        a {
          color: ${({ theme }) => theme['gray-color']};
        }
      }
    }
    .list-project-wrapper {
      margin-left: 5px;
      cursor: pointer;
      .list-projects {
        display: flex;
        flex-direction: column;
        line-height: 21px;
        margin-left: 1rem;
        margin-bottom: 0px;
        .project-title {
          font-size: 12px;
        }
        .project-name {
          font-size: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .header-search-tools {
      display: flex;
      align-items: center;
      height: 100%;

      @media only screen and (min-width: 1200px) {
        width: 80%;
        margin: auto;
      }
    }
    .mobile-action {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      .btn-auth {
        display: flex;
        align-items: center;
      }
    }
  }

  /* Sidebar styles */
  .nav-sidebar-icon {
    width: 8px !important;
    font-size: 17px !important;
    color: #adb4d2 !important;
    padding-left: 0px !important;
  }
  .ant-layout {
    background-color: #fff;
  }

  .ant-layout-sider {
    @media (max-width: 991px) {
      background: linear-gradient(285.83deg, #8878f9 -34.95%, #6c5afb 92.08%) !important;
      margin-top: 0px !important;
      height: 100vh !important;
      border-right: 0px !important;
    }
    .ant-layout-sider-zero-width-trigger {
      @media (max-width: 991px) {
        display: none;
      }
    }
    .ant-layout-sider-children {
      padding-bottom: 0;
      > .sidebar-nav-title {
        margin-top: 0px;
      }

      .ant-menu {
        overflow-x: hidden;
        background-color: transparent;
        font-size: 14px;

        .ant-menu-sub.ant-menu-inline {
          // background-color: #fff;
        }
        border-right: 0 none;
        .ant-menu-submenu,
        .ant-menu-item {
          .feather,
          img {
            width: 16px;
            font-size: 16px;
            color: ${({ theme }) => theme['extra-light-color']};
          }
          span {
            display: inline-block;
            color: ${({ theme }) => theme['dark-color']};
            transition: 0.3s ease;
            a {
              padding-left: 0px;
            }
          }
          .sDash_menu-item-icon {
            line-height: 0.6;
          }
        }
        .ant-menu-item {
          height: 17px;
          font-weight: 400;
          .menuItem-icon {
            width: auto;
          }
        }
        .ant-menu-item,
        .ant-menu-submenu-title {
          a {
            position: relative;
          }
          > span {
            width: 100%;
            .pl-0 {
              ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0px;
            }
          }
          .badge {
            position: absolute;
            ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 30px;
            top: 50%;
            transform: translateY(-50%);
            display: inline-block;
            height: auto;
            font-size: 10px;
            border-radius: 3px;
            padding: 3px 4px 4px;
            line-height: 1;
            letter-spacing: 1px;
            color: #fff;
            &.badge-primary {
              background-color: ${({ theme }) => theme['primary-color']};
            }
            &.badge-success {
              background-color: ${({ theme }) => theme['success-color']};
            }
          }
        }
        .ant-menu-submenu {
          .ant-menu-submenu-title {
            display: flex;
            height: 17px;
            font-weight: 400;
            align-items: center;
            .title {
              padding-left: 0;
            }
            .badge {
              ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 45px;
            }
          }
        }
        .ant-menu-submenu-inline {
          > .ant-menu-submenu-title {
            display: flex;
            align-items: center;
            padding: 0 15px !important;
            svg,
            img {
              width: 16px;
              height: 16px;
            }
            .ant-menu-submenu-arrow {
              right: auto;
              right: 24px;
              &:after,
              &:before {
                width: 8px;
                background: ${({ theme }) => theme['color-black']};
                height: 2.25px;
                font-weight: 700;
              }
              &:before {
                transform: rotate(45deg) ${({ theme }) => (!theme.rtl ? 'translateY(-3.3px)' : 'translateY(3.3px)')};
              }
              &:after {
                transform: rotate(-45deg) ${({ theme }) => (theme.rtl ? 'translateY(-3.3px)' : 'translateY(3.3px)')};
              }
            }
          }
          &.ant-menu-submenu-open {
            > .ant-menu-submenu-title {
              .ant-menu-submenu-arrow {
                transform: translateY(2px);
                &:before {
                  transform: rotate(45deg) translateX(-3.3px);
                }
                &:after {
                  transform: rotate(-45deg) translateX(3.3px);
                }
              }
            }
          }
          .ant-menu-item {
            padding-left: 0px !important;
            padding-right: 0 !important;
            transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
            a {
              padding-left: 50px !important;
            }
          }
        }
        .ant-menu-item {
          display: flex;
          align-items: center;
          padding: 0 15px;
          .ant-menu-title-content:hover {
            color: ${({ theme }) => theme['primary-color']};
          }

          &.ant-menu-item-active {
            border-radius: 4px;
          }
          a {
            width: 100%;
            display: flex !important;
            align-items: center;
            .feather {
              width: 16px;
              color: ${({ theme }) => theme['extra-light-color']};
            }
            span {
              ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 20px;
              display: inline-block;
              color: ${({ theme }) => theme['dark-color']};
            }
          }
          &.ant-menu-item-selected {
            svg,
            span,
            i {
              color: ${({ theme }) => theme['primary-color']};
            }
          }
        }
        .ant-menu-submenu,
        .ant-menu-item {
          ${({ theme }) => theme.rtl && `padding-right: 5px;`}
          &.ant-menu-item-selected {
            border-radius: 4px;
            &:after {
              content: none;
            }
          }
          &.ant-menu-submenu-active {
            border-radius: 4px;
          }
        }
        .sidebar-nav-title {
          color: #8c939b;
          margin-top: 25px;
          margin-bottom: 10px;
        }
        &.ant-menu-inline-collapsed {
          .ant-menu-submenu {
            text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')};
            .ant-menu-submenu-title {
              padding: 0 20px;
              justify-content: center;
            }
          }
          .ant-menu-item {
            padding: 0 20px !important;
            justify-content: center;
          }
        }
      }
    }
    .sidebar-nav-title {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      color: #9299b8;
      padding: 0 ${({ theme }) => (theme.rtl ? '20px' : '15px')};
      display: flex;
    }
    &.ant-layout-sider-collapsed {
      padding: 15px 0px 55px !important;
      margin-left: -256px !important;
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
      @media only screen and (max-width: 992px) {
        left: -256px !important;
        margin-left: 0 !important;
      }
      .sidebar-nav-title {
        display: none;
      }
      & + .atbd-main-layout {
        margin-left: 0;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
      }
      .ant-menu-item {
        color: #333;
        .badge {
          display: none;
        }
      }
    }
  }

  .atbd-main-layout {
    @media (max-width: 768px) {
      margin-bottom: 5rem;
      padding-top: 20px;
      background: #fff;
      position: relative;
      // margin-top: ${(props) => (props.showMobileBanner ? '' : '120px')};
    }
    margin-left: 256px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    @media (max-width: 961px) {
      position: relative;
      left: 0;
      margin-left: 0;
    }
    margin-top: ${(props) => (props.showTopBanner ? '118px' : '70px')};
    // transition: 0.3s ease;

    @media only screen and (max-width: 991px) {
      // ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: auto !important;
    }
    @media (min-width: 768px) {
      .Icon {
        bottom: 0px !important;
      }
    }
    .Icon {
      float: right;
      position: fixed;
      bottom: 80px;
      right: 4px;
    }

    .antd-content-wrapper {
      background-color: #ffffff;
      border: 1px solid #fff;
      // border-radius: 20px 20px 20px 20px;
    }
    @media (max-width: 767px) {
      .antd-content-wrapper {
        border-radius: 0;
        border: 0px solid #fff;
      }
    }
  }
`;

const SidebarStyleTheme = styled.div`
  .sidebarStyleTheme {
    margin: 70px 0px 0px;
    padding: 15px 10px 55px 5px;
    overflow-y: auto;
    height: 100vh;
    position: fixed;
    background: rgb(255, 255, 255);
    left: 0px;
    z-index: 999;
    flex: 0 0 256px !important;
    max-width: 256px !important;
    min-width: 256px !important;
    width: 256px !important;

    @media only screen and (max-width: 768px) {
      position: static !important;
      margin-left: -256px;
      background: #6c5afb;
    }
  }
`;

export { Div, SidebarStyleTheme, MenuStyleComPonent, SidebarHeaderStyle };

import styled from 'styled-components';
import Search from '../../../../static/new-teams/Search.svg';

const InviteWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0px 0px;

  .invite-wrap {
    display: flex;
    margin-bottom: 10px;
  }
  .invite-wrap {
    display: flex;
    gap: 13px;
  }

  button {
    margin-top: 31px;
    width: 561px;
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 24px;
    }
  }
  .ant-typography {
    color: #282b3f;
    font-family: 'Poppins-Medium';
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }
`;

const InviteHeader = styled.div`
  .invite-heading {
    font-size: 14px !important;
    font-family: 'Poppins-Medium' !important;
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }
  .ant-typography {
    margin: 0px;
    font-size: 24px;
    font-family: 'Poppins-SemiBold';
    font-weight: 500;
    margin-bottom: 4px;
    @media (max-width: 768px) {
      font-size: 20px;
    }
  }
`;

const InviteContainer = styled.div``;

const PeopleSearchBarWrap = styled.div`
  margin: 82px 0px 30px;
  @media (max-width: 768px) {
    margin: 31px 0px 25px;
  }
  .people-title {
    margin-bottom: 6px;
    .ant-typography {
      margin: 0px;
      font-size: 24px;
      font-family: 'Poppins-SemiBold';
      font-weight: 500;
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }
  .people-search {
    width: 100%;
  }
  .image-button {
    margin-right: 17px;
  }
`;

const SidebarMenuInput = styled.div`
  .ant-collapse-header {
    flex-direction: row-reverse;
  }
  .ant-collapse-content-box {
    display: none;
  }
  .ant-collapse-content {
    display: none;
  }
  .ant-collapse-header {
    background: white;
    border-radius: 6px;
    padding: 9.5px 12px !important;
    width: 191px;
    justify-content: space-between;
  }
  .imageuser {
    margin-right: 5.5px;
  }
  .ant-typography {
    font-weight: 500;
    font-family: 'Poppins-Regular';
    color: #737277;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 4px !important;
  }
  .ant-collapse {
    border: 1px solid #e2e4e8;
  }
`;

const PeapleSearchHead = styled.div`
  display: flex;
  justify-content- space-between;
  align-items: center;

  .search-input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
  }

  .ant-input::placeholder {
    font-family: 'Poppins-Medium';
  }
  .ant-input-affix-wrapper {
    padding: 9.5px 12px !important;
    border-radius: 6px;
  }
  .ant-input-prefix{
    content:url(${Search});
  }
  
`;
const MemberTableContainer = styled.div`
.member-table{
  .ant-table-thead > tr > th{
    background: white;
    font-size: 14px;
    font-family: 'Poppins-SemiBold';
    @media (max-width: 767px){
        font-size: 12px !important;
    }
  }
  @media (max-width: 767px){
    .ant-table-tbody{
      font-size: 10px !important;
    }
  }
  th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    {
      display: none;
    }
    }
  .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover{
    background: none !important;
  }
  .ant-table-pagination.ant-pagination {
    display: none;
}
`;

const AdminTableStyle = styled.div`
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 4px !important;
    background: transparent;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    gap: 10px;
    padding: 0;
    @media (max-width: 767px) {
      font-size: 10px;
    }
  }
  @media (max-width: 767px) {
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
      margin-top: 3px;
      font-size: 10px;
    }
  }
  .ant-collapse > .ant-collapse-item:last-child {
    display: flex;
    border: none;
    background-color: white;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
  .ant-collapse {
    border: none;
  }
`;

const TypographyStyle = styled.div`
  .person-icon {
    margin-right: 10px;
  }
`;

const TeamsWrapped = styled.div`
  padding: 29px 13.5px;
`;

const MemberMobileStyle = styled.div`
  button {
    font-size: 11px !important;
  }
  .image-button {
    margin-right: 10px;
  }
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin: 12px 11px;
  width: 100%;
  .sidemenu {
    width: 164px;
  }
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    @media (max-width: 767px) {
      font-size: 10px;
      margin: 0px;

      width: 164px;
    }
  }
  .ant-collapse-header {
    padding: 11px 12px !important;
    margin-bottom: 12px;
  }
`;
export {
  InviteWrap,
  InviteHeader,
  InviteContainer,
  PeopleSearchBarWrap,
  SidebarMenuInput,
  MemberTableContainer,
  AdminTableStyle,
  TypographyStyle,
  TeamsWrapped,
  PeapleSearchHead,
  MemberMobileStyle,
};

import styled from 'styled-components';

const CatBanner = styled.div`
  background: url(${require('../../static/category/BannerBackground.png')}) no-repeat 0 0 / cover;
  width: 100%;
  border-radius: 20px;
  @media (max-width: 768px) {
    display: none;
  }
  .catergory-banner {
    padding: 30px;
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    min-height: 211px;
    justify-content: center;
    .search-tool-area {
      width: 35rem;
      margin: auto;
    }
  }

  .ant-typography {
    margin-bottom: 0px;
    font-size: 32px;
  }

  .ant-row {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  .ant-input-affix-wrapper-lg {
    padding: 0;
    @media (min-width: 1350px) {
      width: 598.25px;
      height: 48.25px;
    }
    @media (max-width: 1349px) {
      width: 38rem;
    }
  }
  .ant-input-affix-wrapper > .ant-input-prefix {
    width: 48px;
    height: 48px;
    justify-content: center;
  }

  .ant-input-affix-wrapper > input.ant-input {
    padding-left: 15px;
    font-size: 14px;
    font-family: 'Poppins-Medium';
  }

  .anticon-close-circle {
    margin-right: 20px;
  }
`;

const CategoryConatiner = styled.div`
  padding: 16px;
  @media (min-width: 768px) {
    padding: 15px 22px 24px 15px;
  }
  .explore-section {
    margin-top: 2.5rem;
    margin-bottom: 10px;

    .explore-text {
      font-size: 16px;
      font-family: 'Poppins-Medium';
    }
  }
`;

const MenuItemsStyled = styled.div`
  padding: 20px 0px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-breadcrumb-link {
    color: #848484;
    font-family: 'Poppins-Medium';
  }

  .ant-breadcrumb-separator {
    margin: 0 8px;
    color: #6c5afb;
  }

  .ant-breadcrumb li:last-child a {
    color: #848484;
    font-weight: 500;
    font-size: 14px;
  }

  .menu-mobile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    .menu-category-mobile {
      font-size: 14px;
      font-weight: 500;
      color: #282b3f;
      font-family: 'Poppins-Medium';
      cursor: pointer;
    }
    .menu-selected-category {
      font-size: 14px;
      color: #848484;
      font-family: 'Poppins-Medium';
    }
  }

  .filter-wrapper {
    display: none;
    margin-right: 10px;
  }

  .sort-by {
    color: #848484;
  }

  .anticon svg {
    color: #8c49f7;
    margin-left: 11px;
    position: relative;
    top: 1.5px;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
    padding: 0 10px !important;
  }

  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    content: none !important;
  }

  .category-menu-wrapper {
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    display: flex;

    .menu-category {
      font-weight: 500;
      font-size: 14px;
      font-family: 'Poppins-Medium';
      display: inline-flex;
      white-space: nowrap;
      align-items: flex-start;
      display: inline-block;
      justify-content: start;
      cursor: pointer;
      padding-bottom: 12px;
    }
  }

  img {
    width: 10.5px !important;
    margin-left: 41px;
    height: auto !important;
    @media (max-width: 1169px) {
      width: 16px !important;
      margin-left: 10px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1390px) {
      margin-left: 7px;
    }
  }
  .ant-menu-light {
    border-bottom: none;
    background: none;
    border: none;
  }
  .ant-typography {
    @media (max-width: 768px) {
      padding: 3px 0px 20px 6px;
      font-family: 'Poppins-Regular';
    }
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    .web-menubar {
      gap: 5px;
    }
    .ant-menu-vertical .ant-menu-item {
      padding: 2px 5px 0px 5px !important;
    }
    img {
      width: 7.5px !important;
    }
    .ant-btn > span {
      font-size: 10px !important;
    }
    .ant-btn {
      padding: 0 6.5px;
    }
  }
`;
///

const CardWrap = styled.div`
  .ant-card-body {
    padding: 0px !important;
  }

  .ant-card-meta-title {
    font-family: 'Poppins-Medium';
    font-size: 18px;
    line-height: 27px;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }

  .ant-card-meta {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 20px 20px 0 20px;
    margin-bottom: 25px;
    @media (max-width: 768px) {
      padding: 15px 0 0 15px;
      margin-bottom: 18px;
    }
  }
  .ant-card-meta .ant-card-meta-avatar {
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 0;
  }
  .image {
    max-width: 176px;
    width: 100%;
    float: right;
    border-bottom-right-radius: 10px;
    @media (max-width: 768px) {
      width: 90%;
      max-width: 90%;
    }
  }

  .ant-col {
    width: 20%;
  }
  .custom-grid {
    .ant-card-body {
      .ant-card-meta {
        margin-bottom: 0px !important;
        padding: 20px 20px 0 0px !important;
        .ant-card-meta-detail {
          flex: none;
          .ant-card-meta-title {
            font-family: 'Poppins-Light';
            font-size: 15px;
            font-weight: 100;
          }
        }
      }
    }
    .ant-card-cover img {
      border-radius: 6px;
    }
    .category-image {
      .ant-card-cover {
        border: 1px solid #eee;
        border-radius: 6px;
      }
    }
  }

  @media (max-width: 786px) {
    .ant-col {
      width: 50%;
    }
  }

  @media (min-width: 787px) and (max-width: 990px) {
    .ant-col {
      width: 33%;
    }
  }
`;

const DashboardcardStyle = styled.div`
  .ant-card-body {
    border: none !important;
  }
`;

const DashboardToolsWrap = styled.div`
  .goback {
    background: linear-gradient(285.83deg, #8878f9 -34.95%, #6c5afb 92.08%);
    cursor: pointer;
    @media (max-width: 731px) {
      min-height: 150px !important;
    }
    @media only screen and (min-width: 822px) and (max-width: 1100px) {
      min-height: 240px !important;
    }
    @media only screen and (min-width: 1101px) and (max-width: 1256px) {
      min-height: 244px !important;
    }
  }

  .ant-card-body {
    padding: 0px !important;
    min-height: 210px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    @media (max-width: 731px) {
      min-height: 200px;
    }
    @media only screen and (min-width: 732px) and (max-width: 767px) {
      min-height: 250px;
    }
    @media only screen and (min-width: 768px) and (max-width: 855px) {
      min-height: 280px;
    }
    @media only screen and (min-width: 856px) and (max-width: 932px) {
      min-height: 240px;
    }
    @media only screen and (min-width: 993px) and (max-width: 1119px) {
      min-height: 340px;
    }

    @media only screen and (min-width: 1120px) and (max-width: 1290px) {
      min-height: 264px;
    }
  }

  .second-img {
    position: absolute;
    right: 0;
    @media (max-width: 731px) {
      height: 25px;
    }
  }

  .top {
    padding: 20px 17px 0px;
  }
  .link {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 14px;

    @media (max-width: 731px) {
      height: 25px;
    }
  }
  .box-secondary h1 {
    font-family: 'Poppins-SemiBold';
    font-weight: 500 !important;
    font-size: 1rem;
    color: ${({ theme }) => theme['white-color']};
    @media (max-width: 786px) {
      font-size: 1rem;
    }
  }
  .title {
    width: 100% !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    h1 {
      color: #282b3f;
      font-family: 'Poppins-SemiBold';
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 0;

      @media (max-width: 731px) {
        padding-right: 5px;
        font-family: 'Poppins-SemiBold';
        font-size: 9px;
      }
      a {
        color: ${({ theme }) => theme['dark-color']};
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 11px !important;
      }
    }
  }

  .desc {
    font-size: 13px;
    font-weight: 500;
    font-family: 'Poppins-Regular';
    color: #282b3f;
    line-height: 20px;
    margin: 11px 0 0px 0;
    @media (max-width: 731px) {
      margin-top: 5px;
      font-size: 12px;
      margin-bottom: 0px;
      font-family: 'Poppins-Regular';
      line-height: 14px;
    }
  }
`;

const Breadcrumbcontainer = styled.div`
  ::after {
    content: '';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 55px;
    height: 30px;
    right: -1px;
    position: absolute;
    z-index: 9999999;
    margin-left: auto;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.7) 40%, #fff 70%);
  }
  display: flex;
  display: -webkit-box !important;
  flex-direction: row;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  .category-web-menu {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins-Regular';
    color: #848484;
  }

  .ant-breadcrumb ol {
    cursor: pointer;
    color: #282b3f !important;
    font-weight: 500;
    font-size: 14px;
    padding: 10px 0px 20px 0px;
    min-width: max-content;
  }
  .ant-breadcrumb ol li {
    display: flex;
    align-items: center;
  }
`;

const SuggestedToolsStyled = styled.div`
  margin-top: 3rem;
  .suggested-text {
    font-size: 16px;
    font-family: 'Poppins-Medium';
  }
  .ant-col {
    width: 20%;
  }
  @media (max-width: 786px) {
    .ant-col {
      width: 50%;
    }
  }

  @media (min-width: 787px) and (max-width: 1280px) {
    .ant-col {
      width: 33%;
    }
  }
  .box-wrapper {
    margin-top: 10px;
  }

  .suggested-color-blog {
    background: #6c5afb;
  }
  .suggested-color-sentence {
    background: #58dfd7;
  }
  .suggested-color-improver {
    background: #42d68f;
  }
  .suggested-color-bullet {
    background: #ff9e20;
  }

  .suggested-box {
    border-radius: 10px;
    padding: 30px 24px;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .suggested-icon {
      margin-bottom: 11px;
      width: 46px;
      height: 46px;
    }
    .suggested-tool-title {
      color: #ffffff;
      font-size: 14px;
      line-height: 150%;
      font-family: 'Poppins-Medium';
    }
  }
`;

export {
  CatBanner,
  MenuItemsStyled,
  CategoryConatiner,
  CardWrap,
  DashboardToolsWrap,
  DashboardcardStyle,
  Breadcrumbcontainer,
  SuggestedToolsStyled,
};

/* ------------------------------------------------------ */
/*                    Admin Top Banner                    */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * Craftly Administration can display the notifications to different type of users. The Notifications appear on the top of the page as a banner.
 * Multitple notifcations can be added. and each notification have the following  fields
 * 1. speed
 * 2. Notification Text
 * 3. background color
 * 4. Plan - can select multiple plans. the purpose is to visble only those users who have one of these plan.
 *
 * Notifcations are stored in firestore.
 * Link: https://console.firebase.google.com/u/1/project/craftly-d50ba/firestore/data/~2Fnotifications~2Ftop-banner
 * Collection: notifications
 * Document: top-banner
 */

import React, { useState, useEffect } from 'react';
import { Form, Spin, Input, Tag, Button, Select, Typography, InputNumber, Divider } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Main } from '../styled';
import { getFirestore } from 'redux-firestore';
import { addNotificationError, addNotificationInfo } from '../../components/utilities/notifications';
import { AdminNotificationWrapper } from './styled';
import { useSelector } from 'react-redux';
import { TwitterPicker } from 'react-color';
import { ALL_PLAN_LIST } from '../../constants';
import PageTitle from '../../components/PageTitle';

const { Text } = Typography;
const { TextArea } = Input;

const AdminTopBanner = () => {
  const [addLoading, setAddLoading] = useState(false);
  const [backColor, setBackColor] = useState('');
  const { topBannerNotifications } = useSelector((store) => store.adminFeatures); // pre-saved notifications list in firestore

  // antd form
  const [form] = Form.useForm();
  const db = getFirestore();

  useEffect(() => {
    // To display the list of already saved notifications.
    form.resetFields();
    Object.keys(topBannerNotifications).forEach((data) => {
      form.setFieldsValue({
        [data]: topBannerNotifications[data],
      });
    });

    setBackColor(topBannerNotifications.bannerList);
  }, [topBannerNotifications]);

  // on submit antd form
  const onFinish = (values) => {
    setAddLoading(true);
    const { bannerList, autoPlaySpeed } = values; // values of all fields in form
    const bannerValue = bannerList === undefined ? [] : bannerList;
    try {
      // add data in firebase collection
      db.collection('notifications')
        .doc('top-banner')
        .set(
          {
            bannerList: bannerValue,
            autoPlaySpeed,
          },
          { merge: true },
        )
        .then(() => {
          addNotificationInfo('Notification Added');
          setAddLoading(false);
        });
    } catch (err) {
      addNotificationError(err.message);
      setAddLoading(false);
    }
  };

  // use to change the color of the banner background.
  const backgroundColorChange = (color, key) => {
    const fields = form.getFieldsValue();
    const { bannerList } = fields;
    Object.assign(bannerList[key], { backgroundColor: color.hex });
    form.setFieldsValue({ bannerList });
    setBackColor(bannerList);
  };

  // to display selected plans
  const planMultipleSelect = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={'purple'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {label}
      </Tag>
    );
  };

  return (
    <Main>
      <AdminNotificationWrapper>
        <PageTitle title="Top Banner Notification"> Top Banner Notification</PageTitle>
        <Spin spinning={false}>
          <Form form={form} layout="vertical" name="TopBannerList" onFinish={onFinish} autoComplete="off">
            {/* The speed controls how long it takes for the second notice to display.  */}
            <Form.Item
              name="autoPlaySpeed"
              label="Speed in Seconds"
              rules={[{ required: true, message: 'Speed is required' }]}
            >
              <InputNumber min={1} addonAfter="seconds" />
            </Form.Item>

            {/* In order to add multiple notifications, the admin can add multiple forms and each form have same fields */}
            <Form.List name="bannerList">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => {
                    return (
                      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Form.Item
                          {...restField}
                          name={[name, 'notificationText']}
                          fieldKey={[key, 'notificationText']}
                          rules={[{ required: true, message: 'Enter Notification First' }]}
                          label={
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <Text>Notification </Text>
                              {/* to remove the notification from the list */}
                              <div>
                                <MinusCircleOutlined
                                  style={{ color: 'red', margin: '0rem 10px' }}
                                  onClick={() => remove(name)}
                                />{' '}
                                Remove
                              </div>
                            </div>
                          }
                          style={{ width: '100%' }}
                        >
                          {/* Notification text. the background color is controlled by the backgroundColor field */}
                          <TextArea
                            style={{ backgroundColor: backColor && backColor[key] && backColor[key].backgroundColor }}
                            className="banner-input-design"
                            rows={1}
                            autoFocus
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'backgroundColor']}
                          fieldKey={[key, 'backgroundColor']}
                          label={
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                              <Text>Background Color </Text>
                            </div>
                          }
                          style={{ width: '100%' }}
                        >
                          {/* can select 1 background color or can provide HEX color code. */}
                          <TwitterPicker
                            color={backColor && backColor[key] && backColor[key].backgroundColor}
                            onChangeComplete={(color) => backgroundColorChange(color, index)}
                          />
                        </Form.Item>

                        <Form.Item
                          label="Plan"
                          name={[name, 'showPlan']}
                          fieldKey={[key, 'showPlan']}
                          rules={[{ required: true, message: 'Plan is required' }]}
                        >
                          <Select showArrow style={{ width: '400px' }} mode="multiple" tagRender={planMultipleSelect}>
                            {/* all types of plan users. Can select multiple plans */}
                            {ALL_PLAN_LIST.map(({ planTitle, planValue }) => {
                              return <Select.Option value={planValue}>{planTitle}</Select.Option>;
                            })}
                          </Select>
                        </Form.Item>
                        <Divider />
                      </div>
                    );
                  })}
                  <Form.ErrorList errors={errors} />

                  {/* This is to add multiple notifications */}
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      Add Notification
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item>
              <Button loading={addLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </AdminNotificationWrapper>
    </Main>
  );
};

export default AdminTopBanner;

/* ------------------------------------------------------ */
/*                USER DETAILS                            */
/* ------------------------------------------------------ */

/* ----------------- CRAFTLY ADMIN PAGE ----------------- */

/**
 *  Display the user profile in more detail
 *  1. Edit user profile
 *  2. Review Craftly outputs
 *  3. View Profile data
 *
 * User tool data is being from api request in cloudfunction  and data fetch from bigQuery view.
 *
 * Craftly admin can edit user profile, change status, view tool craft output data and change role type
 *
 * When somebody establishes an account, it will by default be a user.
 * The role type can be changed if the Craftly admin wishes to elevate the user to the position of Craftly admin.
 *
 */

import React, { useEffect } from 'react';
import moment from 'moment';
import { Form, Col, Row, Typography, Skeleton, Tag, Select, Tabs, Popconfirm, Input, Divider } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArrowUpOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';
import { Button } from '../../../components/Button';
import { Main } from '../../styled';
import { EditForm } from '../styled';
import { capitalizeFirstLetter, dateFormat, dateFormatWithTime } from '../../../utility/utility';
import { ADMIN_DELETE, NO_TRIAL_CC, ROLES, STATUS } from '../../../constants';
import {
  fetchUserDetailsByUID,
  changeUserProfile,
  deleteUserByAdmin,
  readBotsByDate,
  readAvailableBotDates,
} from '../../../redux/AdminFeatures/actionCreator';
import { noTrialccRemaingTime } from '../../../utility/calculateBilling';
import DisplayBots from '../../../components/DisplayBots';
import PageTitle from '../../../components/PageTitle';

const { Text, Link } = Typography;
const { Option } = Select;

const UserDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {
    fetchUserDetails,
    changeProfileLoading,
    userDeleteByAdminLoading,
    saveUIDForFetching,
    userBotsDate,
    userBotsDateLoading,
    userAvailableDates,
  } = useSelector((state) => state.adminFeatures);

  if (saveUIDForFetching === null || saveUIDForFetching === undefined) {
    navigate('/user-management');
  }

  useEffect(() => {
    saveUIDForFetching && dispatch(fetchUserDetailsByUID(saveUIDForFetching));
  }, [saveUIDForFetching]);

  const {
    roles,
    status,
    notes,
    name,
    uid,
    stripeLink,
    jam,
    createdDate,
    companyName,
    firstName,
    lastName,
    planStatus,
    trackingInfo,
    deletedBy,
    noTrialccPlanAcitivated,
    plan,
    phoneNumber,
    picture,
    email,
    displayPlanStatus,
  } = fetchUserDetails || {
    name: '',
    roles: '',
    status: '',
    notes: '',
    companyName: '',
    username: '',
    firstName: '',
    lastName: '',
    deletedBy: '',
    phoneNumber: '',
    allowTeamStarterPlan: false,
  };
  useEffect(() => {
    // fetch user craft output data by specific user
    // data fetch from bigQuery
    uid && dispatch(readAvailableBotDates(uid));
  }, [uid]);

  const { TabPane } = Tabs;

  const { daysRemaingWithZero, trialExpired } = noTrialccRemaingTime(noTrialccPlanAcitivated) || {};

  const onUserDelete = (selectedUserUId) => {
    dispatch(
      deleteUserByAdmin({ userUID: selectedUserUId, permanentDelete: false, deletedBy: `${ADMIN_DELETE} (${name})` }),
    );
  };

  const readUsersBots = (date) => {
    dispatch(readBotsByDate({ date, uid }));
  };

  const onFinish = (values: any) => {
    form
      .validateFields()
      .then((values) => {
        if (values && values.firstName && values.lastName) {
          const trimFirstName = values.firstName.replace(/\s+/g, ' ');
          const trimLastName = values.lastName.replace(/\s+/g, ' ');
          const newValues = {
            ...values,
            firstName: trimFirstName,
            lastName: trimLastName,
            name: `${trimFirstName} ${trimLastName}`,
            planStatus,
          };
          Object.keys(newValues).forEach((key) => newValues[key] === undefined && delete newValues[key]);
          // console.log('finish', { userData: newValues, uid });
          dispatch(changeUserProfile({ userData: newValues, uid }));
          // onEditForm({ values: newValues, uid: selectedData.uid });
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Main>
      <PageTitle
        title={capitalizeFirstLetter(name)}
        backIcon
        tags={
          <>
            <Tag color="blue">{`Role: ${capitalizeFirstLetter(roles)}`}</Tag>
            <Tag color="red">{`Status: ${capitalizeFirstLetter(status)}`}</Tag>
          </>
        }
        avatar={picture ? picture : 'https://avatars1.githubusercontent.com/u/8186664?s=460&v=4'}
      />

      <Tabs defaultActiveKey="1">
        <TabPane tab="View" key="1">
          <EditForm>
            <div style={{ paddingTop: '20px' }}>
              <Text strong>Name: </Text>
              <Text>{name ? name : 'Not Available'}</Text>
            </div>
            <div>
              <Text strong>Email: </Text>
              <Text>{email}</Text>
            </div>
            <div>
              <Text strong>Plan: </Text>
              <Text>{plan}</Text>
            </div>
            <div>
              <Text strong>Status: </Text>
              <Text>{displayPlanStatus}</Text>
            </div>
            <div>
              <Text strong>Created Date: </Text>
              <Text> {createdDate ? moment(createdDate.toDate()).format(dateFormat) : ''}</Text>
            </div>
            <div>
              <Text strong>Position: </Text>
              <Text>{jam && Array.isArray(jam) && jam.map((position) => <Tag color="#2db7f5">{position}</Tag>)}</Text>
            </div>

            <div>
              <Text strong> View Billing: </Text>
              <Link href={stripeLink} target="_blank">
                {stripeLink && (
                  <>
                    Stripe <ArrowUpOutlined style={{ transform: 'rotate(45deg)', fontSize: '16px' }} />{' '}
                  </>
                )}{' '}
                {stripeLink}
              </Link>
            </div>
            {deletedBy && status === STATUS.DELETED && (
              <div>
                <Text strong> Deleted by: </Text>
                <Text> {deletedBy} </Text>
              </div>
            )}
            <div style={{ paddingTop: '20px' }}>
              <Text strong>Phone Number: </Text>
              <Text>{phoneNumber ? <Tag color="#855bf7">+{phoneNumber}</Tag> : 'Not Available'}</Text>
            </div>

            {noTrialccPlanAcitivated && plan === NO_TRIAL_CC && (
              <div>
                <div>
                  <Text strong>Trial Type: </Text>
                  <Text>No Credit Card</Text>
                </div>
                <div>
                  <Text strong>Activated at: </Text>
                  <Text>
                    {noTrialccPlanAcitivated ? moment(noTrialccPlanAcitivated.toDate()).format(dateFormatWithTime) : ''}
                  </Text>
                </div>
                {!trialExpired && planStatus === 'active' && (
                  <div>
                    <Text strong>Expire in: </Text>
                    <Text>
                      <Tag color="#2db7f5">{daysRemaingWithZero}</Tag>
                    </Text>
                  </div>
                )}
                <div>
                  <Text strong>Trial Status:</Text>
                  <Text> {trialExpired ? 'Expired' : 'Not Expired'} </Text>
                </div>
              </div>
            )}

            {trackingInfo && (
              <div>
                <div>
                  <Text strong> Country: </Text>
                  <Text> {trackingInfo.countryName} </Text>
                </div>
                <div>
                  <Text strong> City: </Text>
                  <Text> {trackingInfo.city} </Text>
                </div>
                <div>
                  <Text strong> State: </Text>
                  <Text> {trackingInfo.state} </Text>
                </div>
                <div>
                  <Text strong> IPaddress: </Text>
                  <Text> {trackingInfo.IPaddress} </Text>
                </div>
                <div>
                  <Text strong> Browser: </Text>
                  <Text> {trackingInfo.browserName} </Text>
                </div>
                <div>
                  <Text strong> Browser Version: </Text>
                  <Text> {trackingInfo.browserVersion} </Text>
                </div>
                <div>
                  <Text strong> Browser Language: </Text>
                  <Text> {trackingInfo.browserLanguage || ''} </Text>
                </div>
                <div>
                  <Text strong> Device: </Text>
                  <Text> {trackingInfo.device} </Text>
                </div>
              </div>
            )}
          </EditForm>
          <Divider />
          {fetchUserDetails && (
            <>
              <div style={{ margin: '1rem 0px' }}>
                <Text strong>For Talal & Safee Only</Text>
              </div>
              <ReactJson src={fetchUserDetails} collapsed={true} />{' '}
            </>
          )}
        </TabPane>

        <TabPane tab="Edit" key="2">
          {fetchUserDetails ? (
            <Row gutter={24}>
              <Col sm={24} lg={12}>
                <Form
                  form={form}
                  layout="vertical"
                  name="user-edit"
                  onFinish={onFinish}
                  initialValues={{
                    firstName: firstName,
                    lastName: lastName,
                    roles: roles,
                    status: status,
                    companyName: companyName,
                    notes: notes,
                  }}
                >
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input first name',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input last name',
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="roles" label="Role">
                    <Select>
                      <Option value={ROLES.ADMIN}>Admin</Option>
                      <Option value={ROLES.USER}>User</Option>
                      <Option value={ROLES.STAFF}>Staff</Option>
                      <Option value={ROLES.FREE_USER}>Free User</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="status" label="Status">
                    <Select disabled={status === STATUS.DELETED}>
                      <Option value={STATUS.ACTIVE}>Approved</Option>
                      <Option value={STATUS.PAUSE}>Pause</Option>
                      <Option value={STATUS.DEACTIVE}>Deactive</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="notes" label="Note">
                    <Input />
                  </Form.Item>
                  <Form.Item name="companyName" label="Company">
                    <Input />
                  </Form.Item>

                  <Form.Item>
                    <Button loading={changeProfileLoading} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
                <br />
                <Text type="warning">Note: In a Delete action, all user's craft and stripe data will be deleted.</Text>
                <br />
                <Popconfirm
                  placement="top"
                  title="Are you sure"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => onUserDelete(uid)}
                >
                  <Button loading={userDeleteByAdminLoading} style={{ width: '30%' }} type="danger">
                    Delete User
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          ) : (
            <Skeleton active avatar paragraph={{ rows: 5 }} />
          )}
        </TabPane>
        <TabPane tab="Bots" key="3">
          <DisplayBots
            uid={uid}
            viewType={'userManagement'}
            userBotsDateLoading={userBotsDateLoading}
            userBotsDate={userBotsDate}
            readUsersBots={readUsersBots}
            userAvailableDates={userAvailableDates}
          />
        </TabPane>
      </Tabs>
    </Main>
  );
};

export default UserDetails;

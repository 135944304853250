import { INPUT_LANGUAGE, OUTPUT_LANGUAGE } from '../constants';

export const listLangOptions = [
  {
    langCode: 'BG',
    langName: 'Bulgarian',
    CountryFlagCode: 'BG',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'CS',
    langName: 'Czech',
    CountryFlagCode: 'CZ',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'EN',
    langName: 'English',
    CountryFlagCode: 'US',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'EN-GB',
    langName: 'English (British)',
    CountryFlagCode: 'GB',
    display: [OUTPUT_LANGUAGE],
  },
  {
    langCode: 'ZH',
    langName: 'Chinese',
    CountryFlagCode: 'CN',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'DA',
    langName: 'Danish',
    CountryFlagCode: 'DK',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'NL',
    langName: 'Dutch',
    CountryFlagCode: 'NL',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'DE',
    langName: 'German',
    CountryFlagCode: 'DE',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'EL',
    langName: 'Greek',
    CountryFlagCode: 'GR',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'ET',
    langName: 'Estonian',
    CountryFlagCode: 'CN',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'FI',
    langName: 'Finnish',
    CountryFlagCode: 'FI',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'FR',
    langName: 'French',
    CountryFlagCode: 'FR',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'HU',
    langName: 'Hungarian',
    CountryFlagCode: 'HU',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'IT',
    langName: 'Italian',
    CountryFlagCode: 'IT',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'JA',
    langName: 'Japanese',
    CountryFlagCode: 'JP',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'LT',
    langName: 'Lithuanian',
    CountryFlagCode: 'LT',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'LV',
    langName: 'Latvian',
    CountryFlagCode: 'LV',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'PL',
    langName: 'Polish',
    CountryFlagCode: 'PL',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'PT-PT',
    langName: 'Portuguese',
    CountryFlagCode: 'PT',
    display: [OUTPUT_LANGUAGE],
  },
  {
    langCode: 'PT',
    langName: 'Portuguese',
    CountryFlagCode: 'PT',
    display: [INPUT_LANGUAGE],
  },
  {
    langCode: 'PT-BR',
    langName: 'Portuguese (Brazilian)',
    CountryFlagCode: 'BR',
    display: [OUTPUT_LANGUAGE],
  },
  {
    langCode: 'RO',
    langName: 'Romanian',
    CountryFlagCode: 'RO',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'RU',
    langName: 'Russian',
    CountryFlagCode: 'RU',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'SK',
    langName: 'Slovak',
    CountryFlagCode: 'SK',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'SL',
    langName: 'Slovenian',
    CountryFlagCode: 'SI',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'SV',
    langName: 'Swedish',
    CountryFlagCode: 'SE',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
  {
    langCode: 'ES',
    langName: 'Spanish',
    CountryFlagCode: 'ES',
    display: [INPUT_LANGUAGE, OUTPUT_LANGUAGE],
  },
];

/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import { Card, Checkbox, Col, Row, Typography } from 'antd';
import React from 'react';
import { useViewport } from '../../../../hooks/viewPort';
import { ContentCategoryOne } from './mock';
import { ContentCategoryWrap, ContentWrap } from './styled';

const { Text, Title } = Typography;

const ContentCategory = () => {
  const { width } = useViewport();

  return (
    <ContentCategoryWrap>
      <Row gutter={[17, 32]}>
        {ContentCategoryOne.map((item) => (
          <Col lg={8} md={8} sm={24} xs={24}>
            <ContentWrap>
              <Card>
                <div className="card1">
                  <div>
                    <img src={item.image} alt="Search" />
                    <Text>{item.name}</Text>
                  </div>
                  <Checkbox>Check all</Checkbox>
                </div>
              </Card>
              <Card>
                <div className="content-card">
                  {item.input.map((key) => (
                    <Checkbox>{key.nameCat}</Checkbox>
                  ))}
                </div>
              </Card>
              {width > 768 && <Title level={5}>{item.text}</Title>}
            </ContentWrap>
          </Col>
        ))}
      </Row>
    </ContentCategoryWrap>
  );
};

export default ContentCategory;

import React from 'react';
import { TaskListWrap } from './styled';
import FontAwesome from 'react-fontawesome';

const BotOutput = ({ botsOutput }) => {
  const { output, favData } = botsOutput || { output: null, favData: null };
  const content = output && output;

  return (
    <TaskListWrap className="mb-30">
      <div className="sDash_tasklist-wrap">
        <div className="sDash_tasklist-head">
          <h2 className="sDash_task-list-title">Content</h2>
        </div>
        <div className="sDash_tasklist-body">
          <ul className="sDash_tasks">
            {content &&
              content.map(({ contentData, contentId, cfr, len, words }, i) => {
                const isFav = favData && favData.find((fav) => fav.contentId === contentId);
                return (
                  <li className="sDash_tasks-item" key={i}>
                    <div className="sDash_tasks-item__content">
                      <div className="sDash_tasks-item__text">
                        {cfr === '0' && <p style={{ color: '#313ee4' }}>{contentData}</p>}
                        {cfr === '1' && <p style={{ color: 'orange' }}>{contentData}</p>}
                        {cfr === '2' && <p style={{ color: 'red' }}>{contentData}</p>}
                        <span className="nav-item-text"> Chars: {len}</span>
                        <br />
                        <span className="nav-item-text"> Words: {words}</span>
                      </div>
                    </div>
                    {/* task-favourite */}
                    <div className="sDash_tasks-item__action">
                      <FontAwesome
                        className={`${isFav ? 'fav-icon-filled' : 'icon-color '} `}
                        name={isFav ? 'heart' : 'heart-o'}
                      />
                    </div>
                    <br />
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </TaskListWrap>
  );
};

export default BotOutput;

import actions from './actions';

const initialState = {
  teamChat: {
    email: '',
    chatContent: [],
  },
  loading: false,
};

const { FETCH_TEAM_CHAT_START, FETCH_TEAM_CHAT_SUCCESS, FETCH_TEAM_CHAT_ERROR } = actions;

const teamChatReducer = (state = initialState, action) => {
  const { type, teamChat, err } = action;
  switch (type) {
    case FETCH_TEAM_CHAT_START:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TEAM_CHAT_SUCCESS:
      return {
        ...state,
        teamChat,
        loading: false,
      };
    case FETCH_TEAM_CHAT_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    default:
      return state;
  }
};

export default teamChatReducer;

import Styled from 'styled-components';
import { Carousel } from 'antd';

const TopNoticeCarousel = Styled(Carousel)`
.slick-dots-bottom {
    bottom: 4px !important;
} 
`;

const CarouselText = Styled.div`
display: flex !important;
justify-content: center;
align-items: center;
background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#ef4c61')} ;
height: 50px;
font-size: 16px;
color: white;
overflow: hidden;
padding: 0 2px;

.text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1.5rem;
    padding-right: 10px;
    
}
.see-more-icon {
    cursor: pointer;
    padding-right: 1rem;

}

.topbar-icon {
    width: 15px;
    height: 14px;
    margin-right: 8px;
}
.ant-typography {
    color: white;
    text-overflow: ellipsis;
    word-break: break-all;
}

.link {
    margin: 0px 7px;
    color: #f8ab2a;
} 
`;

export { TopNoticeCarousel, CarouselText };

export const TabItemData = [
  { key: 'edit-profile', title: 'Edit Profile' },
  { key: 'password', title: 'Password' },
  { key: 'account', title: 'Account' },
  { key: 'billing', title: 'Billing' },
  { key: 'workspace', title: 'Workspace' },
  { key: 'language', title: 'Language' },
  { key: 'notifications', title: 'Notifications' },
  { key: 'Integrations', title: 'Integrations' },
];

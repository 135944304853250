import styled from 'styled-components';

const TeamManagementWrapper = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1rem;

  .team-spinner {
    display: flex;
    justify-content: center;
    height: 20rem;
    align-items: center;
    flex-direction: column;
  }
  .loading-gif {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .page-wrapper {
    display: flex;
    justify-content: center;
    .page-title {
      margin: auto;
      font-size: 28px;
      font-weight: 500;
      @media only screen and (max-width: 575px) {
        font-weight: 300;
      }
    }
  }

  .view-results {
    display: flex;
    justify-content: end;
  }
  .title-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    @media screen and (max-width: 575px) {
      flex-direction: column;
    }
    .plan-points {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
    }

    .welcome-title {
      font-size: 19px;
      font-weight: 400;
      @media only screen and (max-width: 575px) {
        font-weight: 300;
      }
    }
    .subtitle {
      font-size: 14px;
      color: ${({ theme }) => theme['light-grey-color']};
      margin: 6px 0px;

      .icon {
        color: ${({ theme }) => theme['primary-color']};
        margin-right: 10px;
      }
    }
  }
  .expiring-plan {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 2rem;
  }
  .seatsWithTeamPlan {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    .seats {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 2rem 0px 2rem 0px;
      .seats-note {
        margin-left: 1rem;
      }
    }
    .proration {
      width: 32rem;
      @media only screen and (max-width: 576px) {
        width: 100%;
      }
    }
  }

  .create-team-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2.4rem;
  }
  .required-plan {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    color: ${({ theme }) => theme['light-grey-color']};
    font-style: italic;
    font-size: 18px;
  }

  .getting-started {
    font-size: 14px;
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme['light-grey-color']};
    margin-top: 2.4rem;
  }
  .team-note {
    margin-top: 5rem;
    font-size: 12px;
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme['light-grey-color']};
  }
`;

const TeamSummary = styled.div`
  margin-top: 1.5rem;
  .team-summary {
    display: flex;
    justify-content: space-between;

    .details {
      .title {
        font-size: 12px;
        color: ${({ theme }) => theme['light-gray-color']};
      }
      .description {
        font-size: 28px;
        margin-top: 3px;
        margin-bottom: 0px;
        font-weight: 500;
        letter-spacing: -1px;
      }
    }
    .plan-icon {
      width: 32%;
      height: 100%;
      margin-right: -38px;
    }
  }
`;

const ListMembers = styled.div`
  .list-title {
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  .list-count {
    color: ${({ theme }) => theme['light-gray-color']};
    margin-left: 2px;
  }
`;

const ListMemberDetailStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid ${({ borderColorOnHOLD, theme }) => (borderColorOnHOLD ? theme['secondary-color'] : '#f5f5f5')};

  .member-action {
    display: flex;
    justify-content: center;
    height: 100%;
    .avatar {
      margin: auto;
    }
  }

  .member-details-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .member-name {
      margin-top: 1.5rem;
      font-size: 1.2rem;
      margin-bottom: 0px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
    .member-status {
      color: #959ca8;
      font-size: 13px;
    }
    .phoneNumber {
      margin-top: 1rem;
    }
    .email {
      color: #959ca8;
      font-size: 13px;
      margin-top: 0.5rem;
    }
    .member-counts {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .member-words {
        font-weight: 500;
      }
      .words-title {
        font-size: 13px;
        color: #959ca8;
        margin-right: 10px;
      }
    }
  }
`;

const TeamMembersWrapper = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 3rem;
  .additional {
    margin: 0;
    color: ${({ theme }) => theme['light-color']};
  }
  .subtitle {
    font-size: 14px;
    color: ${({ theme }) => theme['light-grey-color']};
    margin: 6px 0px;
    .icon {
      color: ${({ theme }) => theme['primary-color']};
      margin-right: 10px;
    }
  }
  .one-seat {
    font-size: 14px;
    margin: 6px 0px;
    .icon {
      color: ${({ theme }) => theme['primary-color']};
      margin-right: 10px;
    }
  }
  .add-more-seats {
    display: flex;
    justify-content: end;
    @media only screen and (max-width: 576px) {
      justify-content: start;
      margin-top: 2rem;
    }
  }

  .team-list {
    margin-top: 2rem;
    .team-member-email {
      color: ${({ theme }) => theme['light-color']};
    }
  }
`;

const MemberSummaryWrapper = styled.div`
    padding: 1.5rem;
    border: 1px solid;
    border-color: #EAF0F3;
    border-left: 0px;
    overflow: auto;
    
    .member-summary-wrapper {
      display: flex;
      .member{
        margin-left: 27px;
        margin-top: 10px;
        .name {
            font-weight: 500; 
            margin-bottom: 0px;
        }
        .text {
          font-size: 13px;
           color: ${({ theme }) => theme['grey-text-color']};
        }
        .date-text {
          font-size: 13px;
          margin-left: 10px
        }
        .date {
            margin-top: 1rem; 
            display: flex;
        }
        .red-dot {
            width: 7px;
            height: 7px;
            background: red;
            border-radius: 50%;
            margin-top: 6px;
            margin-right: 6px;
        }
      }
    }
    .last-active {
      color: ${({ theme }) => theme['light-gray-color']};
      font-size: 12px;
    }
     
    .most-tool {
        font-size: 18px;
        margin-bottom: 13px;
    } 
    .top-tool {
        margin-top: 0rem;
        border-radius: 4px
        display: flex;
        flex-direction: row;
        .tool-image {
            width: 15%
        }
        .tool-details {
            width: 90%;
            padding-left: 1.2rem
            .title {
                margin: 0;
                padding: 4px 0rem;
                font-size: 1rem;
            }
            .description {
                color: #959ca8;
                padding: 4px 0rem;
            }
        }
    }
    
`;

const UsersBotsWrapper = styled.div`
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  .user-bots {
    background-color: rgb(248, 250, 252);
    padding: 1.5rem;
    border: 1px solid;
    border-color: #eaf0f3;
  }
  .tools-card-heading {
    color: #272b41;
    font-weight: 500;
    font-size: 14px;
  }
  .extra-features {
    color: ${({ theme }) => theme['grey-text-color']};
    font-weight: 500;
    font-size: 13px;
  }
  .ant-card-head-title {
    padding: 10px 0;
    span {
      margin-left: 0px;
    }
  }
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    span {
      font-size: 15px;
    }
    .ant-checkbox-wrapper {
      padding: 6px 30px;
      &:hover {
        background-color: #f4f1ff;
      }
    }
    .ant-checkbox-group-item {
      margin-right: 0px;
    }
  }
`;

const UserBotsWrapper = styled.div`
  .bot-sidebar {
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(${({ theme }) => theme['gray-color']}, 0.03);
    min-height: 600px;
    max-height: 600px;
    overflow: auto;

    .inputWrapper {
      padding: 1.2rem;
      border: 1px solid #e2ecf1;
      border-radius: 7px;
      background-color: #fafbfc;
      margin: 1rem 0rem;
      cursor: pointer;
    }
    .inputWrapperSelected {
      border: 1px solid ${({ theme }) => theme['primary-color']};
    }
  }

  .bot-sidebar::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .bot-sidebar::-webkit-scrollbar:vertical {
    width: 11px;
  }

  .bot-sidebar::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  .bot-sidebar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const ToolOutputContent = styled.div`
  background-color: ${({ hasContent }) => (hasContent ? `#fff` : 'transparent')};
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(#9299b8, 0.03);
  min-height: 600px;
  max-height: 600px;
  overflow: auto;
  border: ${({ hasContent }) => (hasContent ? `1px solid #e2ecf1` : 'none')};

  .content-wrapper {
    padding-bottom: 25px;
    .content-list {
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 30px;
        &:not(:last-child) {
          border-bottom: 1px solid #e2ecf1;
        }
        @media only screen and (max-width: 575px) {
          padding: 15px 20px;
          flex-direction: column;
          align-items: flex-start;
        }

        .output-details-wrapper {
          .content-icon-wrapper {
            display: flex;
            flex-direction: column;
            align-items: end;
            height: 100%;
            .content-icon {
              font-size: 18px;
              color: ${({ theme }) => theme['primary-color']};
              padding: 1rem 9px 0px;
            }
          }
          .output-text {
            color: #455263;
          }
          .output-summary {
            font-weight: 600;
          }
        }
      }
    }
  }
`;

const Focard = styled.div`
  canvas{
      width: 100% !important;
      margin-top: 50px;
      @media only screen and (max-width: 1199px){
          margin-top: 15px;
      }
  }
  .focard-details{
      &.growth-downward{
          h1{                
              font-size: 30px;
              @media only screen and (max-width: 767px){
                  font-size: 24px;
              }
          }
          .focard-status{
              .focard-status__percentage{
                  color: ${({ theme }) => theme['danger-color']};
                  font-size: 16px;
              }
          }
      }
      &.growth-upward{
          .focard-status{
              .focard-status__percentage{
                  color: ${({ theme }) => theme['success-color']};
                  font-size: 16px;
              }
          }
      }
      h1{
          font-weight: 600;
          margin-bottom: 2px;
      }
      .subtitle{
          font-size: 14px;
          color: #868EAE;
          margin-bottom: 10px;
      }
      .focard-status{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 0 -5px;
          span{
              display: inline-flex;
              align-items: center;
              margin: 0 5px;
              &.focard-status__percentage{
                  font-weight: 500;
              }
          }
          span + span{
              color: #868EAE;
              font-size: 13px;
          }
      }
      svg{
          width: 15px;
          ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
      }
  }

  .focard-chart{
      ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: -10px;
  }

  @media (max-width: 1300px){
      .focard-details{
          h1{
              font-size: 24px;
          }
          &.growth-downward{
              h1{
                  font-size: 24px;
              }
          }
      }
  }

  .forcast-card-box {
      .ant-card-body {
        padding: 0px 15px !important;
          h1{
              padding: ${({ theme }) => (theme.rtl ? '25px 25px 0 0' : '25px 0 0 25px')};
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 26px;
              @media only screen and (max-width: 767px){
                  margin-bottom: 16px;
              }
            } 
        }
      }

      .focard-details{
          margin-top: 15px;
          padding: 0 25px 22px;
          @media only screen and (max-width: 767px){
              padding: 0 25px 0;
          }
          h1{
              padding: 0;
              font-size: 30px;
              font-weight: 600;
              margin-bottom: 4px;
              @media only screen and (max-width: 767px){
                  font-size: 24px;
              }
          }
          p{
              margin-bottom: 0;
          }
      }
      canvas{
          margin-top: 0px;
          border-radius: 0 0 10px 10px;
      }
    
      .bots-date-range {
        display: flex;
        justify-content: center;
        margin-bottom: 4rem;
        margin-top: 1rem;
      }
  }
`;
const RatioCard = styled.div`
  .ant-card-body {
    h1 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .ratio-content {
    cursor: pointer;
    margin-top: 30px;
    @media only screen and (max-width: 767px) {
      margin-top: 25px;
    }
    h1 {
      margin-bottom: 2px;
      font-size: 36px;
      font-weight: 600;
      @media only screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
    .ant-progress {
      margin-bottom: 12px;
      .ant-progress-bg {
        height: 6px !important;
      }
      .ant-progress-text {
        position: absolute;
        ${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
        bottom: 26px;
        font-weight: 500;
      }
      &.progress-success {
        .ant-progress-text {
          color: ${({ theme }) => theme['success-color']};
        }
      }
      &.ant-progress-status-warning {
        .ant-progress-text {
          color: ${({ theme }) => theme['warning-color']};
        }
      }
    }
    p {
      color: ${({ theme }) => theme['light-color']};
      margin-bottom: 0;
      strong {
        font-size: 13px;
        color: ${({ theme }) => theme['dark-color']};
        font-weight: 600;
      }
    }
  }
`;

const ProrationWrapper = styled.div`
  .seatsWithTeamPlan {
    .question {
      font-size: 16px;
    }
    .seats {
      padding: 1rem 0px 1rem 0px;
      align-items: center;
      display: flex;
      .seats-note {
        margin-left: 1rem;
      }
    }
  }
  .note {
    padding: 10px 0px;
  }
  .amount-due-today {
    margin-top: 1rem;
  }
  .spinner {
    display: flex;
    justify-content: center;
    margin: 2rem 0rem;
    .ant-spin-nested-loading {
      width: 100%;
      @media only screen and (max-width: 961px) {
        background: linear-gradient(285.83deg, #8878f9 -34.95%, #6c5afb 92.08%) !important;
      }
    }
    .action-proration-button {
      margin-top: 1rem;
    }
  }
`;

const TeamDashboardWrapper = styled.div`
  margin-top: 2rem;

  .team-dashboard-card-box {
    .ant-card-head {
      border-bottom: none;
    }
    border: 1px solid #eeeeee;
    border-radius: 10px;

    height: 22rem;
    max-height: 22rem;
    min-height: 22rem;
    overflow: auto;
    padding: 15px !important;
    padding-bottom: 1.5rem !important;
    h1 {
      padding: ${({ theme }) => (theme.rtl ? '25px 25px 0 0' : '25px 0 0 25px')};
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 26px;
      @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
  }

  .top-tools {
    scrollbar-width: thin;
    scrollbar-color: blue #9078fc;
    .top-tool {
      background: #fbfaff;
      padding: 1rem;
      margin-bottom: 1rem;
      margin-top: 0rem;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      .tool-image {
        width: 10%;
      }
      .tool-details {
        width: 90%;
        padding-left: 1.2rem;
        .title {
          margin: 0;
          padding: 4px 0rem;
        }
        .description {
          color: #959ca8;
          padding: 4px 0rem;
        }
      }
    }
  }
  .top-tools::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  .top-tools::-webkit-scrollbar-track {
    background: #ebebeb; /* color of the tracking area */
    width: 1px !important;
  }

  .top-tools::-webkit-scrollbar-thumb {
    border-radius: 5px; /* roundness of the scroll thumb */
    border: 5px solid #9078fc; /* creates padding around scroll thumb */
    width: 5px;
  }
  .team-details {
    display: flex;
    padding: 1rem 1.5rem;
    .img {
      .icon {
        width: 75%;
        margin-right: 1rem;
        @media screen and (max-width: 992px) {
          width: 90%;
        }
      }
    }
    .detail {
      width: 90%;
      .count {
        margin-bottom: 0px;
      }
      .active-team {
        color: #8f97a1;
      }
    }
  }
  .overall-craft {
    padding: 1rem;
    .amount {
      font-size: 2.5rem;
      margin-bottom: 0px;
    }
    .description {
      color: #959ca8;
    }
  }
  .add-new-member {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .add-icon {
      padding-bottom: 10px;
      width: 75%;
      margin-right: auto;
      margin-left: auto;
      @media screen and (max-width: 768px) and (max-width: 1200px) {
        width: 50%;
      }
    }
  }
`;
const TeamMembersPageWrapper = styled.div`
  margin-top: 2rem;
  .team-member-card-box {
    .ant-card-head {
      border-bottom: none;
    }
    .ant-card-body {
      padding: 1.5rem 2rem !important;
      .member-count {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .details {
          display: flex;
          flex-direction: column;
          .count {
            text-align: end;
            margin-bottom: 0px;
          }
          .description {
            color: #959ca8;
            font-size: 13px;
          }
        }
        .ant-progress-text {
          margin-left: 0px !important;
        }
      }
    }
  }
  .add-member {
    display: flex;
    .invite-team-layout {
      display: flex;
      width: 100%;
      .ant-form-inline {
        width: 100%;
      }
      .ant-input {
        margin-right: 15px;
        //font-size: 14px;
      }
      .ant-btn {
        font-size: 14px;
      }
    }
    .add-member-email {
      margin-right: 1rem;
      padding: 12px 24px;
    }
    .add-button {
      padding: 23px;
    }
  }
`;

const ModalContentWrapper = styled.div`
  .ant-modal-body {
    border: 2px solid white;
    border-radius: 3px;
  }

  .content {
    text-align: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .button-action {
    display: flex;
    justify-content: center;
  }
`;

export {
  TeamManagementWrapper,
  TeamSummary,
  ListMembers,
  MemberSummaryWrapper,
  UserBotsWrapper,
  ToolOutputContent,
  UsersBotsWrapper,
  TeamMembersWrapper,
  Focard,
  RatioCard,
  ProrationWrapper,
  TeamDashboardWrapper,
  TeamMembersPageWrapper,
  ListMemberDetailStyled,
  ModalContentWrapper,
};

/* -------------------------------------------------------------*/
/*                       TRIAL PRICE PAGE                       */
/* ------------------------------------------------------------- */

export const TRAIL_STARTER_DATA = [
  {
    name: '100+ Expert Trained Tools',
    flag: true,
    hover: 'A tool for every use-case',
  },
  {
    name: 'Continuous Optimization',
    flag: true,
    hover: 'Go global- Craft to and from multiple languages',
  },
  {
    name: '25+ Languages ',
    flag: true,
    hover: 'Craft, write, edit and format in-app',
  },
  {
    name: 'Live Support',
    flag: true,
  },
  {
    name: 'Long-Form Tools',
    flag: false,
  },
  {
    name: 'Documents Drive',
    flag: false,
  },
  {
    name: 'Teams',
    flag: false,
  },
];

export const TRAIL_CRAFTER_DATA = [
  {
    name: 'Everything in Starter +',
    flag: true,
    recommended: true,
  },
  {
    name: 'Long-form Tools',
    flag: true,
    hover: 'Access long-form blog building tools',
    recommended: true,
  },
  {
    name: 'Text-Editor + Drive',
    flag: true,
    hover: 'Store, access and edit your files',
    recommended: true,
  },
  {
    name: 'Command Tool',
    flag: true,
    hover: 'Add up to 10 members for $25/ seat',
    recommended: true,
  },
  {
    name: 'Access to Teams',
    flag: true,
    hover: 'Pin specific tool-sets that suit your needs',
    recommended: true,
  },
  {
    name: 'Command Tool',
    flag: true,
    hover: "Tell Cai what to do and she'll Craft it",
    recommended: true,
  },
  {
    name: 'Priority Support & Onboarding',
    flag: true,
    recommended: true,
  },
  {
    name: 'Access to AI Image Generation',
    flag: true,
    recommended: true,
  },
  {
    name: 'Custom Tools & Training',
    flag: false,
    recommended: true,
  },
];

export const TRAIL_CUSTOM_DATA = [
  {
    name: 'Custom Tools and Training',
    flag: true,
    hover: 'Collaborate with all colleagues across different departments',
  },
  {
    name: 'Custom Word Count',
    flag: true,
    hover: ' Customize the input and output character allowance',
  },
  {
    name: 'Custom Seat Count',
    flag: true,
    hover:
      'If you can think it, we can build it. Access custom input fields, output formatting, audience, tone and more',
  },
  {
    name: 'Fine-tuned Models',
    flag: true,
    hover: "Maintain brand voice and get the results you're looking for with a fine-tuned Al model",
  },
  {
    name: 'API Access & Whitelabel Opportunities',
    flag: true,
    hover: 'APl available upon application. Please contact us to learn more!',
  },
  {
    name: 'Dedicated Manager',
    flag: true,
    hover: 'Whitelabel solutions available upon application.Please contact us to learn more!',
  },
];

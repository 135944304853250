/* ------------------------------------------------------ */
/*                       Plan Points                      */
/* ------------------------------------------------------ */

/*
  Methadology. 
  Each plan have their own features. Those features are represents as points. 
  A tooltip will popup to provide further details in order to explain key functionalities.
  Each feature is represented by this component. The list is supplied by the parent component, and the list renders to display each point.

  Used in  
  src/container/Trial/Trial.jsx
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ListStyle } from './styled';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as FACROSS } from '../../static/trial/cross.svg';
import CheckIcon from '../../static/trial/check';

/**
 @string key: unique number 
 @string text: Plan point
 @boolean condition: check whether to show tooltip or not.
 */
const PlanPoints = ({ key, text, recommended, condition, hover }) => {
  /*
    Show the plan feature with an icon and text. A tooltip will show on chosen ones for more clarification.
  */
  return (
    <ListStyle className="list-single" key={key}>
      {/* condition determines the icon between check (tick) or cross  */}
      {condition ? (
        // check (tick) icon
        <CheckIcon color={recommended ? '#58DFD7' : '#8878F9'} /> // on recommended section the color of the icon is chnaged
      ) : (
        // cross icon
        <Icon component={FACROSS} className="cross-circle" />
      )}

      <Tooltip placement="top" title={hover} color={'white'}>
        <span className="text" style={!condition ? { cursor: 'pointer', color: '#a0a0a8' } : { cursor: 'pointer' }}>
          {text}
        </span>
      </Tooltip>
    </ListStyle>
  );
};
PlanPoints.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]),
};
export default PlanPoints;

/* ------------------------------------------------------ */
/*                   TEAM RESULT PAGE              */
/* ------------------------------------------------------ */

/**
 *
 * Team results is the way to review all the team member workspaces
 * there are filters such as
 *  1. users
 *  2. projects
 *  3. tools
 *
 * On the bases of these filter the data of input and output displays
 *
 *
 *  Firestore
 *  Collection: team-members
 *  Document : <UID>
 *
 *
 * When the team admin wants to review the data we sent the team admin UID to fetch the team data.
 * when the team member wants to review, we send the team admin UI to fetch the data.
 *
 * Team ID is the same of TEAM ADMIN UID
 */

import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, DatePicker, Divider, Space, Checkbox, Spin, Input, Empty } from 'antd';
import { UserBotsWrapper, ToolOutputContent, UsersBotsWrapper } from '../style';
import { getCarftWordsFromSentence } from '../../../utility/calculateBilling';
import moment from 'moment';
import { capitalizeFirstLetter, dateFormat, dateFormatWithSlash } from '../../../utility/utility';
import { Main } from '../../styled';
import { useDispatch, useSelector } from 'react-redux';
import { PERSONAL_WORKSPACE, TEAM_RESULTS, TEAM_STATUS, TEAM_WORKSPACE } from '../../../constants';
import {
  fetchCopiedMembersContent,
  fetchTeamMembers,
  getWorkSpaceBots,
} from '../../../redux/AccountSettings/actionCreator';
import { Cards } from '../../../components/Card';
import FontAwesome from 'react-fontawesome';
import TeamHeader from '../../../components/TeamPageHeader/TeamHeader';
import { SearchOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { Text, Title, Paragraph } = Typography;

const TeamResults = () => {
  const [selectedTeamMembers, setSelectedTeamMembers] = useState('');
  const [selectedProjects, setSelectedProjects] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTools, setSelectedTools] = useState('');
  const [selectedInputBotId, setSelectedInputBotId] = useState('');
  const [acceptedMembers, setAcceptedMembers] = useState([]);
  const [projectsOptions, setProjectsOptions] = useState([]);
  const [toolsOptions, setToolsOptions] = useState([]);
  const [toolInput, setToolInput] = useState('');
  const [toolOutput, setToolOutput] = useState('');
  const [projectLoading, setProjectLoading] = useState(true);
  const [firstTimeCheckAll, setFirstTimeCheckAll] = useState(true);
  const [userSearch, setUserSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');
  const [toolSearch, setToolSearch] = useState('');

  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(true);

  const [projectIndeterminate, setProjectIndeterminate] = React.useState(true);
  const [projectCheckAll, setProjectCheckAll] = React.useState(true);

  const [memberIndeterminate, setMemberIndeterminate] = React.useState(true);
  const [memberCheckAll, setMemberCheckAll] = React.useState(true);

  const [favIndeterminate, setFavIndeterminate] = React.useState(false);
  const [favCheck, setFavCheck] = React.useState(false);

  const [CopiedIndeterminate, setCopiedIndeterminate] = React.useState(false);
  const [copiedCheck, setCopiedCheck] = React.useState(false);

  const dispatch = useDispatch();
  const { teamMembersList, teamName, teamMembersLoading, selectedWorkspace, workspaceBots, memberCopiedContent } =
    useSelector((state) => state.accountSettings);
  const { uid } = useSelector((state) => state.fb.auth);
  const { allTools: toolList } = useSelector((store) => store.adminFeatures);
  const { data: projectData } = useSelector((state) => state.userProject);
  const { mirrorTeamUID } = useSelector((state) => state.team);

  useEffect(() => {
    setSelectedDate(moment().format(dateFormat));
  }, []);

  useEffect(() => {
    const { workspaceType, selectedUID } = selectedWorkspace || {};
    if (mirrorTeamUID !== null) {
      setProjectLoading(true);
      dispatch(fetchTeamMembers(mirrorTeamUID));
      dispatch(fetchCopiedMembersContent(mirrorTeamUID));
    } else {
      // this is the differenece between member and admin.

      if (workspaceType === PERSONAL_WORKSPACE) {
        setProjectLoading(true);

        // this must be team admin who wants to get the data
        dispatch(fetchTeamMembers(uid));
        dispatch(fetchCopiedMembersContent(uid));
      } else if (workspaceType === TEAM_WORKSPACE) {
        setProjectLoading(true);

        // it fetch team members from firestore collection
        dispatch(fetchTeamMembers(selectedUID));
        dispatch(fetchCopiedMembersContent(selectedUID));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace, uid]);

  useEffect(() => {
    if (toolList) {
      const teamOptions =
        toolList &&
        toolList
          .filter(
            ({ isVisible, title }) =>
              title && isVisible === true && title.toLowerCase().includes(toolSearch.toLocaleLowerCase()),
          )
          .map(({ id, title, isVisible }) => {
            return { label: title, value: id };
          });
      setToolsOptions(teamOptions);
    }
  }, [toolList, toolSearch]);

  useEffect(() => {
    const { workspaceType, teamProjects } = selectedWorkspace || {};
    if (workspaceType === PERSONAL_WORKSPACE) {
      const getActiveMembers =
        teamMembersList &&
        teamMembersList
          .filter(
            ({ teamInviteStatus, teamMemberName }) =>
              teamMemberName &&
              (teamInviteStatus === TEAM_STATUS.ACCEPTED || teamInviteStatus === TEAM_STATUS.ACTIVE) &&
              teamMemberName.toLowerCase().includes(userSearch.toLowerCase()),
          )
          .map(({ teamMemberUID, teamMemberName }) => {
            return { label: capitalizeFirstLetter(teamMemberName), value: teamMemberUID };
          });

      setAcceptedMembers(getActiveMembers);
      if (projectData) {
        const teamOptions =
          projectData &&
          projectData
            .filter(({ project }) => project.toLowerCase().includes(projectSearch.toLowerCase()))
            .map(({ project, projectId }) => {
              return { label: project, value: projectId };
            });

        setProjectsOptions(teamOptions);
        teamOptions && Array.isArray(teamOptions) && setProjectLoading(false);
      }
    } else if (workspaceType === TEAM_WORKSPACE) {
      const getActiveMembers =
        teamMembersList &&
        teamMembersList
          .filter(
            ({ teamInviteStatus, teamMemberName }) =>
              (teamInviteStatus === TEAM_STATUS.ACCEPTED || teamInviteStatus === TEAM_STATUS.ACTIVE) &&
              teamMemberName.toLowerCase().includes(userSearch.toLowerCase()),
          )
          .map(({ teamMemberUID, teamMemberName }) => {
            return { label: capitalizeFirstLetter(teamMemberName), value: teamMemberUID };
          });
      setAcceptedMembers(getActiveMembers);
      if (teamProjects) {
        const teamOptions =
          teamProjects &&
          teamProjects
            .filter(({ project }) => project && project.toLocaleLowerCase().includes(projectSearch.toLocaleLowerCase()))
            .map(({ project, projectId }) => {
              return { label: project, value: projectId };
            });
        setProjectsOptions(teamOptions);
        setProjectLoading(false);
      }
    }
  }, [projectData, selectedWorkspace, uid, userSearch, projectSearch, teamMembersList]);

  useEffect(() => {
    const { workspaceType, selectedUID } = selectedWorkspace || {};

    // if team admin then team admin UID
    if (workspaceType === PERSONAL_WORKSPACE) {
      teamMembersList && teamMembersList.length > 0 && dispatch(getWorkSpaceBots({ workspaceAdminUID: uid }));
    }
    // if team member then team admin UID.
    else if (workspaceType === TEAM_WORKSPACE) {
      teamMembersList && teamMembersList.length > 0 && dispatch(getWorkSpaceBots({ workspaceAdminUID: selectedUID }));
    }
  }, [selectedWorkspace, teamMembersList, uid]);

  const onToolInputClick = (inputBotId) => {
    setSelectedInputBotId(inputBotId);
    const toolOutput = workspaceBots.filter(
      ({ date, toolName, botId, recentProjectId, uid }) =>
        date === selectedDate &&
        selectedTools.includes(toolName) &&
        selectedProjects.includes(recentProjectId) &&
        selectedTeamMembers.includes(uid) &&
        botId === inputBotId,
    );
    const toolResults = toolOutput.map(({ output, favorites, recentProjectId, toolName, userName, date }) => {
      return { output, recentProjectId, toolName, userName, favorites, date };
    });
    setToolOutput(toolResults);
  };

  const currentDate = selectedDate
    ? moment(selectedDate).format(dateFormatWithSlash)
    : moment().format(dateFormatWithSlash);

  const botDates = workspaceBots && [...new Set(workspaceBots.map((item) => item.date))];

  const disabledDate = (current) => {
    const currentDate = current.format('YYYY-MM-DD');
    return botDates && !botDates.includes(currentDate);
  };

  const onDateClick = (clickDate) => {
    setSelectedDate(clickDate);
  };

  function onDateChange(date, dateString) {
    setSelectedDate(dateString);
    onDateClick(dateString);
  }

  function onMemberChange(checkedValues) {
    setSelectedTeamMembers(checkedValues);
    setFirstTimeCheckAll(false);
  }
  function onProjectChange(checkedProjects) {
    setSelectedProjects(checkedProjects);
    setFirstTimeCheckAll(false);
  }
  function onToolChange(checkedValues) {
    setSelectedTools(checkedValues);
    setFirstTimeCheckAll(false);
  }

  useEffect(() => {
    const chooseDate = selectedDate ? selectedDate : moment().format(dateFormat);
    const toolOutput = workspaceBots.filter(
      ({ date, toolName, uid, recentProjectId }) =>
        date === chooseDate &&
        selectedTools.includes(toolName) &&
        selectedProjects.includes(recentProjectId) &&
        selectedTeamMembers.includes(uid),
    );
    const toolInput = toolOutput.map(({ input, botId, output }) => {
      return { input, botId };
    });
    setToolInput(toolInput);
    toolInput && toolInput.length === 0 && setToolOutput([]);
    toolInput && toolInput.length === 0 && setSelectedInputBotId([]);
  }, [selectedTeamMembers, selectedDate, selectedTools, selectedProjects, workspaceBots]);

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      const onlyToolId =
        toolsOptions &&
        toolsOptions.map(({ value }) => {
          return value;
        });
      setSelectedTools(onlyToolId);
    } else {
      setSelectedTools([]);
    }
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    setFirstTimeCheckAll(false);
  };

  const ToolsCard = () => {
    return (
      <Cards headless bodypadding="14px">
        <Row gutter={16} justify="space-between">
          <Col xs={24} sm={24} lg={15}>
            <Input
              size="small"
              enterButton
              allowClear
              placeholder="Tools"
              bordered={false}
              prefix={<SearchOutlined style={{ fontSize: '1rem' }} />}
              onChange={(e) => setToolSearch(e.target.value)}
              value={toolSearch}
            />
          </Col>
          <Col xs={24} sm={24} lg={9}>
            <div style={{ textAlign: 'end' }}>
              <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                <Text className="tools-card-heading"> Check all</Text>
              </Checkbox>
            </div>
          </Col>
        </Row>
      </Cards>
    );
  };

  const onProjectCheckAllChange = (e) => {
    if (e.target.checked) {
      const onlyProjectId = projectsOptions.map(({ value }) => {
        return value;
      });
      setSelectedProjects(onlyProjectId);
    } else {
      setSelectedProjects([]);
    }
    setProjectIndeterminate(false);
    setProjectCheckAll(e.target.checked);
    setFirstTimeCheckAll(false);
  };
  const ContentCard = () => {
    return (
      <Row gutter={16}>
        <Col>
          <div>
            <Checkbox indeterminate={favIndeterminate} onChange={onFavCheck} checked={favCheck}>
              <Text className="extra-features">Favourites</Text>
            </Checkbox>
          </div>
        </Col>
        <Col>
          <div>
            <Checkbox indeterminate={CopiedIndeterminate} onChange={onCopiedCheck} checked={copiedCheck}>
              <Text className="extra-features">Copied</Text>
            </Checkbox>
          </div>
        </Col>
      </Row>
    );
  };
  const ProjectsCard = () => {
    return (
      <Cards headless bodypadding="14px">
        <Row gutter={16} justify="space-between">
          <Col xs={24} sm={24} lg={15}>
            <Input
              size="small"
              enterButton
              allowClear
              placeholder="Projects"
              bordered={false}
              prefix={<SearchOutlined style={{ fontSize: '1rem' }} />}
              onChange={(e) => setProjectSearch(e.target.value)}
            />
          </Col>
          <Col xs={24} sm={24} lg={9}>
            <div style={{ textAlign: 'end' }}>
              <Checkbox
                indeterminate={projectIndeterminate}
                onChange={onProjectCheckAllChange}
                checked={projectCheckAll}
              >
                <Text className="tools-card-heading"> Check all</Text>
              </Checkbox>
            </div>
          </Col>
        </Row>
      </Cards>
    );
  };

  const MembersCard = () => {
    return (
      <Cards headless bodypadding="14px">
        <Row gutter={16} justify="space-between">
          <Col xs={24} sm={24} lg={15}>
            <Input
              value={userSearch}
              size="small"
              enterButton
              allowClear
              placeholder="Users"
              bordered={false}
              prefix={<SearchOutlined style={{ fontSize: '1rem' }} />}
              onChange={onUserSearch}
            />
          </Col>
          <Col xs={24} sm={24} lg={9}>
            <div style={{ textAlign: 'end' }}>
              <Checkbox indeterminate={memberIndeterminate} onChange={onMemberCheckAllChange} checked={memberCheckAll}>
                <Text className="tools-card-heading">Check all</Text>
              </Checkbox>
            </div>
          </Col>
        </Row>
      </Cards>
    );
  };

  const onMemberCheckAllChange = (e) => {
    if (e.target.checked) {
      const onlyMemberId =
        acceptedMembers &&
        acceptedMembers.map(({ value }) => {
          return value;
        });
      setSelectedTeamMembers(onlyMemberId);
    } else {
      setSelectedTeamMembers([]);
    }
    setMemberIndeterminate(false);
    setMemberCheckAll(e.target.checked);
    setFirstTimeCheckAll(false);
  };

  const onUserSearch = (e) => {
    setUserSearch(e.target.value);
  };

  const onFavCheck = (e) => {
    setFavIndeterminate(false);
    setFavCheck(e.target.checked);
  };

  const onCopiedCheck = (e) => {
    setCopiedIndeterminate(false);
    setCopiedCheck(e.target.checked);
  };

  const checkIsCopied = (checkContentId) => {
    return memberCopiedContent.find(({ contentId }) => contentId === checkContentId);
  };

  useEffect(() => {
    const onlyMemberId =
      acceptedMembers &&
      acceptedMembers.map(({ value }) => {
        return value;
      });
    firstTimeCheckAll && onlyMemberId && setSelectedTeamMembers(onlyMemberId);
  }, [acceptedMembers, firstTimeCheckAll]);

  useEffect(() => {
    const onlyToolId =
      toolsOptions &&
      toolsOptions.map(({ value }) => {
        return value;
      });
    firstTimeCheckAll && setSelectedTools(onlyToolId);

    const onlyProjectId = projectsOptions.map(({ value }) => {
      return value;
    });
    firstTimeCheckAll && setSelectedProjects(onlyProjectId);
  }, [toolsOptions, acceptedMembers, projectsOptions, firstTimeCheckAll]);

  const showContent = ({
    userName,
    index,
    cfr,
    len,
    contentData,
    toolFullName,
    projectName,
    displayFavHeart,
    displayCopiedHeart,
  }) => {
    return (
      <li className="content" key={index}>
        <div className="output-details-wrapper">
          <Row justify="space-between">
            <Col xs={22}>
              <Paragraph className="output-text"> {contentData}</Paragraph>
              <Space className="output-summary">
                <span> Chars: {len}</span>
                <Divider type="vertical" />
                <span>Words: {getCarftWordsFromSentence(contentData)}</span>
                <Divider type="vertical" />
                <span>Tool: {toolFullName && toolFullName.label}</span>
                <Divider type="vertical" />
                <span>Project: {projectName && projectName.label}</span>
                <Divider type="vertical" />
                <span>Crafted By: {capitalizeFirstLetter(userName)}</span>
              </Space>
            </Col>
            <Col xs={2}>
              <div className="content-icon-wrapper">
                <FontAwesome
                  //  favourites-128  these are the icons of saving and removing fav content
                  className="content-icon"
                  name={displayFavHeart ? 'heart' : 'heart-o'}
                />

                <FontAwesome
                  className="content-icon"
                  //  favourites-128  these are the icons of saving and removing fav content
                  name={displayCopiedHeart ? 'file' : 'file-o'}
                />
              </div>
            </Col>
          </Row>
        </div>

        <br />
      </li>
    );
  };

  return (
    <Main>
      <UsersBotsWrapper>
        <Row style={{ marginTop: '2rem', marginBottom: '1rem' }}>
          <Col xs={24}>
            <TeamHeader
              name={teamName}
              isCraftlyAdminView={mirrorTeamUID}
              showPageName={'Results'}
              currentPageToDisplay={TEAM_RESULTS}
            />
          </Col>
        </Row>
        <>
          <Row>
            <Col xs={24}>
              <Cards headless bodypadding="24px 35px 28px 35px">
                <Title level={5} style={{ fontWeight: 400, marginBottom: '1rem' }}>
                  Crafted Content
                </Title>
                <Row gutter={16} align="middle" justify="start">
                  <Col>
                    <DatePicker
                      // defaultValue={moment(currentDate, dateFormat)}
                      value={dayjs(currentDate, dateFormat)}
                      format={dateFormat}
                      disabledDate={disabledDate}
                      onChange={onDateChange}
                      size={'small'}
                    />
                  </Col>
                  <Col>{ContentCard()}</Col>
                </Row>
              </Cards>
            </Col>
          </Row>

          <Row gutter={25}>
            <Col xs={24} sm={8}>
              {MembersCard()}
              <Cards
                headless
                bodypadding={'10px 0px'}
                bodyStyle={{ minHeight: '300px', maxHeight: '300px', overflow: 'auto' }}
              >
                <Spin spinning={teamMembersLoading}>
                  <Checkbox.Group value={selectedTeamMembers} options={acceptedMembers} onChange={onMemberChange} />
                </Spin>
              </Cards>
            </Col>
            <Col xs={24} sm={8}>
              {ProjectsCard()}
              <Cards
                headless
                bodypadding={'10px 0px'}
                bodyStyle={{ minHeight: '300px', maxHeight: '300px', overflow: 'auto' }}
              >
                <Spin spinning={projectLoading}>
                  <Checkbox.Group value={selectedProjects} options={projectsOptions} onChange={onProjectChange} />
                </Spin>
              </Cards>
            </Col>
            <Col xs={24} sm={8}>
              {ToolsCard()}
              <Cards
                bodypadding={'10px 0px'}
                headless
                bodyStyle={{ minHeight: '300px', maxHeight: '300px', overflow: 'auto' }}
              >
                <Checkbox.Group value={selectedTools} options={toolsOptions} onChange={onToolChange} />
              </Cards>
            </Col>
          </Row>

          <Row gutter={25}>
            <Col xl={10} lg={10} md={8} sm={8} xs={24}>
              <UserBotsWrapper>
                <div className="bot-sidebar">
                  <Title level={4}>Input</Title>

                  {toolInput &&
                    toolInput.map(({ input, botId }, index) => {
                      return (
                        <div
                          key={botId}
                          className={`inputWrapper ${selectedInputBotId === botId && 'inputWrapperSelected'}`}
                          onClick={() => onToolInputClick(botId)}
                        >
                          <Text>{input}</Text>
                        </div>
                      );
                    })}
                  {toolInput && toolInput.length === 0 && <Empty />}
                </div>
              </UserBotsWrapper>
            </Col>

            <Col xl={14} lg={14} md={16} sm={16} xs={24}>
              <Title level={4}>Content</Title>

              <ToolOutputContent hasContent={toolOutput && toolOutput.length === 0 ? false : true}>
                <div className="content-wrapper">
                  {toolOutput &&
                    toolOutput.map(({ output, favorites, date, recentProjectId, toolName, userName }) => {
                      return (
                        <>
                          <ul className="content-list">
                            {output &&
                              output.content &&
                              Array.isArray(output.content) &&
                              output.content.map(({ contentData, contentId, cfr, len }, index) => {
                                const displayFavHeart = favorites && favorites.includes(contentId);
                                const displayCopiedHeart = checkIsCopied(contentId);
                                const toolFullName =
                                  toolsOptions && toolsOptions.find(({ value }) => toolName === value);
                                const projectName =
                                  projectsOptions && projectsOptions.find(({ value }) => recentProjectId === value);
                                return (
                                  <>
                                    {favCheck === true &&
                                      copiedCheck === false &&
                                      displayFavHeart &&
                                      showContent({
                                        index,
                                        cfr,
                                        userName,
                                        contentData,
                                        toolFullName,
                                        projectName,
                                        displayFavHeart,
                                        displayCopiedHeart,
                                        len,
                                      })}
                                    {favCheck === false &&
                                      copiedCheck === false &&
                                      showContent({
                                        index,
                                        cfr,
                                        userName,
                                        contentData,
                                        toolFullName,
                                        projectName,
                                        displayFavHeart,
                                        len,
                                        displayCopiedHeart,
                                      })}
                                    {favCheck === false &&
                                      copiedCheck === true &&
                                      displayCopiedHeart &&
                                      showContent({
                                        index,
                                        cfr,
                                        userName,
                                        contentData,
                                        toolFullName,
                                        projectName,
                                        displayFavHeart,
                                        len,
                                        displayCopiedHeart,
                                      })}
                                    {favCheck === true &&
                                      copiedCheck === true &&
                                      displayCopiedHeart &&
                                      displayFavHeart &&
                                      showContent({
                                        index,
                                        cfr,
                                        userName,
                                        contentData,
                                        toolFullName,
                                        projectName,
                                        displayFavHeart,
                                        displayCopiedHeart,
                                        len,
                                      })}
                                  </>
                                );
                              })}
                          </ul>
                        </>
                      );
                    })}
                  {toolOutput && toolOutput.length === 0 && <Empty />}
                </div>
              </ToolOutputContent>
            </Col>
          </Row>
        </>
      </UsersBotsWrapper>
    </Main>
  );
};

export default TeamResults;

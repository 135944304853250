/* ------------------------------------------------------ */
/*                        Settings                        */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * By default the edit-Profile will be the default tab
 *
 * If the user joins as a normal user and plan is STARTER or CRAFTER  then workspace selection is not visble.
 * Team plan is must for Personal Workspace to have multiple workspace
 *
 * If a user joins Craftly as a team member and the team admin later removes him from the team,
 * the user will have no workspace options and will only have Personal workspace.
 * In the tab options there will be no Workspace option for the user
 *
 *
 */

import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import NewBilling from '../Billing';
import PageHeader from '../../components/PageHeader';
import NotificationComponent from './overview/NotificationComponent';
import LanguageSettings from './overview/LanguageSettings';
import Password from './overview/Password';
import EditProfileSettings from './overview/EditProfileSettings';
import { TabItemData } from './settingData';
import AccountSettings from './overview/AccountSettings';
import WorkspaceSettings from './overview/WorkspacesSettings';
import { TEAM } from '../../constants';
import { NewTabStyle } from './styled';

const { TabPane } = Tabs;

const Settings = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [activeTab, setActiveTab] = useState('edit-profile');
  const { usersTeams } = useSelector((state) => state.accountSettings);
  const { plan } = useSelector((state) => state.fb.profile);

  useEffect(() => {
    const { pathname } = location;
    const subLink = pathname.split('/');
    if (subLink && Array.isArray(subLink) && subLink.length > 1) {
      setActiveTab(subLink[2]);
    }
  }, [location]);

  const onChange = (key) => {
    navigate({
      pathname: `/settings/${key}`,
    });
    setActiveTab(key);
  };

  const handleTab = (item) => {
    if (item?.key === 'edit-profile') {
      return <EditProfileSettings />;
    } else if (item?.key === 'account') {
      return <AccountSettings />;
    } else if (item?.key === 'password') {
      return <Password />;
    } else if (item?.key === 'notifications') {
      return <NotificationComponent />;
    } else if (item?.key === 'language') {
      return <LanguageSettings />;
    } else if (item?.key === 'billing') {
      return <NewBilling />;
    } else if (item?.key === 'workspace') {
      return <WorkspaceSettings />;
    }
  };

  const displayTabs = TabItemData.map((data) => {
    if (data.key === 'workspace') {
      // workspace option will not be visible if the user dont have an active team
      // Team plan is must for Personal Workspace to have multiple workspace
      return (plan === TEAM || (usersTeams && usersTeams.length > 0)) && { ...data };
    } else {
      return { ...data };
    }
  }).filter(Boolean);

  console.log('displayTabs', displayTabs);
  return (
    <>
      <PageHeader hideTranslation={true} title="Settings" />
      <NewTabStyle>
        <Tabs onTabClick={onChange} transition={true} activeKey={activeTab}>
          {displayTabs.map((item) => {
            return (
              <TabPane tab={item.title} key={item.key} disabled={item.title === 'Integrations'}>
                {handleTab(item)}
              </TabPane>
            );
          })}
        </Tabs>
      </NewTabStyle>
    </>
  );
};

export default Settings;

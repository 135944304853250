import styled from 'styled-components';
import calendar from '../../../../static/favourite/calendar-icon.svg';

const ResultPageWrap = styled.div`
  padding: 14px 25px 0 14px;
  @media (max-width: 768px) {
    padding: 10px 16px 24px;
  }
`;

const CraftedContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #e2e4e8;
  border-radius: 8px;
  @media (max-width: 768px) {
    align-items: flex-start;
  }

  h5.ant-typography {
    margin-bottom: 5px;
    color: #282b3f;
    font-family: 'Poppins-SemiBold';

    @media (max-width: 768px) {
      margin-bottom: 8px;
    }
  }
`;

const CraftedWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  .ant-picker {
    padding: 13px 15px;
    border: 1px solid #e2e4e8;
    border-radius: 8px;

    .ant-picker-input > input {
      color: #8f9095;
      font-family: 'Poppins-Regular';
    }

    .ant-picker-suffix {
      content: url(${calendar});
      height: 18px !important;
      margin-left: 10px;
    }

    .ant-picker-suffix:hover {
      content: '';
      display: none;
      /* height: 18px !important; */
      /* margin-left: 10px; */
    }
  }

  .ant-checkbox + span {
    padding-right: 0px;
    color: #bfbfbf;
    font-weight: 500;
    font-family: 'Poppins-Light';
  }
`;

const LabelsWrap = styled.div`
  display: flex;
  gap: 20px;
  margin-right: auto;
`;

const ContentCategoryWrap = styled.div`
  /* display: flex; */

  .ant-row {
    /* width: 100%; */
    margin-top: 18px;
    @media (max-width: 768px) {
      margin-top: 32px;
    }
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;

  .content-card {
    display: flex;
    flex-direction: column;
    padding-top: 9px;
    padding-left: 8px;
    gap: 10px;
    height: 317px;
    @media (max-width: 768px) {
      height: 2 * (auto);
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }

  .ant-card {
    margin-top: 6px;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    @media (max-width: 768px) {
      margin-top: 0px;
    }

    .ant-card-body {
      padding: 13px 15px !important;
      display: flex;
      /* gap: 24px; */
      align-items: center;

      .ant-typography {
        font-weight: 500;
        font-size: 14px;
        color: #8f9095;
        font-family: 'Poppins-Regular';
      }

      .card1 {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .ant-checkbox-checked .ant-checkbox-inner:after {
          border-color: #6c5afb;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #f5f4ff;
          border-color: #6c5afb;
        }
      }
    }
  }

  .ant-typography {
    font-weight: 600;
    font-size: 18px;
    letter-spacing: -0.02em;
    color: #282b3f;
    padding-left: 24px;
    font-family: 'Poppins-SemiBold';
  }

  .ant-checkbox + span {
    padding-right: 0px;
    color: #282b3f;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    font-family: 'Poppins-Medium';
  }
`;

export { ResultPageWrap, CraftedContentWrap, ContentCategoryWrap, ContentWrap, CraftedWrap, LabelsWrap };

import React from 'react';

const ArrowDown = ({ width = '33', height = '33', color = '#848484' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 33 33`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M26.0164 11.8333L16.6831 21.1666L7.34976 11.8333"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ArrowDown;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Input } from 'antd';
import { AuthWrapper } from './style';
import { Typography } from 'antd';
import { Button } from '../../../../components/Button';
import { firebase } from '../../../../config/firebase';
import { addNotificationError, addNotificationInfo } from '../../../../components/utilities/notifications';
import { AUTH_FORGET_PASSWORD, AUTH_FORGET_PASSWORD_TEXT } from '../../../../constants/content';

const ForgotPassword = () => {
  const [loading, setloading] = useState(false);
  const handleSubmit = ({ email }) => {
    setloading(true);

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        addNotificationInfo(AUTH_FORGET_PASSWORD);
        setloading(false);
      })
      .catch((error) => {
        addNotificationError(error.message);
        setloading(false);
      });
  };

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="forgotPass" onFinish={handleSubmit} layout="vertical">
          <div className="heading">Forget Password?</div>
          <div className="forgot-text">{AUTH_FORGET_PASSWORD_TEXT} </div>

          <Form.Item
            validateTrigger={['onBlur']}
            // label="Email Address"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
              },
            ]}
          >
            <Input placeholder="Email" className="auth-input" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" type="purple-gradient" size="large" block loading={loading}>
              Send Reset Instructions
            </Button>
          </Form.Item>
          <div>
            <Typography.Paragraph className="auth-notice">
              Return to{'  '}
              <NavLink to="/auth" style={{ marginLeft: '6px' }}>
                Sign In
              </NavLink>
            </Typography.Paragraph>
          </div>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;

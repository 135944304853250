/* ------------------------------------------------------ */
/*                      User Billing                      */
/* ------------------------------------------------------ */

import { addNotificationError } from '../../components/utilities/notifications';
import {
  CRAFTER,
  TEAM,
  NA,
  NO_PLAN_SUBSCRIPED,
  NO_TRIAL_CC,
  STARTER,
  TRIAL,
  PERSONAL_WORKSPACE,
  TEAM_WORKSPACE,
} from '../../constants';
import { allSubscribers } from '../../utility/utility';
import actions from './actions';

const {
  planTrialProgress,
  planTrialFailed,
  planTrialSuccess,
  fetchPlanTrialFailed,
  fetchPlanTrialProgress,
  fetchPlanTrialSuccess,
  planStarterProgress,
  planStarterSuccess,
  planStarterFailed,
  fetchPlanStarterProgress,
  fetchPlanStarterSuccess,
  fetchPlanStarterFailed,
  userTodayCurrentPlanActivity,
  adminBillingConfig,
  userAllowToCraft,
  userBotData,
} = actions;

/**
 * Admin Stripe collection.
 * Firestore:
 * Collection: stripe-plan.
 * Document: crafter | starter | team | trial | noCreditCard  -> Each plan has their own document and each document has its own properties lile
 *
 * In Crafter Document: as this plan has unlimited words and workspaces in V1 pricing, objects are
 * monthly: <stripe-price-id>
 * yearly: <stripe-price-id>
 * productID: <stripe-product-id>
 *
 * noCreditCard Document: this feature is a limited time offer for users who can join craftly without givinf credit card info
 * totalTrialHits: 10
 * totalWorkspaces: 1
 * trialDays: 5
 *
 *
 * In Starter Document: as this plan has limited words and workspaces in V1 pricing, objects are
 * monthly: <stripe-price-id>
 * yearly: <stripe-price-id>
 * productID: <stripe-product-id>
 * totalWords: <number>
 * totalWorkspaces: <number>
 *
 *
 * In Trial Document: when a user signup for the 1st time, craftly provide them 5 days free trial on both plans either crafter or starter.
 * but in trial period there is a limitation. as soon the trial days over the subscription starts on the user choosen plan.
 * totalTrialHits: 10
 * totalWorkspaces: 1
 * trialDays: 5
 *
 *
 */
const adminBillingSettingAction = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    console.log('"first"', '---->>>> first');
    const db = getFirestore();
    try {
      const adminBillingSettingActionSubscriber = await db.collection('stripe-plan').onSnapshot((querySnapshot) => {
        const adminBillingSettings = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          adminBillingSettings.push({ plan: doc.id, details: doc.data() });
        });
        console.log('adminBillingSettings', adminBillingSettings);
        dispatch(adminBillingConfig(adminBillingSettings));
      });
      allSubscribers.findIndex((x) => x.name === 'adminBillingSettingActionSubscriber') === -1 &&
        allSubscribers.push({
          func: adminBillingSettingActionSubscriber,
          name: 'adminBillingSettingActionSubscriber',
        });
    } catch (err) {
      console.log(`error`, err);
    }
  };
};

/**
 * this function executes when app loads
 * This functions is used to get the total number of words crafted by the user in specific plan duration.
 *
 * In this we decide whether the user is able to use tools or not.
 *
 * This fetch data according to workspace.
 * Personal Workspace Firestore
 * Colleciton: users-bot
 * Document: <UID>
 *
 * Team Workspace Firestore
 * Colleciton: teams-bot
 * Document: <teamID>
 * Collection: team-member-bot
 * Document: <uid>
 *
 */
const getUserTodaysActivity = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    const { plan } = getState().fb.profile;
    const { selectedWorkspace } = getState().accountSettings;
    const { selectedUID, workspaceType } = selectedWorkspace || {}; // selectedUID is a teamID

    // LOGIC to allow the user to craft CRAFT-LOGIC
    // this is accoridng to the user plan
    // this is user personal account

    if (plan === NA) {
      dispatch(userAllowToCraft({ craft: false, code: NO_PLAN_SUBSCRIPED }));
    }
    if (plan === TRIAL) {
      dispatch(userAllowToCraft({ craft: true }));
    }
    if (plan === NO_TRIAL_CC) {
      dispatch(userAllowToCraft({ craft: true }));
    }
    if (plan === STARTER) {
      // if user has selected the starter plan but later on we must have to check whether starter is active or not.
      dispatch(userAllowToCraft({ craft: true }));
    }
    if (plan === TEAM) {
      // if user has selected the TEAM plan but later on we must have to check whether TEAM is active or not.
      dispatch(userAllowToCraft({ craft: true }));
    }
    // if user has crafter plan then every thing is free
    if (plan === CRAFTER) {
      dispatch(userAllowToCraft({ craft: true }));
    }
    try {
      if (selectedUID && workspaceType === PERSONAL_WORKSPACE) {
        const fetchTotalWordsScriber = await db
          .collection('users-bot')
          .doc(uid)
          .onSnapshot(async (doc) => {
            if (doc.data()) {
              const { todaysHits, currentPlanCraftedWords, lastCraftDate } = doc.data();
              await dispatch(
                userTodayCurrentPlanActivity({
                  totalWordsCraft: currentPlanCraftedWords || 0,
                  todaysTotalHits: todaysHits || 0,
                  lastCraftDate,
                }),
              );
            } else {
              await dispatch(userTodayCurrentPlanActivity({ totalWordsCraft: 0, todaysTotalHits: 0 }));
            }
          });
        allSubscribers.findIndex((x) => x.name === 'fetchTotalWordsScriber') === -1 &&
          allSubscribers.push({
            func: fetchTotalWordsScriber,
            name: 'fetchTotalWordsScriber',
          });
      } else if (selectedUID && workspaceType === TEAM_WORKSPACE) {
        const fetchTeamTotalWordsScriber = await db
          .collection('teams-bot')
          .doc(selectedUID)
          .collection('team-member-bot')
          .doc(uid)
          .onSnapshot(async (doc) => {
            if (doc.data()) {
              const { todaysHits, currentTeamCraftedWords } = doc.data();
              await dispatch(
                userTodayCurrentPlanActivity({ totalWordsCraft: currentTeamCraftedWords, todaysTotalHits: todaysHits }),
              );
            } else {
              await dispatch(userTodayCurrentPlanActivity({ totalWordsCraft: 0, todaysTotalHits: 0 }));
            }
          });
        allSubscribers.findIndex((x) => x.name === 'fetchTeamTotalWordsScriber') === -1 &&
          allSubscribers.push({
            func: fetchTeamTotalWordsScriber,
            name: 'fetchTeamTotalWordsScriber',
          });
      }

      const getTotalWordsSubscriber = await db
        .collection('users-bot')
        .doc(uid)
        .collection('craft')
        .onSnapshot(async (snapshot) => {
          const userBots = [];
          snapshot.forEach((doc) => {
            userBots.push({ ...doc.data(), botId: doc.id });
          });
          await dispatch(userBotData(userBots));
        });
      allSubscribers.findIndex((x) => x.name === 'getTotalWordsSubscriber') === -1 &&
        allSubscribers.push({
          func: getTotalWordsSubscriber,
          name: 'getTotalWordsSubscriber',
        });
    } catch (err) {
      await addNotificationError(err);
    }
  };
};

// Craftly admin has the ability to control the faetures on Starter and Trail plan
// Firestore:
// Collection: stripe-plan
// Document: trial
const planTrialBilling = (planTrial) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    dispatch(planTrialProgress());
    try {
      // Add Trial details
      await db
        .collection('stripe-plan')
        .doc('trial')
        .set({ ...planTrial }, { merge: true })
        .then(() => {
          dispatch(planTrialSuccess());
        });
    } catch (err) {
      await addNotificationError(err);
      await dispatch(planTrialFailed());
    }
  };
};

// Craftly admin has the ability to control the faetures on Starter and Trail plan
// Firestore:
// Collection: stripe-plan
// Document: trial
const fetchTrialBilling = (planTrial) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    dispatch(fetchPlanTrialProgress());
    try {
      // FETCH Trial Details
      const fetchTrialBillingSubscriber = await db
        .collection('stripe-plan')
        .doc('trial')
        .onSnapshot((doc) => {
          dispatch(fetchPlanTrialSuccess(doc.data()));
        });
      allSubscribers.findIndex((x) => x.name === 'fetchTrialBillingSubscriber') === -1 &&
        allSubscribers.push({
          func: fetchTrialBillingSubscriber,
          name: 'fetchTrialBillingSubscriber',
        });
    } catch (err) {
      await addNotificationError(err);
      await dispatch(fetchPlanTrialFailed());
    }
  };
};

// Craftly admin has the ability to control the faetures on Starter and Trail plan
// Firestore:
// Collection: stripe-plan
// Document: trial
const planStarterBilling = (planStarter) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    dispatch(planStarterProgress());
    try {
      // ADD PLAN Details
      await db
        .collection('stripe-plan')
        .doc('starter')
        .set({ ...planStarter }, { merge: true })
        .then(() => {
          dispatch(planStarterSuccess());
        });
    } catch (err) {
      await addNotificationError(err);
      await dispatch(planStarterFailed());
    }
  };
};

// Craftly admin has the ability to control the faetures on Starter and Trail plan
// Firestore:
// Collection: stripe-plan
// Document: trial
const fetchStarterBilling = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    dispatch(fetchPlanStarterProgress());
    try {
      const fetchStarterBillingSubscriber = await db
        .collection('stripe-plan')
        .doc('starter')
        .onSnapshot((doc) => {
          dispatch(fetchPlanStarterSuccess(doc.data()));
        });
      allSubscribers.findIndex((x) => x.name === 'fetchStarterBillingSubscriber') === -1 &&
        allSubscribers.push({
          func: fetchStarterBillingSubscriber,
          name: 'fetchStarterBillingSubscriber',
        });
    } catch (err) {
      await addNotificationError(err);
      await dispatch(fetchPlanStarterFailed());
    }
  };
};

export {
  getUserTodaysActivity,
  planTrialBilling,
  fetchTrialBilling,
  fetchStarterBilling,
  planStarterBilling,
  adminBillingSettingAction,
};

/* ------------------------------------------------------ */
/*                    Ant-Design Button                   */
/* ------------------------------------------------------ */

// We have created our own customized button styles

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonStyledGroup, ButtonStyled } from './styled';
import {
  BUTTON_BLUE_GRADIENT,
  BUTTON_DANGER,
  BUTTON_DARK,
  BUTTON_DASHED,
  BUTTON_DEFAULT,
  BUTTON_ERROR,
  BUTTON_GRADIENT,
  BUTTON_INFO,
  BUTTON_LIGHT,
  BUTTON_LIGHT_PURPLE,
  BUTTON_LINK,
  BUTTON_PRIMARY,
  BUTTON_PURPLE_GRADIENT,
  BUTTON_SECONDARY,
  BUTTON_SUCCESS,
  BUTTON_WARNING,
  BUTTON_WHITE,
  BUTTON_YELLOW_GRADIENT,
} from '../../constants';

const Button = (props) => {
  const {
    disabled,
    type,
    shape,
    icon,
    size,
    outlined,
    ghost,
    transparented,
    raised,
    squared,
    color,
    social,
    load,
    children,
    editButton,
    transparentwithoutline,
    ...rest
  } = props;
  const [state, setState] = useState({
    loading: false,
  });

  const enterLoading = () => {
    setState({ loading: true });
  };

  return (
    // its a styled-component.
    <ButtonStyled
      transparentwithoutline={transparentwithoutline}
      editButton={editButton}
      disabled={disabled}
      squared={squared}
      outlined={outlined ? 1 : 0}
      ghost={ghost}
      transparent={transparented ? 1 : 0}
      raised={raised ? 1 : 0}
      data={type}
      size={size}
      shape={shape}
      type={type}
      icon={icon}
      color={color}
      social={social}
      onClick={load && enterLoading}
      loading={state.loading}
      {...rest}
    >
      {children}
    </ButtonStyled>
  );
};

Button.defaultProps = {
  type: 'default',
};

Button.propTypes = {
  type: PropTypes.oneOf([
    BUTTON_PRIMARY,
    BUTTON_SECONDARY,
    BUTTON_SUCCESS,
    BUTTON_INFO,
    BUTTON_WARNING,
    BUTTON_DANGER,
    BUTTON_LINK,
    BUTTON_DARK,
    BUTTON_LIGHT,
    BUTTON_WHITE,
    BUTTON_DASHED,
    BUTTON_ERROR,
    BUTTON_DEFAULT,
    BUTTON_LIGHT_PURPLE,
    BUTTON_GRADIENT,
    BUTTON_PURPLE_GRADIENT,
    BUTTON_YELLOW_GRADIENT,
    BUTTON_BLUE_GRADIENT,
  ]),
  shape: PropTypes.string,
  icon: PropTypes.object,
  size: PropTypes.string,
  color: PropTypes.string,
  outlined: PropTypes.bool,
  transparented: PropTypes.bool,
  transparentwithoutline: PropTypes.bool,
  raised: PropTypes.bool,
  squared: PropTypes.bool,
  social: PropTypes.bool,
  load: PropTypes.bool,
  ghost: PropTypes.bool,
  editButton: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

const BtnGroup = ({ children }) => {
  return <ButtonStyledGroup>{children}</ButtonStyledGroup>;
};

BtnGroup.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export { Button, BtnGroup };

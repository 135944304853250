import styled from 'styled-components';

const FavoriteContainer = styled.div`
  padding: 24px;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export { FavoriteContainer };

import React from 'react';
import { Col, Row } from 'antd';
import { AuthStyleWrapper } from './styled.js';
import { useSelector } from 'react-redux';

export const Home = () => {
  const { animation } = useSelector(state => state.auth);
  return (
    <AuthStyleWrapper animation={animation}>
      <div className="right-side-wrapper">
        <div className="right-side-section">
          <div className="ani-img0"></div>
          <div className="ani-img1"></div>
          <div className="ani-img2"></div>
          <div className="ani-img4"></div>
          <div className="ani-img5"></div>
          <div className="ani-img6"></div>
          <Row>
            <Col>
              <h1 className="autho-txt">Changing the way the world writes</h1>
            </Col>
          </Row>
        </div>
      </div>
    </AuthStyleWrapper>
  );
};

const actions = {
  CHANGE_EMAIL_PROGRESS: 'CHANGE_EMAIL_PROGRESS',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_FAILED: 'CHANGE_EMAIL_FAILED',

  CHANGE_USERNAME_PROGRESS: 'CHANGE_USERNAME_PROGRESS',
  CHANGE_USERNAME_SUCCESS: 'CHANGE_USERNAME_SUCCESS',
  CHANGE_USERNAME_FAILED: 'CHANGE_USERNAME_FAILED',

  CLOSE_ACCOUNT_PROGRESS: 'CLOSE_ACCOUNT_PROGRESS',
  CLOSE_ACCOUNT_SUCCESS: 'CLOSE_ACCOUNT_SUCCESS',
  CLOSE_ACCOUNT_FAILED: 'CLOSE_ACCOUNT_FAILED',

  ONBOARDING_PROGRESS: 'ONBOARDING_PROGRESS',
  ONBOARDING_SUCCESS: 'ONBOARDING_SUCCESS',

  CHANGE_PROFILE_START: 'CHANGE_PROFILE_START',
  CHANGE_PROFILE_SUCCESS: 'CHANGE_PROFILE_SUCCESS',
  CHANGE_PROFILE_FAILED: 'CHANGE_PROFILE_FAILED',

  SET_ADD_TEAM_DISPLAY: 'SET_ADD_TEAM_DISPLAY',
  ADD_TEAM_MEMBER_START: 'ADD_TEAM_MEMBER_START',
  ADD_TEAM_MEMBER_SUCCESS: 'ADD_TEAM_MEMBER_SUCCESS',
  ADD_TEAM_MEMBER_FAILED: 'ADD_TEAM_MEMBER_FAILED',

  TEAM_MEMBER_EXIST: 'TEAM_MEMBER_EXIST',

  FETCH_TEAM_MEMBER_START: 'FETCH_TEAM_MEMBER_START',
  FETCH_TEAM_MEMBER_SUCCESS: 'FETCH_TEAM_MEMBER_SUCCESS',
  FETCH_TEAM_MEMBER_FAILED: 'FETCH_TEAM_MEMBER_FAILED',

  USERS_TEAMS: 'USERS_TEAMS',
  USERS_TEAMS_LOADING: 'USERS_TEAMS_LOADING',

  SELECTED_WORKSPACE: 'SELECTED_WORKSPACE',

  MEMBERS_CRAFT_LIST: 'MEMBERS_CRAFT_LIST',

  MEMBER_BOTS_START: 'MEMBER_BOTS_START',
  MEMBER_BOTS_SUCCESS: 'MEMBER_BOTS_SUCCESS',
  MEMBER_BOTS_FAILED: 'MEMBER_BOTS_FAILED',

  WORKSPACE_BOTS_START: 'WORKSPACE_BOTS_START',
  WORKSPACE_BOTS_SUCCESS: 'WORKSPACE_BOTS_SUCCESS',
  WORKSPACE_BOTS_FAILED: 'WORKSPACE_BOTS_FAILED',

  CURRENT_MONTH_CRAFT_TEAM: 'CURRENT_MONTH_CRAFT_TEAM',

  TEAM_PLAN_UPDATE_LOADING: 'TEAM_PLAN_UPDATE_LOADING',

  TEAM_INVITE_ACTION_START: 'TEAM_INVITE_ACTION_START',
  TEAM_INVITE_ACTION_SUCCESS: 'TEAM_INVITE_ACTION_SUCCESS',
  TEAM_INVITE_ACTION_FAILED: 'TEAM_INVITE_ACTION_FAILED',

  ALL_MEMBERS_HOLD: 'ALL_MEMBERS_HOLD',

  UPDATE_TEAM_NAME_START: 'UPDATE_TEAM_NAME_START',
  UPDATE_TEAM_NAME_SUCCESS: 'UPDATE_TEAM_NAME_SUCCESS',

  MEMBERS_COPIED_CONTENT: 'MEMBERS_COPIED_CONTENT',

  changeEmailProgress: () => {
    return {
      type: actions.CHANGE_EMAIL_PROGRESS,
    };
  },

  changeEmailSuccess: () => {
    return {
      type: actions.CHANGE_EMAIL_SUCCESS,
    };
  },
  changeEmailFailed: () => {
    return {
      type: actions.CHANGE_EMAIL_FAILED,
    };
  },

  changeUsernameProgress: () => {
    return {
      type: actions.CHANGE_USERNAME_PROGRESS,
    };
  },

  changeUsernameSuccess: () => {
    return {
      type: actions.CHANGE_USERNAME_SUCCESS,
    };
  },
  changeUsernameFailed: () => {
    return {
      type: actions.CHANGE_USERNAME_FAILED,
    };
  },

  closeAccountProgress: () => {
    return {
      type: actions.CLOSE_ACCOUNT_PROGRESS,
    };
  },

  closeAccountSuccess: () => {
    return {
      type: actions.CLOSE_ACCOUNT_SUCCESS,
    };
  },

  closeAccountError: () => {
    return {
      type: actions.CLOSE_ACCOUNT_FAILED,
    };
  },

  onBoardingProgress: () => {
    return {
      type: actions.ONBOARDING_PROGRESS,
    };
  },

  onBoardingSuccess: () => {
    return {
      type: actions.ONBOARDING_SUCCESS,
    };
  },

  changeProfileProgress: () => {
    return {
      type: actions.CHANGE_PROFILE_START,
    };
  },

  changeProfileSuccess: () => {
    return {
      type: actions.CHANGE_PROFILE_SUCCESS,
    };
  },
  changeProfileFailed: () => {
    return {
      type: actions.CHANGE_PROFILE_FAILED,
    };
  },

  setAddTeamUIDisplay: () => {
    return {
      type: actions.SET_ADD_TEAM_DISPLAY,
    };
  },

  teamPlanUpdateLoading: ({ teamPlanUpdateLoading }) => {
    return {
      type: actions.TEAM_PLAN_UPDATE_LOADING,
      teamPlanUpdateLoading,
    };
  },

  addTeamMemberStart: () => {
    return {
      type: actions.ADD_TEAM_MEMBER_START,
    };
  },

  addTeamMemberSuccess: () => {
    return {
      type: actions.ADD_TEAM_MEMBER_SUCCESS,
    };
  },

  addTeamMemberFailed: () => {
    return {
      type: actions.ADD_TEAM_MEMBER_FAILED,
    };
  },
  checkTeamMemberAlreadyExists: ({ checkTeamMemberDetails, checkTeamMemberExists }) => {
    return {
      type: actions.TEAM_MEMBER_EXIST,
      checkTeamMemberExists,
      checkTeamMemberDetails,
    };
  },
  fetchTeamMembersStart: () => {
    return {
      type: actions.FETCH_TEAM_MEMBER_START,
    };
  },
  fetchTeamMembersSuccess: ({
    teamStatus,
    teamAdminName,
    teamProjectsList,
    teamMembersList,
    teamName,
    teamAdminUID,
  }) => {
    return {
      type: actions.FETCH_TEAM_MEMBER_SUCCESS,
      teamMembersList,
      teamName,
      teamAdminUID,
      teamAdminName,
      teamProjectsList,
      teamStatus,
    };
  },
  fetchTeamMembersFailed: () => {
    return {
      type: actions.FETCH_TEAM_MEMBER_FAILED,
    };
  },

  usersTeams: ({ usersTeams, memberTeamsWithOutHold }) => {
    return {
      type: actions.USERS_TEAMS,
      usersTeams,
      memberTeamsWithOutHold,
    };
  },
  usersTeamsLoading: () => {
    return {
      type: actions.USERS_TEAMS_LOADING,
    };
  },
  currentSelectedWorkspace: ({
    selectedUID,
    selectedName,
    checkHoldAccount,
    workspaceType,
    canTeamMemberCraft,
    checkUserIsStillInTeam,
    teamProjects,
    teamPlanActive,
    teamWorkspaceTrigger,
  }) => {
    return {
      type: actions.SELECTED_WORKSPACE,
      selectedWorkspace: {
        selectedUID,
        selectedName,
        workspaceType,
        checkHoldAccount,
        canTeamMemberCraft,
        checkUserIsStillInTeam,
        teamProjects,
        teamPlanActive,
        teamWorkspaceTrigger,
      },
    };
  },

  membersCraftList: ({ wordCraftMemberList }) => {
    return {
      type: actions.MEMBERS_CRAFT_LIST,
      wordCraftMemberList,
    };
  },

  memberBotsStart: () => {
    return {
      type: actions.MEMBER_BOTS_START,
    };
  },

  memberBotsSuccess: ({ memberBots }) => {
    return {
      type: actions.MEMBER_BOTS_SUCCESS,
      memberBots,
    };
  },
  memberBotsFailed: () => {
    return {
      type: actions.MEMBER_BOTS_FAILED,
    };
  },

  workspaceBotsStart: () => {
    return {
      type: actions.WORKSPACE_BOTS_START,
    };
  },

  workspaceBotsSuccess: ({ workspaceBots }) => {
    return {
      type: actions.WORKSPACE_BOTS_SUCCESS,
      workspaceBots,
    };
  },
  workspaceBotsFailed: () => {
    return {
      type: actions.WORKSPACE_BOTS_FAILED,
    };
  },

  currentMonthCraftTeam: ({ topToolsUsedByTeamMembers }) => {
    return {
      type: actions.CURRENT_MONTH_CRAFT_TEAM,
      topToolsUsedByTeamMembers,
    };
  },

  teamInviteActionStart: () => {
    return {
      type: actions.TEAM_INVITE_ACTION_START,
    };
  },

  teamInviteActionSuccess: () => {
    return {
      type: actions.TEAM_INVITE_ACTION_SUCCESS,
    };
  },
  teamInviteActionFailed: () => {
    return {
      type: actions.TEAM_INVITE_ACTION_FAILED,
    };
  },

  updateTeamNameStart: () => {
    return {
      type: actions.UPDATE_TEAM_NAME_START,
    };
  },
  updateTeamNameSuccess: () => {
    return {
      type: actions.UPDATE_TEAM_NAME_SUCCESS,
    };
  },

  memberCopiedSuccess: ({ memberCopiedContent }) => {
    return {
      type: actions.MEMBERS_COPIED_CONTENT,
      memberCopiedContent,
    };
  },
};

export default actions;

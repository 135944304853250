import styled from 'styled-components';

const ModalLearnPopup = styled.div`
  .data {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .getting-started {
      font-family: 'Poppins-SemiBold';
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 0px !important;
    }
    .starting-statement {
      font-family: 'Poppins-Medium';
      font-size: 14px;
      line-height: 21px;
    }
  }

  .ant-popover-inner {
    padding: 0px !important;
  }
`;

const LearnPopupModalStyle = styled.div`
  border-bottom: 1.5px solid #eeeeee;
  padding: 24px;
  background: #ffffff;

  .ant-card-bordered {
    border: none;
  }

  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px !important;
    border-radius: 16px;
    cursor: pointer;
  }

  img {
    margin-right: 16px;
  }
`;

const CardDataStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  justify-content: space-between;

  h5.ant-typography {
    font-family: 'Poppins-SemiBold';
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #282b3f;
    margin-bottom: 0px;
  }

  .ant-typography + span {
    font-family: 'Poppins-Regular';
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #7f8186;
  }

  img {
    margin-right: 0px;
    margin-left: auto;
  }
`;

const LearnPopupModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  background: #f8f9ff;

  h5.ant-typography {
    font-family: 'Poppins-SemiBold';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 0px;
  }

  .ant-typography + span {
    font-family: 'Poppins-Regular';
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #737277;
  }
`;

export { LearnPopupModalStyle, CardDataStyle, ModalLearnPopup, LearnPopupModalFooter };

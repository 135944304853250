import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Input, Button, Alert } from 'antd';
import { AuthWrapper } from './style';
import Heading from '../../../../components/Headings';
import { Typography } from 'antd';
import { addNotificationError, addNotificationInfo } from '../../../../components/utilities/notifications';
import { useNavigate } from 'react-router-dom';
import { AUTH_PASSWORD_CHANGE_SUCCESS } from '../../../../constants/content';
import { firebase } from '../../../../config/firebase';

const ResetPassword = ({ actionCode, showResetError }) => {
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = ({ password }) => {
    setloading(true);
    // TODO: Show the reset screen with the user's email and ask the user for
    // the new password.

    // Save the new password.
    firebase
      .auth()
      .confirmPasswordReset(actionCode, password)
      .then((resp) => {
        // console.log(`resp`, resp);
        addNotificationInfo(AUTH_PASSWORD_CHANGE_SUCCESS);
        navigate('/auth');
        // Password reset has been confirmed and new password updated.
        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      })
      .catch((error) => {
        addNotificationError(error.message);
        console.log(`error`, error);
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      });
  };

  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="forgotPass" onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">
            <span className="heading">Create a new password</span>
            {!showResetError && (
              <div className="forgot-text">Your new password must be different than previously used passwords.</div>
            )}
          </Heading>
          {showResetError ? (
            <>
              <Alert message="Some thing went wrong" description={showResetError} type="error" />
              <br />
              <div>
                <Typography.Paragraph>
                  Return to{' '}
                  <NavLink to="/forgotPassword" style={{ marginLeft: '2px' }}>
                    Forget Password
                  </NavLink>
                </Typography.Paragraph>
              </div>
            </>
          ) : (
            <>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  { min: 7, message: 'Minimum length of 7 characters' },
                ]}
              >
                <Input.Password placeholder="Enter new password" />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Enter confirm password" />
              </Form.Item>
              <Form.Item>
                <Button className="btn-create" htmlType="submit" type="primary" size="large" block loading={loading}>
                  Reset Password
                </Button>
              </Form.Item>
              <div>
                <Typography.Paragraph className="auth-notice">
                  Return to{' '}
                  <NavLink to="/auth" style={{ marginLeft: '2px' }}>
                    Sign In
                  </NavLink>
                </Typography.Paragraph>
              </div>
            </>
          )}
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;

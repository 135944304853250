import styled from 'styled-components';

const HelpStyled = styled.div`
  .setting-dropdwon {
    max-width: 700px;
    padding: 4px 0;
    .setting-dropdwon__single {
      align-items: flex-start;
      padding: 16px 20px;
      margin-bottom: 0;
      position: relative;
      &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 5px 20px ${({ theme }) => theme['gray-solid']}15;
        z-index: 1;
        content: '';
        opacity: 0;
        visibility: hidden;
      }
      &:hover {
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
      h1 {
        font-size: 15px;
        font-weight: 500;
        margin: -4px 0 2px;
      }
      p {
        margin-bottom: 0;
        color: ${({ theme }) => theme['gray-solid']};
      }
      img {
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 16px;
        transform: ${({ theme }) => (theme.rtl ? 'rotatey(180deg)' : 'rotatey(0deg)')};
      }
      figcaption {
        text-align: ${({ theme }) => (!theme.rtl ? 'left' : 'right')};
      }
    }
  }
`;
export { HelpStyled };

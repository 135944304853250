import {
  ORIGINAL_PRORATION_ADMIN,
  ORIGINAL_PRORATION_MEMBER,
  PRORATION_ADMIN,
  PRORATION_MEMBERS,
} from '../constants/content';

export const blockWordsList = [
  { original: 'fuck', mask: 'F*ck', block: true },
  { original: 'fucking', mask: 'F*cking', block: true },
  { original: 'Fucking', mask: 'F*cking', block: true },
  { original: 'Fuck', mask: 'F*ck', block: true },
  { original: 'bitch', mask: 'B*tch', block: true },
  { original: 'Bitch', mask: 'B*tch', block: true },
  { original: 'shit', mask: 'sh*t', block: true },
  { original: 'Shit', mask: 'Sh*t', block: true },
  { original: 'pussy', mask: 'pu$$y', block: true },
  { original: 'Pussy', mask: 'Pu$$y', block: true },
  { original: 'blowjob', mask: 'bl*wj*b', block: true },
  { original: 'Blowjob', mask: 'Bl*wj*b', block: true },
  { original: 'cunt', mask: 'c*nt', block: true },
  { original: 'Cunt', mask: 'C*nt', block: true },
  { original: 'faggot', mask: 'f*ggot', block: true },
  { original: 'Faggot', mask: 'F*ggot', block: true },
  { original: 'nigger', mask: 'n*gger', block: true },
  { original: 'Nigga', mask: 'N*gga', block: true },
  { original: 'porn', mask: 'p*rn', block: true },
  { original: 'Porn', mask: 'P*rn', block: true },
  { original: 'queef', mask: 'que*f', block: true },
  { original: 'Queef', mask: 'Que*f', block: true },
  { original: 'retard', mask: 'P*rn', block: true },
  { original: 'Retard', mask: 'r*tard', block: true },
  { original: 'rimjob', mask: 'r*mjob', block: true },
  { original: 'Rimjob', mask: 'R*mjob', block: true },
  { original: 'tranny', mask: 'tr*nny', block: true },
  { original: 'Tranny', mask: 'Tr*nny', block: true },
  { original: 'wigger', mask: 'w*gger', block: true },
  { original: 'Wigger', mask: 'W*gger', block: true },
  { original: 'xxx', mask: 'xxx', block: true },
  { original: 'XXX', mask: 'XXX', block: true },
  { original: 'rape', mask: 'r*pe', block: false },
  { original: 'rapes', mask: 'r*pes', block: false },
  { original: 'Rape', mask: 'r*pe', block: false },
  { original: 'RAPE', mask: 'r*pe', block: false },
  { original: 'masturbate', mask: 'masturb*te', block: false },
  { original: 'Masturbate', mask: 'masturb*te', block: false },
  { original: 'MASTURBATE', mask: 'masturb*te', block: false },
  { original: 'cock', mask: 'c*ck', block: false },
  { original: 'cocks', mask: 'c*cks', block: false },
  { original: 'Cock', mask: 'C*ck', block: false },
  { original: 'Cocks', mask: 'C*cks', block: false },
  { original: 'COCK', mask: 'C*CK', block: false },
  { original: 'anal', mask: 'an*l', block: false },
  { original: 'Anal', mask: 'An*l', block: false },
  { original: 'ANAL', mask: 'AN*L', block: false },
  { original: 'Horny', mask: 'hor*y', block: false },
];

export const checkBadWords = toolBody => {
  const inputBadWords = [];
  toolBody &&
    Object.keys(toolBody).forEach(key => {
      const sentence = isNaN(toolBody[key]) ? toolBody[key].toLowerCase() : toolBody[key];
      blockWordsList.forEach(word => {
        if (word.block && new RegExp('\\b' + word.original + '\\b').test(sentence)) {
          !inputBadWords.includes(word.original) && inputBadWords.push(word.original);
        }
      });
    });
  return inputBadWords;
};

/* Define function for escaping user input to be treated as 
    a literal string within a regular expression */
function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/* Define functin to find and replace specified term with replacement string */

export const maskBadWords = responseSentence => {
  let maskedSentence = responseSentence;
  const lowerCaseSentence = responseSentence;

  blockWordsList.forEach(word => {
    if (new RegExp('\\b' + word.original + '\\b').test(lowerCaseSentence)) {
      // /\bfoo\b/gi;
      var stringToGoIntoTheRegex = escapeRegExp(word.original);
      var regex = new RegExp('\\b' + stringToGoIntoTheRegex + '\\b', 'gi');
      maskedSentence = maskedSentence.replace(regex, word.mask);
    }
  });

  return maskedSentence;
};

export const changeProrationText = prorationText => {
  if (prorationText.includes(ORIGINAL_PRORATION_ADMIN)) {
    return PRORATION_ADMIN;
  } else if (prorationText.includes(ORIGINAL_PRORATION_MEMBER)) {
    const seats = prorationText.substr(0, prorationText.indexOf(' '));
    return PRORATION_MEMBERS(seats);
  } else {
    return prorationText;
  }
};

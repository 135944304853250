import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { DEVELOPMENT, DEVELOPMENT_LOCAL, PRODUCTION, PRODUCTION_LOCAL, STATUS } from '../constants';
import { userMappingStatus } from './userProperties';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const uniqueId = () => {
  let ID = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghtpoqjdchpd';
  for (var i = 0; i < 8; i++) {
    ID += characters.charAt(Math.floor(Math.random() * 36));
  }
  return ID;
};

const myRandomInts = (min = 0, max = 12) => {
  const values = [10, 44, 104, 90, 80, 160, 87, 68, 33, 130, 77, 22, 140];
  const rand = Math.floor(Math.random() * (max - min + 1) + min);
  return values[rand];
};

const uniqueIdByChar = (charlength) => {
  let ID = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghtpoqjdchpd';
  for (var i = 0; i < charlength; i++) {
    ID += characters.charAt(Math.floor(Math.random() * 36));
  }
  return ID;
};

const dateFormat = 'YYYY-MM-DD';
const dateFormatWithSlash = 'YYYY/MM/DD';
const dateFormatWithTime = `YYYY-MM-DD HH:mm`;
const TimeFormat = `HH:mm`;

// it is used to filter the crafts bot data, according to different parameters.
const getCleanData = ({ objectArray, compareObject, getData, isUnique, botId, customResponse }) => {
  const { filterDate, filterUserUID, filterToolName, filterBotId } = compareObject;

  const filterData = objectArray.filter(({ date, uid, toolName, botId }) => {
    if (filterDate && filterUserUID && filterToolName && filterBotId) {
      return date === filterDate && uid === filterUserUID && toolName === filterToolName && botId === filterBotId;
    }
    if (filterDate && filterUserUID && filterToolName) {
      return date === filterDate && uid === filterUserUID && toolName === filterToolName;
    }
    if (filterDate && filterUserUID) {
      return date === filterDate && uid === filterUserUID;
    }
    if (filterDate) {
      return date === filterDate;
    }
    return filterDate;
  });

  return filterData
    ? isUnique
      ? [...new Set(filterData.map((item) => item[getData]))]
      : filterData.map((item) => {
          if (customResponse) {
            return { botId: item.botId, [getData]: item[getData], recentProjectId: item.recentProjectId };
          } else if (botId) {
            return { botId: item.botId, [getData]: item[getData] };
          } else {
            return item[getData];
          }
        })
    : [];
};

const capitalizeFirstLetter = (string) => {
  return (string || ' ').charAt(0).toUpperCase() + (string || '').slice(1);
};

const numberFormat = (number) => {
  return (number && number.toLocaleString('en-US', { maximumFractionDigits: 2 })) || number;
};

const firstTwoLetters = (word) => {
  const checkWord = word && word.split(' ');
  if (checkWord && checkWord.length > 1) {
    return word.split(' ')[0].charAt(0).toUpperCase() + word.split(' ')[1].charAt(0).toUpperCase();
  } else if (checkWord && checkWord.length === 1 && word.length > 2) {
    return word.substring(0, 2).toUpperCase();
  } else {
    return word;
  }
};

const removeUnderScore = (string) => {
  return (string || '').replace('_', ' ');
};

// When user perform action on user profile.
const isApprovedLogic = (userData) => {
  // the isApproved Flag is decide on the status. here is multiple conditions.
  let finalData = { ...userData };
  if (userData.status === STATUS.PAUSE) {
    finalData = {
      ...finalData,
      isApproved: false,
      deactivateByAdmin: false,
      displayPlanStatus: userMappingStatus({ status: userData.status, planStatus: userData.planStatus }),
    };
  } else if (userData.status === STATUS.DEACTIVE) {
    finalData = {
      ...finalData,
      isApproved: false,
      deactivateByAdmin: true,
      displayPlanStatus: userMappingStatus({ status: userData.status, planStatus: userData.planStatus }),
    };
  } else if (userData.status === STATUS.ACTIVE) {
    finalData = {
      ...finalData,
      isApproved: true,
      deactivateByAdmin: false,
      displayPlanStatus: userMappingStatus({ status: userData.status, planStatus: userData.planStatus }),
    };
  }

  return finalData;
};

const useScroll = (position) => {
  const elRef = React.useRef(null);
  const tempRef = React.useRef(null);
  const executeScroll = () => elRef.current && elRef.current.scrollIntoView({ behavior: 'smooth', block: position });
  return [executeScroll, elRef, tempRef];
};

const randomImage = 'https://picsum.photos/100/100';

const arrayRemove = (arr, value) => {
  return arr.filter(function (ele) {
    return ele !== value;
  });
};
const currencyFormatting = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const numberFormating = (number) => {
  const nf = new Intl.NumberFormat();
  return nf.format(number);
};

const convertTimeStampToDate = (timeStamp) => {
  return timeStamp && moment(new Date(timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1000000)).format('yyyy-MM-DD');
};
const convertTimeStampToDateTime = (timeStamp) => {
  return (
    timeStamp &&
    moment(new Date(timeStamp.seconds * 1000 + timeStamp.nanoseconds / 1000000)).format('yyyy-MM-DD HH:mm:ss')
  );
};

const convertFirebaseToDate = (date) => {
  return moment(date && date.toDate()).format(dateFormat) || '';
};
const convertFirebaseToTime = (date) => {
  return moment(date && date.toDate()).format(TimeFormat) || '';
};

const constructUserToolInput = ({ fields, toolBody }) => {
  const inputFields = fields
    .map(({ name, key }) => {
      const toolInput = toolBody[key] || '';
      if (toolInput || '') {
        return {
          name,
          value: toolInput,
        };
      }
    })
    .filter(Boolean);

  const input = fields.reduce((_input, _new, index) => {
    const toolInput = toolBody[_new.key] || '';
    return `${_input} ${index > 0 && toolInput ? '->' : ''} ${toolInput}`;
  }, '');

  return { inputFields, input };
};
const initialDate = moment().startOf('year');
const months = Array.from({ length: 12 }, (e, i) => {
  return moment(initialDate).add(i, 'months').format('MMM');
});

const random = (mn, mx) => {
  return Math.random() * (mx - mn) + mn;
};

const convertToDollar = (amount) => {
  return (
    (amount / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }) || ''
  );
};

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const checkSegmentConfig = () => {
  // const allowSegment = [DEVELOPMENT_LOCAL, DEVELOPMENT, PRODUCTION_LOCAL, PRODUCTION];

  const browserURL = window.location.origin;
  const allowSegment = [PRODUCTION_LOCAL, PRODUCTION];
  return allowSegment.includes(browserURL) ? true : false;
};

const htmlToText = (html) => {
  if (html) {
    html = html.replace(/\n/g, '\n');
    html = html.replace(/\n\n/g, '\n');
    html = html.replace(/\t/g, '');
    html = html.replace(/<\/p>/g, '\n\n');
    html = html.replace(/<br>/g, '\n\n');
    html = html.replace(/<br( )*\/>/g, '\n\n');
    //parse html into text
    var dom = new DOMParser().parseFromString('<!doctype html><body>' + html, 'text/html');
    return dom.body.textContent;
  }
};

// in some profile there is no firebase timeFormat date. there is only string.
const getUTCDate = (date) => {
  // check DATE is in firebase DateTime Type
  if (typeof date === 'object' && date !== null) {
    const readableDate = moment.unix(date.seconds).format(dateFormat);
    const typeDateFormat = moment.unix(date.seconds);
    return { readableDate, typeDateFormat };

    // check DATE is in string Type
  } else if (typeof date === 'string' && date) {
    const unixFormat = moment(date, dateFormat).unix();
    const readableDate = moment.unix(unixFormat).format(dateFormat);
    const typeDateFormat = moment.unix(unixFormat);
    return { readableDate, typeDateFormat };
  } else {
    return { readableDate: '', typeDateFormat: '' };
  }
};

const allSubscribers = [];

const minTwoDigits = (n) => {
  return (n < 10 && n > 0 ? '0' : '') + n;
};
const breakLineIfRequired = (paragraph) => {
  const changeNewLine = paragraph && paragraph.replaceAll('\\n\\n', '\n\n');
  const singleChangeNewLine = changeNewLine && changeNewLine.replaceAll('\\n', '\n');

  const changeNewLineBr = paragraph && paragraph.replaceAll('\n\n', '<br> <br>');
  const lineBr = changeNewLineBr && changeNewLineBr.replaceAll('\\n', '<br />');

  const lines =
    singleChangeNewLine &&
    singleChangeNewLine.split('\n').map(function (line, n) {
      return n === 0 ? [line] : [<br />, line];
    });
  return { lines, singleChangeNewLine, changeNewLineBr, lineBr };
};

const breakLineForCopy = (paragraph) => {
  const changeNewLine = paragraph && paragraph.replaceAll('\\n\\n', '\n \n');
  return changeNewLine;
};

const getCategoryTools = ({ displayCategories, selectedCategory, allToolsList }) => {
  const categoryTools =
    displayCategories &&
    Array.isArray(displayCategories) &&
    displayCategories.length > 0 &&
    displayCategories.find(({ categoryId }) => categoryId === selectedCategory);

  const showCategoryTools = categoryTools && categoryTools.optionFields ? categoryTools.optionFields[0].toolIds : [];

  const displayCategoryTools =
    showCategoryTools &&
    Array.isArray(showCategoryTools) &&
    showCategoryTools.map((toolId) => allToolsList.find(({ id }) => id === toolId)).filter(Boolean);

  return displayCategoryTools;
};

const getToolIcon = (toolId) => {
  try {
    return require(`../static/tools/svg/${toolId}.svg`);
  } catch {
    return require(`../static/tools/svg/keyword.png`);
    // return `https://picsum.photos/200`;
  }
};

const getToolName = ({ toolId, allTools }) => {
  return allTools && allTools.find(({ id }) => id === toolId)?.title;
};
const getToolIconId = ({ toolId, allTools }) => {
  const toolDetails = allTools && allTools.find(({ id }) => id === toolId);
  return (toolDetails && toolDetails.commonIcon) || toolId;
};

const handleClickScroll = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    // 👇 Will scroll smoothly to the top of the billing section
    // element.scrollIntoView({ behavior: 'smooth' });
    const yOffset = -10;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

export const apiErrorMessage = (error) => {
  const errorData = (error && error.response && error.response.data) || error;
  return errorData;
};

function withParams(Component) {
  return (props) => <Component {...props} location={useLocation()} />;
}
function delay(delay: number) {
  return new Promise((res) => setTimeout(res, delay));
}

export {
  ellipsis,
  uniqueId,
  dateFormat,
  arrayRemove,
  getCleanData,
  capitalizeFirstLetter,
  isApprovedLogic,
  useScroll,
  randomImage,
  numberFormating,
  convertTimeStampToDate,
  constructUserToolInput,
  convertFirebaseToDate,
  months,
  dateFormatWithTime,
  removeUnderScore,
  random,
  getKeyByValue,
  convertFirebaseToTime,
  dateFormatWithSlash,
  checkSegmentConfig,
  htmlToText,
  convertTimeStampToDateTime,
  convertToDollar,
  getUTCDate,
  firstTwoLetters,
  allSubscribers,
  uniqueIdByChar,
  minTwoDigits,
  myRandomInts,
  breakLineIfRequired,
  getCategoryTools,
  numberFormat,
  currencyFormatting,
  getToolIcon,
  getToolName,
  breakLineForCopy,
  handleClickScroll,
  getToolIconId,
  withParams,
  delay,
};

/* ------------------------------------------------------ */
/*                          TEAM                          */
/* ------------------------------------------------------ */

import { addNotificationError, addNotificationSuccess } from '../../components/utilities/notifications';
import { TEAM_SUCCESSFULLY_DELETED } from '../../constants/content';
import actions from './actions';
import userProjectAction from '../UserProject/actions';
import { allSubscribers, getToolName } from '../../utility/utility';
import { TEAM_WORKSPACE } from '../../constants';

const { deleteTeamStart, deleteTeamError, deleteTeamSuccess, resetTeam, showMirrorTeamUID, saveTeamList } = actions;
const {
  userProjectFavLoading,
  userProjectFavoriteSuccess,
  favoritesPageLoading,
  recentPageLoading,
  recentPageSuccess,
  favoritesPageSuccess,
} = userProjectAction;

const deleteTeam = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;

    try {
      dispatch(deleteTeamStart());
      db.collection('team-members')
        .doc(uid)
        .delete()
        .then(() => {
          addNotificationSuccess(TEAM_SUCCESSFULLY_DELETED);
          dispatch(deleteTeamSuccess());
        })
        .catch((error) => {
          addNotificationError(error.message);
          console.error('Error removing document: ', error);
        });
    } catch (err) {
      dispatch(deleteTeamError(err));
    }
  };
};
const resetTeamDeletion = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(resetTeam());
  };
};

const setMirrorTeamUID = (teamID) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(showMirrorTeamUID(teamID));
  };
};

// Fetch all the teams data
// In onboarding process it helps to find the user whether the user is present in any team or not.
const fetchAllTeams = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    // onSnapshot services is used in
    const teamMemberSubscriber = db.collection('team-members').onSnapshot((snapshot) => {
      const allTeams = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        allTeams.push({ ...data });
      });
      dispatch(saveTeamList(allTeams));
    });
    allSubscribers.findIndex((x) => x.name === 'teamMemberSubscriber') === -1 &&
      allSubscribers.push({ func: teamMemberSubscriber, name: 'teamMemberSubscriber' });
  };
};

// Team members All Bots
const teamMemberBots = ({ workspaceAdminUID, teamMemberUID }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      // await dispatch(memberBotsStart());
      const query = await db
        .collection('team-members') // members-bots to get top 3 tools
        .doc(workspaceAdminUID)
        .collection('craft')
        .where('uid', '==', teamMemberUID);

      const botCollectionRef = query.onSnapshot(async (snapshot) => {
        const membersBots = [];
        snapshot.forEach((doc) => {
          const { toolName } = doc.data();
          membersBots.push({ toolName, botId: doc.id });
        });
        // await dispatch(memberBotsSuccess({ memberBots: membersBots }));
      });

      allSubscribers.findIndex((x) => x.name === 'botCollectionRef') === -1 &&
        allSubscribers.push({ func: botCollectionRef, name: 'botCollectionRef' });
    } catch (err) {
      await addNotificationError(err.message);
      // await dispatch(memberBotsFailed());
    }
  };
};

const teamProjectFavouriteSubmit = ({ botId, contentId, workspaceUID }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();

    const { selectedWorkspace } = getState().accountSettings;
    const { workspaceType } = selectedWorkspace;

    try {
      workspaceUID &&
        workspaceType === TEAM_WORKSPACE &&
        (await db
          .collection('team-members')
          .doc(workspaceUID)
          .collection('craft')
          .doc(botId)
          .set(
            {
              favorites: firebase.firestore.FieldValue.arrayUnion(contentId),
              action: 'favorites',
            },
            { merge: true },
          )
          .then(() => {
            dispatch(
              userProjectFavLoading({
                favLoading: {
                  spin: false,
                  contentId: null,
                },
              }),
            );
          })
          .catch((error) => {
            dispatch(
              userProjectFavLoading({
                favLoading: {
                  spin: false,
                  contentId: null,
                },
              }),
            );
          }));
    } catch (err) {
      console.log('error.message', err.message);
      await addNotificationError(err);
    }
  };
};

const fetchTeamMembersFavContent = ({ selectedToolPageId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    let selectedCurrentProjectId = getState().userProject.selectedCurrentProject.currentProjectId;
    const { selectedWorkspace } = getState().accountSettings;

    const { selectedUID } = selectedWorkspace;
    try {
      const teamFavContentSubscriber = db
        .collection('team-members')
        .doc(selectedUID)
        .collection('craft')
        .where('toolName', '==', selectedToolPageId)
        .where('recentProjectId', '==', selectedCurrentProjectId)
        .orderBy('favorites')
        .where('uid', '==', uid)
        .onSnapshot(async (favOutputDoc) => {
          const favOutputs = [];
          favOutputDoc.forEach((doc) => {
            favOutputs.push(doc.data());
          });
          const favoritesData = [];
          favOutputs.forEach(({ error, favorites, uid, output, botId, dateInFormat }) => {
            if (!error && output && output.content && Array.isArray(output.content)) {
              const da = output.content
                .map(({ contentId, contentData, len, time }) => {
                  if (favorites && favorites.includes(contentId)) {
                    return {
                      contentId,
                      contentData,
                      len,
                      date: dateInFormat,
                      uid,
                      time,
                      botId,
                    };
                  }
                })
                .filter(Boolean);
              favoritesData.push(...da);
            }
          });
          const sortFavData = favoritesData.sort((a, b) => b.time - a.time);
          const displayFavData = { [selectedToolPageId]: sortFavData };

          dispatch(userProjectFavoriteSuccess(displayFavData));
        });
      allSubscribers.findIndex((x) => x.name === 'teamFavContentSubscriber') === -1 &&
        allSubscribers.push({ func: teamFavContentSubscriber, name: 'teamFavContentSubscriber' });
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

// This Recent is for Recent PAGE
const fetchTeamRecentProjectsPage = (dateRangeRecent) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    // Recent Project Id is whether the user personal project or the workspace
    // In Firebase collection, all team workspace are stored with their recent project.
    const { selectedWorkspace } = getState().accountSettings;
    let selectedCurrentProjectId = getState().userProject.selectedCurrentProject.currentProjectId;
    const { uid } = getState().fb.auth;
    const { allTools } = getState().adminFeatures;

    dispatch(recentPageLoading(true));
    const startDate = new Date(`${dateRangeRecent[0]}T00:00:00.000`);
    const endDate = new Date(`${dateRangeRecent[1]}T23:59:59.000`);
    try {
      if (selectedCurrentProjectId) {
        db.collection('team-members')
          .doc(selectedWorkspace.selectedUID)
          .collection('craft')
          .where('uid', '==', uid)
          .where('recentProjectId', '==', selectedCurrentProjectId)
          .where('dateInFormat', '>=', startDate)
          .where('dateInFormat', '<=', endDate)

          .onSnapshot(async (favOutputDoc) => {
            const recentOutputs = [];
            favOutputDoc.forEach((doc) => {
              recentOutputs.push(doc.data());
            });
            const recentData = [];
            recentOutputs.forEach(
              ({ input, error, inputFields, recentProjectId, toolName, uid, output, botId, dateInFormat }) => {
                if (!error && output && output.content && Array.isArray(output.content)) {
                  const da = output.content
                    .map(({ contentId, contentData, len, words, time }) => {
                      const toolDisplayName = getToolName({ toolId: toolName, allTools });
                      return {
                        input,
                        contentId,
                        contentData,
                        len,
                        words,
                        date: dateInFormat,
                        uid,
                        time,
                        botId,
                        toolName,
                        inputFields,
                        toolDisplayName: toolDisplayName || '',
                      };
                    })
                    .filter(Boolean);
                  recentData.push(...da);
                }
              },
            );
            const sortRecentData = recentData.sort((a, b) => b.time - a.time);
            dispatch(recentPageSuccess(sortRecentData));
            dispatch(recentPageLoading(false));
          });
      } else {
        dispatch(recentPageLoading(false));
      }
    } catch (err) {
      console.log(`err`, err.message);
      dispatch(recentPageLoading(false));
    }
  };
};

// This favourite is for FAV PAGE
const fetchTeamFavProjectsPage = (dateRangeFav) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    // Recent Project Id is whether the user personal project or the workspace
    // In Firebase collection, all team workspace are stored with their recent project.
    const { selectedWorkspace } = getState().accountSettings;
    const { allTools } = getState().adminFeatures;

    let selectedCurrentProjectId = getState().userProject.selectedCurrentProject.currentProjectId;

    dispatch(favoritesPageLoading(true));

    const startDate = new Date(`${dateRangeFav[0]}T00:00:00.000`);
    const endDate = new Date(`${dateRangeFav[1]}T23:59:59.000`);

    try {
      if (selectedCurrentProjectId) {
        db.collection('users-bot')
          .doc(selectedWorkspace.selectedUID)
          .collection('craft')
          .where('recentProjectId', '==', selectedCurrentProjectId)
          .where('hasFavorites', '==', true)
          .where('dateInFormat', '>=', startDate)
          .where('dateInFormat', '<=', endDate)
          .onSnapshot(async (favOutputDoc) => {
            const favOutputs = [];
            favOutputDoc.forEach((doc) => {
              favOutputs.push(doc.data());
            });
            const favoritesData = [];
            favOutputs.forEach(
              ({ commonIcon, error, favorites, input, inputFields, toolName, uid, output, botId, dateInFormat }) => {
                if (!error && output && output.content && Array.isArray(output.content)) {
                  const da = output.content
                    .map(({ contentId, contentData, len, words, time }) => {
                      if (favorites.includes(contentId)) {
                        const toolDisplayName = getToolName({ toolId: toolName, allTools });
                        return {
                          input,
                          contentId,
                          contentData,
                          len,
                          words,
                          date: dateInFormat,
                          uid,
                          time,
                          botId,
                          toolName,
                          inputFields,
                          commonIcon,
                          toolDisplayName: toolDisplayName || '',
                        };
                      }
                    })
                    .filter(Boolean);
                  favoritesData.push(...da);
                }
              },
            );
            const sortFavData = favoritesData.sort((a, b) => b.time - a.time);
            dispatch(favoritesPageSuccess(sortFavData));
            dispatch(favoritesPageLoading(false));
          });
      } else {
        dispatch(favoritesPageLoading(false));
      }
    } catch (err) {
      console.log(`err`, err.message);
      dispatch(favoritesPageLoading(false));
    }
  };
};

export {
  deleteTeam,
  resetTeamDeletion,
  setMirrorTeamUID,
  fetchAllTeams,
  teamMemberBots,
  teamProjectFavouriteSubmit,
  fetchTeamMembersFavContent,
  fetchTeamRecentProjectsPage,
  fetchTeamFavProjectsPage,
};

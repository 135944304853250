/* ------------------------------------------------------ */
/*                      Root Reducers                     */
/* ------------------------------------------------------ */

import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './authentication/reducers';
import { teamReducer } from './team/reducers';
import featuresReducer from './features/reducers';

import { userProjectReducer } from './UserProject/reducers';
import { adminFeatures } from './AdminFeatures/reducers';
import stripReducer from './Stripe/reducers';
import firebaseAuth from './firebase/auth/reducers';
import { accountSettings } from './AccountSettings/reducers';
import { billing } from './Billing/reducers';
import teamChatReducer from './teamChat/reducers';
import { initialRootState } from './store';

const appReducer = combineReducers({
  fb: firebaseReducer,
  fs: firestoreReducer,
  team: teamReducer,
  auth: authReducer,
  userProject: userProjectReducer,
  stripe: stripReducer,
  features: featuresReducer,
  firebaseAuth,
  adminFeatures: adminFeatures,
  accountSettings: accountSettings,
  billing: billing,
  teamChat: teamChatReducer,
});

const rootReducers = (state, action) => {
  if (action.type === 'FB_LOGOUT_SUCCESS') {
    return appReducer(initialRootState, action);
  }
  return appReducer(state, action);
};

export default rootReducers;

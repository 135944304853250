/* ------------------------------------------------------ */
/*                   Rephrase Output UI                   */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * Rephrase ouput content is being highlighted with underline mark and each underline words or phrases have Popover to select the alternative words
 * Dropdown have the alternative words.
 *
 * When a different word is chosen from the Popover, the original word is replaced,
 * When rephase is compelte a little animation is displayed.
 *
 *
 */

import { Popover, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { uniqueId } from '../../../utility/utility';
import { AlternativeStyled } from '../styled';
import { RandomReveal } from 'react-random-reveal';

const { Paragraph } = Typography;

/**
 * @rephraseContent it is the actual rephrase content from the tool response.
 * @userInput it is the original text which is later on replace by rephrase contnet
 * @key paragrapgh ID
 * @additionalWords is is basically the alternative word list
 * @onRephraseContentChange a flag basic purpose is to close the popover.
 *
 */
const RephraseUI = ({ rephraseContent, userInput, key, additionalWords, onRephraseContentChange }) => {
  console.log('first', rephraseContent, userInput, key, additionalWords, onRephraseContentChange);

  const [open, setOpen] = useState(false);
  const [finalDisplayRephrase, setFinalDisplayRephrase] = useState([]);
  const uniqueAlternativeWords =
    additionalWords && Array.isArray(additionalWords)
      ? [...new Map(additionalWords.map((item) => [item['word'], item])).values()]
      : [];

  const onAlternativeClick = (alternativeWord, highlightId) => {
    var span = document.getElementById(highlightId);
    span.textContent = alternativeWord;
    onRephraseContentChange();
    setOpen({ newOpen: false });
    document.getElementById('fix-popup').click();
  };

  const showAlternative = ({ highlightId }) => {
    const highlightedWord = document.getElementById(highlightId)?.textContent;
    const searchAlternative = uniqueAlternativeWords.find(({ alternative }) => {
      return highlightedWord && alternative.toLowerCase().includes(highlightedWord.toLowerCase().trim());
    });

    const listAlternative =
      searchAlternative && searchAlternative.alternative && searchAlternative.alternative.split('|');
    const alternativeDropdown = listAlternative && listAlternative.filter((alter) => alter !== highlightedWord);
    return (
      <AlternativeStyled>
        {alternativeDropdown &&
          Array.isArray(alternativeDropdown) &&
          alternativeDropdown.map((alternativeWord) => {
            return (
              <div className="alternate-word-hover" onClick={() => onAlternativeClick(alternativeWord, highlightId)}>
                {alternativeWord}
              </div>
            );
          })}
      </AlternativeStyled>
    );
  };

  // special formatting is being done inorder to differentiate the alternative words,
  // the underline remains even the original word is replaced.
  useEffect(() => {
    let rephraseRawContent = rephraseContent;
    uniqueAlternativeWords &&
      uniqueAlternativeWords.forEach(({ word, alternative }, index) => {
        const regexp = new RegExp(`\\b${word}\\b`, 'gi'); // showing how to pass optional flags
        rephraseRawContent = rephraseRawContent.replace(regexp, function (matched) {
          return `####>>>${matched}>>>####`;
        });
      });
    const refine = rephraseRawContent.split('####');
    const displayFinalContent = [];

    refine &&
      Array.isArray(refine) &&
      refine.forEach((word, index) => {
        const keywords = word.slice(0, 3);
        // this condition is to deduct the alternative word and alternative word should have Popover with alternative words in it
        if (keywords === '>>>') {
          const rephraseSchema = word.split('>>>');
          let highlightId = uniqueId();
          // higlighted words are displayed with underline and with popover to show alternative words
          displayFinalContent.push(
            <Popover
              key={index}
              overlayInnerStyle={{
                padding: '0px',
                boxShadow: '0 2px 8px #6C5AFB75',
              }}
              destroyTooltipOnHide={true}
              overlayClassName="alternate-word-style"
              placement="bottomLeft"
              content={() => rephraseSchema[1] && showAlternative({ highlightId })}
              trigger="click"
            >
              <span className="word-tooltip" id={highlightId}>
                <RandomReveal isPlaying duration={2.6} revealDuration={0.5} characters={rephraseSchema[1]} />
              </span>
            </Popover>,
          );
        } else if (userInput) {
          // this is the initial stage when a user submit the request.
          // this simply displays the user original content
          const wordsplit = word.split('\n\n');

          wordsplit.forEach((sentence) => {
            displayFinalContent.push(<Paragraph className="user-original-input">{sentence} </Paragraph>);
          });
        } else {
          // for the rest of the words are displayed here.
          // the paragraph are deducted by \n\n
          if (word.includes('\n\n')) {
            const wordsplit = word.split('\n\n');

            wordsplit.forEach((sentence) => {
              displayFinalContent.push(wordsplit[1]);
            });
          } else {
            displayFinalContent.push(word);
          }
        }
      });
    setFinalDisplayRephrase(displayFinalContent);
  }, [rephraseContent]);

  useEffect(() => {
    onRephraseContentChange();
  }, [finalDisplayRephrase]);

  return (
    <div id="fix-popup" className="rephrase-wrapper" key={`output-${key}`}>
      <div id="rephrase-block">{finalDisplayRephrase}</div>
    </div>
  );
};

export default RephraseUI;

import styled from 'styled-components';

const CustomStepContent = styled.div`
  @media only screen and (max-width: 574px) {
    padding: 3rem 1rem 0rem 1rem;
  }
  .title {
    margin-bottom: 0px;
  }
  .welcome {
    font-size: 30px;
    font-weight: 300;
    margin: 0px !important;
    padding-bottom: 1rem;
    @media only screen and (max-width: 574px) {
      font-size: 26px;
    }
  }
  .question {
    margin-top: 2.5rem;
    .label-onboarding {
      font-size: 16px;
    }
    .name-onboarding-input {
      outline: 0;
      border-width: 0 0 1px;
      box-shadow: none;
      background: transparent;
      border-radius: 0px;
    }
    .ant-input:focus,
    .ant-input-focused {
      border-right-width: 0px !important;
    }
    .ant-input:hover {
      border-right-width: 0px !important;
    }
  }
  .statement {
    font-size: 15px;
    color: ${({ theme }) => theme['light-grey-color']} !important;
  }
  .options {
    cursor: pointer;
    .member-charges {
      margin-left: 1rem;
      font-size: 19px;
      margin-top: 4px;
    }
    .CardRow {
      row-gap: 10px !important;
    }
    .ant-row {
      margin-bottom: 1rem;
    }
    .selected {
      border: 1px solid #9979fd !important;
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
    }
    .ant-card-bordered {
      border: 1px solid rgb(226, 236, 241);
      &:hover {
        border: 1px solid #9979fd;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
      }
    }
    .ant-card-body {
      padding: 24px !important;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      .image-icon {
        width: 20%;
        margin-right: 20px;
      }
    }
  }
`;

const CustomWizardWrapper = styled.div`
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #c7cad1;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: #c7cad1;
  }

  .ant-steps-vertical > .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #c7cad1;
    width: 2px;
    height: 100%;
    margin: 0rem 0px;
  }

  .ant-steps-vertical > .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #c7cad1;
    width: 2px;
    height: 100%;
    margin: 0rem 0px;
  }

  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    margin-left: 0.5rem;
  }

  .ant-steps-vertical > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #9979fd;
    width: 2px;
    height: 100%;
    margin: 0rem 0px;
  }

  .ant-steps-item .ant-steps-item-process .ant-steps-item-custom .ant-steps-item-active > .ant-steps-item-tail::after {
    background-color: ${({ theme }) => theme['aliceBlue-color']};
    width: 2px;
    height: 100%;
    margin: 0.3rem 0px;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #9979fd;
    font-weight: 600;
    text-transform: uppercase;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #8f97a1;
    font-weight: 600;
  }

  &.bordered-wizard {
    padding: 0;
  }
  .steps-action button.btn-next svg {
    ${({ theme }) => (theme.rtl ? 'margin-right' : 'margin-left')}: 10px;
  }
  .steps-action button.btn-prev svg {
    ${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 10px;
  }
  .ant-steps {
    @media only screen and (max-width: 575px) {
      flex-flow: column;
      align-items: center;
    }
    @media only screen and (max-width: 480px) {
      align-items: flex-start;
    }
  }
  .ant-steps-item-container {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 50%;
    ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 15px;

    @media only screen and (max-width: 575px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 480px) {
      flex-flow: row;
    }
    .ant-steps-item-tail {
      left: 22px !important;
      display: none;

      @media (min-width: 575px) {
        top: 7px !important;
      }
    }
    .ant-steps-item-title::after {
      top: -26px;
      height: 2px;
      left: 89%;
    }
    .ant-steps-item-content {
      @media only screen and (max-width: 480px) {
        min-height: auto !important;
      }
    }
  }
  .steps-content {
    margin-top: 72px !important;
    @media only screen and (max-width: 1599px) {
      min-height: 252px !important;
    }
    @media only screen and (max-width: 1199px) {
      margin-top: 45px !important;
    }
    @media only screen and (max-width: 575px) {
      margin-top: 30px !important;
    }
  }

  .ant-steps-item {
    &:last-child {
      ${({ theme }) => (!theme.rtl ? 'padding-left' : 'padding-right')}: 15px !important;
      @media only screen and (max-width: 575px) {
        padding: 0 !important;
      }
    }
    &:last-child {
      @media only screen and (max-width: 991px) {
        flex: 1 1;
      }
    }
    .ant-steps-item-title {
      font-size: 12px;
      font-weight: 500;
      margin-top: 2px;
      line-height: 15px !important;
      text-transform: uppercase;

      @media only screen and (max-width: 575px) {
        padding: 0;
      }
    }
    .ant-steps-item-description {
      font-size: 14px;
      color: black;
      font-weight: 600;
    }
    .ant-steps-item-icon {
      width: 45px;
      height: 45px;
      line-height: 45px;
      background-color: transparent;
      border: 2px solid #d4d4da;
      .ant-steps-icon {
        font-size: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme['white']};
      }
    }
    &.ant-steps-item-active {
      .ant-steps-item-icon {
        background-image: -moz-linear-gradient(-90deg, rgb(139, 119, 252) 0%, rgb(165, 122, 254) 100%);
        background-image: -webkit-linear-gradient(-90deg, rgb(139, 119, 252) 0%, rgb(165, 122, 254) 100%);
        background-image: -ms-linear-gradient(-90deg, rgb(139, 119, 252) 0%, rgb(165, 122, 254) 100%);
        border: none;
        .ant-steps-icon {
          font-size: 18px;
          font-weight: 500;
          color: ${({ theme }) => theme['white']};
        }
      }
      .ant-steps-item-title {
        color: #9979fd !important;
        font-weight: bold;
        margin-top: 0px;
      }
      &.ant-steps-item-finish {
        .ant-steps-item-title {
          background-color: transparent !important;
          color: ${({ theme }) => theme['light-color']} !important;
        }
      }
    }

    &.ant-steps-item-wait {
      .ant-steps-icon {
        font-size: 18px;
        font-weight: 500;
        color: #8f97a1 !important;
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-icon {
        background-image: -moz-linear-gradient(-90deg, rgb(139, 119, 252) 0%, rgb(165, 122, 254) 100%);
        background-image: -webkit-linear-gradient(-90deg, rgb(139, 119, 252) 0%, rgb(165, 122, 254) 100%);
        background-image: -ms-linear-gradient(-90deg, rgb(139, 119, 252) 0%, rgb(165, 122, 254) 100%);
        border: none;
        .ant-steps-icon {
          font-size: 18px;
          font-weight: 500;
          color: ${({ theme }) => theme['white']};
        }
      }
    }
  }
`;

const CustomStepWizard = styled.div`
    width: 100%;
     
    .steps-content-wrapper {
        // height:100%
    }
    .steps-wrapper{
        width: 100%;
        max-width: 490px;
        margin-top: 5rem;
        margin-bottom: auto;
        margin-left: auto;
        margin-right auto;s
     }
    .steps-content{
        margin-top: 0 !important;
        justify-content: flex-start !important;
    }
    .step-action-wrap{
        .step-action-inner{
            padding: 0 !important;
        }
    } 
    .ant-steps-item-container{
        position: relative
        display: flex;
        flex-flow: row;
        
      
        ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0;
        @media (max-width: 575px){
            flex-flow: column;
        }    
        @media (min-width: 575px){
            width: 100%;
            height: 100%
            display: block;
        }    
    }
    .ant-steps-item:last-child {
        ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 0 !important;
    }
    .ant-steps {
        ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 30px;
        display: flex;

        @media (max-width: 991px){
            min-width: auto;
        }
        @media (min-width: 575px){
            align-items: flex-start;
        }
        @media (max-width: 575px){
            align-items: center;
            flex-direction: row;
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
            height: 9rem;
            padding-left: 2.8rem;
        }
      
        &.ant-steps-vertical {
            flex: 1;
            padding: 6rem 2rem 19rem 2rem;
            height: 100%;
 
            @media (max-width: 575px) {
                max-width: 100%;
            }

            @media (min-width: 575px){
                max-height: 50rem;
            }

            .ant-steps-item {
                flex: 1;
                ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 0;
                &:not(:last-child){
                    @media (max-width: 575px){
                        margin-bottom: 15px;
                    }
                }
                &.ant-steps-item-wait{
                    .ant-steps-item-icon {
                        border: 2px solid #d4d4da;
                        background-color: transparent !important;
                        .ant-steps-icon {
                            color:  #8f97a1 !important;
                        }
                    }
                }
                &.ant-steps-item-active {
                    .ant-steps-item-title {
                        color:  #9979fd !important;
                        font-weight: bold;
                        margin-top: 0px;
                    }
                    &.ant-steps-item-finish {
                        .ant-steps-item-title {
                            background-color: transparent !important;
                            color: ${({ theme }) => theme['light-color']} !important;
                        }
                    }
                }
            }
            .ant-steps-item-content{
                ${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0 !important;
            }
        }
    }
    
    .create-account-form{
        padding: 30px;
        border-radius: 6px;
        border: 1px solid ${({ theme }) => theme['bg-color-deep']};
    }
    .atbd-finish-order{
        max-width: 100%;
    }  
`;

export { CustomWizardWrapper, CustomStepWizard, CustomStepContent };

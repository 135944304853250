import styled from 'styled-components';

const LanguageSelectionStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme['color-black']};
  .language-button {
    margin-right: 1rem;
    padding: 7px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;

    .lang {
      display: flex;
      justify-content: center;
      height: 20px;
      align-items: center;
      width: 100%;
      align-items: center;

      .lang-icon {
        width: 2.3rem;
        transform: scale(0.9, 1.2);
        border-radius: 50%;
      }
      .lang-icon-mobile {
        width: 2rem;
        transform: scale(0.9, 1.2);
        border-radius: 50%;
      }
    }

    .lang-text {
      margin-left: 10px;
    }

    @media only screen and (max-width: 575px) {
      margin-right: 0rem;
    }
    .arrow-down {
      @media (max-width: 768px) {
        height: 24px;
      }
    }
  }
`;

const LanguageSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .field-title {
    margin-bottom: 10px;
    color: ${({ theme }) => theme['light-grey-color']};
  }
  .output {
    margin-top: 1rem;
  }
  .flag {
    width: 1.1rem;
    margin-right: 5px;
  }
`;
export { LanguageSelectionStyled, LanguageSelectionWrapper };

const actions = {
  BOTS_READ_BEGIN: 'BOTS_READ_BEGIN',
  BOTS_READ_SUCCESS: 'BOTS_READ_SUCCESS',
  BOTS_READ_ERROR: 'BOTS_READ_ERROR',

  CHANGE_PROFILE_DATA: 'CHANGE_PROFILE_DATA',
  CHANGE_PROFILE_DATA_SUCCESS: 'CHANGE_PROFILE_DATA_SUCCESS',
  CHANGE_PROFILE_DATA_ERROR: 'CHANGE_PROFILE_DATA_ERROR',

  GET_ALL_USERS_PROGRESS: 'GET_ALL_USERS_PROGRESS',

  GET_FILTERED_USERS_PROGRESS: 'GET_FILTERED_USERS_PROGRESS',
  GET_FILTERED_USERS_SUCCESS: 'GET_FILTERED_USERS_SUCCESS',

  GET_SEARCH_USERS: 'GET_SEARCH_USERS',
  GET_SEARCH_USERS_SUCCESS: 'GET_SEARCH_USERS_SUCCESS',
  GET_SEARCH_USERS_FAILED: 'GET_SEARCH_USERS_FAILED',

  CREATE_NEW_USER: 'CREATE_NEW_USER',
  CREATE_NEW_USER_SUCCESS: 'CREATE_NEW_USER_SUCCESS',
  CREATE_NEW_USER_FAILED: 'CREATE_NEW_USER_FAILED',

  BOTS_USERNAME_READ_SUCCESS: 'BOTS_USERNAME_READ_SUCCESS',
  BOTS_TOOL_READ_SUCCESS: 'BOTS_TOOL_READ_SUCCESS',
  BOTS_INPUT_READ_SUCCESS: 'BOTS_INPUT_READ_SUCCESS',
  BOTS_OUTPUT_READ_SUCCESS: 'BOTS_OUTPUT_READ_SUCCESS',

  BOTS_OUTPUT_SUMMARY: 'BOTS_OUTPUT_SUMMARY',
  // Tools Related Stuff
  GET_ALL_TOOLS_PROGRESS: 'GET_ALL_TOOLS_PROGRESS',
  GET_ALL_TOOLS_SUCCESS: 'GET_ALL_TOOLS_SUCCESS',

  GET_ALL_GROUP_FIELDS: 'GET_ALL_GROUP_FIELDS',
  TOP_BANNER_NOTIFICATIONS_START: 'TOP_BANNER_NOTIFICATIONS_START',
  TOP_BANNER_NOTIFICATIONS_SUCCESS: 'TOP_BANNER_NOTIFICATIONS_SUCCESS',

  ADMIN_USER_DELETE_PROGRESS: 'ADMIN_USER_DELETE_PROGRESS',
  ADMIN_USER_DELETE_SUCCESS: 'ADMIN_USER_DELETE_SUCCESS',
  ADMIN_USER_DELETE_FAILED: 'ADMIN_USER_DELETE_FAILED',

  USERS_BOTS_START: 'USERS_BOTS_START',
  USERS_BOTS_SUCCESS: 'USERS_BOTS_SUCCESS',
  USERS_BOTS_FAILED: 'USERS_BOTS_FAILED',

  APP_SETTINGS: 'APP_SETTINGS',
  APP_SETTINGS_LOADING: 'APP_SETTINGS_LOADING',

  ALL_FINGERPRINTS: 'ALL_FINGERPRINTS',
  SAVE_UID_FOR_FETCHING: 'SAVE_UID_FOR_FETCHING',
  FETCH_USER_DETAILS_START: 'FETCH_USER_DETAILS_START',
  FETCH_USER_DETAILS_SUCCESS: 'FETCH_USER_DETAILS_SUCCESS',
  FETCH_USER_DETAILS_FAILED: 'FETCH_USER_DETAILS_FAILED',

  SAVE_FILTERS_USER_MANAGEMENT: 'SAVE_FILTERS_USER_MANAGEMENT',
  GET_TOTAL_ROWS_USER_MANAGEMENT: 'GET_TOTAL_ROWS_USER_MANAGEMENT',
  GET_USERS_BOTS_DATE: 'GET_USERS_BOTS_DATE',
  GET_USERS_BOTS_DATE_LOADING: 'GET_USERS_BOTS_DATE_LOADING',
  GET_USERS_BOTS_AVAILABLE_DATES: 'GET_USERS_BOTS_AVAILABLE_DATES',

  SEARCH_TOOLS: 'SEARCH_TOOLS',
  SELECTED_CATEGORY_ID: 'SELECTED_CATEGORY_ID',
  SELECTED_TOOL_PAGE_ID: 'SELECTED_TOOL_PAGE_ID',

  readSavedBots: () => {
    return {
      type: actions.BOTS_READ_BEGIN,
    };
  },

  readSavedBotsSuccess: ({ savedBots, botDates }) => {
    return {
      type: actions.BOTS_READ_SUCCESS,
      savedBots,
      botDates,
    };
  },
  readSavedBotsError: () => {
    return {
      type: actions.BOTS_READ_ERROR,
    };
  },
  readBotsUserName: ({ botsUserName }) => {
    return {
      type: actions.BOTS_USERNAME_READ_SUCCESS,
      botsUserName,
    };
  },
  readBotsToolName: ({ botsToolName }) => {
    return {
      type: actions.BOTS_TOOL_READ_SUCCESS,
      botsToolName,
    };
  },
  readBotsInputName: ({ botsInput }) => {
    return {
      type: actions.BOTS_INPUT_READ_SUCCESS,
      botsInput,
    };
  },
  readBotsOutput: ({ botsOutput }) => {
    return {
      type: actions.BOTS_OUTPUT_READ_SUCCESS,
      botsOutput,
    };
  },
  botsOutpoutSummary: ({ botsSummary }) => {
    return {
      type: actions.BOTS_OUTPUT_SUMMARY,
      botsSummary,
    };
  },

  getFilterUsersSuccess: ({ filteredUsers }) => {
    return {
      type: actions.GET_FILTERED_USERS_SUCCESS,
      filteredUsers,
    };
  },
  getFilterUsersProgress: filteredUsersLoading => {
    return {
      type: actions.GET_FILTERED_USERS_PROGRESS,
      filteredUsersLoading,
    };
  },

  getAllToolsSuccess: ({ allTools }) => {
    return {
      type: actions.GET_ALL_TOOLS_SUCCESS,
      allTools,
    };
  },

  getAllGroupFieldsSuccess: ({ allGroupFields }) => {
    return {
      type: actions.GET_ALL_GROUP_FIELDS,
      allGroupFields,
    };
  },

  getTopBannerNotificationsStart: () => {
    return {
      type: actions.TOP_BANNER_NOTIFICATIONS_START,
    };
  },

  getTopBannerNotificationsSuccess: ({ topBannerNotifications }) => {
    return {
      type: actions.TOP_BANNER_NOTIFICATIONS_SUCCESS,
      topBannerNotifications,
    };
  },

  getAllToolsProgress: () => {
    return {
      type: actions.GET_ALL_TOOLS_PROGRESS,
    };
  },

  // this will implement when we link with algolia ;
  getSearchUsers: ({ searchUsers }) => {
    return {
      type: actions.GET_SEARCH_USERS_SUCCESS,
      searchUsers,
    };
  },

  changeProfileDataProgress: () => {
    return {
      type: actions.CHANGE_PROFILE_DATA,
    };
  },

  changeProfileDataSuccess: () => {
    return {
      type: actions.CHANGE_PROFILE_DATA_SUCCESS,
    };
  },
  changeProfileDataError: () => {
    return {
      type: actions.CHANGE_PROFILE_DATA_ERROR,
    };
  },
  createNewUserStart: () => {
    return {
      type: actions.CREATE_NEW_USER,
    };
  },
  createNewUserSuccess: () => {
    return {
      type: actions.CREATE_NEW_USER_SUCCESS,
    };
  },
  createNewUserFailed: () => {
    return {
      type: actions.CREATE_NEW_USER_FAILED,
    };
  },

  adminDeleteProgress: () => {
    return {
      type: actions.ADMIN_USER_DELETE_PROGRESS,
    };
  },
  adminDeleteSuccess: () => {
    return {
      type: actions.ADMIN_USER_DELETE_SUCCESS,
    };
  },
  adminDeleteFailed: () => {
    return {
      type: actions.ADMIN_USER_DELETE_FAILED,
    };
  },

  userBotsStart: () => {
    return {
      type: actions.USERS_BOTS_START,
    };
  },
  userBotsSuccess: userBots => {
    return {
      type: actions.USERS_BOTS_SUCCESS,
      userBots,
    };
  },
  userBotsFailed: () => {
    return {
      type: actions.USERS_BOTS_FAILED,
    };
  },

  blockFingerPrint: ({ blockNow }) => {
    return {
      type: actions.ALL_FINGERPRINTS,
      blockNow,
    };
  },
  getAppSettings: appSettings => {
    return {
      type: actions.APP_SETTINGS,
      appSettings,
    };
  },
  getAppSettingLoading: appSettingLoading => {
    return {
      type: actions.APP_SETTINGS_LOADING,
      appSettingLoading,
    };
  },

  fetchUserDetailsStart: () => {
    return {
      type: actions.FETCH_USER_DETAILS_START,
    };
  },

  fetchUserDetailsSuccess: ({ fetchUserDetails }) => {
    return {
      type: actions.FETCH_USER_DETAILS_SUCCESS,
      fetchUserDetails,
    };
  },
  fetchUserDetailsFailed: () => {
    return {
      type: actions.FETCH_USER_DETAILS_FAILED,
    };
  },
  saveUIDForFetching: ({ saveUIDForFetching }) => {
    return {
      type: actions.SAVE_UID_FOR_FETCHING,
      saveUIDForFetching,
    };
  },
  saveUserManagementFilters: ({ userManagmentFilters }) => {
    return {
      type: actions.SAVE_FILTERS_USER_MANAGEMENT,
      userManagmentFilters,
    };
  },

  // get the totalNumber of rows from bigQuery
  // this is required for pagination
  getTotalRowsUsers: ({ totalUsersRows }) => {
    return {
      type: actions.GET_TOTAL_ROWS_USER_MANAGEMENT,
      totalUsersRows,
    };
  },

  // get user specific data with date from bigQuery
  getUsersBotsFromDate: ({ userBotsDate }) => {
    return {
      type: actions.GET_USERS_BOTS_DATE,
      userBotsDate,
    };
  },

  // get user specific data with date from bigQuery loading
  getUsersBotsFromDateLoading: ({ userBotsDateLoading }) => {
    return {
      type: actions.GET_USERS_BOTS_DATE_LOADING,
      userBotsDateLoading,
    };
  },
  getUsersBotsAvailableDates: ({ userAvailableDates }) => {
    return {
      type: actions.GET_USERS_BOTS_AVAILABLE_DATES,
      userAvailableDates,
    };
  },

  getSearchToolIds: ({ searchToolIds }) => {
    return {
      type: actions.SEARCH_TOOLS,
      searchToolIds,
    };
  },
  saveSelectedCategoryId: ({ selectedCategoryId }) => {
    return {
      type: actions.SELECTED_CATEGORY_ID,
      selectedCategoryId,
    };
  },
  saveSelectedToolPageId: ({ selectedToolPageId }) => {
    return {
      type: actions.SELECTED_TOOL_PAGE_ID,
      selectedToolPageId,
    };
  },
};

export default actions;

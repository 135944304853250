/* eslint-disable jsx-a11y/control-has-associated-label */

/* ------------------------------------------------------ */
/*                  STARTER Plan Billing                  */
/* ------------------------------------------------------ */

/**
 * Fetch and Insert Start Plan Detials
 *
 * 1. Total words  - Total words a the user can utilized in a month
 * 2. Total worksapce - total workspace a user can create
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Form, InputNumber } from 'antd';
import { Button } from '../../../components/Button';
import { PlanFormStyle } from '../styled';
import { Cards } from '../../../components/Card';
import { BasicFormWrapper } from '../../styled';
import { planStarterBilling, fetchStarterBilling } from '../../../redux/Billing/actionCreator';

const StarterPlan = () => {
  const dispatch = useDispatch();
  const { planStarterLoading, fetchPlanStarterLoading, fetchPlanStarter } = useSelector((state) => state.billing);

  const [form] = Form.useForm();

  useEffect(() => {
    // it fetch the
    dispatch(fetchStarterBilling());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchPlanStarter) {
      form.setFieldsValue(fetchPlanStarter);
    }
  }, [form, fetchPlanStarter]);

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      <BasicFormWrapper>
        <PlanFormStyle>
          <Cards title="Starter Plan" loading={fetchPlanStarterLoading}>
            <Form
              initialValues={fetchPlanStarter}
              name="starter-plans"
              form={form}
              layout="horizontal"
              onFinish={(values) => {
                dispatch(planStarterBilling(values));
              }}
            >
              <>
                <Row align="middle">
                  <Col lg={8} md={9} xs={24}>
                    <label htmlFor="totalWords">Total Words</label>
                  </Col>
                  <Col lg={16} md={15} xs={24}>
                    <Form.Item name="totalWords" rules={[{ required: true, message: 'Please enter total words!' }]}>
                      <InputNumber type="number" placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row align="middle">
                  <Col lg={8} md={9} xs={24}>
                    <label htmlFor="totalWorkspaces">Total Workspaces</label>
                  </Col>
                  <Col lg={16} md={15} xs={24}>
                    <Form.Item
                      name="totalWorkspaces"
                      rules={[{ required: true, message: 'Please enter total workspaces!' }]}
                    >
                      <InputNumber type="number" placeholder="" />
                    </Form.Item>
                  </Col>
                </Row>
              </>

              <Row>
                <Col lg={{ span: 16, offset: 8 }} md={{ span: 15, offset: 9 }} xs={{ span: 24, offset: 0 }}>
                  <div className="sDash_form-action">
                    <Button className="btn-signin" onClick={onReset} type="light" size="large">
                      Cancel
                    </Button>
                    <Button
                      loading={planStarterLoading}
                      htmlType="submit"
                      className="btn-signin"
                      type="primary"
                      size="large"
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Cards>
        </PlanFormStyle>
      </BasicFormWrapper>
    </>
  );
};

export default StarterPlan;

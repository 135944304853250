/* ------------------------------------------------------ */
/*                          Store                         */
/* ------------------------------------------------------ */

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { getFirebase } from 'react-redux-firebase';
import { reduxFirestore, getFirestore, createFirestoreInstance } from 'redux-firestore';
import rootReducer from './rootReducers';
import { firebaseConfig, firebase } from '../config/firebase';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
    reduxFirestore(firebaseConfig),
  ),
);
export const initialRootState = {
  ...store.getState(),
};

export const rrfProps = {
  firebase,
  config: {
    useFirestoreForProfile: true,
    userProfile: 'users',
    attachAuthIsReady: true,
    // presence: 'presence', // where list of online users is stored in database
    // sessions: 'sessions', // where list of user sessions is stored in database (presence must be enabled)
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export default store;

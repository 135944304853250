import actions from './actions';

const {
  USER_PROJECT_ADD_BEGIN,
  USER_PROJECT_ADD_SUCCESS,
  USER_PROJECT_ADD_ERR,

  USER_PROJECT_RECENT_SUCCESS,
  USER_PROJECT_FAVORITE_SUCCESS,

  USER_PROJECT_READ_BEGIN,
  USER_PROJECT_READ_SUCCESS,
  USER_PROJECT_READ_ERR,

  USER_PROJECT_UPDATE_BEGIN,
  USER_PROJECT_UPDATE_SUCCESS,
  USER_PROJECT_UPDATE_ERR,

  USER_PROJECT_DELETE_BEGIN,
  USER_PROJECT_DELETE_SUCCESS,
  USER_PROJECT_DELETE_ERR,

  USER_PROJECT_SEARCH_BEGIN,
  USER_PROJECT_SEARCH_SUCCESS,
  USER_PROJECT_SEARCH_ERR,

  TOOL_HIT_BACKEND_PROGRESS,
  TOOL_HIT_BACKEND_SUCCESS,
  TOOL_HIT_BACKEND_FAILED,
  TOOL_HIT_NULL,
  TEAM_RECENT_PROJECT,
  TOOL_FAV_LOADING,
  FILTER_TOOL_OUPUT,
  FAVORITE_PAGE_SUCCESS,
  FAVORITE_PAGE,
  RECENT_PAGE,
  RECENT_PAGE_SUCCESS,
  SELECTED_CURRENT_PROJECT,
  CAI_CHAT_HISTORY_START,
  CAI_CHAT_HISTORY_SUCCESS,
  CAI_CHAT_HISTORY_FAILED,
} = actions;

// Project Redux State
const initialState = {
  data: [], // the project data stored here.
  loading: false,
  error: null,
  recentProject: {
    projectName: '',
    projectId: '',
  },
  favorites: {}, // this is reducers state, here all the projects are stored
  toolHitData: [],
  // toolOutput: mockData,
  toolOutput: null,
  highlightRowsId: [],
  toolOutputLoading: false,
  toolHitFailed: false,
  setToolOutputNull: false,
  teamRecentProject: null,
  favLoading: {
    spin: false,
    contentId: null,
  },
  currentStepOutputId: false,
  longFormOutput: null,
  allFavoritesPage: [],
  allFavoritesPageLoading: false,
  allRecentPage: [],
  allRecentPageLoading: false,
  selectedCurrentProject: {
    currentProjectId: null,
    projectName: null,
  },
  caiChatHistoryOutput: [],
  caiChatHistoryLoading: false,
  currentBotId: '',
  craftedToolId: null,
};

// this the reducer to handle all the request related to projects

const userProjectReducer = (state = initialState, action) => {
  const {
    type,
    data,
    err,
    recentProject,
    favorites,
    toolOutput,
    highlightRowsId,
    teamRecentProject,
    favLoading,
    currentStepOutputId,
    longFormOutput,
    filterToolOutputs,
    allFavoritesPage,
    allFavoritesPageLoading,
    allRecentPageLoading,
    allRecentPage,
    selectedCurrentProject,
    caiChatHistoryOutput,
    currentBotId,
    craftedToolId,
  } = action;
  switch (type) {
    case USER_PROJECT_ADD_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_PROJECT_ADD_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case USER_PROJECT_RECENT_SUCCESS:
      return {
        ...state,
        recentProject,
        loading: false,
      };
    case USER_PROJECT_FAVORITE_SUCCESS:
      return {
        ...state,
        favorites,
        loading: false,
      };

    case USER_PROJECT_ADD_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_PROJECT_SEARCH_BEGIN:
      return {
        ...state,
      };

    case USER_PROJECT_SEARCH_SUCCESS:
      return {
        ...state,
        data,
        error: false,
      };

    case USER_PROJECT_SEARCH_ERR:
      return {
        ...state,
        error: err,
      };

    case USER_PROJECT_READ_BEGIN:
      return {
        ...state,
        data: [],
        loading: true,
        error: null,
        recentProject: {
          projectName: '',
          projectId: '',
        },
        favorites: {},
      };

    case USER_PROJECT_READ_SUCCESS:
      return {
        ...state,
        data,
        recentProject,
        error: false,
        loading: false,
      };

    case USER_PROJECT_READ_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_PROJECT_DELETE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        error: false,
        data,
        recentProject,
        loading: false,
      };

    case USER_PROJECT_DELETE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case USER_PROJECT_UPDATE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case USER_PROJECT_UPDATE_SUCCESS:
      return {
        ...state,
        data,
        error: false,
        loading: false,
      };

    case USER_PROJECT_UPDATE_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case TOOL_HIT_BACKEND_PROGRESS:
      return {
        ...state,
        toolOutputLoading: true,
        toolHitFailed: false,
        currentBotId,
      };

    case TOOL_HIT_BACKEND_SUCCESS:
      return {
        ...state,
        toolOutput,
        highlightRowsId,
        toolOutputLoading: false,
        toolHitFailed: false,
        currentStepOutputId,
        longFormOutput,
        craftedToolId,
      };

    case TOOL_HIT_BACKEND_FAILED:
      return {
        ...state,
        toolOutputLoading: false,
        toolHitFailed: true,
      };

    case TOOL_HIT_NULL:
      return {
        ...state,
        toolOutput: null,
        highlightRowsId: [],
        toolOutputLoading: false,
        toolHitFailed: false,
      };
    case FILTER_TOOL_OUPUT:
      return {
        ...state,
        toolOutput: filterToolOutputs,
      };

    case TEAM_RECENT_PROJECT:
      return {
        ...state,
        teamRecentProject,
        loading: false,
      };

    case TOOL_FAV_LOADING:
      return {
        ...state,
        favLoading,
      };
    case FAVORITE_PAGE:
      return {
        ...state,
        allFavoritesPageLoading,
      };
    case FAVORITE_PAGE_SUCCESS:
      return {
        ...state,
        allFavoritesPage,
        allFavoritesPageLoading: false,
      };

    case RECENT_PAGE:
      return {
        ...state,
        allRecentPageLoading,
      };
    case RECENT_PAGE_SUCCESS:
      return {
        ...state,
        allRecentPage,
        allRecentPageLoading: false,
      };
    case SELECTED_CURRENT_PROJECT:
      return {
        ...state,
        selectedCurrentProject,
      };

    case CAI_CHAT_HISTORY_START:
      return {
        ...state,
        caiChatHistoryLoading: true,
      };

    case CAI_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        caiChatHistoryOutput,
        caiChatHistoryLoading: false,
      };

    case CAI_CHAT_HISTORY_FAILED:
      return {
        ...state,
        caiChatHistoryLoading: false,
      };

    default:
      return state;
  }
};

export { userProjectReducer };

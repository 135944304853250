import Styled from 'styled-components';

const CreateProjectStyled = Styled.div`
    margin-top: 1rem;
    .project-title{
        font-size: 15px; 
    }
    .input-button-project {
        display: flex;
        border: 1px solid #E3E6EF;
        padding: 5px 8px;
        .project-input {
            border: 0;
            box-shadow: 0 0 0 0px;
            height: 41px;
            font-size: 16px;
        }
        @media only screen and (max-width: 575px){
            display: none;
        }
    } 
    .mobile-project-input{
        @media   (max-width: 575px){
           display: flex;
        }
        @media   (min-width: 575px){
           display: none;
        }
    }
`;
export { CreateProjectStyled };

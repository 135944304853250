/* ------------------------------------------------------ */
/*                    Design Default UI                   */
/* ------------------------------------------------------ */

/**
 * Methodology
 * The default UI when there is no tool craft from user .
 */

import { Typography, Button } from 'antd';
import React from 'react';
import { ToolRightSideWrap } from '../styled';
import { ReactSVG } from 'react-svg';
import adduser from '../../../static/tool-page/add-user.svg';
import calling from '../../../static/tool-page/calling.svg';
import chat from '../../../static/tool-page/chat.svg';
import infoCycle from '../../../static/tool-page/info-circle.svg';
import show from '../../../static/tool-page/show.svg';
import { useViewport } from '../../../hooks/viewPort.js';

const DesignDefaultUI = () => {
  const { Text } = Typography;
  const { width } = useViewport();

  return (
    <ToolRightSideWrap>
      <div className="right-text">
        {width < 768 ? (
          <Text>Fill in the Prompts to Start Crafting</Text>
        ) : (
          <Text>Fill in the Prompts on the Left to Start Crafting</Text>
        )}
      </div>
      <Text className="example">See examples of Input and Outputs</Text>
      <div className="icons-wrapper">
        <div className="examples-icons">
          <ReactSVG className="icon-svg show" src={show} />
          <Text className="icons-text"> Watch Tutorials</Text>
        </div>
        <div className="examples-icons">
          <ReactSVG className="icon-svg info-cycle" src={infoCycle} />
          <Text className="icons-text">Help! AI is Being Weird</Text>
        </div>
        <div className="examples-icons">
          <ReactSVG className="icon-svg chat" src={chat} />
          <Text className="icons-text">Feedback</Text>
        </div>
        <div className="examples-icons">
          <ReactSVG className="icon-svg calling" src={calling} />
          <Text className="icons-text"> Visit Support Center </Text>
        </div>
        <div className="examples-icons">
          <ReactSVG className="icon-svg adduser" src={adduser} />
          <Text className="icons-text">Join Our Community</Text>
        </div>
      </div>
      <div className="button-div">
        <Button className="button-crafting" block size="large" type="primary">
          Keep Crafting
        </Button>
      </div>
    </ToolRightSideWrap>
  );
};
export default DesignDefaultUI;

import { Avatar, Card, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import React from 'react';
import { FeaturesCard } from './mock';
import { FeatureUsWrap, FeatureUsContainer, FeatureUsContent, FeatureMain } from './styled';

const FeatureUs = () => {
  const { Text, Title } = Typography;
  return (
    <FeatureMain>
      <FeatureUsWrap>
        <Title level={4}>Features us!</Title>
        <Text>
          Have a website of your own? Features us on your site, blog, article or podcast and receive free Craftly.ai!
        </Text>
      </FeatureUsWrap>
      <FeatureUsContent>
        {FeaturesCard.map(item => (
          <FeatureUsContainer lastCard={item.key}>
            <div className={item.key === 'submit' ? 'last' : 'dotted'}>
              <figure>
                <img src={item.icon1} alt="count" className="newpic" />
              </figure>
            </div>

            <Card>
              <Meta avatar={<Avatar src={item.icon} />} description={item.des} />
            </Card>
          </FeatureUsContainer>
        ))}
      </FeatureUsContent>
    </FeatureMain>
  );
};

export default FeatureUs;

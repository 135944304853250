import React from 'react';

const CheckIcon = ({ color }) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none">
      <circle cx="9.21409" cy="9.50023" r="9.21409" fill={color} />
      <path
        d="M5.58203 9.27646L8.14941 11.8434L12.8473 7.14551"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient x1="22.4671" y1="33.6266" x2="-5.34523" y2="25.7399" gradientUnits="userSpaceOnUse">
          <stop stop-color={color} />
          <stop offset="1" stop-color={color} />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default CheckIcon;

/* ------------------------------------------------------ */
/*                      Users Columns                     */
/* ------------------------------------------------------ */

/**
 * Users Columns
 * These columns are displayed in the users table.
 *
 * Under Name column there a team name and on clicking the team name it redirect to the
 */

import React from 'react';
import { Typography, Tag } from 'antd';
import { capitalizeFirstLetter } from '../../../utility/utility';
import { Popover } from '../../../components/Popover';

const { Text, Paragraph } = Typography;
export const usersColumns = (onteamClick) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name && b.name && a.name.localeCompare(b.name),
    sortDirections: ['ascend', 'descend'],
    render: (name, record) => {
      const firstName = record.firstName;
      const lastName = record.lastName;
      return (
        <>
          <div style={{ display: 'flex' }}>
            {firstName && lastName ? (
              <Text style={{ wordBreak: ' break-all', whiteSpace: 'break-spaces', width: '200px' }}>
                {capitalizeFirstLetter(`${firstName} ${lastName}`)}
              </Text>
            ) : (
              <Text
                style={{
                  wordBreak: ' break-all',
                  whiteSpace: 'break-spaces',
                  width: '225px',
                  textDecoration: 'underline',
                }}
              >
                {name ? capitalizeFirstLetter(name) : ''}
              </Text>
            )}
            <br />
          </div>
          {record.referralLink && <span className={`status-text referral`}>{`referral:  ${record.referralLink}`}</span>}
          {record.teamEnable && (
            <Text
              style={{ cursor: 'pointer' }}
              type="warning"
              onClick={() => onteamClick(record.key)}
            >{`Team: ${record.companyName}`}</Text>
          )}
        </>
      );
    },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: (a, b) => a.email && b.email && a.email.localeCompare(b.email),
    sortDirections: ['ascend', 'descend'],
    render: (text, record) => (
      <div>
        <div style={{ wordBreak: ' break-all', whiteSpace: 'break-spaces', width: '236px' }}>{text}</div>
        <span className={`status-text invite-pending`}>
          <Paragraph style={{ marginBottom: '0rem', fontSize: '10px' }} copyable={{ text: record.key }}>
            {record.key}
          </Paragraph>
        </span>
      </div>
    ),
  },
  {
    title: 'Roles',
    dataIndex: 'roles',
    key: 'roles',
    sorter: (a, b) => a.roles && b.roles && a.roles.localeCompare(b.roles),
    sortDirections: ['ascend', 'descend'],
    render: (roles) => {
      return <Text>{capitalizeFirstLetter(roles)}</Text>;
    },
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) => a.plan && b.plan && a.plan.localeCompare(b.plan),
    render: (plan, record) => {
      return (
        <>
          <Text>{capitalizeFirstLetter(plan)} </Text>{' '}
          {/* {PAID_PLAN.includes(record.plan) && <Tag color="#2db7f5">{record.planStatus}</Tag>} */}
        </>
      );
    },
  },
  {
    title: 'Status',
    dataIndex: 'displayPlanStatus',
    key: 'displayPlanStatus',
    sortDirections: ['ascend', 'descend'],
    sorter: (a, b) =>
      a.displayPlanStatus && b.displayPlanStatus && a.displayPlanStatus.localeCompare(b.displayPlanStatus),
    render: (displayPlanStatus, record) => (
      <>
        <span className={`status-text ${displayPlanStatus}`}>
          {capitalizeFirstLetter(displayPlanStatus)} <br />
        </span>
        <br />
        {record.acceptInvite === false && <span className={`status-text invite-pending`}>Invite Sent</span>}
      </>
    ),
  },
  {
    title: 'Words',
    dataIndex: 'wordsStatictics',
    key: 'wordsStatictics',
    sorter: (a, b) =>
      a.wordsStatictics &&
      b.wordsStatictics &&
      a.wordsStatictics.currentPlanCraftedWords &&
      b.wordsStatictics.currentPlanCraftedWords &&
      a.wordsStatictics.currentPlanCraftedWords - b.wordsStatictics.currentPlanCraftedWords,
    sortDirections: ['descend', 'ascend'],
    render: (wordsStatictics, record) => {
      const {
        currentPlanTotalHits,
        currentPlanCraftedWords,
        totalPricePerRun,
        todaysHits,
        lifeTimeCrafted,
        lifeTimeHits,
        lifeTimeTotalPricePerRun,
        lastCraftDate,
      } = wordsStatictics || {
        currentPlanTotalHits: '',
        currentPlanCraftedWords: '',
        totalPricePerRun: '',
        todaysHits: '',
        lifeTimeCrafted: '',
        lifeTimeTotalPricePerRun: '',
        lifeTimeHits: '',
        lastCraftDate: '',
      };

      const displayCurrentTotalPricePerRun =
        totalPricePerRun && isNaN(totalPricePerRun) ? totalPricePerRun.toFixed(4) : totalPricePerRun;
      const displayLifeTimeTotalPricePerRun =
        lifeTimeTotalPricePerRun && isNaN(lifeTimeTotalPricePerRun)
          ? lifeTimeTotalPricePerRun.toFixed(4)
          : lifeTimeTotalPricePerRun;
      const content = (
        <div>
          <Text>
            Current Plan Total PricePerRun:{' '}
            <Tag color="magenta">${displayCurrentTotalPricePerRun || totalPricePerRun}</Tag>
          </Text>
          <br />
          <Text>
            Last Craft: <Tag color="magenta">{lastCraftDate}</Tag>
          </Text>
          <br />
          <Text>
            Todays Hit: <Tag color="magenta">{todaysHits}</Tag>
          </Text>
          <br />
          <Text>
            Lifetime Crafted: <Tag color="magenta">{lifeTimeCrafted}</Tag>
          </Text>
          <br />
          <Text>
            Lifetime Hit: <Tag color="magenta">{lifeTimeHits}</Tag>
          </Text>
          <br />
          <Text>
            Lifetime Total PricePerRun:{' '}
            <Tag color="magenta">${displayLifeTimeTotalPricePerRun || lifeTimeTotalPricePerRun}</Tag>{' '}
          </Text>
        </div>
      );
      return (
        <div style={{ cursor: 'pointer' }}>
          <Popover content={content} title={`Craft Statistics For ${record.email}`}>
            <Text underline> {currentPlanCraftedWords || 0}</Text>
            <span className={`status-text invite-pending`}>Hits: {currentPlanTotalHits || 0}</span>
          </Popover>
        </div>
      );
    },
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.createdDate && b.createdDate && new Date(a.createdDate) - new Date(b.createdDate),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
  },
];

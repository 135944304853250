/* eslint-disable jsx-a11y/accessible-emoji */
// eslint-disable-next-line import/no-extraneous-dependencies

/* ------------------ UNDER DEVELOPMENT ----------------- */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars';
import { SingleChatWrapper, MessageList, Footer } from '../style';
import Heading from '../../../components/Headings';
import { Button } from '../../../components/Button';
import { Cards } from '../../../components/Card';
import { DropdownOptions } from '../../../components/DropdownOptions';
import { fetchTeamChatData, updateTeamChat } from '../../../redux/teamChat/actionCreator';
import { Avatar } from 'antd';
import { capitalizeFirstLetter, firstTwoLetters } from '../../../utility/utility';

const TeamChat = ({ teamName, teamID }) => {
  const dispatch = useDispatch();

  const { uid } = useSelector((state) => state.fb.auth);
  const { name } = useSelector((state) => state.fb.profile);
  const { teamChat } = useSelector((state) => state.teamChat);

  const left = 'left';

  const [state, setState] = useState({
    me: uid,
    chatContent: [],
    inputValue: '',
  });

  const { chatContent, me, inputValue } = state;

  useEffect(() => {
    teamID && dispatch(fetchTeamChatData({ teamID }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamID]);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      teamChat &&
        setState({
          chatContent: teamChat.content,
          inputValue,
          me: uid,
        });
    }
    return () => {
      unmounted = true;
    };
  }, [teamChat, inputValue, uid]);

  const handleChange = (e) => {
    setState({
      ...state,
      inputValue: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const pushcontent = {
      content: inputValue,
      time: new Date().getTime(),
      seen: false,
      reaction: false,
      userUID: me,
      name: name,
    };
    dispatch(updateTeamChat({ teamID, messageContent: pushcontent }));
    setState({
      ...state,
      chatContent: [...chatContent, pushcontent],
      inputValue: '',
    });
  };

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: 'auto',
      right: '2px',
      marginRight: '-19px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  const renderThumbVertical = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
      [left]: '2px',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      right: '6px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div style={thumbStyle} />;
  };

  const renderThumbHorizontal = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const content = (
    <>
      <NavLink to="#">
        <FeatherIcon icon="users" size={14} />
        <span>Create new group</span>
      </NavLink>
      <NavLink to="#">
        <FeatherIcon icon="trash-2" size={14} />
        <span>Delete conversation</span>
      </NavLink>
      <NavLink to="#">
        <FeatherIcon icon="slash" size={14} />
        <span>Block & Report</span>
      </NavLink>
    </>
  );

  return (
    <SingleChatWrapper>
      <Cards
        title={
          <>
            <Heading as="h5">{capitalizeFirstLetter(teamName)}</Heading>
            {/* <p>Active Now</p> */}
          </>
        }
        isbutton={[
          <DropdownOptions content={content} key="1">
            <Link to="#">
              <FeatherIcon icon="more-vertical" />
            </Link>
          </DropdownOptions>,
        ]}
      >
        <ul className="atbd-chatbox">
          <Scrollbars
            className="custom-scrollbar"
            autoHide
            autoHideTimeout={500}
            autoHideDuration={200}
            renderThumbHorizontal={renderThumbHorizontal}
            renderThumbVertical={renderThumbVertical}
            renderView={renderView}
            renderTrackVertical={renderTrackVertical}
          >
            {chatContent && chatContent.length ? (
              chatContent.map((mes, index) => {
                const id = mes.time;

                const same = moment(id).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY');

                return (
                  <div key={id}>
                    {index === 1 && (
                      <p className="time-connector text-center text-capitalize">
                        <span>today</span>
                      </p>
                    )}
                    <li className="atbd-chatbox__single" key={id} style={{ overflow: 'hidden' }}>
                      <div className={mes.userUID !== me ? 'left' : 'right'}>
                        {mes.userUID !== me ? (
                          <Avatar size={42} style={{ color: '#ffff', backgroundColor: '#9D79FD' }}>
                            {firstTwoLetters(mes.name)}
                          </Avatar>
                        ) : null}

                        <div className="atbd-chatbox__content">
                          <Heading as="h5" className="atbd-chatbox__name">
                            {mes.userUID !== me && capitalizeFirstLetter(mes.name)}
                            <span>{same ? moment(id).format('hh:mm A') : moment(id).format('LL')}</span>
                          </Heading>

                          {mes.userUID !== me ? (
                            <div className="atbd-chatbox__contentInner d-flex">
                              <div className="atbd-chatbox__message">
                                <MessageList className="message-box">{mes.content}</MessageList>
                              </div>

                              <div className="atbd-chatbox__actions">
                                <DropdownOptions
                                  action={['hover']}
                                  content={
                                    <div className="atbd-chatbox__messageControl">
                                      <ul>
                                        <li>
                                          <Link to="#">Copy</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Edit</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Quote</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Forward</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Remove</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  }
                                  placement="bottomCenter"
                                >
                                  <Link to="#">
                                    <FeatherIcon icon="more-horizontal" size={16} />
                                  </Link>
                                </DropdownOptions>
                              </div>
                            </div>
                          ) : (
                            <div className="atbd-chatbox__contentInner d-flex">
                              <div className="atbd-chatbox__actions">
                                <DropdownOptions
                                  action={['hover']}
                                  content={
                                    <div className="atbd-chatbox__messageControl">
                                      <ul>
                                        <li>
                                          <Link to="#">Edit </Link>
                                        </li>
                                        <li>
                                          <Link to="#">Copy </Link>
                                        </li>
                                        <li>
                                          <Link to="#">Quote</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Forward</Link>
                                        </li>
                                        <li>
                                          <Link to="#">Remove</Link>
                                        </li>
                                      </ul>
                                    </div>
                                  }
                                  placement="bottomCenter"
                                >
                                  <Link to="#">
                                    <FeatherIcon icon="more-horizontal" size={16} />
                                  </Link>
                                </DropdownOptions>
                              </div>
                              <div className="atbd-chatbox__message">
                                <MessageList className="message-box">{mes.content}</MessageList>
                              </div>
                            </div>
                          )}
                          {/* {mes.userUID === me && chatContent.length === index + 1 ? (
                            <div className="message-seen text-right">
                              <span className="message-seen__time">Seen 9:20 PM </span>
                              <img src={`../../../static/img/chat-author/${mes.img}`} alt="" />
                            </div>
                          ) : null} */}
                        </div>
                      </div>
                    </li>
                  </div>
                );
              })
            ) : (
              <p style={{ marginLeft: '1rem', color: 'gray' }}>Type your First Message</p>
            )}
          </Scrollbars>
        </ul>

        <Footer>
          <form onSubmit={handleSubmit}>
            <div className={`chatbox-reply-form d-flex`}>
              <div className="chatbox-reply-input">
                {/* <span className="smile-icon">
                  {pickerShow && <Picker onEmojiClick={onEmojiClick} />}
                  <Link onClick={onPickerShow} to="#">
                    <FeatherIcon icon="smile" size={24} />
                  </Link>
                </span> */}
                <input
                  onChange={handleChange}
                  placeholder="Type your message..."
                  name="chat"
                  id="chat"
                  style={{ width: '100%' }}
                  value={inputValue}
                />
              </div>
              <div className="chatbox-reply-action d-flex">
                {/* <Link to="#">
                  <Upload {...props}>
                    <FeatherIcon icon="camera" size={18} />
                  </Upload>
                </Link>
                <Link to="#">
                  <Upload {...attachment}>
                    <FeatherIcon icon="paperclip" size={18} />
                  </Upload>
                </Link> */}
                <Button onClick={handleSubmit} type="primary" className="btn-send">
                  <FeatherIcon icon="send" size={18} />
                </Button>
              </div>
            </div>
          </form>
        </Footer>
      </Cards>
    </SingleChatWrapper>
  );
};

export default TeamChat;

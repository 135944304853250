import styled from 'styled-components';
import { CAI_CHAT_INPUT, CAI_CHAT_OUTPUT } from '../../constants';

const CaiChatStyled = styled.div`
  .chat-side {
    border-right: 1px solid #eeee;
    height: calc(100vh - 10rem);
    .top {
      display: flex;
      justify-content: space-between;
      padding: 1.2rem 28px;

      .header-action-btn {
        span {
          font-size: 10px !important;
        }
      }
    }
    .cai-chat-messages {
      height: calc(100vh - 26rem);
      overflow: auto;
      padding-right: 28px;
      padding-left: 28px;
      .empty-chat {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        flex-direction: column;
        .chat-send-img {
          width: 80px;
        }
        .empty-text {
          margin-top: 1rem;
          font-family: 'Poppins-Medium';
          color: #737277;
          font-size: 14px;
        }
      }
    }

    .input-section {
      display: flex;
      flex-direction: column;
      /* position: sticky;
      bottom: 0; */
      padding: 1rem;
      padding-bottom: 0px;
      background-color: #fff;

      .input-text {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .prompt-input {
          display: flex;
          justify-content: flex-start;
          align-items: start;
          border: 1px solid #eeeeee;
          border-radius: 8px;
          flex-direction: column-reverse;
          padding: 13px 10px 13px 14px;
          gap: 10.05px;
          width: 100%;
          .prompt-btn {
            background: #f5f4ff;
            border: 1px solid #6c5afb;
            border-radius: 8px;
            color: #6c5afb;
          }

          .highlight-prompt-display {
            color: #6c5afb;
            margin: 5px 0px;
            background: #f5f4ff;
            color: #6c5afb;
            border: 1px solid #6c5afb;
            border-radius: 5px;
            padding: 5px;
            display: inline-block;
            &:hover {
              cursor: pointer;
            }
          }
          .remove-highlight {
            background-color: transparent;
            border: none;
            padding: 5px 2px;
            display: inline;
          }
          .prompt-editable-input {
            color: #282b3f;
            &:focus-visible {
              outline: none;
            }
          }
        }
        .cai-send-btn {
          background: linear-gradient(285.83deg, #8878f9 -34.95%, #6c5afb 92.08%);
          border-radius: 8px;
          width: 56px;
          height: 56px;
          img {
            width: 21.66px;
            height: 21.66px;
          }
        }
        .paste-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding: 5px 10px;
          border: 1px solid #6c5afb;
          border-radius: 6px;
          width: 64px;
          float: right;
          cursor: pointer;

          .paste-icon {
            width: 15px;
            margin-right: 5px;
          }
          .paste-text {
            color: #6c5afb;
            font-size: 10px;
            font-family: 'Poppins-Medium';
          }
        }
      }
      .prompt-suggestions {
        .ant-collapse-header > .ant-collapse-header-text {
          flex: inherit !important ;
          margin-inline-end: inherit;
        }
        .prompt-text {
          display: flex;
          flex-direction: row;
          gap: 1rem;
          flex-wrap: wrap;
          height: 5rem;
          overflow: auto;
          .suggested-text {
            background: #f5f4ff;
            border: 1px solid #eeeeee;
            border-radius: 8px;
            padding: 8px 12px;
            color: #6c5afb;
            height: 38px;
            overflow: auto;
            white-space: nowrap;
            max-width: 14rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .history-side {
    .saved-chat {
      padding: 27px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 13px;
      border-bottom: 1.5px solid #eeeeee;
      cursor: pointer;

      .saved-chat-text {
        color: #282b3f;
        font-size: 16px;
        font-family: 'Poppins-Medium';
      }
    }
    .history-messages {
      padding: 24px 20px;
      padding-top: 0px;
      cursor: pointer;

      .history-chat {
        margin-top: 1rem;

        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        gap: 8px;
        overflow: auto;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: space-between;

        .history-text-wrapper {
          display: flex;
          align-items: center;
          .chat-img {
            width: 20px;
            margin-right: 10px;
          }
          .history-text {
            overflow: hidden;
            font-size: 14px;
            overflow-x: hidden;
          }
        }

        .history-action {
          position: absolute;
          right: 20px;
          padding: 0px 9px;
          display: none;
          img {
            width: 18px;
            cursor: pointer;
            margin: 0 5px;
          }
          background: #ffff;
        }
        .history-action-selected {
          background: #f9f8ff;
          display: flex;
        }
      }
      .history-chat-selected {
        background: #f9f8ff;
      }
    }
  }
`;

const ChatMessageStyled = styled.div`
  display: flex;
  gap: 14px;
  margin-top: 32px;
  flex-direction: row;
  flex-direction: ${(props) => props.displayType === CAI_CHAT_INPUT && 'row-reverse'};
  &:first-child {
    margin-top: 0px;
  }
  .robot-avatar {
    height: 2rem;
  }

  .text-box {
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 15px 14px 20px;
    background: #f5f4ff;
    background: ${(props) => props.displayType === CAI_CHAT_INPUT && '#ffff'};
    border: ${(props) => props.displayType === CAI_CHAT_INPUT && '1px solid #EEEEEE'};
    border-radius: 12px;
    max-width: 92%;
    font-size: 14px;

    .text-cai-chat {
      padding-right: ${(props) => props.displayType === CAI_CHAT_OUTPUT && '1rem'};
      overflow: auto;
    }
    .action-icons {
      padding-top: 5px;
      .copy-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

const PromptStyled = styled.div`
  .prompt-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1.5rem;
    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .prompt-text-heading {
      color: #282b3f;
      font-size: 20px;
      font-family: 'Poppins-SemiBold';
      margin-right: 2.5rem;
    }
    border-bottom: 1px solid #e2e4e8;
  }
  .prompt-section {
    min-height: 31rem;
    @media (max-width: 576px) {
      min-height: 100%;
    }
    .empty {
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: 31rem;
    }
    .prompt-list {
      border-right: 1px solid #e2e4e8;
      max-height: 31rem;
      min-height: 31rem;
      @media (max-width: 576px) {
        min-height: 100%;
        max-height: 100%;
      }
      overflow-x: auto;
    }
    .prompt-first {
      border-bottom: 1px solid #e2e4e8;
    }
    .prompt-suggestion-wrapper {
      padding: 12px;
      cursor: pointer;

      .prompt-suggestion-text {
        color: #282b3f;
        font-size: 14px;
        font-family: 'Poppins-Medium';
        &:hover {
          color: ${({ theme }) => theme['primary-color']};
        }
      }
    }
    .categorySelected {
      background-color: #f7f8fa;
      border-radius: 4px;
    }

    .prompt-suggestion-wrapper:first-child {
      margin-top: 1rem;
    }
    .first-list {
      margin-top: 1rem;
      @media (max-width: 576px) {
        display: none;
      }
    }
    .select-list {
      margin-top: 1rem;
      @media (min-width: 576px) {
        display: none;
      }
    }
    .sub-category {
      padding: 0 1rem;
      .second-list {
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
    .sub-category-selected {
      background: #f5f4ff;
      border-radius: 4px;
    }
    .prompt-review-wrapper {
      padding: 1rem;
      height: 100%;

      .prompt-review {
        background: #f7f8fa;
        border-radius: 10px;
        max-height: 26rem;
        min-height: 10rem;
        overflow: auto;
        height: 88%;
        padding: 1rem;
        margin-bottom: 1rem;
        .prompt-text-preview {
          color: #737277;
          font-family: 'Poppins-Medium';
          font-size: 14px;
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;

          &:hover {
            background-color: #fff;
          }
        }
        .text-preview-selected {
          background-color: rgba(206, 201, 249, 0.37) !important;
        }
        .highlight-prompt-text {
          color: #6c5afb;
        }
      }
    }
  }
`;

const DefaultCaiChatStyled = styled.div`
  .heading-cai-chat {
    text-align: center;
    .welcome {
      color: #282b3f;
      font-size: 32px;
      font-family: 'Poppins-SemiBold';
    }
    .cai-chat-heading {
      margin-left: 7px;
      color: #6c5afb;
      font-size: 32px;
      font-family: 'Poppins-SemiBold';
    }
    .sub-heading-wrapper {
      margin-top: 1rem;
      .second-heading {
        margin-top: 1rem;
      }
      .sub-heading-text {
        color: #282b3f;
        font-family: 'Poppins-Light';
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .feature-section {
    max-width: 600px;
    margin: auto;
    .feature-wrapper {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 1rem;

      .cai-chat-features {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;

        .title {
          color: #6c5afb;
          font-size: 16px;
          font-family: 'Poppins-SemiBold';
        }
        .description {
          font-size: 14px;
          font-family: 'Poppins-Light';
          font-weight: 400;
        }
      }
    }
  }
`;

export { CaiChatStyled, ChatMessageStyled, PromptStyled, DefaultCaiChatStyled };

/* ------------------------------------------------------ */
/*                       SIDEBAR DATA                     */
/* ------------------------------------------------------ */

/**
 *
 * It contains all the side bar page links with access level
 */

import Recent from '../../../static/sidebar/Recents_white.svg';
import Rewrite_white from '../../../static/sidebar/Rewriter_white.svg';
import home from '../../../static/sidebar/Home_white.svg';
import heart from '../../../static/sidebar/Heart_white.svg';
import Improver_white from '../../../static/sidebar/Improver_white.svg';
import builder_white from '../../../static/sidebar/Builder_white.svg';

import Dashboard from '../../../static/sidebar/Dashboard.svg';
import Recents from '../../../static/sidebar/Recents.svg';
import Favorites from '../../../static/sidebar/Favorites.svg';

import Rewriter from '../../../static/sidebar/Rewriter.svg';
import Improver from '../../../static/sidebar/Improver.svg';
import Builder from '../../../static/sidebar/Builder.svg';
import Social_white from '../../../static/sidebar/social_white.svg';
import Social from '../../../static/sidebar/social.svg';
import { ROLES } from '../../../constants';

// common page links for craftly admin and users
export const SidebarPopularData = [
  {
    id: 'tool/one-shot-blog',
    icon: Builder,
    iconwhite: builder_white,
    title: 'One Shot Blog',
    pageLink: '/tool/one-shot-blog',
  },
  {
    id: 'tool/sentence-rewriter',
    icon: Rewriter,
    iconwhite: Rewrite_white,
    title: 'Sentence Rewriter',
    pageLink: '/tool/sentence-rewriter',
  },
  {
    id: 'tool/content-improver',
    icon: Improver,
    iconwhite: Improver_white,
    title: 'Content Improver',
    pageLink: '/tool/content-improver',
  },
  {
    id: 'long-form',
    icon: Builder,
    iconwhite: builder_white,
    title: 'Blog Builder',
    pageLink: '/long-form',
  },
];

// common page links for craftly admin and users
export const DashboardData = [
  {
    id: 'dashboard',
    icon: Dashboard,
    iconwhite: home,
    title: 'Dashboard',
    pageLink: '/category',
  },
  {
    id: 'recent',
    icon: Recents,
    iconwhite: Recent,
    title: 'Recents',
    pageLink: '/recent',
  },
  {
    id: 'favourite',
    icon: Favorites,
    iconwhite: heart,
    title: 'Favorites',
    pageLink: '/favourite',
  },
  {
    id: 'documents',
    icon: Builder,
    iconwhite: builder_white,
    title: 'Documents ',
    pageLink: '/documents',
  },
  {
    id: 'team-portal',
    icon: Social,
    iconwhite: Social_white,
    title: 'Team',
    pageLink: '/team-portal',
  },
];

//   page links for craftly admin
export const AdminPageData = [
  {
    id: 'user-settings',
    title: 'User Settings',
    menuItems: [
      {
        icon: Builder,
        iconwhite: home,
        title: 'User Management',
        pageLink: `/user-management`,
        id: 'user-management',
      },
      {
        icon: Builder,
        iconwhite: home,
        title: 'Team Management',
        pageLink: `/team-management`,
        id: 'team-management',
      },
      {
        icon: Builder,
        iconwhite: home,
        title: 'Duplicate Cards',
        pageLink: `/duplicate-cards`,
        id: 'duplicate-cards',
      },
    ],
    icon: Builder,
    role: [ROLES.ADMIN], // only craftly admin have the access
  },

  {
    id: 'bot-moderator',
    title: 'Bot Moderator',
    menuItems: [
      {
        icon: Builder,
        iconwhite: home,
        title: 'Content Moderator',
        pageLink: '/content-moderator',
        id: 'content-moderator',
      },

      { icon: Builder, iconwhite: home, title: 'Error Board', pageLink: '/error-board', id: 'error-board' },
    ],
    icon: Builder,
    role: [ROLES.ADMIN, ROLES.STAFF], // only craftly admin and staff have the access
  },
  {
    id: 'notifications',
    title: 'Notifications',
    menuItems: [{ icon: Builder, iconwhite: home, title: 'Top Banner', pageLink: '/top-banner', id: 'top-banner' }],
    icon: Builder,
    role: [ROLES.ADMIN, ROLES.STAFF], // only craftly admin & staff have the access
  },
  {
    id: 'tool-management',
    title: 'Tool Settings',
    menuItems: [
      { icon: Builder, iconwhite: home, title: 'Tool Status', pageLink: '/tool-status', id: 'tool-status' },
      { icon: Builder, iconwhite: home, title: 'Add New Tool', pageLink: '/add-new-tool', id: 'add-new-tool' },
      {
        icon: Builder,
        iconwhite: home,
        title: 'Add Group Fields',
        pageLink: '/fields-group',
        id: 'fields-group',
      },
      { icon: Builder, iconwhite: home, title: 'Update Tool', pageLink: '/update-tool', id: 'update-tool' },
      { icon: Builder, iconwhite: home, title: 'Tools Check', pageLink: '/tools-check', id: 'tools-check' },
      { icon: Builder, iconwhite: home, title: 'Tool Category', pageLink: '/tool-categories', id: 'tools-category' },
    ],
    icon: Builder,
    role: [ROLES.ADMIN], // only craftly admin have the access
  },
  {
    id: 'plans',
    title: 'Plans',
    menuItems: [{ icon: Builder, iconwhite: home, title: 'Billing', pageLink: '/plan-billing', id: 'plan-billing' }],
    icon: Builder,
    role: [ROLES.ADMIN, ROLES.STAFF], // only craftly admin & staff have the access
  },
  {
    id: 'reports',
    title: 'Reports',
    menuItems: [{ icon: Builder, iconwhite: home, title: 'Reports', pageLink: '/reports', id: 'reports' }],
    icon: Builder,
    role: [ROLES.ADMIN, ROLES.STAFF], // only craftly admin & staff have the access
  },
];

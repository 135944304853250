/* ------------------------------------------------------ */
/*                         TopBar                         */
/* ------------------------------------------------------ */

/**
 *
 * Craftly admin notifications and User account and billing notificaitons are displayed here.
 *
 */

import React from 'react';
import { Typography } from 'antd';
import { TEAM_WORKSPACE } from '../../constants';
import {
  LIMIT_APPROACHING,
  TOP_BAR_ACCOUNT_HOLD,
  TOP_BAR_NO_ACTIVE_PLAN,
  TOP_BAR_NO_INTERNET_CONNECTION,
  TOP_BAR_NO_TEAM_PLAN_ACTIVE_TEAM_ADMIN,
  TOP_BAR_NO_TEAM_PLAN_ACTIVE_TEAM_MEMBER,
  TOP_BAR_TEAM_MEMBER_REMOVE,
  TOP_BAR_TRAIL_LEFT,
  TOP_BAR_TRIAL_PERIOD_EXPIRE,
} from '../../constants/content';
import TopBarCarousel from '../../components/TopBarCarousel';

const { Text } = Typography;

const TopBar = ({
  noTeamActivePlan,
  trialModeExpire,
  trialModeLive,
  showNoInternetBanner,
  noActivePlan,
  remainingHits,
  selectedWorkspace,
  userUID,
  topBannerNotifications,
  plan,
  showWordsLimitGettingFinish,
}) => {
  // Check the user is using app is whether team admin and team admin is on TEAM Workspace.
  const TeamAdminInTeamWorkspace = selectedWorkspace && selectedWorkspace.selectedUID === userUID;
  let topBannerMessage = '';

  // when trial mode is active and few hits are left
  if (trialModeLive && !showNoInternetBanner) {
    topBannerMessage = <>{TOP_BAR_TRAIL_LEFT(remainingHits)}</>;
  }

  // starter have limited words. it shows the limit is approaching
  if (showWordsLimitGettingFinish && !showNoInternetBanner) {
    topBannerMessage = <>{LIMIT_APPROACHING()}</>;
  }

  // Trial period expired
  if (trialModeExpire && !showNoInternetBanner) {
    topBannerMessage = <>{TOP_BAR_TRIAL_PERIOD_EXPIRE()}</>;
  }

  // when user dont have an active plan
  if (noActivePlan && !showNoInternetBanner) {
    topBannerMessage = <>{TOP_BAR_NO_ACTIVE_PLAN()}</>;
  }

  // VISIBLE only TEAM WORKSPACE
  // TEAM ADMIN:  There is no Team Plan in place. Update
  // TEAM MEMBER: Team is not active
  if (
    noTeamActivePlan &&
    !showNoInternetBanner &&
    selectedWorkspace &&
    !selectedWorkspace.teamPlanActive &&
    selectedWorkspace.workspaceType === TEAM_WORKSPACE
  ) {
    topBannerMessage = (
      <>
        {TeamAdminInTeamWorkspace
          ? TOP_BAR_NO_TEAM_PLAN_ACTIVE_TEAM_ADMIN()
          : TOP_BAR_NO_TEAM_PLAN_ACTIVE_TEAM_MEMBER()}
      </>
    );
  }

  // VISIBLE only TEAM WORKSPACE
  // Account is on hold.
  if (
    noTeamActivePlan &&
    !showNoInternetBanner &&
    selectedWorkspace &&
    selectedWorkspace.teamPlanActive &&
    selectedWorkspace.checkHoldAccount &&
    selectedWorkspace.workspaceType === TEAM_WORKSPACE
  ) {
    topBannerMessage = <>{TOP_BAR_ACCOUNT_HOLD()}</>;
  }

  // VISIBLE only TEAM WORKSPACE
  // If team member is removed from the team
  if (
    noTeamActivePlan &&
    !showNoInternetBanner &&
    selectedWorkspace &&
    selectedWorkspace.teamPlanActive &&
    !selectedWorkspace.checkUserIsStillInTeam &&
    selectedWorkspace.workspaceType === TEAM_WORKSPACE
  ) {
    topBannerMessage = <>{TOP_BAR_TEAM_MEMBER_REMOVE()}</>;
  }

  // internet connection
  if (showNoInternetBanner) {
    topBannerMessage = (
      <>
        <Text>{TOP_BAR_NO_INTERNET_CONNECTION}</Text>
      </>
    );
  }

  return (
    <TopBarCarousel plan={plan} topBannerNotifications={topBannerNotifications}>
      {topBannerMessage}
    </TopBarCarousel>
  );
};

export default TopBar;

import Search from '../../../../static/new-teams/Searching.svg';
export const ContentCategoryOne = [
  {
    image: Search,
    name: 'Users',
    input: [{ nameCat: 'Iman Bashir' }],
    text: 'Input',
  },
  {
    image: Search,
    name: 'Projects',
    input: [{ nameCat: 'Craftly' }, { nameCat: 'hello' }, { nameCat: 'Fun Writing' }],
    text: 'Content',
  },
  {
    image: Search,
    name: 'Tools',
    input: [
      { nameCat: 'Ad from Product Description' },
      { nameCat: 'Youtube Video Title' },
      { nameCat: 'Sentence Rewriter' },
      { nameCat: 'Quality, Understand, Educate, Simulate, Transition' },
      { nameCat: 'Hashtag Generator' },
      { nameCat: 'Confirmation Email' },
      { nameCat: 'Announcement Post' },
      { nameCat: 'Bullets -> Paragraph' },
    ],
    text: '',
  },
];

/* ------------------------------------------------------ */
/*                        Recent                          */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 * It shows the tool output recents,
 * The recent outputs are directly dependable to the workspace and project selection
 *
 * Every tool craft have a recentProjectId object. it goes with the selection project id
 * It obtains the preferred output that they hasFavorites object are true
 *
 * Firestore
 * Collection: users-bot
 * Document : UID
 * SubCollection: crafts
 *
 * Date Filter is very important inorder to fetch the results
 *
 *
 * There are 2 type of Recent
 * 1. Personal workspace
 * 2. Team workspace
 *
 *
 */

import React, { useState, useEffect } from 'react';
import RecentCards from './overview/RecentCards';
import RecentHeader from './overview/RecentHeader';
import { useDispatch, useSelector } from 'react-redux';
import { RecentContainer } from './style';
import { PERSONAL_WORKSPACE, TEAM_WORKSPACE } from '../../constants';
import { fetchRecentProjectsPage } from '../../redux/UserProject/actionCreator';
import PageHeader from '../../components/PageHeader';
import { fetchTeamRecentProjectsPage } from '../../redux/team/actionCreator';

const Recent = () => {
  const dispatch = useDispatch();
  const [dateRangeRecent, setDateRangeRecent] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState();
  const [displayAllRecent, setDisplayAllRecent] = useState([]);
  const { selectedWorkspace } = useSelector((state) => state.accountSettings);
  const { plan } = useSelector((state) => state.fb.profile);
  const { allRecentPage, allRecentPageLoading } = useSelector((state) => state.userProject);
  const { selectedCurrentProject } = useSelector((state) => state.userProject);
  const { currentProjectId } = selectedCurrentProject;

  useEffect(() => {
    currentProjectId &&
      dateRangeRecent &&
      Array.isArray(dateRangeRecent) &&
      dateRangeRecent.length > 0 &&
      fetchRecent();
  }, [currentProjectId, dateRangeRecent]);

  const fetchRecent = () => {
    if (selectedWorkspace && selectedWorkspace.workspaceType === PERSONAL_WORKSPACE) {
      // it fetch the recent project list on personal worksapce
      dispatch(fetchRecentProjectsPage(dateRangeRecent));
    }
    if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
      // it fetch the team project list on team workspace
      dispatch(fetchTeamRecentProjectsPage(dateRangeRecent));
    }
  };
  useEffect(() => {
    if (inputSearchValue) {
      const searchResults = allRecentPage.filter(
        ({ input, toolDisplayName }) =>
          input.includes(inputSearchValue) ||
          (toolDisplayName && toolDisplayName.toLowerCase().includes(inputSearchValue.toLowerCase())),
      );
      setDisplayAllRecent(searchResults);
    } else {
      setDisplayAllRecent(allRecentPage);
    }
  }, [allRecentPage, inputSearchValue]);

  return (
    <>
      <PageHeader hideTranslation={true} title="Recent" />
      <RecentContainer>
        <RecentHeader plan={plan} recentDateRange={setDateRangeRecent} onInputSearch={setInputSearchValue} />
        <RecentCards allRecentPageLoading={allRecentPageLoading} allRecentPage={displayAllRecent} />
      </RecentContainer>
    </>
  );
};

export default Recent;

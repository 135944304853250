/* ------------------------------------------------------ */
/*                      V2PopUpStyled                     */
/* ------------------------------------------------------ */

/**
 * V2 Video Modal shows for the new users who has Starter, Crafter or Trial plan only
 *
 * This is not visible for team members.
 *
 * if the plan is crafter the button will redirect to long form
 * or if the plan is starter or trial it redirect to billing page to upgrade plan
 *
 */

import React from 'react';
import { Col, Row, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { V2PopUpStyled } from './styled';
import { Button } from '../Button';
import { CRAFTER_V2_POINTS, STARTER_V2_POINTS } from '../../constants/content';
import { useNavigate } from 'react-router-dom';
import { CRAFTER, STARTER, TRIAL } from '../../constants';

const { Text } = Typography;
const V2Popup = ({ plan, onV2PopUpClose }) => {
  const navigate = useNavigate();

  const onActionButtonClick = () => {
    let link = '';

    if (plan === STARTER || plan === TRIAL) {
      link = '../settings/billing';
    } else if (plan === CRAFTER) {
      link = '../long-form';
    }
    onV2PopUpClose();

    navigate(link);
  };

  let buttonText = '';
  if (plan === STARTER || plan === TRIAL) {
    buttonText = 'Take me there';
  } else if (plan === CRAFTER) {
    buttonText = 'Try Long Form Blog Builder';
  }

  return (
    <V2PopUpStyled>
      <div className="v2-title">
        <Text className="first-title">You Spoke, we heard.</Text>
        <br />
        <Text className="second-title">The V2 you've been wating for</Text>
      </div>

      <div>
        <iframe
          className="v2-video"
          src="https://www.veed.io/embed/ea4487b6-b65d-45f9-b123-40d0debc8f8e"
          width="100%"
          height="350"
          frameborder="0"
          title="Craftly.AI - V2 Release"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
      </div>
      <div className="v2-plan-comparison">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <div>
              <Button size="large" block transparentwithoutline type="primary">
                Starter
              </Button>
            </div>
            <div className="v2-points">
              {[
                STARTER_V2_POINTS.map((point) => {
                  return (
                    <div className="point">
                      <CheckOutlined style={{ color: '#705BFA', fontSize: '10px', paddingRight: '1rem' }} />{' '}
                      <Text className="point-text"> {point}</Text>
                    </div>
                  );
                }),
              ]}
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div>
              <Button size="large" block type="primary">
                Crafter
              </Button>
            </div>
            <div className="v2-points">
              {[
                CRAFTER_V2_POINTS.map((point) => {
                  return (
                    <div className="point">
                      <CheckOutlined style={{ color: '#705BFA', fontSize: '10px', paddingRight: '1rem' }} />{' '}
                      <Text className="point-text"> {point}</Text>
                    </div>
                  );
                }),
              ]}
            </div>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col>
            <Button size="large" type="primary" onClick={onActionButtonClick}>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </div>
    </V2PopUpStyled>
  );
};

export default V2Popup;

/* ------------------------------------------------------ */
/*                    Tool Library Popup                  */
/* ------------------------------------------------------ */

/**
 * Methdology
 *
 * When a user clicks on the right side navigation category, a tool library popups modal appears.
 *
 * On the left side there is category list and on the right side there is a search and tool list of category
 * On the search, tool can be search on tool name and tool description.
 *
 */

import { Breadcrumb, Col, Row, Empty } from 'antd';
import React, { useState, useEffect } from 'react';
import SidebarTemplate from './sidebarTemplate';
import { CloseCircleFilled } from '@ant-design/icons';
import { ModalTool, Breadcrumbcontainer, ToolDivPopupCard } from './styled';
import { useViewport } from '../../hooks/viewPort';
import ToolLibraryCard from '../../components/ToolLibraryCard';
import { useDispatch, useSelector } from 'react-redux';
import { ALL_TOOLS, TOOL_CATEGORIES } from '../../constants';
import HeaderSearchTools from '../../components/HeaderSearchTools';
import { useNavigate } from 'react-router-dom';
import { getCategoryTools } from '../../utility/utility';
import { saveCategoryId } from '../../redux/AdminFeatures/actionCreator';

/**
 * @handleToolPopUp cancel the modal visibility
 * @menuClick check active category-id
 * @toolModalVisible check the modal visibility
 * @closeLibraryPopup when a user clicks on any tool, it redirect to the tool and the modal closes.
 *
 */

const ToolLibraryPopup = ({ handleToolPopUp, menuClick, toolModalVisible, closeLibraryPopup }) => {
  const { width } = useViewport();
  const navigate = useNavigate();

  const [displayCategories, setDisplayCategories] = useState([]);
  const [categoryTools, setCategoryTools] = useState([]);
  const [categoryToolList, setCategoryToolList] = useState([]);
  const dispatch = useDispatch();

  const { allTools, allGroupFields, selectedCategoryId, searchToolIds } = useSelector((store) => store.adminFeatures);
  useEffect(() => {
    const categories =
      allGroupFields &&
      allGroupFields.filter(({ type }) => type === TOOL_CATEGORIES).sort((a, b) => a.categoryOrder - b.categoryOrder);

    categories &&
      setDisplayCategories([{ categoryName: 'All Tools', categoryId: ALL_TOOLS, iconSmall: ALL_TOOLS }, ...categories]);
  }, [allGroupFields]);

  const activeCategoryId = selectedCategoryId || menuClick;

  useEffect(() => {
    if (activeCategoryId) {
      if (activeCategoryId === ALL_TOOLS) {
        setCategoryTools(allTools);
        setCategoryToolList(allTools);
      } else {
        const displayCategoryTools = getCategoryTools({
          displayCategories,
          selectedCategory: activeCategoryId,
          allToolsList: allTools,
        });
        setCategoryTools(displayCategoryTools);
        setCategoryToolList(displayCategoryTools);
      }
    }
  }, [activeCategoryId, displayCategories]);

  useEffect(() => {
    const displayCategoryTools =
      searchToolIds &&
      Array.isArray(searchToolIds) &&
      searchToolIds.map((toolId) => {
        return allTools.find(({ id, description }) => id === toolId.id);
      });
    setCategoryTools(displayCategoryTools);
  }, [allTools, searchToolIds]);

  const onContinueClick = (toolId) => {
    navigate(`/tool/${toolId}`);
    closeLibraryPopup();
  };

  const onCategoryIdClick = (categoryId) => {
    dispatch(saveCategoryId({ selectedCategoryId: categoryId }));
  };

  return (
    <>
      <ModalTool
        id="tool-modal"
        closeIcon={<CloseCircleFilled style={{ fontSize: '16px' }} />}
        centered={true}
        open={toolModalVisible}
        onCancel={handleToolPopUp}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div className="sidebar-menu">
          {width > 768 && (
            <SidebarTemplate
              selectedCategory={activeCategoryId}
              onCategoryClick={onCategoryIdClick}
              displayCategories={displayCategories}
            />
          )}
        </div>
        <div className="right-topup">
          <HeaderSearchTools
            selectedCategory={activeCategoryId}
            categoryToolList={categoryToolList}
            showSearchOptionsInDropdown={false}
            placeholder="Try article, press release, anything!"
          />

          {width < 993 ? (
            <Breadcrumbcontainer>
              {displayCategories.map(({ categoryName, categoryId }, i) => (
                <Breadcrumb separator=" ">
                  <Breadcrumb.Item
                    className="mobile-menu"
                    style={
                      activeCategoryId === categoryId ? { borderBottom: '2px solid #6850E9', color: '#6850E9' } : {}
                    }
                    onClick={() => onCategoryIdClick(categoryId)}
                  >
                    {categoryName}
                  </Breadcrumb.Item>
                </Breadcrumb>
              ))}
            </Breadcrumbcontainer>
          ) : null}
          <ToolDivPopupCard>
            <Row
              gutter={[
                { xs: 16, sm: 16, md: 24, lg: 24 },
                { xs: 16, sm: 16, md: 24, lg: 24 },
              ]}
            >
              {categoryTools &&
                Array.isArray(categoryTools) &&
                categoryTools.map(({ commonIcon, title, popular, newTool, description, id }, i) => (
                  <Col xs={12} sm={8} md={6}>
                    <ToolLibraryCard
                      popUp={true}
                      new={newTool}
                      popular={popular}
                      title={title}
                      commonIcon={commonIcon}
                      description={description}
                      id={id}
                      setCardClick={onContinueClick}
                    />
                  </Col>
                ))}
            </Row>
            {categoryTools && categoryTools.length === 0 && (
              <div className="empty">
                <Empty />
              </div>
            )}
          </ToolDivPopupCard>
        </div>
      </ModalTool>
    </>
  );
};

export default ToolLibraryPopup;

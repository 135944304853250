import starterPrice from '../../../../static/header/starterPrice.svg';
import crafterPrice from '../../../../static/header/crafterPrice.svg';
import enterprisePrice from '../../../../static/header/enterprisePrice.svg';
import hat from '../../../../static/header/hat.svg';
import pencil from '../../../../static/header/pencil.svg';

export const PriceData = [
  {
    key: 'Starter',
    title: 'Starter',
    desc: 'For writers who are just getting started.',
    icon: starterPrice,
    url: 'https://www.craftly.ai/pricing',
  },
  {
    key: 'Crafter',
    title: 'Crafter',
    desc: 'For writers, marketers & small businesses.',
    icon: crafterPrice,
    url: 'https://www.craftly.ai/pricing',
  },
  {
    key: 'Enterprise',
    title: 'Enterprise',
    desc: 'Custom solutions for larger teams and anyone looking to craft their dream tools.',
    icon: enterprisePrice,
    url: 'https://www.craftly.ai/pricing',
  },
];

export const CardEditData = [
  {
    key: 'edit1',
    title: 'Education',
    desc: 'How to make the most of Craftly when you’re in school.',
    icon: hat,
  },
  {
    key: 'edit2',
    title: 'Not-for-profit',
    desc: 'How to make the most of Craftly when you’re saving the world.',
    icon: pencil,
  },
];

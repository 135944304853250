/* ------------------------------------------------------ */
/*                       PAGE HEADER                      */
/* ------------------------------------------------------ */

/**
 * This Page Header appears on multiple pages.
 * It has the input and output language
 *
 * Modal languages appears in the modal
 *
 */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { INPUT_LANGUAGE, OUTPUT_LANGUAGE } from '../../constants';
import { changeProfileDetails } from '../../redux/AccountSettings/actionCreator';
import HeaderComponent from './overview/HeaderComponent';

const PageHeader = ({ title, translation, hideTranslation }) => {
  const dispatch = useDispatch();

  const { defaultOutputLang, defaultInputLang } = useSelector((state) => state.fb.profile);
  const [langOutput, setLangOutput] = useState();
  const [langInput, setLangInput] = useState();
  const [langVisible, setLangVisible] = useState(false);
  const [inputDefaultLang, setInputDefaultLang] = useState(null);
  const [outputDefaultLang, setOutputDefaultLang] = useState(null);

  // selection of the input and output languages
  useEffect(() => {
    if (defaultInputLang) {
      const { langCode, langName, CountryFlagCode } = defaultInputLang;
      setLangInput({ langCode, langName, CountryFlagCode });
    } else {
      setLangInput({ langCode: 'EN', langName: 'English', CountryFlagCode: 'US' });
    }
    if (defaultOutputLang) {
      const {
        langCode: outputLangCode,
        langName: outputLangName,
        CountryFlagCode: outputCountryFlagCode,
      } = defaultOutputLang;
      setLangOutput({ langCode: outputLangCode, langName: outputLangName, CountryFlagCode: outputCountryFlagCode });
    } else {
      setLangOutput({ langCode: 'EN', langName: 'English', CountryFlagCode: 'US' });
    }
  }, [defaultInputLang, defaultOutputLang]);

  useEffect(() => {
    if (langInput && langOutput && translation) {
      const { langCode } = langInput;
      const { langCode: langCodeOutput } = langOutput;
      translation({
        output_language: langCodeOutput,
        input_language: langCode,
      });
    }
  }, [langInput, langOutput]);

  const onLanguageClick = (langSelection, langType) => {
    const langCode = langSelection[0];
    const langName = langSelection[1];
    const CountryFlagCode = langSelection[2];
    if (langType === INPUT_LANGUAGE) {
      setLangInput({ langCode, langName, CountryFlagCode });
    } else if (langType === OUTPUT_LANGUAGE) {
      setLangOutput({ langCode, langName, CountryFlagCode });
    }
  };

  const handleVisibleChange = (visible) => {
    setLangVisible(visible);
    setInputDefaultLang(false);
    setOutputDefaultLang(false);
    if (visible === false) {
      let newValues = {};
      if (inputDefaultLang) {
        newValues['defaultInputLang'] = langInput;
      }
      if (outputDefaultLang) {
        newValues['defaultOutputLang'] = langOutput;
      }
      if (outputDefaultLang || inputDefaultLang) {
        dispatch(changeProfileDetails(newValues, true));
      }
    }
  };

  return (
    <HeaderComponent
      title={title}
      langInput={langInput}
      langVisible={langVisible}
      handleVisibleChange={handleVisibleChange}
      onLanguageClick={onLanguageClick}
      langOutput={langOutput}
      setInputDefaultLang={setInputDefaultLang}
      inputDefaultLang={inputDefaultLang}
      outputDefaultLang={outputDefaultLang}
      setOutputDefaultLang={setOutputDefaultLang}
      hideTranslation={hideTranslation}
    />
  );
};

export default PageHeader;

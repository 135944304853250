/* ------------------------------------------------------ */
/*                Category CategoryBreadcrumb             */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * The category list on web view it display in a horizontal layout.
 * on mobile view the selection category only display
 * on web view the selected category highlight and other stays the same
 */
import React from 'react';
import { MenuItemsStyled, Breadcrumbcontainer } from '../styled';
import { Typography, Breadcrumb } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useViewport } from '../../../hooks/viewPort';

const { Text } = Typography;

/**
 * @displayCategories list of categories
 * @selectedCategoryName selected category name, it is used in mobile view
 * @selectedCategory selected category id
 * @goToCategoryPage it redirect to the category page  or home page
 * @onCategoryClick on clicking the category the tool-list of that category appear
 */
const CategoryBreadcrumb = ({
  displayCategories,
  selectedCategoryName,
  selectedCategory,
  goToCategoryPage,
  onCategoryClick,
}) => {
  const { width } = useViewport();

  return (
    <MenuItemsStyled>
      {/* Mobile VIEW */}
      {width < 992 ? (
        <div className="menu-mobile-wrapper">
          <div>
            <Breadcrumb separator=">">
              <Breadcrumb.Item className="menu-category-mobile" onClick={() => goToCategoryPage()}>
                Tool Library
              </Breadcrumb.Item>
              <Breadcrumb.Item className="menu-selected-category" onClick={() => onCategoryClick(selectedCategory)}>
                {selectedCategoryName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      ) : (
        <>
          {/* Web VIEW */}
          <div className="category-menu-wrapper">
            <Text className="menu-category" onClick={() => goToCategoryPage()}>
              Tool Library
              <span>
                <RightOutlined />
              </span>
            </Text>

            <Breadcrumbcontainer>
              {displayCategories.map(({ categoryName, categoryId }, i) => (
                <Breadcrumb separator=" ">
                  <Breadcrumb.Item
                    className="category-web-menu"
                    style={
                      selectedCategory === categoryId ? { borderBottom: '2px solid #6850E9', color: '#6850E9' } : {}
                    }
                    onClick={() => onCategoryClick(categoryId)}
                  >
                    {categoryName}
                  </Breadcrumb.Item>
                </Breadcrumb>
              ))}
            </Breadcrumbcontainer>
          </div>
        </>
      )}
    </MenuItemsStyled>
  );
};

export default CategoryBreadcrumb;

import { Breadcrumb } from 'antd';
import styled from 'styled-components';

// customized antd Brumbcrumb designs.
const BreadcrumbStyled = styled(Breadcrumb)`
  padding-bottom: 5px;
  align-items: center;
  text-align: center;

  .breadcrumb-link-item {
    font-size: 13px;
    a {
      color: rgb(40, 43, 63) !important;
      cursor: pointer;
      height: 0;
      &:hover {
        background-color: transparent;
      }
    }
  }
  li:last-child {
    color: #8c49f7 !important;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
export { BreadcrumbStyled };

/* ------------------------------------------------------ */
/*                    Notification List                   */
/* ------------------------------------------------------ */

/**
 * Methadology
 * This render as a popover. This is present in the header.
 *
 * The notifications appears as a antd-popover.
 * The list consists the notifications related to teams.
 *
 * It notifies related to team PENDING, ACCEPTED, DECLINE and HOLD
 *
 * Used In
 * src/components/UserAvatar/index.jsx
 *
 */

import React, { useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { useSelector, useDispatch } from 'react-redux';
import { Popover } from '../Popover';
import Heading from '../Headings';
import { Button } from '../Button';
import {
  CANCEL_PLAN_AUTOMATICALLY,
  PERSONAL_WORKSPACE,
  TEAM_STATUS,
  TEAM_WORKSPACE,
  WITH_PLAN_STATUS,
} from '../../constants';
import {
  onWorkspaceChanged,
  setSelectedWorkSpace,
  updateTeamJoinAction,
} from '../../redux/AccountSettings/actionCreator';
import { capitalizeFirstLetter } from '../../utility/utility';
import { NotificationStyled } from './styled';

const NotificationList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // usersTeams: it consists of all the workspaces teams information
  // selectedWorkspace: it is the current workspace. it can be either personal or team

  const { usersTeams, selectedWorkspace, teamInviteActionLoading } = useSelector((state) => state.accountSettings);
  const { name, plan, planStatus } = useSelector((state) => state.fb.profile);
  const { uid } = useSelector((state) => state.fb.auth);

  useEffect(() => {
    if (selectedWorkspace && usersTeams) {
      // this checks whether the team member is still in the team or does the team still present.
      const checkTeamDetails =
        usersTeams && usersTeams.find(({ teamInviteStatus, adminUID }) => adminUID === selectedWorkspace.selectedUID);
      // if there is no team for team member or member is being removed from the team.
      if (checkTeamDetails) {
        const { adminUID, teamName } = checkTeamDetails;
        if (adminUID !== uid) {
          dispatch(
            setSelectedWorkSpace({
              selectedUID: adminUID,
              selectedName: teamName,
              workspaceType: TEAM_WORKSPACE,
            }),
          );
        }
      } else {
        dispatch(
          setSelectedWorkSpace({
            selectedUID: uid,
            selectedName: name,
            workspaceType: PERSONAL_WORKSPACE,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersTeams]);

  // this triggers when there is a change in current Workspace.
  useEffect(() => {
    const { workspaceType, teamWorkspaceTrigger } = selectedWorkspace || {
      workspaceType: null,
      teamWorkspaceTrigger: false,
    };
    workspaceType === TEAM_WORKSPACE && teamWorkspaceTrigger === true && dispatch(onWorkspaceChanged());
  }, [selectedWorkspace]);

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: '#F1F2F6',
    };
    return <div style={{ ...style, ...thumbStyle }} props={props} />;
  };

  const renderTrackVertical = () => {
    const thumbStyle = {
      position: 'absolute',
      width: '6px',
      transition: 'opacity 200ms ease 0s',
      opacity: 0,
      right: '2px',
      bottom: '2px',
      top: '2px',
      borderRadius: '3px',
    };
    return <div className="hello" style={thumbStyle} />;
  };

  const renderView = ({ style, ...props }) => {
    const customStyle = {
      marginRight: '-17px',
    };
    return <div {...props} style={{ ...style, ...customStyle }} />;
  };

  renderThumb.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  renderView.propTypes = {
    style: PropTypes.shape(PropTypes.object),
  };

  const teamDecline = ({ teamAdminUID, memberEmail }) => {
    dispatch(updateTeamJoinAction({ action: TEAM_STATUS.DECLINE, teamAdminUID, memberEmail }));
  };

  const teamAccept = ({ teamAdminUID, memberEmail }) => {
    //> when the user joins a team, and user have already subscribed to CRAFTER or STARTER plan then plan will cancel Automatically.
    const cancelSubscription = planStatus === WITH_PLAN_STATUS.ACTIVE && CANCEL_PLAN_AUTOMATICALLY.includes(plan);
    dispatch(updateTeamJoinAction({ action: TEAM_STATUS.ACCEPTED, teamAdminUID, memberEmail, cancelSubscription }));
  };

  const pendingUI = ({ teamName, adminName, adminUID, teamMemberEmail }) => {
    return (
      <Spin spinning={teamInviteActionLoading}>
        <Heading as="h5">
          <b>{capitalizeFirstLetter(adminName)}</b> invited you to join <b>{teamName}</b>.
        </Heading>
        <Row justify="space-between">
          <Col xs={12}>
            <Button
              type="secondary"
              onClick={() => teamDecline({ teamAdminUID: adminUID, memberEmail: teamMemberEmail })}
            >
              Decline
            </Button>
          </Col>
          <Col xs={12}>
            <Button type="primary" onClick={() => teamAccept({ teamAdminUID: adminUID, memberEmail: teamMemberEmail })}>
              Accept
            </Button>
          </Col>
        </Row>
      </Spin>
    );
  };

  const acceptedUI = ({ teamName, selectedUID }) => {
    return (
      <>
        <Heading as="h5">
          You have accepted the request of{' '}
          <span onClick={() => onLinkClick({ selectedUID, teamName })}>{teamName}</span> team
        </Heading>
      </>
    );
  };

  const declineUI = ({ teamName }) => {
    return (
      <>
        <Heading as="h5">
          You have declined the request of <b>{teamName}</b> team
        </Heading>
      </>
    );
  };

  const holdUI = ({ teamName }) => {
    return (
      <Heading as="h5">
        Contact <span>{teamName}</span>, your account is on hold.
      </Heading>
    );
  };

  const onLinkClick = ({ selectedUID, teamName }) => {
    dispatch(
      setSelectedWorkSpace({
        selectedUID,
        selectedName: teamName,
        workspaceType: TEAM_WORKSPACE,
      }),
    );
    navigate('/team-portal');
  };

  const content = (
    <NotificationStyled>
      <Heading as="h5" className="title">
        <span className="title-text">Notifications</span>
      </Heading>
      <Scrollbars
        autoHeight
        autoHide
        renderThumbVertical={renderThumb}
        renderView={renderView}
        renderTrackVertical={renderTrackVertical}
      >
        <ul>
          {usersTeams &&
            Array.isArray(usersTeams) &&
            usersTeams.map(({ teamName, adminName, adminUID, teamInviteStatus, teamMemberEmail }, index) => {
              return (
                <li key={index}>
                  <div className="notification-list-wrapper">
                    <div className="notification-icon  ">
                      <FeatherIcon icon="hard-drive" />
                    </div>
                    <div className="notification-content d-flex">
                      <div className="notification-text">
                        {teamInviteStatus === TEAM_STATUS.PENDING &&
                          pendingUI({ teamName, adminName, adminUID, teamMemberEmail })}
                        {teamInviteStatus === TEAM_STATUS.ACCEPTED &&
                          acceptedUI({ selectedUID: adminUID, teamName, adminName })}
                        {teamInviteStatus === TEAM_STATUS.DECLINE && declineUI({ teamName, adminName })}
                        {teamInviteStatus === TEAM_STATUS.HOLD && holdUI({ teamName, adminName })}
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </Scrollbars>
    </NotificationStyled>
  );

  return (
    <div className="notification">
      <Popover placement="bottomLeft" customContent={content} trigger="click">
        <Link to="#" className="link-style">
          <FeatherIcon icon="bell" size={20} />
        </Link>
      </Popover>
    </div>
  );
};

export default NotificationList;

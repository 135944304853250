/* ------------------------------------------------------ */
/*                        TEAM CHAT                       */
/* ------------------------------------------------------ */

/* ------------------ UNDER DEVELOPMENT ----------------- */

import React from 'react';
import TeamChat from './overview/TeamChat';

const TeamChatApp = ({ teamName, teamID }) => {
  return <TeamChat teamID={teamID} teamName={teamName} />;
};

export default TeamChatApp;

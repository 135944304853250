import styled from 'styled-components';
import { Popover } from 'antd';

const Content = styled.div`
  a,
  .span {
    display: block;
    color: #888;
    padding: 6px 12px;
    text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')};
    span {
      ${({ theme }) => (theme.rtl ? 'padding-right' : 'padding-left')}: 12px;
    }
  }
  a:hover {
    background: ${({ theme }) => theme['primary-color']}10;
    color: ${({ theme }) => theme['primary-color']};
  }
`;

const Title = styled.p`
  text-align: ${({ theme }) => (theme.rtl ? 'right' : 'left')};
  margin: 0;
`;

const PopoverStyle = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0 !important;
  }
`;

export { Content, PopoverStyle, Title };

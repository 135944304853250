/* ------------------------------------------------------ */
/*                   TEAM MEMBER LIST               */
/* ------------------------------------------------------ */

/**
 * Methodology
 *
 *  All Team members list are displayed here. The HOLD AND ACTIVE status are decided from here.
 */

import React from 'react';
import { Row, Button, Space, Modal, Tag, Menu, Dropdown, Avatar, Col, Typography, Spin } from 'antd';
import { ListMemberDetailStyled, ListMembers, ModalContentWrapper } from '../style';
import { theme } from '../../../config/theme/themeVariables';
import { capitalizeFirstLetter, firstTwoLetters } from '../../../utility/utility';
import { CloseOutlined, MoreOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { TEAM_ROLE, TEAM_STATUS } from '../../../constants';

const { Text, Title } = Typography;

const MembersList = ({
  teamMembersList,
  teamMembersLoading,
  onMemberSelect,
  selectedMember,
  wordCraftMemberList,
  teamAdminControlOnly,
  makeTeamMemberAction,
  teamQuantity,
  teamInviteActionLoading,
  searchTeamMemberValue,
}) => {
  const onMemberClick = (memberDetails) => {
    onMemberSelect(memberDetails);
  };

  // calculate words calculation.
  const getWordsByUID = (userUID) => {
    const words =
      wordCraftMemberList &&
      wordCraftMemberList.find(({ uid }) => {
        return userUID === uid;
      });

    return words?.currentTeamCraftedWords || 0;
  };

  const TeamMemberDeleteConfirmation = ({ teamMemberEmail, teamMemberUID, teamInviteStatus }) => {
    const confirmDelete = () => {
      makeTeamMemberAction({
        memberEmail: teamMemberEmail,
        action: TEAM_STATUS.REMOVE,
        teamMemberUID,
      });
      warningModal.destroy();
    };
    const warningModal = Modal.warning({
      icon: null,
      centered: true,
      closable: true,
      width: 600,
      okButtonProps: { style: { display: 'none' } },
      content: (
        <ModalContentWrapper>
          <Text className="content">
            All user data will be removed and the user will be removed from your team billing. Are you sure you'd like
            to continue?
          </Text>
          <Space className="button-action" align="center">
            <Button onClick={confirmDelete} size="large" type="primary">
              Delete User <ArrowRightOutlined />
            </Button>
          </Space>
        </ModalContentWrapper>
      ),
    });
  };

  // Dropdown on the team member to remove the member
  const menu = ({ teamMemberEmail, teamMemberUID, teamInviteStatus }) => {
    return (
      <Menu>
        <Menu.Item
          key="0"
          onClick={() => TeamMemberDeleteConfirmation({ teamMemberUID, teamMemberEmail, teamInviteStatus })}
        >
          <CloseOutlined /> <Text>Remove</Text>
        </Menu.Item>
      </Menu>
    );
  };

  // Dropdown on the team member.
  const cardActions = ({ teamMemberEmail, teamMemberUID, teamInviteStatus }) => {
    return (
      <Dropdown
        placement="bottomCenter"
        overlay={menu({ teamMemberEmail, teamMemberUID, teamInviteStatus })}
        trigger={['click', 'hover']}
      >
        <MoreOutlined rotate={90} />
      </Dropdown>
    );
  };

  // This is the condition to show team admin controls. Team admin can only REMOVE members.
  const showMemberActions = (role) => {
    return role !== TEAM_ROLE.ADMIN && teamAdminControlOnly;
  };

  return (
    <ListMembers>
      <Row style={{ marginTop: '1rem' }} gutter={25} justify="start">
        {teamMembersList &&
          Array.isArray(teamMembersList) &&
          teamMembersList
            .filter(({ teamMemberEmail, teamMemberName }) => {
              return teamMemberEmail.includes(searchTeamMemberValue) || teamMemberName.includes(searchTeamMemberValue);
            })
            .map(({ role, teamMemberName, teamMemberEmail, teamMemberUID, teamInviteStatus }) => {
              return (
                <Col xs={12} sm={12} md={8} lg={6} xxl={4}>
                  {/* <Card
                    loading={teamMembersLoading || teamInviteActionLoading}
                    // headless
                    border
                    cursor={'pointer'}
                    // 
                  > */}
                  <Spin spinning={teamMembersLoading || teamInviteActionLoading}>
                    <ListMemberDetailStyled
                      borderColorOnHOLD={teamInviteStatus === TEAM_STATUS.HOLD && theme['secondary-color']}
                    >
                      <div className="member-action">
                        <div className="avatar">
                          <Avatar
                            size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                            style={{ color: '#ffff', backgroundColor: '#9D79FD' }}
                          >
                            {firstTwoLetters(teamMemberName || teamMemberEmail)}
                          </Avatar>
                        </div>

                        {showMemberActions(role) && (
                          <div>{cardActions({ teamMemberEmail, teamMemberUID, teamInviteStatus })}</div>
                        )}
                      </div>

                      <div
                        className="member-details-wrapper"
                        onClick={() =>
                          onMemberClick({
                            teamMemberUID,
                            teamMemberEmail,
                            teamInviteStatus,
                            teamMemberName,
                          })
                        }
                      >
                        <Title className="member-name">
                          {teamMemberName ? capitalizeFirstLetter(teamMemberName) : '-'}
                        </Title>

                        <Text
                          className={teamInviteStatus !== TEAM_STATUS.HOLD && 'member-status'}
                          type={teamInviteStatus === TEAM_STATUS.HOLD ? 'danger' : 'default'}
                        >
                          {capitalizeFirstLetter(teamInviteStatus)}{' '}
                        </Text>

                        <Text className="phoneNumber">{capitalizeFirstLetter(role)}</Text>
                        <Text className="email">{teamMemberEmail}</Text>

                        <div className="member-counts">
                          <div className="member-words">
                            <Text className="words-title">Words:</Text>
                            <Text>
                              <Tag color="#9D79FD">{getWordsByUID(teamMemberUID)}</Tag>{' '}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </ListMemberDetailStyled>
                  </Spin>
                  {/* </Card> */}
                </Col>
              );
            })}
      </Row>
    </ListMembers>
  );
};

export default MembersList;

export const crafterPlanFeatures = {
  title: 'For writers and small businesses',
  features: [
    'Access to AI Image Generator',
    'Everything in Starter +',
    'Long-form Tools',
    'Text Editor and Document Drive ',
    'Command Tool',
    'Access to Teams',
    'Priority Support and Onboarding',
  ],
};

export const starterPlanFeatures = {
  title: 'For individuals getting started ',
  features: ['100+ Expert Trained Tools', 'Continuous Optimization ', '25+ Languages', 'Live Support'],
};

import actions from './actions';

const initialState = {
  deleteLoading: false,
  teamDeletedSuccessfully: false,
  mirrorTeamUID: null,
  teamList: [],
};
const {
  DELETE_TEAM_ERR,
  DELETE_TEAM_RESET,
  DELETE_TEAM_START,
  DELETE_TEAM_SUCCESS,
  SAVE_TEAM_LIST,
  MIRROR_TEAM_UID,
} = actions;

const teamReducer = (state = initialState, action) => {
  const { type, mirrorTeamUID, teamList } = action;

  switch (type) {
    case DELETE_TEAM_START:
      return {
        ...state,
        deleteLoading: true,
        teamDeletedSuccessfully: false,
      };
    case DELETE_TEAM_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        teamDeletedSuccessfully: true,
      };
    case DELETE_TEAM_ERR:
      return {
        ...state,
        deleteLoading: false,
      };
    case DELETE_TEAM_RESET:
      return {
        ...state,
        teamDeletedSuccessfully: false,
      };
    case MIRROR_TEAM_UID:
      return {
        ...state,
        mirrorTeamUID,
      };
    case SAVE_TEAM_LIST:
      return {
        ...state,
        teamList,
      };
    default:
      return state;
  }
};

export { teamReducer };

import Styled from 'styled-components';

const CustomStepContent = Styled.div`

    @media only screen and (max-width: 574px) {
        padding: 3rem 1rem 0rem 1rem; 

    } 
    .title {
        font-family: "Poppins-SemiBold";
        margin: 95px 0 0;
        margin-bottom: 0.5rem;
        @media only screen and (max-width: 574px) {
            font-size: 26px; 
        } 
    }   
    .question {
        margin-top: 2rem; 
        .label-onboarding{
            font-size: 14px;
            font-family: "Poppins-Regular";
            .required-staric {
                margin-left: 1px;
                color: rgb(231, 76, 60);
              }
            }
        } 
        .name-onboarding-input {
            height: 3rem;
            display: block;
            width: 100%;
            font-size: 1rem;
            padding: 0.75rem 1rem;
            border-radius: 0.25rem;
            border: 1px solid rgb(178, 178, 178);
            background-color: transparent;
            cursor: auto;
            color: rgb(46, 46, 46);
             &:focus {
                outline: currentcolor none medium;
                border-color: rgb(40, 40, 40);
                box-shadow: none;
            }
        }
    }
    .statement {
        font-size: 15px;
        font-Family: "Poppins-Light";
        color: ${({ theme }) => theme['light-grey-color']} !important;
     }
    .options {
        cursor: pointer;
        margin-top: 2rem;
        .CardRow{
            row-gap: 10px !important;
        }
        .label-onboarding{
            font-size: 14px;
            font-family: "Poppins-Regular";
            .required-staric {
                margin-left: 1px;
                color: rgb(231, 76, 60);
              }
            }
        }
        .ant-row{
            margin-bottom: 1rem;
        }
        .selected {
            border: 1px solid #9979fd !important;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
        }
        .ant-card-bordered{
                border: 1px solid  rgb(206, 206, 206);
                background-color: rgb(255, 255, 255); 
                border-radius: 10px;  

            &:hover {  
                border: 1px solid #9979fd;
                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
            }
        }
        .ant-card-body{
            padding: 12px 24px !important;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: center;
            .image-icon{ 
                margin-right: 15px; 
            } 
            .noselect {
                -webkit-touch-callout: none; /* iOS Safari */
                  -webkit-user-select: none; /* Safari */
                   -khtml-user-select: none; /* Konqueror HTML */
                     -moz-user-select: none; /* Old versions of Firefox */
                      -ms-user-select: none; /* Internet Explorer/Edge */
                          user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
            }
            .option-text { 
                font-family: 'Poppins-Medium';
            }
        } 
    }
`;

const CustomStepWizard = Styled.div`
    width: 100%;
    .steps-content-wrapper {
        height:100%;
        .actions {  
            display: flex; 
            justify-content: space-between;
            width: 100%;
             
            @media screen and  (max-width: 440px){
                flex-direction: column;
            }
            .onboarding-logo {
                width: 10%; 
                margin: 1.5rem; 
                @media screen and  (max-width: 2560px){
                    width: 8%; 
                    margin: 2rem;
                }
                @media screen and  (max-width: 1500px){
                    width: 11%; 
                    margin: 2rem;
                }
                @media  screen and (max-width: 1024px){
                    width: 16%; 
                    margin: 2rem;
                    margin-left: 1rem;
                }
                @media  screen and (max-width: 884px){
                    width: 19%; 
                    margin: 2rem;
                    margin-left: 1rem;
                }
                @media screen and  (max-width: 570px){
                    width: 27%; 
                    margin: 2rem;
                    margin-left: 1rem;

                }
                @media screen and  (max-width: 569px){
                    width: 25%; 
                    margin: 1rem;
                    margin-left: 0rem;
                }
                @media screen and  (max-width: 440px){
                    width: 30%; 
                    margin: 1rem;
                    margin-left: 0rem;
                } 
            }
            .onboarding-signout {
                float: right;
                margin: 1rem;  
                @media (max-width: 352px){
                    margin-right: 0  
                }
                @media (min-width: 769px){
                    
                    margin: 2rem;
                }
                @media screen and  (max-width: 440px){
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }
        @media (max-width:800px) {
            padding: 0 1.5rem
        }
    }
    .steps-wrapper{
        width: 100%;
        max-width: 490px;
        margin-bottom: auto;
        margin-left: auto;
        margin-right auto;
        margin-top: 3rem;
     }
    .steps-content{
        margin-top: 0 !important;
        justify-content: flex-start !important;
    }
    .step-action-wrap{
        .step-action-inner{
            padding: 0 !important;
        }
    } 
   
    
    .atbd-finish-order{
        max-width: 100%;
    }  
`;

export { CustomStepWizard, CustomStepContent };

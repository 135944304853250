import styled from 'styled-components';

const CaiRephraseWrapper = styled.div`
  .rephrase-top {
    border-bottom: 1px solid #eeee;
    padding-bottom: 1rem;
    margin-top: 1.2rem;
    .breadcrumb-ui {
      padding: 0 14px;
    }
    .modes-wrapper {
      padding: 0px 24px;
      .modes-text {
        color: #282b3f;
        font-size: 14px;
        font-family: 'Poppins-SemiBold';
      }
      .mode-selection {
        margin-top: 10px;
        .mode-selection-text {
          font-size: 12px;
          font-family: 'Poppins-Medium';
          color: #282b3f;
        }
      }
      .rephrase-header-summary {
        @media (max-width: 768px) {
          display: none;
        }
      }
      @media (max-width: 768px) {
        padding: 0px;
      }
    }
  }
  .tool-section {
    .skeleton-loading {
      padding: 2rem;
    }
    .input-section {
      border-right: 1px solid #eeee;
      height: ;
      .input-header {
        display: flex;
        padding: 28px 14px 5px;

        justify-content: space-between;
        .field-title {
          font-family: 'Poppins-SemiBold';
          font-size: 14px;
          .required-staric {
            color: red;
            padding: 0px 5px;
          }
        }
        .count-words {
          .field-count {
            font-size: 10px;
            line-height: 15px;
          }
        }
      }
      .craft-btn {
        .paste-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding: 5px 10px;
          border: 1px solid #6c5afb;
          border-radius: 6px;
          width: 64px;
          float: right;
          cursor: pointer;

          .paste-icon {
            width: 15px;
            margin-right: 5px;
          }
          .paste-text {
            color: #6c5afb;
            font-size: 10px;
            font-family: 'Poppins-Medium';
          }
        }
        position: sticky;
        bottom: 0;
        padding: 1rem;
        background: white;
      }
    }
    .output-section {
      padding: 28px 14px;
      display: flex;
      flex-direction: column;

      .rephrase-summary-output {
        display: flex;
        justify-content: end;
        border-bottom: 1px solid #e2e4e8;
        padding-bottom: 1rem;
        @media (min-width: 768px) {
          display: none;
          border: 0;
          padding-bottom: 0;
        }
      }

      .rephrase-wrapper {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        /* font-size: 16px; */
        font-family: 'Poppins-Regular';
        margin-bottom: 5px;
        line-height: 175%;
        .user-original-input {
          color: rgba(0, 0, 0, 0.25) !important;
          /* margin-top: 17px; */
          background-image: linear-gradient(225deg, rgba(0, 0, 0, 0.25) 67%, #6954e2 100%);
          /* background-image: linear-gradient(-225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100%); */
          background-size: auto auto;
          background-clip: border-box;
          background-size: 200% auto;
          color: #fff;
          background-clip: text;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: textclip 2s linear infinite;
          animation-direction: normal;
          display: inline-block;
        }
        @keyframes textclip {
          from {
            background-position: 200% center;
          }
        }

        .rephrase-craft {
          cursor: pointer;
          top: 20px;
          left: 90px;
          min-width: 185px;
          transform: translate(-50%, 0);
          background-color: white;
          position: absolute;
          z-index: 99999999;
          box-sizing: border-box;
          border: 1px solid rgb(222, 225, 227);
          border-radius: 2px;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 22px 0px;
          display: none;
        }

        .word-tooltip {
          display: inline-block;
          position: relative;
          color: rgb(227, 107, 0);
          text-align: left;
          cursor: pointer;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 1.43;
          color: rgb(37, 37, 37);
          text-decoration: underline solid rgb(255, 221, 46) 2px;
          -webkit-text-decoration-color: rgb(255, 221, 46);
          text-underline-offset: 6px;
          &:hover {
            background: #978cfb26;
          }
        }
      }

      @media (max-width: 768px) {
        border-top: 1px solid #e2e4e8;
        margin-top: 1rem;
      }
    }
  }
`;

const AlternativeStyled = styled.div`
  max-height: 10rem;
  overflow: auto;
  .alternate-word-hover {
    padding: 5px 1rem;
    &:hover {
      background-color: rgb(241, 241, 241);
      cursor: pointer;
    }
  }
`;

const RephraseBoxStyled = styled.div`
  overflow: auto;
  margin-bottom: 10px;
  min-height: 10rem;
  margin-top: 12px;

  .rephrase-summary {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    .action-rephrase-icon {
      width: 18px;
      margin: 0 13px;
      &:hover {
        color: #6c5afb;
      }
    }
    .statistic-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      padding: 5px 10px;
      border: 1px solid #6c5afb;
      border-radius: 6px;
      float: right;
      cursor: pointer;

      .statistic-icon {
        width: 15px;
        margin-right: 5px;
      }
      .statistic-text {
        color: #6c5afb;
        font-size: 10px;
        font-family: 'Poppins-Medium';
      }
    }
  }
  .animationWrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .loading-image {
      width: 100%;
      border-radius: 10px;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #eeee #fff !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(238, 238, 238, 0.928) !important;
    border-radius: 15px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    /* background: blue;  */
  }

  .no-content-craft {
    display: flex;
    justify-content: start;
  }

  .rephrase-craft {
    display: flex;
    align-items: start;
    gap: 15px;

    @media (max-width: 768px) {
      margin: 12px 12px 16px 12px;
    }

    .craft-wrapper {
      width: 100%;
    }
  }
`;

const ActionSummaryStyled = styled.div`
  .rephrase-summary {
    background: #ffffff;
    border: 1.5px solid #eeeeee;
    border-radius: 49px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;
    padding: 2px 8px;
    font-size: 12px;
    color: #848484;
    position: relative;
    top: -62px;
    @media (max-width: 768px) {
      top: -40px;
    }
    .dot {
      width: 4px;
      height: 4px;
      background: #282b3f;
      border-radius: 50%;
      margin: 0 7px;
    }
  }
  .delete-rephrase {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    gap: 10px;
    background: #eeeff2;
    border-radius: 6px;
    flex-direction: row;
    cursor: pointer;
    float: right;

    .delete-text {
      font-size: 16px;
      color: #737277;
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }
    .delete-icon {
      color: #737277;
      width: 20px;
    }
    @media (max-width: 768px) {
      width: 100px;
      margin-top: -17px;
      padding: 4px 12px;
    }
  }
`;

export { CaiRephraseWrapper, AlternativeStyled, RephraseBoxStyled, ActionSummaryStyled };

/* ------------------------------------------------------ */
/*                   Chat Message Block                   */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * the user and tool output display here.
 * it renders as a message layout.
 * user input is on the right side
 * tool output is on the left side.
 *
 * output have the copy feature.
 *
 */

import { Avatar } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { ChatMessageStyled } from '../styled';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { breakLineForCopy, breakLineIfRequired, htmlToText } from '../../../utility/utility';
import { CheckOutlined } from '@ant-design/icons';
import { CAI_CHAT_INPUT, CAI_CHAT_OUTPUT } from '../../../constants';
import { maskBadWords } from '../../../utility/filterWords';
import { ThreeDots } from 'react-loader-spinner';
import { theme } from '../../../config/theme/themeVariables';
import copyImg from '../../../static/tool-page/paper.svg';
import logoIcon from '../../../static/general/step-robot-icon.png';

/**
 * @loading loading time to get the repsonse from openai
 * @text the message text either of input or output
 * @displayType  differentiate the layout on bases of text type CAI_CHAT_OUTPUT or CAI_CHAT_INPUT
 * @setCopySuccess func to get the message-id, to replace the copy icon
 * @copySuccess state of copy message-id. It is used to compare the message copy id and index id
 * @index the messages id
 * @contentId
 *
 */
const ChatMessage = ({ loading, text, displayType, setCopySuccess, copySuccess, index, contentId }) => {
  const { name, picture } = useSelector((state) => state.fb.profile);
  const url = picture || '';

  console.log('contentId', contentId);
  const onCopyIconClick = (messageId) => {
    // messageAlert({ type: 'info', textMessage: 'Text Copied' });
    setCopySuccess(messageId);
  };

  let displayContentDataOutput = '';
  if (text) {
    const { lines } = breakLineIfRequired(maskBadWords(text));
    displayContentDataOutput = lines;
  }

  return (
    <ChatMessageStyled displayType={displayType}>
      {displayType === CAI_CHAT_INPUT ? (
        <Avatar
          style={{ backgroundColor: '#9785ff', color: '#fff' }}
          src={<img src={url} alt={name.charAt(0).toUpperCase()} />}
          size={40}
          shape="circle"
        />
      ) : (
        <img className="robot-avatar" style={{ height: '3rem' }} src={logoIcon} alt={name.charAt(0).toUpperCase()} />
      )}
      <div className="text-box">
        {/* loading 3 dots  */}
        <div className="text-cai-chat">
          <ThreeDots
            height="20"
            width="60"
            radius="5"
            color={theme['primary-color']}
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loading}
          />
        </div>

        {!loading && text && (
          <div id={contentId} className={`text-cai-chat`}>
            {displayContentDataOutput}
          </div>
        )}

        {displayType === CAI_CHAT_OUTPUT && (
          <div className="action-icons">
            <>
              <CopyToClipboard text={htmlToText(breakLineForCopy(text))} onCopy={() => onCopyIconClick(index)}>
                {copySuccess === index ? (
                  <CheckOutlined style={{ color: 'black' }} />
                ) : (
                  <img className="copy-icon" src={copyImg} alt="copy-img" />
                )}
              </CopyToClipboard>
            </>
          </div>
        )}
      </div>
    </ChatMessageStyled>
  );
};

export default ChatMessage;

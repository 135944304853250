/* --------------- UNDER DVELOPMENT FOR V2 -------------- */

import { Collapse, Typography } from 'antd';
import React from 'react';
import User from '../../../../static/new-teams/User.svg';
import { Button } from '../../../../components/Button';
import AddUser from '../../../../static/new-teams/AddUser.svg';
import { SidebarMenuInput, MemberMobileStyle } from './styled';
const MemberMobileSearch = () => {
  const { Panel } = Collapse;
  const { Text } = Typography;
  return (
    <MemberMobileStyle>
      <div>
        <SidebarMenuInput>
          <Collapse defaultActiveKey={['1']}>
            <Panel
              header={
                <>
                  <img src={User} alt="User" className="imageuser" />
                  <Text>All roles</Text>
                </>
              }
              key="1"
            ></Panel>
          </Collapse>
        </SidebarMenuInput>
      </div>
      <div className="sidemenu">
        <Button size="default" type="primary">
          <div>
            <img src={AddUser} alt="Add new member" className="image-button" />
            Add new member
          </div>
        </Button>
      </div>
    </MemberMobileStyle>
  );
};

export default MemberMobileSearch;

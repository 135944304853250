/* ------------------------------------------------------ */
/*                       Learn Popup                      */
/* ------------------------------------------------------ */

/**
 * Shows multiple options for learning
 */

import { Card, Col, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { CardDataStyle, LearnPopupModalFooter, LearnPopupModalStyle, ModalLearnPopup } from './styled';
import ArrowRight from '../../../../static/header/ArrowRight.svg';
import ArrowRightHover from '../../../../static/header/ArrowRightHover.svg';
import { LongPopupData } from './data';
import { Button } from '../../../Button';

const LearnPopup = () => {
  const { Text, Title } = Typography;
  const [hover, setHover] = useState({ state: true, cardKey: null });

  const handleHoverIn = (cardKey) => {
    setHover({ state: true, cardKey });
  };

  const handleHoverOut = (cardKey) => {
    setHover({ state: true, cardKey });
  };

  const redirect = (url) => {
    url
      ? window.open(url, '_blank', 'noopener,noreferrer')
      : window.open('mailto:hello@craftly.ai?subject=Help', '_self');
  };

  return (
    <ModalLearnPopup>
      <LearnPopupModalStyle>
        <Row gutter={[16, 24]}>
          {LongPopupData.map(({ key, image, title, data, url }) => (
            <Col span={12} key={`learn-${key}`}>
              <Card
                onClick={() => redirect(url)}
                hoverable
                onMouseOver={() => handleHoverIn(key)}
                onMouseOut={() => handleHoverOut(key)}
              >
                <img src={image} alt="Blog" />
                <CardDataStyle>
                  <div className="data">
                    <Title level={5}>{title}</Title>
                    <Text>{data}</Text>
                  </div>
                  <img src={hover.state && key === hover.cardKey ? ArrowRightHover : ArrowRight} alt="ArrowRight" />
                </CardDataStyle>
              </Card>
            </Col>
          ))}
        </Row>
      </LearnPopupModalStyle>
      <LearnPopupModalFooter>
        <div className="data">
          <Text className="getting-started">Getting Started</Text>
          <Text className="starting-statement">Start crafting original content in seconds now! </Text>
        </div>
        <Button size="medium" type="primary">
          Learn More
        </Button>
      </LearnPopupModalFooter>
    </ModalLearnPopup>
  );
};

export default LearnPopup;

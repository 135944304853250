import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Popover } from '../Popover';
import Heading from '../Headings';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { HelpStyled } from './styled';

const Help = () => {
  const content = (
    <HelpStyled>
      <div className="setting-dropdwon">
        <Row gutter="10">
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../static/help/document.png')} alt="" />
              <figcaption>
                <Heading as="h5">Contact</Heading>
                <p>Call us, beep us, if you want to reach us</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../static/help/color-palette.png')} alt="" />
              <figcaption>
                <Heading as="h5">Affiliate With Us</Heading>
                <p>Share your love of Craftly and get paid</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../static/help/home.png')} alt="" />
              <figcaption>
                <Heading as="h5">Join our Community</Heading>
                <p>Find like minded crafters</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../static/help/video-camera.png')} alt="" />
              <figcaption>
                <Heading as="h5">Craftly Academy </Heading>
                <p>Become a Craftly expert with our guides</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../static/help/documentation.png')} alt="" />
              <figcaption>
                <Heading as="h5">Documentation</Heading>
                <p>Learn from the content pros</p>
              </figcaption>
            </figure>
          </Col>
          <Col sm={12}>
            <figure className="setting-dropdwon__single d-flex">
              <img src={require('../../static/help/microphone.png')} alt="" />
              <figcaption>
                <Heading as="h5">Submit Feedback</Heading>
                <p>Love us? Hate us? Let us know</p>
              </figcaption>
            </figure>
          </Col>
        </Row>
      </div>
    </HelpStyled>
  );

  return (
    <>
      <Popover placement="bottomRight" content={content} trigger="click">
        <Link to="#" className="link-style">
          <QuestionCircleOutlined style={{ fontSize: '18px', paddingTop: '5px' }} />
        </Link>
      </Popover>
    </>
  );
};

export default Help;

import actions from './actions';

const initialState = {
  documentList: [],
  documentListLoading: false,
  lastDocumentLoading: false,
  lastDocumentData: null,
  readDocumentById: [],
  readDocumentByIdLoading: false,
};

const {
  DOCUMENT_READ_FAILED,
  DOCUMENT_READ_SUCCESS,
  DOCUMENT_READ_START,
  DOCUMENT_READ_BY_ID_START,
  DOCUMENT_READ_BY_ID_SUCCESS,
  DOCUMENT_READ_BY_ID_FAILED,
} = actions;

const FeaturesReducer = (state = initialState, action) => {
  const { type, documentList, readDocumentById } = action;
  switch (type) {
    case DOCUMENT_READ_START:
      return {
        ...state,
        documentList: [],
        documentListLoading: true,
        lastDocumentLoading: true,
      };
    case DOCUMENT_READ_SUCCESS:
      return {
        ...state,
        documentList,
        documentListLoading: false,
        lastDocumentLoading: false,
        lastDocumentData: documentList && documentList.length > 0 && documentList[0],
      };
    case DOCUMENT_READ_FAILED:
      return {
        ...state,
        documentList: [],
        documentListLoading: false,
        lastDocumentData: null,
      };

    case DOCUMENT_READ_BY_ID_START:
      return {
        ...state,
        readDocumentById: [],
        readDocumentByIdLoading: true,
      };
    case DOCUMENT_READ_BY_ID_SUCCESS:
      return {
        ...state,
        readDocumentById,
        readDocumentByIdLoading: false,
      };
    case DOCUMENT_READ_BY_ID_FAILED:
      return {
        ...state,
        readDocumentById: [],
        readDocumentByIdLoading: false,
      };

    default:
      return state;
  }
};

export default FeaturesReducer;

import styled from 'styled-components';

const NewEarnWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: 768px) {
    gap: 20px;
  }
`;

const NewEarnHeaderMain = styled.div`
  padding: 18px 23px 0px;
  @media (max-width: 768px) {
    padding: 14px 16px 0px;
  }

  h4.ant-typography {
    line-height: 30px;
    color: #282b3f;
    font-weight: 500!important;
    font-family: 'Poppins-Semibold';
    font-size: 28px;
    margin-bottom: 4px;
    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  .ant-typography {
    font-family: 'Poppins-Regular';
    font-size: 14px;
    line-height: 18px;
    color: #737277;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;
const NewEarnContainer = styled.div`
  display: flex;
  gap: 24px;
  padding: 24px 24px 33px;
  @media (max-width: 768px) {
    display: flex;
    gap: 20px;
    padding: 16px 16px 96px;
    flex-direction: column;
  }

  h4.ant-typography {
    line-height: 30px;
    color: #282b3f;
    font-weight: 500!important;
    font-family: 'Poppins-Semibold';
    font-size: 20px;
    margin-bottom: 4px;
    @media (max-width: 768px) {
      font-size: 18px;
    }
  }

  .ant-typography {
    font-family: 'Poppins-Regular';
    font-size: 12px;
    line-height: 18px;
    color: #737277;
  }
`;

const ShareLinkWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  @media (max-width: 768px) {
    padding: 15px 15px;
  }
  gap: 24px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 15px;

  .partA {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .ant-input {
      padding: 14px 10px;
      border-radius: 8px;
    }

    .ant-input::placeholder {
      font-size: 12px;
      line-height: 16px;
      color: #8189a2;
      font-family: 'Poppins-Regular';
    }

    .icon-images {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;

      img {
        width: 44px;
        height: 44px;
        border: 1px solid #e2e4e8;
        border-radius: 50px;
      }
      img:hover {
        border: 1px solid #6c5afb;
        box-shadow: 0px 9px 20px -5px rgba(95, 103, 117, 0.25);
      }
      .tIcon,
      .iIcon {
        padding: 10px;
      }
    }
  }

  .partB {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .ant-card-bordered {
      border: none;
    }

    .ant-card-body {
      display: flex;
      flex-direction: column;
      padding: 0px;
      gap: 4px;
      justify-content: center;
      align-items: center;
      height: 52px;

      .ant-row {
        gap: 32px;
      }

      .ant-typography {
        font-size: 16px;
        color: #282b3f;
        line-height: 24px;
        font-family: 'Poppins-Regular';
      }
    }

    button {
      height: 48px;
    }
  }
`;

const FeatureUsWrap = styled.div`
  margin-bottom: 20px;
`;

const FeatureUsContainer = styled.div`
  display: flex;
  align-items: center;

  .last {
    margin-right: 10px;
    margin-bottom: 20px;
  }

  .dotted:after {
    content: '';
    position: absolute;
    left: 35%;
    height: 250%;
    border-left: 2px dashed #ffd732;
    
    @media (min-width: 1242px) {
      height: 208%;
    }
    @media (min-width: 1180px) and (max-width: 1242px) {
      height: 220%;
    }
    @media (min-width: 924px) and (max-width: 991px) {
      height: 212%;
    }
    @media (max-width: 768px) {
      height: 177%;
    }
  }

  .dotted {
    position: relative;
    width: 50px;
    margin-bottom: 20px;
  }

  figure {
    margin: 0;
  }

  .ant-card {
    box-shadow: none;
    border: none;
    width: 100%;
    margin-bottom: 24px;
    @media (max-width: 768px) {
      margin-bottom: ${props => props.lastCard === 'submit' && '15px'};
    }
  }
  .ant-card-body {
    padding: 10px 20px !important;
    background: #ffffff;
    border: 1px solid #eeeeee;

    border-radius: 15px;
    &:hover {
      box-shadow: 0px 25px 35px -20px rgba(95, 103, 117, 0.2);
    }
  }
  .ant-avatar-image {
    width: 62px;
    height: 62px;
    @media (max-width: 768px) {
      width: 55px;
      height: 55px;
    }
  }
  .ant-card-meta-description {
    font-family: Poppins-Medium;
    color: #282b3f;
    @media (max-width: 768px) {
      font-size: 12px;
    }
    .ant-typography {
      font-size: 14px;
      font-family: Poppins-Medium;
      color: #282b3f;
      @media (max-width: 768px) {
        font-size: 12px;
      }
      a {
        color: #6c5afb;
      }
    }
  }
  .ant-card-meta {
    align-items: center;
  }
`;

const FeatureUsContent = styled.div``;

const FeatureMain = styled.div`
  padding: 24px 20px 0px;
  @media (max-width: 768px) {
    padding: 15px 15px 0px;
  }

  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 15px;
`;

const ChatEarnWrap = styled.div`
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  padding: 24px 0px 24px 20px;
  @media (max-width: 768px) {
    padding: 15px 0px 15px 15px;
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ChatEarnHeading = styled.div`
  margin-right: 20px;
  @media (max-width: 768px) {
    margin-right: 15px;
  }
`;

const ChatDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24.5px;
  height: 760px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right : 16px;
  @media (max-width: 768px) {
    margin-right : 15px;
  }
  

  .ChatEarnCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px !important;
    @media (max-width: 815px) {
      display: block;
      margin-bottom: 0px !important;
    }
    @media screen and (min-width: 815px) and (max-width: 768px) {
      display: block;
      margin-bottom: 0px !important;
    }
    @media screen and (min-width: 990px) and (max-width: 1095px) {
      display: block;
      margin-bottom: 0px !important;
    }

  }

  ::-webkit-scrollbar-thumb {
    height: 300px;
    background-color: #eeeeee;
    border: 5px solid rgba(238, 238, 238, 0.5);
  }
  
  ::-webkit-scrollbar {
    width: 5px;
  }


.ant-card-bordered {
  border: none;
  padding: 20px 20px 0 !important;
  @media only screen and (max-width: 768px) {
    padding: 15px 10px 0px !important;
  }
}

.ant-card-body {
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  margin-right: 12px;
  padding: 0px !important;
  @media (max-width: 768px) {
    margin-right: 10px;
    // display: flex;
    // flex-direction: column;
  }

  .data {
    display: flex;
    gap: 14px;
    flex-direction: row;
    align-items: center;
    // @media only screen and (min-width: 1095px) {
    //   margin-bottom: 20px !important;
    // }

    .img {
      width: 32px;
      height: 32px;
    }

    .site {
      gap: 4px !important;
      padding: 4.5px 4.5px 0;

      h5.ant-typography {
        margin-bottom: 0;
        color: #282b3f;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500!important;
        font-family: 'Poppins-Medium';
      }

      .freeDays {
        font-weight: 400;
        font-family: 'Poppins-Regular';
        @media (max-width: 768px) {
          line-height: 16px;
        }
      }
    }
  }
  .link {
    float: right;
    // @media only screen and (max-width: 1095px) {
    //   margin-bottom: 10px;
    // }
    @media (max-width: 815px) {
      margin-bottom: 10px;
    }
    @media screen and (min-width: 815px) and (max-width: 768px) {
      margin-bottom: 10px;
    }
    @media screen and (min-width: 990px) and (max-width: 1095px) {
      margin-bottom: 10px;
    }

    .ant-typography {
      font-family: 'Poppins-Regular';
      @media (max-width: 768px) {
        line-height: 16px;
      }
    }
  }
}

.ant-card-body:hover {
  box-shadow: 0px 23.4967px 36.7135px -23.4967px rgba(95, 103, 117, 0.25);
  border-bottom: 1.5px solid #6c5afb;

  .link {
    .ant-typography {
      color: #6c5afb;
      font-family: 'Poppins-Regular';
    }
    img {
      color: red;
    }
  }
}
`;

export {
  NewEarnWrap,
  ShareLinkWrap,
  FeatureUsWrap,
  ChatEarnWrap,
  FeatureUsContainer,
  FeatureUsContent,
  FeatureMain,
  NewEarnContainer,
  NewEarnHeaderMain,
  ChatDataWrap,
  ChatEarnHeading,
};

/* ------------------------------------------------------ */
/*                     FavouriteModal                     */
/* ------------------------------------------------------ */

import React from 'react';
import { FavouriteModalStyle, ModalFavHeader, Modalfav, ModalFavFooter } from './styled';
import { Typography } from 'antd';
import Heart from '../../../static/favourite/Heart.svg';
import Paper from '../../../static/favourite/Paper.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { breakLineForCopy, htmlToText } from '../../../utility/utility';
import { messageAlert } from '../../../components/utilities/messages';

const FavouriteModal = ({ handleToolPopUp, toolModal, favouriteData }) => {
  const { displayDate, toolIcon, inputFields, toolDisplayName, lines, contentData, words, len } = favouriteData || '';
  const { Title, Text } = Typography;
  return (
    <>
      <Modalfav
        className="favourite-modal"
        centered={true}
        open={toolModal}
        onCancel={handleToolPopUp}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <FavouriteModalStyle>
          <ModalFavHeader>
            <div className="fav-left-header">
              <img src={toolIcon} alt="tool-icon" />
              <Title level={5} className="Modal-fav-title">
                {toolDisplayName}
              </Title>
            </div>
            <Text level={5} className="Modal-fav-date">
              {displayDate}
            </Text>
          </ModalFavHeader>
          <div className="favourite-input-output">
            <div className="display-output">
              <Text className="heading-text">Input </Text>
              {inputFields &&
                inputFields.length > 0 &&
                inputFields.map(({ name, value }) => {
                  return (
                    <div>
                      <Text className="heading-text"> {name} </Text>
                      <Text className="display-data"> {value} </Text>
                    </div>
                  );
                })}
            </div>
            <div className="display-output">
              <Text className="heading-text">Output:</Text>
              <Text className="display-data"> {lines}</Text>
            </div>
          </div>
          <ModalFavFooter>
            <div className="favourite-bottom">
              <div className="favchar-words">
                <Text>{len} Chars </Text>
                <span className="dot-fav">•</span>
                <Text>{words} Words </Text>
              </div>
            </div>
            <div className="favIcon">
              <img alt={'fav'} src={Heart} />
              <CopyToClipboard
                text={htmlToText(breakLineForCopy(contentData))}
                onCopy={() => messageAlert({ type: 'info', textMessage: 'Text Copied' })}
              >
                <img alt={'copy'} src={Paper} />
              </CopyToClipboard>
            </div>
          </ModalFavFooter>
        </FavouriteModalStyle>
      </Modalfav>
    </>
  );
};

export default FavouriteModal;

/* ------------------------------------------------------ */
/*                       Tool Check                       */
/* ------------------------------------------------------ */

/* ----------------- CRAFTLY ADMIN PAGE ----------------- */

/**
 * It is used to check the status of the every tool.
 */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Form, Switch, Radio, Typography, List } from 'antd';
import { Main } from '../styled';
import { Cards } from '../../components/Card';
import { Button } from '../../components/Button';
import axios from 'axios';
import { ToolsCheckWrapper } from './styled';
import { capitalizeFirstLetter } from '../../utility/utility';
import PageTitle from '../../components/PageTitle';

const { Text, Title } = Typography;
const ToolsCheck = () => {
  const { allTools: toolList } = useSelector((store) => store.adminFeatures);
  const liveTools = toolList.filter(({ isVisible }) => isVisible === true);
  const [loading, setLoading] = useState(false);
  const [toolCheckResponse, setToolCheckResponse] = useState(null);
  const [toolResponse, setToolResponse] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      language_check: false,
    });
  }, [form]);

  const handleSubmit = (values) => {
    setLoading(true);
    axios
      .post(`https://us-central1-craftly-d50ba.cloudfunctions.net/craftly_tool_test`, {
        ...values,
      })
      .then((res) => {
        setLoading(false);
        // setToolCheckResponse(res.data);
        const { outputs, error, number_of_tools } = res.data;
        setToolResponse({ numberOfTools: number_of_tools, error: error });
        const outputFormat = outputs.map((item) => {
          return {
            time_taken: item[0].time_taken,
            tool: item[0].tool,
            tool_output: item[0].tool_output,
            error: item[0].error,
          };
        });
        setToolCheckResponse(outputFormat);
        return outputFormat;
      })
      .catch((err) => {
        console.log(`err`, err.message);
        setLoading(false);
      });
  };
  const description = (item) => {
    return (
      <>
        <Text>
          Output:
          <Text type="success" style={{ marginLeft: '10px', color: '#9D79FD' }}>
            {item.tool_output}
          </Text>
        </Text>
        <br />
        <br />
        <Text type="danger"> Error: {item.error}</Text>
      </>
    );
  };

  return (
    <>
      <PageTitle
        title="Tools"
        subtitle={
          <div>
            <span className="title-counter">Total Tools: </span>
            <span style={{ marginLeft: '0rem' }}>{toolList.length} </span>
            <br />
            <span className="title-counter">Live Tools: {liveTools.length} </span>
          </div>
        }
      />

      <Main>
        <ToolsCheckWrapper>
          <Row gutter={15}>
            <Col md={24}>
              <Cards headless={true}>
                <Row justify="start">
                  <Col xl={24} lg={24} xs={24}>
                    <Form
                      labelCol={{ span: 2 }}
                      wrapperCol={{ span: 16 }}
                      form={form}
                      layout="horizontal"
                      name="billing-settings"
                      onFinish={handleSubmit}
                    >
                      <Form.Item name="tool_type" label="Type" required>
                        <Radio.Group>
                          <Radio.Button value="social">Social</Radio.Button>
                          <Radio.Button value="ecommerce">Ecommerce</Radio.Button>
                          <Radio.Button value="business">Business</Radio.Button>
                          <Radio.Button value="writing">Writing</Radio.Button>
                          <Radio.Button value="blog">Blog</Radio.Button>
                          <Radio.Button value="emails">Emails</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item name="website" label="Website" required>
                        <Radio.Group>
                          <Radio.Button value="production">Production </Radio.Button>
                          <Radio.Button value="staging">Staging</Radio.Button>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item name="language_check" label="Language" valuePropName="checked">
                        <Switch checked={false} />
                      </Form.Item>
                      <Form.Item>
                        <Button loading={loading} size="default" htmlType="submit" type="primary">
                          Check Tool
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
                {toolCheckResponse && (
                  <List
                    itemLayout="horizontal"
                    dataSource={toolCheckResponse}
                    split={false}
                    header={<div>Total Number of Tools: {toolResponse.numberOfTools}</div>}
                    footer={<div>Error: {toolResponse.error}</div>}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          title={<Title level={4}>{capitalizeFirstLetter(item.tool)}</Title>}
                          description={description(item)}
                        />
                      </List.Item>
                    )}
                  />
                )}
              </Cards>
            </Col>
          </Row>
        </ToolsCheckWrapper>
      </Main>
    </>
  );
};

export default ToolsCheck;

import { WITH_PLAN_STATUS } from '../../constants';
import actions from './actions';

const {
  BOTS_READ_BEGIN,
  BOTS_READ_SUCCESS,
  BOTS_READ_ERROR,
  BOTS_USERNAME_READ_SUCCESS,
  BOTS_TOOL_READ_SUCCESS,
  BOTS_INPUT_READ_SUCCESS,
  BOTS_OUTPUT_READ_SUCCESS,
  CHANGE_PROFILE_DATA,
  CHANGE_PROFILE_DATA_ERROR,
  CHANGE_PROFILE_DATA_SUCCESS,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER,
  CREATE_NEW_USER_FAILED,
  GET_SEARCH_USERS_SUCCESS,
  BOTS_OUTPUT_SUMMARY,
  ADMIN_USER_DELETE_PROGRESS,
  ADMIN_USER_DELETE_SUCCESS,
  ADMIN_USER_DELETE_FAILED,
  GET_ALL_TOOLS_SUCCESS,
  GET_ALL_TOOLS_PROGRESS,
  GET_FILTERED_USERS_SUCCESS,
  GET_FILTERED_USERS_PROGRESS,
  USERS_BOTS_START,
  USERS_BOTS_SUCCESS,
  USERS_BOTS_FAILED,
  ALL_FINGERPRINTS,
  APP_SETTINGS,
  APP_SETTINGS_LOADING,
  GET_ALL_GROUP_FIELDS,
  TOP_BANNER_NOTIFICATIONS_SUCCESS,
  TOP_BANNER_NOTIFICATIONS_START,
  FETCH_USER_DETAILS_START,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILED,
  SAVE_UID_FOR_FETCHING,
  SAVE_FILTERS_USER_MANAGEMENT,
  GET_TOTAL_ROWS_USER_MANAGEMENT,
  GET_USERS_BOTS_DATE,
  GET_USERS_BOTS_DATE_LOADING,
  GET_USERS_BOTS_AVAILABLE_DATES,
  SEARCH_TOOLS,
  SELECTED_CATEGORY_ID,
  SELECTED_TOOL_PAGE_ID,
} = actions;

const initialState = {
  savedBots: null,
  botDates: null,
  botsInput: null,
  botsUserName: null,
  botsToolName: null,
  botsOutput: null,
  loading: false,
  error: null,
  changeProfileLoading: false,
  changeProfileComplete: false,
  createUserLoading: false,
  searchUsers: [],
  botsSummary: null,
  userDeleteByAdminLoading: false,
  allTools: [],
  allToolsLoading: true,
  filteredUsers: [],
  filteredUsersLoading: false,
  userBots: [],
  userBotsLoading: false,
  blockNow: false,
  appSettings: {
    enableOnboardingWithoutCreditCard: false,
  },
  appSettingLoading: false,
  allGroupFields: [],
  topBannerNotifications: [],
  topBannerLoading: false,
  fetchUserDetails: null,
  fetchUserDetailsLoading: null,
  saveUIDForFetching: null,
  userManagmentFilters: {
    roles: undefined,
    status: [WITH_PLAN_STATUS.ACTIVE, WITH_PLAN_STATUS.APPROVED],
    plan: undefined,
  },
  totalUsersRows: 0,
  userManagementRowsLimit: 100,
  userBotsDate: [],
  userBotsDateLoading: false,
  userAvailableDates: [],
  searchToolIds: [],
  selectedCategoryId: null,
  selectedToolPageId: null,
};

const adminFeatures = (state = initialState, action) => {
  const {
    type,
    savedBots,
    botDates,
    botsToolName,
    botsOutput,
    botsUserName,
    botsInput,
    searchUsers,
    allTools,
    err,
    botsSummary,
    filteredUsers,
    filteredUsersLoading,
    userBots,
    blockNow,
    appSettings,
    appSettingLoading,
    allGroupFields,
    topBannerNotifications,
    fetchUserDetails,
    saveUIDForFetching,
    userManagmentFilters,
    totalUsersRows,
    userBotsDate,
    userBotsDateLoading,
    userAvailableDates,
    searchToolIds,
    selectedCategoryId,
    selectedToolPageId,
  } = action;
  switch (type) {
    case BOTS_READ_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case BOTS_READ_SUCCESS:
      return {
        ...state,
        savedBots,
        botDates,
        botsUserName: null,
        botsToolName: null,
        botsInput: null,
        error: false,
        loading: false,
      };
    case BOTS_READ_ERROR:
      return {
        ...state,
        error: err,
        loading: false,
      };

    case BOTS_USERNAME_READ_SUCCESS:
      return {
        ...state,
        botsUserName,
        botsToolName: null,
        botsInput: null,
        botsOutput: null,
      };
    case BOTS_TOOL_READ_SUCCESS:
      return {
        ...state,
        botsToolName,
        botsInput: null,
        botsOutput: null,
      };
    case BOTS_INPUT_READ_SUCCESS:
      return {
        ...state,
        botsInput,
        botsOutput: null,
      };
    case BOTS_OUTPUT_READ_SUCCESS:
      return {
        ...state,
        botsOutput,
      };
    case BOTS_OUTPUT_SUMMARY:
      return {
        ...state,
        botsSummary,
      };

    case GET_FILTERED_USERS_SUCCESS:
      return {
        ...state,
        filteredUsers: filteredUsers,
      };

    case GET_FILTERED_USERS_PROGRESS:
      return {
        ...state,
        filteredUsersLoading,
      };

    case GET_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        searchUsers: searchUsers,
      };
    case CHANGE_PROFILE_DATA:
      return {
        ...state,
        changeProfileLoading: true,
        changeProfileComplete: false,
      };

    case CHANGE_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        changeProfileLoading: false,
        changeProfileComplete: true,
      };
    case CHANGE_PROFILE_DATA_ERROR:
      return {
        ...state,
        changeProfileLoading: false,
        changeProfileComplete: true,
      };

    case CREATE_NEW_USER:
      return {
        ...state,
        createUserLoading: true,
      };

    case CREATE_NEW_USER_SUCCESS:
      return {
        ...state,
        createUserLoading: false,
      };
    case CREATE_NEW_USER_FAILED:
      return {
        ...state,
        createUserLoading: false,
      };
    case GET_ALL_TOOLS_SUCCESS:
      return {
        ...state,
        allTools,
        allToolsLoading: false,
      };
    case GET_ALL_TOOLS_PROGRESS:
      return {
        ...state,
        allToolsLoading: true,
      };

    case ADMIN_USER_DELETE_PROGRESS:
      return {
        ...state,
        userDeleteByAdminLoading: true,
      };

    case ADMIN_USER_DELETE_SUCCESS:
      return {
        ...state,
        userDeleteByAdminLoading: false,
      };
    case ADMIN_USER_DELETE_FAILED:
      return {
        ...state,
        userDeleteByAdminLoading: false,
      };

    case USERS_BOTS_START:
      return {
        ...state,
        userBots: [],
        userBotsLoading: true,
      };

    case USERS_BOTS_SUCCESS:
      return {
        ...state,
        userBots,
        userBotsLoading: false,
      };
    case USERS_BOTS_FAILED:
      return {
        ...state,
        userBots: [],
        userBotsLoading: false,
      };

    case ALL_FINGERPRINTS:
      return {
        ...state,
        blockNow,
      };
    case APP_SETTINGS:
      return {
        ...state,
        appSettings,
        appSettingLoading: false,
      };
    case APP_SETTINGS_LOADING:
      return {
        ...state,
        appSettingLoading,
      };
    case GET_ALL_GROUP_FIELDS:
      return {
        ...state,
        allGroupFields,
      };
    case TOP_BANNER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        topBannerNotifications,
        topBannerLoading: false,
      };
    case TOP_BANNER_NOTIFICATIONS_START:
      return {
        ...state,
        topBannerLoading: true,
      };

    case FETCH_USER_DETAILS_START:
      return {
        ...state,
        fetchUserDetailsLoading: true,
        fetchUserDetails: null,
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchUserDetailsLoading: false,
        fetchUserDetails,
      };
    case FETCH_USER_DETAILS_FAILED:
      return {
        ...state,
        fetchUserDetailsLoading: false,
        fetchUserDetails: null,
      };
    case SAVE_UID_FOR_FETCHING:
      return {
        ...state,
        saveUIDForFetching,
        fetchUserDetails: null,
      };

    case SAVE_FILTERS_USER_MANAGEMENT:
      return {
        ...state,
        userManagmentFilters,
      };
    case GET_TOTAL_ROWS_USER_MANAGEMENT:
      return {
        ...state,
        totalUsersRows,
      };
    case GET_USERS_BOTS_DATE:
      return {
        ...state,
        userBotsDate,
        userBotsDateLoading: false,
      };
    case GET_USERS_BOTS_DATE_LOADING:
      return {
        ...state,
        userBotsDateLoading,
      };
    case GET_USERS_BOTS_AVAILABLE_DATES:
      return {
        ...state,
        userBotsDate: [],
        userAvailableDates,
        userBotsDateLoading: false,
      };
    case SEARCH_TOOLS:
      return {
        ...state,
        searchToolIds,
      };
    case SELECTED_CATEGORY_ID:
      return {
        ...state,
        selectedCategoryId,
      };
    case SELECTED_TOOL_PAGE_ID:
      return {
        ...state,
        selectedToolPageId,
      };

    default:
      return state;
  }
};

export { adminFeatures };

/**
 * This Routes decide to which the user should be go
 * For team member JOIN TEAM would be visible
 * For normal User trial page will be visible
 */

import { Spin } from 'antd';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import TrialV2 from '../container/Trial/Trialv2';
import TrialV1 from '../container/Trial/Trial';
import { checkUserPresentInTeam } from '../redux/AccountSettings/actionCreator';
import Styled from 'styled-components';
import JoinTeam from '../container/JoinTeam';
import { currentPricingModel } from '../utility/stripePlan';

// Currently we do have two pricing plans.
// 1. fixed pricing
// 2. Tier pricing
const { v1PriceModal, v2PriceModal } = currentPricingModel();
const Trial = v1PriceModal ? TrialV1 : v2PriceModal ? TrialV2 : null;

const ConditionalRouteWrapper = Styled.div`
    min-height: 100vh;
  .loading-time-spinner {
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 100vh
  }
`;

// this component calls when there is no route to match. user inputs an incorrect url
const ConditionalPage = () => {
  const dispatch = useDispatch();
  const { usersTeams } = useSelector((state) => state.accountSettings);
  const { email } = useSelector((state) => state.fb.profile);
  const { uid } = useSelector((state) => state.fb.auth);

  useEffect(() => {
    dispatch(checkUserPresentInTeam());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, uid]);

  const checkTeams = usersTeams && Array.isArray(usersTeams) && usersTeams.length > 0 && true;
  console.log('checkTeams', checkTeams);

  if (usersTeams === null) {
    return (
      <ConditionalRouteWrapper>
        <div className="loading-time-spinner">
          <Spin />
        </div>
      </ConditionalRouteWrapper>
    );
  } else if (checkTeams) {
    return <Navigate to="/join-team" />;
  } else if (checkTeams === false) {
    return <Navigate to="/trial" />;
  }
};

const AfterSignInRoutes = () => {
  return (
    <Routes>
      <Route path="/trial" element={<Trial />} />
      <Route path="/join-team" element={<JoinTeam />} />
      <Route exact path="*" element={<ConditionalPage />} />
    </Routes>
  );
};

export default AfterSignInRoutes;

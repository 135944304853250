import { Popover } from 'antd';
import styled from 'styled-components';

const PopoverStyled = styled(Popover)``;

const HeaderWrapStyled = styled.div`
  .ant-popover .ant-popover-content {
    background: pink !important;
    .ant-popover-inner {
      background: pink;
    }
  }
  border-bottom: 1px solid #eeeeee;
  height: 70px;
  background: #fff;
  margin-top: ${(props) => (props.showTopBanner ? '44px' : '0px')};

  .craftly-logos {
    width: 97.57px;
    margin-top: 4px;
    cursor: pointer;
  }
  .ant-row {
    .ant-row {
      @media (max-width: 767px) {
        width: 100%;
        padding: 0 16px;
      }
    }
  }

  .top-header {
    width: calc(100% - 256px);
    @media (max-width: 767px) {
      display: none;
    }
  }

  .ant-col {
    border-right: 1px solid #eeeeee;
    height: 70px;
    .head-example {
      padding: 0px;
      margin: 0 10px;
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        margin: 0px;
      }
      .ant-avatar-image {
        margin: 0 14px 0 0;
        width: 41px;
        height: 41px;
        @media (max-width: 767px) {
          margin: 0px;
        }
      }
    }
    .notification {
      .head-example {
        width: 24px;
        height: 24px;
      }
    }
  }

  .logo {
    display: flex;
    width: 256px;
    align-items: center;
    gap: 16px;
    padding: 24px 27px;
    button {
      padding: 0;
    }

    @media (max-width: 767px) {
      border-right: none;
      padding: 0;
      justify-content: space-between;
    }
  }

  .pricing-learn {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 42px;
    justify-content: center;
    @media (max-width: 767px) {
      display: none;
    }

    .pricing,
    .learn {
      cursor: pointer;
      gap: 3%;
      display: flex;
      img {
        margin-left: 5px;
      }
      padding: 7px 15px !important;
      @media (max-width: 1260px) and (min-width: 992px) {
        padding: 0px;
      }

      &:hover {
        background: #e1e4e7;
        border-radius: 6px;
      }
    }

    .ant-typography {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: #282b3f;
    }
  }
  .ant-input-affix-wrapper-lg {
    padding: 0px 6px;
    height: 59px;
    vertical-align: middle;
  }
  .ant-input-affix-wrapper:focus {
    box-shadow: 0 0 0 0 rgb(108 90 251 / 20%);
  }
  .ant-input-prefix {
    margin-right: 0;
    width: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-input-affix-wrapper > input.ant-input {
    padding: 0 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    /* color: #bfbfbf; */
    align-items: center;
  }

  .head-example {
    color: #848484;
  }

  .ant-input-affix-wrapper {
    border: none;
  }

  .info {
    padding: 22px;
  }
`;

export { HeaderWrapStyled, PopoverStyled };

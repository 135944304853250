import { Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

// Footer of the app
const AppFooter = () => {
  return (
    <Text
      style={{
        color: 'rgba(106, 114, 127, 0.702)',
        fontFamily: 'Poppins-Regular',
        marginTop: '3rem',
        marginbottom: '1rem',
      }}
    >
      © 2021 Craftly.ai. All rights reserved.
    </Text>
  );
};

export default AppFooter;

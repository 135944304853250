/* ------------------------------------------------------ */
/*                      Add New Tool                      */
/* ------------------------------------------------------ */

/* ----------------- CRAFTLY ADMIN PAGE ----------------- */

/**
 * Craftly admin can create new tool. This creates the page layout according to the tool data.
 *
 * Firestore:
 * Collection: tools
 * Document : <tool-ID>  // this tool id is  also depends on the backend-phyton-ai
 * object:
 *        id: it is very important toolID.
 *        description: tool description
 *        desplayToolName: tool name
 *        groupId: it is used to get the dropdown list
 *        icon : string
 *        img: string
 *        inVisible: whether it should be visible to users or not.
 *        maxToken: maxToken is from ai developers
 *        popular: popular flag
 *        popularOrder: popular order to show tools
 *        showInNav: show the tool is left side navigation
 *        title: tool title
 *        beta: beta flag
 *        commonIcon: many tools have the same icon.
 *        fields: [{ characterLimit: 48, key, maxRows, name, optional, placeholder, subtitle, toolTipText, type }]
 *
 *
 * fields: It is an object array containing all the required fields to render a tool page.
 * All the tools are design to be develop from here.
 * Tool id is very important to interact with backend-phyton-api
 *
 * Tool name and description is used to search any tool
 *
 *
 *
 */

import React, { useState } from 'react';
import {
  Row,
  Col,
  Card,
  InputNumber,
  Form,
  Input,
  Button,
  // Upload,
  // message,
  // Progress,
  Space,
  Switch,
  Select,
} from 'antd';
import { Main } from '../styled';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { AddNewToolWrapper } from './styled';
import { getFirestore } from 'redux-firestore';
import { addNotificationError, addNotificationInfo } from '../../components/utilities/notifications';
import PageTitle from '../../components/PageTitle';

const AddNewTool = () => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  // const [onUploadStart, setOnUploadStart] = useState(false);
  // const [onUploadSuccess, setOnUploadSuccess] = useState(false);
  // const [onUploadError, setOnUploadError] = useState(false);
  // const [uploadProgress, setUploadProgress] = useState(0);
  // const [imageOnlineLink, setImageOnlineLink] = useState();
  // const [defaultFileList, setDefaultFileList] = useState([]);

  const db = getFirestore();
  // const storage = getFirebase().storage();

  const onFinish = (values) => {
    const toolFields = { ...values, img: 'new-tool.jpg', icon: 'SmileOutlined' };

    setLoading(true);
    try {
      db.collection('tools')
        .add(
          {
            ...toolFields,
          },
          { merge: true },
        )
        .then(() => {
          addNotificationInfo('Tool Added');
          setLoading(false);
        });
    } catch (err) {
      addNotificationError(err.message);
      setLoading(false);
      console.log(`err`, err);
    }
  };

  // const normFile = e => {
  //   console.log('Upload event:', e);
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };
  // const handleOnChange = ({ file, fileList, event }) => {
  //   // console.log(file, fileList, event);
  //   //Using Hooks to update the state to the current filelist
  //   setDefaultFileList(fileList);
  // };

  return (
    <>
      <PageTitle title="Add New Tool" />

      <Main>
        <AddNewToolWrapper>
          <Row gutter={15}>
            <Col md={24}>
              <Form
                className={'add-new-form'}
                layout="vertical"
                form={form}
                name="add-new-tool"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                  isVisible: false,
                  commonIcon: null,
                  fields: [
                    {
                      characterLimit: '',
                      key: '',
                      name: '',
                      placeholder: '',
                      toolTipText: null,
                      subtitle: null,
                      optional: false,
                    },
                  ],
                }}
              >
                <Form.Item
                  name="title"
                  label="Tool Name"
                  rules={[{ required: true, message: 'Tool Name is required' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item name="id" label="Tool ID" rules={[{ required: true, message: 'Tool ID is required' }]}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: 'Tool Description is required' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="maxToken"
                  label="Max Token"
                  rules={[{ required: true, message: 'Max Token is required' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="commonIcon" label="Common Icon">
                  <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="isVisible" label="Visible to Public">
                  <Switch defaultChecked={false} value={false} />
                </Form.Item>

                <Form.List name="fields">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field) => (
                        <Card
                          size="small"
                          extra={
                            fields.length > 1 && (
                              <>
                                <MinusCircleOutlined
                                  style={{ fontSize: '16px', color: 'red', marginRight: '0.5rem' }}
                                  onClick={() => remove(field.name)}
                                />
                                Remove Field
                              </>
                            )
                          }
                        >
                          <Space key={field.key} align="baseline">
                            <Form.Item
                              {...field}
                              label="Name"
                              name={[field.name, 'name']}
                              fieldKey={[field.fieldKey, 'name']}
                              rules={[{ required: true, message: 'Name is required' }]}
                            >
                              <Input />
                            </Form.Item>

                            <Form.Item
                              {...field}
                              label="Key"
                              name={[field.name, 'key']}
                              fieldKey={[field.fieldKey, 'key']}
                              rules={[{ required: true, message: 'Key is required' }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label="Character Limit"
                              name={[field.name, 'characterLimit']}
                              fieldKey={[field.fieldKey, 'characterLimit']}
                              rules={[{ required: true, message: 'Character Limit is required' }]}
                            >
                              <InputNumber style={{ width: '200px' }} />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label="Placeholder"
                              name={[field.name, 'placeholder']}
                              fieldKey={[field.fieldKey, 'placeholder']}
                              rules={[{ required: true, message: 'Placeholder is required' }]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label="Information Text"
                              name={[field.name, 'toolTipText']}
                              fieldKey={[field.fieldKey, 'toolTipText']}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              label="Subtitle"
                              name={[field.name, 'subtitle']}
                              fieldKey={[field.fieldKey, 'subtitle']}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Type"
                              name={[field.name, 'type']}
                              fieldKey={[field.fieldKey, 'type']}
                              rules={[{ required: true, message: 'Type is required' }]}
                            >
                              <Select style={{ width: '200px' }}>
                                <Select.Option value="textarea">Textarea</Select.Option>
                                <Select.Option value="input">Input</Select.Option>
                                <Select.Option value="slider">Slider</Select.Option>
                                <Select.Option value="radio">Toggle</Select.Option>
                                <Select.Option value="engine-select">Engine's Dropdown</Select.Option>
                                <Select.Option value="number-of-output">Number of Output's Dropdown</Select.Option>
                                <Select.Option value="model-radio-button">Radio Buttons</Select.Option>
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="Field Optional"
                              name={[field.name, 'optional']}
                              fieldKey={[field.fieldKey, 'optional']}
                            >
                              <Switch />
                            </Form.Item>
                          </Space>
                        </Card>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() =>
                            add({
                              optional: false,
                              toolTipText: null,
                              subtitle: null,
                            })
                          }
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Fields
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>

                {/* <Form.Item name="upload" label="Upload" valuePropName="fileList" getValueFromEvent={normFile} extra=" ">
                <Upload
                  maxCount={1}
                  listType="picture"
                  name="file"
                  onChange={handleOnChange}
                  defaultFileList={defaultFileList}
                  //eslint-disable-next-line no-undef
                  customRequest={data => {
                    const { onSuccess, onError, file, onProgress } = data;

                    const ref = getFirebase()
                      .storage()
                      .ref('tools')
                      .child(`${new Date().getTime()}`);

                    const task = ref.put(file);
                    task.on(
                      getFirebase().storage.TaskEvent.STATE_CHANGED,
                      snapshot => {
                        const progress = Math.round((100 * snapshot.bytesTransferred) / snapshot.totalBytes);
                        setOnUploadStart(true);
                        console.log(`progress`, progress);
                        onProgress({ percent: progress });

                        setUploadProgress(progress);
                      },
                      error => {
                        // Handle error during the upload
                        onError({ error });
                      },
                      () => {
                        task.snapshot.ref.getDownloadURL().then(downloadURL => {
                          setImageOnlineLink(downloadURL);
                          console.log(`object`, downloadURL);
                          return downloadURL;
                        });
                        onSuccess('Ok');
                        setOnUploadSuccess(true);
                        setOnUploadStart(false);
                      },
                    );
                  }}
                >
                  <Button icon={<UploadOutlined />}>Upload (Max: 1)</Button>

                  <Progress type="line" percent={uploadProgress} />
                </Upload>
              </Form.Item> */}

                <Form.Item>
                  <Button loading={loading} type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </AddNewToolWrapper>
      </Main>
    </>
  );
};

export default AddNewTool;

import styled from 'styled-components';
import { Slider } from 'antd';

const IconWrapper = styled.div`
  position: relative;
  padding: 0px 30px;
  .anticon {
    position: absolute;
    top: -2px;
    width: 16px;
    height: 16px;
    line-height: 1;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.25);
  }
  .anticon:first-child {
    left: 0;
  }
  .anticon:last-child {
    right: 0;
  }
`;

const SliderStyle = styled(Slider)`
  .ant-tooltip .ant-tooltip-inner {
    box-shadow: none;
    border: none;
  }
  .ant-slider-mark {
    top: 25px;
    left: 14px;
    span:last-child {
      left: 86% !important;
    }
    .ant-slider-mark-text,
    .ant-slider-mark-text-active {
      color: ${({ theme, railColor }) => (railColor ? '#ffff' : '#8189a2')};
    }
  }
  .ant-tooltip-arrow-content {
    background-color: black !important;
  }

  .ant-slider-handle {
    margin-top: -6px;
    width: 26px;
    height: 26px;
    background-color: #58dfd7;
    border: 4px solid white !important;
    border-radius: 1rem;
  }
  .ant-slider-handle::before {
    content: none;
  }
  .ant-slider-handle::after {
    content: none;
  }

  .ant-slider-rail {
    height: 12px;
    border-radius: 14px;
    background: ${({ theme, railColor }) =>
      railColor ? theme[railColor] : theme['gray-slider-rail-color']} !important;
  }

  .ant-slider-track {
    height: 12px;
    border-radius: 14px;
    background-color: ${({ theme, trackColor }) =>
      trackColor ? theme[trackColor] : theme['primary-color']} !important;
  }

  .ant-slider-step {
    display: none;
  }
`;

export { IconWrapper, SliderStyle };

import styled from 'styled-components';

const SidebarContainer = styled.div`
  padding-left: 19px;
  height: 100% !important;
  .ant-menu .ant-menu-item > span {
    color: ${({ theme }) => theme['black']} !important;
    font-size: 12px;
    font-family: 'Poppins-Regular';
    @media (max-width: 991px) {
      color: #fff !important;
    }
  }

  button.ant-btn-default {
    color: ${props => (props.width < 992 ? '#fff !important' : '#848484 !important')};
    font-size: 12px;
    font-family: 'Poppins-Regular';
  }
  .sidebar_inner_scroll {
    height: 'calc(100vh - 90px)';
    padding-bottom: '50px';
  }

  button.ant-btn-default {
    bottom: 0px;
    background: transparent;
    padding: 0;
    @media (max-width: 991px) {
      color: #fff;
    }
  }

  .stickers {
    display: flex;
    justify-content: center;
  }
`;

const UpgradeProWrap = styled.div`
  margin-left: -15px;
  margin-bottom: 16px;
  width: 211px;
  height: 224px;
  background: linear-gradient(149.51deg, #ffffff -7.43%, #e9effa 117.25%);
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  padding: 1rem;
  img {
    width: 102.3px;
    height: 93.99px;
  }

  .ant-typography {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    align-items: center;
    text-align: center;
    color: #282b3f;
    margin-top: 8px;
    margin-bottom: 7px;
  }
  .ant-btn > span {
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
`;

const SidebarWrap = styled.div`
  padding: 12px 0px 10px;

  .ant-menu {
    display: flex;
  }

  .ant-menu .ant-menu-item {
    padding: 0px 0px !important;
    height: auto;
    font-weight: 400;
    line-height: inherit;
    margin: 7px 0;
  }
  .icon-image {
    margin-right: 12px;
    width: 17px !important;
    align-items: center;
    display: flex;
  }
`;

const TitleContainer = styled.div`
  .dropdown {
    width: 18px;
    height: 18px;
  }
  display: flex;
  justify-content: space-between;
  width: calc(100% - 22px);
  .ant-typography {
    color: #9979fd;
    font-family: 'Poppins-Regular';
    font-size: 12px;
  }
`;

const SidebarMenuStyle = styled.div`
  .ant-collapse {
    border: none;
    padding: 8px 0;
    background: transparent !important;
  }
  .ant-collapse-content {
    border: none;
    @media (max-width: 991px) {
      background-color: transparent;
    }
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px !important;

    .scroller-menu {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: calc(50vh - 106px);
      margin-right: 21px;

      @media screen and (min-height: 310px) and (max-height: 600px) {
        max-height: calc(50vh - 26px);
      }
      @media screen and (min-height: 601px) and (max-height: 649px) {
        max-height: calc(50vh - 200px);
      }
      @media screen and (min-height: 650px) and (max-height: 780px) {
        max-height: calc(50vh - 85px);
      }
      @media screen and (min-height: 781px) and (max-height: 800px) {
        max-height: calc(50vh - 140px);
      }

      ::-webkit-scrollbar-thumb {
        background-color: #eeeeee;
        border: 5px solid rgba(238, 238, 238, 0.5);
        border-radius: 5px;
      }
      ::-webkit-scrollbar {
        width: 5px;
      }
    }
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    justify-content: space-between;
    flex-direction: row-reverse;
    background: #fff;
    padding: 0px;
    border: none;
    @media (max-width: 991px) {
      background: transparent;
    }
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 10px;
    position: absolute;
    margin-right: 15px;
    font-size: 12px;
    vertical-align: -1px;
    svg {
      @media (max-width: 991px) {
        fill: #fff;
      }
    }
  }
  .ant-collapse-header-text {
    color: #978cfb;
    font-family: 'Poppins-Medium';
    font-size: 13px;
    @media (max-width: 991px) {
      color: #fff;
    }
  }
`;

export { SidebarContainer, SidebarWrap, TitleContainer, SidebarMenuStyle, UpgradeProWrap };

/* ------------------------------------------------------ */
/*                          PAUSE                         */
/* ------------------------------------------------------ */

/**
 * Craftly admin has the access to pause the user account
 */

import React from 'react';
import { PauseWrapper } from './styled';
import Signout from '../../components/Signout';
import { Typography, Row, Col } from 'antd';

const { Text, Title } = Typography;

const Pause = () => {
  return (
    <PauseWrapper>
      <Row justify="space-between">
        <Col xs={6} sm={3} md={2}>
          <img
            className="logo"
            style={{ width: '100%', margin: '2rem' }}
            src={require('../../static/general/craftly_final.png')}
            alt="logo"
          />
        </Col>
        <Col>
          <div style={{ margin: '2rem' }}>
            <Signout />
          </div>
        </Col>
      </Row>

      <div className="box-wrapper">
        <div className="verify-box">
          <Title level={1} className="early-text">
            Your account has been paused by staff for unusual activity.{' '}
          </Title>

          <br></br>

          <Text className="text-contact">Please contact us at support. </Text>
        </div>
      </div>
    </PauseWrapper>
  );
};

export default Pause;

/* ------------------------------------------------------ */
/*                    Ant-Design Modal                    */
/* ------------------------------------------------------ */
// customized Ant-Design Modal
// Details: https://ant.design/components/modal

// Used in multiple files.

import React from 'react';
import PropTypes, { object } from 'prop-types';
import { ModalStyled } from './styled';
import { Button } from '../Button';

const Modal = (props) => {
  const {
    style,
    onCancel,
    bodyStyle,
    className,
    onOk,
    maskClosable,
    open,
    title,
    type,
    color,
    footer,
    width,
    children,
  } = props;

  return (
    <ModalStyled
      style={style}
      title={title}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      type={color ? type : false}
      width={width}
      className={className}
      wrapClassName={className}
      bodyStyle={bodyStyle}
      maskClosable={maskClosable || true}
      footer={
        footer || footer === null
          ? footer
          : [
              <Button type="secondary" key="back" onClick={onCancel}>
                Cancel
              </Button>,
              <Button type={type} key="submit" onClick={onOk}>
                Save Change
              </Button>,
            ]
      }
    >
      {children}
    </ModalStyled>
  );
};

Modal.defaultProps = {
  width: 520, //default width
  className: 'atbd-modal',
};

Modal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  footer: PropTypes.arrayOf(object),
  width: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
};

const alertModal = ModalStyled;

export { Modal, alertModal };

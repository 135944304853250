import React from 'react';
import { RecentModalStyle, ModalRecentHeader, Modalrecent, ModalRecentFooter } from './styled';
import { Typography } from 'antd';

import Paper from '../../../static/favourite/Paper.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import { breakLineForCopy, htmlToText } from '../../../utility/utility';
import { messageAlert } from '../../../components/utilities/messages';

const RecentModal = ({ handleToolPopUp, toolModal, recentData }) => {
  const { displayDate, toolIcon, inputFields, toolDisplayName, lines, contentData, words, len } = recentData || '';
  const { Title, Text } = Typography;
  return (
    <>
      <Modalrecent
        className="recent-modal"
        centered={true}
        open={toolModal}
        onCancel={handleToolPopUp}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <RecentModalStyle>
          <ModalRecentHeader>
            <div className="recent-left-header">
              <img src={toolIcon} alt="tool-icon" />
              <Title level={5} className="Modal-recent-title">
                {toolDisplayName}
              </Title>
            </div>
            <Text level={5} className="Modal-recent-date">
              {displayDate}
            </Text>
          </ModalRecentHeader>
          <div className="recent-input-output">
            <div className="display-output">
              <Text className="heading-text">Input </Text>
              {inputFields &&
                inputFields.length > 0 &&
                inputFields.map(({ name, value }) => {
                  return (
                    <div>
                      <Text className="heading-text"> {name} </Text>
                      <Text className="display-data"> {value} </Text>
                    </div>
                  );
                })}
            </div>
            <div className="display-output">
              <Text className="heading-text">Output:</Text>
              <Text className="display-data"> {lines}</Text>
            </div>
          </div>
          <ModalRecentFooter>
            <div className="recent-bottom">
              <div className="char-words">
                <Text>{len} Chars </Text>
                <span className="dot-fav">•</span>
                <Text>{words} Words </Text>
              </div>
            </div>
            <div className="favIcon">
              <CopyToClipboard
                text={htmlToText(breakLineForCopy(contentData))}
                onCopy={() => messageAlert({ type: 'info', textMessage: 'Text Copied' })}
              >
                <img alt={'copy'} src={Paper} />
              </CopyToClipboard>
            </div>
          </ModalRecentFooter>
        </RecentModalStyle>
      </Modalrecent>
    </>
  );
};

export default RecentModal;

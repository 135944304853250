/* ------------------------------------------------------ */
/*                      User Project                      */
/* ------------------------------------------------------ */

import React from 'react';
import actions from './actions';
import moment from 'moment';
import { gpt3Service } from '../../services/gpt3services';
import { maskBadWords } from '../../utility/filterWords';
import { constructUserToolInput, getToolName, uniqueId } from '../../utility/utility';
import { errorNotificationSenders, getHTMLString } from '../../utility/Email/email';
import ErrorHTML from '../../utility/Email/errorTemplate';
import {
  FIREBASE_RECENT_PROJECT_OBJECT,
  OPENAI_DEDUCTION,
  PERSONAL_WORKSPACE,
  RECENT_TOOL_NOT_DISPLAY,
  REPHRASE_TOOL_KEY,
  TEAM,
  TEAM_WORKSPACE,
  TOOL_ERROR,
  TOOL_USER_CANCEL_RESULTS,
} from '../../constants';
import { getFirestore } from 'redux-firestore';
import { getFirebase } from 'react-redux-firebase';
import { setSelectedWorkSpace, updateTeamProjects } from '../AccountSettings/actionCreator';
import { teamProjectFavouriteSubmit } from '../team/actionCreator';
import { FirebaseAnalytics } from '../../config/firebase';
import {
  CRAFTLY_DOWN_DUE_TO_OPENAI,
  CRAFT_ERROR_TRY_AGAIN,
  PLEASE_CREATE_PROJECT,
  PROJECT_CREATED,
  PROJECT_DELETED,
  SOME_THING_WENT_WRONG,
} from '../../constants/content';
import { addNotificationError, addNotificationInfo } from '../../components/utilities/notifications';
import { messageAlert } from '../../components/utilities/messages';

const {
  userProjectAddBegin,
  userProjectAddSuccess,
  userProjectAddErr,

  userProjectRecentSuccess,
  userProjectFavoriteSuccess,

  userProjectReadBegin,
  userProjectReadSuccess,
  userProjectReadErr,

  userProjectDeleteBegin,
  userProjectDeleteSuccess,
  userProjectDeleteErr,

  userProjectSingleDataBegin,
  userProjectSingleDataSuccess,
  userProjectSingleDataErr,

  userProjectSearchBegin,
  userProjectSearchSuccess,
  userProjectSearchErr,

  toolHitBackendProgress,
  toolHitBackendSuccess,
  toolHitBackendFailed,

  toolOutputNull,
  teamRecentProject,
  toolOutputFiltered,
  userProjectFavLoading,

  favoritesPageSuccess,
  favoritesPageLoading,
  recentPageLoading,
  recentPageSuccess,
  selectedCurrentProject,

  fetchCaiChatHistorySuccess,
  fetchCaiChatHistoryLoading,
  fetchCaiChatHistoryFailed,
} = actions;

/**
 *  Submit new project in Personal workspace
 *  Where manage projects is stored
 *  the projects are stored in firebase collection 'Projects'
 * Saving a new project in firebase.
 */
const userProjectDataSubmit = (data, showNotification) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const uid = getState().fb.auth.uid;
    const { plan } = getState().fb.profile;
    const db = getFirestore();
    const firebase = getFirebase();
    const projectId = Math.random().toString(36).substring(7);
    try {
      if (data) {
        data.projectId = projectId;

        dispatch(userProjectAddBegin());
        db.collection('projects') // here is the name of the collection of the projects. here all the projects stored
          .doc(uid) // the uid is the user id, in which all the user project saved.
          .set(
            {
              projects: firebase.firestore.FieldValue.arrayUnion(data), // this is the object array. contain multiple projects, with project name and project Id.
              recentProject: { project: data.project, projectId: projectId }, // the moment when user creates a new project, we are also setting this project as Recent Project
            },
            { merge: true },
          );

        // if the current plan is team plan then it will be added on team projects also.
        plan === TEAM && (await dispatch(updateTeamProjects({ teamProjects: data, action: 'ADD' })));
        dispatch(userProjectAddSuccess(data));
        dispatch(userProjectDataRead());
        showNotification && addNotificationInfo(PROJECT_CREATED);
      }
    } catch (err) {
      await dispatch(userProjectAddErr(err));
      await addNotificationError(err);
    }
  };
};

/**
 * Read Personal workspace projects
 *
 * Read the project Data and also the recent project.
 * while we are fetching the projects, we do also fetch the recent-projects from the firebase.
 * this technique would save the number of hits of firebase query. we are fetching 2 things on a single hit.
 */
const userProjectDataRead = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const uid = getState().fb.auth.uid;
    try {
      uid &&
        db
          .collection('projects')
          .doc(`${uid}`)
          .get()
          .then(async (doc) => {
            if (doc.data()) {
              const { projects, recentProject } = doc.data();
              projects && recentProject && (await dispatch(userProjectReadSuccess({ data: projects, recentProject })));
            }
          });
    } catch (err) {
      console.log(`err`, err);
      //await dispatch(userProjectReadErr(err));
    }
  };
};

/**
 * Firestore
 * Collection: workspaces
 * Document: <UID>
 *
 * Default worskspace objects are
 *  selectedName
 *  selectedUID
 *  workspaceType
 *
 * if setNewRecentTeamProject is true, a team admin add a new project in team
 *
 */
const userWorkspaceRead = ({ setNewRecentTeamProject }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const uid = getState().fb.auth.uid;
    const { name } = getState().fb.profile;
    try {
      dispatch(userProjectReadBegin());
      uid &&
        db
          .collection('workspaces')
          .doc(uid)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              if (doc.data()) {
                const availableWorkSpaces = Object.keys(doc.data());
                const finalAvailableWorkSpaces = availableWorkSpaces.filter((item) => item !== 'defaultWorkspace');
                const defaultWorkspace = doc.data().defaultWorkspace;
                const workSpaceDetails = { ...doc.data() };
                delete workSpaceDetails.defaultWorkspace;
                if (setNewRecentTeamProject) {
                  dispatch(
                    teamRecentProject({
                      availableWorkSpaces: finalAvailableWorkSpaces,
                      allTeamRecentProject: { ...workSpaceDetails },
                    }),
                  );
                } else {
                  dispatch(setSelectedWorkSpace({ ...defaultWorkspace }));
                  dispatch(
                    teamRecentProject({
                      availableWorkSpaces: finalAvailableWorkSpaces,
                      allTeamRecentProject: { ...workSpaceDetails },
                    }),
                  );
                }
              } else {
                // if any default workspace is not set it set the Personal workspace.
                // Normally this is for new users .
                dispatch(
                  setSelectedWorkSpace({
                    selectedUID: uid,
                    selectedName: name,
                    workspaceType: PERSONAL_WORKSPACE,
                  }),
                );
              }
            } else {
              // doc.data() will be undefined in this case
              await dispatch(
                setSelectedWorkSpace({
                  selectedUID: uid,
                  selectedName: name,
                  workspaceType: PERSONAL_WORKSPACE,
                }),
              );
            }
          })
          .catch((error) => {
            dispatch(userProjectReadErr(error));
          });
    } catch (err) {
      console.log(`err`, err);
      await dispatch(userProjectReadErr(err));
    }
  };
};

// In Firebase 'projects' is the collection where all the personal projects are saved. (personal workspace )
// In Firebase 'workspaces' is the collection where recent team project and also default workspace is saved (team workspace )
const userProjectRecentSubmit = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const uid = getState().fb.auth.uid;
    const { selectedWorkspace } = getState().accountSettings;
    const { workspaceType, selectedUID, selectedName } = selectedWorkspace || {};
    const db = getFirestore();
    // checking the workspace.
    const firebaseObject =
      workspaceType === TEAM_WORKSPACE
        ? selectedUID
        : workspaceType === PERSONAL_WORKSPACE
        ? FIREBASE_RECENT_PROJECT_OBJECT[workspaceType]
        : null;
    try {
      if (data && selectedWorkspace && firebaseObject && selectedUID && workspaceType === PERSONAL_WORKSPACE) {
        await dispatch(userProjectRecentSuccess(data));
        // personal workspace have recent projects in Projects database
        await db
          .collection('projects')
          .doc(`${uid}`)
          .set(
            {
              [firebaseObject]: { project: data.project, projectId: data.projectId },
            },
            { merge: true },
          );
      } else if (data && selectedUID && workspaceType === TEAM_WORKSPACE && firebaseObject) {
        // team workspace have recent projects in Projects database
        // If a team member is a part of multiple teams then each team have its own recent projects.
        await db
          .collection('workspaces')
          .doc(`${uid}`)
          .set(
            {
              [firebaseObject]: { ...data, teamID: selectedUID, teamName: selectedName },
            },
            { merge: true },
          );
        await dispatch(userWorkspaceRead({ setNewRecentTeamProject: true }));
      }
    } catch (err) {
      await addNotificationError(err.message);
    }
  };
};

// This saves the user default workspace in firebase collection.
/**
 * Default worskspace objects in firestore is
 *  selectedName
 *  selectedUID
 *  workspaceType
 */
const userDefaultWorkspace = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const uid = getState().fb.auth.uid;
    const db = getFirestore();
    try {
      data &&
        (await db
          .collection('workspaces')
          .doc(`${uid}`)
          .set({ defaultWorkspace: { ...data } }, { merge: true }));
      // you can see project name & project Id is being saved.
    } catch (err) {
      await addNotificationError(err);
    }
  };
};

const setToolOutputNull = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(toolOutputNull());
  };
};
const cancelToolBackendApi = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    await gpt3Service({ data: {}, cancelRequest: true });
  };
};

const toolHitBackEndAPI = ({
  recentProjectId,
  botId,
  toolBody,
  fields,
  toolID,
  saveBotFields,
  currentStepOutputId,
}) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const uid = getState().fb.auth.uid;
    const { name, plan, email } = getState().fb.profile;
    const { selectedWorkspace } = getState().accountSettings;

    const existingToolOutput = getState().userProject.toolOutput;

    try {
      dispatch(toolHitBackendProgress({ currentBotId: botId }));
      // @ Where API logic is handled
      // here in the toolBody values the input and description values are present. the backend api function is being called.
      const { outputs, error, html } = await gpt3Service({
        data: { ...toolBody, userId: uid, ...saveBotFields },
        cancelRequest: false,
      });

      // This check all the other tools except Rephrase
      if (outputs && toolID !== REPHRASE_TOOL_KEY) {
        const badFilterWord = [];

        outputs.length > 0 &&
          outputs.forEach(({ contentData, words, contentId, len, seed, cfr, time, image }) => {
            const maskedText = maskBadWords(contentData);
            badFilterWord.push({
              contentData: maskedText,
              len,
              cfr,
              contentId,
              image,
              seed,
              words,
              time,
              html,
            });
          });
        const highlightRowsId = [];
        const listContentWithFilters =
          badFilterWord.length > 0
            ? badFilterWord.reduce(
                (
                  _state,
                  { additionalWords, contentData, words, seed, contentId, time, image, len, cfr, html },
                  index,
                ) => {
                  // console.log('contentData', contentData);
                  if (contentData.trim()) {
                    _state.push({
                      contentData,
                      additionalWords,
                      contentId,
                      words,
                      seed,
                      len,
                      cfr,
                      time,
                      image,
                      botId,
                      recentProjectId,
                      html,
                    });
                    highlightRowsId.push(contentId);
                  }
                  return _state;
                },
                [],
              )
            : [];

        const finalContent = {
          content: listContentWithFilters,
        };

        // this IF statement handles only Rephrase TOOL only.
        if (toolID === REPHRASE_TOOL_KEY) {
          dispatch(
            toolHitBackendSuccess({
              toolOutput: finalContent,
              highlightRowsId,
              craftedToolId: toolID,
            }),
          );
        } else {
          if (currentStepOutputId) {
            dispatch(
              toolHitBackendSuccess({
                longFormOutput: finalContent,
                highlightRowsId,
                currentStepOutputId,
                toolOutput: null,
                craftedToolId: toolID,
              }),
            );
          }

          if (existingToolOutput && !currentStepOutputId) {
            const previousResults = existingToolOutput.content;
            const newResults = [...finalContent.content, ...previousResults];
            const addFinalContent = {
              content: newResults,
            };
            dispatch(
              toolHitBackendSuccess({
                toolOutput: addFinalContent,
                highlightRowsId,
                craftedToolId: toolID,
              }),
            );
          } else {
            dispatch(
              toolHitBackendSuccess({
                toolOutput: finalContent,
                highlightRowsId,
                craftedToolId: toolID,
              }),
            );
          }
        }

        //Event Tool-Craft google analytics GA_E_tool_craft
        FirebaseAnalytics.logEvent('tool_craft', {
          email,
          plan,
          toolName: toolID,
          workspaceType: selectedWorkspace.workspaceType,
          uid,
          name,
        });
        // await dispatch(
        //   userSaveBot({
        //     toolName: toolID,
        //     input,
        //     output: finalContent,
        //   }),
        // );
        // if the output is Null or undefined
      } else if (toolID === REPHRASE_TOOL_KEY) {
        dispatch(
          toolHitBackendSuccess({
            toolOutput: { content: [] },
          }),
        );
      } else if (error) {
        dispatch(toolHitBackendFailed());
        if (error !== TOOL_USER_CANCEL_RESULTS) {
          if (error.includes(OPENAI_DEDUCTION)) {
            addNotificationInfo(CRAFTLY_DOWN_DUE_TO_OPENAI);
            const { input: inputUser } = constructUserToolInput({ fields, toolBody });
            const htmlString = getHTMLString(
              <ErrorHTML botId={botId} toolID={toolID} input={inputUser} errorMessage={error} />,
            );

            sendMail({
              subject: `ERROR! Tools API Stopped Working. Time: ${new Date()}`,
              html: htmlString,
              text: `ToolID: ${toolID}, Input: ${inputUser}, BotId: ${botId},  Error Message: ${error}`,
              to: errorNotificationSenders,
              type: TOOL_ERROR,
            });
          } else {
            addNotificationInfo(error);
            const { input: inputUser } = constructUserToolInput({ fields, toolBody });
            const htmlString = getHTMLString(
              <ErrorHTML botId={botId} toolID={toolID} input={inputUser} errorMessage={error} />,
            );

            sendMail({
              subject: `ERROR! Tools API Stopped Working. Time: ${new Date()}`,
              html: htmlString,
              text: `ToolID: ${toolID}, BotId: ${botId}, Input: ${inputUser}, Error Message: ${error}`,
              to: errorNotificationSenders,
              type: TOOL_ERROR,
            });
          }
        }
      } else {
        dispatch(toolHitBackendFailed());
        addNotificationInfo(CRAFT_ERROR_TRY_AGAIN);
        const { input: inputUser } = constructUserToolInput({ fields, toolBody });
        const htmlString = getHTMLString(<ErrorHTML botId={botId} toolID={toolID} input={inputUser} />);

        sendMail({
          subject: `ERROR! Tools API SEND A NULL RESPONSE. Time: ${new Date()}`,
          html: htmlString,
          text: `ToolID: ${toolID}, Input: ${inputUser}, BotId: ${botId},`,
          to: errorNotificationSenders,
          type: TOOL_ERROR,
        });
      }
    } catch (err) {
      // console.log('err', err);
      dispatch(toolHitBackendFailed());
      addNotificationError(SOME_THING_WENT_WRONG);
      const { input: inputUser } = constructUserToolInput({ fields, toolBody });
      const htmlString = getHTMLString(
        <ErrorHTML toolID={toolID} input={inputUser} errorMessage={`App crashed. ${JSON.stringify(err)} ${err}`} />,
      );
      sendMail({
        subject: `ERROR! APP CRASHED. Time: ${new Date()}`,
        html: htmlString,
        text: `ToolID: ${toolID}, Input: ${inputUser}`,
        to: errorNotificationSenders,
        type: TOOL_ERROR,
      });
    }
  };
};

/**
 *
 * Firestore in case of PERSONAL Workspace
 * Collection: users-bot
 * Document: <UID>
 * Collection: craft
 * Document: <botId>
 
 * Firestore in case of TEAM Workspace
 * Collection: team-members
 * Document: <teamID> // this workspace UID is the same ID of TEAM ADMIN UID
 * Collection: craft
 * Document: <botId>
 *
 * The main purpose of this action is to get the toolId output from the open ai.
 * The method is to get the output is
 * 1. First a request payload data is stored in the firebase collection
 * 2. When storing a data in firestore, it return the documentID.
 * 3. With the DocumentID and the other request parameter is being request to the backend-phyton-ai api
 * 4. Reponse came from the backend-phyton-ai api and this reponse has the tool ouput or result.
 * 4. backend-phyton-ai also saves the tool output result in firestore users-bot collection
 */
const userSaveBot = ({ toolName, input, toolBody, fields, toolID, currentStepOutputId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    let recentProjectId = getState().userProject.recentProject.projectId;
    const db = getFirestore();
    const uid = getState().fb.auth.uid;
    const { name, plan, email } = getState().fb.profile;
    const { selectedWorkspace } = getState().accountSettings;
    const date = moment().format('YYYY-MM-DD');
    const teamRecentProject = getState().userProject.teamRecentProject;

    // personal-workspace bots are saved in users-bot collection.
    let collectionName = 'users-bot';
    let documentID = uid;
    const botId = uniqueId(); // its a unique number to identify the tool request id
    const saveBotFields = { botId }; // if personal-workspace then only botId is sent to backend-phyton-ai.

    // Firestore CollectionID is changed for Team Workspace
    if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
      const { allTeamRecentProject } = teamRecentProject || {};
      const { selectedUID } = selectedWorkspace || {};
      const teamRecentProjectId =
        teamRecentProject && allTeamRecentProject[selectedUID] && allTeamRecentProject[selectedUID].projectId;
      recentProjectId = teamRecentProjectId;
      collectionName = 'team-members'; // team-workspace bots are saved in team-members collection
      documentID = selectedUID;
      saveBotFields['workspaceUID'] = selectedUID; // if team-workspace then botId and workspaceUID are sent to GPT-API.
    }
    const { inputFields, input } = constructUserToolInput({ toolBody, fields });
    dispatch(toolHitBackendProgress({ currentBotId: botId }));
    try {
      // now we are saving the users-bot and with the help of the cloud-functions we are populating the analyze-bots for admin
      if (recentProjectId) {
        await db
          .collection(collectionName) //Done
          .doc(documentID)
          .collection('craft')
          .doc(botId)
          .set({
            uid,
            date,
            toolName: toolID,
            input,
            inputFields,
            recentProjectId,
            userName: name,
            plan,
            workspaceType: selectedWorkspace.workspaceType,
            workspaceUID: selectedWorkspace.selectedUID,
            dateInFormat: new Date(),
            email,
            updated: false,
            botId: botId ? botId : 'checkme',
          })
          .then(() => {
            dispatch(
              toolHitBackEndAPI({
                recentProjectId,
                botId,
                toolBody,
                fields,
                toolID,
                saveBotFields,
                currentStepOutputId,
              }),
            ); // GPT-3 API
          })
          .catch((err) => {
            addNotificationError(SOME_THING_WENT_WRONG);
            dispatch(toolHitBackendFailed());
            const { input: inputUser } = constructUserToolInput({ fields, toolBody });
            const htmlString = getHTMLString(
              <ErrorHTML
                toolID={toolID}
                input={inputUser}
                errorMessage={`Users-bot Collection wasnt created successfully . ${JSON.stringify(err)} ${err.message}`}
              />,
            );
            sendMail({
              subject: `ERROR! APP CRASHED. USERS-BOT document doesnt Created. Time: ${new Date()}`,
              html: htmlString,
              text: `ToolID: ${toolID}, BotId: ${botId}, Input: ${inputUser}`,
              to: errorNotificationSenders,
              type: TOOL_ERROR,
            });
          });
      } else {
        addNotificationInfo(PLEASE_CREATE_PROJECT);
        dispatch(toolHitBackendFailed());
      }
    } catch (err) {
      // console.log(`err.message`, err.message);
      addNotificationError(err.message);
      dispatch(toolHitBackendFailed());
    }
  };
};

// very dangerous function. As before we were using the tools from the code but now we have shifted the tools to firebase.
// so basically this function do shift the old-hardcoded tools to firebase.
// thats y it is DANGEROUS FUNCTION.
const saveProducts = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    // const db = getFirestore();
    try {
      // toolList.forEach(async tool => {
      //   try {
      ////     i have intenstionally change the tools to toolsss
      //       await db.collection('toolssss').add({ ...tool, isVisible: true });
      //   } catch (error) {
      //     console.log(error);
      //   }
      // });
    } catch (err) {
      console.log(err);
      await addNotificationError(err);
    }
  };
};

/**
 *
 * By changing the action in the tool output to favourites,
 * the cloud function is triggered and the hasFavorites flag is set in the output data.
 * favorites have the multiple tool-ouput-Id, array
 */
const userProjectFavouriteSubmit = ({ botId, categoryId, contentId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const { uid } = getState().fb.auth;

    const { selectedWorkspace } = getState().accountSettings;

    dispatch(
      userProjectFavLoading({
        favLoading: {
          spin: true,
          contentId,
        },
      }),
    );
    try {
      // For team workspace
      if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
        const { selectedUID } = selectedWorkspace || {};

        dispatch(teamProjectFavouriteSubmit({ botId, contentId, workspaceUID: selectedUID }));
      } else {
        // For Personal Workspace
        await db
          .collection('users-bot')
          .doc(uid)
          .collection('craft')
          .doc(botId)
          .set(
            {
              favorites: firebase.firestore.FieldValue.arrayUnion(contentId), // In one tool ouput there might be multiple tool outputs. so it saves the tool output id
              action: 'favorites',
            },
            { merge: true },
          )
          .then(() => {
            dispatch(
              userProjectFavLoading({
                favLoading: {
                  spin: false,
                  contentId: null,
                },
              }),
            );
          })
          .catch((error) => {
            dispatch(
              userProjectFavLoading({
                favLoading: {
                  spin: false,
                  contentId: null,
                },
              }),
            );
            console.log('error.message', error.message);
          });
      }
    } catch (err) {
      console.log('err', err);
      dispatch(
        userProjectFavLoading({
          favLoading: {
            spin: false,
            contentId: null,
          },
        }),
      );
      await addNotificationError(err);
    }
  };
};

// This gives us all the fav content of the specific project
const userProjectFavouriteRead = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    // Recent Project Id is whether the user personal project or the workspace
    let selectedCurrentProject = getState().userProject.selectedCurrentProject.currentProjectId;
    let selectedToolPageId = getState().adminFeatures.selectedToolPageId;
    // In Firebase collection, all team workspace are stored with their recent project.
    const { selectedWorkspace } = getState().accountSettings;

    // favorites have the multiple tool-ouput-Id, array
    try {
      if (
        selectedCurrentProject &&
        selectedToolPageId &&
        selectedWorkspace &&
        selectedWorkspace.workspaceType === PERSONAL_WORKSPACE
      ) {
        db.collection('users-bot')
          .doc(uid)
          .collection('craft')
          .orderBy('favorites')
          .where('toolName', '==', selectedToolPageId)
          .where('recentProjectId', '==', selectedCurrentProject)
          .onSnapshot(async (favOutputDoc) => {
            const favOutputs = [];
            favOutputDoc.forEach((doc) => {
              favOutputs.push(doc.data());
            });
            const favoritesData = [];
            favOutputs.forEach(({ error, favorites, uid, output, botId, dateInFormat }) => {
              if (!error && output && output.content && Array.isArray(output.content)) {
                const da = output.content
                  .map(({ contentId, contentData, len, time }) => {
                    if (favorites.includes(contentId)) {
                      return {
                        contentId,
                        contentData,
                        len,
                        date: dateInFormat,
                        uid,
                        time,
                        botId,
                      };
                    }
                  })
                  .filter(Boolean);
                favoritesData.push(...da);
              }
            });
            const sortFavData = favoritesData.sort((a, b) => b.time - a.time);
            const displayFavData = { [selectedToolPageId]: sortFavData };
            dispatch(userProjectFavoriteSuccess(displayFavData));
            dispatch(
              userProjectFavLoading({
                favLoading: {
                  spin: false,
                  contentId: null,
                },
              }),
            );
          });
      } else {
        dispatch(
          userProjectFavLoading({
            favLoading: {
              spin: false,
              contentId: null,
            },
          }),
        );
      }
    } catch (err) {
      console.log(`err`, err.message);
      dispatch(
        userProjectFavLoading({
          favLoading: {
            spin: false,
            contentId: null,
          },
        }),
      );
    }
  };
};

// To remove the fav project. For individual users and Team members
const userProjectFavouriteRemove = ({ botId, contentId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const firebase = getFirebase();
    const { uid } = getState().fb.auth;
    const { selectedWorkspace } = getState().accountSettings;
    let collectionName = 'users-bot'; // for normal users
    let docId = uid;

    if (selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
      collectionName = 'team-members'; // for team members only
      docId = selectedWorkspace.selectedUID;
    }

    dispatch(
      userProjectFavLoading({
        favLoading: {
          spin: true,
          contentId,
        },
      }),
    );
    try {
      db.collection(collectionName) // this is the collection in firebase.
        .doc(docId)
        .collection('craft')
        .doc(botId)
        .set({ favorites: firebase.firestore.FieldValue.arrayRemove(contentId) }, { merge: true })
        .then(() => {
          dispatch(
            userProjectFavLoading({
              favLoading: {
                spin: false,
                contentId,
              },
            }),
          );
        })
        .catch((error) => {
          console.log('error.message', error.message);
        });
    } catch (err) {
      await addNotificationError(err.message);
      dispatch(
        userProjectFavLoading({
          favLoading: {
            spin: false,
            contentId: null,
          },
        }),
      );
    }
  };
};

const userProjectDataSearch = (value) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const data = [];
    try {
      dispatch(userProjectSearchBegin());
      db.collection('projects')
        .get()
        .then(async (projectDoc) => {
          projectDoc.forEach((doc) => {
            data.push(doc.data());
          });
          const searchValue = data.filter((item) => item.name.toLowerCase().startsWith(value.toLowerCase()));
          dispatch(userProjectSearchSuccess(searchValue));
        })
        .catch((err) => {
          console.error(err);
          dispatch(userProjectSearchErr(err));
        });
    } catch (err) {
      await dispatch(userProjectSearchErr(err));
    }
  };
};

const userProjectDataDelete = (userProjectId) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const uid = getState().fb.auth.uid;
    const plan = getState().fb.profile.plan;
    const projects = getState().userProject.data;
    const db = getFirestore();
    const updatedProjects = projects.filter(({ projectId }) => projectId !== userProjectId);
    const recentProject = updatedProjects.length > 0 ? updatedProjects[0] : {};
    const updated = {
      projects: updatedProjects,
      recentProject,
    };
    dispatch(userProjectDeleteSuccess({ data: updatedProjects, recentProject }));
    try {
      dispatch(userProjectDeleteBegin());
      db.collection('projects')
        .doc(`${uid}`)
        .set(updated)
        .then(() => {
          addNotificationInfo(PROJECT_DELETED);
          // deleteNotificationSuccess();
          dispatch(userProjectDataRead());
          // if the current plan is team plan then it will be added on team projects also.
          plan === TEAM && dispatch(updateTeamProjects({ teamProjects: updatedProjects, action: 'DELETE' }));
        });
    } catch (err) {
      dispatch(userProjectDeleteErr(err));
      addNotificationError(err.message);
    }
  };
};

const userProjectDataSingle = (id) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    try {
      dispatch(userProjectSingleDataBegin());
      id &&
        db
          .collection('projects')
          .where('id', '==', id)
          .get()
          .then(async (projectSingleDoc) => {
            projectSingleDoc.forEach((doc) => {
              dispatch(userProjectSingleDataSuccess(doc.data()));
            });
          })
          .catch((err) => {
            console.error(err);
            dispatch(userProjectSingleDataErr(err));
          });
    } catch (err) {
      dispatch(userProjectSingleDataErr(err));
    }
  };
};

const sendMail = async ({ subject, text = '', html, to, type = '', testModeOn = false }) => {
  const db = getFirestore();
  const firebase = getFirebase();
  const user = firebase.auth().currentUser;

  return await db
    .collection('mail')
    .add({
      to: to,
      url: window.location.href,
      mode: testModeOn ? 'Live' : process.env.NODE_ENV === 'production' ? 'Live' : 'Test',
      user: user.email,
      time: new Date(),
      message: {
        subject: subject,
        text: text,
        html: html,
      },
      type,
    })
    .then(() => 'success')
    .catch((err) => console.log(`err`, err));
};

// This save the contentId when user COPY any content.
const saveCopyContent = ({ contentId, categoryId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const { uid } = getState().fb.auth;
    const db = getFirestore();

    let recentProjectId = getState().userProject.recentProject.projectId;
    const { selectedWorkspace } = getState().accountSettings;
    const teamRecentProject = getState().userProject.teamRecentProject;

    const { workspaceType } = selectedWorkspace;
    const { selectedUID } = selectedWorkspace || {};

    if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
      const { allTeamRecentProject } = teamRecentProject || {};
      const teamRecentProjectId =
        teamRecentProject && allTeamRecentProject[selectedUID] && allTeamRecentProject[selectedUID].projectId;
      recentProjectId = teamRecentProjectId;
    }
    try {
      selectedUID &&
        recentProjectId &&
        (await db
          .collection('team-copy-content') // collection name in firebase
          .doc(`${contentId}`)
          .set(
            {
              contentId,
              date: new Date(),
              userUID: uid,
              workspaceType,
              recentProjectId,
              workspaceUID: selectedUID,
              categoryId,
            },
            { merge: true },
          ));
    } catch (err) {
      await addNotificationError(err);
    }
  };
};

const deleteOutputCraft = ({ contentId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const allToolOutputs = getState().userProject.toolOutput;
    const deleteOutput = allToolOutputs?.content.filter((item) => item.contentId !== contentId);
    dispatch(toolOutputFiltered({ filterToolOutputs: { content: deleteOutput } }));
  };
};

// This favourite is for FAV PAGE
// Firestore
// Collection: users-bot
// Document : UID
// SubCollection: crafts
// subDocument: BotID  - means craft or outputID
// It obtains the preferred output that they hasFavorites object are true
const fetchFavProjectsPage = (dateRangeFav) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    // Recent Project Id is whether the user personal project or the workspace
    // In Firebase collection, all team workspace are stored with their recent project.
    const { allTools } = getState().adminFeatures;

    let selectedCurrentProjectId = getState().userProject.selectedCurrentProject.currentProjectId;

    dispatch(favoritesPageLoading(true));

    const startDate = new Date(`${dateRangeFav[0]}T00:00:00.000`);
    const endDate = new Date(`${dateRangeFav[1]}T23:59:59.000`);

    try {
      if (selectedCurrentProjectId) {
        db.collection('users-bot')
          .doc(uid)
          .collection('craft')
          .where('recentProjectId', '==', selectedCurrentProjectId)
          .where('hasFavorites', '==', true)
          .where('dateInFormat', '>=', startDate)
          .where('dateInFormat', '<=', endDate)
          .onSnapshot(async (favOutputDoc) => {
            const favOutputs = [];
            favOutputDoc.forEach((doc) => {
              favOutputs.push(doc.data());
            });
            const favoritesData = [];
            favOutputs.forEach(
              ({ favorites, error, input, inputFields, toolName, uid, output, botId, dateInFormat }) => {
                if (!error && output && output.content && Array.isArray(output.content)) {
                  const da =
                    output &&
                    output.content
                      .map(({ contentId, contentData, len, words, time }) => {
                        if (favorites.includes(contentId)) {
                          const toolDisplayName = getToolName({ toolId: toolName, allTools });
                          return {
                            input,
                            contentId,
                            contentData,
                            len,
                            words,
                            date: dateInFormat,
                            uid,
                            time,
                            botId,
                            toolName,
                            toolDisplayName: toolDisplayName || '',
                            inputFields,
                          };
                        }
                      })
                      .filter(Boolean);
                  favoritesData.push(...da);
                }
              },
            );
            const sortFavData = favoritesData.sort((a, b) => b.time - a.time);
            dispatch(favoritesPageSuccess(sortFavData));
            dispatch(favoritesPageLoading(false));
          });
      } else {
        dispatch(favoritesPageLoading(false));
      }
    } catch (err) {
      console.log(`err`, err.message);
      dispatch(favoritesPageLoading(false));
    }
  };
};

// Fetch Recent Tool outputs
const fetchRecentProjectsPage = (dateRangeRecent) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    // Recent Project Id is whether the user personal project or the workspace
    let selectedCurrentProjectId = getState().userProject.selectedCurrentProject.currentProjectId;

    // In Firebase collection, all team workspace are stored with their recent project.
    const { allTools } = getState().adminFeatures;

    dispatch(recentPageLoading(true));

    const startDate = new Date(`${dateRangeRecent[0]}T00:00:00.000`);
    const endDate = new Date(`${dateRangeRecent[1]}T23:59:59.000`);

    try {
      if (selectedCurrentProjectId) {
        db.collection('users-bot')
          .doc(uid)
          .collection('craft')
          .where('recentProjectId', '==', selectedCurrentProjectId)
          .where('dateInFormat', '>=', startDate)
          .where('dateInFormat', '<=', endDate)
          .onSnapshot(async (favOutputDoc) => {
            const recentOutputs = [];
            favOutputDoc.forEach((doc) => {
              const { toolName } = doc.data() || { toolName: '' };
              !RECENT_TOOL_NOT_DISPLAY.includes(toolName) && recentOutputs.push(doc.data());
            });
            const recentData = [];
            recentOutputs.forEach(({ input, inputFields, toolName, uid, output, botId, dateInFormat, error }) => {
              if (!error && output && output.content && Array.isArray(output.content)) {
                const da =
                  output &&
                  output.content
                    .map(({ contentId, contentData, len, words, time }) => {
                      const toolDisplayName = getToolName({ toolId: toolName, allTools });
                      return {
                        input,
                        contentId,
                        contentData,
                        len,
                        words,
                        date: dateInFormat,
                        uid,
                        time,
                        botId,
                        toolName,
                        toolDisplayName: toolDisplayName || '',
                        inputFields,
                      };
                    })
                    .filter(Boolean);
                recentData.push(...da);
              }
            });
            const sortRecentData = recentData.sort((a, b) => b.time - a.time);

            dispatch(recentPageSuccess(sortRecentData));
            dispatch(recentPageLoading(false));
          });
      } else {
        dispatch(recentPageLoading(false));
      }
    } catch (err) {
      console.log(`err`, err.message);
      dispatch(recentPageLoading(false));
    }
  };
};

const saveSelectedCurrentProject = ({ currentSelectedProject }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const { projectId, project } = currentSelectedProject || { projectId: null, project: null };
    dispatch(selectedCurrentProject({ selectedCurrentProject: { currentProjectId: projectId, projectName: project } }));
  };
};

const deleteRephraseOutputCraft = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch(toolOutputFiltered({ filterToolOutputs: null }));
  };
};

// Save Change Rephrase content. this is a manual process, user can save the output ones all the changes are complete.
const saveEditRephraseCraft = (rephraseUpdatedContent) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;

    await db
      .collection('users-bot')
      .doc(uid)
      .collection('craft')
      .doc(rephraseUpdatedContent.botId)
      .update({ 'output.content': [{ ...rephraseUpdatedContent }], action: 'favorites' }, { merge: true })
      .then(() => {
        messageAlert({ type: 'success', textMessage: 'Successfully Saved' });
      })
      .catch((error) => {
        console.log('error.message', error.message);
      });
  };
};

// Chat output save in Firestore
// Collection: cai-chat-tool -> Document: <UID> -> subCollection: history -> subDocument: botId or output Id
const saveCaiChatHistory = ({ title, caiChatOutput, caiChatId, toolId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    let recentProjectId = getState().userProject.recentProject.projectId;
    const { selectedWorkspace } = getState().accountSettings;
    const { email } = getState().fb.profile;

    const caiChatOutputJSON = JSON.stringify(caiChatOutput);

    await db
      .collection('cai-chat-tool')
      .doc(uid)
      .collection('history')
      .doc(caiChatId)
      .set(
        {
          caiChatId,
          title,
          uid,
          toolId,
          history: caiChatOutputJSON,
          recentProjectId,
          workspaceType: selectedWorkspace.workspaceType,
          workspaceUID: selectedWorkspace.selectedUID,
          dateInFormat: new Date(),
          email,
        },
        { merge: true },
      )
      .then(() => {
        messageAlert({ type: 'success', textMessage: 'Successfully Saved' });
      })
      .catch((error) => {
        console.log('error.message', error.message);
      });
  };
};

const deleteSavedCaiChat = ({ caiChatId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;
    await db
      .collection('cai-chat-tool')
      .doc(uid)
      .collection('history')
      .doc(caiChatId)
      .delete()
      .then(() => {
        // messageAlert({ type: 'success', textMessage: 'Successfully Deleted' });
      })
      .catch((error) => {
        console.log('error.message', error.message);
      });
  };
};

/**
 * Firestore:
 * Collection: cai-chat-tool - Document: <UID>
 * subCollection: history
 *
 * Firestore Filter:
 * fetching on recentProjectId
 * order on dateInFormat
 *
 * Each output is connected to the project, and each worksapace has its own projects.
 * It always retrieves outputs created for a certain project.-id
 */
const fetchCaiChatHistory = ({ recentProjectId }) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const db = getFirestore();
    const { uid } = getState().fb.auth;

    try {
      dispatch(fetchCaiChatHistoryLoading());
      recentProjectId &&
        db
          .collection('cai-chat-tool')
          .doc(uid)
          .collection('history')
          .where('recentProjectId', '==', recentProjectId)
          .orderBy('dateInFormat', 'desc')
          .onSnapshot((querySnapshot) => {
            const caiChatHistoryData = [];
            querySnapshot.forEach((doc) => {
              caiChatHistoryData.push(doc.data());
            });
            dispatch(fetchCaiChatHistorySuccess(caiChatHistoryData));
          })
          .catch((err) => {
            dispatch(fetchCaiChatHistoryFailed());
          });
    } catch (err) {
      dispatch(fetchCaiChatHistoryFailed(err));
    }
  };
};

export {
  userProjectDataSubmit,
  userProjectDataSearch,
  userProjectDataDelete,
  userProjectDataSingle,
  userProjectDataRead,
  userProjectRecentSubmit,
  userProjectFavouriteSubmit,
  userProjectFavouriteRead,
  userProjectFavouriteRemove,
  userSaveBot,
  toolHitBackEndAPI,
  setToolOutputNull,
  cancelToolBackendApi,
  saveProducts,
  sendMail,
  userDefaultWorkspace,
  userWorkspaceRead,
  saveCopyContent,
  deleteOutputCraft,
  fetchFavProjectsPage,
  fetchRecentProjectsPage,
  saveSelectedCurrentProject,
  deleteRephraseOutputCraft,
  saveEditRephraseCraft,
  saveCaiChatHistory,
  fetchCaiChatHistory,
  deleteSavedCaiChat,
};

/* ------------------------------------------------------ */
/*                DISPLAY USER CRAFT OUTPUT               */
/* ------------------------------------------------------ */

/* 
Methadology
  This component is being used in user-management page. This display the user's craft output according to specific dates.
  This also filter out the tool, input and outputs.
*/

// Used in UserDetails.js

import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, DatePicker, Radio, Divider, Space, Spin, Input, Empty } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { Cards } from '../Card';
import { breakLineIfRequired, dateFormat, dateFormatWithSlash } from '../../utility/utility';
import { maskBadWords } from '../../utility/filterWords';
import { UserBotsWrapper, ToolOutputContentStyled, DisplayBotsWrapper } from './styled';

const { Title, Text } = Typography;
const { TextArea } = Input;

/**
@array  userAvailableDates: Array of accessible dates that the user has crafted.
@boolean userBotsDateLoading: Calculating user availability dates and retrieving user data as dates change.
@array userBotsDate: It includes all of the user's tool outputs data for a specific date.
@func  readUsersBots: it fetch the data of specific date.
@string uid: user Firebase UID
 */

const DisplayBots = ({ userAvailableDates, userBotsDateLoading, userBotsDate, readUsersBots, uid }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const [toolOutputList, setToolOutputList] = useState([]);
  const [selectedInputBotId, setSelectedInputBotId] = useState('');
  const [toolList, setToolList] = useState([]);
  const [inputList, setInputList] = useState([]);
  const [selectedTool, setSelectedTool] = useState([]);

  const currentDate = selectedDate
    ? moment(selectedDate).format(dateFormatWithSlash)
    : moment().format(dateFormatWithSlash);

  // setting the date and fetch the users dots of specific date
  function onDateChange(date, dateString) {
    setSelectedDate(dateString);
    date && readUsersBots(dateString);
    setSelectedInputBotId('');
    reset();
  }
  const reset = () => {
    setToolOutputList([]);
    setInputList([]);
    setToolList([]);
  };

  useEffect(() => {
    reset();
  }, [uid]);

  useEffect(() => {
    const toolList = [...new Set(userBotsDate.map(({ toolName }) => toolName))]; //Only tools used by the user to generate outputs will be visible.
    // it only fetch the tool-name from the user crafts.
    setToolList(toolList);

    if (toolList && toolList.length === 0) {
      // it reset everything when a user change the date.
      setToolOutputList([]);
      setInputList([]);
    }
  }, [userBotsDate]);

  const onToolInputClick = (botID) => {
    setSelectedInputBotId(botID);
    const filterOutputs = userBotsDate.filter(({ toolName, botId }) => toolName === selectedTool && botId === botID);
    // selected-tool and craft or bot-id
    setToolOutputList(filterOutputs);
  };

  useEffect(() => {
    // it filter out those outputs  of selected tool
    const filterInputs = userBotsDate.filter(({ toolName }) => toolName === selectedTool);
    setInputList(filterInputs);
  }, [selectedTool]);

  // func: when user click on tool-id
  const onSelectToolId = (e) => {
    setSelectedTool(e.target.value);
    setToolOutputList([]);
    setInputList([]);
  };

  // A display of craft output.
  const showContent = ({ index, cfr, gen, len, contentData, words }) => {
    const { singleChangeNewLine } = breakLineIfRequired(maskBadWords(contentData));
    return (
      <li className="content" key={index}>
        <div className="output-details-wrapper">
          <Row justify="space-between">
            <Col xs={24}>
              <TextArea autoSize bordered={false} className={`output-text`} value={singleChangeNewLine} />

              <Space className="output-summary">
                <span> Chars: {len}</span>
                <Divider type="vertical" />
                <span>Words: {words}</span>
                <Divider type="vertical" />
                <span>CFR: {cfr}</span>
                <Divider type="vertical" />
                <span>Generations: {gen}</span>
              </Space>
            </Col>
          </Row>
        </div>
        <br />
      </li>
    );
  };

  // Those dates are only displayed that the user generates from tools.
  const disabledDate = (currentDate) => {
    return userAvailableDates && !userAvailableDates.includes(currentDate.format(dateFormat));
  };

  return (
    <DisplayBotsWrapper>
      <Spin spinning={userBotsDateLoading}>
        {/* Date selects to fetch the users bot */}
        <Title level={5} className="heading-text">
          Select Date
        </Title>

        {/* Antd DatePicker component - it supports Dayjs. */}
        <DatePicker
          defaultValue={dayjs(currentDate, dateFormat)}
          value={dayjs(currentDate, dateFormat)}
          format={dateFormat}
          disabledDate={disabledDate}
          onChange={onDateChange}
          size={'medium'}
        />

        {/* Only tools used by the user to generate outputs will be visible. */}
        <Row gutter={25} className="tool-list">
          <Col xs={24}>
            <Title level={5} className="heading-text">
              Tools
            </Title>
            <Cards headless bodypadding="24px 35px 28px 35px">
              <Radio.Group buttonStyle="solid" onChange={onSelectToolId}>
                {/* tool-list within all crafted outputs*/}
                {toolList &&
                  Array.isArray(toolList) &&
                  toolList.map((toolId) => {
                    return <Radio.Button value={toolId}>{toolId}</Radio.Button>;
                  })}
              </Radio.Group>
              {toolList && toolList.length === 0 && <Empty />}
            </Cards>
          </Col>
        </Row>

        <Row gutter={25}>
          <Col xl={10} lg={10} md={8} sm={8} xs={24}>
            <UserBotsWrapper>
              <div className="bot-sidebar">
                <Title level={5} className="heading-text">
                  Input
                </Title>
                {/* Input of the crafts */}
                {inputList &&
                  inputList.map(({ input, tppr, totalWords, workspaceType, planOnCraft, botId }, index) => {
                    return (
                      <div
                        key={botId}
                        className={`inputWrapper ${selectedInputBotId === botId && 'inputWrapperSelected'}`}
                        onClick={() => onToolInputClick(botId)}
                      >
                        <Text>{input}</Text>
                        <Space className="output-summary">
                          <span>
                            <Text strong> Price Per Hit: </Text> ${tppr}
                          </span>
                          <span>
                            <Text strong> Total words: </Text> {totalWords}
                          </span>
                          <span>
                            <Text strong> BotId:</Text> {botId}
                          </span>
                          <span>
                            <Text strong>Plan on Craft: </Text> {planOnCraft}
                          </span>
                        </Space>
                      </div>
                    );
                  })}
                {inputList && inputList.length === 0 && <Empty />}
              </div>
            </UserBotsWrapper>
          </Col>

          <Col xl={14} lg={14} md={16} sm={16} xs={24}>
            <Title level={5} className="heading-text">
              Content
            </Title>
            {/* output content of the selected tool and date */}
            <ToolOutputContentStyled hasContent={toolOutputList && toolOutputList.length === 0 ? false : true}>
              <div className="content-wrapper">
                {/* craft output */}
                {toolOutputList &&
                  toolOutputList.map(({ output, date, toolName }) => {
                    return (
                      <>
                        <ul className="content-list">
                          {output &&
                            output.map(({ contentData, contentId, cfr, len, token, words, gen }, index) => {
                              return (
                                <>
                                  {showContent({
                                    index,
                                    contentData,
                                    contentId,
                                    cfr,
                                    len,
                                    token,
                                    words,
                                    gen,
                                  })}
                                </>
                              );
                            })}
                        </ul>
                      </>
                    );
                  })}
                {toolOutputList && toolOutputList.length === 0 && <Empty />}
              </div>
            </ToolOutputContentStyled>
          </Col>
        </Row>
      </Spin>
    </DisplayBotsWrapper>
  );
};

export default DisplayBots;

/* ------------------------------------------------------ */
/*                        TOOL PAGE                       */
/* ------------------------------------------------------ */

/**
 * Every Tool is rendered. This is TOOL UI PAGE.
 *
 * Personal and Team workspace are saved in 2 different workspaces.
 *
 * Firestore for Personal Workspace
 * Collection: users-bot
 * Document: <UID>
 * Data: <Output Summary> it is generated from cloud function
 * Collection: craft
 * Document: <tool output data>
 *
 * Firestore for Team workspace
 * Collection: team-members
 * Document: <teamId>
 * Data: <team details>
 * Collection: craft
 * Document: <tool output data>
 *
 *
 * Team Member and Team Admin Tool output summary
 * Collection: teams-bot
 * Document: <teamID>
 * Data: <Team output Summary> it is generated from cloud function
 * Collection: team-member-bot
 * Document: <team-member-UID>
 * Data: <Team-member-summary> it is generated from cloud function trigger
 *
 *
 * Tool Output Mechanism
 *
 *  The method is to get the tool output is
 * 1. First a request payload data is stored in the firebase collection
 * 2. When storing a data in firestore, it return the documentID or botId
 * 3. With the DocumentID or botId and the other request parameter is being request to the backend-phyton-ai api
 * 4. Reponse came from the backend-phyton-ai api and this reponse has the tool ouput or result.
 * 4. backend-phyton-ai also saves the tool output result in firestore users-bot collection
 *
 * In The favourite tab, the favourite shows of personal and team worksapce. They are according to the projects
 *
 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  userSaveBot,
  setToolOutputNull,
  cancelToolBackendApi,
  userProjectFavouriteRead,
} from '../../redux/UserProject/actionCreator';
import { addNotificationInfo } from '../../components/utilities/notifications';
import { checkBadWords } from '../../utility/filterWords';
import {
  BLOG_BUILDER_TOOL,
  CHAT_TOOL_KEY,
  CRAFTER_ACCESS,
  IMAGE_TOOL_KEY,
  isAdminRole,
  isFreeUserRole,
  REPHRASE_TOOL_KEY,
  SPECIAL_TOOLS,
  SPECIAL_TOOLS_UI,
  TEAM_WORKSPACE,
} from '../../constants';
import { verifyBilling } from '../../utility/calculateBilling';
import { engines, modelList, numberOfOutput } from '../../utility/toolsList';
import { badWordsDisplay, PAGETILE, TOOL_BLOCK_WORDS } from '../../constants/content';
import { Helmet } from 'react-helmet';
import { NewHeaderMain } from './styled';
import ToolDesignLeft from './overview/ToolDesignLeft';
import ToolDesignRightContainer from './overview/ToolDesignRightContainer';
import PageHeader from '../../components/PageHeader';
import { saveToolPageId } from '../../redux/AdminFeatures/actionCreator';
import { fetchTeamMembersFavContent } from '../../redux/team/actionCreator';
import CaiRephrase from '../CaiRephrase';
import CaiChat from '../CaiChat';
import BillingModal from '../../components/BillingModal';
import { TOOL_CATEGORIES } from '../../constants';
import { mockData } from './mock';

const ToolDesign = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { toolId, subToolId } = useParams();

  const { Text } = Typography;

  const { roles, plan, planStatus, activatedPlanDetails, noTrialccPlanAcitivated } = useSelector(
    (state) => state.fb.profile,
  );
  const { uid } = useSelector((state) => state.fb.auth);

  const { selectedWorkspace, memberTeamsWithOutHold } = useSelector((state) => state.accountSettings);
  const { allTools: toolList, allToolsLoading, allGroupFields } = useSelector((store) => store.adminFeatures);
  const {
    toolOutput,
    currentBotId,
    selectedCurrentProject,
    highlightRowsId,
    toolHitFailed,
    toolOutputLoading,
    craftedToolId,
  } = useSelector((state) => state.userProject);

  const [toolCharacterInput, setToolCharacterInput] = useState();
  const [showBillingModal, setShowBillingModal] = useState({
    modalVisible: false,
    messageType: null,
  });

  // userAllowToCraft is setup on getUserTodaysActivity function
  const { todaysTotalHits, lastCraftDate, totalWordsCraft, userAllowToCraft, adminBillingSettings, userBots } =
    useSelector((state) => state.billing);
  const { currentBilling } = useSelector((state) => state.stripe);

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    dispatch(saveToolPageId({ selectedToolPageId: toolId }));

    if (toolId === BLOG_BUILDER_TOOL) {
      navigate('/long-form');
    }
  }, [toolId]);

  let recentProjectId = selectedCurrentProject.currentProjectId;

  useEffect(() => {
    if (selectedWorkspace && selectedWorkspace.workspaceType === TEAM_WORKSPACE) {
      dispatch(fetchTeamMembersFavContent({ selectedToolPageId: toolId }));
    } else {
      toolId && recentProjectId && dispatch(userProjectFavouriteRead({ selectedToolPageId: toolId }));
    }
  }, [selectedWorkspace, selectedCurrentProject, toolId]);

  const [translationCode, setTranslationCode] = useState({});

  const details = toolList.find((el) => el.id === toolId);

  let pageDetails = {};

  if (subToolId) {
    pageDetails = details
      ? details.subItem.find((subItemDetails) => {
          return subItemDetails.id === toolId ? subItemDetails : null;
        })
      : null;
  } else {
    pageDetails = details;
  }
  // !pageDetails && navigate('/home');
  const { title, displayToolName, fields, groupId } = pageDetails || {
    title: '',
    displayToolName: null,
    description: '',
    fields: [],
  };

  const [toolBody, setToolBody] = useState({
    model_name: toolId,
  });
  useEffect(() => {
    const stateFields = {};
    const countStateFields = {};

    fields &&
      fields.length > 0 &&
      fields.forEach((field) => {
        if (field.optional !== true) {
          stateFields[field.key] = '';
          countStateFields[field.key] = 0;
        }
      });
    setToolBody({
      ...toolBody,
      ...stateFields,
    });
    setToolCharacterInput({
      ...countStateFields,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (toolList.length > 0 && !details) {
      navigate('/home');
    }
    if (toolList.length > 0) {
      dispatch(setToolOutputNull());
      toolOutputLoading && dispatch(cancelToolBackendApi());
      const stateFields = {};

      const countStateFields = {};
      fields &&
        fields.length > 0 &&
        fields.forEach(({ optional, key, defaultValue }) => {
          if (optional !== true) {
            stateFields[key] = defaultValue ? defaultValue : '';
            countStateFields[key] = 0;
          }
        });
      setToolBody({
        model_name: toolId,
        ...stateFields,
      });
      setToolCharacterInput({
        ...countStateFields,
      });
    }
  }, [fields, toolId, toolList]);

  useEffect(() => {
    document.removeEventListener('keydown', keyhandler);
    setTimeout(() => {
      document.addEventListener('keydown', keyhandler);
    }, 500);

    return () => {
      document.removeEventListener('keydown', keyhandler);
    };
    // eslint-disable-next-line no-use-before-define
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const keyhandler = (e) => {
    if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
      if (!(!isFormValid() || maxLimitExceed().check) && !toolOutputLoading) {
        const $button = document.getElementById('craftButton');
        if ($button) $button.click();
      }
    }
  };

  const onChangeText = (e) => {
    const value = e.target.value;
    let typeCharacters = value.length;
    if (isNaN(value)) {
      typeCharacters = (value || '').trim().length;
    } else {
      if (e.target.name === 'slider') {
        typeCharacters = value;
      } else if (e.target.name === 'n_outputs') {
        typeCharacters = value;
      } else {
        typeCharacters = value.length;
      }
    }

    setToolCharacterInput({
      ...toolCharacterInput,
      [e.target.name]: typeCharacters,
    });
    setToolBody({
      ...toolBody,
      [e.target.name]: e.target.value,
    });
    maxLimitExceed();
  };

  const isFormValid = () => {
    return Object.keys(toolBody).every((_key) => {
      const v = toolBody[_key];
      const field = fields.find((f) => f.key === _key);
      const isOptional = field && field?.optional === true;
      return isOptional || (Array.isArray(v) ? true : isNaN(v) ? v.trim().length > 0 : 0);
    });
  };

  const onSelectTones = (tag, checked) => {
    const { tone } = toolBody || [];
    const nextSelectedTags = tone ? (checked ? [...tone, tag] : tone.filter((t) => t !== tag)) : [tag];
    setToolBody({
      ...toolBody,
      tone: nextSelectedTags,
    });
  };

  // The call to this function is made when the user clicks the "craft" button.
  const onSubmit = async ({ extraInputs, clearInputField }) => {
    if (!isFormValid())
      return addNotificationInfo({
        message: 'All fields are required.',
      });

    const alertBadWords = checkBadWords(toolBody);

    if (alertBadWords.length > 0) {
      const badWordsMessage = (
        <>
          {badWordsDisplay(alertBadWords)}
          <br />
          <Text>{TOOL_BLOCK_WORDS}</Text>
        </>
      );
      return addNotificationInfo(badWordsMessage, 6);
    }

    const toolRequestData = extraInputs
      ? { ...toolBody, ...translationCode, ...extraInputs }
      : { ...toolBody, ...translationCode };

    // Craft Mechanism
    const verify = verifyBilling({
      adminBillingSettings,
      userAllowToCraft,
      userCurrentPlan: plan,
      todaysTotalHits,
      currentBilling,
      userBots,
      planStatus,
      noTrialccPlanAcitivated,
      selectedWorkspace,
      memberTeamsWithOutHold,
      lastCraftDate,
      activatedPlanDetails,
      totalWordsCraft,
      recentProjectId,
      roles,
    });
    if (verify === true) {
      // hitting backend Openai API
      dispatch(userSaveBot({ toolBody: toolRequestData, fields, toolID: toolId })); // when user hit the craft button.
    } else if (typeof verify === 'object') {
      const { notAllowedMessage, limit } = verify;
      setShowBillingModal({ modalVisible: true, messageType: notAllowedMessage, wordsLimit: limit });
    }

    if (clearInputField) {
      setToolBody({
        ...toolBody,
        [clearInputField]: '',
      });
    }
  };

  // every input has its limit
  const maxLimitExceed = () => {
    let limitCheck = { check: false, inputId: '' };
    fields &&
      fields.length > 0 &&
      toolCharacterInput &&
      fields.forEach((field) => {
        const inputFieldId = field['key'];
        const toolLimit = field['characterLimit'];
        const userTypeCount = toolCharacterInput[inputFieldId];
        if (userTypeCount > toolLimit) {
          limitCheck = { ...limitCheck, check: true, [inputFieldId]: inputFieldId };
        }
      });
    return limitCheck; // return fieldsCharacterCount - totalCharacters;
  };

  const sliderRange = { 0: 0.5, 20: 0.6, 40: 0.7, 60: 0.8, 80: 0.9, 100: 1 };

  const onDynamicToolChange = (toolId) => {
    console.log('toolId', toolId);
    navigate(`/tool/${toolId}`);
  };

  const checkGroupIsPresent =
    groupId && allGroupFields && allGroupFields.length > 0
      ? allGroupFields.find(({ groupName }) => groupName === groupId)
      : null;

  const projectFilterToolOutput =
    toolOutput &&
    Array.isArray(toolOutput.content) &&
    toolOutput.content.filter((data) => data.recentProjectId === recentProjectId);

  const displayProjectFilterToolOutput = { content: projectFilterToolOutput, toolId };

  const allowTemplateOnPlan =
    !SPECIAL_TOOLS.includes(toolId) || (memberTeamsWithOutHold && memberTeamsWithOutHold.length > 0)
      ? true
      : CRAFTER_ACCESS.includes(plan) || isAdminRole(roles) || isFreeUserRole(roles);

  const onRedirectClick = (onRedirectLink) => {
    setShowBillingModal({ modalVisible: false });
    navigate(onRedirectLink);
  };
  const onBillingModalClose = () => {
    setShowBillingModal({ modalVisible: false });
  };

  const imageAITool = toolId === IMAGE_TOOL_KEY;

  return (
    <>
      <Helmet>
        <title>{PAGETILE(title)} </title>
      </Helmet>

      <NewHeaderMain>
        <PageHeader
          translation={setTranslationCode}
          title={displayToolName || title}
          hideTranslation={toolId === CHAT_TOOL_KEY ? true : false}
        />

        {/* this is specific design for CHAT TOOL */}

        {toolId === CHAT_TOOL_KEY && (
          <CaiChat
            displayToolName={displayToolName || title}
            toolName={displayToolName || title}
            disable={disable}
            setDisable={setDisable}
            allToolsLoading={allToolsLoading}
            checkGroupIsPresent={checkGroupIsPresent}
            toolId={toolId}
            onDynamicToolChange={onDynamicToolChange}
            fields={fields}
            maxLimitExceed={maxLimitExceed}
            toolCharacterInput={toolCharacterInput}
            toolBody={toolBody}
            onChangeText={onChangeText}
            modelList={modelList}
            toolOutputLoading={toolOutputLoading}
            isFormValid={isFormValid}
            onSubmit={onSubmit}
            allowTemplateOnPlan={allowTemplateOnPlan}
            output={displayProjectFilterToolOutput}
            toolHitFailed={toolHitFailed}
            craftedToolId={craftedToolId}
          />
        )}

        {/* this is specific design for REPHRASE_TOOL_KEY TOOL */}
        {toolId === REPHRASE_TOOL_KEY && (
          <CaiRephrase
            displayToolName={displayToolName || title}
            toolName={displayToolName || title}
            disable={disable}
            setDisable={setDisable}
            allToolsLoading={allToolsLoading}
            checkGroupIsPresent={checkGroupIsPresent}
            toolId={toolId}
            onDynamicToolChange={onDynamicToolChange}
            fields={fields}
            maxLimitExceed={maxLimitExceed}
            toolCharacterInput={toolCharacterInput}
            toolBody={toolBody}
            onChangeText={onChangeText}
            modelList={modelList}
            currentBotId={currentBotId}
            toolOutputLoading={toolOutputLoading}
            isFormValid={isFormValid}
            onSubmit={onSubmit}
            allowTemplateOnPlan={allowTemplateOnPlan}
            output={displayProjectFilterToolOutput}
            uid={uid}
          />
        )}

        {!SPECIAL_TOOLS_UI.includes(toolId) && (
          <div className="wrapper-web">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={10} lg={9}>
                <ToolDesignLeft
                  displayToolName={displayToolName || title}
                  toolName={displayToolName || title}
                  disable={disable}
                  setDisable={setDisable}
                  allToolsLoading={allToolsLoading}
                  checkGroupIsPresent={checkGroupIsPresent}
                  toolId={toolId}
                  onDynamicToolChange={onDynamicToolChange}
                  fields={fields}
                  maxLimitExceed={maxLimitExceed}
                  toolCharacterInput={toolCharacterInput}
                  toolBody={toolBody}
                  onChangeText={onChangeText}
                  engines={engines}
                  onSelectTones={onSelectTones}
                  numberOfOutput={numberOfOutput}
                  sliderRange={sliderRange}
                  modelList={modelList}
                  toolOutputLoading={toolOutputLoading}
                  isFormValid={isFormValid}
                  onSubmit={onSubmit}
                  allowTemplateOnPlan={allowTemplateOnPlan}
                />
              </Col>

              <Col xs={24} sm={24} md={14} lg={15}>
                <ToolDesignRightContainer
                  categoryId={toolId}
                  toolId={toolId}
                  onSubmit={onSubmit}
                  highlightRowsId={highlightRowsId}
                  allowTemplateOnPlan={allowTemplateOnPlan}
                  imageAITool={imageAITool}
                  loading={toolOutputLoading}
                  output={displayProjectFilterToolOutput}
                  // loading={false}
                  // output={mockData}
                />
              </Col>
            </Row>
          </div>
        )}

        {/* {width < 769 && (
          <>
            <ToolDesignLeftWrap>
              <Text className="text-bottom">How were your results?</Text>
              <Rate className="star" defaultValue={0} disabled={!disable} />
            </ToolDesignLeftWrap>
            <Button className="text-editor"> Click for Text Editor Mode </Button>
          </>
        )} */}

        {/* it shows any error occur while trying to generate tool outputs */}

        <BillingModal
          open={showBillingModal.modalVisible}
          onRedirectClick={onRedirectClick}
          onClose={onBillingModalClose}
          messageType={showBillingModal.messageType}
          wordsLimit={showBillingModal.limit}
        />
      </NewHeaderMain>
    </>
  );
};

export default ToolDesign;

//0582087192; address skyview hotel

import styled from 'styled-components';

const BillingModalStyled = styled.div`
  .ant-modal-body {
    border: 2px solid white;
    border-radius: 3px;
  }

  .content {
    text-align: center;
    justify-content: center;
    display: flex;
    font-size: 16px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .button-action {
    display: flex;
    justify-content: center;
  }
`;

export { BillingModalStyled };

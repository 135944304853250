import Styled from 'styled-components';

const UserTableStyleWrapper = Styled.nav`
table{
  tbody{
    td{
      .user-info{
        .user-name{
          font-size: 14px;
        }
      }
      span.status-text {
        font-size: 12px;
        padding: 0 12.41px;
        line-height: 1.9;
        font-weight: 500;
        border-radius: 12px;
        text-transform: capitalize;
        display: inline-block !important;
        background: #ddd;
        &.active{
          background-color: ${({ theme }) => theme['success-color']}15;
          color: ${({ theme }) => theme['success-color']};
        }
        &.deactivate{
          background-color: ${({ theme }) => theme['warning-color']}15;
          color: ${({ theme }) => theme['warning-color']};
        }
        &.pause{
          background-color: #855BF615;
          color: #855BF6;
        }
        &.invite-pending{
          background-color: ${({ theme }) => theme['gray-solid']}15;
          color: ${({ theme }) => theme['gray-color']};
        }
        &.deleted{
          background-color: #07007C15;
          color: #07007C;
        }
        &.approved{
          background-color: ${({ theme }) => theme['warning-color']}15;
          color: ${({ theme }) => theme['warning-color']};
        }
        &.deactive{
          background-color: #07007C15;
          color: #07007C;
        }
        &.error{
          background-color: ${({ theme }) => theme['danger-color']}15;
          color: ${({ theme }) => theme['danger-color']};
        }
        &.expired{
          background-color: ${({ theme }) => theme['danger-color']}15;
          color: ${({ theme }) => theme['danger-color']};
        }
        &.referral{
          background-color: ${({ theme }) => theme['warning-color']}15;
          color: ${({ theme }) => theme['warning-color']};
          font-size: 12px;
        }
      }
    }
  }
}
.ant-table-pagination.ant-pagination{
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme['border-color-light']};
  margin-top: 0 !important;
  padding-top: 30px;
  @media only screen and (max-width: 767px){
    text-align: center;
  }
}
 
`;

const EditForm = Styled.div`
  display: flex;
  flex-direction: column;
  .field {
    width: 100%;
    padding-top: 20px
  }
  div {
    padding-top: 10px

  }
`;

const TableFilters = Styled.div`
  display: flex;
  flex-direction: column; 
  padding-bottom: 14px;
  justify-content: space-between

  .filters-wrapper{
    display: flex ; 
    flex-direction: row;
    padding: 2px;
    justify-content: space-between;
    margin-bottom: 11px;

    @media only screen and (max-width: 576px){
      flex-direction: column;
    }

    .filters-status{
      display: flex; 
      flex-direction: column; 
      padding-right: 25px
    }
  }
`;

const UserManagementHeader = Styled.div`
    margin-top: 3rem;
    margin-bottom: 3rem; 
    .title-counter {
        font-size: 30px;
    }
    
    .user-search {
      min-width: 450px;
         .ant-input-group-addon {
            padding: 0px;
            .ant-select {
                margin: 0px
            }
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 44px;
            border: none;
        }
    }
    
`;
export { UserManagementHeader, UserTableStyleWrapper, EditForm, TableFilters };

/* ------------------------------------------------------ */
/*                    Top Bar Carousel                    */
/* ------------------------------------------------------ */

/**
 * Methadology
 *
 * There are 2 types of notifications
 * 1. User account notification such as user billing, plan, account notifications.
 * 2. Admin notifications such as announcements and tool notifications.
 *
 * admin notification
 *
 * The announcement of admins are displayed on the top of the page.
 * The data is pulled from firestore.
 * Collection: notifications
 * Document: top-banner
 * data:
 * {[
 *   autoPlaySpeed: 10      -number
 *   bannerList : [{
 *    backgroundColor: 'red'   -- color Hex code
 *    notificationText: 'admin nofication text'   -- string nofication text
 *    showPlan: [ starter, crafter ]    -- plan list such as starter, crafter, trial, team, noCreditCard, na
 *   }]
 * ]}
 *
 * we can save the multiple banners and each banner notification slides one by one.
 * on full-screen mode, the notifications  modal opens with the full content
 *
 *
 *
 */

import React from 'react';
import { CarouselText, TopNoticeCarousel } from './style';
import { FullscreenOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { createContext } from 'react';

/**
 * @ReactNode children: these are user profile notifications such as billing, account or plan
 * @array plan: user current plan
 * @boolean topBannerNotifications: does admin have notifications or not.
 */

const TopBarCarousel = ({ children, plan, topBannerNotifications }) => {
  const { autoPlaySpeed, bannerList } = topBannerNotifications || { autoPlaySpeed: 8, bannerList: [] }; //autoPlaySpeed is the slider speed to control the movement of the slides
  const [modal, contextHolder] = Modal.useModal();

  const ReachableContext = createContext(null);

  // In order to see the notification in fullscreen
  const expandNotification = (notificationText) => {
    modal.info({
      title: 'Notification',
      content: notificationText,
    });
  };

  return (
    <>
      {/* Carousel antd component */}
      <TopNoticeCarousel dots={false} autoplay autoplaySpeed={autoPlaySpeed * 1000 || 8000} pauseOnHover>
        {/* this section is for user notifications. it handles those notifications which are related to users account or plan or billing. */}
        {children && (
          <CarouselText>
            <span className="text"> {children}</span>
            <span className="see-more-icon" onClick={() => expandNotification(children)}>
              <FullscreenOutlined />
            </span>
          </CarouselText>
        )}
        {/* this section is for admin notitications. important announcements by admin are displayed here.  */}
        {topBannerNotifications &&
          bannerList &&
          bannerList
            .filter(({ showPlan }) => (showPlan && showPlan.length > 0 ? showPlan.includes(plan) : true))
            .map(({ notificationText, backgroundColor }, index) => (
              // admin notifications are fully controlled by admin such as background-color, text color, plan access, text
              <CarouselText backgroundColor={backgroundColor} key={index}>
                <span className="text">{notificationText} </span>
                <span className="see-more-icon" onClick={() => expandNotification(notificationText)}>
                  <FullscreenOutlined />
                </span>
              </CarouselText>
            ))}
      </TopNoticeCarousel>
      <ReachableContext.Provider>{contextHolder}</ReachableContext.Provider>
    </>
  );
};

export default TopBarCarousel;

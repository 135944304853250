/* ------------------------------------------------------ */
/*                       SummaryPage                      */
/* ------------------------------------------------------ */

/**
 * This UI displays as the last page of long-form.
 * All data for this page is coming as PROPS from index file.
 * This file is acting as Component of LongForm.
 * Styles of this UI is present in STYLED.js file
 */

import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { ReactSVG } from 'react-svg';
import { Button } from '../../../components/Button';
import { SummaryPageWrapper, UnSavedChangesStyled } from '../styled';
import { handleClickScroll } from '../../../utility/utility';
import plus from '../../../static/longForm/Plus.svg';
import Copy from '../../../static/longForm/Paper.svg';
import ArrowUp from '../../../static/longForm/ArrowUp.svg';
import ArrowDown from '../../../static/longForm/arrowDown.svg';
import DeleteIcon from '../../../static/longForm/Delete.svg';
const { Text } = Typography;

const SummaryPage = ({ summaryData, goToSteps, summaryActions }) => {
  const [summary, setSummary] = useState([]);
  const [animateSection, setAnimateSection] = useState(false);
  const [totalWordCount, setTotalWordCount] = useState(0);
  useEffect(() => {
    let wordCount = 0;
    Object.keys(summary).forEach((key) => {
      const words = summary[key];
      if (Array.isArray(words)) {
        words.forEach(({ sectionTitle, value }) => {
          wordCount = wordCount + sectionTitle.split(' ').length;
          wordCount = wordCount + value.toString().split(' ').length;
        });
      } else {
        wordCount = wordCount + words.split(' ').length;
      }
    });
    setTotalWordCount(wordCount);
  }, [summary]);

  useEffect(() => {
    setSummary(summaryData);
    handleClickScroll('summary-page-title');
  }, [summaryData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimateSection(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [animateSection]);

  const changeOrder = (sectionIndex, type, id) => {
    setAnimateSection(id);
    const d = summary['sections'].map((v, index) => {
      const f = type === 'moveUp' ? sectionIndex - 1 : sectionIndex + 1;
      if (sectionIndex === index) {
        return {
          ...v,
          order: type === 'moveUp' ? sectionIndex - 1 : sectionIndex + 1,
        };
      } else if (f === index) {
        return {
          ...v,
          order: type === 'moveUp' ? sectionIndex + 1 : sectionIndex - 1,
        };
      } else {
        return v;
      }
    });
    setSummary({
      ...summary,
      sections: d,
    });
  };

  const onDelete = (sectionId) => {
    const deleteSections = summary['sections'].filter(({ id }) => id !== sectionId);
    setSummary({
      ...summary,
      sections: deleteSections,
    });
  };

  return (
    <SummaryPageWrapper id="summary-page-title">
      <UnSavedChangesStyled>
        <p className="complete-step-text">Actions</p>
        <div className="unsaved-action">
          <Button type="primary" outlined onClick={() => summaryActions({ type: 'draft' })}>
            Save as a draft{' '}
          </Button>

          <Button type="danger" className="new-blog" onClick={() => summaryActions({ type: 'delete' })}>
            Delete & Start Over
          </Button>
        </div>
      </UnSavedChangesStyled>
      <div className="top">
        {/* User Selected Tile  */}
        <div className="title">
          <Text className="text">{summary['title']}</Text>
        </div>

        <Text className="total-words-count"> {totalWordCount} Words </Text>
      </div>
      <div className="intro-wrapper">
        <Text className="intro">Intro </Text>
        <div className="intro-wrapper">
          {/* User Selected Intro  */}
          <Text className="intro-text">{summary['intro']} </Text>
        </div>
      </div>
      <div className="edit-icon">
        <ReactSVG src={plus} onClick={() => goToSteps()} />
      </div>
      {/* User Selected Section in Paragraph builder  */}
      {summary['sections'] &&
        summary['sections']
          .sort((a, b) => a.order - b.order)
          .map(({ sectionTitle, value, id }, index) => (
            <>
              <div className={`section-list-wrapper`}>
                <div className={`section-list ${animateSection === id && 'highlight'}`}>
                  <div className="heading-title">
                    <Text className="heading"> Section: {sectionTitle} </Text>
                  </div>
                  {value.map((text) => {
                    return (
                      <div className="content">
                        <Text className="text">{text}</Text>
                      </div>
                    );
                  })}
                </div>
                {/* These icons will be visible on hovering the box*/}
                <div className="action-icons">
                  {index !== 0 && (
                    <ReactSVG className="summary-icon" src={ArrowUp} onClick={() => changeOrder(index, 'moveUp', id)} />
                  )}
                  {summary['sections'].length - 1 !== index && (
                    <ReactSVG
                      className="summary-icon"
                      src={ArrowDown}
                      onClick={() => changeOrder(index, 'moveDown', id)}
                    />
                  )}
                  <ReactSVG className="summary-icon" src={Copy} />
                  <ReactSVG className="summary-icon" src={DeleteIcon} onClick={() => onDelete(id)} />
                </div>
              </div>

              <div className="edit-icon">
                <ReactSVG src={plus} onClick={() => goToSteps()} />
              </div>
            </>
          ))}
      <div className="section-list-wrapper ">
        <div className="section-list">
          <div className="heading-title">
            <Text className="heading">Conclusion</Text>
          </div>
          <div className="content">
            {/* User Selected Conclusion  */}
            <Text className="text">{summary['conclusion']} </Text>
          </div>
        </div>
      </div>
      <br />
      <br />
    </SummaryPageWrapper>
  );
};

export default SummaryPage;

import styled from 'styled-components';

const TeamHeaderWrapper = styled.div`
  .admin-view {
    background-color: rgba(157, 121, 253, 0.3);
    padding: 1rem;
    border-radius: 1rem;
  }
  .title {
    margin-top: 10px;
  }
  .ant-menu-horizontal {
    border-bottom: none !important;
  }
  .team-menu {
    background-color: transparent;
    font-size: 1rem;
  }
`;

export { TeamHeaderWrapper };
